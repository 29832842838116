import ColorAttributes from './ColorAttributes'
import StringOps from '../utils/StringOps'

class ProteinDomain extends ColorAttributes{
    constructor(options = {}){
        super()
        this.start = 0, 
        this.stop = 0;
        this.source = "Pfam-A", 
        this.accession = "", 
        this.evalue = "", 
        this.bitscore = "";
        this.bColorSpecifiedByUser = false;
    
        if(options && StringOps.size(options) >= 6){
            this.start = options.start;
            this.stop = options.stop;
            this.setName(options.name);
            this.source = options.source;
            this.setColorAttributesByString(options.colorstring);
            this.setStyle(options.shape);
        }else if(options && StringOps.size(options)  === 3){
            this.start = options.start;
		    this.stop = options.stop;
		    this.setName(options.name);
        }else if(options && StringOps.size(options) === 2){
            this.start = options.start;
		    this.stop = options.stop;
        }else if(options && StringOps.size(options) === 1){
            /**
             * @param attrstr - 
             * 0     1   2    3      4     5     6         7      8
             * start,end,name,source,shape,color,accession,evalue,bitscore
             */
            var attrstr = options.attrstr
            var domainArchit = StringOps.JsArrayStringToArrayList( StringOps.splitStringBySeperator(attrstr, ","));
            var size_arraylist = domainArchit.length;
            
            try {
                this.start = parseInt(domainArchit[0]);
            } catch ( e) {
                console.log(" --> domain start parsing error" + attrstr);
            }
    
            try {
                this.stop = parseInt(domainArchit[1]);
            } catch ( e) {
                console.log(" --> domain stop parsing error" + attrstr);
            }
    
            if( size_arraylist >= 3 ){
                this.setName(domainArchit[2].trim());
            }

            if (this.start != 0 && this.stop != 0 && this.stop > this.start) {
                // 0     1   2    3      4     5     6         7      8
                // start,end,name,source,shape,color,accession,evalue,bitscore
                var arrlen = 4;
                if (size_arraylist >= arrlen) {
                    this.setSource(domainArchit[arrlen - 1].trim());
                }
    
                // shape 
                arrlen++;
                if (size_arraylist >= arrlen) {
                    this.setStyle(domainArchit[arrlen - 1].trim());
                }
    
                // color 
                arrlen++;
                if (size_arraylist >= arrlen) {
                    var color = domainArchit[arrlen - 1].trim();
                    if( color.length>=1 ){
                        this.setColorAttributesByString( color );
                        this.setbColorSpecifiedByUser(true);
                    }
                }
    
                // accession 
                arrlen++;
                if (size_arraylist >= arrlen) {
                    this.setAccession(domainArchit[arrlen - 1].trim());
                }
    
                // evalue 
                arrlen++;
                if (size_arraylist >= arrlen) {
                    this.setEvalue(domainArchit[arrlen - 1].trim());
                }
    
                // set bitscore
                arrlen++;
                if (size_arraylist >= arrlen) {
                    this.setBitscore(domainArchit[arrlen - 1].trim());
                }
            }
        }
    }

    isValid() {
		return !(this.start < 0 || this.stop == 0 || this.stop <= this.start);
	}

	/**
	 * @return the start
	 */
	getStart() {
		return this.start;
	}

	/**
	 * @param start the start to set
	 */
	setStart( start) {
		this.start = start;
	}

	/**
	 * @return the stop
	 */
	getStop() {
		return this.stop;
	}

	/**
	 * @param stop the stop to set
	 */
	setStop( stop) {
		this.stop = stop;
	}

	/**
	 * @return the source
	 */
	getSource() {
		return this.source;
	}

	/**
	 * @param source the source to set
	 */
	setSource( source) {
		this.source = source;
	}

	/**
	 * @return the accession
	 */
	getAccession() {
		return this.accession;
	}

	/**
	 * @param accession the accession to set
	 */
	setAccession( accession) {
		this.accession = accession;
	}

	/**
	 * @return the evalue
	 */
	getEvalue() {
		return this.evalue;
	}

	/**
	 * @param evalue the evalue to set
	 */
	setEvalue( evalue) {
		this.evalue = evalue;
	}

	/**
	 * @return the bitscore
	 */
	getBitscore() {
		return this.bitscore;
	}

	/**
	 * @param bitscore the bitscore to set
	 */
	setBitscore( bitscore) {
		this.bitscore = bitscore;
	}

	// May 14, 2012; check if domain annotations are from pfam-B
	isPfamB() {
		return StringOps.equalsIgnoreCase(this.source,"pfam-B");
	}

	isbColorSpecifiedByUser() {
		return this.bColorSpecifiedByUser;
	}

	setbColorSpecifiedByUser( bColorSpecifiedByUser) {
		this.bColorSpecifiedByUser = bColorSpecifiedByUser;
	}
}

export default ProteinDomain