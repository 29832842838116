import StringOps from '../utils/StringOps'
import TreeDecoType from '../treeObjects/TreeDecoType'

class PhyNode {
    constructor () {
        this.node_features = {}
        this.id = ''
        this.name = ''
        this.internal_id = ''
        this.description = ''
        this.htmlName = ''
        this.parent = ''
        this.descendents = []
        this.max_distance_to_tip = -1
        this.branchlength_to_root = -1 
        this.branch_length = 0
        this.bootstrap = 0
        this.isRoot = false
        this.isLeaf = false
        this.collapse = false
        this.arBootstrapValues = []
        this.branchColors = {}
        this.branchStroke = {}
        this.branchLabels = {}
        this.leafColors = {}
        this.leafBackgroundColors = {}
        this.colors = {}
        this.collapsedStyles = {}
        this.level_horizontal = 0
        this.level_vertical = 0
        this.level_vertical_slanted = 0
        this.min_leaf_vertical_level = 0
        this.max_leaf_vertical_level = 0

        this.startUpdated = false
        this.endUpdated = false
        this.selected = false

        this.ptsAr = []
    }

    isSelected(){
      return this.selected
    }

    addFeatures(key,value){
        this.node_features[key] = value
    }
    loadFeatureSet(){
        for(var featrs in this.node_features){
          if(featrs === 'name'){
            this.name = this.node_features[featrs]
          }else if(featrs === 'length'){
            this.given_branch_len = this.node_features[featrs]
          }else if(featrs === 'hasMultiBootStrap'){
            this.hasMultiBootStrap = Boolean(Number(this.node_features[featrs])); // false
          }else if(featrs === 'multiBootstrap'){
            this.ar_bootstrap_values = this.node_features[featrs]
          }else if(featrs === 'boostratp'){
            this.boostrap = this.node_features[featrs]
          }
        }
    
        if(this.name.length<=0){
          this.name = this.internal_id
        }
    }
  generatePolyArray(){
      if(this.node_branchDir === 1){ //drawing towds down
        this.polyAr = [this.start_x,this.start_y,this.start_x-this.branch_len,this.start_y,this.start_x-this.branch_len,this.start_y+this.branch_ht]
      }else if(this.node_branchDir === 2){ //drawing towards up
        this.polyAr = [this.start_x,this.start_y,this.start_x-this.branch_len,this.start_y,this.start_x-this.branch_len,this.start_y-this.branch_ht]
      }
  }

  getLevelHorizontal(){
      return this.level_horizontal;
  }

  setLevelHorizontal(new_level_horizontal){
    this.level_horizontal = new_level_horizontal;
  }

  getLevelVertical(){
      return this.level_vertical;
  }

  setLevelVertical(new_level_vertical){
    this.level_vertical = new_level_vertical;
  }

  getLevelVerticalSlanted(){
      return this.isLeaf ? this.level_vertical : this.level_vertical_slanted;
  }

  setLevelVerticalSlanted(new_level_vertical_slanted){
    this.level_vertical_slanted = new_level_vertical_slanted;
  }

  // jan 7, 2011; if leaf node, min_leaf_vertical_level and max_leaf_vertical_level = level_vertical
  // for slanted normal mode
  getMinLeafVerticalLevel(){
      return this.isleaf() ? this.level_vertical : this.min_leaf_vertical_level;
  }

  setMinLeafVerticalLevel(new_min_leaf_level_vertical){
    this.min_leaf_vertical_level = new_min_leaf_level_vertical;
  }
  getMaxLeafVerticalLevel(){
      return this.isleaf() ? this.level_vertical : this.max_leaf_vertical_level;
  }

  setMaxLeafVerticalLevel(new_max_leaf_level_vertical){
    this.max_leaf_vertical_level = new_max_leaf_level_vertical;
  }

  isAncestor( p ){ //phylonode
    var b = false;
    if( p != null && p != this ){
      if( p.isroot() ){
          b = true;
        } else if( this.isroot() || p.isleaf() ){
          b = false;
        } else {
          var cp = this.getParent(); // get partent 
          while( cp != null && !cp.isroot() ){
            if( p == cp ){
              b = true;
              break;
            }            
            cp = cp.getParent();
          }
        }
    }
    return b;
  }

/*
     * x and y; position;
     */
  getX(){
      return this.x;
  }

  setX(newx){
      this.x = newx;
  }

  getY(){
      return this.y;
  }

  setY(newy){
      this.y = newy;
  }
  // <<<<< x and y <<<<<<<

  getID(){
      return this.id;
  }

  setID(newid){
      this.id = newid;
  }

  getInternalID(){
      return this.internal_id;
  }

  setInternalID(newid){
      this.internal_id = newid;
  }

  getDescription(){
      return this.description;
  }

  setDescription(newdesc){
      this.description = newdesc;
  }

  getBranchLength(){
      return this.branch_length;
  }

  setBranchLength(new_bl){
      this.branch_length = new_bl;
  }

  getBootStrap(){
      return this.bootstrap;
  }

  setBootStrap(newbootstrapscore){
      this.bootstrap = newbootstrapscore;
  }

  getParent(){
      return this.parent;
  }

  setParent(newparent){
      this.parent = newparent;
  }

  getDescendents(){
      return this.descendents;
  }

  setDescendents(newdescendents){
      this.descendents = newdescendents;
  }

addADescendent(newdescendent){
    if(this.descendents.indexOf(newdescendent) == -1){
        this.descendents.push(newdescendent);
        // also change the parent automatically
        newdescendent.setParent(this);
    }
}

removeADescendent(descendent){
    if(this.descendents.indexOf(descendent) != -1){
        var idx = this.descendents.indexOf(descendent);
        // this.descendents.remove(idx);
        this.descendents.splice(idx, 1);
    }
}

getNumberOfLeafDescendents(){
  var nLeafDescendents = 0;
  for( let ind in this.descendents ){
    var node = this.descendents[ind]
      if( node.isleaf() ){
          nLeafDescendents ++;
      } else {
          nLeafDescendents += this.countLeafDescendents( node.getDescendents()  );
      }
  }
  return nLeafDescendents == 0 ? 1 : nLeafDescendents;
}

countLeafDescendents(node ){
  var count = 0;
  for( let ind in node){
    var dnode = node[ind]
      if( dnode.isleaf() ){
          count ++;
      } else if(dnode.getDescendents().length >=1){
          count += this.countLeafDescendents(dnode.getDescendents());
      }
  }
  return count;
}

setAdditionalAttributes(nhxAttrs) {
  this.nhxAttributes = nhxAttrs;
}

getAdditionalAttributs(  ){
  return this.nhxAttributes;
}

isleaf(){
  return this.isLeaf;
}

setLeaf(leaf){
  this.isLeaf = leaf;
}

isroot(){
  return this.isRoot;
}

isCollapse() {
  return this.collapse;
}

setRoot(root){
  this.isRoot = root;
}

getMaxDistanceToTip(){
  return this.max_distance_to_tip;
}

setMaxDistanceToTip(newheight){
  this.max_distance_to_tip = newheight;
}

setBranchLengthToRoot(new_distance_to_root){
  this.branchlength_to_root = new_distance_to_root;
}

getBranchLengthToRoot(){
  return this.branchlength_to_root;
}


removeFromParent(){
  var parent = this.getParent();
  if( parent != null ){
    parent.removeADescendent(this);
  }
  return parent;
}

isNodeComplete(){ 
  var isComplete = true;

  if(this.branch_length <= 0 || this.id.trim().length() == 0 || (this.parent == null && this.descendents.isEmpty())){
      isComplete = false;
  }

  return isComplete;
}

getNumberOfDescendents(){
    return this.descendents.length();
}

 /*
     * branch colors
     */
  addColorToBranch(colorsetID, color){
    // console.log('added ',colorsetID,color)
      this.branchColors[colorsetID] = color
  }
  
  getBranchColorByColorsetID(colorsetID){
      // console.log(colorsetID,this.branchColors[colorsetID],this.branchColors)
      return this.branchColors.hasOwnProperty(colorsetID) ? this.branchColors[colorsetID] : "black";      
  }
  
  removeBranchColorByColorsetID(colorsetID){
    delete this.branchColors[colorsetID];
  }
  
  removeAllBranchColors(){
      this.branchColors = [];
  }

  /*
  * branch stroke
  */
  addStrokeToBranch(colorsetID, color){
      this.branchStroke[colorsetID] = color
  }

  getBranchStrokeByColorsetID(colorsetID){
      return this.branchStroke.hasOwnProperty(colorsetID) ? this.branchStroke[colorsetID] : "";      
  }

  removeBranchStrokeByColorsetID(colorsetID){
    delete this.branchStroke[colorsetID];
  }

  removeAllBranchStroke(){
      this.branchStroke = {};
  }

  /*
  *branch labels
  */
  addLabelsToBranch(colorsetID, color){
    this.branchLabels[colorsetID] = color
  }

  getBranchLabelByColorsetID(colorsetID){
    return this.branchLabels.hasOwnProperty(colorsetID) ? this.branchLabels[colorsetID] : null;      
  }

  removeBranchLabelByColorsetID(colorsetID){
    delete this.branchLabels[colorsetID];
  }

  removeAllBranchLabel(){
    this.branchLabels = {};
  }

  /*
   * leaf colors
   */
  addColorToLeaf(colorsetID, color){
      this.leafColors[colorsetID] =  color;
  }
  
  getLeafColorByColorsetID(colorsetID){
      return this.leafColors.hasOwnProperty(colorsetID) ? this.leafColors[colorsetID] : "black";
  }
  
  removeLeafColorByColorsetID(colorsetID){
    delete this.leafColors[colorsetID];
  }
  
  removeAllLeafColors(){
      this.leafColors = [];
  }
  
  /*
   * leaf background colors
   */
  addColorToLeafBK(colorsetID, color){
      this.leafBackgroundColors[colorsetID] = color
  }
  
  getLeafBKColorByColorsetID(colorsetID){
      return this.leafBackgroundColors.hasOwnProperty(colorsetID) ? this.leafBackgroundColors[colorsetID] : "white"; // March 21, 2011; change default color from 'black' to 'white'
  }
  
  removeLeafBKColorByColorsetID(colorsetID){
    delete this.leafBackgroundColors[colorsetID];
  }
  
  removeAllLeafBKColors(){
      this.leafBackgroundColors = [];
  }
  
  getColorByDatasetID(id){
      return this.colors.hasOwnProperty(id) ? this.colors[id] : "";
  }
  
  addColorWithID(datasetID, color){
    this.colors[datasetID] = color
  }
  
  getColorByDataTypeAndID( type, id ){
      let col = "";
      if(StringOps.equalsIgnoreCase( type,("branch"))){
          col = this.getBranchColorByColorsetID(id);
      }else if ( StringOps.equalsIgnoreCase(type,("leaf"))){
          col = this.getLeafColorByColorsetID(id);
      }else if ( StringOps.equalsIgnoreCase(type,("leafbk"))){
          col = this.getLeafBKColorByColorsetID(id);
      }   
      return col;
  }
  
  addColorByDataTypeAndID(type,id,col ){
    // console.log(type,id,col)
      if( type === ( TreeDecoType.BRANCHCOLOR) ){
          this.addColorToBranch(id, col);
      }else if ( type === ( TreeDecoType.LEAFCOLOR) ){
          this.addColorToLeaf(id, col);
      }else if (  type === ( TreeDecoType.LEAFBKCOLOR ) ){
          this.addColorToLeafBK(id, col);
      }
  }
  
  
  removeColorByDataTypeAndID(  type, id ){
      if( type === ( TreeDecoType.BRANCHCOLOR) ){
          this.removeBranchColorByColorsetID(id);
      }else if ( type === ( TreeDecoType.LEAFCOLOR) ){
          this.removeLeafColorByColorsetID(id);
      }else if ( type === ( TreeDecoType.LEAFBKCOLOR ) ){
          this.removeLeafBKColorByColorsetID(id);
      }
  }

  addStrokeByDataTypeAndId(id,col){
    this.addStrokeToBranch(id,col)
  }

  removeStrokeByDataTypeAndId(id){
    this.removeBranchStrokeByColorsetID(id)
  }
  
  getDistanceToRoot(){
    var distance_to_root = 0;
    if( !this.isroot() ){
        distance_to_root ++;
        var node = this.getParent();
        while( node != null && !node.isroot() ){
            distance_to_root ++;
            node = node.getParent();
        }
    }
    return distance_to_root;
  }

  removeAllDescendents() {
    this.descendents = [];
  }

  setCollapse(collapseStyles) {
    // console.log(collapseStyles)
    if( collapseStyles == null ){
      this.collapse = false;
      this.collapsedStyles = null;
    } else {
      this.collapse = true;
      this.collapsedStyles = collapseStyles;
    }
  }

  getCollapseStyles(){
		return this.collapsedStyles;
	}

  getArBootstrapValues() {
		return this.arBootstrapValues;
	}

	setArBootstrapValues(arBootstrapValues) {
		this.arBootstrapValues = arBootstrapValues;
	}

  setHTMLname(htmlname) {
    this.htmlname = htmlname;
  }

  getHTMLname(){
    return this.htmlname;
  }

  clearPtsAr(){
    this.ptsAr = []
  }
  
  addToPtsAr(pts){
    this.ptsAr.push(pts)
  }

  getPtsAr(){
    return this.ptsAr
  }

  emptyPtsAr(){
    this.ptsAr = []
  }

  toJSON(){
    
  }
}
export default PhyNode
