
class ColourGradient{
    constructor(){
        this.startColour = [0xff, 0x00, 0x00];
	    this.endColour = [0x00, 0x00, 0xff];
	    this.minNum = 0;
	    this.maxNum = 100;
        this.htmlColors = {}
        this.loadHtmlColors()
    }

    loadHtmlColors(){
        this.htmlColors["aliceblue"] = [0xF0, 0xF8, 0xFF]
        this.htmlColors["antiquewhite"] = [0xFA, 0xEB, 0xD7]
        this.htmlColors["aqua"] = [0x00, 0xFF, 0xFF]
        this.htmlColors["aquamarine"] = [0x7F, 0xFF, 0xD4]
        this.htmlColors["azure"] = [0xF0, 0xFF, 0xFF]
        this.htmlColors["beige"] = [0xF5, 0xF5, 0xDC]
        this.htmlColors["bisque"] = [0xFF, 0xE4, 0xC4]
        this.htmlColors["black"] = [0x00, 0x00, 0x00]
        this.htmlColors["blanchedalmond"] = [0xFF, 0xEB, 0xCD]
        this.htmlColors["blue"] = [0x00, 0x00, 0xFF]
        this.htmlColors["blueviolet"] = [0x8A, 0x2B, 0xE2]
        this.htmlColors["brown"] = [0xA5, 0x2A, 0x2A]
        this.htmlColors["burlywood"] = [0xDE, 0xB8, 0x87]
        this.htmlColors["cadetblue"] = [0x5F, 0x9E, 0xA0]
        this.htmlColors["chartreuse"] = [0x7F, 0xFF, 0x00]
        this.htmlColors["chocolate"] = [0xD2, 0x69, 0x1E]
        this.htmlColors["coral"] = [0xFF, 0x7F, 0x50]
        this.htmlColors["cornflowerblue"] = [0x64, 0x95, 0xED]
        this.htmlColors["cornsilk"] = [0xFF, 0xF8, 0xDC]
        this.htmlColors["crimson"] = [0xDC, 0x14, 0x3C]
        this.htmlColors["cyan"] = [0x00, 0xFF, 0xFF]
        this.htmlColors["darkblue"] = [0x00, 0x00, 0x8B]
        this.htmlColors["darkcyan"] = [0x00, 0x8B, 0x8B]
        this.htmlColors["darkgoldenrod"] = [0xB8, 0x86, 0x0B]
        this.htmlColors["darkgray"] = [0xA9, 0xA9, 0xA9]
        this.htmlColors["darkgreen"] = [0x00, 0x64, 0x00]
        this.htmlColors["darkgrey"] = [0xA9, 0xA9, 0xA9]
        this.htmlColors["darkkhaki"] = [0xBD, 0xB7, 0x6B]
        this.htmlColors["darkmagenta"] = [0x8B, 0x00, 0x8B]
        this.htmlColors["darkolivegreen"] = [0x55, 0x6B, 0x2F]
        this.htmlColors["darkorange"] = [0xFF, 0x8C, 0x00]
        this.htmlColors["darkorchid"] = [0x99, 0x32, 0xCC]
        this.htmlColors["darkred"] = [0x8B, 0x00, 0x00]
        this.htmlColors["darksalmon"] = [0xE9, 0x96, 0x7A]
        this.htmlColors["darkseagreen"] = [0x8F, 0xBC, 0x8F]
        this.htmlColors["darkslateblue"] = [0x48, 0x3D, 0x8B]
        this.htmlColors["darkslategray"] = [0x2F, 0x4F, 0x4F]
        this.htmlColors["darkslategrey"] = [0x2F, 0x4F, 0x4F]
        this.htmlColors["darkturquoise"] = [0x00, 0xCE, 0xD1]
        this.htmlColors["darkviolet"] = [0x94, 0x00, 0xD3]
        this.htmlColors["deeppink"] = [0xFF, 0x14, 0x93]
        this.htmlColors["deepskyblue"] = [0x00, 0xBF, 0xFF]
        this.htmlColors["dimgray"] = [0x69, 0x69, 0x69]
        this.htmlColors["dimgrey"] = [0x69, 0x69, 0x69]
        this.htmlColors["dodgerblue"] = [0x1E, 0x90, 0xFF]
        this.htmlColors["firebrick"] = [0xB2, 0x22, 0x22]
        this.htmlColors["floralwhite"] = [0xFF, 0xFA, 0xF0]
        this.htmlColors["forestgreen"] = [0x22, 0x8B, 0x22]
        this.htmlColors["fuchsia"] = [0xFF, 0x00, 0xFF]
        this.htmlColors["gainsboro"] = [0xDC, 0xDC, 0xDC]
        this.htmlColors["ghostwhite"] = [0xF8, 0xF8, 0xFF]
        this.htmlColors["gold"] = [0xFF, 0xD7, 0x00]
        this.htmlColors["goldenrod"] = [0xDA, 0xA5, 0x20]
        this.htmlColors["gray"] = [0x80, 0x80, 0x80]
        this.htmlColors["green"] = [0x00, 0x80, 0x00]
        this.htmlColors["greenyellow"] = [0xAD, 0xFF, 0x2F]
        this.htmlColors["grey"] = [0x80, 0x80, 0x80]
        this.htmlColors["honeydew"] = [0xF0, 0xFF, 0xF0]
        this.htmlColors["hotpink"] = [0xFF, 0x69, 0xB4]
        this.htmlColors["indianred"] = [0xCD, 0x5C, 0x5C]
        this.htmlColors["indigo"] = [0x4B, 0x00, 0x82]
        this.htmlColors["ivory"] = [0xFF, 0xFF, 0xF0]
        this.htmlColors["khaki"] = [0xF0, 0xE6, 0x8C]
        this.htmlColors["lavender"] = [0xE6, 0xE6, 0xFA]
        this.htmlColors["lavenderblush"] = [0xFF, 0xF0, 0xF5]
        this.htmlColors["lawngreen"] = [0x7C, 0xFC, 0x00]
        this.htmlColors["lemonchiffon"] = [0xFF, 0xFA, 0xCD]
        this.htmlColors["lightblue"] = [0xAD, 0xD8, 0xE6]
        this.htmlColors["lightcoral"] = [0xF0, 0x80, 0x80]
        this.htmlColors["lightcyan"] = [0xE0, 0xFF, 0xFF]
        this.htmlColors["lightgoldenrodyellow"] = [0xFA, 0xFA, 0xD2]
        this.htmlColors["lightgray"] = [0xD3, 0xD3, 0xD3]
        this.htmlColors["lightgreen"] = [0x90, 0xEE, 0x90]
        this.htmlColors["lightgrey"] = [0xD3, 0xD3, 0xD3]
        this.htmlColors["lightpink"] = [0xFF, 0xB6, 0xC1]
        this.htmlColors["lightsalmon"] = [0xFF, 0xA0, 0x7A]
        this.htmlColors["lightseagreen"] = [0x20, 0xB2, 0xAA]
        this.htmlColors["lightskyblue"] = [0x87, 0xCE, 0xFA]
        this.htmlColors["lightslategray"] = [0x77, 0x88, 0x99]
        this.htmlColors["lightslategrey"] = [0x77, 0x88, 0x99]
        this.htmlColors["lightsteelblue"] = [0xB0, 0xC4, 0xDE]
        this.htmlColors["lightyellow"] = [0xFF, 0xFF, 0xE0]
        this.htmlColors["lime"] = [0x00, 0xFF, 0x00]
        this.htmlColors["limegreen"] = [0x32, 0xCD, 0x32]
        this.htmlColors["linen"] = [0xFA, 0xF0, 0xE6]
        this.htmlColors["magenta"] = [0xFF, 0x00, 0xFF]
        this.htmlColors["maroon"] = [0x80, 0x00, 0x00]
        this.htmlColors["mediumaquamarine"] = [0x66, 0xCD, 0xAA]
        this.htmlColors["mediumblue"] = [0x00, 0x00, 0xCD]
        this.htmlColors["mediumorchid"] = [0xBA, 0x55, 0xD3]
        this.htmlColors["mediumpurple"] = [0x93, 0x70, 0xDB]
        this.htmlColors["mediumseagreen"] = [0x3C, 0xB3, 0x71]
        this.htmlColors["mediumslateblue"] = [0x7B, 0x68, 0xEE]
        this.htmlColors["mediumspringgreen"] = [0x00, 0xFA, 0x9A]
        this.htmlColors["mediumturquoise"] = [0x48, 0xD1, 0xCC]
        this.htmlColors["mediumvioletred"] = [0xC7, 0x15, 0x85]
        this.htmlColors["midnightblue"] = [0x19, 0x19, 0x70]
        this.htmlColors["mintcream"] = [0xF5, 0xFF, 0xFA]
        this.htmlColors["mistyrose"] = [0xFF, 0xE4, 0xE1]
        this.htmlColors["moccasin"] = [0xFF, 0xE4, 0xB5]
        this.htmlColors["navajowhite"] = [0xFF, 0xDE, 0xAD]
        this.htmlColors["navy"] = [0x00, 0x00, 0x80]
        this.htmlColors["oldlace"] = [0xFD, 0xF5, 0xE6]
        this.htmlColors["olive"] = [0x80, 0x80, 0x00]
        this.htmlColors["olivedrab"] = [0x6B, 0x8E, 0x23]
        this.htmlColors["orange"] = [0xFF, 0xA5, 0x00]
        this.htmlColors["orangered"] = [0xFF, 0x45, 0x00]
        this.htmlColors["orchid"] = [0xDA, 0x70, 0xD6]
        this.htmlColors["palegoldenrod"] = [0xEE, 0xE8, 0xAA]
        this.htmlColors["palegreen"] = [0x98, 0xFB, 0x98]
        this.htmlColors["paleturquoise"] = [0xAF, 0xEE, 0xEE]
        this.htmlColors["palevioletred"] = [0xDB, 0x70, 0x93]
        this.htmlColors["papayawhip"] = [0xFF, 0xEF, 0xD5]
        this.htmlColors["peachpuff"] = [0xFF, 0xDA, 0xB9]
        this.htmlColors["peru"] = [0xCD, 0x85, 0x3F]
        this.htmlColors["pink"] = [0xFF, 0xC0, 0xCB]
        this.htmlColors["plum"] = [0xDD, 0xA0, 0xDD]
        this.htmlColors["powderblue"] = [0xB0, 0xE0, 0xE6]
        this.htmlColors["purple"] = [0x80, 0x00, 0x80]
        this.htmlColors["red"] = [0xFF, 0x00, 0x00]
        this.htmlColors["rosybrown"] = [0xBC, 0x8F, 0x8F]
        this.htmlColors["royalblue"] = [0x41, 0x69, 0xE1]
        this.htmlColors["saddlebrown"] = [0x8B, 0x45, 0x13]
        this.htmlColors["salmon"] = [0xFA, 0x80, 0x72]
        this.htmlColors["sandybrown"] = [0xF4, 0xA4, 0x60]
        this.htmlColors["seagreen"] = [0x2E, 0x8B, 0x57]
        this.htmlColors["seashell"] = [0xFF, 0xF5, 0xEE]
        this.htmlColors["sienna"] = [0xA0, 0x52, 0x2D]
        this.htmlColors["silver"] = [0xC0, 0xC0, 0xC0]
        this.htmlColors["skyblue"] = [0x87, 0xCE, 0xEB]
        this.htmlColors["slateblue"] = [0x6A, 0x5A, 0xCD]
        this.htmlColors["slategray"] = [0x70, 0x80, 0x90]
        this.htmlColors["slategrey"] = [0x70, 0x80, 0x90]
        this.htmlColors["snow"] = [0xFF, 0xFA, 0xFA]
        this.htmlColors["springgreen"] = [0x00, 0xFF, 0x7F]
        this.htmlColors["steelblue"] = [0x46, 0x82, 0xB4]
        this.htmlColors["tan"] = [0xD2, 0xB4, 0x8C]
        this.htmlColors["teal"] = [0x00, 0x80, 0x80]
        this.htmlColors["thistle"] = [0xD8, 0xBF, 0xD8]
        this.htmlColors["tomato"] = [0xFF, 0x63, 0x47]
        this.htmlColors["turquoise"] = [0x40, 0xE0, 0xD0]
        this.htmlColors["violet"] = [0xEE, 0x82, 0xEE]
        this.htmlColors["wheat"] = [0xF5, 0xDE, 0xB3]
        this.htmlColors["white"] = [0xFF, 0xFF, 0xFF]
        this.htmlColors["whitesmoke"] = [0xF5, 0xF5, 0xF5]
        this.htmlColors["yellow"] = [0xFF, 0xFF, 0x00]
        this.htmlColors["yellowgreen"] = [0x9A, 0xCD, 0x32]
    }

    colourAt(number) {
		return this.formatHex(this.calcHex(number, this.startColour[0], this.endColour[0])) + this.formatHex(this.calcHex(number, this.startColour[1], this.endColour[1]))
				+ this.formatHex(this.calcHex(number, this.startColour[2], this.endColour[2]));
    }
    
    calcHex( number, channelStart, channelEnd) {
		var num = number;
		if (num < this.minNum) {
			num = this.minNum;
		}
		if (num > this.maxNum) {
			num = this.maxNum;
		}
		var numRange = this.maxNum - this.minNum;
		var cPerUnit = (channelEnd - channelStart) / numRange;
		return Math.round(cPerUnit * (num - this.minNum) + channelStart);
	}

	formatHex(val) { 
        // console.log(val,this.toHexString(val))
		var hex = this.toHexString(val);
		if (hex.length === 1) {
			return '0' + hex;
		} else {
			return hex;
		}
    }
    
    toHexString(number){
        return "" + number.toString(16);
    }

	setNumberRange(minNumber, maxNumber)  {
		if (maxNumber > minNumber) {
			this.minNum = minNumber;
			this.maxNum = maxNumber;
		}
	}

	setGradient(colourStart, colourEnd){
		this.startColour = this.getHexColour(colourStart);
		this.endColour = this.getHexColour(colourEnd);
	}

	getHexColour(s){
		if (s.match("^#?[0-9a-fA-F]{6}$")) {
			return this.rgbStringToArray(s.replace("#", ""));
		} else {
			var rgbArray = this.htmlColors[s.toLowerCase()];
			if (rgbArray == null) {
				// throw new InvalidColourException(s);
			} else {
				return rgbArray;
			}
		}
	}

	rgbStringToArray( s) {
		var red = parseInt(s.substring(0, 2), 16);
		var green = parseInt(s.substring(2, 4), 16);
		var blue = parseInt(s.substring(4, 6), 16);
		return [red, green, blue];
	}
}

export default ColourGradient