import DataContainerParserBase from './DataContainerParserBase'
import StringOps from '../utils/StringOps'

class NumericMatrixDataContainer extends DataContainerParserBase{
    constructor(newdatasetid, newdataString, phylotree) {
        super()
        this.setDatasetID(newdatasetid);
        this.setActive(true);
        this.setOriginal_datastring(newdataString);
        this.cat2col = {}
        this.row2data = {}
        this.column2data = {}
        this.id2sum = {}
        this.id2radius = {}
        this.id2radiusByArea = {}
        this.id2originalID = {}

        this.cMaxPixelRadius = 40,
        this.cMinPixelRadius = 10, // use 10; April 20, 2012
        this.pixelRadiusPerValue = 0,
        this.maxPieSum = 0, // specify meaning here  
        this.minPieSum = 1000000, // April 8, 2011; bug fix
        this.gridinterval = 0, // grid interval

            /**
             * note: here max and min values refer to the user data plus user-input maxPixelRadius and minPixelRadius ...
             */
        this.minValue = 0,
        this.maxValue = 0;
        
        this.numericMatrixDataParser( newdataString, phylotree );
    } // end of contructor){

    numericMatrixDataParser( newdataString, phylotree) {
        /*
         * prepare to process input data Nov 07, 2011; now annotation line
         * starts with #; action line starts with !
         */
    	var lines = StringOps.JsArrayStringToArrayList( StringOps.splitStringByNewLine( newdataString ) );
    	var hasSeperatorInfo = StringOps.hasSeperatorInfo(lines)
        if(hasSeperatorInfo != null){
            this.hasNewSPacer = true
            this.newSpacer = hasSeperatorInfo
        }
        // console.log(hasSeperatorInfo)
        for (var idx = 0; idx < lines.length; idx++) {
            var current_line_index = idx + 1;
            var line = lines[idx].trim();
            if (line.trim().length > 0 && ! line.startsWith("#") && !line.startsWith("!seperator") &&!line.startsWith("!Seperator")) { // Nov 7, 2011;
            	var lineparts = StringOps.JsArrayStringToArrayList( StringOps.splitStringByTab( line ,hasSeperatorInfo) );
				var part1 = lineparts[0].trim();
                
                // modifiers ---------------------------------------
                if ( part1.startsWith("!")) {
                    this.parseModifier(lineparts, current_line_index);
                    if(!this.isDataValid()){
                       break
                    }
                } 
                // values ------------------------------------------
                else if (lineparts.length >= 2) {
                    var part2 = lineparts[1].trim();

                    /*
                     * deal with data
                     */
                    var internal_id = '';
                    var ids = StringOps.JsArrayStringToArrayList( StringOps.splitStringBySeperator(part1, ","));
                    var lca = phylotree.getLCA(ids);
                    if (lca != null) {
                        internal_id = lca.getInternalID();
                    }

                    if (internal_id != null) {
                    	/**
                    	 * Nov 5, 2013; keep tracking the internal-id <=> ids relationships
                    	 */
                    	this.id2originalID[internal_id] =  ids;
                    	
                        /**
                         *  get data
                         */
                        var sum = 0;
                        var ardata = [];
                        var strAr = StringOps.JsArrayStringToArrayList( StringOps.splitStringBySeperator(part2, ","))
                        for (var ind in strAr) {
                            var strdata = strAr[ind]
                            try {
                                var fdata = parseFloat(strdata);
                                ardata.push(fdata);
                                sum += fdata;
                            } catch (e) {
                                this.setError("error parsing data at line: " + current_line_index + "; float expected, got " + strdata);
                                this.setValid(false);
                            }
                        }
                        var groups = this.getLegendTexts();
                        if( groups.length<=0){
                            this.setError("both !groups and !colors are required for this type of dataset, please check your input!!");
                            this.setValid(false);
                        } else {
                            if (ardata.length != groups.length) {
                                console.log(ardata,groups)
                                this.setError("error parsing data at line: " + current_line_index + "; " + groups.length + " valid values expected, got " + ardata.length);
                                this.setValid(false);
                            }
                        }

                        if (sum >= 0) {
                            this.row2data[internal_id] =  ardata;
                            this.id2sum[internal_id] =  sum;
                            if (this.maxPieSum < sum) {
                                this.maxPieSum = sum;
                            }
                            if (this.minPieSum > sum) {
                                this.minPieSum = sum;
                            }
                        } else {
                            this.setError("error parsing data at line: " + current_line_index + "; " + groups.length + " valid values expected, got " + ardata.length );
                            this.setValid(false);
                        }
                    } // internal id 
                }
            } // end of if line isn't empty
        } // iterate string fragments split from pcdatastring

        // cat to colors
        if (this.isValid()) {
            if (this.isSizesMatchGroupsSizes()) {
                this.cat2col = this.getGroup2ColorHash();
            } else {
                this.setError(" the number of groups (from line: #groups) and colors (from #colors) don't match, please check your input data!");
                this.setValid(false);
            }
        }

        /*
         * check if user input max/ min radius values are valid April 20, 2012:
         * now allow min radius == 0
         */
        this.cMaxPixelRadius = this.getMaxPixelRadius();
        this.cMinPixelRadius = this.getMinPixelRadius();
        
        if( this.cMaxPixelRadius <=0 || this.cMaxPixelRadius < this.cMinPixelRadius ){
            this.setError("max radius cannot be smaller than min radius, please check your input!!");
            this.setValid(false);
        }

        /*
         * calculate radius values
         */
        if (this.isValid()) {
            this.caculateRadiusValues();
        }

        /*
         * check if there is any valid data
         */
        if (this.row2data.length<=0) {
            this.setError("no valid data parsed, please check your input!!");
            this.setValid(false);
        } // if treenodeid2data empty

        // at the end, iterate treenodeid2data and get group2data; March 23, 2011
        // also calculate the max and min values 
        var temp = [];

        if (this.isValid()) {
            for (var ind in this.row2data) {
                var row = this.row2data[ind]
                temp.push.apply(temp,row); // 
                for (var gidx = 0; gidx < this.getGroupsCount(); gidx++) {
                    var group = this.getGroupByIndex(gidx);
                    if (!this.column2data.hasOwnProperty(gidx)) {
                        var newarr = [];
                        this.column2data[group] =  newarr;
                    }
                    this.column2data[group].push(row[gidx]);
                }
            }// iterate treenodeid2data;
            
            /**
             * April 2, 2015 .. when calculate min and max values, the user input max and min will also be taken into account --
             */
            if( this.isMaxValueSet() ){
            	temp.push(this.getMaxPixelRadius());
            }
            if( this.isMinValueSet() ){
            	temp.push(this.getMinPixelRadius());
            }

            if (temp.length>=1) {
                this.minValue = Math.min(...temp);
                this.maxValue = Math.max(...temp);
            } else {
                this.setError(" unknown error, please check your input!!");
                this.setValid(false);
            }
        }
    }
    
    caculateRadiusValues() {
        var success = false;
        if (this.maxPieSum >= this.minPieSum) {// Feb 13, 2012; 
            // calculate
            var pixelAreaPerValue = 0;
            if (this.cMinPixelRadius == 0) { // if it's default
                this.pixelRadiusPerValue = this.cMaxPixelRadius / this.maxPieSum; // important fix a bug Feb 22, 2012
                pixelAreaPerValue = (this.cMaxPixelRadius / Math.sqrt(this.maxPieSum));
            } else {
                this.pixelRadiusPerValue = (this.maxPieSum > this.minPieSum)
                        ? (this.cMaxPixelRadius - this.cMinPixelRadius) / (this.maxPieSum - this.minPieSum) : 0; // important fix a bug Feb 22, 2012
                pixelAreaPerValue = ((this.maxPieSum > this.minPieSum)
                        ? (this.cMaxPixelRadius - this.cMinPixelRadius) / (Math.sqrt(this.maxPieSum) - Math.sqrt(this.minPieSum)) : 0);
            }

            for (var ind in this.id2sum) {
                var node_id = ind
                var currentSum = this.id2sum[node_id];
                if (this.cMinPixelRadius == 0) {
                    this.id2radius[node_id]  = currentSum * this.pixelRadiusPerValue;
                    this.id2radiusByArea[node_id] =  Math.sqrt(currentSum) * pixelAreaPerValue;
                } else {
                    this.id2radius[node_id] =  (currentSum - this.minPieSum) * this.pixelRadiusPerValue + this.cMinPixelRadius;
                    this.id2radiusByArea[node_id] = (Math.sqrt(currentSum) - Math.sqrt(this.minPieSum)) * pixelAreaPerValue + this.cMinPixelRadius;
                }
            } // April 20, 2012; area versus radius 
            success = true;

        } else {
            this.addError_message(" error, possible reasons: \n"
                    + "a. the largest row sum and the smallest row sum are of the same size, please check your input!!\n"
                    + "b. you chose to plot pies using their areas but the the difference between ");
            this.setValid(false);
            success = false;
        }
        return success;
    }

    reMapDataAfterTreeReroot( phylotree ){
    	
    	// System.err.println("  --> recalculate some data in NumericMatrixData --> ");
    	/**
    	 * all the following hashMaps will be recalculate
    	 * row2data ( but not 'column2data' ), id2sum, id2radius, id2radiusByArea
    	 */
    	for( var ke in this.id2originalID){
    		var oldInternalId =ke;
    		var ids = this.id2originalID[ke];
    		
    		/**
    		 * get internal ID on current phylotree 
    		 */
    		var lca = phylotree.getLCA(ids);
            if (lca != null) {
            	var internal_id = lca.getInternalID();
            	if( internal_id != null && internal_id.length>=1 ){
            		/**
            		 * if old internal ID does not match to new internal id,
            		 * change values 
            		 */
            		if( !oldInternalId === (internal_id) ){
            			this.row2data[internal_id] = this.row2data[oldInternalId];
            			this.id2sum[internal_id] = this.id2sum[oldInternalId];
            			this.id2radius[internal_id] = this.id2radius[oldInternalId];
            			this.id2radiusByArea[internal_id] = this.id2radiusByArea[oldInternalId];
            		}
            	}
            }// if lca if not null    		
    	}// entry set
    }

    getTreeNodeID2Data() {
        return this.row2data; // note ID is internal ID
    }

    getRadiusByID( node_id) {
        return this.id2radius[node_id];
    }

    getGroup2Colors() {
        return this.cat2col;
    }

    updateminPixelRadius( newminPixelRadius) {
        if (newminPixelRadius != this.cMinPixelRadius && newminPixelRadius < this.cMaxPixelRadius) {
            this.cMinPixelRadius = newminPixelRadius;
            this.caculateRadiusValues();
        }
    }

    updatemaxPixelRadius( newmaxPixelRadius) {
        if (newmaxPixelRadius != this.cMaxPixelRadius && newmaxPixelRadius > this.cMinPixelRadius) {
            this.cMaxPixelRadius = newmaxPixelRadius;
            this.caculateRadiusValues();
        }
    }

    updateMinmaxPixelRadius( newminPixelRadius, newmaxPixelRadius) {
        if (!(newmaxPixelRadius == this.cMaxPixelRadius && newminPixelRadius == this.cMinPixelRadius) && newmaxPixelRadius > newminPixelRadius) {
            this.cMaxPixelRadius = newmaxPixelRadius;
            this.cMinPixelRadius = newminPixelRadius;

            this.caculateRadiusValues();
        }
    }

    updateIncrementalPixelRadiusPerValue( newIncrementalPixelRadiusPerValue) {
        if (newIncrementalPixelRadiusPerValue != this.pixelRadiusPerValue) {
            var newmaxPixelRadius = this.cMinPixelRadius + (this.maxPieSum - this.minPieSum) * newIncrementalPixelRadiusPerValue;
            this.updatemaxPixelRadius(newmaxPixelRadius);
        }
    }
    
    getPixelRadiusPerValue() {
        return this.pixelRadiusPerValue;
    }

    getSumByID(node_internalID) {
        return this.id2sum[node_internalID];
    }

    getID2Sum() {
        return this.id2sum;
    }

    getGridinterval() {
        var ginterval = this.gridinterval;
        if (ginterval == 0) {
        }
        return ginterval;
    }

    getColumn2Data() {
        return this.column2data;
    }

    getMaxValue() {
        return this.maxValue;
    }

    getMinValue() {
        return this.minValue;
    }

    // = April 20, 2012 ==
    getAreaByID( node_internalID) {
        return this.id2radiusByArea[node_internalID];
    } //

    /**
     * Nov 28, 2015; return false if current_value is in the range of valuesToHideStartValue and valuesToHideEndValue 
     * @param current_value
     */
	shouldThisValueToBeShown( current_value) {
		var bShow = true;
		if( this.isValuesToHideSet() && current_value >= this.getValuesToHideStartValue() && current_value <= this.getValuesToHideEndValue() ){
			bShow = false;
		}
		return bShow;
	}
	
	/**
	 * logtransform 
	 * !logtransform
	 */
	canMakeLogTransform() {
		return ( this.isLogtransform() && this.column2data.length == 1 ) || ( this.isLogtransform() && this.column2data.length > 1 && this.isAlignindividualcolumn() );
	}
}

export default NumericMatrixDataContainer
