
class BranchLabelAttributeContainer{
    constructor(nodeids,itemDef){
        this.nodeIDs = nodeids;
        this.itemDef = itemDef
    }

    getNodeIDs() {
        return this.nodeIDs;
    }

    getItemDef(){
        return this.itemDef
    }
}

export default BranchLabelAttributeContainer