<template>
    <div>
    <modal id="viewFileMod" name="viewFileMod"        
        :draggable="true"
        :adaptive="true" 
        :min-height="minWinHt"
        :min-width="minWinWid"
        :scrollable="true"
        @before-close="beforeClose"     
        :resizable="true"
        :clickToClose="false"
    >
    <md-card style="height:100%;width:100%;overflow:scroll">
        <md-card-header >
            <!-- <md-toolbar md-elevation="1">
                <h3 class="md-title" style="flex: 1">File upload</h3>
                    <md-button class="md-icon-button" @click="$modal.hide('viewFileMod')">
                        <font-awesome-icon icon="times-circle" class="iconsz"></font-awesome-icon>
                    </md-button>                    
            </md-toolbar> -->
            <div class="md-title" >
                <h5 class="d-flex justify-content-between align-items-center" align="center">
                        File import
                    <md-button class="md-icon-button" @click="$modal.hide('viewFileMod')">
                        <font-awesome-icon icon="times-circle" class="iconsz"></font-awesome-icon>
                    </md-button>
                </h5>
            </div>
            <div class="md-subhead" align="left">autodetct mode</div>
        </md-card-header>                    
        <md-card-content>
            <span v-show="unknownFile">
                <b-row style="width:100%;padding-top:15px">
                        <b-col sm="3">Select format</b-col>
                        <b-col>
                            <b-form-select v-model="unknownFileSel" :options="unknownFileOptions" @change="selInputChange" size="sm"></b-form-select>
                        </b-col>
                </b-row>
            </span>
            <span v-show="datsetUpload">
                <b-row style="width:100%;padding-top:15px">
                        <b-col sm="3">File</b-col>
                        <b-col >
                            {{fileName}}                            
                        </b-col>
                </b-row>
                <b-row style="width:100%;padding-top:5px">
                    <b-col sm="3">Dataset Name</b-col>
                    <b-col sm="5">
                        <b-form-input id="datasetNameTF" v-model="datasetNameTF" type="text" size="sm" :state="notEmptyDsName"></b-form-input>
                    </b-col>
                </b-row>
                <b-row style="width:100%;padding-top:5px">
                    <b-col sm="3"> Dataset type</b-col>
                    <b-col>
                        <b-row>
                        <!-- <span style="margin-top:5px;padding:8px;background-color:white;border-radius: 15px;border: 2px solid white;margin-right:5px" class="md-elevation-1">                              
                            <img 
                                v-for="(btn, idx) in importButtons" 
                                :key="`f-${idx}`"
                                :id="btn.name"
                                v-bind:src="`image/${btn.src}`"
                                @mouseover="btnMouseOver(btn)"
                                @mouseout="btnMouseExit(btn)"
                                @click="importCtrlAction(btn)"
                                width="30" height="30"
                                style="padding-top:0px;padding-bottem:0px"
                            />
                            <b-popover v-for="(btn, idx) in importButtons" 
                            :key="`p-${idx}`"
                            :target="btn.name" triggers="hover focus" placement="bottomright">
                                <template v-slot:title><span v-html="btn.tooltip"></span> </template>
                                <img v-bind:src="`image/${btn.tooltipUrl}`" style='width:100%'/>
                            </b-popover>
                        </span>
                        <span style="margin-top:5px;padding:8px;background-color:white;border-radius: 15px;border: 2px solid white;margin-right:5px" class="md-elevation-1">                              
                            <img 
                                v-for="(btn, idx) in importButtonIconTwo" 
                                :key="`f-${idx}`"
                                :id="btn.name"
                                v-bind:src="`image/${btn.src}`"
                                @mouseover="btnMouseOver(btn)"
                                @mouseout="btnMouseExit(btn)"
                                @click="importCtrlAction(btn)"
                                width="30" height="30"
                                style="padding-top:0px;padding-bottem:0px"
                            />
                            <b-popover v-for="(btn, idx) in importButtonIconTwo" 
                            :key="`p-${idx}`"
                            :target="btn.name" triggers="hover focus" placement="bottomright">
                                <template v-slot:title><span v-html="btn.tooltip"></span> </template>
                                <img v-bind:src="`image/${btn.tooltipUrl}`" style='width:100%'/>
                            </b-popover>
                        </span> -->
                        <span style="margin-top:5px;padding:8px;background-color:white;border-radius: 15px;border: 2px solid white;margin-right:5px" class="md-elevation-1">                              
                            <b-button 
                            v-for="(btn, idx) in importButtons" 
                            :key="`f-${idx}`"
                            :id="btn.name"
                            class="eicons"
                            variant="link"
                            @mouseover="btnMouseOver(btn)"
                            @mouseout="btnMouseExit(btn)"
                            :style="btn.disabled ? 'filter: opacity(50%);padding:0px' : 'background-color:'+btn.bg+';padding:0px'"
                            @click="importCtrlAction(btn)">
                            <svg                                     
                            width="30" height="30">
                                <use :xlink:href="btn.svg"></use>
                            </svg>
                            </b-button>

                            <b-popover v-for="(btn, idx) in importButtons" 
                            :key="`p-${idx}`"
                            :target="btn.name" triggers="hover focus" placement="bottomright">
                                <template v-slot:title><span v-html="btn.tooltip"></span> </template>
                                <img v-bind:src="`image/${btn.tooltipUrl}`" style='width:100%'/>
                            </b-popover>
                        </span>
                        <span style="margin-top:5px;padding:8px;background-color:white;border-radius: 15px;border: 2px solid white;margin-right:5px" class="md-elevation-1">                              
                            <b-button 
                            v-for="(btn, idx) in importButtonIconTwo" 
                            :key="`f-${idx}`"
                            :id="btn.name"
                            class="eicons"
                            variant="link"
                            @mouseover="btnMouseOver(btn)"
                            @mouseout="btnMouseExit(btn)"
                            :style="btn.disabled ? 'filter: opacity(50%);padding:0px' : 'background-color:'+btn.bg+';padding:0px'"
                            @click="importCtrlAction(btn)">
                            <svg                                     
                            width="30" height="30">
                                <use :xlink:href="btn.svg"></use>
                            </svg>
                            </b-button>

                            <b-popover v-for="(btn, idx) in importButtonIconTwo" 
                            :key="`p-${idx}`"
                            :target="btn.name" triggers="hover focus" placement="bottomright">
                                <template v-slot:title><span v-html="btn.tooltip"></span> </template>
                                <img v-bind:src="`image/${btn.tooltipUrl}`" style='width:100%'/>
                            </b-popover>
                        </span>                        
                        </b-row>
                        <b-row>
                        <span style="margin-top:5px;padding:8px;background-color:white;border-radius: 15px;border: 2px solid white;margin-right:5px" class="md-elevation-1">                              
                            <b-button 
                            v-for="(btn, idx) in importButtonIconThree" 
                            :key="`f-${idx}`"
                            :id="btn.name"
                            class="eicons"
                            variant="link"
                            @mouseover="btnMouseOver(btn)"
                            @mouseout="btnMouseExit(btn)"
                            :style="btn.disabled ? 'filter: opacity(50%);padding:0px' : 'background-color:'+btn.bg+';padding:0px'"
                            @click="importCtrlAction(btn)">
                            <svg                                     
                            width="30" height="30">
                                <use :xlink:href="btn.svg"></use>
                            </svg>
                            </b-button>

                            <b-popover v-for="(btn, idx) in importButtonIconThree" 
                            :key="`p-${idx}`"
                            :target="btn.name" triggers="hover focus" placement="bottomright">
                                <template v-slot:title><span v-html="btn.tooltip"></span> </template>
                                <img v-bind:src="`image/${btn.tooltipUrl}`" style='width:100%'/>
                            </b-popover>
                        </span>
                        </b-row>
                    </b-col>
                </b-row>
                <b-row style="width:100%;padding-top:5px">
                    <b-col sm="3">Selected datatype</b-col>
                    <b-col sm="5">
                        {{selDatType}}
                    </b-col>
                </b-row>
            </span>
             <span v-show="treeUpload">
                    <b-row style="width:100%;padding-top:15px">
                        <b-col sm="3">File format</b-col>
                        <b-col>
                            <b-form-select v-model="inFileTypSel" :options="fileTypOptions" @change="selFormChange" size="sm"></b-form-select>
                        </b-col>
                    </b-row>
                    <b-row style="width:100%;padding-top:5px">
                        <b-col sm="3">File</b-col>
                        <b-col >
                            {{fileName}}
                            <span v-if="treeFileOk">
                                <font-awesome-icon icon="check" style="color:green"></font-awesome-icon>
                            </span>
                            <span v-else>
                                <font-awesome-icon icon="times" style="color:red"></font-awesome-icon>
                            </span>
                        </b-col>
                    </b-row>
                    <b-row style="width:100%;padding-top:5px">
                        <b-col sm="3">Project Name <font-awesome-icon icon="info-circle" id="popover-1-bot" style="padding:0px"></font-awesome-icon></b-col>
                        <b-popover
                        target="popover-1-bot"
                        placement="bottom"
                        title="Attention!"
                        triggers="hover focus"
                        content="Only pre created project names can be used here."
                        ></b-popover>
                        <b-col >
                            <b-form-input id="projNameTF" v-model="projNameTF" type="text" :state="validateProj" size="sm"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row style="width:100%;padding-top:5px">
                        <b-col sm="3">Tree Name</b-col>
                        <b-col >
                            <b-form-input id="treeNameTF" v-model="treeNameTF" type="text" :state="validateTree" size="sm"></b-form-input>
                        </b-col>
                    </b-row>                    
             </span>
        </md-card-content>
        <md-card-actions class="md-alignment-center" style="padding-right:20px">
            <md-button class="md-raised md-primary"  @click="uploadFile" :disabled="disableUpload">Upload</md-button>
            <md-button class="md-raised md-accent" @click="resetInputs">Cancel</md-button>
        </md-card-actions>

    </md-card>
    </modal>
    </div>
</template>

<script>
import EventBus from './EventBus'
import Commons from '../utils/CommonEvol'
import Db from '../dbop/Db'
import ButtonIconMap from '../utils/ButtonsIconMap'
import MC from '../treeObjects/MC'

export default {
    props:['actProj','actTree','catPanel'],
    data() {
        return {
            userId:'',
            server_add : this.$hostname,
            treeUpload:false,
            fileName:'',
            projNameTF:'',
            treeNameTF:'',
            activeTree:'',
            activeProject:'',
            inFileTypSelForm:'newick(also phylip)',
            projList:[],
            selProj:'',
            treeList:[],
            treeFileOk:false,
            fileTypOptions:['newick(also phylip)','nhx','nexus','phyloXML'],
            inFileTypSel:'newick(also phylip)',
            unknownFile:false,
            unknownFileOptions:['tree file','dataset file'],
            unknownFileSel:'',
            datsetUpload:false,
            datasetNameTF:'',
            cancelUsed : true,
            minWinHt:340,
            minWinWid:700,
            canUpload:false,
            importButtons:[],
            importButtonIconTwo:[],
            importButtonIconThree:[],
            selDatType:'',            
            selIcnBg:'#ccdcff',
            disableUpload:true,
        }
    },
    watch:{
        actTree(val){
            this.activeTree = val
        },
        actProject(val){
            this.activeProject = val
        },
        catPanel(val){
            this.name2catpanel = val
        },
        "$store.state.projList": {
            handler: function(nv) {
                this.projList = nv
            },
            immediate: true // provides initial (not changed yet) state
        },
    },
    created() {
        let that = this
        EventBus.$on('file-props', propsList => {
            that.fileName = propsList[0]
            that.inFileTypSel = propsList[1]
            that.treeUpload = propsList[2]
            that.treeFileOk = propsList[2]
            that.inFileStr = propsList[3]
            that.treeNameTF = that.fileName
            that.canUpload = true
            if(!that.treeUpload){
                that.unknownFile = true
                that.canUpload = false
            }            
        })
    },
    computed:{
        validateProj() {
          var found = false
          var that = this
          for(var ind in this.projList){
            var proj = this.projList[ind]
            if(proj.text == this.projNameTF){
                found = true
                proj.siblings.forEach(element => {
                    that.treeList.push(element.TreeName)
                });
                that.selProj = proj
                // this.loadTreesOftheProj(proj)
                break                    
            }
          }
            if(this.projNameTF != null && this.projNameTF.length<=0){
                found = false
            }            
          return found
        },      
        validateTree() {
          var found = true
            if(this.treeList.includes(this.treeNameTF.trim())){
                found = false
            }
            if(this.treeNameTF.trim().length <=0){
                found = false
            }
          return found
        },
        notEmptyDsName() {
            var found = false
            if(this.datasetNameTF.length>=1){
                found = true

                if(found){
                    this.enableDisableBtnGrp(this.importButtons,false)
                    this.enableDisableBtnGrp(this.importButtonIconTwo,false)
                    this.enableDisableBtnGrp(this.importButtonIconThree,false)
                }
            }
            return found
        },
    },
    mounted() {
        if(localStorage.getItem("usertoken") != null){
            const token = localStorage.getItem("usertoken") 
            const decoded = JSON.parse(token)//jwtDecode
            this.userId = decoded.userId
        }

        var importButtonIcon = new ButtonIconMap()            
        this.butIconMap = importButtonIcon.getIconMap()
        let temDatsetList = this.butIconMap['dataset']
        this.importButtons = [temDatsetList['ibtnDataUploadLabelColor'],temDatsetList['ibtnDataUploadLeafLabelBKColor'],temDatsetList['ibtnDataUploadLeafLabelDecoration'],temDatsetList['ibtnDataUploadGroupLabel'],temDatsetList['ibtnDataUploadLeafGraphics']]
        this.importButtonIconTwo = [temDatsetList['ibtnDataUploadBranchColor'],temDatsetList['ibtnDataUploadBranchAnnotate'],temDatsetList['ibtnDataUploadBranchLabel'],temDatsetList['ibtnDataUploadBranchMarker'],temDatsetList['ibtnDataUploadBootstrapStyles'],temDatsetList['ibtnDataUploadCollapseInternally'],temDatsetList['ibtnDataUploadPiechart'],temDatsetList['ibtnDataUploadTimeLine']]
        this.importButtonIconThree = [temDatsetList['ibtnDataUploadBarPlots'],temDatsetList['ibtnDataUploadHeatmap'],temDatsetList['ibtnDataUploadProteinDomains'],temDatsetList['ibtnDataUploadDotPlots'],temDatsetList['ibtnDataUploadNamedColumnPlot'],temDatsetList['ibtnDataUploadColorStrips'],temDatsetList['ibtnDataUploadGeneSyn'],temDatsetList['ibtnDataUploadGeneTransfer']]

    },
    methods: {
        beforeClose(event){
            // if(!this.cancelUsed){
            //     event.cancel()
            // }
        },
        selFormChange(){
            // console.log('format changed ',this.inFileTypSel)
            this.setModalFormatSel(this.inFileTypSel)
        },
        setModalFormatSel(format){
            // console.log(format)
            for(var ind in this.fileTypOptions){
                var form = this.fileTypOptions[ind]
                if(form.match(format)){
                    this.inFileTypSel = form
                    if(form.startsWith('newick')){
                        this.inFileTypSelForm = 'newick'
                    }else{
                        this.inFileTypSelForm = form
                    }
                }
            }
            this.disableUpload = false
            // console.log(this.inFileTypSelForm)
        },
        btnMouseOver(btn){
            if(!btn.state){
                btn.src = btn.iconActive
                btn.bg = this.selIcnBg

            }
        },
        btnMouseExit(btn){
            if(!btn.state ){
                btn.src = btn.iconNormal
                btn.bg = ''
            }
        },
        importCtrlAction(btn){
            btn.src = btn.iconActive
            btn.state = !btn.state
            if(btn.name === 'ibtnDataUploadPiechart'){
                this.selDatType = MC.PIES
            }else if(btn.name === 'ibtnDataUploadBranchColor'){
                this.selDatType = MC.BRANCHECOLORS
            }else if(btn.name === 'ibtnDataUploadBranchLabel'){
                this.selDatType = MC.BRANCHLABELS
            }else if(btn.name === 'ibtnDataUploadLabelColor'){
                this.selDatType = MC.LEAFLABELCOLORS                
            }else if(btn.name === 'ibtnDataUploadLeafLabelBKColor'){
                this.selDatType = MC.LEAFBKCOLORS
            }else if(btn.name === 'ibtnDataUploadLeafLabelDecoration'){
                this.selDatType = MC.LEAFLABELDECO
            }else if(btn.name === 'ibtnDataUploadTimeLine'){
                this.selDatType = MC.TIMELINE
            }else if(btn.name === 'ibtnDataUploadGroupLabel'){
                this.selDatType = MC.GROUPLABELS                
            }else if(btn.name === 'ibtnDataUploadColorStrips'){
                this.selDatType = MC.STRIPS
            }else if(btn.name === 'ibtnDataUploadBarPlots'){
                this.selDatType = MC.BARS
            }else if(btn.name === 'ibtnDataUploadDotPlots'){
                this.selDatType = MC.DOTPLOTS
            }else if(btn.name === 'ibtnDataUploadHeatmap'){
                this.selDatType = MC.HEATMAP                
            }else if(btn.name === 'ibtnDataUploadProteinDomains'){
                this.selDatType = MC.PROTEINDOMAINS
            }else if(btn.name === 'ibtnDataUploadBootstrapStyles'){
                this.selDatType = MC.BOOTSTRAPSTYLES
            }else if(btn.name === 'ibtnDataUploadCollapseInternally'){
                this.selDatType = MC.INTERNALCOLLAPSES
            }else if(btn.name === 'ibtnDataUploadNamedColumnPlot'){
                this.selDatType = MC.NAMEDCOLUMN
            }else if(btn.name === 'ibtnDataUploadBranchAnnotate'){
                this.selDatType = MC.LEAFANNOTATE
            }else if(btn.name === 'ibtnDataUploadLeafGraphics'){
                this.selDatType = MC.LEAFIMAGE
            }else if(btn.name === 'ibtnDataUploadGeneSyn'){
                this.selDatType = MC.GENESYTNEY
            }else if(btn.name === 'ibtnDataUploadBranchMarker'){
                this.selDatType = MC.BRANCHMARKER
            }else if(btn.name == 'ibtnDataUploadGeneTransfer'){
                this.selDatType = MC.GENETRANSFER
            }
            this.disableUpload = false
            this.resetOthers(btn)
        },
        resetOthers(btn){
            this.resetBtnList(btn,this.importButtons)
            this.resetBtnList(btn,this.importButtonIconTwo)
            this.resetBtnList(btn,this.importButtonIconThree)
        },
        resetBtnList(btn,array){
            for(let ind in array){
                var ar = array[ind]
                if(btn != null && btn.name != ar.name){
                    ar.state = false
                    ar.src = ar.iconNormal                    
                    ar.bg = ''
                }else if(btn == null){
                    ar.state = false
                    ar.src = ar.iconNormal
                    ar.bg = ''
                }                
            }
        },
        enableDisableBtnGrp(btnGrp,bool){
            for(let key in btnGrp){
                var btn = btnGrp[key]
                btn.disabled = bool                
                btn.state = false
                btn.bg = ''
            }
        },
        selInputChange(){
            if(this.unknownFileSel === 'tree file'){
                this.treeUpload = true
                this.treeFileOk = true
                this.datsetUpload = false
            }else{
                this.datsetUpload = true
                this.treeUpload = false
                this.treeFileOk = false
                this.minWinHt = 500                
            }
        },
        uploadFile(){
            var error_msg = ''
            // console.log(this.treeUpload,this.projNameTF,this.treeNameTF,this.inFileTypSelForm)
            if(this.treeUpload){
                var inputs_ok = this.projNameTF.length > 1 && this.treeNameTF.length > 1 && this.treeFileOk && this.inFileTypSelForm.length > 1
                if(inputs_ok){
                    var doesUserHaveRights = Commons.isThisMyTree(this.selProj.userId,this.userId)
                    if(doesUserHaveRights){
                        var isTrueForm = Commons.checkTreeFormat(this.treeNameTF,this.inFileTypSelForm,this.inFileStr)
                        if(isTrueForm.isTreeDataValid()){
                            Db.uploadTheTree(this.server_add,this.projNameTF,this.treeNameTF,this.inFileTypSelForm,this.inFileStr,this.projList,this.treeList)
                            this.resetInputs()
                        }else{
                            error_msg = 'Error with input tree:'+isTrueForm.getErrorMessage()
                        }
                    }else{
                        error_msg = 'You dont have rights to upload tree to this project!!'
                    }
                }else{
                    error_msg ="check inputs like project name/tree name/tree file format type "
                }
            }else{
                var doesUserHaveRights = Commons.isThisMyTree(this.selProj.userId,this.userId)
                var isTruedat = Commons.checkDatasetFormat(this.activeProj,this.activeTree,this.name2catpanel,this.datasetNameTF,this.inFileStr,this.selDatType)
                if(doesUserHaveRights){
                        if(isTruedat != null){
                            if(isTruedat.ds && isTruedat.ds.isDataValid()){
                                var getOrdId = Db.getLatestOrderId(this.server_add,this.activeTree.dbserial)
                                getOrdId.then(ordStat => {
                                    if(ordStat.status == 200){
                                        var orderId = ordStat.data.ord+1                                
                                        var uploadStat = Db.uploadDataSet(this.server_add,orderId,this.activeTree.dbserial,this.datasetNameTF,this.inFileStr,this.selDatType)                        
                                        uploadStat.then(res => {
                                            if(res.status == 200){
                                                Db.resetOtherDatasets(this.server_add,res.data.ID,res.data.DatasetType,this.activeTree.getdbserial())                                
                                                EventBus.$emit('new-dataset', {'ds':res.data})
                                                this.resetInputs()
                                            }else{
                                                error_msg = 'error updating the tree '+res
                                            }
                                        }).catch(err => {
                                            error_msg = 'error updating '+err
                                        })
                                    }else{
                                        error_msg = 'error updating the tree '+ordStat.data
                                    }
                                }).catch(err => {
                                    error_msg = 'error updating '+err
                                })
                            }else{
                                error_msg = 'error with dataset '+isTruedat.ds.getErrorMessage()
                            }
                        }
                }else{
                    error_msg = 'You dont rights to upload dataset to this tree!!'
                }
            }
            if(error_msg.length > 1){
                console.log(this.projNameTF,this.treeNameTF,this.treeFileOk,this.inFileTypSelForm)
                this.showErrorMsg(error_msg)
            }
        },
        resetInputs(){
            this.treeUpload = false
            this.datsetUpload = false
            this.treeNameTF = ''
            this.projNameTF = ''
            this.inFileTypSelForm = ''
            this.inFileStr = ''
            this.datasetNameTF = ''
            this.selDatType = ''
            this.disableUpload = true
            this.resetOthers(null)

            this.enableDisableBtnGrp(this.importButtons,true)
            this.enableDisableBtnGrp(this.importButtonIconTwo,true)
            this.enableDisableBtnGrp(this.importButtonIconThree,true)

            this.$modal.hide('viewFileMod')
        },
        showErrorMsg(errorMsg){
            this.$modal.show('dialog', {
            title: 'Error',
            text: errorMsg,
            buttons: [
              {
                title: 'close',
                handler: () => {
                  this.$modal.hide('dialog')
                }
              }              
            ]
          })
        }
    },
}
</script>