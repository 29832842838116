import StringOps from "../utils/StringOps";
import PlotBase from "./PlotBase";
import treePlotMode from '../treeObjects/treePlotMode'


class BranchMarker extends PlotBase{
    constructor(layer_branch_marker, disable_all_dataset, treeSVG){
        super()
        this.treeSVG = treeSVG
        this.layer_branch_marker = layer_branch_marker
        this.disable_all_dataset = disable_all_dataset
        this.chart_plots_enabled = this.treeSVG.chart_plots_enabled
        this.currentMostRightPosition = this.treeSVG.getLeafMostRightPosition()
        this.plotmode = treeSVG.getPlotMode()
        this.phylotree = treeSVG.getPhyloTree()
        this.BootstrapPositions = this.getBootstrapPositions()
        this.dataset = null
    }

    setDataSet(newID, newdataset) {
        this.dataset = newdataset;
        this.datasetID = newID;
        this.setActive(true);
        this.BootstrapPositions = this.getBootstrapPositions()
        this.row2data = this.dataset.getTreeNodeID2Data(); //
        this.id2originalID = this.dataset.getId2originalID()
        this.datasetContentAsString = newdataset.getOriginal_datastring(); // get original user input as String ;
        this.setLegendEntry(newdataset.getLegend());
        this.pxPerHeight = this.treeSVG.pxPerHeight
        this.space_between_datasets = this.treeSVG.space_between_datasets                                                       
    }

    setRootXY(rootXY){
        this.rootXY = rootXY
    }

    getLeafMostRightPosition(){
        return this.currentMostRightPosition
    }

    setLeafMostRightPosition(currentMostRightPosition){
        this.currentMostRightPosition = currentMostRightPosition
    }

    setPlotMode(plotmode){
        this.plotmode = plotmode
    }

    getAngleSpan(){
        return this.treeSVG.getAngleSpan()
    }

    getBootstrapPositions(){
        return this.treeSVG.getBootstrapPositions()
    }

    getLegend(){
        return this.dataset.getLegend()
    }

    isCircular(){
        return this.treeSVG.plotmode === (treePlotMode.CIRCULAR_CLADOGRAM)
        || this.treeSVG.plotmode === (treePlotMode.CIRCULAR_PHYLOGRAM)
    }

    setBootstrapPositions(BootstrapPositions){
        this.BootstrapPositions = BootstrapPositions
    }

    makeOrUpdatePlot() {
        this.initLayer(this.layer_branch_marker)
        this.BootstrapPositions = this.getBootstrapPositions()
        const groups = this.dataset.getLegendTexts();
        const colors = this.dataset.getLegendColors();
        this.phylotree = this.treeSVG.getPhyloTree()
        this.rootXY = this.treeSVG.getRootXY()
        this.angle_span = this.getAngleSpan()

        if (this.active && !this.disable_all_dataset && this.layer_branch_marker) {
            var isCircular = this.isCircular();
            
            for (var entry in this.BootstrapPositions) {
                const leaf_internal_id = entry;
                var startpos = this.BootstrapPositions[entry].pt;
                var angle = this.BootstrapPositions[entry].angle;
                let bMarker = this.layer.group().id('bMarker');

                if(this.row2data.hasOwnProperty(leaf_internal_id)){
                    // console.log(leaf_internal_id,this.row2data[leaf_internal_id])
                    let rowDat = this.row2data[leaf_internal_id]                    
                    var startX = startpos.x - rowDat.getWidth()
                    var startY = startpos.y - rowDat.getHeight()
                    // if(rowDat.getShape() === 'circle'){
                    //     startX = startX - 2
                    // }                    
                    if(rowDat.isBgImage()){
                        let img = bMarker.image(rowDat.getImgSrc()).size(rowDat.getWidth(),rowDat.getHeight()).move(startX,startY)
                        if(isCircular && angle != 0){
                            img.transform({rotation: 180 - angle, cx: this.rootXY.x, cy:this.rootXY.y})
                        }
                    }else{
                        this.treeSVG.makeAShape(rowDat.getShape(),startX,startY,rowDat.getWidth(),rowDat.getHeight(),rowDat.getBgColor(),rowDat.getStrokeColor(),2,angle,bMarker)
                    }
                }
            }
        }
    }
}

export default BranchMarker