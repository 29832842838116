import DataContainerParserBase from './DataContainerParserBase'
import StringOps from '../utils/StringOps'
import GroupLabelItemAttributes from './GroupLabelItemAttributes'

class GroupLabelDataContainerParser extends DataContainerParserBase{
    constructor(datasetid, datasetContent){
        super()
        this.setDatasetID(datasetid);
        this.setOriginal_datastring(datasetContent);
        this.arGroupLabels = []
        
        /**
		 * sample data: # global parameters !grouplabel
		 * show=1,fontcolor=green,fontitalic=1,fontsize=1,linecolor=green,linewidth=2,linetype=dashed,textalign=begin|end|center
		 * # tab-delimited texts
		 * A,B text=annotation text, color=text_and_line_color, linestyle=linestyle, textorientation=text orientation
		 */
        var lines = StringOps.JsArrayStringToArrayList( StringOps.splitStringByNewLine( datasetContent ) );
        for (var idx = 0; idx < lines.length; idx++) {
			var line = lines[idx].trim();
			if (line.length > 0 && !line.startsWith("#")) { // ignore annotation lines
				var lineparts = 
                StringOps.JsArrayStringToArrayList( StringOps.splitStringByTab( line ) );
																			// be viewed as one
				var part1 = lineparts[0].trim();
				// Modifiers -----------------------------
				if (part1.startsWith("!")) {
					this.parseModifier(lineparts, idx + 1);
				}
				// values ---------------------------------
				else if (lineparts.length >= 2) {
					var item = new GroupLabelItemAttributes();
					this.arGroupLabels.push(item); // add 

					// start and end ids
					var ids = StringOps.JsArrayStringToArrayList( StringOps.splitStringBySeperator(lineparts[0].trim(), ",") ); 
					if (ids.length>=1) {
						item.setStartLeafID(ids[0].trim());
					}
					if (ids.length > 1) {
						item.setEndLeafID(ids[1].trim());
					}

					/**
					 * attributes ; NOTE: global parameters have lower priority
					 * ...
					 */
					var kvs = this.splitKeyValuePairs(lineparts[1]);
					
					// background color;
					item.setBkColor( kvs.hasOwnProperty("backgroundcolor") ? kvs["backgroundcolor"] : 
						kvs.hasOwnProperty("bkcolor") ? kvs["bkcolor"] : this.getGroupLabelBkColor() );
					
					// text / label / grouplabel / annotation 
					item.setGroupLabel( kvs.hasOwnProperty("text") ? kvs["text"] : 
						kvs.hasOwnProperty("label") ? kvs["label"] :
							kvs.hasOwnProperty("annotation") ? kvs["annotation"] :
							kvs.hasOwnProperty("grouplabel") ? kvs["grouplabel"] : "" );
					
					// font color / text color ; if 'linecolor' or 'color' exists, otherwise use default color 
					item.setFontColor(kvs.hasOwnProperty("fontcolor") ? kvs["fontcolor"] : 
						kvs.hasOwnProperty("textcolor") ? kvs["textcolor"] : 
						kvs.hasOwnProperty("color") ? kvs["color"] : this.getGroupLabelFontColor());

					// font italic / text italic 
					var italic = this.isbGroupLabelFontItalic();
					if (kvs.hasOwnProperty("fontitalic") || kvs.hasOwnProperty("textitalic") ) {
						try {
							var text = kvs.hasOwnProperty("fontitalic") ? kvs["fontitalic"] : kvs["textitalic"];
							italic = parseInt( text ) > 0;
						} catch ( e) { // true
						}
					} else {
					}
					item.setFontItalic(italic);

					// font fontsize / text size 
					var fontsize = this.getGrouplabelFontSize();
					if (kvs.hasOwnProperty("fontsize") || kvs.hasOwnProperty("textsize")) {
						try {
							var text = kvs.hasOwnProperty("fontsize") ? kvs["fontsize"] : kvs["textsize"];
							fontsize = parseInt( text );
						} catch ( e) {
						}
					}
					item.setFontSize(fontsize);

					// line color; if 'linecolor' or 'color' exists
					item.setLineColor(kvs.hasOwnProperty("linecolor") ? kvs["linecolor"] : 
						kvs.hasOwnProperty("color") ? kvs["color"] : this
							.getGroupLabelLineColor());

					// line width
					var line_width = this.getGroupLabelLinewidth();
					if (kvs.hasOwnProperty("linewidth")) {
						try {
							line_width = parseFloat(kvs["linewidth"]);
						} catch ( e) {
						}
					}
					item.setLineWidth(line_width);

					// dashed line
					var dashed = this.isbGrouplabelDashedLine();
					if (kvs.hasOwnProperty("linestyle")) {
						dashed = StringOps.equalsIgnoreCase(kvs["linestyle"],"dashed");
					}
					item.setbDashedLine(dashed);

					// text align
					var atextalign = this.getGrouplabelTextAlign();
					if (kvs.hasOwnProperty("textalign") || kvs.hasOwnProperty("fontalign") ) {
						var textalign = kvs.hasOwnProperty("textalign")  ? kvs["textalign"] : kvs["fontalign"];
                        if(StringOps.equalsIgnoreCase(textalign,"middle") || StringOps.equalsIgnoreCase(textalign,"start") || StringOps.equalsIgnoreCase(textalign,"end"))
                         {
							atextalign = textalign;
						}
					} //
					item.setTextAlign(atextalign);
					
					// text orientation
					var textOrientation = this.getGroupLabelTextOrientation();
					if( kvs.hasOwnProperty("textorientation") || kvs.hasOwnProperty("fontorientation") ){
						var s = kvs.hasOwnProperty("textorientation") ? kvs["textorientation"].toLowerCase() : kvs["fontorientation"].toLowerCase();
						if( s === ("vertical") || s === ("horizontal") ){
							textOrientation = s;
						}
					}
					item.setTextOrientation(textOrientation); //
				}
			}
        }// end of for
        
        if (this.arGroupLabels.length <=0) {
			this.setError("error: at least one valid group label data should be given");
		}

		if (!this.isSizesMatchGroupsSizes()) {
			this.setError("error: the numbers of legends and legend colors don't match;");
		}
    }

    getGroupLabels() {
		return this.arGroupLabels;
	}
}

export default GroupLabelDataContainerParser