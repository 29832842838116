import DataContainerParserBase from './DataContainerParserBase'
import StringOps from '../utils/StringOps'
import BootstrapValueStyleAttributesContainer from './BootstrapValueStyleAttributesContainer'

class BootstrapValueStyleDataContainerParser extends DataContainerParserBase{
    constructor(newdatasetid, newdataString) {
        super()

        this.cons = []
        this.ds = new BootstrapValueStyleAttributesContainer();
        this.setDatasetID(newdatasetid);
        this.setOriginal_datastring(newdataString);
        this.setActive(true);
        this.bootstrapValueStyleDataDataParser( newdataString );
    }

	getBootstrapValueStyleAttributesByValue( f ){
    	var hit = this.ds;
    	for( var ind in this.cons ){
			var con = this.cons[ind]
    		if( f >= con.getMinValue() && f <= con.getMaxValue() ){
    			hit = con;
    			break;
    		}
    	}
    	return hit;
	}
	
    bootstrapValueStyleDataDataParser(newdataString) {
		/**
		 * NOTE: modifiers will be processed by the mother class, more specifically, the parseModifier() function ... 
		 * data how ever, will be processed here 
		 * 
		 * the dataset should look like the following:
		 * !bootstrapValueStyle	show=1|0,style=numeric|shape,size=10,place=1|2
		 * value1[:value2]	show=1|0,style=numeric|shape,color=fill_color[:stroke_color],place=1|2,size=10
		 * 
		 */
		var lines = StringOps.JsArrayStringToArrayList( StringOps.splitStringByNewLine( newdataString ) );
		for (var idx = 0; idx < lines.length; idx++) {
            var current_line_index = idx + 1;
			var line = lines[idx].trim();
			// console.log(' check me ',this.datasetID,line)
            if (line.trim().length > 0 && ! line.startsWith("#")) { 
                var lineparts = StringOps.JsArrayStringToArrayList( StringOps.splitStringByTab(line) ); // nov 07, 2011; split by tab"\t" 
				var part1 = lineparts[0].trim();
				// console.log(lineparts,part1)
                // modifiers ---------------------------------------
                if ( part1.startsWith("!")) {
                    this.parseModifier(lineparts, current_line_index);
                } 
                // values ------------------------------------------
                // value1[:value2]	show=1|0,style=numeric|shape,color=fill_color[:stroke_color],place=1|2,size=10
                else if (lineparts.length >= 2) {
                    var newcon = new BootstrapValueStyleAttributesContainer();
					this.cons.push(newcon);
					// console.log(lineparts,newcon)
                    
                    // first, parse the first part to get min and max value
                    // if there are two values, they can be separated by , ~ or : 
                    var min = 0, max = 0;
                    var ps = StringOps.JsArrayStringToArrayList( StringOps.splitStringSpecial1(part1) );
                    if( ps.length >= 1 ){
                    	try{
                    		min = parseFloat(ps[0].trim());
                    		max = min;
                    	} catch( e ){
                    		
                    	}
                    }
                    
                    if( ps.length >= 2 ){
                    	try{
                    		max = parseFloat(ps[1].trim());
                    	} catch( e ){
                    		
                    	}
                    }
                    
                    if( min > max ){
                    	var tmp = min;
                    	min = max;
                    	max = tmp;
                    }
                    
                    newcon.setMinValue(min);
                    newcon.setMaxValue(max);
                    
                    /**
                     * second, split 2nd part
                     * NOTE: here if local variables are NOT set, default values will be taken from global variables  
                     */
					// console.log(lineparts[1])
                    var kvs = this.splitKeyValuePairs( lineparts[1].trim() );
					
                    //show=1|0,style=numeric|shape,size=10,place=1|2,shape=rect,color=fill_color[:stroke_color]
					if (kvs.hasOwnProperty("show")) {
						newcon.setShow( !kvs["show"] === ("0") );
					} else {
						newcon.setShow( this.isShowBootstrapValueStyle() ); // 
					}
					
					// style --
					if (kvs.hasOwnProperty("style")) {
						newcon.setStyle( kvs["style"] );
					} else {
						newcon.setStyle( this.getBootstrapValueStyle() );
					}
					
					// size 
					if (kvs.hasOwnProperty("size")) {
						try {
							var size = parseInt( kvs["size"] );
							newcon.setHeight( size );
							newcon.setWidth( size );
						} catch ( e) {
							newcon.setHeight( this.getBootstrapValueSize() );
							newcon.setWidth( this.getBootstrapValueSize() );
						}
					} else {
						newcon.setHeight( this.getBootstrapValueSize() );
						newcon.setWidth( this.getBootstrapValueSize() );
					}
					
					// place 
					if (kvs.hasOwnProperty("place")) {
						try {
							newcon.setPlace( parseInt( kvs["place"] ) );
						} catch ( e) {
							newcon.setPlace( this.getBootstrapValueShowPlace());
						}
					} else {
						newcon.setPlace( this.getBootstrapValueShowPlace());
					}
					
					// fill_color and stroke_color
					if( kvs.hasOwnProperty("color") ){
						var es = StringOps.JsArrayStringToArrayList(
                            StringOps.splitStringBySeperator( kvs["color"] , ":"));
						
						var length = es.length;
						if( length >= 1 ){
							newcon.setColor( es[0].trim() );
						}
						if( length >= 2 ){
							newcon.setStrokeColor( es[1].trim() );
						}
					} else {
						newcon.setColor( this.getBootstrapValueColor() );
						newcon.setStrokeColor( this.getDefaultStrokeColor() );
					}
					
					// strokewidth -
					if (kvs.hasOwnProperty("strokewidth")) {
						try {
							newcon.setStrokeWidth(parseFloat( kvs["strokewidth"] ) );
						} catch ( e) {
							newcon.setStrokeWidth( this.getDefaultStrokeWidth() );
						}
					} else {
						newcon.setStrokeWidth( this.getDefaultStrokeWidth());
					}
					
					// bootstrap value replace with; Feb 5, 2018;  
					if( kvs.hasOwnProperty("replacewith") ) {
						var replacewith = kvs["replacewith"].trim();
						if( replacewith.length>=1 ) {
							newcon.setReplacedWithSet(true);
							newcon.setReplaceWithString(replacewith);
						}
					}
					
                }
            } // end of if line isn't empty
        } // end of for loop iterate string fragments split from pcdatastring
		
		/**
		 * at the end, copy global style from mother class 
		 */
		this.ds.setShow( this.isShowBootstrapValueStyle() );
		this.ds.setPlace( this.getBootstrapValueShowPlace() );
		this.ds.setStyle( this.getBootstrapValueStyle() );
		this.ds.setHeight( this.getBootstrapValueSize() );
		this.ds.setWidth( this.getBootstrapValueSize() );
		this.ds.setColor( this.getBootstrapValueColor() );
		this.ds.setStrokeColor( this.getDefaultStrokeColor() );
		this.ds.setStrokeWidth( this.getDefaultStrokeWidth() );
		
	}
    
}

export default BootstrapValueStyleDataContainerParser