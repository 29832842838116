<template>
    <!-- <modal id="loadingMod" name="loadingMod"        
        :draggable="true" 
        :adaptive="true" 
        :width="loadingModWidth"
        :height="80"
        :clickToClose="false"          
        >
            <b-card no-body>
                <b-card-body>
                    <div class="text-center">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>&nbsp;{{loadMessage}}</strong>
                    </div>                    
                </b-card-body>                
            </b-card>
        </modal> -->
        <modal id="loadingMod" name="loadingMod"        
        :draggable="true" :adaptive="true" :width="loadingModWidth"
         :height="95"          
        >
            <b-card no-body>
                <b-card-body>
                    <div class="text-center" v-show="showLoading">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>&nbsp;Loading...</strong>
                    </div>
                    <div class="text-center" v-show="showShareLink">
                        <span v-if="linkShareMode === 'Share'">
                        To view the shared tree,
                        <router-link :to="{ path: '/shared/'+shareLinkName}" target="_blank">Click here</router-link>..
                        </span>
                        <span v-else>
                            Embed tree link:
                            <b-input-group>
                                <b-button variant="outline" v-clipboard:copy="embedPath"
      v-clipboard:success="onCopy"><font-awesome-icon icon="clipboard"></font-awesome-icon></b-button>
                                <input class="form-control" id="selectCategory" v-model="embedPath" :readonly="true">
                            </b-input-group>
                        </span>
                    </div>
                </b-card-body>
            </b-card>
        </modal>
</template>

<script>
import EventBus from './EventBus'

export default {
    data() {
        return {
            loadingModWidth:350,
            showLoading:false,
            loadMessage:'Loading...',
            showShareLink:false,
            shareLinkName:'',
            linkShareMode:'',
            embedPath:'',
        }
    },
    methods: {
        onCopy(e){
        this.$bvToast.toast('URL Copied to clipboard', {
                title: 'Copy',
                autoHideDelay: 5000,
                variant:"success",
                solid: true
            })
        }
    },
    created() {
        let that = this
        EventBus.$on('loadMess', modProps => {
            that.loadMessage = modProps
            that.showLoading = true
            that.$modal.show("loadingMod")
        })
        EventBus.$on('closeLoadWin',prop => {
            that.$modal.hide("loadingMod")
        })
        EventBus.$on('loadModProps',props=>{
            if(props[0] == 0){
                that.showLoading = true
                that.$modal.show("loadingMod")
            }else{
                that.showLoading = false
                that.showShareLink = props[2]
                that.shareLinkName = props[4]
                that.linkShareMode = props[3]
                that.embedPath = props[6]
            }            
        })
    },
}
</script>