import DataContainerParserBase from './DataContainerParserBase'
import StringOps from '../utils/StringOps'
import ColumnAttributesContainerParser from './ColumnAttributesContainerParser'
import ColumnPlotsColumnDataContainer from './ColumnPlotsColumnDataContainer'

class ColumnPlotsDataContainerParser extends DataContainerParserBase{
    constructor(newdatasetid, newdataString){
        super()

        this.globalparameters = null
        this.dconp = []

        this.setDatasetID(newdatasetid);
		this.setActive(true);
		this.setOriginal_datastring(newdataString);
		
		/**
		 * set some global variables
		 */
		this.setShowGridLabel(true);
		
		this.columnPlotsDataParser( newdataString );
    }

    getparsedData(){
    	return this.dconp;
    }

    columnPlotsDataParser( newdataString  ) {
		/**
		 * split input data into lines
		 */
		var lines = StringOps.JsArrayStringToArrayList( StringOps.splitStringByNewLine( newdataString ) );
		var columnCon = null; 
		
		/**
		 * go through the data line by line 
		 */
		for (var idx = 0; idx < lines.length; idx++) {
			var current_line_index = idx + 1;
			var line = lines[idx].trim();
			
			/**
			 * if line is not empty nor annotation line 
			 */
			if (line.length > 0 && !line.startsWith("#")) {
				
				/**
				 * split current line by TAB char 
				 */
				var lineparts =
                StringOps.JsArrayStringToArrayList( StringOps.splitStringByTab( line ) );
				var part1 = lineparts[0].trim();
				/**
				 * deal with modifiers ---------------------------------------
				 * From now on, some dataset specific modifiers will be dealt by this class
				 *  in order to reduce the complexity of the inherited class;
				 *  however, for now this no class-specific modifiers
				 *  
				 * Supported modifiers are (already implemented in the inherited class: 
				 *  + !defaultstrokecolor or !strokecolordefault or !strokecolor
				 *  + !defaultstrokewidth or !defaultstroke-width or !strokewidth
				 *  + !columnLabelStyle		show=1,fontsize=14,fontcolor=black, fontitalic=1,fontbold=1
				 *  
				 * Data format: a column should have the following contents:
				 * 
				 * !columnplot	
				 * ## a column should start with namedcolumn=column_name
				 * namedcolumn=my first column,show=1,fontsize=14,color=fillcolor:strokecolor,fontitalic=1,
				 * fontbold=1,textangle=-60,width=14,strokewidth=2,spacebetweencolumns=2,style=rect,roundedcorner=0
				 * 
				 * ## followed by the following seqments
				 * 		from=species1,to=species2,color=fillcolor:strokecolor,width=14,style=rect,roundedcorner=0,label=my label,labelangle=-90,strokewidth=2
				 * 		from=species1,to=species2,,color=fillcolor:strokecolor,width=14,style=rect,roundedcorner=0,label=my label,labelangle=-90,strokewidth=2
				 * 
				 */
				if (part1.startsWith("!")) {
					if( ( StringOps.equalsIgnoreCase(part1,"!columnplot")  ||  StringOps.equalsIgnoreCase(part1,"!columnplots")) && lineparts.length >= 2 ){
						this.globalparameters = new ColumnAttributesContainerParser( this.splitKeyValuePairs( lineparts[1].trim() ), null );
					} else {
						this.parseModifier(lineparts, current_line_index);
					}
				}
				// values ------------------------------------------
				else {
										
					/**
					 * deal with data 
					 */
					var kvs = this.splitKeyValuePairs( part1 );
					if( StringOps.size(kvs) > 0 ){
						/**
						 * note: columnPlotsColumnAttributesContainerParser will be resposible for parsing the string 
						 */
						if( part1.startsWith("namedcolumn") ){
							// create a new column, and add it to columns 
							var columnCon = new ColumnPlotsColumnDataContainer();  
							this.dconp.push(columnCon); // add current column to columns
							
							// part column name style and add to the column  
							var colNameAttrCon = new ColumnAttributesContainerParser( kvs, this.globalparameters );
							columnCon.setColumNameData( colNameAttrCon );							 
						} else if ( columnCon != null ) {
							// make a fragment for curent column, and add it 
							var dataAttrCon = new ColumnAttributesContainerParser( kvs, columnCon.getColumnNameData() );
							columnCon.addFragment( dataAttrCon );
						}
					} // end of kvs is not empty ....
				} // if part1 is modifier or not ...
			} // end of if line isn't empty
		} // iterate string fragments split from pcdatastring

		/*
		 * check if there is any valid data
		 */
		if ( this.dconp.length <=0 ) {
			this.setError("no valid data parsed, please check your input!!");
			this.setValid(false);
		} // if treenodeid2data empty
	}
}

export default ColumnPlotsDataContainerParser