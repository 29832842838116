import DataContainerParserBase from './DataContainerParserBase'
import BranchMarkerAttribute from './BranchMarkerAttribute'
import StringOps from '../utils/StringOps'


class BranchMarkerDataContainerParser extends DataContainerParserBase{
    constructor(datasetid, datasetContent, phylotree){
        super()

        if(datasetid.length >=1 && datasetContent.length >=1){
            this.setDatasetID(datasetid);
            this.setOriginal_datastring(datasetContent);
        }

        this.colorsets = []
        this.id2originalID = {}
        this.row2data = {}
        let groupDefMap = {}

        var lines = StringOps.JsArrayStringToArrayList( StringOps.splitStringByNewLine( datasetContent ) );
        for (var idx = 0; idx < lines.length; idx++) {
            var line = lines[idx];
            if (line.trim().length > 0 && ! line.startsWith("#")) { // March 22, 2011; ignore annotation lines
                var lineparts = StringOps.JsArrayStringToArrayList( StringOps.splitStringByTab( line ) );
                var part1 = lineparts[0].trim();
                // console.log(part1)
                // Modifiers -----------------------------
                if( StringOps.equalsIgnoreCase(part1,"!groups_class")){
                    let item_def = this.getBranchProp(lineparts[1])                    
                    if(item_def.getDefId().length >=1){
                        groupDefMap[item_def.getDefId()] = item_def
                    }
                }else if ( part1.startsWith("!")) {
                    this.parseModifier(lineparts, idx + 1);
                }
                //values ---------------------------------
                else if (lineparts.length >= 2) {
                    var part2 = lineparts[1].trim();
                    var temp = part2.split(",")
                    let branch_def = null
                    // console.log(part2,temp.length,temp,groupDefMap.hasOwnProperty(part2),Object.keys(groupDefMap))
                    if(temp.length>=2){ //has label def here itself
                        branch_def = this.getBranchProp(part2)
                    }else{ //uses group label def
                        if(groupDefMap.hasOwnProperty(part2)){
                            branch_def = groupDefMap[part2]
                        }else{
                            //group def not found
                            this.valid = false
                            this.setError("error at line "+idx+" ,found no group_class id")
                        }
                    }
                    if(branch_def != null){
                        var internal_id = '';
                        var ids = StringOps.JsArrayStringToArrayList( StringOps.splitStringBySeperator(part1, ","));
                        var lca = phylotree.getLCA(ids);
                        if (lca != null) {
                            internal_id = lca.getInternalID();
                            // console.log(part1,internal_id,lca.getParent().getInternalID())
                        }

                        if (internal_id != null) {                    	
                            this.id2originalID[internal_id] =  ids;
                            this.row2data[internal_id] =  branch_def;
                        }
                    }
                }
                if(lineparts.length>=4){
                    this.hasStroke = true
                }
            }
        }

        if (Object.keys(this.row2data).length <=0) {
            this.valid = false
            this.setError("error: at least one valid colorset data should be given");
        }
 
        if (!this.isSizesMatchGroupsSizes()) {
             this.valid = false
             this.setError("error: the numbers of legends and legend colors don't match;"); // TODO: ciculate colors if there are not enough colors
        }
    }

    getBranchProp(def_str){
        let branchMarker = new BranchMarkerAttribute()
        var kvs = this.splitKeyValuePairs(def_str)

        branchMarker.setId(kvs['id'])
        branchMarker.setShape(kvs.hasOwnProperty("shape") ? kvs["shape"] : 'star')
        branchMarker.setBgColor( kvs.hasOwnProperty("bkcolor") ? kvs["bkcolor"] : 'white')
        branchMarker.setFillBg(kvs.hasOwnProperty("fillbg") ? parseInt(kvs['fillbg']) == 1 : false)
        branchMarker.setStrokeColor(kvs.hasOwnProperty("linecolor") ? kvs["linecolor"] : 
            kvs.hasOwnProperty("color") ? kvs["color"] : 'black');
        branchMarker.setLineType(kvs.hasOwnProperty("dashline") ? parseInt(kvs['dashline']) == 1 : false)
        branchMarker.setImgSrc(kvs.hasOwnProperty('src') ? kvs['src'] : '')
        if(kvs.hasOwnProperty('src')){
            branchMarker.setBgImg(true)
        }
        branchMarker.setWidth(kvs.hasOwnProperty('width') ? kvs['width'] : 20)
        branchMarker.setHeight(kvs.hasOwnProperty('height') ? kvs['height'] : 20)

        return branchMarker
    }

    getTreeNodeID2Data() {
        return this.row2data; // note ID is internal ID
    }

    getId2originalID(){
        return this.id2originalID
    }

    getColorsets() {
        return this.colorsets;
    }
}

export default BranchMarkerDataContainerParser