const ignoreCase = require('ignore-case');

class StringOps{

    static isEmpty(str){
        if(str.length>=1) return false
        return true
    }

    static hasSeperatorInfo(lines){
        for (var idx = 0; idx < lines.length; idx++) {
            var line = lines[idx].trim();
            if (line.trim().length > 0 && (line.startsWith("!seperator") || line.startsWith("!Seperator"))) {
                return line.split("=")[1].trim()
            }
        }
        return null
    }

    static splitStringByNewLine(string) {
		return string.split(/[\n\r]+/)
	}

	// by tab-, white-space ect
	static splitStringBySpace( string) {
		return string.split(/\s+/)
	}

	// split by some special characters:   , : ~
	static splitStringSpecial1( string) {
		return string.split(/[,:~]/)
	}

	// tab
	static splitStringByTab( string , seperator = "\t") {
        if(seperator == "\t"){
            return this.splitStringByTabDefault(string)
        }else if(seperator == "|"){
            return string.split("|")
        }else if(seperator == "$"){
            return string.split("$")
        }else{
            return this.splitStringByTabDefault(string)
        }
	}

    static splitStringByTabDefault(string){
        return string.split(/\t+/)
    }

	static splitStringBySeperator( string,  separator) {
		return string.split(separator)
    }
    
    static JsRemoveNewLines(string) {
		return string.replace(/[\r\n]+/gi, "");
    }
    
    static splitStringByEqualSignIgnoreHTMLTags( string) {
		return string.split(/=(?=[^>]*(?:<|$))/)
	}

	static JsArrayStringToArrayList(jsarray) {
		var arstring = [];
		for (var idx = 0; idx < jsarray.length; idx++) {
			arstring.push(jsarray[idx]);
		}
		return arstring;
    }
    
    static JsReplaceSpacesAndIllegalCharactersWithSingleSpaceChar(string) {
		return string.replace(/[ "']+/gi, " ");
    }
    
    static toHexString(number){
        return '\\x0' + number.toString(16);
    }

    static equalsIgnoreCase(str1,str2){
        return ignoreCase.equals(str1,str2)
    }

    static size(obj) {
        var size = 0, key;
        for (key in obj) {
            if (obj.hasOwnProperty(key)) size++;
        }
        return size;
    }

    static joinStringBySeparator(obj, separator) {
        var sb = [];
        for (var i = 0; i < obj.length; i++) {
            if (i > 0) {
                sb.push(separator);
            }
            sb.push(obj[i]);
        }

        return sb.join("");
    }

    static NiceNumber(range,round) {

        var NiceFraction;

        var Exponent = Math.floor(Math.log10(range));
        var Fraction = range / Math.pow(10, Exponent);

        if (round > 0) {

            if (Fraction < 1.5) {
                NiceFraction = 1.0;
            } else if (Fraction < 3.0) {
                NiceFraction = 2.0;
            } else if (Fraction < 7.0) {
                NiceFraction = 5.0;
            } else {
                NiceFraction = 10.0;
            }

        } else {

            if (Fraction <= 1.0) {
                NiceFraction = 1.0;
            } else if (Fraction <= 2.0) {
                NiceFraction = 2.0;
            } else if (Fraction <= 5.0) {
                NiceFraction = 5.0;
            } else {
                NiceFraction = 10.0;
            }

        }// if round

        return NiceFraction * Math.pow(10,Exponent);

    }

    static AxisStartEndStep(axisstart,axisend, NumTicks) {

        var ticks = [];

        var width = axisend - axisstart;
        if (width > 0) {

            /* Compute the new nice range and ticks */
            var NiceRange = StringOps.NiceNumber(axisend - axisstart, 0);
            var NiceTick = StringOps.NiceNumber(NiceRange / (NumTicks - 1), 1);

            /* Compute the new nice start and end values */
            var NewAxisStart = Math.floor(axisstart / NiceTick) * NiceTick;
            var NewAxisEnd = Math.ceil(axisend / NiceTick) * NiceTick;

            var step = (NewAxisEnd - NewAxisStart) / NumTicks;

            ticks.push(NewAxisStart);
            ticks.push(NewAxisEnd);
            ticks.push(step);
        }

        return ticks;

    }
}

export default StringOps