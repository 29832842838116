import Point from '../treePainter/Point'
import EventBus from '../components/EventBus'
import SVG  from 'svg.js'
import Drag from './Drag.js'
const _ = require("underscore")
const ignoreCase = require('ignore-case');
import PyTree from '../treeParser/PyTree'
const clone = require('lodash.clonedeep')
import treePlotMode from '../treeObjects/treePlotMode'
import StringOps from '../utils/StringOps'
import Shapes from './Shapes'
import ColorUtils from '../utils/ColorUtils'
import RgbaColor from '../utils/RgbaColor'
import jwtDecode from 'jwt-decode'
import TreeDecoType from '../treeObjects/TreeDecoType'

import axios from 'axios'
import $ from 'jquery'

import Pies from './Pies'
import Bars from './Bars'
import ColumnPlots from './ColumnPlots'
import DotPlots from './DotPlots'
import Heatmap from './Heatmap'
import GroupLabelPlot from './GroupLabelPlot'
import ColorStrips from './ColorStrips'
import ProteinDomains from './ProteinDomains'
import LeafAnnotation from './LeafAnnotation'
// import LabelImage from './LabelImage'
import GeneSynteny from './GeneSynteny'
import GeneTransferPlot from './GeneTransferPlot'
import BranchMarker from './BranchMarker'

const FontWeight = {
'NORMAL':'normal',
'BOLD':'bold',
'BOLDER':'bolder',
'LIGHTER':'lighter',
}
Object.freeze(FontWeight)

const FontStyle = {
'NORMAL':'normal',
'ITALIC':'italic',
'OBLIQUE':'oblique',
}
Object.freeze(FontStyle)

const Unit = {
  'PX':'px',
  'PCT':'%',
  'EM':'em',
}
Object.freeze(Unit)

const promode = {
  'API':'API',
  'LIVE':'LIVE',
  'SHARING':'SHARING',
}
Object.freeze(Unit)

class TreeSVG {
  constructor (svgDom,svg, winWidth, winHeight, server_add, promode,canType) {
    this.svgDom = svgDom
    this.svg = svg
    this.winHeight = winHeight
    this.winWidth = winWidth
    this.canType = canType
    this.canWidth = 800
    this.canHeight = 600
    
    this.showBrachLength = false
    this.showNodeLabel = true
    this.showBootStrap = false
    this.draw_branch_length_scale = false
    this.circular_tree_plot_in_clockwise_mode = true,

    this.disable_all_dataset = false
    this.chart_plots_enabled = true
    
    this.bMouseWheelEnabled = false

    this.leaf_font_italic = false,
    this.bootStrapFontItalic = false
    this.branchLengthFontItalic = false
    this.showLineFromChart2Leaflabel = false
    this.bHTMLmode = false
    this.bMegaMode = false
    this.bootstrapValueStyleEnabled = false
    this.branchLabelEnabled = false
    this.activeBranchLabelID = ''
    this.activeBranchAnnotateID = ''
    this.leafAnnEnabled = false
    this.activeBranchMarkID = ''
    this.labelImagesEnabled = false
    this.activeImageLabelId = ''
    this.geneTransferEnabled = false
    this.activeGeneTransferID = ''

    this.pxPerWidthDefault = 20
    this.pxPerHeight = 20
    this.pxPerWidthCurrentMode = 0
    this.pxPerBranchLengthScaling = 5, 
    this.pxPerBranchLength = this.pxPerWidthDefault * this.pxPerBranchLengthScaling //not used in cladograms
    this.margin_x = 200
    this.margin_y = 80

    this.default_line_width = 1
    this.sel_line_width = 2
    this.default_branchcolor = "black"

    this.angle_per_vertical_level = 0, // Oct 27, 2011: this should be float, instead of int
    this.angle_start = 0 
    this.angle_span = 350
    this.scale_tree_branch_linewidth = 1.5 // Nov 07, 2011;
      
    this.treeMostRightPosition = 0
    this.leafMostRightPosition = 0

    this.line_width_align_leaf_labels = 0.3
    this.line_width_line2leaf_label = 0.3
    this.font_width_height_ratio = 0.55
    this.margin_top = 10
    this.margin_left = 0;

    // for CIRCULAR mode;
    this.max_angle_span = 360
    this.min_angle_span = 20;

    this.currentMostRightPosition = 0
    this.default_font_size = 14
    this.default_bootstrap_font_size = 10
    this.default_branchlength_font_size = 10
    this.default_tick_label_font_size = 10
    
    this.default_font_family = "Arial"
    this.space_between_datasets = 12
    this.space_between_columns_within_a_dataset = 8, // for multicolumn plot
		this.space_between_treeskeleton_and_leaflabels = 5

    this.svg_translate_x = 0
    this.svg_translate_y = 0
    this.svg_scale = 1
    this.leg_scale = 1
    
    this.hmPlotMode2pxPerWidth = {}
    this.hmPlotMode2TranslateX = {}
    this.hmPlotMode2TranslateY = {}

    this.default_font_size = 14
    this.default_bootstrap_font_size = 10
    this.default_branchlength_font_size = 10
		this.default_tick_label_font_size = 10
    this.default_font_family = "Arial";    

    this.pyTree = ''
    this.rootnode = ''
    this.treeID = "", this.projectID = "" , this.db_serialID = 0

    this.pie = null

    this.LeafInternalID2NodePosisionts = {}
    this.leafIDtoInternalID = {}
    this.leafInternalID2LeafTextLabelOMNodeObj = {}
    this.hmLeafID2NodePositions = {}
    this.hmLeafInternalID2LeafPath = {}
    this.hmLeafInternalID2LeafPathStyles = {}
    this.BranchLengthPositions = {}
    this.branchID2OMNode = {}
    this.BootstrapPositions = {}
    this.leafID2LeafAlignOMNode = {}
    this.alPathStyles = ["fill", "stroke", "stroke-width", "stroke-dasharray"]

    this.legend_start_pos_x = 65
    this.legend_start_pos_y = 65
    this.branchlength_scale_y1 = 70
    this.default_legend_font_size = 12
    this.legend_current_pos_y = this.legend_start_pos_y
    this.space_between_legends = 10
    this.space_between_legend_and_text = 5
    this.space_between_legend_items = 5
    this.plot_width_per_col = 20

    this.activeCharts = []
    this.leafAnnMap = {}
    this.geneTransferMap = {}
    this.imageLabelMap = {}
    this.branchMarkMap = {}
    this.charts = {}
    this.datasetID2dataContainerObj = {}
    this.dataset2opacity = {}
    this.datasetID2DecoCon = {}
    this.datasetID2LfImgCon = {}

    let def_pt = 'image/labelGraphics/'
    this.def_img_ar = [def_pt+'cow.png',def_pt+'pig.png',def_pt+'dolphin.png',def_pt+'fish.png',def_pt+'plant.png',def_pt+'camel.png']
    
    this.treeCurveStyle = 0
    this.curvePixel = 4

    this.plotmode = treePlotMode.RECT_CLADOGRAM;
    this.promode = promode
    this.rootXY = {'x':-1,'y':-1}
    this.sPxPerWidth = "horizScale4PlotMode"
    this.sTranslateX = "translateX4PlotMode"
    this.sTranslateY = "translateY4PlotMode"

    this.leafColorEnabled = false
    this.branchColorEnabled = false
    this.branchMarkEnabled = false

    this.collapseInternalEnabled = false

    this.mouse_pos = {'x':0,'y':0}
    this.mouse_moved = {'x':0,'y':0}
    this.dragging = false, // mouse left button down and drag
    this.moveTree = true,
    this.moveLegend = false
		this.resizable = false, 
		this.mousedown = false, 
    this.resizing = false;
    this.mouse_client_x = 0, 
    this.mouse_client_y = 0
    this.scale_factor = 0.9

    this.bBox = null
    
    this.server_add = server_add
    this.data_loaded = false

    this.loadUserDet()
    this.createSVG()
    this.iniDatasetObj()
    // this.treeViewType = "RECT_CLADOGRAM"
    // this.drawTreeSkeleton()
  }

  loadUserDet(){
    if(localStorage.getItem("usertoken") != null){
      const token = localStorage.getItem("usertoken")
      const decoded = JSON.parse(token)//jwtDecode
      this.userId = decoded.userId
    }else{

    }
  }

  iniDatasetObj(){
    this.pie = new Pies(this.disable_all_dataset,this.rootXY,this.layer_tree,this)
  }

  loadTree(curTree,proID){
    this.pyTree = new PyTree(curTree.treeName,curTree.treeFormat,curTree.treeContentString)
    this.activeTree = curTree
    this.projectID = proID;
    this.treeID = curTree.dbserial;
    this.loadTreeViewParams()
    this.drawPhyloTreeUsingSVG()
    this.pie.loadTreeData(this.pyTree,this.BranchLengthPositions)
    this.data_loaded = true
  }

  getProjectID(){
    return this.projectID
  }

  getTreeID(){
    return this.treeID
  }

  getPlotMode(){
    return this.plotmode
  }

  getPhyloTree(){
    return this.pyTree
  }

  createSVG(){
    this.svgContainer = this.svg.size(this.winWidth, this.winHeight).viewbox(0, 0, this.canWidth, this.canHeight)    
    this.defs = this.svgContainer.defs()
    this.main_layer = this.svgContainer.group().id('main_tree')
    this.drag = new Drag(this.main_layer)
    // this.svgLayer = this.svgContainer.group().id('main_layer')
    // this.svgLayer.text('legends').move(10,10)
    this.layer_legend = this.svgContainer.group().id('layer_legend')
    this.layer_tree = this.main_layer.group().id('layer_tree')
    this.layer_scale_branch_length = this.svgContainer.group().id('layer_branch_length_scale')
    this.layer_scale_branch_length.attr('width',this.default_line_width)
    this.layer_scale_branch_length.attr('stroke','black')
    this.inv_rect = this.layer_tree.rect(10,10)
    this.inv_rect.attr("fill-opacity", '0');

    this.layer_tree_leaflabel_background = this.layer_tree.group().id('layer_tree_leaflabel_background')
    this.layer_tree_leaflabel_decoration = this.layer_tree.group().id('layer_tree_leaflabel_decoration')
    this.layer_tree_branchlength = this.layer_tree.group().id('tree_branchlength')
    this.layer_tree_bootstrap = this.layer_tree.group().id('tree_bootstrap')    
    this.layer_tree_charts = this.layer_tree.group().id('layer_tree_charts')
    this.layer_tree_timeline = this.layer_tree.group().id('layer_tree_timeline')
    this.layer_tree_skeleton = this.layer_tree.group().id('tree_skeleton')
    this.layer_tree_leaf_labels = this.layer_tree.group().id('tree_leaf_labels')
    this.layer_tree_align_leaf_labels = this.layer_tree.group().id('tree_align_leaf_labels')
    this.layer_branchLabel = this.layer_tree.group().id('layer_branchlabel')
    this.layer_leafAnnotate = this.layer_tree.group().id("layer_leafAnnotate")
    this.layer_imageLabel = this.layer_tree.group().id("layer_labelImages")
    this.layer_branchMarker = this.layer_tree.group().id('layer_branchMarker')
    this.layer_geneTransfer = this.layer_tree.group().id('layer_geneTransfer')

    this.layer_tree_line2leaf_label = this.layer_tree.group().id('layer_tree_line2leaf_label')
    this.layer_tree_line2leaf_label.attr("stroke", "grey");
		this.layer_tree_line2leaf_label.attr("stroke-width", this.line_width_line2leaf_label + "");
    this.layer_tree_line2leaf_label.attr("stroke-dasharray", "4,4");
    
    this.tp_layer = this.svgContainer.group().id('tootip')
    var that = this
    
    this.svgContainer.mousedown(function(evt) {
      that.mousedown = true
      that.dragging = true
      that.drag.start_drag(evt)
      evt.preventDefault()
    })

    this.svgContainer.mousemove(function(evt) {
      if(that.dragging){          
          that.svgContainer.attr("cursor","all-scroll")
          that.drag.drag(evt)
      }
      evt.preventDefault()
    })

    this.svgContainer.mouseup(function(evt) {      
      that.svgContainer.attr("cursor","default")
      var end_p = that.drag.end_drag(evt)
      var tx = that.main_layer.transform().e;
      var ty = that.main_layer.transform().f;
      var cx = that.main_layer.transform().a;
      var cy = that.main_layer.transform().d;
      var x = that.layer_tree.transform().x;
      var y = that.layer_tree.transform().y;

      // 1. keep tracking rootxy in a hash
      that.hmPlotMode2TranslateX[that.plotmode] = (tx / cx)+x;
      that.hmPlotMode2TranslateY[that.plotmode] = (ty / cy)+y;

      // 2. keep tracking rootxy on the server
      that.updateTreeCanvasInfoOnServer(that.sTranslateX + that.plotmode, (tx / cx)+x);
      that.updateTreeCanvasInfoOnServer(that.sTranslateY + that.plotmode, (ty / cy)+y);
      
      // console.log(end_p,tx / cx,ty / cy)
      that.mousedown = false;
      that.dragging = false
      evt.preventDefault()
    })

    this.svgContainer.mouseleave(function(evt) {
      that.svgContainer.attr("cursor","default")
      that.mousedown = false;

      if (that.dragging) {
        if(that.moveTree){
          var end_p = that.drag.end_drag(evt)
          var tx = that.main_layer.transform().e;
          var ty = that.main_layer.transform().f;
          var cx = that.main_layer.transform().a;
          var cy = that.main_layer.transform().d;
          var x = that.layer_tree.transform().x;
          var y = that.layer_tree.transform().y;

          // 1. keep tracking rootxy in a hash
          that.hmPlotMode2TranslateX[that.plotmode] = (tx / cx)+x;
          that.hmPlotMode2TranslateY[that.plotmode] = (ty / cy)+y;

          // 2. keep tracking rootxy on the server
          that.updateTreeCanvasInfoOnServer(that.sTranslateX + that.plotmode, (tx / cx)+x);
          that.updateTreeCanvasInfoOnServer(that.sTranslateY + that.plotmode, (ty / cy)+y);

          // console.log(end_p,tx / cx,ty / cy)

        }else if(that.moveLegend){
          var tx = that.layer_legend.transform().e;
          var ty = that.layer_legend.transform().f;
          var cx = that.layer_legend.transform().a;
          var cy = that.layer_legend.transform().d;

          // 2. keep tracking rootxy on the server
          that.updateTreeCanvasInfoOnServer('leg_'+that.sTranslateX + that.plotmode, tx / cx);
          that.updateTreeCanvasInfoOnServer('leg_'+that.sTranslateY + that.plotmode, ty / cy);
        }
      } else {
        // that.updateTreeCanvasInfoOnServer("canvasWidth", canvas_width);
        // that.updateTreeCanvasInfoOnServer("canvasHeight", canvas_height);
      }

      that.dragging = false

      evt.preventDefault()
    })

    // this.setSvgSizeStyles()
  }

  isCursorResize( mouse_x,  mouse_y,  offsetWidth,  offsetHeight) {
		let margin = 20;
		var edge_r = (mouse_x >= (offsetWidth - margin) && mouse_x < offsetWidth) ? true : false;
		var edge_b = (mouse_y >= (offsetHeight - margin) && mouse_y < offsetHeight) ? true : false;
    // console.log(edge_r,edge_b,mouse_x,  mouse_y,offsetWidth,offsetHeight)
		if (edge_r && edge_b) {
			this.svgContainer.attr("cursor", "se-resize");
		}
		return edge_r && edge_b;
	}

  fitCanvas2PlotWidth() {
		var bBox = this.svgContainer.bbox();
    var width = Math.round((bBox.x + bBox.width) + this.margin_x);
    // console.log(width,bBox.x , bBox.width,this.canWidth,this.winWidth)
		if (this.canWidth != width ) { //&& width >= this.canWidth
      this.canWidth = width;
      this.winWidth = width;

			this.svgContainer.viewbox(0, 0, this.canWidth, this.canHeight);
      this.svgContainer.width(this.canWidth)
      EventBus.$emit('svg-dim',{'width':this.canWidth,'height': this.canHeight})
      // this.svgContainer.attr('width',this.canWidth)
      // svg.getStyle().setWidth(canvas_width, Unit.PX);

			this.updateTreeCanvasInfoOnServer("canvasWidth", this.canWidth);
    }
    this.bBox = bBox
  }
  
  getWinWidth(){
    return this.canWidth
  }

  getWinHeight(){
    return this.canHeight
  }

  setSvgSizeStyles(){
    this.svgContainer.attr('width',this.canWidth)
    this.svgContainer.attr('height',this.canHeight)
  }

  setSvgWidthStyle(){
    this.svgContainer.attr('width',this.canWidth)
  }

  setSVGHeightStyle(){
    this.svgContainer.attr('height',this.canHeight)
  }

	fitCanvas2PlotHeight() {
		var bBox = this.svgContainer.bbox();
		var height = Math.round((bBox.y + bBox.height) + this.margin_y);
		if (this.canHeight != height ) { //&& height >= this.canHeight
			this.winHeight = height
			this.canHeight = height;

			this.svgContainer.viewbox(0, 0, this.canWidth, this.canHeight);
      this.svgContainer.height(this.canHeight)
      EventBus.$emit('svg-dim',{'width':this.canWidth,'height': this.canHeight})

			this.updateTreeCanvasInfoOnServer("canvasHeight", this.canHeight);
    }
    this.bBox = bBox
	} // Dec 28, 2011;

	fitCanvas2Plot() {
		var bBox = this.svgContainer.bbox();
		var width = Math.round((bBox.x + bBox.width) + this.margin_x),
        height = Math.round((bBox.y + bBox.height) + this.margin_y);
    // console.log(this.canHeight,this.canWidth,bBox,this.svgContainer.rbox())
		if (this.canHeight != height && this.canWidth != width ) { //this.width >= this.canWidth && this.height >= this.canHeight
			this.winWidth = width;
			this.winHeight = height

			this.canWidth = width;
			this.canHeight = height;

			this.svgContainer.viewbox(0, 0, this.canWidth, this.canHeight);
      this.svgContainer.width(this.canWidth)
      this.svgContainer.height(this.canHeight)
      EventBus.$emit('svg-dim',{'width':this.canWidth,'height': this.canHeight})
      
			this.updateTreeCanvasInfoOnServer("canvasHeight", this.canHeight);
			this.updateTreeCanvasInfoOnServer("canvasWidth", this.canWidth);
    }
    this.bBox = bBox
  }
  
  whereIsMyTree() {

		var mx = 30 //this.main_layer.rbox().x - this.layer_tree.rbox().x + this.margin_left;
		var my = 50 //this.main_layer.rbox().y - this.layer_tree.rbox().y + this.margin_top;

		// may 14, width of legend
		var legend_width = this.layer_legend.bbox().width;
		mx += legend_width;

		// may 5, 2013 : get scale xy from screen CTM
		// var sx = this.layer_tree.transform().a;
		// var sy = this.layer_tree.transform().d;

		// mx /= sx;
		// my /= sy;

    console.log(mx,my)
		
    this.main_layer.move(mx,my)
		/*
		 * save infor to server
		 */
		// update translate xy
		this.updateTranslateXY4layerTree();

		// fit canvas to plot
		this.fitCanvas2Plot();
	}

  zoom(zoomtype) {
		if (zoomtype === ("restore")) {
      this.svg_scale = 1;
      this.layer_tree.scale(this.svg_scale)
		} else if (zoomtype === ("in")) {
      // this.svg_scale+=0.5      
      this.svg_scale /= this.scale_factor
      this.layer_tree.scale(this.svg_scale)
		} else if (zoomtype === ("out")) {
      if(this.svg_scale>=0.1){
        // this.svg_scale-=0.5
        this.svg_scale *= this.scale_factor
        this.layer_tree.scale(this.svg_scale)
      }
		}
		this.makeBranchLengthScale();
    this.updateTreeCanvasInfoOnServer("svgScale", this.svg_scale);
		this.updateTranslateXY4layerTree();
  }

  zoomLegends(zoomtype) {
		if (zoomtype === ("restore")) {
      this.leg_scale = 1;
      this.layer_legend.scale(this.leg_scale)
		} else if (zoomtype === ("in")) {
      this.leg_scale/= this.scale_factor      
      this.layer_legend.scale(this.leg_scale)
		} else if (zoomtype === ("out")) {
      if(this.leg_scale>=0.1){
        this.leg_scale*= this.scale_factor
        this.layer_legend.scale(this.leg_scale)
      }
		}
    this.updateTreeCanvasInfoOnServer("legScale", this.leg_scale);		
  }

  updateTranslateXY4layerTree(){    
    var tx = this.layer_tree.transform().e;
    var ty = this.layer_tree.transform().f;
    var cx = this.layer_tree.transform().a;
    var cy = this.layer_tree.transform().d;

    // 1. keep tracking rootxy in a hash
    this.hmPlotMode2TranslateX[this.plotmode] = tx / cx;
    this.hmPlotMode2TranslateY[this.plotmode] = ty / cy;

    // 2. keep tracking rootxy on the server
    this.updateTreeCanvasInfoOnServer(this.sTranslateX + this.plotmode, tx / cx);
    this.updateTreeCanvasInfoOnServer(this.sTranslateY + this.plotmode, ty / cy);    
  }

  makeBranchLengthScale() {
		this.clearAllChildNodesFromSVGGElement(this.layer_scale_branch_length);

		var minLenght = 10, maxLength = 100;

		if (this.draw_branch_length_scale) {
			this.layer_scale_branch_length.show()

			var x1 = 30, x2 = x1 + this.pxPerBranchLength / 10 * this.svg_scale, // Jan 25, 2011; use current scale to adjust
					y2 = this.branchlength_scale_y1;

			var text = 0.1;
			var distance = x2 - x1;

			/**
			 * fine-tune x1 and x2
			 */
			if (distance < minLenght) {
				while (distance < minLenght) {
					distance *= 10;
					text *= 10;
					x2 = x1 + distance;
				}
			} else if (distance > maxLength) {
				while (distance > maxLength) {
					distance /= 10;
					text /= 10;
					x2 = x1 + distance;
				}
			}

			/**
			 * a branch length scale is like: 0.01 |-----------------------| left h-line
			 * right tick tick
			 */
      this.layer_scale_branch_length.line(x1, this.branchlength_scale_y1, x2, y2)
			this.layer_scale_branch_length.line(x1, this.branchlength_scale_y1, x1, this.branchlength_scale_y1 - 4) //tick on the left
      this.layer_scale_branch_length.line(x2, y2, x2, y2 - 4) //tick on the right
      
      // text
			var mark = this.layer_scale_branch_length.text(text+"").move(x1 + (x2 - x1) / 2, this.branchlength_scale_y1 - 12);
      mark.font({
        anchor:'middle',
        size:this.default_tick_label_font_size,
        style:FontStyle.ITALIC
      })
		}
	}

  genTrans(num){
    if(num<0) return 0
    else return num
  }

  setMoveTree(bool){
    this.moveTree = bool
  }

  setMoveLegend(bool){
    this.moveLegend = bool   
  }

  getLegDragStatus(){
    return this.moveLegend
  }

  getTreeDragStatus(){
    return this.moveTree
  }
  
  loadTreeViewParams(){
    for(let ind in this.activeTree.canvasParams){
      var dat = this.activeTree.canvasParams[ind]
      var key = dat.key
      var value = dat.value
      // canvas sizes
      if (ignoreCase.equals(key,"canvasWidth")) {
        this.canWidth = parseInt(value)
      } else if (ignoreCase.equals(key,"canvasHeight")) {
        this.canHeight = parseInt(value);

        // svg translate/ transform
      } else if (ignoreCase.equals(key,"svgScale")) { // always do scale first
        this.svg_scale = value;
      } else if (ignoreCase.equals(key,"svgTranslateX")) {
        this.svg_translate_x = value;
      } else if (ignoreCase.equals(key,"svgTranslateY")) {
        this.svg_translate_y = value;
        // horiz and vertical scales
      } else if (ignoreCase.equals(key,"horizScale")) {
        this.pxPerWidthDefault = value;
      } else if (ignoreCase.equals(key,"verticalScale")) {
        this.pxPerHeight = value;
        // fonts
      } else if (ignoreCase.equals(key,"fontSize")) {
        this.default_font_size = parseInt(value);

        // plotmode
      } else if (ignoreCase.equals(key,"plotMode")) {
        var plotmodeserial = parseInt(value);

        if (plotmodeserial == 1) {
          this.plotmode = treePlotMode.RECT_CLADOGRAM;
        } else if (plotmodeserial == 2) {
          this.plotmode = treePlotMode.RECT_PHYLOGRAM;
        } else if (plotmodeserial == 3) {
          this.plotmode = treePlotMode.SLANTED_CLADOGRAM_NORMAL;
        } else if (plotmodeserial == 4) {
          this.plotmode = treePlotMode.CIRCULAR_CLADOGRAM;
        } else if (plotmodeserial == 5) {
          this.plotmode = treePlotMode.CIRCULAR_PHYLOGRAM;
        }
      } else if (ignoreCase.equals(key,"treeCurveStyle")) {
        this.treeCurveStyle = parseInt(value);
      } else if (ignoreCase.equals(key,"showBootstrap")) {
        this.showBootStrap = (value > 0) ? true : false;
      } else if (ignoreCase.equals(key,"showBranchlength")) {
        this.showBrachLength = (value > 0) ? true : false;
      } else if (ignoreCase.equals(key,"showNodeLabel")) {
        this.showNodeLabel = (value > 0) ? true : false;
      } else if (ignoreCase.equals(key,"alignLeafLabel")) {
        this.alignLeafLabels = (value > 0) ? true : false;
      }

      else if (ignoreCase.equals(key,"treehtmlmode")) {
        this.bHTMLmode = (value > 0) ? true : false;
      } else if (ignoreCase.equals(key,"megamode")) {
        this.bMegaMode = value > 0 ? true : false;
      } 
      else if (ignoreCase.equals(key,"showLine2Leaflabel")) {
        this.showLineFromChart2Leaflabel = (value > 0) ? true : false;
      }

      else if (ignoreCase.equals(key,"circularClockWise")) {
        this.circular_tree_plot_in_clockwise_mode = (value > 0) ? true : false;
      } else if (ignoreCase.equals(key,"circularStartAngle")) {
        this.angle_start = value;
      } else if (ignoreCase.equals(key,"circularAngleSpan")) {
        this.angle_span = value;
      } else if (ignoreCase.equals(key,"treeBranchLineWidth")) {
        this.default_line_width = value;
      } else if (ignoreCase.equals(key,"leafFontItalic")) {
        this.leaf_font_italic = value > 0 ? true : false;
      }
      else if (ignoreCase.equals(key,this.sPxPerWidth + treePlotMode.RECT_CLADOGRAM)) {
        this.hmPlotMode2pxPerWidth[treePlotMode.RECT_CLADOGRAM]  = value;
      } else if (ignoreCase.equals(key,this.sPxPerWidth + treePlotMode.RECT_PHYLOGRAM)) {
        this.hmPlotMode2pxPerWidth[treePlotMode.RECT_PHYLOGRAM] = value;
      } else if (ignoreCase.equals(key,this.sPxPerWidth + treePlotMode.SLANTED_CLADOGRAM_NORMAL)) {
        this.hmPlotMode2pxPerWidth[treePlotMode.SLANTED_CLADOGRAM_NORMAL] = value;
      } else if (ignoreCase.equals(key,this.sPxPerWidth + treePlotMode.CIRCULAR_CLADOGRAM)) {
        this.hmPlotMode2pxPerWidth[treePlotMode.CIRCULAR_CLADOGRAM] = value;
      } else if (ignoreCase.equals(key,this.sPxPerWidth + treePlotMode.CIRCULAR_PHYLOGRAM)) {
        this.hmPlotMode2pxPerWidth[treePlotMode.CIRCULAR_PHYLOGRAM] = value;
      }
      else if (ignoreCase.equals(key,this.sTranslateX +  treePlotMode.RECT_CLADOGRAM)) {
        this.hmPlotMode2TranslateX[ treePlotMode.RECT_CLADOGRAM] = value;
      } else if (ignoreCase.equals(key,this.sTranslateX + treePlotMode.RECT_PHYLOGRAM)) {
        this.hmPlotMode2TranslateX[treePlotMode.RECT_PHYLOGRAM] = value;
      } else if (ignoreCase.equals(key,this.sTranslateX + treePlotMode.SLANTED_CLADOGRAM_NORMAL)) {
        this.hmPlotMode2TranslateX[treePlotMode.SLANTED_CLADOGRAM_NORMAL] = value;
      } else if (ignoreCase.equals(key,this.sTranslateX + treePlotMode.CIRCULAR_CLADOGRAM)) {
        this.hmPlotMode2TranslateX[treePlotMode.CIRCULAR_CLADOGRAM] = value;
      } else if (ignoreCase.equals(key,this.sTranslateX + treePlotMode.CIRCULAR_PHYLOGRAM)) {
        this.hmPlotMode2TranslateX[treePlotMode.CIRCULAR_PHYLOGRAM] = value;
      } // -- root Y --
      else if (ignoreCase.equals(key,this.sTranslateY + treePlotMode.RECT_CLADOGRAM)) {
        this.hmPlotMode2TranslateY[treePlotMode.RECT_CLADOGRAM] = value;
      } else if (ignoreCase.equals(key,this.sTranslateY + treePlotMode.RECT_PHYLOGRAM)) {
        this.hmPlotMode2TranslateY[treePlotMode.RECT_PHYLOGRAM] = value;
      } else if (ignoreCase.equals(key,this.sTranslateY + treePlotMode.SLANTED_CLADOGRAM_NORMAL)) {
        this.hmPlotMode2TranslateY[treePlotMode.SLANTED_CLADOGRAM_NORMAL] = value;
      } else if (ignoreCase.equals(key,this.sTranslateY + treePlotMode.CIRCULAR_CLADOGRAM)) {
        this.hmPlotMode2TranslateY[treePlotMode.CIRCULAR_CLADOGRAM] = value;
      } else if (ignoreCase.equals(key,this.sTranslateY + treePlotMode.CIRCULAR_PHYLOGRAM)) {
        this.hmPlotMode2TranslateY[treePlotMode.CIRCULAR_PHYLOGRAM] = value;
      }
      else if (ignoreCase.equals(key,"bootStrapFontItalic")) {
        this.bootStrapFontItalic = value > 0 ? true : false;
      } else if (ignoreCase.equals(key,"branchLengthFontItalic")) {
        this.branchLengthFontItalic = value > 0 ? true : false;
      } else if (ignoreCase.equals(key,"bootStrapFontSize")) {
        this.default_bootstrap_font_size = parseInt(value);
      } else if (ignoreCase.equals(key,"branchLengthFontSize")) {
        this.default_branchlength_font_size = parseInt(value);
      } else if(ignoreCase.equals(key,"legScale")){
        this.leg_scale = parseInt(value)
      }
      }

      // this.layer_tree.panZoom().zoom(this.svg_scale,this.rootXY.x,this.rootXY.y)
      this.layer_tree.scale(this.svg_scale)
      this.layer_legend.scale(this.leg_scale)
      // transform({scaleX:this.svg_scale,scaleY:this.svg_scale})

      var translate_x = this.hmPlotMode2TranslateX.hasOwnProperty(this.plotmode) ? this.hmPlotMode2TranslateX[this.plotmode]
				: this.svg_translate_x;
	  	var translate_y = this.hmPlotMode2TranslateY.hasOwnProperty(this.plotmode) ? this.hmPlotMode2TranslateY[this.plotmode]
				: this.svg_translate_y;
		  this.layer_tree.transform({x:translate_x,y:translate_y})

		/*
		 * set canvas size for svg
		 */
    // this.setPixelSize(this.canvas_width, this.canvas_height);
    this.svgContainer.viewbox(0, 0, this.canWidth, this.canHeight);

    if (!this.showBootStrap) {
			this.layer_tree_bootstrap.hide();
		}

		if (!this.showBrachLength) {
			this.layer_tree_branchlength.hide();
		}

		if (!this.showNodeLabel) {
			this.layer_tree_leaf_labels.hide();
		}
      // this.drawPhyloTreeUsingSVG()
      EventBus.$emit('svg-dim',{'width':this.canWidth,'height': this.canHeight})
      // this.$emit('load-buttons', this)

  }
  
  switchTreeView(newTreeMode){
    this.plotmode = newTreeMode
    var mode = 1;
			if (this.plotmode === (treePlotMode.RECT_CLADOGRAM)) {
				mode = 1;
			} else if (this.plotmode === (treePlotMode.RECT_PHYLOGRAM)) {
				mode = 2;
			} else if (this.plotmode === (treePlotMode.SLANTED_CLADOGRAM_NORMAL)) {
				mode = 3;
			} else if (this.plotmode === (treePlotMode.CIRCULAR_CLADOGRAM)) {
				mode = 4;
			} else if (this.plotmode === (treePlotMode.CIRCULAR_PHYLOGRAM)) {
				mode = 5;
			}
    this.drawPhyloTreeUsingSVG()
    this.updateTreeCanvasInfoOnServer("plotMode", mode)    
  }

  drawPhyloTreeUsingSVG(beRoot = false){
    // EventBus.$emit('hide-canvas', true)
    this.rootnode = this.pyTree.getRootNode()//clone.cloneDeep(this.pyTree.getRootNode())
    this.makeTreeSkeleton()
    this.makeLeafLabelsAndBranchLengthAndBootstrapValues()
    this.makeLeafLabelBackground()    
    this.drawTreeSkeleton(this.rootnode)
    this.makeBranchLengthScale()
    this.pie.setBranchLengthPositions(this.BranchLengthPositions)
    this.pie.makePies(beRoot)    
    this.makeTimeLine()
    this.updateAllCharts();
    this.updateAllLegends();
    this.moveTree = true
    this.loadActiveBranchMarker()
    this.layer_tree.forward()
    
    this.bBox = this.svgContainer.bbox();
    setTimeout(() => {
      EventBus.$emit('hide-canvas', {'state':false,'src':'treeSVG', 'type':this.canType})  
    }, 150);
    // EventBus.$emit('hide-canvas', {'state':false,'src':'treeSVG', 'type':this.canType})  
    EventBus.$emit('svg-dim',{'width':this.canWidth,'height': this.canHeight})    
  }

  makeToolTip(event,status,node){
    this.clearAllChildNodesFromSVGGElement(this.tp_layer)
    this.tp_layer.forward()
    if(status){    
      var pts = null  
      // var pts = svg_poly.point(event.clientX, event.clientY)//{'x':(event.offsetX-svgbox.cx-5),'y':event.offsetY+15}
      // console.log(pts,event,svgbox)
      var rect = null
      if(node.isLeaf){
        rect = this.tp_layer.rect(200,120)//.move(pts.x,pts.y)        
        pts = rect.point(event.clientX, event.clientY)
        pts = {x:pts.x+10,y:pts.y+10}
        rect.move(pts.x,pts.y)
      }else{
        rect = this.tp_layer.rect(200,165)//.move(pts.x,pts.y)
        pts = rect.point(event.clientX, event.clientY)
        pts = {x:pts.x+10,y:pts.y+10}
        rect.move(pts.x,pts.y)
        var collapse = this.tp_layer.rect(85,30).move(pts.x+10,pts.y+122).fill('#E9ECEC')
        collapse.attr('cursor','pointer')
        var that = this
        collapse.click(function(){
          // console.log('i flipped')
          that.pyTree.flipAtInternalNode(node)
          // console.log(that.pyTree.getTreeString())
          that.drawPhyloTreeUsingSVG(true)
          that.clearAllChildNodesFromSVGGElement(that.tp_layer)
        })
        var copTxt = this.tp_layer.text("swap/flip").move(pts.x+20,pts.y+130).attr('cursor','pointer')
        copTxt.click(function(){
          // console.log('i flipped')
          that.pyTree.flipAtInternalNode(node)
          // console.log(that.pyTree.getTreeString())
          that.drawPhyloTreeUsingSVG(true)            
          that.clearAllChildNodesFromSVGGElement(that.tp_layer)
        })
        // copTxt.mouseout(function(evt){
        //   that.clearAllChildNodesFromSVGGElement(that.tp_layer)
        // })
      }

      rect.attr('fill','white')
      rect.attr('box-shadow','grey 0px 0px 2px')
      rect.attr('stroke-width',0.1)
      rect.attr('opacity','0.9')
      rect.addClass('shadow')
      
      var name = node.id
      if(name.length<=0){
        name = node.internal_id
      }
      if(node.isLeaf){
        name += '(leaf)'
      }
      var max_len = 0,branch_len = 0,broot_len = 0
      var text = this.tp_layer.text(function(add) {
        add.tspan('Name:'+name).newLine()
        branch_len = add.tspan('Branch length:'+node.branch_length).newLine().length()
        broot_len = add.tspan('Branch length to root:'+node.branchlength_to_root).newLine().length()
        add.tspan('Distance to root:'+node.getDistanceToRoot()).newLine()
        add.tspan('Leaf descendents:'+node.getNumberOfLeafDescendents()).newLine()
      }).move(pts.x+10,pts.y+10)
      text.font({
        family:   'Helvetica',
        size:     16
      })
      if(max_len <= branch_len)
        max_len = branch_len
      if(max_len <= broot_len)
        max_len = broot_len
      rect.width(max_len+20)
      this.tp_layer.show()
      // setTimeout(() => this.clearAllChildNodesFromSVGGElement(this.tp_layer), 2000);
      // console.log(text.length(),text.lines().length()*16)
      // console.log(max_len,rect.width(),text.width(),text.height(),text.length(),text.x(),text.y())
    }
  }

  makeDatSetTooltipShow(event,colors,rowDat,groups,externidalId,shape){
    this.clearAllChildNodesFromSVGGElement(this.tp_layer)

    var rect = this.tp_layer.rect(200,165)//.move(pts.x,pts.y)
    var pts = rect.point(event.clientX, event.clientY)
    pts = {x:pts.x+10,y:pts.y+10}
    rect.move(pts.x,pts.y)
    rect.attr('fill','white')
    rect.attr('box-shadow','grey 0px 0px 2px')
    rect.attr('stroke-width',0.1)
    rect.attr('opacity','0.9')
    rect.addClass('shadow')

    var start_x = pts.x+10,start_y=pts.y+10
    var max_width = 0,max_height = 0
    if (externidalId != null && externidalId.length>=1) {
      const id_lbl = this.tp_layer.text(externidalId).move(start_x,start_y)
      start_y +=20
      max_height+=20
    }else{
      start_y +=5
      max_height+=5
    }    
    for (var idx = 0; idx < groups.length; idx++) {
      // a rect or a circle and STYLE
      start_x = pts.x+10
			var color = (colors != null) ? (colors.length > idx) ? colors[idx] : colors[idx % colors.length]
          : "white";
          
      const type_shape = this.tp_layer.rect(10,10).move(start_x,start_y)
      if(shape === 'circle'){
        var radius = 10 / 2;
        type_shape.radius(radius)     
      }
      type_shape.fill(color)
      start_x +=15

      const text_lbl = this.tp_layer.text(groups[idx]+"").move(start_x,start_y-5)
      text_lbl.attr("fontSize", "smaller")
      start_x +=text_lbl.length()+5

      const value_txt_lbl = this.tp_layer.text(rowDat[idx]+"").move(start_x,start_y-5)
      value_txt_lbl.attr("fontSize", "smaller")
      start_x +=value_txt_lbl.length()+5

      if(max_width < (text_lbl.length()+10+value_txt_lbl.length()))
        max_width = text_lbl.length()+10+value_txt_lbl.length()

      start_y +=20
      max_height+=20
    }    
    rect.height(max_height+15)
    rect.width(max_width+30)
    this.tp_layer.show()
  }

  makeToolTipFlextableForDomain(event,pd){
    this.clearAllChildNodesFromSVGGElement(this.tp_layer)
    this.tp_layer.forward()

    var rect = this.tp_layer.rect(200,165)//.move(pts.x,pts.y)
    var pts = rect.point(event.clientX, event.clientY)
    pts = {x:pts.x+10,y:pts.y+10}
    rect.move(pts.x,pts.y)
    rect.attr('fill','white')
    rect.attr('box-shadow','grey 0px 0px 2px')
    rect.attr('stroke-width',0.1)
    rect.attr('opacity','0.9')
    rect.addClass('shadow')

    var start_x = pts.x+10,start_y=pts.y+10
    var max_width = 0,max_height = 0

    var loc = this.tp_layer.text("Location:").move(start_x,start_y)
    loc.font({
      style:'bold'
    })
    var loc_val = this.tp_layer.text(pd.getStart() + " - " + pd.getStop()).move(start_x+loc.length()+5,start_y)
    max_width = this.checkMaxWidth(max_width,loc,loc_val)    
    start_y +=20
    max_height +=20    

    var dName = this.tp_layer.text("Domain name:").move(start_x,start_y)
    dName.font({
      style:'bold'
    })
    var dName_val = this.tp_layer.text(pd.getName()).move(start_x+dName.length()+5,start_y)
    dName_val.fill('blue')
    if (pd.isPfamB()) {
      dName_val.linkTo(function(link) {
        link.to('http://pfam.xfam.org/pfamb/'+pd.getName()).target('_blank')
      })
    }else{
      dName_val.linkTo(function(link) {
        link.to('http://pfam.xfam.org/family/'+pd.getName()).target('_blank')
      })
    }
    max_width = this.checkMaxWidth(max_width,dName,dName_val)
    start_y +=20
    max_height +=20

    var acc = this.tp_layer.text("Accession:").move(start_x,start_y)
    acc.font({
      style:'bold'
    })
    var acc_val = this.tp_layer.text(pd.getAccession()).move(start_x+acc.length()+5,start_y)
    acc_val.fill('blue')
    if (pd.isPfamB()) {
      acc_val.linkTo(function(link) {
        link.to('http://pfam.xfam.org/pfamb/'+pd.getAccession()).target('_blank')
      })
    }else{
      acc_val.linkTo(function(link) {
        link.to('http://pfam.xfam.org/family/'+pd.getAccession()).target('_blank')
      })
    }
    max_width = this.checkMaxWidth(max_width,acc,acc_val)
    start_y +=20
    max_height +=20

    if (pd.getSource() != null && pd.getSource().length >=1) {
      var src = this.tp_layer.text("Source:").move(start_x,start_y)
      src.font({
        style:'bold'
      })
      var src_val = this.tp_layer.text(pd.getSource()).move(start_x+src.length()+5,start_y)
      max_width = this.checkMaxWidth(max_width,src,src_val)
      start_y +=20
      max_height +=20
    }

    if (pd.getEvalue() != null && pd.getEvalue().length >=1) {
      var e_val = this.tp_layer.text("Evalue:").move(start_x,start_y)
      e_val.font({
        style:'bold'
      })
      var eval_val = this.tp_layer.text(pd.getEvalue()).move(start_x+e_val.length()+5,start_y)
      max_width = this.checkMaxWidth(max_width,e_val,eval_val)
      start_y +=20
      max_height +=20
    }

    if (pd.getBitscore() != null && pd.getBitscore().length >=1) {
      var bitscore = this.tp_layer.text("Bitscore:").move(start_x,start_y)
      bitscore.font({
        style:'bold'
      })
      var bitscore_val = this.tp_layer.text(pd.getBitscore()).move(start_x+bitscore.length()+5,start_y)
      max_width = this.checkMaxWidth(max_width,bitscore,bitscore_val)
      start_y +=10
      max_height +=20
    }

    rect.height(max_height+15)
    rect.width(max_width+30)
    this.tp_layer.show()
  }

  checkMaxWidth(max_width,key,value){
    var sum = key.length()+value.length()
    return max_width < sum ? sum : max_width
  }

  makeDatSetTooltipHide(){
    var that = this
    setTimeout(function() {
      that.clearAllChildNodesFromSVGGElement(that.tp_layer)
      that.tp_layer.hide()
    }, 2000);    
  }

  makeTreeSkeleton () { //6800
    this.clearAllChildNodesFromSVGGElement(this.layer_tree_skeleton)
    this.clearAllChildNodesFromSVGGElement(this.layer_branchLabel)
    this.branchID2OMNode = {};
		this.leafID2LeafAlignOMNode = {};
		this.BranchLengthPositions = {};
		this.LeafInternalID2NodePosisionts = {};
		this.LeafInternalID2LabelEndPosisionts = {};
    this.leafIDtoInternalID = {}
		this.BootstrapPositions = {};
    this.hmLeafID2NodePositions = {};
    this.hmLeafInternalID2LeafPath = {}
    this.hmLeafInternalID2LeafPathStyles = {}

    this.pxPerWidthCurrentMode = this.hmPlotMode2pxPerWidth.hasOwnProperty(this.plotmode) ? this.hmPlotMode2pxPerWidth[this.plotmode]
					: this.pxPerWidthDefault;
    this.pxPerBranchLength = this.pxPerWidthCurrentMode * this.pxPerBranchLengthScaling
    
    var opacity = this.dataset2opacity.hasOwnProperty(this.activeBranchColorSetID)
					? this.dataset2opacity[this.activeBranchColorSetID]
					: 1;
    this.layer_tree_skeleton.attr("fill-opacity", opacity+"");
    
    var flagSlantedMode = (this.plotmode == treePlotMode.SLANTED_CLADOGRAM_RECT
      || this.plotmode == treePlotMode.SLANTED_CLADOGRAM_MIDDLE
      || this.plotmode == treePlotMode.SLANTED_CLADOGRAM_NORMAL);
    var activeInternalCollapseDataset = this.datasetID2dataContainerObj[this.activeInternalCollapseDatasetID];
    if (!flagSlantedMode && this.collapseInternalEnabled && activeInternalCollapseDataset != null) {
      this.pyTree.setCollapseNodes(activeInternalCollapseDataset.getInternalNodesToCollapseAtAr());
      var collapse_pct_current = activeInternalCollapseDataset.getCollapsePct();
      if (collapse_pct_current >= 0 && collapse_pct_current <= 1) {
        this.collapse_pct = collapse_pct_current;
      }
      // console.log(this.collapse_pct)
      // this.rootnode = clone.cloneDeep(this.pyTree.getRootNode())
    } else {
      this.pyTree.removeInternalCollapse();
    }

    this.draw_branch_length_scale = (this.plotmode == treePlotMode.CIRCULAR_PHYLOGRAM
      || this.plotmode == treePlotMode.RECT_PHYLOGRAM) ? true : false;
    var branch_stroke_color = (this.branchColorEnabled && !this.disable_all_dataset
        && this.activeBranchColorSetID.length >=1)
            ? this.rootnode.getBranchColorByColorsetID(this.activeBranchColorSetID)
            : "black";

    if (this.plotmode == treePlotMode.RECT_CLADOGRAM || this.plotmode == treePlotMode.RECT_PHYLOGRAM) {
      this.rootXY.x = (this.margin_x);
      this.rootXY.y = (this.rootnode.getLevelVertical() * this.pxPerHeight + this.margin_y);
      var pp = {'x':this.rootXY.x-this.curvePixel,'y':this.rootXY.y}
      // var pathPt = new Point()
      // pathPt.appendSegItem(Point.createPointStr(this.treeCurveStyle == 2 ? this.rootXY.x - this.curvePixel:this.rootXY.x, this.rootXY.y))
      // pathPt.appendSegItem(Point.createPointStr(this.rootXY.x - 10, this.rootXY.y))
      // this.rootnode.pathPt = pathPt.getSegPathListStr()
      // this.rootnode.stroke = branch_stroke_color
      this.drawRect(this.rootnode,this.rootXY,pp,this.pyTree.getMaxHorizontalLevel())
    }else if (this.plotmode == treePlotMode.SLANTED_CLADOGRAM_RECT
      || this.plotmode == treePlotMode.SLANTED_CLADOGRAM_MIDDLE
      || this.plotmode == treePlotMode.SLANTED_CLADOGRAM_NORMAL) {
      this.rootXY.x = (this.margin_x);
      this.rootXY.y = (this.rootnode.getLevelVerticalSlanted() * this.pxPerHeight + this.margin_y);
      
      var x_first_leaf = this.rootnode.getLevelHorizontal() * this.pxPerWidthCurrentMode + this.rootXY.x;
			var y_first_leaf = 1 * this.pxPerHeight + this.margin_y;
      var atan = Math.atan((this.rootXY.y - y_first_leaf) / (x_first_leaf - this.rootXY.x));
      this.drawSlant(this.rootnode, this.rootXY, atan, x_first_leaf);
    }else if (this.plotmode == treePlotMode.CIRCULAR_CLADOGRAM || this.plotmode == treePlotMode.CIRCULAR_PHYLOGRAM) {

      /**
       * angle_per_vertical_level has to be calculated everytime !!!
       */
      this.angle_per_vertical_level = (this.angle_span) / this.pyTree.getMaxVerticalLevel();

      /**
       * also recalculate rootXY
       */
      var center = (this.plotmode == treePlotMode.CIRCULAR_CLADOGRAM)
          ? this.pyTree.getMaxHorizontalLevel() * this.pxPerWidthCurrentMode
          : this.pyTree.getMaxTotalBranchLengthFromRootToAnyTip() * this.pxPerBranchLength;
      this.rootXY.x = (center + this.margin_x + this.default_font_size * 5);
      this.rootXY.y = (center + this.margin_y + this.default_font_size * 5);

      // console.log('rootxy ',this.rootXY,center,this.default_font_size)
      // go plot in circular mode ...
      this.drawCircle(this.rootnode, this.rootXY, this.rootXY, this.angle_start);
    }else if (this.plotmode == treePlotMode.UNROOTED) {
      this.rootXY.x = (this.pyTree.getMaxHorizontalLevel() * this.pxPerWidthDefault);
      this.rootXY.y = (this.rootnode.getLevelVertical() * this.pxPerHeight + this.margin_y);
      var pp = {'x':this.rootXY.x-this.curvePixel,'y':this.rootXY.y}


    }
    this.BranchLengthPositions[this.rootnode.getInternalID()] = {'pt':this.rootXY,'angle':0};
  }

  drawTreeSkeleton(node,isCoolpase){
    var polyline = null
    if(this.plotmode === treePlotMode.RECT_CLADOGRAM || this.plotmode === treePlotMode.RECT_PHYLOGRAM 
      || this.plotmode === treePlotMode.CIRCULAR_CLADOGRAM || this.plotmode === treePlotMode.CIRCULAR_PHYLOGRAM){
      if(node.isCollapse()){
        polyline = this.layer_tree_skeleton.path(node.pathPt+" "+node.tri).id(node.id) //y-30 //node.getPtsAr()
      }else if(!isCoolpase){
        polyline = this.layer_tree_skeleton.path(node.pathPt).id(node.id) //y-30 //node.getPtsAr()
      }
    }else{
      if(node.isCollapse()){
        // console.log(node.tri)
        polyline = this.layer_tree_skeleton.path(node.pathPt+" "+node.tri).id(node.id) //y-30 //node.getPtsAr()
      }else if(!isCoolpase){
        polyline = this.layer_tree_skeleton.polyline(node.ptsAr).id(node.id)
      }
    }
  
    // console.log(node.id,node.stroke)
    if(polyline != null){
      if(!node.isleaf()){
        polyline.id(node.getInternalID())
      }
      if(!this.hmLeafInternalID2LeafPath.hasOwnProperty(node.getInternalID())){
        this.hmLeafInternalID2LeafPath[node.getInternalID()] =  polyline;
      }

      if(node.id == null || node.id.length<=0){
        polyline.id = node.internal_id
      }

      polyline.fill('none')
      polyline.stroke({ color: node.stroke, width: this.default_line_width, linecap: 'round', linejoin: 'round' })              
      
      var that = this
      polyline.mouseover(function(evt) {
        node.isSelected = !node.isSelected
        that.makeToolTip(evt,node.isSelected,node)
        if(node.isSelected){
          this.stroke({ color: '#f06', width: this.sel_line_width, linecap: 'round', linejoin: 'round' })
          // this.attr('stroke-width',that.sel_line_width)              
        }else{
          this.stroke({ color: node.stroke, width: this.default_line_width, linecap: 'round', linejoin: 'round' })              
          // this.attr('stroke-width',that.default_line_width)              
        }
      })
      polyline.mouseout(function(evt){
        node.isSelected = false
        if(node.isLeaf){
          that.makeDatSetTooltipHide()
        }
        this.stroke({ color: node.stroke, width: this.default_line_width, linecap: 'round', linejoin: 'round' })              
      })

      if(node.stroke_typ != null && node.stroke_typ.length > 1){
        if(node.stroke_typ === 'dash-array'){
          if(node.dash != undefined && node.dash != null){
            polyline.attr('stroke-dasharray',node.dash)
          }else{
            polyline.attr('stroke-dasharray','5,5')
          }
        }else if(node.stroke_typ === 'line'){
          polyline.attr('stroke-width',node.stroke_wd)
          // let secLine = this.layer_tree_skeleton.path(node.second_pt)
          // secLine.stroke({ color: node.stroke})
          // secLine.attr('stroke-width',node.stroke_wd)
        }
      }

      if(node.last_line != null && node.last_line.length >=2 && this.branchLabelEnabled && this.activeBranchLabelID.length > 1){
        var bLabel = node.getBranchLabelByColorsetID(this.activeBranchLabelID)
        if(bLabel != null){
          var midX = (node.last_line[1].x - node.last_line[0].x)/2
          var midY = node.last_line[0].y
          let branchLen = Point.distanceBetweenPoint(node.last_line[0],node.last_line[1])
          midX = node.last_line[0].x+midX
          if((this.plotmode === treePlotMode.RECT_CLADOGRAM || this.plotmode === treePlotMode.RECT_PHYLOGRAM) && !node.isCollapse()){
            // console.log(node)
            this.drawBranchLabelForRect(bLabel,{'x':midX,'y':midY},branchLen,node.angle)
          }else if((this.plotmode === treePlotMode.CIRCULAR_CLADOGRAM || this.plotmode === treePlotMode.CIRCULAR_PHYLOGRAM) && !node.isCollapse()){  
            // if(node.angle != 0){              
            //   midX = node.last_line[1].x
            //   midY = node.last_line[1].y
            // }
            // console.log(node.id,bLabel.text,node.angle)
            // this.drawBranchLabelForCircle(bLabel,{'x':midX,'y':midY},branchLen,node.angle,node.last_line)
          }
          // console.log(bLabel.text,node.id,node.angle,midX,midY,branchLen,node.last_line)
        }
      }
    }

    for(var ind in node.getDescendents()){
      var dnode = node.getDescendents()[ind]
      var isCoolp = isCoolpase ? true : node.isCollapse()
      this.drawTreeSkeleton(dnode,isCoolp)
    }
  }

  drawBranchLabelForRect(branchLabelDef,pos,branchLen,angle){    
    let def_rect_width = 20,def_rect_height = 10
    if(branchLabelDef.getTextAlign() === 'start'){
      pos.y = pos.y - (def_rect_height) -2
    }else if(branchLabelDef.getTextAlign() === 'middle'){
      pos.y = pos.y - (def_rect_height/2)
    }else if(branchLabelDef.getTextAlign() === 'end'){
      pos.y = pos.y + 2
    }
    var labelText = this.layer_branchLabel.text(branchLabelDef.getText())//.move(pos.x+10,pos.y)
    var fontSize = branchLabelDef.getFontSize()
    labelText.font({
      family: this.default_font_family
    , size: fontSize     
    , anchor: 'middle'
    , style:branchLabelDef.isItalic() ? FontStyle.ITALIC : FontStyle.NORMAL
    , fill:branchLabelDef.getFontColor()
    })        
    labelText.attr('alignment-baseline','middle')
    var rectwidth = labelText.length()+4 > 1 ? labelText.length()+4 : def_rect_width
    if(rectwidth >= branchLen){
      rectwidth = branchLen*60/100
    }
    var label_shape = null
    if(branchLabelDef.getShape() === 'rect'){
      label_shape = this.layer_branchLabel.rect(rectwidth,def_rect_height).move(pos.x-(rectwidth/2),pos.y)
    }else if(branchLabelDef.getShape() === 'circle'){
      label_shape = this.layer_branchLabel.circle(rectwidth/2).move(pos.x-(rectwidth/2)+(rectwidth/4),pos.y)
      labelText.hide()
    }
    label_shape.attr({
      fill:branchLabelDef.getBackgroundColor(),
      stroke:branchLabelDef.getLineColor(),
      'stroke-width':branchLabelDef.getLineWidth(),
    })
    label_shape.attr('stroke',branchLabelDef.getLineColor())
    if(labelText.length()+4 >= branchLen){
      labelText.hide()
    }
    labelText.move(pos.x+(labelText.length()/2)-(rectwidth/2)+2,pos.y)
    labelText.forward()
    if(branchLabelDef.isBorderDashed()){
      label_shape.attr('stroke-dasharray','5,5')
    }
  }

  drawBranchLabelForCircle(branchLabelDef,pos,branchLen,angle,last_line){
    let def_rect_width = 20,def_rect_height = 10
    // if(branchLabelDef.getTextAlign() === 'start'){
    //   pos.y = pos.y - (def_rect_height) -2
    // }else if(branchLabelDef.getTextAlign() === 'middle'){
    //   pos.y = pos.y - (def_rect_height/2)
    // }else if(branchLabelDef.getTextAlign() === 'end'){
    //   pos.y = pos.y + 2
    // }
    // console.log(branchLabelDef.getText(),angle)
    var labelText = this.layer_branchLabel.text(branchLabelDef.getText())//.move(pos.x+10,pos.y)
    var fontSize = branchLabelDef.getFontSize()
    labelText.font({
      family: this.default_font_family
    , size: fontSize     
    // , anchor: 'middle'
    , style:branchLabelDef.isItalic() ? FontStyle.ITALIC : FontStyle.NORMAL
    , fill:branchLabelDef.getFontColor()
    })        
    labelText.attr('alignment-baseline','middle')
    var rectwidth = labelText.length()+4 > 1 ? labelText.length()+4 : def_rect_width
    if(rectwidth >= branchLen){
      rectwidth = branchLen*60/100
    }
    var label_shape = null
    // if(branchLabelDef.getShape() === 'rect'){
    //   label_shape = this.layer_branchLabel.rect(rectwidth,def_rect_height).move(pos.x,pos.y)
    // }else if(branchLabelDef.getShape() === 'circle'){
    //   label_shape = this.layer_branchLabel.circle(rectwidth/2).move(pos.x,pos.y)
    //   labelText.hide()
    // }
    label_shape = this.layer_branchLabel.polyline(this.lineToPath(last_line[0].x,last_line[0].y,last_line[1].x,last_line[1].y,rectwidth))
    label_shape.attr({
      fill:branchLabelDef.getBackgroundColor(),
      stroke:branchLabelDef.getLineColor(),
      // 'stroke-width':branchLabelDef.getLineWidth(),
    })
    label_shape.attr('stroke',branchLabelDef.getLineColor())
    if(labelText.length()+4 >= branchLen){
      labelText.hide()
    }
    labelText.move(pos.x,pos.y)
    labelText.hide()
    labelText.forward()
    if(branchLabelDef.isBorderDashed()){
      label_shape.attr('stroke-dasharray','5,5')
    }
    // if(angle >90 && angle < 180){
    //   label_shape.rotate(90)
    // }
    // label_shape.rotate(180-angle)
    // label_shape.transform({rotation:angle,cx:this.rootXY.x,cy:this.rootXY.y})
  }

  lineToPath(x1,y1,x2,y2,width){
    const angle = Math.atan((y2 - y1) / (x2 - x1));
    const dx = width / 2 * -Math.sin(angle);
    const dy = width / 2 * Math.cos(angle);
    
    return `M ${x1 + dx},${y1 + dy} L ${x2 + dx},${y2 + dy} 
      L ${x2 - dx},${y2 - dy} L ${x1 - dx},${y1 - dy} Z`;
  }
  

  drawRect(pnode,parentxy,ppxy,maxHorizLevel){ //9370
    var px = parentxy.x
    var py = parentxy.y
    var vlevel = pnode.getLevelVertical();
    var hlevel = pnode.getLevelHorizontal();

    if(pnode.getDescendents().length>=1){
      for(var ind in pnode.getDescendents()){
        var dnode = pnode.getDescendents()[ind]
        // console.log(dnode.isCollapse(),dnode.getInternalID())
        let branch_stroke_color = (this.branchColorEnabled && !this.disable_all_dataset
          && this.activeBranchColorSetID.length >=1)
              ? dnode.getBranchColorByColorsetID(this.activeBranchColorSetID)
              : "black";
        let branch_stroke = (this.branchColorEnabled && !this.disable_all_dataset
          && this.activeBranchColorSetID.length >=1)
              ? dnode.getBranchStrokeByColorsetID(this.activeBranchColorSetID)
              : "";

        if(branch_stroke.length >1){
          var branchstAr = branch_stroke.split(":")
          if(branchstAr.length>=2){ // stroke with varying dash width or line with leaf width
            if(branchstAr[0] === 'dash'){
              dnode.stroke_typ = "dash-array"
              dnode.dash = branchstAr[1]
            }else{
              dnode.stroke_typ = "line"
            }
            dnode.stroke_wd = parseInt(branchstAr[1])
          }else{ //dashed line
            dnode.stroke_typ = "dash-array"
          }
        }else{
          dnode.stroke_typ = ''
        }
        // console.log(branch_stroke_color,dnode.getBranchColorByColorsetID(this.activeBranchColorSetID))
        let vl = dnode.getLevelVertical();
        let hb = dnode.getBranchLength();
        var dx = px;

        var ispolyObjLine = dnode.stroke_typ != null && dnode.stroke_typ.length > 1 && dnode.stroke_typ === 'line'

        if (this.plotmode === treePlotMode.RECT_CLADOGRAM) {
          var  hl = dnode.isCollapse() ? 2 : dnode.getLevelHorizontal();
          dx += (hlevel - hl) * this.pxPerWidthCurrentMode;
        }else {
          dx += hb * this.pxPerBranchLength;
        }
        var dy = py + (vl - vlevel) * this.pxPerHeight;
        var current_xy2 = {'x':dx,'y':dy}
        var pathPt = new Point()

        if (this.treeCurveStyle == 0 || ((this.plotmode == (treePlotMode.CIRCULAR_PHYLOGRAM)
              || this.plotmode == (treePlotMode.RECT_PHYLOGRAM)) && dnode.getBranchLength() == 0)) {
          pathPt.appendSegItem(Point.createPointStr(px,py))
          pathPt.appendSegItem(Point.createPointStr(px,dy,'L'))
          dnode.last_line = [Point.createPoint(px, dy),Point.createPoint(dx, dy)]
          if(ispolyObjLine){
            dnode.second_pt = [Point.createPointStr(px,dy,'M'),Point.createPointStr(dx,dy,'L')].join(" ")
          }
          pathPt.appendSegItem(Point.createPointStr(dx,dy,'L'))
        }else if(this.treeCurveStyle == 1){
          // console.log('type 1')
          dnode.ptsAr = []
          var curvePixel_x = this.curvePixel, curvePixel_y = this.curvePixel;

          if (Math.abs(dy - py) <= curvePixel_y * 2) {
            curvePixel_y = Math.abs(dy - py) / 2;
          }

          if (Math.abs(dx - px) <= curvePixel_x * 2) {
            curvePixel_x = Math.abs(dx - px) / 2;
          }

          var up = dy > py
          pathPt.appendSegItem(Point.createPointStr(px,py))
          pathPt.appendSegItem(Point.createPointStr(px,up ? dy - 2 * curvePixel_y : dy + 2 * curvePixel_y,'L'))
          pathPt.appendSegItem(Point.createCubicArcStr(px , up ? dy - curvePixel_y : dy + curvePixel_y, px + curvePixel_x,
            dy, px + 2 * curvePixel_x, dy))
          pathPt.appendSegItem(Point.createPointStr(dx,dy,'L'))
          dnode.last_line = [Point.createPoint(px + 2 * curvePixel_x, dy),Point.createPoint(dx,dy)]
          if(ispolyObjLine){
            dnode.second_pt = [Point.createPointStr(px + 2 * curvePixel_x, dy,'M'),Point.createPointStr(dx,dy,'L')].join(" ")
          }          
        }else if(this.treeCurveStyle == 2){
          // console.log('type 2')
          dnode.ptsAr = []
          var pp1x = ppxy.x, pp1y = ppxy.y;
          var curve1_x = this.curvePixel, curve1_y = this.curvePixel;
          if (Math.abs(pp1x - px) <= curve1_x * 2) {
            curve1_x = Math.abs(pp1x - px) / 2;
          }
          if (curve1_x > px) {
            curve1_x = 0;
          }
          if (Math.abs(dy - py) * 1 / 2 <= curve1_y * 2) {
            curve1_y = Math.abs(dy - py) * 1 / 2 / 2;
          }

          curvePixel_x = this.curvePixel, curvePixel_y = this.curvePixel;
          if (Math.abs(dy - py) * 1 / 2 <= curvePixel_y * 2) {
            curvePixel_y = Math.abs(dy - py) * 1 / 2 / 2;
          }
          if (Math.abs(dx - px) * 1 / 2 <= curvePixel_x * 2) {
            curvePixel_x = Math.abs(dx - px) * 1 / 2 / 2;
          }
          
          if (!dnode.isCollapse() && !dnode.isleaf()) {
            if (Math.abs(dx - px) <= this.curvePixel * 2) {
              current_xy2.x = (dx - Math.abs(dx - px) / 2);
            } else {
              current_xy2.x = (dx - this.curvePixel);
            }
          }
          var up = dy > py;
          pathPt.appendSegItem(Point.createPointStr(pp1x,pp1y))
          pathPt.appendSegItem(Point.createCubicArcStr(
            px - curve1_x, py, px, up ? py + curve1_y : py - curve1_y,px, up ? py + curve1_y * 2 : py - curve1_y * 2))
          pathPt.appendSegItem(Point.createPointStr(px, up ? dy - curvePixel_y * 2 : dy + curvePixel_y * 2,'L'))
          pathPt.appendSegItem(Point.createCubicArcStr(px,
            up ? dy - curvePixel_y : dy + curvePixel_y, px + curvePixel_x, dy,px + 2 * curvePixel_x, dy))
          pathPt.appendSegItem(Point.createPointStr(current_xy2.x, current_xy2.y,'L'))
          dnode.last_line = [Point.createPoint(px + 2 * curvePixel_x, dy),Point.createPoint(current_xy2.x,current_xy2.y)]
          if(ispolyObjLine){
            dnode.second_pt = [Point.createPointStr(px + 2 * curvePixel_x, dy,'M'),Point.createPointStr(current_xy2.x,current_xy2.y,'L')].join(" ")
          }
        }

        dnode.pathPt = pathPt.getSegPathListStr()
        dnode.stroke = branch_stroke_color
        dnode.fill = 'none'
        
				if (dnode.isCollapse()) { //9502
					var triHalfHeight = ((Math.log10(dnode.getNumberOfLeafDescendents()) + 1) * this.collapse_pct
							/ 2 * this.pxPerHeight);
					var segs4tri = new Point();
					segs4tri.appendSegItem(Point.createPointStr(dx, dy));
					segs4tri.appendSegItem(Point.createSVGPathSegLinetoAbs(dx + this.pxPerWidthCurrentMode, dy - triHalfHeight)); // line from p1 to p2;
					segs4tri.appendSegItem(Point.createSVGPathSegLinetoAbs(dx + this.pxPerWidthCurrentMode, dy + triHalfHeight)); // line from p2 to p3;
					segs4tri.appendSegItem(Point.createSVGPathSegLinetoAbs(dx, dy)); // line from p3 to p1;
					segs4tri.closeSegItem(); // close path
          
          dnode.tri = segs4tri.getSegPathListStr()
          dnode.tri_stroke = branch_stroke_color
          dnode.tri_fill = 'none'
					
          var hmStyles = dnode.getCollapseStyles();
          var tri_styles = {}
					if (hmStyles != null) {
						if (hmStyles.hasOwnProperty("color")) {
              tri_styles["stroke"] = hmStyles["color"]
						}
						if (hmStyles.hasOwnProperty("linewidth")) {
              tri_styles["stoke-width"] = hmStyles["linewidth"]
						}
						if (hmStyles.hasOwnProperty("linestyle") && StringOps.equalsIgnoreCase(hmStyles["linestyle"],("dashed"))) {
              tri_styles["stroke-dasharray"] = "2,2"
						}
          }
          dnode.tri_styles = tri_styles
        }
        
        var current_xy = {'x':(dnode.isCollapse()) ? dx + this.pxPerWidthCurrentMode : dx,'y':dy}
        var node_internal_id = dnode.getInternalID();
        if (dnode.isleaf() || dnode.isCollapse()) {
          this.leafIDtoInternalID[dnode.getID()] = node_internal_id
          this.LeafInternalID2NodePosisionts[node_internal_id] = {'pt':current_xy, 'angle':0, 'verticalLevel':0, 'number_leaf_decendents':dnode.getNumberOfLeafDescendents()}
          if (!this.isEmpty(dnode.getID())) {
            this.hmLeafID2NodePositions[dnode.getID()] =  {'pt':current_xy, 'angle':0, 'verticalLevel':dnode.getLevelVertical(), 'number_leaf_decendents':dnode.getNumberOfLeafDescendents()};
          }
          this.hmLeafID2NodePositions[dnode.getInternalID()] =  {'pt':current_xy, 'angle':0, 'verticalLevel':dnode.getLevelVertical(), 'number_leaf_decendents':dnode.getNumberOfLeafDescendents()};
          // this.hmLeafInternalID2LeafPath[node_internal_id] = {'x1':px,'y1':py,'x2':dx,'y2':dy}

          if (dnode.isCollapse()) {
						this.BootstrapPositions[node_internal_id] =  {'pt':{'x':dx,'y':dy}, 'angle':0};
					}
        }else{
          this.BootstrapPositions[node_internal_id] =  {'pt':current_xy, 'angle':0};
          this.drawRect(dnode, current_xy, current_xy2, maxHorizLevel);
        }
        this.BranchLengthPositions[node_internal_id] = {'pt':{'x':(px + dx) / 2, 'y':dy},'angle':0}
      }
    }
    EventBus.$emit('leafnames',this.hmLeafID2NodePositions)    
  }

  drawSlant(pnode, parentxy, atan, x_of_first_leaf){ //9274
    var px = parentxy.x
    var py = parentxy.y
    var vlevelS = pnode.getLevelVerticalSlanted();
		var vlevel = pnode.getLevelVertical();
    var hlevel = pnode.getLevelHorizontal();
    
    for(var ind in pnode.getDescendents()){
      var dnode = pnode.getDescendents()[ind]
      dnode.clearPtsAr()
      let branch_stroke_color = (this.branchColorEnabled && !this.disable_all_dataset
        && this.activeBranchColorSetID.length>=1)
            ? dnode.getBranchColorByColorsetID(this.activeBranchColorSetID)
            : "black";
      let branch_stroke = (this.branchColorEnabled && !this.disable_all_dataset
          && this.activeBranchColorSetID.length >=1)
              ? dnode.getBranchStrokeByColorsetID(this.activeBranchColorSetID)
              : "";

      if(branch_stroke.length >1){
        var branchstAr = branch_stroke.split(":")
        if(branchstAr.length>=2){ // stroke with varying dash width or line with leaf width
          if(branchstAr[0] === 'dash'){
            dnode.stroke_typ = "dash-array"
            dnode.dash = branchstAr[1]
          }else{
            dnode.stroke_typ = "line"
          }
          dnode.stroke_wd = parseInt(branchstAr[1])
        }else{ //dashed line
          dnode.stroke_typ = "dash-array"
        }
      }else{
        dnode.stroke_typ = ''
      }

      var ispolyObjLine = dnode.stroke_typ != null && dnode.stroke_typ.length > 1 && dnode.stroke_typ === 'line'

      var vlS = dnode.getLevelVerticalSlanted();
			var vl = dnode.getLevelVertical();
			var hl = dnode.getLevelHorizontal();

			var min_leaf_v = dnode.getMinLeafVerticalLevel();
			var max_leaf_v = dnode.getMaxLeafVerticalLevel();

			var y_min_leaf = min_leaf_v * this.pxPerHeight + this.margin_y;
      var dx = 0, dy = 0;
      if (this.plotmode == treePlotMode.SLANTED_CLADOGRAM_RECT) {
        dx = px + (hlevel - hl) * this.pxPerWidthCurrentMode;
        dy = py + (vl - vlevel) * this.pxPerHeight;
      } else if (this.plotmode == treePlotMode.SLANTED_CLADOGRAM_MIDDLE) {
        dx = px + (hlevel - hl) * this.pxPerWidthCurrentMode;
        dy = py + (vlS - vlevelS) * this.pxPerHeight;
      } else if (this.plotmode == treePlotMode.SLANTED_CLADOGRAM_NORMAL) {
        /*
         * NOTE: in this mode, the position of internal node is determined
         * by it's first leaf node and last leaf node. its XY-coordinates are: y = (Y of
         * its first leaf node + Y of its last leaf node) / 2; x is a little
         * complicated; here is howto:
         *
         * 1. suppose coordinateXY of root is : x1, y1, and the first leaf is: x2, y2;
         * then we'll have a global angle for the whole plot: atan = Math.atan( (y2 -
         * y1) / (x2 - x1) ); 2. for internal nodes, x = X of leaf node (all leaf nodes
         * have the same X) - (Y of its last leaf node - Y of its first leaf node) / 2 /
         * Math.tan(atan);
         *
         * the output is similar to SLANTED_CLADOGRAM in Dendroscope
         */
        dx = dnode.isleaf() ? x_of_first_leaf
            : x_of_first_leaf - ((max_leaf_v - min_leaf_v) / 2 * this.pxPerHeight) / Math.tan(atan); // it's
                                                          // very
                                                          // strange
                                                          // here
        dy = y_min_leaf + (max_leaf_v - min_leaf_v) / 2 * this.pxPerHeight;
      }

      dnode.addToPtsAr({'x':px,'y':py})
      dnode.addToPtsAr({'x':dx,'y':dy})
      dnode.stroke = branch_stroke_color
      dnode.last_line = [Point.createPoint(px,py),Point.createPoint(dx,dy)]
      
      var current_xy = {'x':dx,'y':dy}
      var node_internal_id = dnode.getInternalID();

      if (dnode.isleaf() == false) {
        this.BootstrapPositions[node_internal_id] =  {'pt':current_xy, 'angle':0};
        this.drawSlant(dnode, current_xy, atan, x_of_first_leaf);
      }else{
        this.leafIDtoInternalID[dnode.getID()] = node_internal_id
        this.LeafInternalID2NodePosisionts[node_internal_id] = {'pt':current_xy, 'angle':0, 'verticalLevel':0, 'number_leaf_decendents':dnode.getNumberOfLeafDescendents()}
        this.hmLeafID2NodePositions[dnode.getID()] =  {'pt':current_xy, 'angle':0, 'verticalLevel':dnode.getLevelVertical(),'number_leaf_decendents':dnode.getNumberOfLeafDescendents()};
        // this.hmLeafInternalID2LeafPath[node_internal_id] = {'x1':px,'y1':py,'x2':dx,'y2':dy}
      }
      this.BranchLengthPositions[node_internal_id] = {'pt':{'x':(px + dx) / 2, 'y':(py + dy) / 2},'angle':0}
    }
    EventBus.$emit('leafnames',this.hmLeafID2NodePositions)    
  }

  //test tree not yet finished
  drawUnrooted(pnode, parentxy, atan, x_of_first_leaf){
    var px = parentxy.x
    var py = parentxy.y
    var vlevelS = pnode.getLevelVerticalSlanted();
		var vlevel = pnode.getLevelVertical();
    var hlevel = pnode.getLevelHorizontal();
    
    for(var ind in pnode.getDescendents()){
      var dnode = pnode.getDescendents()[ind]
      dnode.clearPtsAr()
      let branch_stroke_color = (this.branchColorEnabled && !this.disable_all_dataset
        && this.activeBranchColorSetID.length>=1)
            ? dnode.getBranchColorByColorsetID(this.activeBranchColorSetID)
            : "black";
      let branch_stroke = (this.branchColorEnabled && !this.disable_all_dataset
          && this.activeBranchColorSetID.length >=1)
              ? dnode.getBranchStrokeByColorsetID(this.activeBranchColorSetID)
              : "";

      if(branch_stroke.length >1){
        var branchstAr = branch_stroke.split(":")
        if(branchstAr.length>=2){ // stroke with varying dash width or line with leaf width
          if(branchstAr[0] === 'dash'){
            dnode.stroke_typ = "dash-array"
            dnode.dash = branchstAr[1]
          }else{
            dnode.stroke_typ = "line"
          }
          dnode.stroke_wd = parseInt(branchstAr[1])
        }else{ //dashed line
          dnode.stroke_typ = "dash-array"
        }
      }else{
        dnode.stroke_typ = ''
      }

      var ispolyObjLine = dnode.stroke_typ != null && dnode.stroke_typ.length > 1 && dnode.stroke_typ === 'line'

      var vlS = dnode.getLevelVerticalSlanted();
			var vl = dnode.getLevelVertical();
			var hl = dnode.getLevelHorizontal();

      var min_leaf_v = dnode.getMinLeafVerticalLevel();
			var max_leaf_v = dnode.getMaxLeafVerticalLevel();

			var y_min_leaf = min_leaf_v * this.pxPerHeight + this.margin_y;
      var dx = 0, dy = 0;
    }
  }

  drawCircle(pnode, parentxy, ppxy, pangle){ //8961
    var tparentxy = this.svgDom.createSVGPoint(parentxy.x,parentxy.y)
    tparentxy.x = parentxy.x
    tparentxy.y = parentxy.y
    var tppxy = this.svgDom.createSVGPoint(ppxy.x,ppxy.y)
    tppxy.x = ppxy.x
    tppxy.y = ppxy.y
    var px = parentxy.x
    var py = parentxy.y

    var vlevel = pnode.getLevelVertical();

    var pradius = (this.plotmode == treePlotMode.CIRCULAR_CLADOGRAM)
					? (this.rootnode.getLevelHorizontal() - pnode.getLevelHorizontal()) * this.pxPerWidthCurrentMode
          : pnode.getBranchLengthToRoot() * this.pxPerBranchLength;
    
    for(var ind in pnode.getDescendents()){
      var dnode = pnode.getDescendents()[ind]

      const branch_stroke_color = (this.branchColorEnabled && !this.disable_all_dataset
        && this.activeBranchColorSetID.length>=1)
            ? dnode.getBranchColorByColorsetID(this.activeBranchColorSetID)
            : "black";
      let branch_stroke = (this.branchColorEnabled && !this.disable_all_dataset
          && this.activeBranchColorSetID.length >=1)
              ? dnode.getBranchStrokeByColorsetID(this.activeBranchColorSetID)
              : "";

      if(branch_stroke.length >1){
        var branchstAr = branch_stroke.split(":")
        if(branchstAr.length>=2){ // stroke with varying dash width or line with leaf width
          if(branchstAr[0] === 'dash'){
            dnode.stroke_typ = "dash-array"
            dnode.dash = branchstAr[1]
          }else{
            dnode.stroke_typ = "line"
          }
          dnode.stroke_wd = parseInt(branchstAr[1])
        }else{ //dashed line
          dnode.stroke_typ = "dash-array"
        }
      }else{
        dnode.stroke_typ = ''
      }
      var ispolyObjLine = dnode.stroke_typ != null && dnode.stroke_typ.length > 1 && dnode.stroke_typ === 'line'

      var vl = dnode.getLevelVertical(), dradius = 0;
			if (this.plotmode == treePlotMode.CIRCULAR_CLADOGRAM) {
					var hl = dnode.isCollapse() ? 2 : dnode.getLevelHorizontal(); // dec 14, 2015;
					dradius = (this.rootnode.getLevelHorizontal() - hl) * this.pxPerWidthCurrentMode;
			} else {
					dradius = (dnode.getBranchLengthToRoot() * this.pxPerBranchLength);
      }
      
      var angle = this.circular_tree_plot_in_clockwise_mode
						? -(dnode.getLevelVertical() - 1) * this.angle_per_vertical_level - 180
						:  (dnode.getLevelVertical() - 1) * this.angle_per_vertical_level - 180; // angle = 360 -
																										// angle

			angle += (360 + this.angle_start);
			angle %= 360;
			if (angle < 0) {
					angle += 360;
      }
      
      var dxy = Point.getCoordinatesOnCircle(this.rootXY,dradius,angle)
      var pxy = Point.getCoordinatesOnCircle(this.rootXY, pradius, angle)
      var dxy2 = this.svgDom.createSVGPoint(dxy);

      var sweepFlag = vl < vlevel ? false : true;
			if (!this.circular_tree_plot_in_clockwise_mode) {
				sweepFlag = !sweepFlag;
      }
      var current_curvePixel = this.curvePixel * 2;
      var needed_angle_span = Math.PI * pradius * 2 / current_curvePixel;
      var angle_dif = this.angle_per_vertical_level
            * Math.abs(dnode.getLevelVertical() - pnode.getLevelVertical());
      var dBranchLength = this.distToPoint(pxy,dxy) //pxy.distance(dxy);
      var pathPt = new Point()

      if (this.treeCurveStyle == 0
        || ((this.plotmode == (treePlotMode.CIRCULAR_PHYLOGRAM)
            || this.plotmode == (treePlotMode.RECT_PHYLOGRAM)) && dnode.getBranchLength() == 0)
        || (pnode.isroot() && (dnode.isleaf() || dnode.isCollapse()))) {
        
        pathPt.appendSegItem(Point.createPointStr(px, py));
				pathPt.appendSegItem(Point.createArcStr(pxy.x, pxy.y, pradius, 0, 0, sweepFlag === true ? 1 : 0 ));
        pathPt.appendSegItem(Point.createPointStr(dxy.x, dxy.y,'L'));
        dnode.last_line = [Point.createPoint(pxy.x, pxy.y),Point.createPoint(dxy.x, dxy.y)]
        if(ispolyObjLine){
          dnode.second_pt = [Point.createPointStr(pxy.x, pxy.y,'M'),Point.createPointStr(dxy.x, dxy.y,'L')].join(" ")
        }
        // console.log(dnode.id,dnode.getInternalID(),px,py,pxy,dxy,pradius,dradius)
      } else if (this.treeCurveStyle === 1) {
        var c1, a1;
					if (needed_angle_span > angle_dif) {
						c1 = Point.getCoordinatesOnCircle(this.rootXY, pradius,
								sweepFlag ? angle + angle_dif / 2 : angle - angle_dif / 2);
						a1 = Point.getCoordinatesOnCircle(this.rootXY, pradius,
								sweepFlag ? angle + angle_dif / 4 : angle - angle_dif / 4);
					} else {
						c1 = Point.getCoordinatesOnCircle(this.rootXY, pradius,
								sweepFlag ? angle + needed_angle_span / 2 : angle - needed_angle_span / 2);
						a1 = Point.getCoordinatesOnCircle(this.rootXY, pradius,
								sweepFlag ? angle + needed_angle_span / 4 : angle - needed_angle_span / 4);
					}
				// 2. 计算 connecting point 2 && anchor point 2 --
				var c2 = Point.getCoordinatesOnCircle(this.rootXY,
							dBranchLength > current_curvePixel * 2 ? pradius + current_curvePixel
									: pradius + dBranchLength / 2,
							angle);
				var a2 = Point.getCoordinatesOnCircle(this.rootXY,
							dBranchLength > current_curvePixel * 2 ? pradius + current_curvePixel / 2
									: pradius + dBranchLength / 4,
              angle);
        
        pathPt.appendSegItem(Point.createPointStr(px, py)); // move to point 1
				pathPt.appendSegItem(Point.createArcStr(c1.x, c1.y, pradius, 0, 0, sweepFlag === true ? 1:0)); // Arc to connecting point  1
					// curve to point connecting point 2; ...
				pathPt.appendSegItem(Point.createCubicArcStr(a1.x, a1.y,
							a2.x, a2.y,c2.x, c2.y));
        pathPt.appendSegItem(Point.createPointStr(dxy.x, dxy.y,'L'));
        dnode.last_line = [Point.createPoint(c2.x, c2.y),Point.createPoint(dxy.x, dxy.y)]
        if(ispolyObjLine){
          dnode.second_pt = [Point.createPointStr(c2.x, c2.y,'M'),Point.createPointStr(dxy.x, dxy.y,'L')].join(" ")
        }
      }else if (this.treeCurveStyle === 2) {
        var dist1 = this.distToPoint(tppxy,tparentxy) //tppxy.distance(tparentxy);
				var pa1 = Point.getCoordinatesOnCircle(this.rootXY,
							dist1 > current_curvePixel ? pradius - current_curvePixel / 2 : pradius - dist1 / 4,
              pangle);
        var pc, pa2, da1, dc1;
        if (needed_angle_span < angle_dif) {
          pc = Point.getCoordinatesOnCircle(this.rootXY, pradius,
              sweepFlag ? pangle - needed_angle_span / 2 : pangle + needed_angle_span / 2);
          dc1 = Point.getCoordinatesOnCircle(this.rootXY, pradius,
              sweepFlag ? angle + needed_angle_span / 2 : angle - needed_angle_span / 2);
          pa2 = Point.getCoordinatesOnCircle(this.rootXY, pradius,
              sweepFlag ? pangle - needed_angle_span / 4 : pangle + needed_angle_span / 4);
          da1 = Point.getCoordinatesOnCircle(this.rootXY, pradius,
              sweepFlag ? angle + needed_angle_span / 4 : angle - needed_angle_span / 4);
        } else {
          pc = Point.getCoordinatesOnCircle(this.rootXY, pradius,
              sweepFlag ? pangle - angle_dif / 2 : pangle + angle_dif / 2);
          dc1 = Point.getCoordinatesOnCircle(this.rootXY, pradius,
              sweepFlag ? angle + angle_dif / 2 : angle - angle_dif / 2);
          pa2 = Point.getCoordinatesOnCircle(this.rootXY, pradius,
              sweepFlag ? pangle - angle_dif / 4 : pangle + angle_dif / 4);
          da1 = Point.getCoordinatesOnCircle(this.rootXY, pradius,
              sweepFlag ? angle + angle_dif / 4 : angle - angle_dif / 4);
        }

        var dc2 = Point.getCoordinatesOnCircle(this.rootXY,
          dBranchLength > current_curvePixel * 2 ? pradius + current_curvePixel
              : pradius + dBranchLength / 2,
          angle);
        dxy2 = Point.getCoordinatesOnCircle(this.rootXY,
          dBranchLength > current_curvePixel * 2 ? dradius - current_curvePixel
              : pradius + dBranchLength / 2,
          angle);
        var da2 = Point.getCoordinatesOnCircle(this.rootXY,
          dBranchLength > current_curvePixel * 2 ? pradius + current_curvePixel / 2
              : pradius + dBranchLength / 4,
          angle);
        
        // move to connect point 1 on the parent, which is ppxy ...
        pathPt.appendSegItem(Point.createPointStr(ppxy.x, ppxy.y));
        // cubic curve to connecting point pc, using anchor points pa1 & pa2 --
        pathPt.appendSegItem(Point.createCubicArcStr(pa1.x, pa1.y,
            pa2.x, pa2.y,pc.x, pc.y));
        if (needed_angle_span < angle_dif) {
          pathPt.appendSegItem(Point.createArcStr(dc1.x, dc1.y, pradius, 0, 0,
              sweepFlag === true ? 1:0)); // Arc to connecting point 1
        }
        // curve to point connecting point dc2 on dnode branch, using anchor points da1
        // & da2; ...
        pathPt.appendSegItem(Point.createCubicArcStr( da1.x, da1.y,
            da2.x, da2.y,dc2.x, dc2.y));

        // line to dxy2 if necessary
        if (dnode.isleaf() || dnode.isCollapse()) {
          pathPt.appendSegItem(Point.createPointStr(dxy.x, dxy.y,'L')); // line to point dxy
          dnode.last_line = [Point.createPoint(dc2.x, dc2.y),Point.createPoint(dxy.x, dxy.y)]
          if(ispolyObjLine){
            dnode.second_pt = [Point.createPointStr(dc2.x, dc2.y,'M'),Point.createPointStr(dxy.x, dxy.y,'L')].join(" ")
          }
        } else if (dBranchLength > current_curvePixel * 2) {
          pathPt.appendSegItem(Point.createPointStr(dxy2.x, dxy2.y,'L')); // line to point dxy
          dnode.last_line = [Point.createPoint(dc2.x, dc2.y),Point.createPoint(dxy2.x, dxy2.y)]
          if(ispolyObjLine){
            dnode.second_pt = [Point.createPointStr(dc2.x, dc2.y,'M'),Point.createPointStr(dxy2.x, dxy2.y,'L')].join(" ")
          }
        }        
      }

      dnode.stroke = branch_stroke_color
      dnode.pathPt = pathPt.getSegPathListStr()
      

      if (dnode.isCollapse()) {
        var current_anglespan = ((Math.log10(dnode.getNumberOfLeafDescendents()) + 1)
            * this.angle_per_vertical_level);

        var p2 = Point.getCoordinatesOnCircle(this.rootXY, dradius + this.pxPerWidthCurrentMode,
            angle - current_anglespan * this.collapse_pct / 2);
        var p3 = Point.getCoordinatesOnCircle(this.rootXY, dradius + this.pxPerWidthCurrentMode,
            angle + current_anglespan * this.collapse_pct / 2);

        var segs4tri = new Point()
        segs4tri.appendSegItem(Point.createPointStr(dxy.x, dxy.y)); // move to point 1
        segs4tri.appendSegItem(Point.createPointStr(p2.x, p2.y,'L')); // line from p1 to p2;
        segs4tri.appendSegItem(Point.createPointStr(p3.x, p3.y,'L')); // line from p2 to p3;
        segs4tri.appendSegItem(Point.createPointStr(dxy.x, dxy.y,'L')); // line from p3 back to
                                              // p1 ...
        segs4tri.closeSegItem(); // close path

        dnode.tri = segs4tri.getSegPathListStr()
        dnode.tri_stroke = branch_stroke_color
        dnode.tri_fill = 'none'
        
        var hmStyles = dnode.getCollapseStyles();
        var tri_styles = {}
				if (hmStyles != null) {
					if (hmStyles.hasOwnProperty("color")) {
              tri_styles["stroke"] = hmStyles["color"]
					}
					if (hmStyles.hasOwnProperty("linewidth")) {
              tri_styles["stoke-width"] = hmStyles["linewidth"]
					}
					if (hmStyles.hasOwnProperty("linestyle") && StringOps.equalsIgnoreCase(hmStyles["linestyle"],("dashed"))) {
              tri_styles["stroke-dasharray"] = "2,2"
					}
        }
        dnode.tri_styles = tri_styles        
      }
      dnode.angle = angle
      var node_internal_id = dnode.getInternalID();
			var current_xy = {'x':dnode.isCollapse() ? this.rootXY.x + dradius + this.pxPerWidthCurrentMode : this.rootXY.x + dradius,'y':this.rootXY.y}
      if (dnode.isleaf() || dnode.isCollapse()) {
        this.leafIDtoInternalID[dnode.getID()] = node_internal_id
        this.LeafInternalID2NodePosisionts[node_internal_id] = {'pt':{'x':current_xy.x,'y':current_xy.y}, 'angle':angle, 'verticalLevel':0, 'number_leaf_decendents':dnode.getNumberOfLeafDescendents()}
        if (!this.isEmpty(dnode.getID())) {
          this.hmLeafID2NodePositions[dnode.getID()] =  {'pt':{'x':current_xy.x,'y':current_xy.y}, 'angle':angle, 'verticalLevel':dnode.getLevelVertical(), 'number_leaf_decendents':dnode.getNumberOfLeafDescendents()};
        }
        this.hmLeafID2NodePositions[dnode.getInternalID()] =  {'pt':{'x':current_xy.x,'y':current_xy.y}, 'angle':angle, 'verticalLevel':dnode.getLevelVertical(), 'number_leaf_decendents':dnode.getNumberOfLeafDescendents()};
        if (dnode.isCollapse()) {
          this.BootstrapPositions[node_internal_id] = {'pt':{'x':this.rootXY.x + dradius,'y': this.rootXY.y}, 'angle':angle};
        }
        // this.hmLeafInternalID2LeafPath[node_internal_id] = {'x1':px,'y1':py,'x2':dx,'y2':dy}
      }else{
        this.BootstrapPositions[node_internal_id] = {'pt':{'x':current_xy.x,'y':current_xy.y}, 'angle':angle};
				this.drawCircle(dnode, {'x':dxy.x,'y':dxy.y}, {'x':dxy2.x,'y':dxy2.y}, angle);
      }
      this.BranchLengthPositions[node_internal_id] = {'pt':{'x':this.rootXY.x + (dradius + pradius) / 2, 'y':this.rootXY.y},'angle':angle}
    }
    EventBus.$emit('leafnames',this.hmLeafID2NodePositions)    
  }
  
  distToPoint(pt1,pt2){
    return Math.sqrt(this.square(pt2.x-pt1.x)+this.square(pt2.y-pt1.y))
  }

  square(pt){
    return pt*pt
  }

  drawLinesfromLeafLabel2charts(currentMostRightPosition) {
		// Sep 22, 2014;
		this.clearAllChildNodesFromSVGGElement(this.layer_tree_line2leaf_label); // clean

		// plot --
		for (var ind in this.LeafInternalID2LabelEndPosisionts) {
      var kv = this.LeafInternalID2LabelEndPosisionts[ind]
			var pos = kv;
			var angle = pos.angle;

			var line = this.layer_tree_line2leaf_label.line(pos.pt.x + this.space_between_treeskeleton_and_leaflabels / 2,
					pos.pt.y, currentMostRightPosition , pos.pt.y);

			if ((this.plotmode == treePlotMode.CIRCULAR_CLADOGRAM || this.plotmode == treePlotMode.CIRCULAR_PHYLOGRAM)) {
        line.transform({rotation:180 - angle,cx:this.rootXY.x,cy:this.rootXY.y})
			}
		}
  } 
  
  makeLeafLabelsAndBranchLengthAndBootstrapValues(){ //7704
    this.makeLeafLabels()
    this.makeBranchLengthLabels()
    this.makeBootstrapLabels()
  }

  makeLeafLabels() { //7704
    this.clearAllChildNodesFromSVGGElement(this.layer_tree_leaf_labels);
    this.clearAllChildNodesFromSVGGElement(this.layer_tree_align_leaf_labels);
    this.clearAllChildNodesFromSVGGElement(this.layer_tree_leaflabel_decoration)
    this.clearAllChildNodesFromSVGGElement(this.layer_imageLabel)
    this.clearAllChildNodesFromSVGGElement(this.layer_leafAnnotate)
    this.clearAllChildNodesFromSVGGElement(this.layer_geneTransfer)
    this.calcRightMostTreeAndLabelPosisions()
    this.leafID2LeafAlignOMNode = {}
    this.layer_tree_leaflabel_decoration.forward()
    this.layer_tree_leaf_labels.forward()
    this.layer_tree_align_leaf_labels.forward()
    this.layer_imageLabel.forward()
    this.layer_leafAnnotate.backward()


    var bAlignleaflable = (this.alignLeafLabels == true && (this.plotmode == (treePlotMode.RECT_PHYLOGRAM)
					|| this.plotmode == (treePlotMode.CIRCULAR_PHYLOGRAM))) ? true : false;
			// April 8, 2011; opacity
		var opacity = this.dataset2opacity.hasOwnProperty(this.activeLeafColorSetID)
					? this.dataset2opacity[this.activeLeafColorSetID]
    			: 1;
    var isCircular = this.plotmode === (treePlotMode.CIRCULAR_CLADOGRAM)
          || this.plotmode === (treePlotMode.CIRCULAR_PHYLOGRAM);
  
    var fontPxHeight = this.default_font_size

    var decoCon = this.getActiveLeafDecorationDataset();
		var leafid2decos = decoCon === null ? null
          : decoCon.getLeafID2Decoration();

    var labelImgCon = this.getActiveLeafImageDataset()
    var labImgRowDat = labelImgCon === null ? null : labelImgCon.getRow2data()
    var labImgId2originalID = labelImgCon === null ? null : labelImgCon.getId2originalID()
    var imgLblPlotLyr = this.layer_imageLabel.group().id('plot_layer')

    var mostrightTree = this.getTreeMostRightPosition();
    var imgLblWdth = 0

    var leafAnnObj = this.activeBranchAnnotateID != null && this.leafAnnMap.hasOwnProperty(this.activeBranchAnnotateID) ?  this.leafAnnMap[this.activeBranchAnnotateID] : null    
    var leafAnnDatset = leafAnnObj === null ? null : leafAnnObj.getDatasetFromParser()
    var leafAnnObjrow2data = leafAnnDatset === null ? null : leafAnnDatset.getTreeNodeID2Data(); //
    var leafAnnObjId2originalID = leafAnnDatset === null ? null : leafAnnDatset.getId2originalID()

    var geneTransObj = this.activeGeneTransferID != null && this.geneTransferMap.hasOwnProperty(this.activeGeneTransferID) ? this.geneTransferMap[this.activeGeneTransferID] : null
    var geneTransDatset = geneTransObj === null ? null : geneTransObj.getDatasetFromParser()
    
    for(let entry in this.LeafInternalID2NodePosisionts){
      var leaf_internal_id = entry
      var leafnode = this.pyTree.getNodeByID(leaf_internal_id);
      var posDet = this.LeafInternalID2NodePosisionts[entry]

      var leaf_id = (!leafnode.getHTMLname() && this.bHTMLmode) ? leafnode.getHTMLname()
            : this.isEmpty(leafnode.getID()) ? leafnode.getInternalID() : leafnode.getID();
            
      if (leafnode.isCollapse() && leafnode.getCollapseStyles() != null
						&& leafnode.getCollapseStyles().hasOwnProperty("name")) {
					leaf_id = leafnode.getCollapseStyles()["name"];
			}

      // console.log(leaf_id,leafnode.getHTMLname(),this.bHTMLmode)
      if(leaf_id == undefined){
        leaf_id = leafnode.getID() ? leafnode.getID() : leafnode.getInternalID()
      }
      // console.log(leaf_id,leafnode.getHTMLname(),this.bHTMLmode)
      var leaf_id_without_underline = leaf_id.replace('_', ' ').replace('.','');
      var leafWidth = (leaf_id_without_underline.length * this.font_width_height_ratio * this.getFontSize());

      var x = (bAlignleaflable) ? mostrightTree + this.space_between_treeskeleton_and_leaflabels
						: posDet.pt.x + this.space_between_treeskeleton_and_leaflabels;
			var y = posDet.pt.y + fontPxHeight / 4;
      var angle = posDet.angle;

      // console.log(leafnode.getID(),leaf_id_without_underline,x,y)
      var nodeLabel = this.layer_tree_leaf_labels.text(leaf_id_without_underline)
      var nodeHtmlLabel = this.layer_tree_leaf_labels.foreignObject(leafWidth * 1.5,fontPxHeight * 1.5)
      if(leafnode.getHTMLname() != undefined){
        nodeHtmlLabel.appendChild("body",{id:"htmlnode",innerHTML:leafnode.getHTMLname().replace('"','')})
      }else{
        nodeHtmlLabel.appendChild("body",{id:"textnode",innerHTML: leaf_id_without_underline})
      }
      nodeHtmlLabel.hide()
      /**
       * ===========================================================================
       * plot decoration -- will also change the coordinates for
       * x,y note: for now, I will use font size as the height and width of the
       * decorational objects; the space between objects is 1 >>> Dec 16, 2015; +
       * added support for internal collapse ...
       * =========================================================================
       */
      if (this.leafDecoEnabled && !this.disable_all_dataset) {
        if (decoCon != null && leafid2decos != null && leafid2decos.hasOwnProperty(leaf_id)) {
        var decox = x, decoy = y;
        var g = this.layer_tree_leaflabel_decoration.group().id('deco_group')
        for (var ind in leafid2decos[leaf_id]) {
                var da = leafid2decos[leaf_id][ind]
          // make a shape and attach it to 'g'
          var ashape = this.makeAShape(da.getStyle(), decox,
            decoy - fontPxHeight / 4 - this.getFontSize() / 2, this.getFontSize(), this.getFontSize(),
            da.getColor(), da.getStrokeColor(), decoCon.getDefaultStrokeWidth(), angle, g);
          // re-calculate the 'x'
          decox += this.getFontSize() + this.space_between_columns_within_a_dataset;
        }
        // new position for leaf label
        x = decox;
        }
      } // leaf deco
      

      //check for leaf label images
      if(this.labelImagesEnabled && !this.disable_all_dataset && labelImgCon != null && labImgRowDat != null ){
        let topPts = null,botPts = null
        let topLabel = null,botLabel = null
        const cdata = labImgRowDat[leaf_internal_id];
        const rowplot = imgLblPlotLyr.group().id('rowplot')
        this.showNodeLabel = labelImgCon.isShowLeafLab()
        if(this.LeafInternalID2NodePosisionts.hasOwnProperty(leaf_internal_id)){
          topPts = this.LeafInternalID2NodePosisionts[leaf_internal_id];
        }else{
            if(labImgId2originalID.hasOwnProperty(leaf_internal_id)){
                let leaf_id_ar = labImgId2originalID[leaf_internal_id]
                topPts = leaf_id_ar.length >=1 && this.hmLeafID2NodePositions.hasOwnProperty(leaf_id_ar[0]) ? this.hmLeafID2NodePositions[leaf_id_ar[0]] : null
                botPts = leaf_id_ar.length >=2 &&this.hmLeafID2NodePositions.hasOwnProperty(leaf_id_ar[1]) ? this.hmLeafID2NodePositions[leaf_id_ar[1]] : null                        
            }
        }
        if(cdata != null){
          let img_src = cdata.getImage().length > 1 ? cdata.getImage() : 'image/labelGraphics/cow.png'
          if(cdata.getImage().length === 1 && parseInt(cdata.getImage()) >=0 && parseInt(cdata.getImage()) <= this.def_img_ar.length){
              img_src = this.def_img_ar[parseInt(cdata.getImage())]
          }
          if(topPts != null){                    
            topLabel = rowplot.image(img_src).size(cdata.getImageWidth(),cdata.getImageHeight()).move(x,y- fontPxHeight+3)
          }
          if(botPts != null){
              botLabel = rowplot.image(img_src).size(cdata.getImageWidth(),cdata.getImageHeight()).move(x,y- fontPxHeight+3)
          }
          if(isCircular){
              if(topLabel != null){
                  topLabel.transform({rotation: 180 - angle, cx: this.rootXY.x, cy:this.rootXY.y})
              }
              if(botLabel != null){
                  botLabel.transform({rotation: 180 - angle, cx: this.rootXY.x, cy:this.rootXY.y})
              }
          }
          // x += cdata.getImageWidth() + this.space_between_treeskeleton_and_leaflabels
          if(imgLblWdth<cdata.getImageWidth()+ this.space_between_datasets){
            imgLblWdth = cdata.getImageWidth() + this.space_between_datasets
          }
        }
      }

      if(!this.labelImagesEnabled){
        imgLblWdth = 0
      }
      
      nodeLabel.move(x+imgLblWdth,y- fontPxHeight+3)
      nodeLabel.font({
        family: this.default_font_family
        , size: this.default_font_size
        , style: this.leaf_font_italic ? FontStyle.ITALIC : FontStyle.NORMAL
      })
      nodeHtmlLabel.move(x+imgLblWdth,y- fontPxHeight+3)
      if (isCircular) {
        // and then rotate
        nodeLabel.transform({ rotation: 180-angle, cx: this.rootXY.x, cy:this.rootXY.y })
        this.betterReadabilityForSVGTextElem(nodeLabel, angle, x+imgLblWdth, y - this.fontPxHeight / 4, "end");        

        nodeHtmlLabel.transform({ rotation: 180-angle, cx: this.rootXY.x, cy:this.rootXY.y })        
      }

      var textfillcolor = (this.leafColorEnabled && this.activeLeafColorSetID !=null && !this.disable_all_dataset)
						? leafnode.getLeafColorByColorsetID(this.activeLeafColorSetID)
            : "black";
      // console.log(leaf_id_without_underline,textfillcolor)
      nodeLabel.font({
        fill:textfillcolor
      })

      var mostRightLeafPos = 0
			
      mostRightLeafPos = this.showNodeLabel ? x + leafWidth + imgLblWdth: x
      if (mostRightLeafPos > this.getLeafMostRightPosition()) {
        this.setLeafMostRightPosition(mostRightLeafPos);
      }

      this.LeafInternalID2LabelEndPosisionts[leaf_internal_id] =  {'pt':{'x':mostRightLeafPos,'y':posDet.pt.y},'angle':posDet.angle};
      if (bAlignleaflable) {
        // if OMNode objects for leaf label not exist; make a SVGtext object and put it
        // at (0,0)
        if (!this.leafID2LeafAlignOMNode.hasOwnProperty(leaf_internal_id)) {
          var line = this.layer_tree_align_leaf_labels.line(posDet.pt.x  + this.space_between_treeskeleton_and_leaflabels / 2, posDet.pt.y,
          mostrightTree + this.space_between_treeskeleton_and_leaflabels / 2, posDet.pt.y ).stroke({ width: 1 ,color:  '#363332',dasharray: '4,4'})
          
          // add it to leafID2OMNode as well as tree_leaf_node layer
          this.leafID2LeafAlignOMNode[leaf_internal_id] =  line;
        }
        /*
         * update position of aligned lines
         */
        var line = this.leafID2LeafAlignOMNode[leaf_internal_id];
        line.plot((posDet.pt.x + this.space_between_treeskeleton_and_leaflabels / 2),posDet.pt.y,
        (mostrightTree + this.space_between_treeskeleton_and_leaflabels / 2),posDet.pt.y)    
          
        if ((this.plotmode == treePlotMode.CIRCULAR_CLADOGRAM || this.plotmode == treePlotMode.CIRCULAR_PHYLOGRAM)) {
          line.transform({rotation:180 - angle,cx:this.rootXY.x, cy: this.rootXY.y})
        }
      } // if align leaf labels

      if (this.bHTMLmode) {
        this.leafInternalID2LeafTextLabelOMNodeObj[leaf_internal_id] =  nodeHtmlLabel;
        nodeHtmlLabel.show()
        nodeLabel.hide()
        // layer_tree_leaf_labels.appendChild(fo);
      } else {
        this.leafInternalID2LeafTextLabelOMNodeObj[leaf_internal_id] =  nodeLabel;
        nodeHtmlLabel.hide()
        nodeLabel.show()
        // layer_tree_leaf_labels.appendChild(txtLeafLabel);
      }
    }

    //leaf annotation
    let layerleafAnnWidth = 0
    try {
      if(this.leafAnnEnabled && leafAnnObj != null){
        var mostrightForBranchAnn = this.getLeafMostRightPosition() + this.space_between_datasets  //this.currentMostRightPosition + this.space_between_datasets;
        var itemHeightPixel = (this.plotmode === (treePlotMode.CIRCULAR_CLADOGRAM)
        || this.plotmode === (treePlotMode.CIRCULAR_PHYLOGRAM))
        ? leafAnnDatset === null ? null : leafAnnDatset.getItemPixelHeight((mostrightForGenTranss * 2 * Math.PI / 360 * this.angle_span
            / this.pyTree.getMaxVerticalLevel()))
        : leafAnnDatset === null ? null : leafAnnDatset.getItemPixelHeight(this.pxPerHeight);
      
        // console.log(mostright,itemHeightPixel,leafAnnDatset === null)

        if (itemHeightPixel > 40) {
            itemHeightPixel = 40;
        }
      
        var fangle = (Math.atan((itemHeightPixel / 2) / (mostrightForBranchAnn - this.rootXY.x)) / Math.PI * 180); // if bars are plotted as fans in circular mode            
        var angleSpanPerHeight = this.getAngleSpan() / this.pyTree.getMaxVerticalLevel();

        if(this.leafAnnEnabled && !this.disable_all_dataset && leafAnnObj != null && leafAnnObjrow2data != null){      
          let leafAnnotateLyr = this.layer_leafAnnotate.group().id('plot_layer')
          for(let entry in leafAnnObjrow2data){
              const leaf_internal_id = entry
              const cdata = leafAnnObjrow2data[entry];
              let fontSize = cdata.getFontSize()
              var start_pos = mostrightForBranchAnn;
              const rowplot = leafAnnotateLyr.group().id('rowplot')
              // this.hmInternalID2RowPlot[leaf_internal_id] =  rowplot; 
    
              let topPts = null,botPts = null
              let topID = null,botID = null
              if(this.LeafInternalID2NodePosisionts.hasOwnProperty(leaf_internal_id)){
                  topPts = this.LeafInternalID2NodePosisionts[entry];
                  const externalID = (this.pyTree != null)? this.pyTree.getExternalIDbyInternalID(leaf_internal_id): "";
                  topID = externalID
              }else{
                  if(leafAnnObjId2originalID.hasOwnProperty(leaf_internal_id)){
                      let leaf_id_ar = leafAnnObjId2originalID[leaf_internal_id]
                      topPts = leaf_id_ar.length >=1 && this.hmLeafID2NodePositions.hasOwnProperty(leaf_id_ar[0]) ? this.hmLeafID2NodePositions[leaf_id_ar[0]] : null
                      botPts = leaf_id_ar.length >=2 &&this.hmLeafID2NodePositions.hasOwnProperty(leaf_id_ar[1]) ? this.hmLeafID2NodePositions[leaf_id_ar[1]] : null
                      topID = leaf_id_ar.length >=1 ? leaf_id_ar[0] : ''
                      botID = leaf_id_ar.length >=2 ? leaf_id_ar[1] : ''
    
                      if (topPts != null && botPts != null) {
                          if (topPts.verticalLevel > botPts.verticalLevel) {
                              var tmp = topPts;
                              topPts = botPts;
                              botPts = tmp;
                          }
                      }
                      const externalID = (this.pyTree != null)? this.pyTree.getExternalIDbyInternalID(leaf_internal_id): "";                        
                  }
              }          
              leafAnnObj.plotLeafAnnotate(topPts,botPts,topID,botID,cdata,rowplot,fontSize,angleSpanPerHeight,leaf_internal_id,mostrightForBranchAnn,this.pyTree,this.pxPerHeight,this.plotmode,this.rootXY,this.BranchLengthPositions)          
              if(layerleafAnnWidth < leafAnnObj.getMaxWidth()){
                layerleafAnnWidth = leafAnnObj.getMaxWidth()
              }
          }
    
          var mostRightLeafPos = mostrightForBranchAnn + layerleafAnnWidth
          if (mostRightLeafPos > this.getLeafMostRightPosition()) {
            this.setLeafMostRightPosition(mostRightLeafPos);
          }
        }
      }
      if(layerleafAnnWidth > 0){
        if(this.labelImagesEnabled){
          layerleafAnnWidth += this.space_between_datasets+this.space_between_datasets 
        }else{
          layerleafAnnWidth += this.space_between_datasets+this.space_between_datasets + 10
        }
      }
    } catch (error) { 
      console.log('error info at loading leaf annotation datasets ',error) 
    }
    
    //gene transfer
    var layerGenTransWidth = 0
    try {
      if(this.geneTransferEnabled && geneTransObj != null && this.plotmode != (treePlotMode.CIRCULAR_CLADOGRAM)
      && this.plotmode != (treePlotMode.CIRCULAR_PHYLOGRAM)){
        var mostrightForGenTranss = layerleafAnnWidth //this.getTreeMostRightPosition()
        var itemHeightPixel = (this.plotmode === (treePlotMode.CIRCULAR_CLADOGRAM)
        || this.plotmode === (treePlotMode.CIRCULAR_PHYLOGRAM))
        ? geneTransDatset === null ? null : geneTransDatset.getItemPixelHeight((mostrightForGenTranss * 2 * Math.PI / 360 * this.angle_span
            / this.pyTree.getMaxVerticalLevel())) : geneTransDatset === null ? null : geneTransDatset.getItemPixelHeight(this.pxPerHeight);
      
        // console.log(mostright,itemHeightPixel,leafAnnDatset === null)

        if (itemHeightPixel > 40) {
            itemHeightPixel = 40;
        }
      
        var fangle = (Math.atan((itemHeightPixel / 2) / (mostrightForGenTranss - this.rootXY.x)) / Math.PI * 180); // if bars are plotted as fans in circular mode            
        var angleSpanPerHeight = this.getAngleSpan() / this.pyTree.getMaxVerticalLevel();

        if(this.geneTransferEnabled && !this.disable_all_dataset && geneTransObj != null && geneTransDatset != null){      
          let geneTransferLyr = this.layer_geneTransfer.group().id('plot_layer')
          let layerWidth = 0
          let arrow_spacer = 10
          
          var bestStartXPos = this.getHighestLeafEndPt()
          for (var index in geneTransDatset.getGeneTrans()) {
            var item = geneTransDatset.getGeneTrans()[index]
            if(item.getStyle() === 1 && item.isInternalID()){
              geneTransObj.plotStyle1(item,arrow_spacer,geneTransferLyr,bestStartXPos+mostrightForGenTranss)
            }else if(item.getStyle() === 2){
              var temWid = geneTransObj.plotStyle2(item,arrow_spacer,geneTransferLyr,bestStartXPos+mostrightForGenTranss)
              if(temWid > layerGenTransWidth){
                layerGenTransWidth = temWid
              }
            }
          }

          // console.log(layerGenTransWidth)

          var mostRightLeafPos = mostrightForGenTranss + layerGenTransWidth + bestStartXPos
          if (mostRightLeafPos > this.getLeafMostRightPosition()) {
            this.setLeafMostRightPosition(mostRightLeafPos);
          }
        }
      }
    } catch (error) { 
      console.log('error info at loading gene transfer datasets ',error) 
    }

    if(this.showNodeLabel){
      this.layer_tree_leaf_labels.show()
    }else{
      this.layer_tree_leaf_labels.hide()
    }

    if (bAlignleaflable) {
      this.layer_tree_align_leaf_labels.show()
    } else {
      this.layer_tree_align_leaf_labels.hide()
    } // if alignLeafLabels is true or false
  }

  getPositionsBasedonLeafID(leaf_ext_id){
    if(this.leafIDtoInternalID.hasOwnProperty(leaf_ext_id)){
      var leaf_int_id = this.leafIDtoInternalID[leaf_ext_id]
      if(this.LeafInternalID2LabelEndPosisionts.hasOwnProperty(leaf_int_id)){                
        var posDat = this.LeafInternalID2LabelEndPosisionts[leaf_int_id]
        posDat.number_leaf_decendents = this.hmLeafID2NodePositions[leaf_ext_id].number_leaf_decendents
        posDat.verticalLevel = this.hmLeafID2NodePositions[leaf_ext_id].verticalLevel
        return posDat
      }
    }
    return null
  }

  getExternalToInternalId(leaf_ext_id){
    // console.log(this.leafIDtoInternalID.hasOwnProperty(leaf_ext_id),leaf_ext_id)
    if(this.leafIDtoInternalID.hasOwnProperty(leaf_ext_id)){
      return this.leafIDtoInternalID[leaf_ext_id]
    }
    return null
  }

  getPositionsBasedonInternalID(int_id){
    // console.log(int_id,int_id.startsWith('INT') ? this.BootstrapPositions[int_id] : 0,int_id.startsWith('LEF')? this.LeafInternalID2LabelEndPosisionts[int_id]:0)
    if(int_id.startsWith('INT')){
      if(this.BootstrapPositions.hasOwnProperty(int_id)){
        return clone(this.BootstrapPositions[int_id])
      }
    }else if(int_id.startsWith('LEF')){
      if(this.hmLeafID2NodePositions.hasOwnProperty(int_id)){                
        return clone(this.hmLeafID2NodePositions[int_id])
      }
    }
  }

  getHighestLeafEndPt(){
    var startX = 0
    for (var ind in this.LeafInternalID2LabelEndPosisionts) {
      var kv = this.LeafInternalID2LabelEndPosisionts[ind]
			var pos = kv;
			var angle = pos.angle;
      if(pos.pt.x > startX){
        startX = pos.pt.x
      }
    }
    return startX
  }

  getAlignLeafLabels() {
    return this.alignLeafLabels;
  } 

  setAlignLeafLabels( alignleaflabel) {
    if (this.alignLeafLabels != alignleaflabel) {
      this.alignLeafLabels = alignleaflabel;

      // if current mode is phylogram, replot
      if (this.plotmode === (treePlotMode.RECT_PHYLOGRAM) || this.plotmode === (treePlotMode.CIRCULAR_PHYLOGRAM)) {
        this.makeLeafLabelsAndBranchLengthAndBootstrapValues();
        this.makeLeafLabelBackground();

        // update all charts; Sep 22, 2014; this is the lazy way of doing so!!
        this.updateAllCharts();
      }
      this.updateTreeCanvasInfoOnServer("alignLeafLabel", this.alignLeafLabels ? 1 : 0); // May 14, 2011
    }
    return this.alignLeafLabels;
  } 

  setShowLine2Leaflabel(select) {

		if (this.showLineFromChart2Leaflabel != select) {
			this.showLineFromChart2Leaflabel = select;

			if (this.showLineFromChart2Leaflabel == false) {
				this.layer_tree_line2leaf_label.hide();
				// this.layer_tree_line2leaf_label.getStyle().setDisplay(Display.NONE);
			} else {
				this.layer_tree_line2leaf_label.show();
				// this.layer_tree_line2leaf_label.getStyle().setDisplay(Display.INLINE);
			}
			// only replot all charts if select is true ...
			if (select) {
				this.updateAllCharts();
			}
			this.updateTreeCanvasInfoOnServer("showLine2Leaflabel", this.showLineFromChart2Leaflabel ? 1 : 0); // sep 16, 2014
		}
	}

	// Sep 16, 2014 ...
	getShowLine2Leaflabel() {
		return this.showLineFromChart2Leaflabel;
  }
  
  isEmpty(str){
    if(str.length>=1) return false
    return true
  }

  makeBranchLengthLabels(){ //8287
    this.clearAllChildNodesFromSVGGElement(this.layer_tree_branchlength);
    this.branchID2OMNode = []
    if (this.pyTree.hasBranchlen()) {
      for (var entry in this.BranchLengthPositions) {
        var node_internal_id = entry
        var posDet = this.BranchLengthPositions[entry]
        var node = this.pyTree.getNodeByID(node_internal_id);
        // console.log(node_internal_id,posDet)
        if (!this.branchID2OMNode.hasOwnProperty(node_internal_id)) {
          var branchlength = node.getBranchLength()
          var branchLengthString = (branchlength > 0 && branchlength < 0.0001)
								? this.formatScientific(branchlength)
                : this.formatDec4(branchlength);
          var branchLenLbl = this.layer_tree_branchlength.text(branchLengthString)
                    .move(posDet.pt.x,posDet.pt.y-10)
          branchLenLbl.font({
            family:   this.default_font_family
          , size:     this.default_branchlength_font_size
          , anchor:   'middle'
          })
          this.branchID2OMNode[node_internal_id] =  branchLenLbl;
        }
        var branchLenLbl = this.branchID2OMNode[node_internal_id]
        if ((this.plotmode == treePlotMode.CIRCULAR_CLADOGRAM || this.plotmode == treePlotMode.CIRCULAR_PHYLOGRAM)) {
          branchLenLbl.transform({ rotation: 180-posDet.angle, cx: this.rootXY.x, cy:this.rootXY.y })
          this.betterReadabilityForSVGTextElem(branchLenLbl, posDet.angle, posDet.pt.x, posDet.pt.y + 3, "middle");
        }
      }

      if(this.showBrachLength){
        this.layer_tree_branchlength.show()
      }else{
        this.layer_tree_branchlength.hide()
      }
    }
  }

  betterReadabilityForSVGTextElem(txtLeafLabel, angle, x, y,
    textAnchor) {
    if ((angle >= 270 && angle <= 360) || (angle >= 0 && angle <= 90)) {
    //   // txtLeafLabel.font({
    //   //   anchor:   textAnchor
    //   // })
      // txtLeafLabel.rotate(angle)
      txtLeafLabel.transform({ rotation: 180,relative: true })
    //   // transform({ rotation: 90, x: x, y:y })
    }
  }

  formatScientific(num){
    return num.toExponential(4)
  }

  formatDec4(num){
    return num.toFixed(4)
  }

  makeBootstrapLabels(){
    if (this.pyTree.hasBootstrapScores()) {
      this.clearAllChildNodesFromSVGGElement(this.layer_tree_bootstrap);
      var bsvaCon = this.getActiveBootstrapStyleDataset(); //bootstrap style dataset 

      for (var  entry in this.BootstrapPositions) {
        var node_internal_id = entry
        var posDet = this.BranchLengthPositions[entry]
        var node = this.pyTree.getNodeByID(node_internal_id);
        var bootstrapvalue = node.getBootStrap();
					bootstrapvalue = (bootstrapvalue <= 1 && this.bMegaMode) ? Math.round(bootstrapvalue * 100)
							: bootstrapvalue; // Oct 27, 2017 ...
				var bootstrapstring = (bootstrapvalue > 0 && bootstrapvalue < 0.0001)
							? parseFloat(this.formatScientific(bootstrapvalue).toString()).toString()
              : parseFloat(this.formatDec4(bootstrapvalue).toString()).toString();
        var startx = posDet.pt.x, starty = posDet.pt.y;
				var text_color = "black";
        var text_size = this.default_bootstrap_font_size;
        if (this.bootstrapValueStyleEnabled && bsvaCon != null) {
          var flag_show = false;
          var atcon = bsvaCon.getBootstrapValueStyleAttributesByValue(bootstrapvalue);
          
          /**
						 * logic: if multiple bootstrap, will set flag_show to TRUE if any of the valid
						 * boostrap value can be shown -- Feb 2, 2018;
						 */
						if (StringOps.equalsIgnoreCase( atcon.getStyle(),("multiple"))
            || StringOps.equalsIgnoreCase( atcon.getStyle(),("multi"))) {
              for (var ind in node.getArBootstrapValues()) {
                var b = node.getArBootstrapValues()[ind]
                b = (b <= 1 && this.bMegaMode) ? Math.round(b * 100) : b; // Oct 27, 2017 ...
                flag_show = bsvaCon.getBootstrapValueStyleAttributesByValue(b).isShow();
                if (flag_show) {
                  break;
                }
              }
            } else {
              flag_show = atcon.isShow();
            }

            // console.log('style type ',atcon.getStyle(),flag_show,atcon.isShow(),this.pyTree.isHasMultipleBoostrap())
            if (flag_show) {
              if (StringOps.equalsIgnoreCase(atcon.getStyle(),("numeric")) ){
								// ----------- numeric style -------
                text_color = atcon.getColor();
                /**
								 * will plot as text
								 */
								text_size = atcon.getWidth();
                starty += text_size / 3;
                
                if (atcon.getPlace() == 1) {
                  startx += 2;
                } else {
                  // JsArrayInteger strPxWidthHeight =
                  // JSFuncs.stringPixelWidthHeight(bootstrapstring, "arial", text_size);
                  var w = bootstrapstring.length * this.font_width_height_ratio * text_size;
                  if (atcon.getPlace() == 2) {
                    startx -= w / 2;
                  } else if (atcon.getPlace() == 3) {
                    startx -= (w + 2);
                  } else if (atcon.getPlace() == 4) {
                    var branchlength = (this.plotmode === (treePlotMode.CIRCULAR_PHYLOGRAM)
                        || this.plotmode === (treePlotMode.RECT_PHYLOGRAM))
                            ? node.getBranchLength() * this.pxPerBranchLength
                            : Math.abs(node.getLevelHorizontal()
                                - node.getParent().getLevelHorizontal())
                                * this.pxPerWidthCurrentMode;
                    starty += (text_size * 2 / 3 + this.default_line_width / 2)-10;
                    startx -= (branchlength / 2 + w / 2)+5;
                  }
                }
                // --- plot ---
                var txtBootstrapScore = this.layer_tree_bootstrap.text(bootstrapstring)//doc.createSVGTextElement(0, 0,
                var angle = posDet.angle;

                txtBootstrapScore.move(startx+5,starty)
                txtBootstrapScore.attr("fill", text_color);
                txtBootstrapScore.font({size:text_size});

                if ((this.plotmode == treePlotMode.CIRCULAR_CLADOGRAM
                  || this.plotmode == treePlotMode.CIRCULAR_PHYLOGRAM)) {                  
                  txtBootstrapScore.transform({rotation:180 - angle, cx: this.rootXY.x, cy:this.rootXY.y})

                  // Apr 24, 2012
                  this.betterReadabilityForSVGTextElem(txtBootstrapScore, angle, startx,
                    atcon.getPlace() == 4
                      ? (starty - text_size * 0.95 - this.default_line_width / 2)
                      : starty - text_size * 0.33,
                    "end");
                }
              } else if (StringOps.equalsIgnoreCase( atcon.getStyle(),("multiple"))
              || StringOps.equalsIgnoreCase(atcon.getStyle(),("multi"))) {
                if (this.pyTree.isHasMultipleBoostrap()) {
                  var cols = StringOps.JsArrayStringToArrayList(
                    StringOps.splitStringBySeperator(atcon.getColor().trim(), "|"));

                  // 1. assemble the bootstrap string ...
                  var combinedString = "";
                  // console.log(node.getArBootstrapValues())
									for (var ind in  node.getArBootstrapValues()) {
                    var f = node.getArBootstrapValues()[ind]
										f = (f <= 1 && this.bMegaMode) ? Math.round(f * 100) : f; // Oct 27, 2017 ...
										var atcon3 = bsvaCon
												.getBootstrapValueStyleAttributesByValue(f);
										
										var textToShow = atcon3.isReplacedWithSet() ? atcon3.getReplaceWithString() : f + "";
										combinedString += textToShow + "/";
                  }
                  
                  // 2. get total size of the combined string
									text_size = atcon.getWidth();
									starty += text_size / 3;

									if (atcon.getPlace() == 1) {
										startx += 2;
									} else {
										var w = combinedString.length * this.font_width_height_ratio * text_size
												+ node.getArBootstrapValues().length * 2;
										if (atcon.getPlace() == 2) {
											startx -= w / 2;
										} else if (atcon.getPlace() == 3) {
											startx -= (w + 2);
										} else if (atcon.getPlace() == 4) {
											var branchlength = (this.plotmode === (treePlotMode.CIRCULAR_PHYLOGRAM)
													|| this.plotmode === (treePlotMode.RECT_PHYLOGRAM))
															? node.getBranchLength() * this.pxPerBranchLength
															: Math.abs(node.getLevelHorizontal()
																	- node.getParent().getLevelHorizontal())
																	* this.pxPerWidthCurrentMode;
											starty += text_size * 2 / 3 + this.default_line_width / 2;
											startx -= (branchlength / 2 + w / 2);
										}
                  }
                  
                  // 3. plot
									// 3.a : make a group svg, set font color and size, and append to mother element
									// ...
									var g = this.layer_tree_bootstrap.group().id('style_group')
                  g.font({size:text_size});
                  // if cicular mode ...
									var angle = posDet.angle;
									if ((this.plotmode == treePlotMode.CIRCULAR_CLADOGRAM
											|| this.plotmode == treePlotMode.CIRCULAR_PHYLOGRAM)) {
										g.transform({rotation:180-angle, cx:this.rootXY.x, cy:this.rootXY.y})
                  }
                  // 3.b : add text elements one by one --
                  var current_x = startx, current_y = starty;
                  if(this.plotmode == treePlotMode.RECT_CLADOGRAM){
                    current_x +=40
                    current_y -=8
                  }else if(this.plotmode == treePlotMode.RECT_PHYLOGRAM){
                    current_y -=8
                  }
                  var current_color_idx = 0, num_bootstrap_values = node.getArBootstrapValues().length;
                  for (var ind in node.getArBootstrapValues()) {
                    var f = node.getArBootstrapValues()[ind]
										f = (f <= 1 && this.bMegaMode) ? Math.round(f * 100) : f; // Oct 27, 2017 ...
										var atcon3 = bsvaCon
												.getBootstrapValueStyleAttributesByValue(f);

										// width and height of current bootstrap string
										// JsArrayInteger wh = JSFuncs.stringPixelWidthHeight(Float.toString(f) + "/",
										// "arial", text_size);
										/**
										 * >>>> Feb 5, 2018 --
										 *  add support for replaceWith=
										 */
										var textToShow = atcon3.isReplacedWithSet() ? atcon3.getReplaceWithString() : f + "";
                    var text = g.text(textToShow).move(current_x, current_y)
                    // console.log(atcon3.isReplacedWithSet(),atcon3.getReplaceWithString(),textToShow)
										text.attr("fill",
												cols[cols.length > current_color_idx ? current_color_idx
														: current_color_idx % cols.length]);
										if (!atcon3.isShow()) {
											text.attr("fill-opacity", "0"); // if isShow is FALSE, set text to
																					// invisiable ...
										}

										if ((this.plotmode == treePlotMode.CIRCULAR_CLADOGRAM
												|| this.plotmode == treePlotMode.CIRCULAR_PHYLOGRAM)) {
											this.betterReadabilityForSVGTextElem(text, angle, current_x,
													atcon.getPlace() == 4
															? (current_y - text_size * 0.95 - this.default_line_width / 2)
															: current_y - text_size * 0.33,
													"end");
										}

										current_x += textToShow.length * this.font_width_height_ratio * text_size; // increase
																														// width
																														// ...
										// if it is NOT the last value 
										if( ( current_color_idx + 1 ) < num_bootstrap_values ) {
                      var slash = g.text('/').move(current_x, current_y)                      
											slash.attr("fill",
													cols[cols.length > current_color_idx ? current_color_idx
															: current_color_idx % cols.length]);
										}
										
										// -- 
										current_x += this.font_width_height_ratio * text_size; // increase width ...
										current_color_idx++;
									}
                }else{
                  console.log('i kind of lost')
                }
              }else {

								/**
								 * will plot as shapes
								 */
                starty -= (atcon.getWidth() / 2)+2;
								if (atcon.getPlace() == 1) {
									startx += 2;
								} else if (atcon.getPlace() == 2) {
									startx -= (atcon.getWidth() / 2)-5;
								} else if (atcon.getPlace() == 3) {
									startx -= 2;
								} else if (atcon.getPlace() == 4) {
									var branchlength = (this.plotmode === (treePlotMode.CIRCULAR_PHYLOGRAM)
											|| this.plotmode === (treePlotMode.RECT_PHYLOGRAM))
													? node.getBranchLength() * this.pxPerBranchLength
													: (node.getLevelHorizontal()
															- node.getParent().getLevelHorizontal())
															* this.pxPerWidthCurrentMode;
									starty += atcon.getWidth() + 2;
									startx -= (branchlength / 2 + atcon.getWidth() / 2);
                }
                // console.log(atcon.getStyle(),atcon.getWidth(),atcon.getWidth())
								var ashape = this.makeAShape(atcon.getStyle(), startx, starty, atcon.getWidth(),
										atcon.getWidth(), atcon.getColor(), atcon.getStrokeColor(),
										atcon.getStrokeWidth(), posDet.angle,this.layer_tree_bootstrap);
								// layer_tree_bootstrap.appendChild(ashape);
							}
            }
        }else if (this.showBootStrap) {
          startx += 12;
          starty += (text_size / 3)-5;
          var angle = posDet.angle;
          
          var txtBootstrapScore = this.layer_tree_bootstrap.text(bootstrapstring)
          .move(startx,starty).stroke({color:  '#363332'}).font({size:text_size})

          if ((this.plotmode == treePlotMode.CIRCULAR_CLADOGRAM
            || this.plotmode == treePlotMode.CIRCULAR_PHYLOGRAM)) {
              txtBootstrapScore.transform({rotation:180 - angle, cx:this.rootXY.x, cy:this.rootXY.y})
          }
        }
      }
    }
    if(this.showBootStrap && this.hasBootStrap()){
      this.layer_tree_bootstrap.show()
    }else{
      this.layer_tree_bootstrap.hide()
    }
  }

  calcRightMostTreeAndLabelPosisions() {
    var posLeafLabels = 0, posTreeSkeleton = 0, maxLenLeafLabel = 0;

    for(let entry in this.LeafInternalID2NodePosisionts){
      var leafInfo = this.LeafInternalID2NodePosisionts[entry]
      var leaf_id = this.pyTree.getNodeByID(entry).getID()
      var x = leafInfo.pt.x

      if (x > posTreeSkeleton) {
        posTreeSkeleton = x;
      } // march 20, 2011

      if (this.showNodeLabel) {
        var pxWidth = this.default_font_size * leaf_id.length * this.font_width_height_ratio;

        if ((x + this.space_between_treeskeleton_and_leaflabels + pxWidth) > posLeafLabels) {
          posLeafLabels = x + this.space_between_treeskeleton_and_leaflabels + pxWidth;
        } // march 21, 2011

        // console.log(leaf_id,posLeafLabels,pxWidth)

        if (pxWidth > maxLenLeafLabel) {
          maxLenLeafLabel = pxWidth;
        }
      }
    }

    if (this.alignLeafLabels) {
      posLeafLabels = posTreeSkeleton + maxLenLeafLabel + this.space_between_treeskeleton_and_leaflabels;
    } // March 21, 2011;

    /*
     * set global values; March 24, 2014
     */
    this.setTreeMostRightPosition(posTreeSkeleton);
    this.setLeafMostRightPosition(this.showNodeLabel ? posLeafLabels + this.default_font_size : posLeafLabels);
  }

  setShowTreeBranchLengths(b) {
		// if (this.showBrachLength != b) {
		this.showBrachLength = b;
		if (this.showBrachLength == false) {
			this.layer_tree_branchlength.hide();
		} else {
			this.layer_tree_branchlength.show();
		}
    // console.log('cur b len ',this.showBrachLength)
		this.updateTreeCanvasInfoOnServer("showBranchlength", this.showBrachLength ? 1 : 0);
		return this.showBrachLength;
	}// April 7, 2011
  
  setShowTreeLeafLabels(b) {
		if (this.showNodeLabel != b) {
			this.showNodeLabel = b;
			if (this.showNodeLabel == false) {
				this.layer_tree_leaf_labels.hide()
			} else {
				this.layer_tree_leaf_labels.show()
			}

			this.makeLeafLabelsAndBranchLengthAndBootstrapValues();
			this.makeLeafLabelBackground(); //
			this.updateAllCharts();
		}

		this.updateTreeCanvasInfoOnServer("showNodeLabel", this.showNodeLabel ? 1 : 0);
		return this.showNodeLabel;
  }

  setShowBootstrapScores( b) {
		if (this.showBootStrap != b) {
			this.showBootStrap = b;

			// May 20, 2016;
			this.makeLeafLabelsAndBranchLengthAndBootstrapValues();
			this.updateAllLegends();

			// server
			this.updateTreeCanvasInfoOnServer("showBootstrap", this.showBootStrap ? 1 : 0); // May 14, 2011
		}
		return this.showBootStrap;
	} // April 7, 2011;
  
  getTreeMostRightPosition() {
		return this.treeMostRightPosition;
	}

	setTreeMostRightPosition(treeMostRightPosition) {
		this.treeMostRightPosition = treeMostRightPosition;
	}

	getLeafMostRightPosition() {
		return this.leafMostRightPosition;
	}

	setLeafMostRightPosition(leafMostRightPosition) {
		this.leafMostRightPosition = leafMostRightPosition;
	}

  hasLeafLabel() {
		return true;
  }
  
  getShowLeafLable() {
		return this.showNodeLabel && this.hasLeafLabel();
  }
  
  getShowBootStrapScores() {
		return this.showBootStrap && this.hasBootStrap(); // April 14, 2011
  }
  
  hasBootStrap() {
		return this.pyTree.hasBootstrapScores();
  }
  
  getShowBranchLength() {
		return this.showBrachLength && this.hasBranchLength();
  }
  
  getFontSize() {
		return this.default_font_size;
  }
  
  hasBranchLength() {
		return this.pyTree.hasBranchlen();
  }
  
  getCurrentFontSize4BootStrap() {
		return this.default_bootstrap_font_size;
  }
  
  getCurrentFontSize4BranchLength() {
		return this.default_branchlength_font_size;
  }
  
  getBootStrapFontItalic() {
		return this.bootStrapFontItalic;
  }
  
  getLeafInternalID2NodePosisionts(){
    return this.LeafInternalID2NodePosisionts
  }

  getBootstrapPositions(){
    return this.BootstrapPositions
  }

  getBranchLengthPositions(){
    return this.BranchLengthPositions
  }

  gethmLeafID2NodePositions(){
    return this.hmLeafID2NodePositions;
  }

  setBootStrapFontItalic(b) {
		if (b != this.bootStrapFontItalic) {
			this.bootStrapFontItalic = b;
			this.layer_tree_bootstrap.font({
        style:this.bootStrapFontItalic ? FontStyle.ITALIC : FontStyle.NORMAL
      })
			this.updateTreeCanvasInfoOnServer("bootStrapFontItalic", this.bootStrapFontItalic ? 1 : 0);
		}
  }

  setDisableAllBranchColorData(b) {
    this.branchColorEnabled = b;
    this.makeTreeSkeleton();
    this.drawTreeSkeleton(this.rootnode)
    this.makeLeafLabelsAndBranchLengthAndBootstrapValues();
    this.updateAllLegends();
    return this.branchColorEnabled;
  }
  
  getBranchLengthFontItalic() {
		return this.branchLengthFontItalic;
  }
  
  setBranchLengthFontItalic(b) {
		if (b != this.branchLengthFontItalic) {
			this.branchLengthFontItalic = b;
      this.layer_tree_branchlength.font({
        style:this.branchLengthFontItalic ? FontStyle.ITALIC : FontStyle.NORMAL
      })
			this.updateTreeCanvasInfoOnServer("branchLengthFontItalic", this.bootStrapFontItalic ? 1 : 0);
		}
  }
  
  setBootStrapFontSize(newFontSize) {
		if (newFontSize > 0 && newFontSize != this.default_bootstrap_font_size) {
			this.default_bootstrap_font_size = newFontSize;

			this.layer_tree_bootstrap.getStyle().setFontSize(this.default_bootstrap_font_size, Unit.PX);
			this.updateTreeCanvasInfoOnServer("bootStrapFontSize", this.default_bootstrap_font_size);
		}
  }

  getBootStrapFontSize() {
		return this.default_bootstrap_font_size;
	}

	setBranthLengthFontSize(newFontSize) {
		if (newFontSize > 0 && newFontSize != this.default_branchlength_font_size) {
			this.default_branchlength_font_size = newFontSize;

			this.layer_tree_branchlength.getStyle().setFontSize(this.default_branchlength_font_size, Unit.PX);
			this.updateTreeCanvasInfoOnServer("branchLengthFontSize", this.default_branchlength_font_size);
		}
	}

	getBranchLengthFontSize() {
		return this.default_branchlength_font_size;
  }
  
  setLeafFontItalic(italicState) {
    if(italicState){
      this.leaf_font_italic = FontStyle.ITALIC   
      this.layer_tree_leaf_labels.font({
        style:this.leaf_font_italic ? FontStyle.ITALIC : FontStyle.NORMAL
      })
      for(var cInd in this.layer_tree_leaf_labels.children()){
        var child = this.layer_tree_leaf_labels.children()[cInd]
        child.font({style: this.leaf_font_italic ? FontStyle.ITALIC : FontStyle.NORMAL})
      }
      this.updateTreeCanvasInfoOnServer("leafFontItalic", this.leaf_font_italic ? 1 : 0);    
    }else{
      this.leaf_font_italic = FontStyle.NORMAL  
      this.layer_tree_leaf_labels.font({
        style:this.leaf_font_italic ? FontStyle.ITALIC : FontStyle.NORMAL
      })
      for(var cInd in this.layer_tree_leaf_labels.children()){
        var child = this.layer_tree_leaf_labels.children()[cInd]
        child.font({style: this.leaf_font_italic ? FontStyle.ITALIC : FontStyle.NORMAL})
      }
      this.updateTreeCanvasInfoOnServer("leafFontItalic", this.leaf_font_italic ? 1 : 0);     
    }    
	}

	getLeafFontItalic() {
		return this.leaf_font_italic;
  }

  setLeafLabelFontSize(newleaflabelFontSize) {
    if (newleaflabelFontSize > 0 && newleaflabelFontSize != this.default_font_size) {
      this.default_font_size = newleaflabelFontSize;

      // remake everything
      this.makeLeafLabelsAndBranchLengthAndBootstrapValues()

      this.makeLeafLabelBackground(); // March 21, 2011;
      this.updateAllCharts(); // April 8, 2011;
      this.updateTreeCanvasInfoOnServer("fontSize", this.default_font_size); // May 14, 2011
    }
  }
  
  getAngleSpan(){
    return this.angle_span
  }

  getPxPerHeight() {
		return this.pxPerHeight;
	}

	getPxPerWidth() {
		return this.hmPlotMode2pxPerWidth.hasOwnProperty(this.plotmode) ? this.hmPlotMode2pxPerWidth[this.plotmode]
				: this.pxPerWidthDefault;
  }
  
  setPxPerWidth( px_per_width) {
		if (this.hmPlotMode2pxPerWidth.hasOwnProperty(this.plotmode)
				&& this.hmPlotMode2pxPerWidth[this.plotmode] == px_per_width) {
			// do nothing
		} else {
			this.hmPlotMode2pxPerWidth[this.plotmode] =  px_per_width;
      this.drawPhyloTreeUsingSVG()
      this.updateTreeCanvasInfoOnServer(this.sPxPerWidth + this.plotmode.toString(), px_per_width);
		}

		return this.hmPlotMode2pxPerWidth[this.plotmode];
	}// April 23, 2012

	setPxPerHeight( px_per_height) {
		if (this.pxPerHeight != px_per_height) {
			this.pxPerHeight = px_per_height;
      this.drawPhyloTreeUsingSVG()
      this.updateTreeCanvasInfoOnServer("verticalScale", this.pxPerHeight);

    }
  }
  
  getTreeCurveStyle() {
		return this.treeCurveStyle;
  }
  
  replotInHTMLmode(bShow) {
		if (this.bHTMLmode != bShow) {
			this.bHTMLmode = bShow;

			this.makeTreeSkeleton();
      this.drawTreeSkeleton(this.rootnode)
			this.makeLeafLabelsAndBranchLengthAndBootstrapValues();

			// update canvas information on the server --
			this.updateTreeCanvasInfoOnServer("TreeHTMLmode", bShow ? 1 : 0);
		}
	}

	getTreeHTMLmode() {
		return this.bHTMLmode;
	}

	isMegaMode() {
		return this.bMegaMode;
	}

  setBoostrapMegaMode(b){
    if (this.bMegaMode != b) {
			this.bMegaMode = b;

			this.makeLeafLabelsAndBranchLengthAndBootstrapValues();
			this.updateAllLegends();

			// server
			this.updateTreeCanvasInfoOnServer("megamode", this.bMegaMode ? 1 : 0);
		}
		return this.showBootStrap;
	}
  

  setTreeCurveStyle(newstyle) {
		if (this.treeCurveStyle != newstyle) {
      this.treeCurveStyle = newstyle;
      
			if (this.plotmode != (treePlotMode.SLANTED_CLADOGRAM_NORMAL)
					&& this.plotmode != (treePlotMode.SLANTED_CLADOGRAM_MIDDLE)
					&& this.plotmode != (treePlotMode.SLANTED_CLADOGRAM_RECT)) {
            this.drawPhyloTreeUsingSVG()
            // this.makeLeafLabelsAndBranchLengthAndBootstrapValues();
			}

			// update canvas information on the server --
			this.updateTreeCanvasInfoOnServer("TreeCurveStyle", newstyle);
		}
  }

  setTreeBranchLineWidth(newlinewidth) {
		if (newlinewidth > 0 && newlinewidth != this.default_line_width) {
			this.default_line_width = newlinewidth;
      for(var cInd in this.layer_tree_skeleton.children()){
        var child = this.layer_tree_skeleton.children()[cInd]
        child.stroke({width: this.default_line_width})
      }
      // may 24, 2016;
			this.makeLeafLabelsAndBranchLengthAndBootstrapValues();

			// update server information ...
			this.updateTreeCanvasInfoOnServer("treeBranchLineWidth", this.default_line_width);
		}
		return this.default_line_width;
	}

	increaseTreeBranchLineWidth() {
		return this.setTreeBranchLineWidth(this.default_line_width * this.scale_tree_branch_linewidth);
	}

	decreaseTreeBranchLineWidth() {
		return this.setTreeBranchLineWidth(this.default_line_width / this.scale_tree_branch_linewidth);
	}

  setCircularModeClockwise( clockwise) {
		if (this.circular_tree_plot_in_clockwise_mode != clockwise && (this.plotmode == treePlotMode.CIRCULAR_CLADOGRAM
				|| this.plotmode == treePlotMode.CIRCULAR_PHYLOGRAM)) {
			this.circular_tree_plot_in_clockwise_mode = clockwise;
      this.drawPhyloTreeUsingSVG()
      this.updateTreeCanvasInfoOnServer("circularClockWise", this.circular_tree_plot_in_clockwise_mode ? 1 : 0);

		}
		return this.circular_tree_plot_in_clockwise_mode;
	} // April 14, 2011;

	getCircularModeClockwise() {
		return this.circular_tree_plot_in_clockwise_mode;
	} // April 14, 2011;

	rotateCicularTree(angle) {
		this.angle_start += angle;
		if (this.angle_start > 360) {
			this.angle_start -= 360;
		} else if (this.angle_start < 0) {
			this.angle_start += 360;
		}

    this.drawPhyloTreeUsingSVG()
    this.updateTreeCanvasInfoOnServer("circularStartAngle", this.angle_start); 
	}

	setCicularAngleSpan(newspan) {
		if (newspan <= this.max_angle_span && newspan >= this.min_angle_span) {
			if (newspan != this.angle_span) {

				this.setCicularStartAngle(this.getCircularModeClockwise()
						? (this.getCicularStartAngle() - (this.angle_span - newspan) / 2)
						: (this.getCicularStartAngle() + (this.angle_span - newspan) / 2), false); // don't replot
																											// yet

				this.angle_span = newspan;
        this.drawPhyloTreeUsingSVG()
        this.updateTreeCanvasInfoOnServer("circularAngleSpan", this.angle_span); 

			}
		}
		return this.angle_span;
	}

  setCicularStartAngle(newStartAngle, bReplot) {
		newStartAngle %= 360; // mod
		if (newStartAngle < 0) {
			newStartAngle += 360;
		}
		if (newStartAngle != this.angle_start) {
			this.angle_start = newStartAngle;

			if (bReplot) {
        this.drawPhyloTreeUsingSVG()
        this.updateTreeCanvasInfoOnServer("circularStartAngle", this.angle_start);
			}
		}
  }
  
  getCicularStartAngle() {
		return this.angle_start;
	}

	getCicularSpan() {
		return this.angle_span;
	}

	getCicularAngleSpan() {
		return this.angle_span;
  }
  
  getPromode() {
		return this.promode;
	}

	setPromode(promode) {
		this.promode = promode;
	}
  
  clearAllChildNodesFromSVGGElement(svgg) {
    if(svgg != null && svgg != undefined){
      svgg.clear()
    }
	}

  svgString(){
    return this.svg.svg()
  }

  updateChartOrder(oldId,oldDborder,newId,newDbOrder){  
  
    if(this.activeCharts.length > 1){
      var tem = this.activeCharts[newOrder]
      var oldOrder = this.findChartIndex('Charts'+oldId,oldDborder)
      var newOrder = this.findChartIndex('Charts'+newId,newDbOrder)
      // console.log(oldOrder,newOrder,oldId,newId,oldDborder,newDbOrder)
      
      this.updateAllCharts()
    }
  }

  findChartIndex(chartId,newOrderId){
    if(this.activeCharts.length >= 1){
      for (var ind in this.activeCharts) {
        var pb = this.activeCharts[ind]
        // console.log(chartId,pb.datasetID,pb)
        if(chartId === pb.datasetID){
          this.activeCharts[ind].setDBOrder(newOrderId)
          // console.log(newOrderId,this.activeCharts[ind])
          return pb
        }
      }
    }
    return null
  }

  updateAllCharts(leftPos = 0){
    var allset = true;    
		for (var ind in this.activeCharts) {
      var pb = this.activeCharts[ind]
      pb.setChartsEnabled(this.chart_plots_enabled)
			if (!pb.isDBOrderSet()) {
				allset = false;
			}
    }
    if (!allset) {
      // console.log(this.activeTree)
      axios.post(this.server_add+'query/OrderOfActiveDatasets',
      {
        treeId:this.activeTree.dbserial,
        userId: this.activeTree.userId
      }).then(datOrder => {
        // console.log(datOrder)
        for (var ind in this.activeCharts) {
          var pb = this.activeCharts[ind]
          if (datOrder.data.hasOwnProperty(pb.getDataSetID())) {
            pb.dbOrder = datOrder.data[pb.getDataSetID()];
            pb.setDBOrder(datOrder.data[pb.getDataSetID()])
          }
        }
        // continue to plot
        this.checkActiveChartsOfTree(leftPos);
      }).catch(err => {
          console.log(err)
      })
    }else{
      this.checkActiveChartsOfTree(leftPos);
    }
  }

  checkActiveChartsOfTree(leftPos){
    //TODO check if the tree has active charts and size of active charts array
    this.loadChartsView(leftPos)
  }

loadChartsView(leftPos){
    this.clearAllChildNodesFromSVGGElement(this.layer_tree_charts);
		this.clearAllChildNodesFromSVGGElement(this.layer_tree_line2leaf_label); // clean
    
    this.activeCharts.sort(function (a, b) {
      return a.dbOrder - b.dbOrder;
    });

    // console.log(this.getLeafMostRightPosition())

    var currentMostRightPosition = this.getLeafMostRightPosition()    
    var chart_count = 1
    for (var ind in this.activeCharts) {      
      var pb = this.activeCharts[ind]
      this.activeCharts[ind].setLeafInternalID2NodePosisionts(this.LeafInternalID2NodePosisionts)
      this.activeCharts[ind].setRootXY(this.rootXY)
      this.activeCharts[ind].setPlotMode(this.plotmode)
      this.activeCharts[ind].setLeafMostRightPosition(currentMostRightPosition)            
      this.activeCharts[ind].chart_plots_enabled = this.chart_plots_enabled
      pb.makeOrUpdatePlot();
      currentMostRightPosition = pb.getLeafMostRightPosition()
      if (this.getShowLine2Leaflabel() && chart_count <= (this.activeCharts.length-1)) {
        this.drawLinesfromLeafLabel2charts(currentMostRightPosition);
      } 
      chart_count++;
      // this.currentMostRightPosition = currentMostRightPosition      
    }
    this.currentMostRightPosition = currentMostRightPosition
}

addHighlightToAllRowplotsByInternalLeafID(leafnode_internal_id){
  for (var ind in this.activeCharts) {
    var pb = this.activeCharts[ind]
    pb.addHighlightToRowPlotByLeafInternalID(leafnode_internal_id);
  }
  // change also leaf label color --
  if (this.leafInternalID2LeafTextLabelOMNodeObj.hasOwnProperty(leafnode_internal_id)) {
    var omnode = this.leafInternalID2LeafTextLabelOMNodeObj[leafnode_internal_id];

    // backup old styles & apply new styles --
    omnode.attr("stroke", "red");
  }

  /**
   * highlight leaf path
   */
  if (this.hmLeafInternalID2LeafPath.hasOwnProperty(leafnode_internal_id)) {
    var svgelement = this.hmLeafInternalID2LeafPath[leafnode_internal_id];
    // save existing styles --
    if (!this.hmLeafInternalID2LeafPathStyles.hasOwnProperty(leafnode_internal_id)) {
      this.hmLeafInternalID2LeafPathStyles[leafnode_internal_id] =  {};
    }

    var styles = this.hmLeafInternalID2LeafPathStyles[leafnode_internal_id];
    var svgelement_styles = svgelement.attr()
    for (var style in this.alPathStyles) {
      if (svgelement_styles.hasOwnProperty(style)) {        
        styles[style] =  svgelement_styles[style];
      }
    }
    // console.log(svgelement.attr(),svgelement.attr()['stroke'])

    // apply new style --
    svgelement.attr("stroke", "red");
    svgelement.attr("stroke-width", "2px");
  }
}

removeHighlightFromAllRowplotsByInternalLeafID(leafnode_internal_id) {
  for (var ind in this.activeCharts) {
    var pb = this.activeCharts[ind]
    pb.removeHighlightFromRowPlotByLeafInternalID(leafnode_internal_id);
  }

  // restore leaf label color --
  if (this.leafInternalID2LeafTextLabelOMNodeObj.hasOwnProperty(leafnode_internal_id)) {
    var omnode = this.leafInternalID2LeafTextLabelOMNodeObj[leafnode_internal_id];

    // restore old styles; if no old styles available, remove new style --
    omnode.attr("stroke", null);
  }
  if (this.hmLeafInternalID2LeafPath.hasOwnProperty(leafnode_internal_id)
				&& this.hmLeafInternalID2LeafPathStyles.hasOwnProperty(leafnode_internal_id)) {
			var svgelement = this.hmLeafInternalID2LeafPath[leafnode_internal_id];

			// remote highlight style
			svgelement.attr("stroke",this.default_branchcolor);
			svgelement.attr("stroke-width",this.default_line_width);
      
			// restore saved styles
			for (var key in this.hmLeafInternalID2LeafPathStyles[leafnode_internal_id]) {
        var kv = this.hmLeafInternalID2LeafPathStyles[leafnode_internal_id][key]
        svgelement.attr(key, kv);
			}
	}
}

getPies(){
    return this.pie
}

addBarplotData(datasetID, datCon, active) {
		/*
		 * NOTE: datasetID2plotbase and activePlotBase contain the same set of PlotBase
		 * classes;
		 */
		var replot = active;
		if (this.charts.hasOwnProperty(datasetID)) {
			var barplot = this.charts[datasetID];
			barplot.setDataSet(datasetID, datCon);
			barplot.setActive(true);
			replot = true;
		} else {
			// 1. add dataset
			var barplot = new Bars(this.layer_tree_charts,this.disable_all_dataset,this);
			barplot.setDataSet(datasetID, datCon);
      barplot.setActive(active);
			this.charts[datasetID] =  barplot;
			if (active) {
				this.activeCharts.push(barplot);
			}
		}
    
		if (replot) {
			this.updateAllCharts();
		}
		this.updateAllLegends();
}
  
addBootstrapValueStyleData( datasetID, dcon,
    type, active) {
  this.datasetID2dataContainerObj[datasetID] =  dcon;
  this.dataset2opacity[datasetID] =  dcon.getOpacity();
  if (active) {
    this.bootstrapValueStyleEnabled = true
    this.setActiveBootstrapStyleSetByID(datasetID);
  }
}

getRootXY(){
  return this.rootXY
}

getRootNode(){
  return this.rootnode
}

getActiveBootstrapStyleDataset() {
  return this.datasetID2dataContainerObj[this.activeBootstrapStyleSetID];
}

// Dec 4, 2015;
setActiveBootstrapStyleSetByID(activeBootstrapStyleSetID) {
  this.activeBootstrapStyleSetID = activeBootstrapStyleSetID;
  this.makeLeafLabelsAndBranchLengthAndBootstrapValues();
  this.updateAllLegends();
}

// set diable all bootstrap style ; dec 2, 2015;
setDisableAllBootstrapValueSTyleData( b) {
  this.bootstrapValueStyleEnabled = b;
  this.makeLeafLabelsAndBranchLengthAndBootstrapValues();
  this.updateAllLegends();

  return this.bootstrapValueStyleEnabled;
}

makeTimeLine() {
  this.clearAllChildNodesFromSVGGElement(this.layer_tree_timeline);

  var timeLineEndX = this.getTreeMostRightPosition(); //
  var timeLineStartX = this.rootXY.x;

  // get active dcon ...
  var dCon = this.getActiveTimeLineDataset();
  if (dCon != null && this.timeLineEnabled) {
    // get data / parameters ...
    var timeLineParams = dCon.getTimeLineParams(),
        axisParams = dCon.getAxisParams(), lineParams = dCon.getLineParams(),
        stripsParams = dCon.getStripsParams();
    /**
     * !TimeLine parameters will have to be there; otherwise the dataset will not be
     * valid ...
     */
    var totalTime = parseFloat(timeLineParams["TotalTime".toLowerCase()][0]);
    var pxPerTimeUnit = Math.abs(timeLineEndX - timeLineStartX) / totalTime;

    /**
     * get the first and last leaf positions ...
     */
    var firstleaf_id = this.pyTree.getFirstLeafNode().getInternalID();
    var lastleaf_id = this.pyTree.getLastLeafNode().getInternalID();

    var firstleafPos = this.LeafInternalID2NodePosisionts[firstleaf_id];
    var lastleafPos = this.LeafInternalID2NodePosisionts[lastleaf_id];

    /**
     * ================================================= stripsParams:
     * !TimeLineStrips op=0.2, Strips=0,3,5,6, StripColors=col1,col2,col3, // repeat
     * ... StripLabels=label 1,label 2,label3, ... StripLabelStyle=12,red,1,1,
     * StripLabelPos=Top|Bottom, StripMarginPX=2, ShowTimeUnit=1
     */
    if (stripsParams != null) {
      // the main layer of this section ...
      var strip_main_layer = this.layer_tree_timeline.group().id('strip_main_layer')

      // test if data is valid ...

      /**
       * get parameters ...
       */
      // get params ...
      var strips = this.getParamValues(stripsParams, ["strips", "strip", "times", "time"]),
          cols = this.getParamValues(stripsParams, ["stripcolors", "stripcolor", "color", "colors"]),
          labels = this.getParamValues(stripsParams, ["StripLabels", "striplabels", "striplabel", "label",
              "labels"]),
          labelstyles = this.getParamValues(stripsParams, ["StripLabelStyle", "StripLabelStyles"]),

          // strip margins
          stripmarginpx = this.getParamValues(stripsParams, ["stripmarginpx"])

      ;
      var striplabeltop = stripsParams.hasOwnProperty("StripLabelPos".toLowerCase())
          ? StringOps.equalsIgnoreCase(stripsParams["StripLabelPos".toLowerCase()][0].trim(),("top"))
          : true,
          showTimeUnit = stripsParams.hasOwnProperty("showtimeunit")
              ? StringOps.equalsIgnoreCase(stripsParams["showtimeunit"][0].trim(),("1"))
              : false;

      // strips should have at least TWO elements. .
      if (strips.length > 1) {

        // create a svg layer for the rects ...
        var layer_strips = strip_main_layer.group().id('timeline strips')
        // set opacity to strips, but not to labels ..
        if (stripsParams.hasOwnProperty("op") || stripsParams.hasOwnProperty("opacity")) {
          layer_strips.attr("opacity",
              stripsParams.hasOwnProperty("op") ? stripsParams["op"][0].trim()
                  : stripsParams["opacity"][0].trim());
        }

        // create a svg layer for labels ... -
        var fontsize = labelstyles != null && labelstyles.length<=0 ? 12
            : parseInt(labelstyles[0].trim());
        var layer_timeline_strip_label = this.makeTextLabelLayer("timeline strip label", fontsize,
            labelstyles, strip_main_layer);
        // layer_timeline_strip_label.getStyle().setTextAlign(TextAlign.CENTER); // text align to center
                                            // ....

        // rotate label if in circular mode ...
        if (this.plotmode === (treePlotMode.CIRCULAR_CLADOGRAM)
            || this.plotmode === (treePlotMode.CIRCULAR_PHYLOGRAM)) {
          layer_timeline_strip_label.transform({rotation:180 - (striplabeltop ? firstleafPos.angle + 4 : lastleafPos.angle - 4), cx: this.rootXY.x, cy: this.rootXY.y});
        }

        // get text label position --
        var text_label_y = !striplabeltop
            ? lastleafPos.pt.y + this.pxPerHeight / 2 + fontsize * 2 / 3 + 2
            : firstleafPos.pt.y - this.pxPerHeight / 2 - fontsize * 1 / 3;

        var timeUnit = timeLineParams["TimeUnit".toLowerCase()][0];
        if (timeUnit.length>=1 && showTimeUnit) {
          var text_y = striplabeltop ? text_label_y - fontsize * 2 / 3 - 5
              : text_label_y + 5 + fontsize * 2 / 3,
              text_x = (timeLineEndX + timeLineStartX) / 2;
          var text = layer_timeline_strip_label.text(timeUnit).move(text_x, text_y)
          text.attr("font-size", "12");
          text.attr("text-anchor", "middle");

          if ((this.plotmode == treePlotMode.CIRCULAR_CLADOGRAM
              || this.plotmode == treePlotMode.CIRCULAR_PHYLOGRAM)) {
            this.betterReadabilityForSVGTextElem(text,
                striplabeltop ? firstleafPos.getAngle() : lastleafPos.getAngle(), text_x,
                text_y - 12 / 4, "middle");
          }
        }

        /**
         * plot strips and labels ...
         */
        for (var tidx = 0; tidx < strips.length - 1; tidx++) {
          // get margin
          var margin = 0;
          if (stripmarginpx.length>=1) {
            margin = parseFloat(stripmarginpx[tidx % stripmarginpx.length]);
          }

          // get x1 (left) and x2 (right)
          var x1 = timeLineEndX - parseFloat(strips[tidx + 1]) * pxPerTimeUnit
              + margin / 2;
          var x2 = timeLineEndX - parseFloat(strips[tidx]) * pxPerTimeUnit - margin / 2;

          if ((this.plotmode === treePlotMode.CIRCULAR_CLADOGRAM
              || this.plotmode === treePlotMode.CIRCULAR_PHYLOGRAM)) {
            var fan = this.makeFanPlot(this.rootXY, x1 - this.rootXY.x, x2 - this.rootXY.x,
                lastleafPos.angle - 4, firstleafPos.angle + 4, this.angle_span + 8,layer_strips);
            fan.attr("fill",
                cols != null && cols.length<=0 ? "white" : cols[tidx % cols.length]);
          } else {
            // create a rect and add it to main layer ...
            var rect = layer_strips.rect(x2 - x1,
              lastleafPos.pt.y - firstleafPos.pt.y + this.pxPerHeight).move(x1,
                firstleafPos.pt.y - this.pxPerHeight / 2)
            // fill color, default is white ...
            rect.attr("fill",
                cols != null && cols.length<=0 ? "white" : cols[tidx % cols.length]);
          }

          /**
           * create labels and add to
           */
          if (labels.length > tidx) {
            var text = layer_timeline_strip_label.text(labels[tidx]).move((x1 + x2) / 2, text_label_y)
            // if in circular mode, check & change readability ...
            // better readability ...
            if ((this.plotmode == treePlotMode.CIRCULAR_CLADOGRAM
                || this.plotmode == treePlotMode.CIRCULAR_PHYLOGRAM)) {
              this.betterReadabilityForSVGTextElem(text,
                  striplabeltop ? firstleafPos.getAngle() : lastleafPos.getAngle(),
                  (x1 + x2) / 2, text_label_y - fontsize / 4, "middle");
            }
          }
        } // lines;
      }

    }

    /**
     * ==============================================================================================
     * !timeLineAxis Pos=Top|Bottom, Ticks=10[,5[,1]],
     * TickColors=darkblue,blue,lightblue TickStrokeWidth=2,1.5,1 TickLables=label
     * 1,label 2,label 3, TickLabelsStyle=12,grey,1,1, Grid=1, ## GridStyle=1,red,1
     * // not yet supported ...
     * 
     * + last modified : May 17, 2016; ++ added support for circular mode ...
     */
    if (axisParams != null) {

      // the main layer of this section ...
      var axis_layer = this.layer_tree_timeline.group().id('axis_layer')

      // get position of current layer
      var top = axisParams.hasOwnProperty("pos")
          ? StringOps.equalsIgnoreCase(axisParams["pos"][0].trim(),("top"))
          : true; // default is top

      // rotate if in circular mode ...
      if (this.plotmode === (treePlotMode.CIRCULAR_CLADOGRAM)
          || this.plotmode === (treePlotMode.CIRCULAR_PHYLOGRAM)) {
        axis_layer.transform({rotation:180 - (top ? firstleafPos.angle : lastleafPos.getAngle()), cx: this.rootXY.x, cy: this.rootXY.y})
      }

      // set opacity to current layer ..
      if (axisParams.hasOwnProperty("op") || axisParams.hasOwnProperty("opacity")) {
        axis_layer.attr("opacity",
            axisParams.hasOwnProperty("op") ? axisParams["op"][0].trim()
                : axisParams["opacity"][0].trim());
      }

      /**
       * ==============================================================================
       * plot ticks & tick labels & grid lines ... * major grid, if exists, will be
       * plotted as solid line, darkgrey minor grid, if exists, will be plotted as
       * dashed line
       */

      /**
       * timeline axis ticks ...
       */
      var bGrid = axisParams.hasOwnProperty("grid") && axisParams["grid"][0].trim() === ("1"); // if
                                                        // bgrid
                                                        // is
                                                        // on,
                                                        // default
                                                        // is
                                                        // not

      var majorTick = 0, minorTick = 0, otherTick = 0;
      if (axisParams.hasOwnProperty("Ticks".toLowerCase())) {
        var ticks = axisParams["Ticks".toLowerCase()];
        majorTick = ticks.length >= 1 ? parseFloat(ticks[0].trim()) : 0;
        minorTick = ticks.length >= 2 ? parseFloat(ticks[1].trim()) : 0;
        otherTick = ticks.length >= 3 ? parseFloat(ticks[2].trim()) : 0;
      }

      /**
       * tick colors and tick strokewidths --
       */
      var tickColors = axisParams.hasOwnProperty("tickColors".toLowerCase())
          ? axisParams["TickColors".toLowerCase()]
          : [];
      var tickWidths = axisParams.hasOwnProperty("tickStrokeWidths".toLowerCase())
          ? axisParams["tickStrokeWidths".toLowerCase()]
          : [];

      /**
       * default height of the tick labels; non-circular mode --
       */
      var majorTickHeight = 8;
      var tickY1 = 0, tickY2 = 0, incre = 0;
      if (top) {
        tickY1 = firstleafPos.pt.y - this.pxPerHeight / 2 - 2;
        tickY2 = tickY1 - majorTickHeight;
        incre = 1.5;
      } else {
        tickY1 = lastleafPos.pt.y + this.pxPerHeight / 2 + 2;
        tickY2 = tickY1 + majorTickHeight;
        incre = -1.5;
      }

      /**
       * grid ...
       */
      var layer_timeline_grid = axis_layer.group().id('layer_timeline_grid')      
      if (bGrid) {
        layer_timeline_grid.id("timeline grid");
        // styles of grid
      }

      var tickLabelFontSize = 10;

      var ticksPlotted = []; // to avoid repeatted plots at certain
                                  // positions

      // major tick
      if (majorTick > 0) {

        /**
         * create a tick label layer, apply tick label styles prepare ticklabel style,
         * tickLabelStyle has four elements, size, color, italic, bold
         */
        var tickLabelStyle = axisParams.hasOwnProperty("tickLabelStyle".toLowerCase())
            ? axisParams["tickLabelStyle".toLowerCase()]
            : [];
        tickLabelFontSize = (tickLabelStyle.length > 0) ? parseInt(tickLabelStyle[0]) : 10;

        var layer_timeline_ticklabel = this.makeTextLabelLayer("time line tick label",
            tickLabelFontSize, tickLabelStyle, axis_layer);

        /**
         * add tick lables to this newly created tick lable layer
         */
        var tickLabels = axisParams.hasOwnProperty("ticklabels")
            ? axisParams["ticklabels"]
            : [];
        var idx = 0;
        for (var time = 0; time <= totalTime; time += majorTick) {

          if (ticksPlotted.includes(time)) {
            continue;
          }
          ticksPlotted.push(time);

          // get x --
          var x = timeLineEndX - time * pxPerTimeUnit;
          var line = axis_layer.line(x, tickY1, x, tickY2)          

          // apply tick color (default is black) and stroke-width (default is 1.5)
          var strokecolor = "black";
          if (tickColors.length > 0 && tickColors[0].trim().length>=1) {
            strokecolor = tickColors[0].trim();
          }
          line.attr("stroke", strokecolor);

          var strokewidth = "1.5";
          if (tickWidths.length > 0 && tickWidths[0].trim().length>=1) {
            strokewidth = tickWidths[0].trim();
          }
          line.attr("stroke-width", strokewidth);

          /**
           * grid ... minor grid is dashed line ... May 17, 2016; added support for
           * circular mode ...
           */
          if (bGrid) {
            if ((this.plotmode == treePlotMode.CIRCULAR_CLADOGRAM
                || this.plotmode == treePlotMode.CIRCULAR_PHYLOGRAM)) {

              var current_radius = x - this.rootXY.x;

              var startpoint = Point.getCoordinatesOnCircle(this.rootXY, current_radius,
                  firstleafPos.angle);
              var endpoint = Point.getCoordinatesOnCircle(this.rootXY, current_radius,
                  lastleafPos.angle);

              var sweepFlag = this.circular_tree_plot_in_clockwise_mode === true ? 1 : 0;
              var large_arc = (this.angle_span >= 180) ? 1 : 0; // Feb 12, 2011; use global
                                          // angle_span?

              var segs = new Point();
              segs.appendSegItem(
                  Point.createPointStr(startpoint.x, startpoint.y));
              segs.appendSegItem(Point.createArcStr(endpoint.x, endpoint.y,
                  current_radius, current_radius, 0, large_arc, sweepFlag));
              var path = this.layer_tree_timeline.path(segs.getSegPathListStr());
              path.attr("stroke", "darkgrey");
              path.attr("stroke-width", "0.5");
              path.attr("fill", "none");
            } else {
              var line = layer_timeline_grid.line(x,
                firstleafPos.pt.y - this.pxPerHeight / 2, x,
                lastleafPos.pt.y + this.pxPerHeight / 2)
              gridline.attr("stroke", "darkgrey");
              gridline.attr("stroke-width", "0.5");
            }
          }

          /**
           * tick label for major ticks ...
           */
          // get text to plot --
          var tickLabel = "";
          if (axisParams.hasOwnProperty("ticklabels")) {
            if (tickLabels.length > idx) {
              tickLabel = tickLabels[idx];
            }
          } else {
            tickLabel = majorTick * idx + "";
          }

          // plot text; added support for circular mode ...
          if (tickLabel.length>=1) {
            var text_y = top ? tickY2 - 2 : tickY2 + tickLabelFontSize * 2 / 3 + 2;
            // position text --
            var text = layer_timeline_ticklabel.text(tickLabel).move(x, text_y-10)            

            // better readability ...
            if ((this.plotmode === treePlotMode.CIRCULAR_CLADOGRAM
                || this.plotmode === treePlotMode.CIRCULAR_PHYLOGRAM)) {
              this.betterReadabilityForSVGTextElem(text,
                  top ? firstleafPos.getAngle() : lastleafPos.getAngle(), x,
                  text_y - tickLabelFontSize / 4, "middle");
            }
          }

          //
          idx++;
        }
      } // if major tick exists ...

      /**
       * plot Time Unit --
       */
      var timeUnit = timeLineParams["TimeUnit".toLowerCase()][0];
      if (timeUnit.length>=1) {
        var text_y = top ? tickY2 - 15 : tickY2 + 15 + tickLabelFontSize * 2 / 3,
            text_x = (timeLineEndX + timeLineStartX) / 2;
        var text = axis_layer.text(timeUnit).move(text_x, text_y-10)        
        text.attr("font-size", "12");
        text.attr("text-anchor", "middle");

        if ((this.plotmode === treePlotMode.CIRCULAR_CLADOGRAM
            || this.plotmode === treePlotMode.CIRCULAR_PHYLOGRAM)) {
          this.betterReadabilityForSVGTextElem(text,
              top ? firstleafPos.angle : lastleafPos.angle, text_x, text_y - 12 / 4,
              "middle");
        }
      }

      // minor tick & grid
      if (minorTick > 0) {

        for (var time = 0; time <= totalTime; time += minorTick) {

          if (ticksPlotted.includes(time)) {
            continue;
          }
          ticksPlotted.push(time);

          // get x --
          var x = timeLineEndX - time * pxPerTimeUnit;

          /**
           * minor tick
           */
          var line = axis_layer.line(x, tickY1, x, tickY2 + incre)
        
          // apply tick color (default is darkgrey) and stroke-width (default is 1.5)
          var strokecolor = "darkgrey";
          if (tickColors.length > 1 && tickColors[1].trim().length>=1) {
            strokecolor = tickColors[1].trim();
          }
          line.attr("stroke", strokecolor);

          var strokewidth = "1.5";
          if (tickWidths.length > 1 && tickWidths[1].trim().length>=1) {
            strokewidth = tickWidths[1].trim();
          }
          line.attr("stroke-width", strokewidth);

          /**
           * grid ... minor grid is dashed line ... + added support for circular mode
           */
          if (bGrid) {
            if ((this.plotmode == treePlotMode.CIRCULAR_CLADOGRAM
                || this.plotmode == treePlotMode.CIRCULAR_PHYLOGRAM)) {
              // --------- circular mode -----------
              var current_radius = x - this.rootXY.x;

              var startpoint = Point.getCoordinatesOnCircle(this.rootXY, current_radius,
                  firstleafPos.angle);
              var endpoint = Point.getCoordinatesOnCircle(this.rootXY, current_radius,
                  lastleafPos.angle);

              var sweepFlag = this.circular_tree_plot_in_clockwise_mode === true ? 1 : 0;
              var large_arc = (this.angle_span >= 180) ? 1 : 0; // Feb 12, 2011; use global
                                          // angle_span?

              var segs = new Point();
              segs.appendSegItem(
                  Point.createPointStr(startpoint.x, startpoint.y));
              segs.appendSegItem(Point.createArcStr(endpoint.x, endpoint.y,
                  current_radius, current_radius, 0, large_arc, sweepFlag));
              
              var path = this.layer_tree_timeline.path(segs.getSegPathListStr())
              path.attr("stroke", "grey");
              path.attr("stroke-width", "0.5");
              path.attr("stroke-dasharray", "5,5");
              path.attr("fill", "none");
            } else {
              // --------- rect mode -----------
              var gridline = layer_timeline_grid.line(x,
                firstleafPos.pt.y - this.pxPerHeight / 2, x,
                lastleafPos.pt.y + this.pxPerHeight / 2)
              gridline.attr("stroke", "grey");
              gridline.attr("stroke-width", "0.5");
              gridline.attr("stroke-dasharray", "5,5");
            }
          }

        }
      }

      // other ticks;
      if (otherTick > 0) {
        for (var time = 0; time <= totalTime; time += otherTick) {

          if (ticksPlotted.includes(time)) {
            continue;
          }
          ticksPlotted.push(time);

          // get x --
          var x = timeLineEndX - time * pxPerTimeUnit;
          var line = axis_layer.line(x, tickY1, x, tickY2 + incre * 2)

          // apply tick color (default is grey) and stroke-width
          if (tickColors.length > 2 && tickColors[2].trim().length>=1) {
            line.attr("stroke", tickColors[2].trim());
          } else {
            line.attr("stroke", "grey");
          }

          if (tickWidths.length > 2 && tickWidths[2].trim().length>=1) {
            line.attr("stroke-width", tickWidths[2].trim());
          }
        }
      }
    } // if( axisParams != null ) {

    /**
     * ================================================= lineParams: !TimeLineLines
     * Lines|linePos|times|time=0,2,4,5,8, LineStyles=solid|dashed[,solid|dashed] //
     * will be cycled ... lineWidths=1[,1] // will be cycled ...
     * lineColors=black[,white] // will be cycled as well ... LineLabels=label
     * 1,label 2, label 3, LineLabelStyle=12,black,1,1, LineLabelPos=Top|Bottom
     */

    if (lineParams != null) {
      var line_layer = this.layer_tree_timeline.group().id('line_layer')      

      // get params ...
      var times = this.getParamValues(lineParams, ["lines", "linePos", "times", "time"]),
          styles = this.getParamValues(lineParams, ["linestyles", "linestyle"]),
          width = this.getParamValues(lineParams, ["lineWidths", "linewidth"]),
          labels = this.getParamValues(lineParams, ["linelabels", "linelabel"]),
          labelstyles = this.getParamValues(lineParams, ["linelabelstyles", "linelabelstyle"]),
          cols = this.getParamValues(lineParams, ["lineColors"]);

      if (times != null && times.length>=1) {
        // create a svg layer for the lines ...
        var layer_lines = line_layer.group().id('timeline lines')        

        var top = lineParams.hasOwnProperty("linelabelpos")
            && StringOps.equalsIgnoreCase(lineParams["linelabelpos"][0].trim(),("top"));

        // set opacity to lines; but not to labels ..
        if (lineParams.hasOwnProperty("op") || lineParams.hasOwnProperty("opacity")) {
          layer_lines.attr("opacity",
              lineParams.hasOwnProperty("op") ? lineParams["op"][0].trim()
                  : lineParams["opacity"][0].trim());
        }

        // create a svg layer for lables -
        /**
         * create a tick label layer, apply tick label styles prepare ticklabel style,
         * tickLabelStyle has four elements, size, color, italic, bold
         */
        var fontsize = labelstyles != null && labelstyles.length<=0 ? 12
            : parseInt(labelstyles[0].trim());
        var layer_timeline_line_label = this.makeTextLabelLayer("timeline line label", fontsize,
            labelstyles, line_layer);
        // rotate labels
        // rotate if in circular mode ...
        if (this.plotmode === (treePlotMode.CIRCULAR_CLADOGRAM)
            || this.plotmode === (treePlotMode.CIRCULAR_PHYLOGRAM)) {          
          layer_timeline_line_label.transform({rotation:180 - (top ? firstleafPos.angle + 4 : lastleafPos.angle - 4), cx: this.rootXY.x, cy: this.rootXY.y});
        }

        /**
         * plot lines and labels ...
         */
        for (var tidx = 0; tidx < times.length; tidx++) {
          var time = parseFloat(times[tidx]);
          var x = timeLineEndX - time * pxPerTimeUnit;

          if ((this.plotmode == treePlotMode.CIRCULAR_CLADOGRAM
              || this.plotmode == treePlotMode.CIRCULAR_PHYLOGRAM)) {
            // ----------- circular mode ---------
            var current_radius = x - this.rootXY.x;

            var startpoint = Point.getCoordinatesOnCircle(this.rootXY, current_radius,
                firstleafPos.angle);
            var endpoint = Point.getCoordinatesOnCircle(this.rootXY, current_radius,
                lastleafPos.angle);

            var sweepFlag = this.circular_tree_plot_in_clockwise_mode === true ? 1 : 0;
            var large_arc = (this.angle_span >= 180) ? 1 : 0; // Feb 12, 2011; use global
                                        // angle_span?

            var segs = new Point();
            segs.appendSegItem(Point.createPointStr(startpoint.x, startpoint.y));
            segs.appendSegItem(Point.createArcStr(endpoint.x, endpoint.y,
                current_radius, current_radius, 0, large_arc, sweepFlag));
            
            var path = this.layer_tree_timeline.path(segs.getSegPathListStr())
            path.attr("fill", "none");
            path.attr("stroke",
                cols != null && cols.length<=0 ? "grey" : cols[tidx % cols.length]);
            if (width != null && width.length>=1) {
              path.attr("stroke-width", width[tidx % width.length]);
            }
            if (styles != null && styles.length>=1
                && StringOps.equalsIgnoreCase(styles[tidx % styles.length],("dashed"))) {
              path.attr("stroke-dasharray", "5,5");
            }
          } else {
            // ----------- rect mode --------------
            // create a line and add it to may layer ...
            var line = layer_lines.line(x,
              firstleafPos.pt.y - this.pxPerHeight / 2, x, lastleafPos.pt.y + this.pxPerHeight / 2)

            // line color (default is grey) and stroke-width (default is 1) and style
            // (default is solid) ...
            line.attr("stroke",
                cols != null && cols.length<=0 ? "grey" : cols[tidx % cols.length]);
            if (width != null && width.length>=1) {
              line.attr("stroke-width", width[tidx % width.length]);
            }

            if (styles != null && styles.length>=1
                && StringOps.equalsIgnoreCase(styles[tidx % styles.length],("dashed"))) {
              line.attr("stroke-dasharray", "5,5");
            }
          }

          /**
           * create labels and add to
           */
          if (labels != null && labels.length > tidx) {
            // get position --
            var y = 0;
            if (!top) {
              y = lastleafPos.pt.y + this.pxPerHeight / 2 + fontsize * 2 / 3 + 2;
            } else {
              y = firstleafPos.pt.y - this.pxPerHeight / 2 - 2;
            }
            var text = layer_timeline_line_label.text(labels[tidx]).move(x,y)          

            if ((this.plotmode === treePlotMode.CIRCULAR_CLADOGRAM
                || this.plotmode === treePlotMode.CIRCULAR_PHYLOGRAM)) {
              this.betterReadabilityForSVGTextElem(text,
                  top ? firstleafPos.angle : lastleafPos.angle, x, y - fontsize / 4,
                  "middle");
            }
          }
        } // lines;
      }
    }
  } // if dCon is valid and timeLineEnabled is true ...

  this.layer_tree_timeline.back()
}

/**
* make it's own legend ...
* 
* @param current_y
* @return
*/
makeLegendForHeatMap(ndCon,current_y) {
  // 1. plot title; this part will not change ...; April 4, 2015 ...
  var title = ndCon.getTitle();
  var title_font_size = this.default_legend_font_size + 2;
  // JsArrayInteger title_height_width = JSFuncs.stringPixelWidthHeight(title,
  // default_font_family, title_font_size);

  var title_width = (title.length * this.font_width_height_ratio * title_font_size);
  var title_height = title_font_size;

  current_y += title_height;
  var text = this.layer_legend.text(title).move(this.legend_start_pos_x, current_y);
  text.font({size:title_font_size})

  current_y += this.space_between_legend_items * 1 / 2;
  var line = this.layer_legend.line(this.legend_start_pos_x, current_y+12,
    this.legend_start_pos_x + title_width, current_y+12);
  line.attr("stroke", "darkgrey");
  line.attr("stroke-linecap", "round");

  current_y += this.space_between_legend_items / 2 + this.default_legend_font_size / 2 + 10;

  /**
   * 2. plot legends; April 4, 2015; here a color gradient will be plotted in a 15
   * x 100 box
   */

  /**
   * 2.1 April 7, 2015; a linear gradient ...
   */
  // defs.removeChild(linearGradient);
  var linearGradient = this.defs.gradient('linear', function(stop) {
  })
  linearGradient.from(0, 0).to(0, 1)
  // a vertical linear gradient ...

  var offset = 0, colorsize = ndCon.getArHeatmapColorGradientColors().length;
  var incremental = 100 / (colorsize - 1);
  linearGradient.update(function(stop) {
    for (var ind in ndCon.getArHeatmapColorGradientColors()) {
      var color = ndCon.getArHeatmapColorGradientColors()[ind]
      var hex = RgbaColor.getHexMap()[color]              
      // console.log(color,offset,offset*0.01,hex)
      stop.at(offset*0.01, hex)
      offset += incremental;
    } // end of linear gradient
  })
  // 2.2, generate a number series
  var legendPixelHeight = ndCon.getColorGradientPixelHeight(),
    legendPixelWidth = ndCon.getColorGradientPixelWidth(); // the box width and height

  // 2.2. make a rect
  var rect = this.layer_legend.rect(legendPixelWidth,legendPixelHeight).move(this.legend_start_pos_x, current_y, )
  rect.fill(linearGradient)

  // 2.3 get mark label ready
  var markLabels = [];
  var steps = 5; // it means how many marks and labels to plot
  var markWidth = 4; // 2px
  if (!ndCon.getArColorGradientMarkLabels().length>=1) {
   for (var ind in ndCon.getArColorGradientMarkLabels()) {
     var f = ndCon.getArColorGradientMarkLabels()[ind]
    if (f >= ndCon.getMinValue() && f <= ndCon.getMaxValue()) {
     markLabels.push(f);
    }
   }
  } else {
   var startendstep = StringOps.AxisStartEndStep(ndCon.getMinValue(), ndCon.getMaxValue(),
     steps);
   for (var i = 0; i < steps; i++) {
    var marklabel = (startendstep[0] + i * startendstep[2]);
    if (marklabel >= ndCon.getMinValue() && marklabel <= ndCon.getMaxValue()) {
      markLabels.push(marklabel);
    }
   }
  }

  // 2.4 plot numbers / texts next to the color gridient
  var maxDif = (ndCon.getMaxValue() - ndCon.getMinValue()) / 0.99;
  for (var i = 0; i < markLabels.length; i++) {
   var marklabel = markLabels[i];
   var y = (current_y + (marklabel - ndCon.getMinValue()) / maxDif * legendPixelHeight);

   // plot a mark
   var markline = this.layer_legend.line(this.legend_start_pos_x + legendPixelWidth, y,
     this.legend_start_pos_x + legendPixelWidth + markWidth, y);
   markline.attr("stroke", "black");

   // plot the text
   var item_text = this.layer_legend.text(marklabel+"").move(
     this.legend_start_pos_x + legendPixelWidth + markWidth + this.space_between_legend_and_text,
     y - this.default_legend_font_size * 1 / 3);

   item_text.font({size:this.default_legend_font_size})
  }
  /**
   * 3. change current Y again
   */
  current_y += (legendPixelHeight + this.space_between_legend_items);

  return current_y;
}

makeLegendForDotPlots(ndCon,current_y,pb) {

  var max = ndCon.getMaxValue();
  if (!ndCon.isDotPlotsScaleByCol() && max > 0) {
    /**
     * 1. plot title; this part will be omitted
     */

    /**
     * 2. plot legends; April 4, 2015, which consists of a series circles /
     * rectangular with numbers next to them
     */
    var steps = 5; // it means how many marks and labels to plot
    var startendstep = StringOps.AxisStartEndStep(0, max, steps);
    for (var i = 0; i < steps; i++) {
      var value = (startendstep[0] + i * startendstep[2]);
      if (value > 0) {
        // calculate radius; by area or not ...
        var r = 0;
        if (value > 0 && ndCon.getMaxValue() > 0) {
          if (ndCon.isByarea()) {
            r = (Math.sqrt(value) / Math.sqrt(max) / 2
                * (pb.getPlot_width_per_col() - ndCon.getPlotColMargin())); 
          } else {
            r = value / max / 2 * (pb.getPlot_width_per_col()  - ndCon.getPlotColMargin());
          }
        }

        /**
         * plot rect or circular
         */
        var c = null;
        if (StringOps.equalsIgnoreCase( ndCon.getDotPlotsShape(),"rect")) {
          c = this.layer_legend.rect(r * 2, r * 2).move(this.legend_start_pos_x, current_y+r);
          c.radius(ndCon.getRectRoundedCorner(), ndCon.getRectRoundedCorner())
        } else {
          c = this.layer_legend.circle(r*2).move(this.legend_start_pos_x, current_y + r);
        }
        c.attr("fill", "darkgrey");
        /**
         * text next to the circle or rectangular
         */
        var text = this.layer_legend.text(value+"").move(
          this.legend_start_pos_x + r * 2 + this.space_between_legend_and_text,
            current_y + r + this.default_legend_font_size * 1 / 3);
        text.font({size:this.default_legend_font_size}) 

        // change current_y
        current_y += r * 2 + this.space_between_legend_items;
      }
    }

    /**
     * 3. change current Y again
     */
    current_y += this.space_between_legend_items;
  }
  return current_y;
}

/*
* make legend plots for the following plot types in the following
* order -- 1. piechart -- 2. branch color -- 3. leaf color -- 4. leaf
* background -- 5. charts
* 
* May 18, 2013; made public March 19, 2014; each legend item has its own shape
* now March 20, 2014; fix a bug here march 23, 2014; add support for triangle,
* check, star
*/
updateAllLegends() {
  // clear tree legend
  this.clearAllChildNodesFromSVGGElement(this.layer_legend); // this is important
  
  // prepare current y-axis --
  var current_y = this.legend_start_pos_y;

  // 1. pie charts
  if (  this.pie.getActivePie() != null) {
   var lepie = this.pie.getActivePie().getLegend();
   if (lepie != null && lepie.getShowLegends() && !this.pie.isAllPiechartsDisabled) {
    current_y = this.plotLegendEntry(current_y, lepie);
   }
  }
  
  // 2. branch color
  if (this.getActiveBranchColorDataset() != null) {
   var leEntry = this.getActiveBranchColorDataset().getLegend();
  // console.log(leEntry)
   if (leEntry != null && leEntry.getShowLegends() && this.branchColorEnabled) {
    current_y = this.plotLegendEntry(current_y, leEntry);
   }
  }

  //2.1 branch label
  if(this.getActiveBranchLabelDataset() != null){
    var leEntry = this.getActiveBranchLabelDataset().getLegend()
    if (leEntry != null && leEntry.getShowLegends() && this.branchLabelEnabled) {
      current_y = this.plotLegendEntry(current_y, leEntry);
     }
  }

  //branch marker
  if(this.getActiveBranchMarkIDDataset() != null){
    var leEntry = this.getActiveBranchMarkIDDataset().getLegend()
    if (leEntry != null && leEntry.getShowLegends() && this.branchMarkEnabled) {
      current_y = this.plotLegendEntry(current_y, leEntry);
     }
  }

  //2.2 branch annotation
  if(this.getActiveBranchAnnotationDataset() != null){
    var leEntry = this.getActiveBranchAnnotationDataset().getLegend()
    if (leEntry != null && leEntry.getShowLegends() && this.leafAnnEnabled) {
      current_y = this.plotLegendEntry(current_y, leEntry);
     }
  }

  // 3. leaf color
  if (this.getActiveLeafColorDataset() != null) {
   var lepie = this.getActiveLeafColorDataset().getLegend();
  
   if (lepie != null && lepie.getShowLegends() && this.leafColorEnabled) {
    current_y = this.plotLegendEntry(current_y, lepie);
   }
  }
  
  // 4. leaf background
  if (this.getActiveLeafBKColorDataset() != null) {
   var lepie = this.getActiveLeafBKColorDataset().getLegend();
   if (lepie != null && lepie.getShowLegends() && this.leafBKColorEnabled) {
    current_y = this.plotLegendEntry(current_y, lepie);
   }
  }
  
  // 5. leaf decoration
  if (this.getActiveLeafDecorationDataset() != null) {
   var lepie = this.getActiveLeafDecorationDataset().getLegend();
   if (lepie != null && lepie.getShowLegends() && this.leafDecoEnabled) {
    current_y = this.plotLegendEntry(current_y, lepie);
   }
  }
  
  // 6. bootstrap styles ; Dec 4, 2015;
  if (this.getActiveBootstrapStyleDataset() != null) {
   var lepie = this.getActiveBootstrapStyleDataset().getLegend();
   if (lepie != null && lepie.getShowLegends() && this.bootstrapValueStyleEnabled) {
    current_y = this.plotLegendEntry(current_y, lepie);
   }
  }
  
  /*
   * lastly, charts May 14, 2012; add protein domain March 20, 2014; make it very
   * simple ... >>>> April 4, 2015 ... + for dotplot ...
   */
  for (var ind in this.activeCharts) {
    var pb = this.activeCharts[ind]
   // runtime instances check
   var lecharts = pb.getLegendEntry();
   if (pb.getActive() && lecharts != null && lecharts.getShowLegends() && this.chart_plots_enabled) {
    if (pb instanceof Heatmap) {
     current_y = this.makeLegendForHeatMap(pb.getNdCon(),current_y);
    } else if (pb instanceof DotPlots) {
     // regular legend ...
     current_y = this.plotLegendEntry(current_y, lecharts);
    //  // additional legend ...
     current_y = this.makeLegendForDotPlots(pb.getNdCon(),current_y,pb);
    } else {
     current_y = this.plotLegendEntry(current_y, lecharts);
    }
   }
  }
} // updateAllLegends

getActiveBranchAnnotationDataset(){
  return this.leafAnnMap[this.activeBranchAnnotateID]
}

getActiveBranchLabelDataset(){
  return this.datasetID2dataContainerObj[this.activeBranchLabelID]
}

getActiveBranchColorDataset() {
  return this.datasetID2dataContainerObj[this.activeBranchColorSetID];
}

getActiveLeafColorDataset() {
  return this.datasetID2dataContainerObj[this.activeLeafColorSetID];
}

getActiveLeafBKColorDataset() {
  return this.datasetID2dataContainerObj[this.activeLeafBKColorSetID];
}

getActiveBranchMarkIDDataset(){
  return this.branchMarkMap[this.activeBranchMarkID]
}

/**
 * last modified : March 19~23, 2014 Bern <+> Geneva each legend item has its
 * own shape add new shapes: triangle and check
 * 
 * @param current_y
 * @param legend
 * @return
 */
plotLegendEntry(current_y, legend) {
  // 1. plot title
  var title = legend.getTitle();
  var title_font_size = this.default_legend_font_size + 2;
  // JsArrayInteger title_height_width = JSFuncs.stringPixelWidthHeight(title,
  // this.default_font_family, title_font_size);

  var title_width =  (title.length * this.font_width_height_ratio * title_font_size);
  var title_height = title_font_size;

  current_y += title_height;
  var text = this.layer_legend.text(title).move(this.legend_start_pos_x, current_y);
  text.font({ size:title_font_size})

  current_y += this.space_between_legend_items * 1 / 2;
  var line = this.layer_legend.line(this.legend_start_pos_x, current_y,
      this.legend_start_pos_x + title_width + 10, current_y);
  line.forward()
  // line.getStyle().setSVGProperty(SVGConstants.CSS_STROKE_PROPERTY, SVGConstants.CSS_DARKGRAY_VALUE);
  // line.getStyle().setSVGProperty(SVGConstants.CSS_STROKE_LINECAP_PROPERTY, "round");

  current_y += this.space_between_legend_items * 1 / 2;

  // console.log(legend,legend.getLegendItems())
  // 2. plot legends
  for (var ind in legend.getLegendItems()) {
    var item = legend.getLegendItems()[ind]
    var ashape = this.makeAShape(item.getStyle(), this.legend_start_pos_x, current_y+10, item.getWidth(),
        item.getWidth(), item.getColor(), item.getStrokeColor(), legend.getStrokeWidth(), 0,this.layer_legend,2,2,item.getName());
    // layer_legend.appendChild(ashape);

    // 2.2 text
    var item_text = this.layer_legend.text(item.getName()).move(
        this.legend_start_pos_x + item.getWidth() + this.space_between_legend_and_text,
        current_y + item.getWidth() / 2 + this.default_font_size * 1 / 3);
    if(item.getStyle() === 'branch_label'){
      item_text.hide()
    }
    item_text.font({ size:this.default_legend_font_size})
    // 2.3 change current Y
    current_y += item.getWidth() + this.space_between_legend_items;
  }
  // 3. change current Y again
  current_y += (this.space_between_legends - this.space_between_legend_items);
  return current_y;
}

addColumPlotsData( datasetID, dcon, treedecotyp,
 active) {
  var replot = active;
  if (this.charts.hasOwnProperty(datasetID)) {
    var plot =  this.charts[datasetID];
    plot.setDataSet(datasetID, dcon);
    plot.setActive(true);
    replot = true;
  } else {
    var plot = new ColumnPlots(this.layer_tree_charts, this.disable_all_dataset,this);
    plot.setDataSet(datasetID, dcon);
    plot.setActive(active);
    this.charts[datasetID] =  plot;
    if (active) {
      this.activeCharts.push(plot);
    }
  }

  if (replot) {
    this.updateAllCharts();
  }
  this.updateAllLegends();
}

getParamValues( key2params, keys) {
  for (var ind in  keys) {
    var key = keys[ind]
    key = key.toLowerCase();
    if (key2params.hasOwnProperty(key)) {
      return key2params[key];
    }
  }

  return []; // return empty
}

addTimeLineData( datasetID, dcon, type,
  active) {
  this.datasetID2dataContainerObj[datasetID] =  dcon;
  this.dataset2opacity[datasetID] =  dcon.getOpacity()
  if (active) {
    this.setActiveTimeLineByID(datasetID);
  }
}

makeFanPlot( center, innerRadius, outterRadius, angle1,
   angle2, angleSpan, parent_layer) {

  var dxy = Point.getCoordinatesOnCircle(center, innerRadius, angle2);
  var pxy = Point.getCoordinatesOnCircle(center, innerRadius, angle1); // xy of current node at parent circle
  var dxy2 = Point.getCoordinatesOnCircle(center, outterRadius, angle2);
  var pxy2 = Point.getCoordinatesOnCircle(center, outterRadius, angle1);

  var segs = new Point();
  segs.appendSegItem(Point.createPointStr(dxy.x, dxy.y)); // move to p1
  segs.appendSegItem(Point.createPointStr(dxy2.x, dxy2.y, 'L')); // line from p1 to p2;
  segs.appendSegItem(Point.createArcStr(pxy2.x, pxy2.y, outterRadius, 0,
      angleSpan > 180 ? 1 : 0, 1)); // arc from p2 to p3
  segs.appendSegItem(Point.createPointStr(pxy.x, pxy.y,'L')); // line from p3 to p4;
  segs.appendSegItem(Point.createArcStr(dxy.x, dxy.y, innerRadius, 0,
      angleSpan > 180 ? 1 : 0, 0)); // arc from p4 to p1
  // segs.appendItem(Point.createSVGPathSegClosePath()); // close path
  const path = parent_layer.path(segs.getSegPathListStr());
  return path;
}

makeTextLabelLayer( string, fontsize, labelstyle, parent_layer) {
  var layer_text_label = parent_layer.group().id(string)
  layer_text_label.attr("font-size", fontsize + ""); // font size;
  layer_text_label.attr("text-anchor", "middle"); // text anchor

  // font color
  if (labelstyle.length > 1 && labelstyle[1].trim().length>=1) {
    layer_text_label.attr("fill", labelstyle[1].trim());
  }
  // font italic
  if (labelstyle.length > 2 && labelstyle[2].trim() === ("1")) {
    layer_text_label.font({style:'italic'})
  }
  // font bold
  if (labelstyle.length > 3 && labelstyle[3].trim() === ("1")) {
    layer_text_label.font({weight:"bold"})
  }
  return layer_text_label;
}

// Dec 4, 2015;
setActiveTimeLineByID( datasetID) {
  if (this.datasetID2dataContainerObj.hasOwnProperty(datasetID)) {

    this.activeTimeLineID = datasetID;
    this.timeLineEnabled = true
    this.makeTimeLine();
    this.updateAllLegends();
  }
}

getActiveTimeLineDataset() {
  return this.datasetID2dataContainerObj[this.activeTimeLineID];
}

// set diable all bootstrap style ; dec 2, 2015;
setDisableAllTimeLineData( b) {
  this.timeLineEnabled = b;
  this.makeTimeLine();
  this.updateAllLegends();
return this.timeLineEnabled;
}

addDotPlotData( datasetID,  numdata, treedecotyp,
  active) {
  var replot = active;
  if (this.charts.hasOwnProperty(datasetID)) {
    var dotplots =  this.charts[datasetID];
    dotplots.setNumericDataSet(datasetID, numdata);
    dotplots.setActive(true);
    replot = true;
  } else {
    var dotplots = new DotPlots(this.layer_tree_charts, this.disable_all_dataset,this)
    dotplots.setNumericDataSet(datasetID, numdata);
    dotplots.setActive(active);
    this.charts[datasetID] =  dotplots;

    if (active) {
      this.activeCharts.push(dotplots);
    }
  }
  if (replot) {
    this.updateAllCharts();
  }
  this.updateAllLegends();
}

addHeatmapData( datasetID, heatdata, treedecotyp,
  active) {
  var replot = active;
  if (this.charts.hasOwnProperty(datasetID)) {
    var plot = this.charts[datasetID];
    plot.setHeatmapDataSet(datasetID, heatdata);
    plot.setActive(true);
    replot = true;
  } else {
    var plot = new Heatmap(this.layer_tree_charts, this.disable_all_dataset,this);
    plot.setHeatmapDataSet(datasetID, heatdata);
    plot.setActive(active);
    this.charts[datasetID] =  plot;
    if (active) {
      this.activeCharts.push(plot);
    }
  }
  if (replot) {
    this.updateAllCharts();
  }
  this.updateAllLegends();
}

setDisableLabelImages(status){
  this.labelImagesEnabled = status
  this.clearAllChildNodesFromSVGGElement(this.layer_imageLabel)
  this.makeLeafLabelsAndBranchLengthAndBootstrapValues()
  this.updateAllCharts()
  this.updateAllLegends()
  return status
}

addImageLabelData( datasetID, dcon, active) {  
  this.datasetID2LfImgCon[datasetID] = dcon
  if(active){
    this.setActiveImageLabelByID(datasetID)
  }
}

setActiveImageLabelByID(datasetID){

  if(this.datasetID2LfImgCon.hasOwnProperty(datasetID)){
    this.activeImageLabelId = datasetID;
    this.labelImagesEnabled = true 
    this.makeLeafLabelsAndBranchLengthAndBootstrapValues();
    this.makeLeafLabelBackground(); // 

    this.updateAllLegends();
    this.updateAllCharts(); // also update all charts ...
  }
}

findRightMostPosition(src){
  let rightPos = this.getLeafMostRightPosition()
  if(this.leafDecoEnabled){ //leaf decoration is on
    rightPos+=this.space_between_datasets
  }
  return rightPos
}


addDecoData( datasetID, dcon, type, active) {
  this.datasetID2DecoCon[datasetID] =  dcon; //
  this.dataset2opacity[datasetID] =  dcon.getOpacity();// ?? why ?? we don't need this hashmap ...

  /**
   * if is active ...
   */
  if (active) {
    this.setActiveLeafDecoByID(datasetID);
  }
}

setActiveLeafDecoByID( datasetid) {
  if (this.datasetID2DecoCon.hasOwnProperty(datasetid)) {
    this.activeLeafDecoSetID = datasetid;
    this.leafDecoEnabled = true 
    this.makeLeafLabelsAndBranchLengthAndBootstrapValues();
    this.makeLeafLabelBackground(); // July 4, 2014 --

    this.updateAllLegends();
    this.updateAllCharts(); // also update all charts ...
  }
}

setDisableAllLeafDecoData( b) {
  this.leafDecoEnabled = b;
  this.makeLeafLabelsAndBranchLengthAndBootstrapValues();

  this.makeLeafLabelBackground(); // Aug 1, 2014 ???
  this.updateAllLegends(); // May 24, 2011;
  this.updateAllCharts(); 
  return this.leafDecoEnabled;
}

getActiveLeafDecorationDataset() {
  return this.datasetID2DecoCon.hasOwnProperty(this.activeLeafDecoSetID)
      ? this.datasetID2DecoCon[this.activeLeafDecoSetID]
      : null;
}

getActiveLeafImageDataset(){
  return this.datasetID2LfImgCon.hasOwnProperty(this.activeImageLabelId) ? this.datasetID2LfImgCon[this.activeImageLabelId] : null
}


makeLeafLabelBackground() { //8333
  // first of all, clear its contents
  Point.clearAllChildNodesFromSVGGElement(this.layer_tree_leaflabel_background);

  var bAlignleaflable = (this.alignLeafLabels == true && (this.plotmode === (treePlotMode.RECT_PHYLOGRAM)
      || this.plotmode === (treePlotMode.CIRCULAR_PHYLOGRAM))) ? true : false;

  // April 8, 2011; opacity
  var opacity = this.dataset2opacity.hasOwnProperty(this.activeLeafBKColorSetID)
      ? this.dataset2opacity[this.activeLeafBKColorSetID]
      : 0.5; // by default, leaf background opacity is 0.5
  this.layer_tree_leaflabel_background.attr("fill-opacity", (opacity)); // change it
  // background colors for leaf nodes

  if (this.leafBKColorEnabled && !this.disable_all_dataset && this.activeLeafBKColorSetID.length >=1
      && this.showNodeLabel) {
        // console.log('going to make leaf bg')
    // 0 = treeskeleton; 1 = leaf
    var mostrightTree = this.getTreeMostRightPosition();
    var mostrightLeaf = this.getLeafMostRightPosition();

    // Log.debug("tree most right: " + mostrightTree + ", most right leaf : " + mostrightLeaf);

    // check if is slanted mode
    var isSlanted = this.plotmode === (treePlotMode.SLANTED_CLADOGRAM_MIDDLE)
        || this.plotmode === (treePlotMode.SLANTED_CLADOGRAM_NORMAL)
        || this.plotmode === (treePlotMode.SLANTED_CLADOGRAM_RECT);

    // make background; March 21, 2011
    for (var entry in this.LeafInternalID2NodePosisionts) {
      var leafnode = this.pyTree.getNodeByID(entry);
      var startpos = this.LeafInternalID2NodePosisionts[entry];
      var angle = startpos.angle

      var bkcolor = leafnode.getLeafBKColorByColorsetID(this.activeLeafBKColorSetID);
      if (!StringOps.equalsIgnoreCase( bkcolor,("white"))) {
        // if circular mode
        if (this.plotmode === (treePlotMode.CIRCULAR_CLADOGRAM)
            || this.plotmode === (treePlotMode.CIRCULAR_PHYLOGRAM)) {
          /**
           * 1. get four points that mark the fan-shape locations of the four points are:
           * 1 ---> 2 | | |arc | arc | v 4 <--- 3
           */
          var angleSpanPerLeafNode = this.angle_span / this.pyTree.getMaxVerticalLevel();

          // Dec 14, 2015; if collapsed mode, get it's real angle span ...
          if (leafnode.isCollapse()) {
            angleSpanPerLeafNode *= (Math.log10(leafnode.getNumberOfLeafDescendents()) + 1);
          }

          // calculate radius
          var innerRadius = (bAlignleaflable)
              ? mostrightTree + this.space_between_treeskeleton_and_leaflabels / 2 - this.rootXY.x
              : startpos.pt.x + this.space_between_treeskeleton_and_leaflabels / 2 - this.rootXY.x;
          var outterRadius = mostrightLeaf - this.rootXY.x + this.space_between_datasets / 3;

          var p1 = Point.getCoordinatesOnCircle(this.rootXY, innerRadius, 360 - angleSpanPerLeafNode / 2);
          var p2 = Point.getCoordinatesOnCircle(this.rootXY, outterRadius,
              360 - angleSpanPerLeafNode / 2);
          var p3 = Point.getCoordinatesOnCircle(this.rootXY, outterRadius, angleSpanPerLeafNode / 2);
          var p4 = Point.getCoordinatesOnCircle(this.rootXY, innerRadius, angleSpanPerLeafNode / 2);

          // 2. make fan using path
          var segs = new Point();
          segs.appendSegItem(Point.createPointStr(p1.x, p1.y)); // move to p1
          segs.appendSegItem(Point.createPointStr(p2.x, p2.y,'L')); // line from p1 to p2;
          segs.appendSegItem(Point.createArcStr(p3.x, p3.y, outterRadius,
              0, 0, 0)); // arc from p2 to p3
          segs.appendSegItem(Point.createPointStr(p4.x, p4.y,'L')); // line from p3 to p4;
          segs.appendSegItem(Point.createArcStr(p1.x, p1.y, innerRadius,
              0, 0, 1)); // arc from p4 to p1
          segs.closeSegItem(); // close path
          var fan = this.layer_tree_leaflabel_background.path(segs.getSegPathListStr())
          fan.attr("stroke", "none");
          fan.attr("fill", bkcolor);

          // 3. rotate; Feb 12, 2012 --
          if ((this.plotmode === treePlotMode.CIRCULAR_CLADOGRAM
              || this.plotmode === treePlotMode.CIRCULAR_PHYLOGRAM)) {
            fan.transform({rotation:-angle, cx:this.rootXY.x, cy:this.rootXY.y}); // March 21, 2011; this rotation
          }
        } else { // non-circular mode
          var rectX1 = (bAlignleaflable)
              ? mostrightTree + this.space_between_treeskeleton_and_leaflabels / 2
              : startpos.pt.x + this.space_between_treeskeleton_and_leaflabels / 2;
          var rectX2 = mostrightLeaf + this.space_between_datasets / 3;
          var rectWidth = rectX2 - rectX1;

          var rectHeight = ((leafnode.isCollapse() && !isSlanted)
              ? (Math.log10(leafnode.getNumberOfLeafDescendents()) + 1) * this.pxPerHeight
              : this.pxPerHeight);
          var rect = this.layer_tree_leaflabel_background.rect(rectWidth, rectHeight).move(rectX1, startpos.pt.y - rectHeight / 2).radius(0,0)          
          rect.attr("stroke", "none");
          rect.attr("fill", bkcolor);
        } // plot mode is circular / non-circular
      } // if background color isn't white
    } // iterate hashPositionOfLeafNodes one more time
  } // if leafBKColorEnabled
}// makeLeafLabelBackground

addGroupLabelData( datasetID, dcCon, grouplabels,active) {
  var replot = active;
  if (this.charts.hasOwnProperty(datasetID)) {
    var grouplabelPlot = this.charts[datasetID];
    grouplabelPlot.setGroupLabelData(datasetID, dcCon);
    grouplabelPlot.setActive(true);

    replot = true;
  } else {
    var grouplabelPlot = new GroupLabelPlot(this.layer_tree_charts, this.disable_all_dataset,this);
    grouplabelPlot.setGroupLabelData(datasetID, dcCon);
    grouplabelPlot.setActive(active);
    this.charts[datasetID] =  grouplabelPlot;

    if (active) {
      this.activeCharts.push(grouplabelPlot);
    }
  }

  if (replot) {
    this.updateAllCharts();
  }

  this.updateAllLegends();
}

addGeneTransferData(datasetID, dcCon,active){
  if(this.geneTransferMap.hasOwnProperty(datasetID)){
    let geneTransferDat = this.geneTransferMap[datasetID]
    geneTransferDat.setGeneTransferData(datasetID,dcCon)
    geneTransferDat.setActive(active)
  }else{
    let geneTransferDat = new GeneTransferPlot(this.layer_geneTransfer,this.disable_all_dataset,this)
    geneTransferDat.setGeneTransferData(datasetID,dcCon)
    geneTransferDat.setActive(active)
    this.geneTransferMap[datasetID] =  geneTransferDat;    
  }  
  if(active){
    this.geneTransferEnabled = true
    this.setActiveGeneTransferByID(datasetID)    
  }
}

setActiveGeneTransferByID(datasetID){
  if(this.geneTransferMap.hasOwnProperty(datasetID)){
    this.activeGeneTransferID = datasetID
    this.geneTransferMap[datasetID].setActive(true)

    this.makeLeafLabelsAndBranchLengthAndBootstrapValues()
    this.makeLeafLabelBackground();
    this.updateAllCharts()
    this.updateAllLegends()
  }else{
  }
}

/*
 * TYPE can be : leaf | leafBK | branch | leaf label decoration
 * This function is used to add color dataset to Leaf, leaf background and
 * branches ;add param: active
 * -------------------------------------------------------------------------
 * added modifier 'toroot' to branchColor:
 */
addColorsetToTreeNodes( colorID, coldatcon, type,
   active) {
  var replot = active;
  // if current dataset exists, delete it
  if (this.datasetID2dataContainerObj.hasOwnProperty(colorID)) {
    for (var ind in this.pyTree.getAllNodes()) {
      var node = this.pyTree.getAllNodes()[ind]
      node.removeColorByDataTypeAndID(type, colorID);
    } // iterate all nodes

    replot = true;
  }
  this.datasetID2dataContainerObj[colorID] =  coldatcon; //

  // put opacity; NOTE: april 8, 2011; different types of plot have different
  // opacity default values
  if (type === (TreeDecoType.LEAFBKCOLOR)) {
    this.dataset2opacity[colorID] =  coldatcon.isIsUserInputOpacity() ? coldatcon.getOpacity() : 0.6;
  } else {
    this.dataset2opacity[colorID] =  coldatcon.getOpacity();
  }

  // Jan 25, 2017; set default legend style for branch colors
  if (type === (TreeDecoType.BRANCHCOLOR) && coldatcon.getLegendShapesOriginal().length <=0) {
    if(coldatcon.hasStrokeInfo()){
      //draw stroke based legend
    }else{
      coldatcon.addALegendShape("line");

      for (var  ind in coldatcon.getLegend().getLegendItems()) {
        var leit = coldatcon.getLegend().getLegendItems()[ind]
        leit.setStyle("line");
      }
    }
  }

  /*
  * add color to tree branch | leaf | leafBK
  */
  for (var ind in coldatcon.getColorsets()) {
    var colorset = coldatcon.getColorsets()[ind]
    var color = colorset.getColors()[0].getColor(); // only the first colorset will be taken
    var mode = colorset.getMode(); // mode can be prefix, suffix, anywhere, ad, and toroot|2root
    var leafID = colorset.getNodeIDs()[0]; // get the first element; to lower case
    var hasStroke = colorset.getStroke()
    /*
    * set colors according to modes
    */
    if (mode.length<=0 || mode === '-') {
      var phylonode = type === (TreeDecoType.BRANCHCOLOR)
          ? this.pyTree.getLCA(colorset.getNodeIDs())
          : this.pyTree.getNodeByID(leafID);
      if (phylonode != null) {
        phylonode.addColorByDataTypeAndID(type, colorID, color);
        if(hasStroke.length > 1){
          phylonode.addStrokeByDataTypeAndId(colorID, hasStroke);
        }
      }

      // if there are two leafIDs, also set leaf color for current internal node ...
      if (colorset.getNodeIDs().length >= 2) {
        var lcanode = this.pyTree.getLCA(colorset.getNodeIDs());
        if (lcanode != null) {
          if (type === (TreeDecoType.LEAFCOLOR)) {
            lcanode.addColorToLeaf(colorID, color);
          } else if (type === (TreeDecoType.LEAFBKCOLOR)) {
            lcanode.addColorToLeafBK(colorID, color);
          }
        }
      }
    } else {
      leafID = leafID.toLowerCase();
      for (var ind in this.pyTree.getAllNodes()) {
        var node = this.pyTree.getAllNodes()[ind]
        var nodeID = node.getID().toLowerCase();
        if ((StringOps.equalsIgnoreCase( mode,("prefix")) && nodeID.startsWith(leafID))
            || (StringOps.equalsIgnoreCase(mode,("suffix")) && nodeID.endsWith(leafID))
            || (StringOps.equalsIgnoreCase(mode,("anywhere")) && nodeID.includes(leafID))) {
          node.addColorByDataTypeAndID(type, colorID, color);
          if(hasStroke.length > 1){
            node.addStrokeByDataTypeAndId(colorID, hasStroke);
          }
        } else if (StringOps.equalsIgnoreCase(mode,("ad"))) {
          var phylonode = this.pyTree.getLCA(colorset.getNodeIDs());
          if (phylonode != null) {
            if (type === (TreeDecoType.BRANCHCOLOR)) {
              this.colorAllDescendentBranches(phylonode, color, colorID);
              if(hasStroke.length >= 1){
                this.strokeAllDescendentBranches(phylonode, hasStroke, colorID);
              }
            } else if (type === (TreeDecoType.LEAFCOLOR)) {
              phylonode.addColorToLeaf(colorID, color);
              this.colorAllDescendentLeafLabels(phylonode, color, colorID);
            } else if (type === (TreeDecoType.LEAFBKCOLOR)) {
              this.colorAllDescendentLeafLabelBackground(phylonode, color, colorID);
            }
          }
        } // mode is AD
        
        /**
         * added modifier 'toroot' to branchColor:
         */
        else if ((StringOps.equalsIgnoreCase(mode,("toroot")) || StringOps.equalsIgnoreCase(mode,("2root"))) && StringOps.equalsIgnoreCase(node.getID(),leafID)) {
          for (var ind in this.pyTree.getPath2Root(node)) {
            var n = this.pyTree.getPath2Root(node)[ind]
            n.addColorByDataTypeAndID(type, colorID, color);
            if(hasStroke.length >= 1){
              n.addStrokeByDataTypeAndId(colorID, hasStroke);
            }
          }
        } //        
      } // iterate phylonodes
    } // different modes
  } // iterate colorsets

  /*
  * at the end, set active datasetID and update plot ; make
  * legend;
  */
  if (replot) {
    if (type === (TreeDecoType.BRANCHCOLOR)) {
      this.activeBranchColorSetID = colorID;
      this.branchColorEnabled = true
      this.makeTreeSkeleton();
      this.drawTreeSkeleton(this.rootnode)
      this.makeLeafLabelsAndBranchLengthAndBootstrapValues();
    } else if (type === (TreeDecoType.LEAFCOLOR)) {
      this.activeLeafColorSetID = colorID;
      this.leafColorEnabled = true
      this.makeLeafLabelsAndBranchLengthAndBootstrapValues();
    } else if (type === (TreeDecoType.LEAFBKCOLOR)) {
      this.leafBKColorEnabled = true
      this.activeLeafBKColorSetID = colorID;
      this.makeLeafLabelBackground();
    }
  }

  /*
  * May 24, 2011; make legendentry; delete old ones if exists
  */
  this.updateAllLegends();
}// addColorsetToTreeNodes

colorAllDescendentBranches( phylonode, color, colorsetID) {
  phylonode.addColorToBranch(colorsetID, color);
  if (!phylonode.isleaf()) { // if not leaf
    for (var ind in phylonode.getDescendents()) {
      var node = phylonode.getDescendents()[ind]
      this.colorAllDescendentBranches(node, color, colorsetID);
    }
  }
}

strokeAllDescendentBranches( phylonode, color, colorsetID) {
  phylonode.addStrokeToBranch(colorsetID, color);
  if (!phylonode.isleaf()) { // if not leaf
    for (var ind in phylonode.getDescendents()) {
      var node = phylonode.getDescendents()[ind]
      this.strokeAllDescendentBranches(node, color, colorsetID);
    }
  }
}
colorAllDescendentLeafLabels( phylonode, color, colorsetID) {
  if (!phylonode.isleaf()) { // if not leaf
    for (var ind in phylonode.getDescendents()) {
      var node = phylonode.getDescendents()[ind]
      this.colorAllDescendentLeafLabels(node, color, colorsetID);
    }
  } else {
    phylonode.addColorToLeaf(colorsetID, color);
  }
} // colorAllDescendentLeafLabels; March 20, 2011;

colorAllDescendentLeafLabelBackground( phylonode, color, colorsetID) {
  if (phylonode.isleaf()) { // if leaf
    phylonode.addColorToLeafBK(colorsetID, color);
  } else { // if not
    for (var ind in phylonode.getDescendents()) {
      var node = phylonode.getDescendents()[ind]
      this.colorAllDescendentLeafLabelBackground(node, color, colorsetID);
    }
  }
} // colorAllDescendentLeafLabelBackground; March 21, 2011;

addColorStripData( datasetID, coldatCon, active) {
  var replot = active;
  /*
   * if dataset exists; repalce it NOTE: datasetID2plotbase and activePlotBase
   * contain the same set of PlotBase classes; April 5, 2011;
   */
  if (this.charts.hasOwnProperty(datasetID)) {
    var colstrip = this.charts[datasetID];
    colstrip.setColorData(datasetID, coldatCon);

    if (!this.activeCharts.includes(colstrip)) {
      this.activeCharts.push(colstrip);
    }
    colstrip.setActive(true);

    replot = true;
  } else {
    var colstrip = new ColorStrips(this.layer_tree_charts, this.disable_all_dataset,this);
    colstrip.setColorData(datasetID, coldatCon);
    this.charts[datasetID] =  colstrip;
    colstrip.setActive(active);

    // if active; Oct 28, 2011;
    if (active) {
      colstrip.setActive(true);
      this.activeCharts.push(colstrip);
    }
  }

  if (replot) {
    this.updateAllCharts();
  }

  /*
   * May 24, 2011; make legendentry; delete old ones if exists
   */
  this.updateAllLegends();
}

setActiveChartByID( datasetID, active) {
  if (this.charts.hasOwnProperty(datasetID)) {
    var pb = this.charts[datasetID];
    pb.setActive(active);
    if (active && !this.activeCharts.includes(pb) && pb != undefined) {
      this.activeCharts.push(pb);
    }
    // remove pb if it is set to inactive ...
    if (!active && this.activeCharts.includes(pb)) {
      this.removeFromChartArray(this.activeCharts,pb)
    }
    this.updateAllCharts();
    this.updateAllLegends();
  }else{
    console.log('not found chart',datasetID,active)
  }
}

deleteChartByID( datasetID) {
  if (this.charts.hasOwnProperty(datasetID)) {
    var pb = this.charts[datasetID];
    this.removeFromChartArray(this.charts,pb)
    this.removeFromChartArray(this.activeCharts,pb)
    // if is in the active list: this is almost always true // also remove it from the active list

    // update all legends anyway
    this.updateAllLegends();

    // if active, set it as inactive and then replot
    if (pb.getActive()) {
      pb.setActive(false);
      pb.makeOrUpdatePlot(); // this is vital
      this.updateAllCharts();
    }
  }
}

setDisableAllCharts( b) {
  this.chart_plots_enabled = b;
  this.updateAllCharts();
  // update legend
  this.updateAllLegends();
  return this.chart_plots_enabled;
}

removeFromChartArray(array,pbObject){
  var ind = 0
  for(var i = 0;i <array.length;i++){
    var pb = array[i]
    if(pb.getDataSetID() === pbObject.getDataSetID()){
      ind = i
      break;
    }
  }
  array.splice(ind,1)
}

addBranchLabelData(datasetID, dcon, type, active){  
  this.datasetID2dataContainerObj[datasetID] =  dcon;  
  if(active){
    this.branchLabelEnabled = true
    this.setActiveBranchLabelByID(datasetID)    
  }
}

setActiveBranchLabelByID(datasetID){
  if(this.datasetID2dataContainerObj.hasOwnProperty(datasetID)){
    this.activeBranchLabelID = datasetID
    let coldatcon = this.datasetID2dataContainerObj[datasetID]    
    this.clearAllChildNodesFromSVGGElement(this.layer_branchLabel)
    for (var ind in coldatcon.getColorsets()) {
      var colorset = coldatcon.getColorsets()[ind]
      if(colorset.nodeIDs != null && colorset.nodeIDs[0].startsWith("INT")){
        var phyNode = this.pyTree.getNodeByID(colorset.getNodeIDs())
        if(phyNode != null){
          // console.log(phyNode.internal_id,phyNode)
          phyNode.addLabelsToBranch(datasetID, colorset.getItemDef());
        }
      }else{
        var phyNode = this.pyTree.getLCA(colorset.getNodeIDs())
        if(phyNode != null){
          this.addBranchLabelsToAllDescendentBranches(phyNode,colorset.getItemDef(),datasetID)
        }
      }
    }
    this.drawTreeSkeleton(this.rootnode)
    this.updateAllLegends(); 
  }
}

addBranchLabelsToAllDescendentBranches( phylonode, color, colorsetID) {
  if (!phylonode.isleaf()) { // if not leaf
    for (var ind in phylonode.getDescendents()) {
      var node = phylonode.getDescendents()[ind]
      this.addBranchLabelsToAllDescendentBranches(node, color, colorsetID);
    }
  }else{
    phylonode.addLabelsToBranch(colorsetID, color);
  }
}

addInternalCollapseData(datasetID, dcon, type, active) {
this.datasetID2dataContainerObj[datasetID] =  dcon;
this.dataset2opacity[datasetID] =  dcon.getOpacity();
  if (active) {
    this.collapseInternalEnabled = true
    this.setActiveInternalCollapseDatasetByID(datasetID);
  }
}

addBranchAnnotateData(datasetID, dcon, type, active){  
  if(this.leafAnnMap.hasOwnProperty(datasetID)){
    let leafAnnDat = this.leafAnnMap[datasetID]
    leafAnnDat.setDataSet(datasetID,dcon)
    leafAnnDat.setActive(active)
    leafAnnDat.setAnnotationStyle(dcon.getLeafAnnotateStyle())
  }else{
    let leafAnnDat = new LeafAnnotation(this.layer_leafAnnotate,this.disable_all_dataset,this)
    leafAnnDat.setDataSet(datasetID,dcon)
    leafAnnDat.setActive(active)
    leafAnnDat.setAnnotationStyle(dcon.getLeafAnnotateStyle())
    this.leafAnnMap[datasetID] =  leafAnnDat;    
  }  
  if(active){
    this.leafAnnEnabled = true
    this.setActiveLeafAnnotateByID(datasetID)    
  }
}

setActiveLeafAnnotateByID(datasetID){
  if(this.leafAnnMap.hasOwnProperty(datasetID)){
    this.activeBranchAnnotateID = datasetID
    this.leafAnnMap[datasetID].setActive(true)

    this.makeLeafLabelsAndBranchLengthAndBootstrapValues()
    this.makeLeafLabelBackground();
    this.updateAllCharts()
    this.updateAllLegends()
  }else{
  }
}

setDisableBranchMarker(status){
  // console.log(status,this.activeBranchMarkID)
  this.branchMarkEnabled = status
  this.clearAllChildNodesFromSVGGElement(this.layer_branchMarker)
  if(status){
    this.loadActiveBranchMarker()
  }
  this.updateAllLegends()  
  return status
}

addBranchMarkerData(datasetID, dcon, type, active){
  if(this.branchMarkMap.hasOwnProperty(datasetID)){
    let branchDat = this.branchMarkMap[datasetID]
    branchDat.setDataSet(datasetID,dcon)
    branchDat.setBootstrapPositions(this.BootstrapPositions)
    branchDat.setActive(active)
  }else{
    let branchDat = new BranchMarker(this.layer_branchMarker,this.disable_all_dataset,this)
    branchDat.setDataSet(datasetID,dcon)
    branchDat.setActive(active)
    branchDat.setBootstrapPositions(this.BootstrapPositions)
    this.branchMarkMap[datasetID] =  branchDat;    
  }  
  if(active){
    // console.log(this.branchMarkMap[datasetID])
    this.branchMarkEnabled = true
    this.setActiveBranchMarkByID(datasetID)    
  }
}

setActiveBranchMarkByID(datasetID){
  // console.log(datasetID,Object.keys(this.branchMarkMap))
  if(this.branchMarkMap.hasOwnProperty(datasetID)){
    this.activeBranchMarkID = datasetID
    this.loadActiveBranchMarker()
  }
}

loadActiveBranchMarker(){ 
  // console.log(this.branchMarkEnabled,this.activeBranchMarkID)
  if(this.branchMarkEnabled && this.activeBranchMarkID != null && this.activeBranchMarkID.length > 1){
    this.clearAllChildNodesFromSVGGElement(this.layer_branchMarker)
    let coldatcon = this.branchMarkMap[this.activeBranchMarkID]
    coldatcon.setBootstrapPositions(this.BootstrapPositions)
    coldatcon.setRootXY(this.rootXY)
    coldatcon.setPlotMode(this.plotmode)
    coldatcon.setActive(true)
    coldatcon.makeOrUpdatePlot()
    this.updateAllLegends()  
  }
}

// set dataset active ...
setActiveInternalCollapseDatasetByID(datasetid) {
  if (this.datasetID2dataContainerObj.hasOwnProperty(datasetid)) {
    this.activeInternalCollapseDatasetID = datasetid;
    this.makeTreeSkeleton();
    this.makeLeafLabelsAndBranchLengthAndBootstrapValues(); //
    this.makeLeafLabelBackground();
    this.drawTreeSkeleton(this.rootnode)
    this.pie.makePies(); // also update pies Dec 15, 2015;
    this.updateAllCharts();
    this.updateAllLegends();
  }
}

setDisableAllPiechartData(disableAll) { 
  this.pie.setDisableAllPiechartData(!disableAll);
  this.updateAllLegends();
}

setDisableAllLabelColorData(b) {
  this.leafColorEnabled = b;
  this.makeLeafLabelsAndBranchLengthAndBootstrapValues();
  this.updateAllLegends(); 
  return this.leafColorEnabled;
} 

setActiveLeafBKcolorByDatasetID(colorSetID) {
  if (this.datasetID2dataContainerObj.hasOwnProperty(colorSetID)) {

    this.activeLeafBKColorSetID = colorSetID;
    this.makeLeafLabelBackground();

    this.updateAllLegends(); // May 24, 2011;
  }
}

setActiveLeafColorSetByID(ccolorSetID) {
  if (this.datasetID2dataContainerObj.hasOwnProperty(ccolorSetID)) {

    // update leaflable colors
    this.activeLeafColorSetID = ccolorSetID;
    this.updateLeafLabelColors();

    this.updateAllLegends(); // May 24, 2011;
  }
}

updateLeafLabelColors() {
  for (var ind in this.pyTree.getLeafNodes()) {
    var leafnode = this.pyTree.getLeafNodes()[ind]
    var color = (this.leafColorEnabled && this.activeLeafColorSetID.length >=1 && !this.disable_all_dataset)
        ? leafnode.getLeafColorByColorsetID(this.activeLeafColorSetID)
        : "black";
    
    // console.log(leafnode.getInternalID(),leafnode.getID(),color,this.activeLeafColorSetID,this.leafColorEnabled,leafnode.getLeafColorByColorsetID(this.activeLeafColorSetID))

    if (!this.bHTMLmode) {
      var text = this.leafInternalID2LeafTextLabelOMNodeObj[leafnode.getInternalID()];
      text.font({fill:color});
    } else {
      var fo = this.leafInternalID2LeafTextLabelOMNodeObj[leafnode.getInternalID()];
      fo.font({fill:color});
    }

  }
  // April 8, 2011; change opacity as well;
  var opacity = this.dataset2opacity.hasOwnProperty(this.activeLeafColorSetID)
      ? this.dataset2opacity[this.activeLeafColorSetID]
      : 1;
  this.layer_tree_leaf_labels.attr("fill-opacity", opacity+""); // change it
}

setDisableAllLabelBKColorData(b) {
  this.leafBKColorEnabled = b;
  this.makeLeafLabelBackground();
  this.updateAllLegends();
  return this.leafBKColorEnabled;
}

setDisableAllBranchLabelData(b) {
  this.branchLabelEnabled = b;
  this.clearAllChildNodesFromSVGGElement(this.layer_branchLabel)
  this.drawTreeSkeleton(this.rootnode);
  this.updateAllLegends()
  return this.branchLabelEnabled;
}

setDisableAllBranchAnnotateData(b) {
  this.leafAnnEnabled = b;
  this.clearAllChildNodesFromSVGGElement(this.layer_leafAnnotate)
  this.makeLeafLabelsAndBranchLengthAndBootstrapValues()
  this.updateAllCharts()
  this.updateAllLegends()
  return this.leafAnnEnabled;
}

setDisableGeneTransfer(b) {
  this.geneTransferEnabled = b;
  this.clearAllChildNodesFromSVGGElement(this.layer_geneTransfer)
  this.makeLeafLabelsAndBranchLengthAndBootstrapValues()
  this.updateAllCharts()
  this.updateAllLegends()
  return this.geneTransferEnabled;
}

setActiveBranchColorByDatasetID( branchColorSetID) {
  if (this.datasetID2dataContainerObj.hasOwnProperty(branchColorSetID)) {
    this.activeBranchColorSetID = branchColorSetID;
    // this.branchColorEnabled = true
    // console.log(branchColorSetID,this.branchColorEnabled)
    // at the end, update tree skeleton
    this.makeTreeSkeleton();
    this.drawTreeSkeleton(this.rootnode)
    this.makeLeafLabelsAndBranchLengthAndBootstrapValues();
    this.updateAllLegends(); // May 24, 2011;
  }
}

// set diable all internal collapseData ; dec 11, 2015;
setDisableAllInternalCollapseData( b) {
  this.collapseInternalEnabled = b;
  this.makeTreeSkeleton();
  this.drawTreeSkeleton(this.rootnode)
  this.makeLeafLabelsAndBranchLengthAndBootstrapValues(); //
  this.makeLeafLabelBackground();
  this.pie.makePies(); // also update pies Dec 15, 2015;
  this.updateAllCharts();
  this.updateAllLegends();
  return this.collapseInternalEnabled;
}

addProteinDomainData(datasetID, domaindata, active) {
  //
  var replot = active;
  if (this.charts.hasOwnProperty(datasetID)) {
    var domainplot = this.charts[datasetID];
    domainplot.setProteinDomainDataSet(datasetID, domaindata);
    domainplot.setActive(true);
    replot = true;
  } else {
    // 1. add dataset
    var domainplot = new ProteinDomains(this.layer_tree_charts,this.disable_all_dataset,this);
    domainplot.setProteinDomainDataSet(datasetID, domaindata);
    domainplot.setActive(active);
    this.charts[datasetID] =  domainplot;
    if (active) {
      this.activeCharts.push(domainplot);
    }
  }

  if (replot) {
    this.updateAllCharts();
  }

  /*
   * NOTE: here TreeDecoType must be 'CHARTS'; to be fixed
   */
  this.updateAllLegends();
}

addGeneData(datasetID, geneData, active) {
  var replot = active;
  if (this.charts.hasOwnProperty(datasetID)) {
    var genePlot = this.charts[datasetID];
    genePlot.setGeneDataSet(datasetID, geneData);
    genePlot.setActive(true);
    replot = true;
  } else {
    // 1. add dataset
    var genePlot = new GeneSynteny(this.layer_tree_charts,this.disable_all_dataset,this);
    genePlot.setGeneDataSet(datasetID, geneData);
    genePlot.setActive(active);
    this.charts[datasetID] =  genePlot;
    if (active) {
      this.activeCharts.push(genePlot);
    }
  }

  if (replot) {
    this.updateAllCharts();
  }
  this.updateAllLegends();
}

	/**
	 * April 4, 2015
	 * 
	 * @param x
	 * @param y
	 * @param value
	 * @param guessFillColorBasedOnBkColor
	 * @param bkColor
	 * @param isCircular
	 * @return
	 */
	makeShowDataValue4DotplotsAndHeatMap( x,  y,  value,  fontsize,
     angle, guessFillColorBasedOnBkColor, bkColor, isCircular, parent_layer) {

  // create a svg text object and add it to rowplot
  var txtData = parent_layer.text(value+"").move(0,0)
  txtData.attr("text-anchor", "middle");
  /**
   * April 4, 2015; when the default color is not set, decide whether to plot it
   * black or white
   */
  if (guessFillColorBasedOnBkColor) {
    var txtColor = ColorUtils.BlackOrWhiteOnABackground(RgbaColor.from(bkColor));
    if (!StringOps.equalsIgnoreCase( txtColor,("black"))) {
      txtData.attr("fill", txtColor);
    }
  }
  // now move the text to intented area --
  txtData.move(x,y-3)

  // if circular mode
  if ((this.plotmode === treePlotMode.CIRCULAR_CLADOGRAM || this.plotmode === treePlotMode.CIRCULAR_PHYLOGRAM)) {
    txtData.transform({rotation:180 - angle, cx:this.rootXY.x, cy: this.rootXY.y})

    // for better readability
    if ((angle >= 270 && angle <= 360) || (angle >= 0 && angle <= 90)) {
      // change y --
      // y -= fontsize * 2 / 3;
      // txtData.y(y+8);
      // console.log(value,angle,x,y)
      // rotate      
      txtData.transform({rotation:360, x:x, y:y })
    }
  }

  return txtData;
}

/**
 * 
 * @param layer
 * @param shape
 * @param startx
 * @param starty
 * @param itemwidth
 * @param itemheight
 * @param color
 * @param strokecolor
 * @param strokewidth
 * @param angle
 * @param group_layer
 * @param rx
 *            : rounded cornor x, only applies to rect, default = 2
 * @param ry
 *            : rounded cornor y, only applies to rect, default = 2
 * 
 *            last modified: added itemheight; Jan 26, 2017; + note in most
 *            cases (as of Jan 2017) itemWidth and itemHeight are the same ...
 * 
 *            Supported shapes are: + check + star + rect + circle
 * 
 *            ---------- last modified : Jan 26, 2017 ---------- + triangle
 *            NOTE: this is the traditional triangle -- + uptriangle or triangle
 *            1 + downtriangle or triangle2 + leftTriangle or triangle3 +
 *            rightTriangle or triangle4 + ellipse + line
 * 
 */
makeAShape( shape, startx, starty, itemwidth, itemheight,
    color, strokecolor, strokewidth, angle, group_layer, rx = 2, ry = 2, item_name= '') {

  var ashape = group_layer.group().id('ashape')
  // console.log(shape,item_name)

  if ( StringOps.equalsIgnoreCase( shape,("circle"))) {
    var circle  =  ashape.circle(itemwidth).move(startx , starty)    
    circle.attr("fill", color);
    if (strokecolor.length>=1 && !StringOps.equalsIgnoreCase(strokecolor,("none"))) {
      circle.attr("stroke", strokecolor);
      circle.attr("stroke-width", strokewidth + "");
    }
    if (angle != 0) {
      circle.transform({rotation:180-angle, cx:this.rootXY.x, cy: this.rootXY.y})
    }
  }
  // ellipse ; jan 26, 2017 ...
  else if (StringOps.equalsIgnoreCase(shape,("ellipse"))) {
    var ellipse = ashape.ellipse(itemwidth / 2, itemheight / 2).move(startx + itemwidth / 2, starty + itemheight / 2)    
    ellipse.attr("fill", color);
    if (strokecolor.length>=1 && !StringOps.equalsIgnoreCase(strokecolor,("none"))) {
      ellipse.attr("stroke", strokecolor);
      ellipse.attr("stroke-width", strokewidth + "");
    }
    if (angle != 0) {
      ellipse.transform({rotation:180-angle, cx:this.rootXY.x, cy: this.rootXY.y})
    }
  } else if (StringOps.equalsIgnoreCase(shape,("star"))) {
    var star = Shapes.makeStar(Point.createPoint(startx + itemwidth / 2, starty + itemwidth / 2),
        itemwidth / 4, 2, ashape);
    star.attr("fill", color);
    if (strokecolor.length>=1 && !StringOps.equalsIgnoreCase(strokecolor,("none"))) {
      star.attr("stroke", strokecolor);
      star.attr("stroke-width", strokewidth + "");
    }
    if (angle != 0) {
      star.transform({rotation:180-angle, cx:this.rootXY.x, cy: this.rootXY.y})
    }
  } else if (StringOps.equalsIgnoreCase(shape,("triangle"))) {
    var tri = Shapes.maketriangle(Point.createPoint(startx, starty + itemwidth), itemwidth,ashape);
    tri.attr("fill", color);

    // March 21, 2014 : apply stroke color
    if (strokecolor.length>=1 && !StringOps.equalsIgnoreCase(strokecolor,("none"))) {
      tri.attr("stroke", strokecolor);
      tri.attr("stroke-width", strokewidth + "");
    }
    if (angle != 0) {
      tri.transform({rotation:180-angle, cx:this.rootXY.x, cy: this.rootXY.y})
    }
  }else if ( StringOps.equalsIgnoreCase( shape,("cross"))) {
    let cross = ashape.group('cross')
    var c1  =  cross.line(startx , starty,startx +itemwidth, starty+itemheight).size(itemwidth,itemheight)
    c1.stroke({ color: '#f06', width: 2, linecap: 'round' })
    var c2  =  cross.line(startx, starty+itemheight,startx+itemwidth , starty).size(itemwidth,itemheight)
    c2.stroke({ color: '#f06', width: 2, linecap: 'round' })
    // cross.stroke({ color: '#f06', width: 10, linecap: 'round' })
    if (strokecolor.length>=1 && !StringOps.equalsIgnoreCase(strokecolor,("none"))) {
      cross.attr("stroke", strokecolor);
      cross.attr("stroke-width", strokewidth + "");
      // cross.stroke({ color: '#f06', width: 10, linecap: 'round' })
    }
    if (angle != 0) {
      cross.transform({rotation:180-angle, cx:this.rootXY.x, cy: this.rootXY.y})
    }
  }
  /**
   * ############################################################### different
   * types of triangles ; please NOTE: the following triangles are different from
   * the above ###############################################################
   */
  else if (shape.toLowerCase().includes("triangle")) {

    // first, decide the coordinates of the three points --
    var p1x = 0, p1y = 0, p2x = 0, p2y = 0, p3x = 0, p3y = 0;
    if (StringOps.equalsIgnoreCase(shape,("uptriangle"))) {
      // bottom-left
      p1x = startx;
      p1y = starty + itemheight;

      // bottom-right
      p2x = startx + itemwidth;
      p2y = starty + itemheight;

      // top
      p3x = startx + itemwidth / 2;
      p3y = starty;
    } else if (StringOps.equalsIgnoreCase(shape,("downtriangle"))) {
      // top-left
      p1x = startx;
      p1y = starty;

      // top-right
      p2x = startx + itemwidth;
      p2y = starty;

      // bottom
      p3x = startx + itemwidth / 2;
      p3y = starty + itemheight;
    } else if (StringOps.equalsIgnoreCase(shape,("lefttriangle"))) {
      // left
      p1x = startx;
      p1y = starty + itemheight / 2;

      // top-right
      p2x = startx + itemwidth;
      p2y = starty;

      // bottom-right
      p3x = startx + itemwidth;
      p3y = starty + itemheight;
    } else if (StringOps.equalsIgnoreCase(shape,("righttriangle"))) {
      // top-left
      p1x = startx;
      p1y = starty;

      // bottom-left
      p2x = startx;
      p2y = starty + itemheight;

      // right
      p3x = startx + itemwidth;
      p3y = starty + itemheight / 2;
    }

    // second, make the triangles --
    var segs = new Point()
    segs.appendSegItem(Point.createPointStr(p1x, p1y));
    segs.appendSegItem(Point.createPointStr(p2x, p2y, 'L')); // line from p1 to p2;
    segs.appendSegItem(Point.createPointStr(p3x, p3y, 'L')); // line from p2 to p3;
    segs.appendSegItem(Point.createPointStr(p1x, p1y, 'L')); // line from p3 to p1;
    segs.closeSegItem(); // close path
    var tri = ashape.path(segs.getSegPathListStr())

    // third, styling --
    tri.attr("fill", color);

    if (strokecolor.length>=1 && !StringOps.equalsIgnoreCase(strokecolor,("none"))) {
      tri.attr("stroke", strokecolor);
      tri.attr("stroke-width", strokewidth + "");
    }

    // fourth, rotation
    if (angle != 0) {
      tri.transform({rotation:180-angle, cx:this.rootXY.x, cy: this.rootXY.y})
    }
  }

  /**
   * #################################################### line; Jan 25, 2017 ...
   * ####################################################
   */
  else if (StringOps.equalsIgnoreCase(shape,("line"))) {
    var line  = ashape.line(startx, starty + itemwidth / 2, startx + itemwidth,
      starty + itemwidth / 2)
    line.attr("stroke-width", 3 + "px");
    line.attr("stroke", color);
    line.attr("stroke-linecap", "round"); // linecap to round

    if (angle != 0) {
      line.transform({rotation:180 - angle, cx: this.rootXY.x, cy: this.rootXY.y});
    }
  }else if(StringOps.equalsIgnoreCase(shape,("dash"))){
    var line  = ashape.line(startx, starty + itemwidth / 2, startx + itemwidth,
      starty + itemwidth / 2)
    line.attr("stroke-width", 3 + "px");
    line.attr("stroke", color);
    line.attr("stroke-linecap", "round"); // linecap to round
    line.attr('stroke-dasharray','5,5')
    if (angle != 0) {
      line.transform({rotation:180 - angle, cx: this.rootXY.x, cy: this.rootXY.y});
    }
  }else if (StringOps.equalsIgnoreCase(shape,("check")) || StringOps.equalsIgnoreCase(shape,("positivecheck"))) {
    var check = Shapes.makeCheckSymbol(Point.createPoint(startx + 1, starty + itemwidth / 2), itemwidth,ashape);
    check.attr("stroke-width", 3 + "px");
    check.attr("stroke", color);
    check.attr("stroke-linecap", "round"); // linecap to round

    if (angle != 0) {
      check.transform({rotation:180-angle, cx:this.rootXY.x, cy:this.rootXY.y})
    }
  }else if(StringOps.equalsIgnoreCase(shape,("branch_label"))) { // default is rect
    var label = ashape.text(item_name).move(startx+2, starty-2).fill('black')
    var rect = ashape.rect(label.length()+5, itemwidth).move(startx, starty)
    rect.radius(rx, ry)
    rect.attr("fill", color);
    if (strokecolor.length>=1 && !StringOps.equalsIgnoreCase(strokecolor,("none"))) {
      rect.attr("stroke", strokecolor);
      rect.attr("stroke-width", strokewidth + "");
    }

    if (angle != 0) {
      rect.transform({rotation:180-angle, cx:this.rootXY.x, cy:this.rootXY.y})
      label.transform({rotation:180-angle, cx:this.rootXY.x, cy:this.rootXY.y})
    }
    label.forward()
  }
  else { // default is rect
    var rect = ashape.rect(itemwidth, itemwidth).move(startx, starty)
    rect.radius(rx, ry)
    rect.attr("fill", color);
    if (strokecolor.length>=1 && !StringOps.equalsIgnoreCase(strokecolor,("none"))) {
      rect.attr("stroke", strokecolor);
      rect.attr("stroke-width", strokewidth + "");
    }

    if (angle != 0) {
      rect.transform({rotation:180-angle, cx:this.rootXY.x, cy:this.rootXY.y})
    }
  }

  return ashape;
} // make a shape

updateTreeCanvasInfoOnServer(key,value) {
  this.layer_tree.forward()
  // console.log(this.promode,this.getDBserialID(),this.sessionid,this.isThisMyTree())
		if (!this.promode != ('SHARING')) {
			if (this.getDBserialID() > 0 && this.isThisMyTree()) { //&& this.sessionid != null 
				axios.post(this.server_add+'query/updateTreeCanvasInfoOnserver',
        {
            treeId:this.getDBserialID(),
            projId:this.getProjId(),
            userId:this.userId,
            // sesId:this.sessionid,
            key:key,
            val:value 
        }).then(stat => {
            if(stat.status == 200){
              if(this.activeTree.canvasParams.hasOwnProperty(key)){
                this.activeTree.canvasParams[key] = value
                localStorage.setItem('curtree',JSON.stringify(this.activeTree))                        
              }
              EventBus.$emit('hide-canvas', {'state':false,'src':'treeSVG', 'type':this.canType})                              
            }
        }).catch(err => {
            console.log(err)
        })
			}
		} else {
			// if (this.getDBserialID() > 0 && this.sessionid != null && !this.sessionid) {
			// 	axios.post(this.server_add+'query/updateTreeCanvasInfoOnserverSharingMode',
      //   {
      //       treeId:this.getDBserialID(),
      //       projId:this.getProjId(),
      //       userId:this.userId,
      //       sesId:this.sessionid,
      //       key:key,
      //       val:value 
      //   }).then(stat => {
      //       if(stat.status == 200){                
      //       }
      //   }).catch(err => {
      //       console.log(err)
      //   })
			// }
		}
  }

  isThisMyTree(){
    // console.log(this.activeTree.userId,this.userId)
    return this.activeTree.userId == this.userId
  }
  
  getDBserialID(){
    return this.activeTree.dbserial
  }

  getProjId(){
    return this.activeTree.otherProps.projectId
  }
}

export default TreeSVG
