
class ItemAttributesBase{
    constructor(){
        this.color = "", 
        this.strokecolor = "", 
        this.style = "rect", 
        this.name = "";
        this.height = 0, 
        this.width = 0, 
        this.strokeWidth = 1;
        this.show = true;
    }

    getColor() {
		return this.color;
	}

	setColor(color) {
		this.color = color;
	}

	getStrokeColor() {
		return this.strokecolor;
	}

	setStrokeColor(strokecolor) {
		this.strokecolor = strokecolor;
	}

	getStyle() {
		return this.style;
	}

	setStyle( shape) {
		this.style = shape;
	}

	getName() {
		return this.name;
	}

	setName( name) {
		this.name = name;
	}

	getHeight() {
		return this.height;
	}

	setHeight(height) {
		this.height = height;
	}

	getWidth() {
		return this.width;
	}

	setWidth(width) {
		this.width = width;
	}

	getStrokeWidth() {
		return this.strokeWidth;
	}

	setStrokeWidth(strokeWidth) {
		this.strokeWidth = strokeWidth;
	}

	isShow() {
		return this.show;
	}

	setShow(show) {
		this.show = show;
	}
}

export default ItemAttributesBase