<template>
    <div style="with:100%;height:100%">
        <iframe src="https://www.evolgenius.info/help/" frameborder="0" style="height:650px" width="100%" onload=""></iframe>
    </div>
</template>

<script>
export default {
    data() {
        return {
            pageheight:'500px',
        }
    },
}
</script>