<template >
    <div>
        <div class="header">
            <b-row v-show="crPageView != 'embed'">
                <!-- <b-col cols="*">
                    <button style="width:100%;height:100%"><font-awesome-icon icon="edit" ></font-awesome-icon></button>
                </b-col> -->
                <b-col>                
                    <b-row>
                        <b-card class ="mw-100" no-body style="width:100%;background-color:white;">
                            <b-tabs  card fill style="margin-top:5px;">
                                <b-tab id="edit" title="EDIT" align="left" style="padding-top:11px;padding-bottom:5px;background-color:#F0EFEF;height:50px;" active>
                                    <span style="margin-top:5px;padding:8px;background-color:white;border-radius: 15px;border: 2px solid white;margin-right:5px" class="md-elevation-1"> 
                                        <md-button class="md-icon-button" @click="editTree" :disabled="editTreeDis" style="width:30px;height:30px" title="edit tree"><font-awesome-icon icon="edit" style="font-size:20px"></font-awesome-icon></md-button>
                                        <md-button class="md-icon-button" :disabled="shareTreeDis" @click="shareTree" style="width:30px;height:30px" title="share tree"><font-awesome-icon icon="share-alt" style="font-size:20px"></font-awesome-icon></md-button>
                                    </span>
                                    <span style="margin-top:5px;padding:8px;background-color:white;border-radius: 15px;border: 2px solid white;margin-right:5px" class="md-elevation-1">                                 
                                    <b-button 
                                        v-for="(btn, idx) in genGrpTreeVStylButtons" 
                                        :key="`3-${idx}`"
                                        :id="btn.name"
                                        class="eicons"
                                        variant="link"
                                        @mouseover="btnMouseOver(btn)"
                                        @mouseout="btnMouseExit(btn)"
                                        :style="btn.disabled ? 'filter: opacity(50%);padding:0px' : 'background-color:'+btn.bg+';padding:0px'"
                                        @click="btnClickedGrp(2,btn,genGrpTreeVStylButtons)"
                                        :title="btn.tooltip"
                                        >
                                        <svg                                     
                                        width="30" height="30">
                                            <use :xlink:href="btn.svg"></use>
                                        </svg>
                                    </b-button>
                                    </span>
                                    <span style="padding:8px;background-color:white;border-radius: 15px;border: 2px solid white;margin-right:5px" class="md-elevation-1">                                 
                                    <b-button 
                                        v-for="(btn, idx) in genGrpThreeButtons" 
                                        :key="`3-${idx}`"
                                        :id="btn.name"
                                        class="eicons"
                                        variant="link"
                                        @mouseover="btnMouseOver(btn)"
                                        @mouseout="btnMouseExit(btn)"
                                        :style="btn.disabled ? 'filter: opacity(50%);padding:0px' : 'background-color:'+btn.bg+';padding:0px'"
                                        @click="btnClickedGrp(3,btn,genGrpThreeButtons)"
                                        :title="btn.tooltip"
                                        >
                                        <svg                                     
                                        width="30" height="30">
                                            <use :xlink:href="btn.svg"></use>
                                        </svg>
                                    </b-button>
                                    </span>
                                    <span style="padding:8px;background-color:white;border-radius: 15px;border: 2px solid white;margin-right:5px" class="md-elevation-1">                                 
                                    <b-button 
                                        v-for="(btn, idx) in ibtnMouseWheelZoominout" 
                                        :key="`00-${idx}`"
                                        :id="btn.name"
                                        class="eicons"
                                        variant="link"
                                        @mouseover="btnMouseOver(btn)"
                                        @mouseout="btnMouseExit(btn)"
                                        :style="btn.disabled ? 'filter: opacity(50%);padding:0px' : 'background-color:'+btn.bg+';padding:0px'"
                                        @click="controlAction(btn)"
                                        :title="btn.tooltip"
                                        >
                                        <svg                                     
                                        width="30" height="30">
                                            <use :xlink:href="btn.svg"></use>
                                        </svg>
                                    </b-button>
                                    </span>
                                    <span style="padding:8px;background-color:white;border-radius: 15px;border: 2px solid white;margin-right:5px" class="md-elevation-1">                             
                                    <b-button 
                                        v-for="(btn, idx) in genGrpCanFitPropButtons" 
                                        :key="`7-${idx}`"
                                        :id="btn.name"
                                        class="eicons"
                                        variant="link"
                                        @mouseover="btnMouseOver(btn)"
                                        @mouseout="btnMouseExit(btn)"
                                        :style="btn.disabled ? 'filter: opacity(50%);padding:0px' : 'background-color:'+btn.bg+';padding:0px'"
                                        @click="controlAction(btn)"
                                        :title="btn.tooltip"
                                        >
                                        <svg                                     
                                        width="30" height="30">
                                            <use :xlink:href="btn.svg"></use>
                                        </svg>
                                    </b-button>
                                    </span>                                
                                    <span style="padding:8px;background-color:white;border-radius: 15px;border: 2px solid white;margin-right:5px" class="md-elevation-1">                                 
                                    <b-button 
                                        v-for="(btn, idx) in genGrpLefPropButtons" 
                                        :key="`6-${idx}`"
                                        :id="btn.name"
                                        class="eicons"
                                        variant="link"
                                        @mouseover="btnMouseOver(btn)"
                                        @mouseout="btnMouseExit(btn)"
                                        :style="btn.disabled ? 'filter: opacity(50%);padding:0px' : 'background-color:'+btn.bg+';padding:0px'"
                                        @click="btnClicked(btn)"
                                        :title="btn.tooltip">
                                        <svg                                     
                                        width="30" height="30">
                                            <use :xlink:href="btn.svg"></use>
                                        </svg>
                                    </b-button>
                                    </span>                                
                                    <span style="padding:8px;background-color:white;border-radius: 15px;border: 2px solid white;margin-right:5px" class="md-elevation-1">                                 
                                    <b-button 
                                        v-for="(btn, idx) in genGrpScalePropButtons" 
                                        :key="`8-${idx}`"
                                        :id="btn.name"
                                        class="eicons"
                                        variant="link"
                                        @mouseover="btnMouseOver(btn)"
                                        @mouseout="btnMouseExit(btn)"
                                        :style="btn.disabled ? 'filter: opacity(50%);padding:0px' : 'background-color:'+btn.bg+';padding:0px'"
                                        @click="controlAction(btn)"
                                        :title="btn.tooltip">
                                        <svg                                     
                                        width="30" height="30">
                                            <use :xlink:href="btn.svg"></use>
                                        </svg>
                                    </b-button>
                                    </span>
                                    <span style="padding:8px;background-color:white;border-radius: 15px;border: 2px solid white;margin-right:5px" class="md-elevation-1">                                 
                                    <b-button 
                                        v-for="(btn, idx) in genGrpFontButtons" 
                                        :key="`9-${idx}`"
                                        :id="btn.name"
                                        class="eicons"
                                        variant="link"
                                        @mouseover="btnMouseOver(btn)"
                                        @mouseout="btnMouseExit(btn)"
                                        :style="btn.disabled ? 'filter: opacity(50%);padding:0px' : 'background-color:'+btn.bg+';padding:0px'"
                                        @click="controlAction(btn)"
                                        :title="btn.tooltip">
                                        <svg                                     
                                        width="30" height="30">
                                            <use :xlink:href="btn.svg"></use>
                                        </svg>
                                    </b-button>
                                    </span>
                                    <span style="padding:8px;background-color:white;border-radius: 15px;border: 2px solid white;margin-right:5px" class="md-elevation-1">                                 
                                    <b-button 
                                        v-for="(btn, idx) in genGrplineButtons" 
                                        :key="`10-${idx}`"
                                        :id="btn.name"
                                        class="eicons"
                                        variant="link"
                                        @mouseover="btnMouseOver(btn)"
                                        @mouseout="btnMouseExit(btn)"
                                        :style="btn.disabled ? 'filter: opacity(50%);padding:0px' : 'background-color:'+btn.bg+';padding:0px'"
                                        @click="controlAction(btn)"
                                        :title="btn.tooltip">
                                        <svg                                     
                                        width="30" height="30">
                                            <use :xlink:href="btn.svg"></use>
                                        </svg>
                                    </b-button>
                                    </span>
                                    <span style="padding:8px;background-color:white;border-radius: 15px;border: 2px solid white;margin-right:5px" class="md-elevation-1">                                 
                                    <b-button 
                                        v-for="(btn, idx) in genGrpHTModesButtons" 
                                        :key="`4-${idx}`"
                                        :id="btn.name"
                                        class="eicons"
                                        variant="link"
                                        @mouseover="btnMouseOver(btn)"
                                        @mouseout="btnMouseExit(btn)"
                                        :style="btn.disabled ? 'filter: opacity(50%);padding:0px' : 'background-color:'+btn.bg+';padding:0px'"
                                        @click="btnClicked(btn)"
                                        :title="btn.tooltip">
                                        <svg                                     
                                        width="30" height="30">
                                            <use :xlink:href="btn.svg"></use>
                                        </svg>
                                    </b-button>
                                    </span>
                                    <span style="padding:8px;background-color:white;border-radius: 15px;border: 2px solid white;margin-right:5px" class="md-elevation-1">                                 
                                    <b-button 
                                        v-for="(btn, idx) in genGrpBranchPropButtons" 
                                        :key="`5-${idx}`"
                                        :id="btn.name"
                                        class="eicons"
                                        variant="link"
                                        @mouseover="btnMouseOver(btn)"
                                        @mouseout="btnMouseExit(btn)"
                                        :style="btn.disabled ? 'filter: opacity(50%);padding:0px' : 'background-color:'+btn.bg+';padding:0px'"
                                        @click="btnClicked(btn)"
                                        :title="btn.tooltip">
                                        <svg                                     
                                        width="30" height="30">
                                            <use :xlink:href="btn.svg"></use>
                                        </svg>
                                    </b-button>
                                    </span>
                                </b-tab>
                                <b-tab id="adv" title="ADVANCED" align="left" style="padding-top:11px;padding-bottom:5px;background-color:#F0EFEF;height:50px" :disabled="advTabDis">
                                    <span style="margin-top:5px;padding:8px;background-color:white;border-radius: 15px;border: 2px solid white;margin-right:5px" class="md-elevation-1">                                 
                                    <b-button 
                                        v-for="(btn, idx) in advancedButtons" 
                                        :key="idx"
                                        :id="btn.name"
                                        class="eicons"
                                        variant="link"
                                        @mouseover="btnMouseOver(btn)"
                                        @mouseout="btnMouseExit(btn)"
                                        :style="btn.disabled ? 'filter: opacity(50%);padding:0px' : 'background-color:'+btn.bg+';padding:0px'"
                                        @click="advCtrlAction(btn)">
                                        <svg                                     
                                        width="30" height="30">
                                            <use :xlink:href="btn.svg"></use>
                                        </svg>
                                    </b-button>
                                    </span>
                                    <!-- <span style="margin-top:5px;padding:8px;background-color:white;border-radius: 15px;border: 2px solid white;margin-right:5px" class="md-elevation-1">                                 
                                    <b-button 
                                        v-for="(btn, idx) in advancedButtonsTwo" 
                                        :key="idx"
                                        :id="btn.name"
                                        class="eicons"
                                        variant="link"
                                        @mouseover="btnMouseOver(btn)"
                                        @mouseout="btnMouseExit(btn)"
                                        :style="btn.disabled ? 'filter: opacity(50%);padding:0px' : 'background-color:'+btn.bg+';padding:0px'"
                                        @click="advCtrlAction(btn)">
                                        <svg                                     
                                        width="30" height="30">
                                            <use :xlink:href="btn.svg"></use>
                                        </svg>
                                    </b-button>
                                    </span>                                 -->
                                    <span style="margin-top:5px;padding:8px;background-color:white;border-radius: 15px;border: 2px solid white;margin-right:5px" class="md-elevation-1"> 
                                        <md-button class="md-icon-button" @click="copyTree" :disabled="copyTreeDis" style="width:30px;height:30px" title="copy tree"><font-awesome-icon icon="copy" style="font-size:20px"></font-awesome-icon></md-button>
                                        <md-button class="md-icon-button" :disabled="delTreeDis" @click="delTree" style="width:30px;height:30px" title="delete tree"><font-awesome-icon icon="trash-alt" style="font-size:20px"></font-awesome-icon></md-button>
                                        <md-button class="md-icon-button" @click="showHideControls" style="width:30px;height:30px" title="Show/Hide controls"><font-awesome-icon icon="window-restore" style="font-size:20px"></font-awesome-icon></md-button>
                                    </span>
                                </b-tab>
                                <b-tab id="import" title="IMPORT" align="left" style="padding-top:11px;padding-bottom:5px;background-color:#F0EFEF;height:50px" :disabled="importTabDis">
                                    <span style="margin-top:5px;padding:8px;background-color:white;border-radius: 15px;border: 2px solid white;margin-right:5px" class="md-elevation-1">                                                                  
                                        <b-button 
                                            v-for="(btn, idx) in genGrpOneButtons" 
                                            :key="idx"
                                            :id="btn.name"
                                            class="eicons"
                                            variant="link"
                                            @mouseover="btnMouseOver(btn)"
                                            @mouseout="btnMouseExit(btn)"
                                            :style="btn.disabled ? 'filter: opacity(50%);padding:0px' : 'background-color:'+btn.bg+';padding:0px'"
                                            @click="controlAction(btn)"
                                            >
                                            <svg                                     
                                            width="30" height="30">
                                                <use :xlink:href="btn.svg"></use>
                                            </svg>
                                        </b-button>
                                    </span>
                                    <span style="margin-top:5px;padding:8px;background-color:white;border-radius: 15px;border: 2px solid white;margin-right:5px" class="md-elevation-1">                              
                                        <b-button 
                                        v-for="(btn, idx) in importButtons" 
                                        :key="`f-${idx}`"
                                        :id="btn.name"
                                        class="eicons"
                                        variant="link"
                                        @mouseover="btnMouseOver(btn)"
                                        @mouseout="btnMouseExit(btn)"
                                        :style="btn.disabled ? 'filter: opacity(50%);padding:0px' : 'background-color:'+btn.bg+';padding:0px'"
                                        @click="importCtrlAction(btn)">
                                        <svg                                     
                                        width="30" height="30">
                                            <use :xlink:href="btn.svg"></use>
                                        </svg>
                                        </b-button>

                                        <b-popover v-for="(btn, idx) in importButtons" 
                                        :key="`p-${idx}`"
                                        :target="btn.name" triggers="hover focus" placement="bottomright">
                                            <template v-slot:title><span v-html="btn.tooltip"></span> </template>
                                            <img v-bind:src="`image/${btn.tooltipUrl}`" style='width:100%'/>
                                        </b-popover>
                                    </span>
                                    <span style="margin-top:5px;padding:8px;background-color:white;border-radius: 15px;border: 2px solid white;margin-right:5px" class="md-elevation-1">                              
                                        <b-button 
                                        v-for="(btn, idx) in importButtonIconTwo" 
                                        :key="`f-${idx}`"
                                        :id="btn.name"
                                        class="eicons"
                                        variant="link"
                                        @mouseover="btnMouseOver(btn)"
                                        @mouseout="btnMouseExit(btn)"
                                        :style="btn.disabled ? 'filter: opacity(50%);padding:0px' : 'background-color:'+btn.bg+';padding:0px'"
                                        @click="importCtrlAction(btn)">
                                        <svg                                     
                                        width="30" height="30">
                                            <use :xlink:href="btn.svg"></use>
                                        </svg>
                                        </b-button>

                                        <b-popover v-for="(btn, idx) in importButtonIconTwo" 
                                        :key="`p-${idx}`"
                                        :target="btn.name" triggers="hover focus" placement="bottomright">
                                            <template v-slot:title><span v-html="btn.tooltip"></span> </template>
                                            <img v-bind:src="`image/${btn.tooltipUrl}`" style='width:100%'/>
                                        </b-popover>
                                    </span>
                                    <span style="margin-top:5px;padding:8px;background-color:white;border-radius: 15px;border: 2px solid white;margin-right:5px" class="md-elevation-1">                              
                                        <b-button 
                                        v-for="(btn, idx) in importButtonIconThree" 
                                        :key="`f-${idx}`"
                                        :id="btn.name"
                                        class="eicons"
                                        variant="link"
                                        @mouseover="btnMouseOver(btn)"
                                        @mouseout="btnMouseExit(btn)"
                                        :style="btn.disabled ? 'filter: opacity(50%);padding:0px' : 'background-color:'+btn.bg+';padding:0px'"
                                        @click="importCtrlAction(btn)">
                                        <svg                                     
                                        width="30" height="30">
                                            <use :xlink:href="btn.svg"></use>
                                        </svg>
                                        </b-button>

                                        <b-popover v-for="(btn, idx) in importButtonIconThree" 
                                        :key="`p-${idx}`"
                                        :target="btn.name" triggers="hover focus" placement="bottomright">
                                            <template v-slot:title><span v-html="btn.tooltip"></span> </template>
                                            <img v-bind:src="`image/${btn.tooltipUrl}`" style='width:100%'/>
                                        </b-popover>
                                    </span>
                                </b-tab>
                                <b-tab id="export" title="EXPORT" align="left" style="padding-top:11px;padding-bottom:5px;background-color:#F0EFEF;height:50px" :disabled="exportTabDis">
                                    <span style="margin-top:5px;padding:8px;background-color:white;border-radius: 15px;border: 2px solid white;margin-right:5px" class="md-elevation-1">                                     
                                        <b-button 
                                        v-for="(btn, idx) in exportButtons" 
                                        :key="idx"
                                        class="eicons"
                                        variant="link"
                                        @mouseover="btnMouseOver(btn)"
                                        @mouseout="btnMouseExit(btn)"
                                        :style="btn.disabled ? 'filter: opacity(50%);padding:0px' : 'background-color:'+btn.bg+';padding:0px'"
                                        @click="exportCtrlAction(btn,$event)">
                                        <svg                                     
                                        width="30" height="30">
                                            <use :xlink:href="btn.svg"></use>
                                        </svg>
                                        </b-button>
                                    </span>
                                    <span style="margin-top:5px;padding:8px;background-color:white;border-radius: 15px;border: 2px solid white;margin-right:5px" class="md-elevation-1">                                     
                                        <b-button 
                                        v-for="(btn, idx) in exportButtonsTwo" 
                                        :key="idx"
                                        class="eicons"
                                        variant="link"
                                        @mouseover="btnMouseOver(btn)"
                                        @mouseout="btnMouseExit(btn)"
                                        :style="btn.disabled ? 'filter: opacity(50%);padding:0px' : 'background-color:'+btn.bg+';padding:0px'"
                                        @click="exportCtrlAction(btn,$event)">
                                        <svg                                     
                                        width="30" height="30">
                                            <use :xlink:href="btn.svg"></use>
                                        </svg>
                                        </b-button>
                                    </span>
                                </b-tab>
                            </b-tabs>
                                                    
                        </b-card>
                    </b-row>
                    <!-- <b-row >
                        <font-awesome-icon icon="window-close" @click="showTBar = !showTBar" style="position: sticky;top: 10;left:0;margin-top:10px" title="Show/Hide toolbar"></font-awesome-icon>
                    </b-row> -->
                </b-col>
            </b-row>
            
            <div v-show="crPageView === 'embed'" style="width:100%;text-align: left;" >
                <span style="padding:8px;background-color:white;border-radius: 15px;border: 2px solid white;margin-right:5px" class="md-elevation-1">                                 
                    <b-button 
                        v-for="(btn, idx) in embedbtnMap" 
                        :key="`00-${idx}`"
                        :id="btn.name"
                        class="eicons"
                        variant="link"
                        @mouseover="btnMouseOver(btn)"
                        @mouseout="btnMouseExit(btn)"
                        :style="btn.disabled ? 'filter: opacity(50%);padding:0px' : 'background-color:'+btn.bg+';padding:0px'"
                        @click="embedActions(btn)">
                        <svg                                     
                        width="24" height="24">
                            <use :xlink:href="btn.svg"></use>
                        </svg>
                    </b-button>
                </span>
            </div>
        </div>        
        <UploadEditWindow         
        :actTree="activeTree"
        :actProject="activeProj"
        :catPanel="name2catpanel"
        v-bind:demoProjects="demoTreeOptions"
        ></UploadEditWindow>
        <CopyShareWindow
        :actTree="activeTree"
        :actProject="activeProj"
        :projTrList="projTreeList"
        :datstList="datasetLis">
        </CopyShareWindow>        
        <LoadingWindow></LoadingWindow>
    </div>
</template>

<script>
import axios from 'axios'
import EventBus from './EventBus'
import ButtonIconMap from '../utils/ButtonsIconMap'
var FileSaver = require('file-saver');
import {saveSvgAsPng} from 'save-svg-as-png'

import UploadEditWindow from './UploadEditWindow'
import CopyShareWindow from './CopyShareWindow'
import LoadingWindow from './LoadingWindow'
import Commons from '../utils/CommonEvol'
import MC from '../treeObjects/MC'
import treePlotMode from '../treeObjects/treePlotMode'
const download = require('downloadjs')

export default {
    props:['actTree','actProject','catPanel','trSVG','promode','crPageView'],
    components:{
        UploadEditWindow,CopyShareWindow,LoadingWindow
    },
    data() {
        return {
            userId: '',
            server_add : this.$hostname,
            activeProj: '',
            activeTree: '',
            selIcnBg:'#ccdcff',
            genGrpOneButtons:[],
            genGrpTreeVStylButtons:[],
            curActiveTreeView:0,
            genGrpThreeButtons:[],
            curActiveTreeEdge:0,
            genGrpHTModesButtons:[],
            genGrpBranchPropButtons:[],
            genGrpLefPropButtons:[],
            genGrpCanFitPropButtons:[],
            genGrpScalePropButtons:[],
            genGrpFontButtons:[],
            genGrplineButtons:[],
            ibtnMouseWheelZoominout:[],
            advancedButtons:[],
            advancedButtonsTwo:[],
            advancedButtonsThree:[],
            exportButtons:[],
            exportButtonsTwo:[],
            exportButtonsThree:[],
            importButtons:[],
            importButtonIconTwo:[],
            importButtonIconThree:[],
            embedbtnMap:[],
            curPageView:null,
            treeTabDis:null,
            genTabDis:null,
            advTabDis:null,
            importTabDis:null,
            exportTabDis:null,

            projNameTF:'',
            projList:[],
            treeNameTF:'',
            treeList:[],

            editTreeDis:false,
            delTreeDis:false,
            copyTreeDis:false,
            shareTreeDis:false,

            //share modal vars
            shareOptions:['Share','embed'],//,'Collaborate'],
            shareSel:'Share',
            shareProjActive:false,
            projTreeList:[],
            smCurDataset:[],
            smCurTree:[],
            datasetLis:[],

            smModelType:'',
            shareProject:null,

            //upload/edit modal vars
            isModalVisible:false,
            modelType:'',
            uploadDatasetType:'',
            modContentStr:'',
            rawTxtAr:'',
            treeUpload:false,
            showFileTyp:false,
            showFileUpload:false,
            cancelUsed:false,
            showUploadEditWin:false,
            demoTreeOptions:[],

            //data from canvas
            name2catpanel:[],
            promoMode:'LIVE',
            treeSVG:null,
            maxWin:{
                width:500,
                height:400
            },
            window: {
                width: 600,
                height: 300
            },
            showTBar:true,
            showPan:true,
            activeTab:'edit',
        }
    },
    created() {
        let that = this               
        EventBus.$on('control-closed', showPan => {
            this.showPan = showPan
        })
        EventBus.$on('disable-all', state => {
            that.disableAllBtns()
        }) 
    },
    computed:{
        setBg(){
            return {
                    'background-color':'#ccdcff',
                    'padding':'0px',
            }          
        },
        noBg(){
            return {
                    'background-color':'',
                    'padding':'0px',
            }
        },      
    },
    mounted() {
        if(localStorage.getItem("usertoken") != null){
            const token = localStorage.getItem("usertoken") 
            const decoded = JSON.parse(token)//jwtDecode
            this.userId = decoded.userId
        }
        var importButtonIcon = new ButtonIconMap()            
        this.butIconMap = importButtonIcon.getIconMap()

        let temDatsetList = this.butIconMap['dataset']
        this.importButtons = [temDatsetList['ibtnDataUploadLabelColor'],temDatsetList['ibtnDataUploadLeafLabelBKColor'],temDatsetList['ibtnDataUploadLeafLabelDecoration'],temDatsetList['ibtnDataUploadGroupLabel'],temDatsetList['ibtnDataUploadLeafGraphics'],temDatsetList['ibtnDataUploadBranchAnnotate']]
        this.importButtonIconTwo = [temDatsetList['ibtnDataUploadBranchColor'],temDatsetList['ibtnDataUploadBranchLabel'],temDatsetList['ibtnDataUploadBranchMarker'],temDatsetList['ibtnDataUploadBootstrapStyles'],temDatsetList['ibtnDataUploadCollapseInternally'],temDatsetList['ibtnDataUploadPiechart'],temDatsetList['ibtnDataUploadTimeLine']]
        this.importButtonIconThree = [temDatsetList['ibtnDataUploadBarPlots'],temDatsetList['ibtnDataUploadHeatmap'],temDatsetList['ibtnDataUploadProteinDomains'],temDatsetList['ibtnDataUploadDotPlots'],temDatsetList['ibtnDataUploadNamedColumnPlot'],temDatsetList['ibtnDataUploadColorStrips'],temDatsetList['ibtnDataUploadGeneSyn'],temDatsetList['ibtnDataUploadGeneTransfer']]

        let temExpoList = this.butIconMap['export']
        this.exportButtons = [this.butIconMap['quickSaveSVG'],this.butIconMap['quickSavePNG'],temExpoList['ibtnExport2JPEG'],temExpoList['ibtnExport2TIFF'],temExpoList['pdfSave']]
        this.exportButtonsTwo = [temExpoList['ibtnExport2Nexus'],temExpoList['ibtnExport2Nhx'],temExpoList['ibtnExport2PhyloXML'],temExpoList['newickSave']]

        let temAdvList = this.butIconMap['adv']
        this.advancedButtons = [temAdvList['ibtnCicularCounterClockwise'],temAdvList['ibtnCicularClockwise'],temAdvList['ibtnCicularRotateCounterClockwise'],temAdvList['ibtnCicularRotateClockwise'],temAdvList['ibtnCicularSpanDecrease'],temAdvList['ibtnCicularSpanIncrease']]
        this.advancedButtonsTwo = [temAdvList['ibtnMoveLegend'],temAdvList['ibtnMoveTree']]
        this.advancedButtonsThree = [temAdvList['ibtnLegZoomIn'],temAdvList['ibtnLegZoomOut']]

        this.genGrpOneButtons = [this.butIconMap['file']] //this.butIconMap['quickSaveSVG'],this.butIconMap['quickSavePNG']
        this.genGrpTreeVStylButtons = this.butIconMap['treeViewStyle']
        this.genGrpThreeButtons = this.butIconMap['treeEdgeStyle']
        this.genGrpHTModesButtons = [this.butIconMap['htmlMode'],this.butIconMap['megaMode'],this.butIconMap['ibtnBootstrapShowHide'],this.butIconMap['ibtnBootstrapFontItalics']]
        this.genGrpBranchPropButtons = [this.butIconMap['ibtnBranchLenShowHide'],this.butIconMap['ibtnBranchLenFontItalics']]
        this.genGrpLefPropButtons = [this.butIconMap['leafLabelShowHide'],this.butIconMap['leafLabelAlign'],this.butIconMap['line2Leaflabel']]
        this.genGrpCanFitPropButtons = [this.butIconMap['fitCanvas2plotWidth'],this.butIconMap['fitCanvas2plotHeight'],this.butIconMap['fitCanvas2plot'],this.butIconMap['whereIsMyTree']]
        this.genGrpScalePropButtons = [this.butIconMap['horizScaleIncrease'],this.butIconMap['horizScaleDecrease'],this.butIconMap['verticScaleIncrease'],this.butIconMap['verticScaleDecrease']]
        this.genGrpFontButtons = [this.butIconMap['fontItalic'],this.butIconMap['fontSizeIncrease'],this.butIconMap['fontSizeDecrease']]
        this.genGrplineButtons = [this.butIconMap['linewidthIncrease'],this.butIconMap['linewidthDecrease']]
        
        this.ibtnMouseWheelZoominout = [this.butIconMap['ibtnMouseZoomin'],this.butIconMap['ibtnMouseZoomout'],this.butIconMap['ibtnMouseZoomrestore']] //this.butIconMap['ibtnMouseWheelZoominout']        
        this.embedbtnMap = [this.genGrpTreeVStylButtons['ibtnRectClado'],this.genGrpTreeVStylButtons['ibtnRectPhylo'],this.genGrpTreeVStylButtons['ibtnSlantedClado'],this.genGrpTreeVStylButtons['ibtnCircularClado'],this.genGrpTreeVStylButtons['ibtnCircularPhylo'],this.butIconMap['fitCanvas2plotWidth'],this.butIconMap['fitCanvas2plotHeight'],this.butIconMap['fitCanvas2plot'],this.butIconMap['whereIsMyTree'],this.butIconMap['ibtnMouseZoomin'],this.butIconMap['ibtnMouseZoomout'],this.butIconMap['ibtnMouseZoomrestore'],this.butIconMap['quickSaveSVG'],this.butIconMap['quickSavePNG']]
        this.activeTab = 'edit'
        this.enableDrag()
        this.loadDemoProjects() //loaded for upload modal window
    },
    watch:{
        upFile(file){
            var reader = new FileReader();
            reader.readAsText(file);
            reader.onload = (event) => {
                this.upFileContStr = event.target.result;
            }
        },
        actTree(val){
            this.activeTree = val
        },
        actProject(val){
            this.activeProject = val
        },
        catPanel(val){
            this.name2catpanel = val
            this.enableTabs()
        },
        trSVG(val){
            if(val != null && val != undefined && val.pyTree != undefined){
                this.treeSVG = val
                // console.log(this.curPageView,this.crPageView)
                this.setToolbarButtonStatus(this.treeSVG)
            }else{
                this.setToolbarButtonStatus(null)
            }
        },
        promode(val){
            this.promoMode = val
        },
        crPageView(val){
            this.curPageView = val
            // console.log('page type',this.curPageView,this.activeTree)
        },
        "$store.state.projList": {
            handler: function(nv) {
                this.projList = nv
            },
            immediate: true // provides initial (not changed yet) state
        }
    },
    methods: {        
        updateTab(t) {
            this.activeTab = t;
        },
        btnMouseOver(btn){
            // console.log(btn,!btn.state && !btn.disabled)
            if(!btn.state && !btn.disabled){
                // btn.src = btn.iconActive
                btn['mouseover'] = true
                btn['mouseexit'] = false
                btn.bg = this.selIcnBg
            }
            // console.log('mo',btn)
        },
        btnMouseExit(btn){
            if(!btn.state && !btn.disabled){
                // btn.src = btn.iconNormal
                btn['mouseover'] = false
                btn['mouseexit'] = true
                btn.bg = ''
            }
            // console.log('mx',btn)
        },
        showHideControls(){
            this.showPan = !this.showPan
            EventBus.$emit('control-open', this.showPan)
        },
        controlAction(btn){
                if(btn.name === 'ibtnFileOpen'){
                    this.showModal(1,'','')
                }else if(btn.name === 'ibtnExport2PNG'){
                    saveSvgAsPng(document.getElementById("treeCanvas"), this.activeTree.treeName+"_tree.png");
                }else if(btn.name === 'ibtnExport2SVGurl'){
                    var toAdd = '<?xml version="1.0" encoding="UTF-8" standalone="no"?>'
                    var svgString = this.treeSVG.svgString()
                    this.saveToFile(toAdd+svgString,this.activeTree.treeName+'_tree.svg',true)
                }else if(btn.name === 'ibtnBranchLenShowHide'){
                    this.treeSVG.setShowTreeBranchLengths(btn.state)
                    this.enableBtn(1,this.genGrpBranchPropButtons,!btn.state)
                }else if(btn.name === 'ibtnLeafLabelShowHide'){
                    this.treeSVG.setShowTreeLeafLabels(btn.state)
                    this.enableBtn(4,this.genGrpScalePropButtons,!btn.state)
                    this.enableBtn(5,this.genGrpScalePropButtons,!btn.state)
                    this.enableBtn(6,this.genGrpScalePropButtons,!btn.state)
                }else if(btn.name === 'ibtnBranchLenFontItalics'){
                    this.treeSVG.setBranchLengthFontItalic(btn.state)
                }else if(btn.name === 'ibtnFontItalic'){
                    btn.state = !btn.state
                    this.treeSVG.setLeafFontItalic(btn.state)
                }else if(btn.name === 'ibtnFontSizeIncrease'){
                    this.treeSVG.setLeafLabelFontSize(this.treeSVG.getFontSize()+1)
                }else if(btn.name === 'fontSizeDecrease'){
                    this.treeSVG.setLeafLabelFontSize(this.treeSVG.getFontSize()-1)
                }else if(btn.name === 'ibtnLinewidthIncrease'){
                    this.treeSVG.increaseTreeBranchLineWidth()
                }else if(btn.name === 'ibtnLinewidthDecrease'){
                    this.treeSVG.decreaseTreeBranchLineWidth()
                }else if(btn.name === 'ibtnHorizScaleIncrease'){
                    var pxperwidth = this.treeSVG.getPxPerWidth();
                    pxperwidth *= (1 + 0.05);
                    var modifiedPxPerWidth = this.treeSVG.setPxPerWidth(pxperwidth);
                }else if(btn.name === 'ibtnFitCanvas2plotWidth'){
                    this.treeSVG.fitCanvas2PlotWidth()
                    this.setPanelSize()
                }else if(btn.name === 'ibtnFitCanvas2plotHeight'){
                    this.treeSVG.fitCanvas2PlotHeight()
                    this.setPanelSize()
                }else if(btn.name === 'ibtnFitCanvas2plot'){
                    this.treeSVG.fitCanvas2Plot()
                    this.setPanelSize()
                }else if(btn.name === 'ibtnWhereIsMyTree'){
                    this.treeSVG.whereIsMyTree()
                }else if(btn.name === 'ibtnHorizScaleDecrease'){
                    var pxperwidth = this.treeSVG.getPxPerWidth();
                    pxperwidth *= (1 - 0.05);
                    var modifiedPxPerWidth = this.treeSVG.setPxPerWidth(pxperwidth);
                }else if(btn.name === 'ibtnVerticScaleIncrease'){
                    var pxperheight = this.treeSVG.getPxPerHeight();
                    pxperheight *= (1 + 0.05);
                    var modifiedPxPerWidth = this.treeSVG.setPxPerHeight(pxperheight);
                }else if(btn.name === 'ibtnVerticScaleDecrease'){
                    var pxperheight = this.treeSVG.getPxPerHeight();
                    pxperheight *= (1 - 0.05);
                    var modifiedPxPerWidth = this.treeSVG.setPxPerHeight(pxperheight);
                }else if(btn.name === 'ibtnMouseWheelZoominout'){
                    btn.state = !btn.state
                    if(btn.state){
                        btn.src = btn.iconActive
                        btn.bg = this.selIcnBg
                    }else if(!btn.disabled){
                        btn.src = btn.iconNormal
                        btn.bg = ''
                    }
                    // this.treeSVG.setOneOffZoomWheel(btn.state)
                }else if(btn.name === 'ibtnMouseZoomin'){                
                    this.treeSVG.zoom('in')
                }else if(btn.name === 'ibtnMouseZoomout'){                
                    this.treeSVG.zoom('out') 
                }else if(btn.name === 'ibtnMouseZoomrestore'){                
                    this.treeSVG.zoom('restore')
                }else if(btn.name === 'ibtnLeafLabelAlign'){                
                    this.treeSVG.setAlignLeafLabels(btn.state);
                }else if(btn.name === 'ibtnLine2Leaflabel'){
                    this.treeSVG.setShowLine2Leaflabel(btn.state)
                }else if (btn.name === 'ibtnBootstrapShowHide'){
                    if(btn.state){
                        this.enableBtn(3,this.genGrpHTModesButtons,false)
                    }else{
                        this.enableBtn(3,this.genGrpHTModesButtons,true)
                    }
                    this.treeSVG.setShowBootstrapScores(btn.state)                
                }else if(btn.name === 'ibtnHtmlMode'){
                    this.treeSVG.replotInHTMLmode(btn.state)
                }
                else{
                    console.log(btn.name)
                }
        },
        embedActions(btn){
            if(btn.name === 'ibtnExport2PNG'){
                saveSvgAsPng(document.getElementById("treeCanvas"), this.activeTree.treeName+"_tree.png");
            }else if(btn.name === 'ibtnExport2SVGurl'){
                var toAdd = '<?xml version="1.0" encoding="UTF-8" standalone="no"?>'
                var svgString = this.treeSVG.svgString()
                this.saveToFile(toAdd+svgString,this.activeTree.treeName+'_tree.svg',true)
            }else if(btn.name === 'ibtnFitCanvas2plotWidth'){
                this.treeSVG.fitCanvas2PlotWidth()
                this.setPanelSize()
            }else if(btn.name === 'ibtnFitCanvas2plotHeight'){
                this.treeSVG.fitCanvas2PlotHeight()
                this.setPanelSize()
            }else if(btn.name === 'ibtnFitCanvas2plot'){
                this.treeSVG.fitCanvas2Plot()
                this.setPanelSize()
            }else if(btn.name === 'ibtnMouseZoomin'){                
                this.treeSVG.zoom('in')
            }else if(btn.name === 'ibtnMouseZoomout'){                
                this.treeSVG.zoom('out') 
            }else if(btn.name === 'ibtnMouseZoomrestore'){                
                this.treeSVG.zoom('restore')
            }else if(btn.name === 'ibtnRectClado' || btn.name === 'ibtnRectPhylo' || btn.name === 'ibtnSlantedClado' || btn.name === 'ibtnCircularClado' || btn.name === 'ibtnCircularPhylo'){
                btn.state = !btn.state
                if(btn.state){
                    btn.src = btn.iconActive
                    btn.bg = this.selIcnBg
                }else if(!btn.disabled){                    
                    btn.src = btn.iconNormal
                    btn.bg = ''
                }
                this.resetBtnGroup(btn,this.embedbtnMap)
                this.switchTreeMode(btn)
            }else{
                console.log('unknown action recorded ',btn.name)
            }
        },
        setPanelSize(){
            this.window.width = this.maxWin.width
            this.window.height = this.maxWin.height
        },
        enableBtn(btnInd,btnGrp,bool){
                var btn = btnGrp[btnInd]
                if(btn != undefined && btn != null){
                    btn.disabled = bool
                    if(btn.state && !bool){
                        btn.bg = this.selIcnBg
                    }else{
                        btn.bg = ''
                    }
                    // btn['mouseover'] = bool
                    // btn['mouseexit'] = !bool
                }
        },
        btnClicked(btn){
            if(!btn.disabled){
                btn.state = !btn.state
                if(btn.state){
                    btn.src = btn.iconActive
                    btn.bg = this.selIcnBg
                }else{
                    btn.src = btn.iconNormal
                    btn.bg = ''
                }
                //add action here
                this.controlAction(btn)
            }
        },
        btnClickedGrp(grpNum,btn,array){
            if(!btn.disabled){
                btn.state = !btn.state
                if(btn.state){
                    btn.src = btn.iconActive
                    btn.bg = this.selIcnBg
                }else if(!btn.disabled){                    
                    btn.src = btn.iconNormal
                    btn.bg = ''
                }
                this.resetBtnGroup(btn,array)
                //add action here
                switch(grpNum){
                    case 2:this.switchTreeMode(btn)
                    break;
                    case 3: 
                    if(btn.state){
                        this.switchTreeEdge(btn.actionId)
                    }
                    break;
                }
            }
        },
        enableDisableBtnGrp(btnGrp,bool){
            for(let key in btnGrp){
                var btn = btnGrp[key]
                btn.disabled = bool                
                btn.state = false
                btn.bg = ''
            }
        },
        setSelectedBtn(btnInd,btnGrp,bool){
            var btn = btnGrp[btnInd]            
            if(btn != null && btn != undefined){
                btn['mouseover'] = bool
                btn['mouseexit'] = !bool            
                btn.state = bool
                if(bool){
                    btn.bg = this.selIcnBg
                }else{
                    btn.bg = ''
                }
            }else{
                // console.log('error with given btn input',btnInd,btnGrp)
            }
        },
        resetBtnGroup(btn,array){
            var activeCounter = 0
            for(let ind in array){
                var ar = array[ind]
                if(btn.name != ar.name){
                    ar.state = false
                    if(!btn.disabled){
                        ar.src = ar.iconNormal
                        ar.bg = ''
                    }
                }
                if(btn.state){
                    activeCounter++
                }
            }
            if(activeCounter<=0){
                if(btn.name != 'ibtnTreeCurveStyle1' && btn.name != 'ibtnTreeCurveStyle2'){
                    btn.state = true
                    btn.src = btn.iconActive
                    btn.bg = this.selIcnBg
                }else{
                    this.switchTreeEdge(0)
                }
            }
        },
        advCtrlAction(btn){
            if(btn.name === 'ibtnCicularCounterClockwise'){
                this.treeSVG.setCircularModeClockwise(true)
            }else if(btn.name === 'ibtnCicularClockwise'){
                this.treeSVG.setCircularModeClockwise(false)
            }else if(btn.name === 'ibtnCicularRotateCounterClockwise'){                
                this.treeSVG.rotateCicularTree(-20);
            }else if(btn.name === 'ibtnCicularRotateClockwise'){
                this.treeSVG.rotateCicularTree(20);
            }else if(btn.name === 'ibtnCicularSpanDecrease'){
                this.treeSVG.setCicularAngleSpan(Math.round(this.treeSVG.getCicularAngleSpan()) - 20);
            }else if(btn.name === 'ibtnCicularSpanIncrease'){
                this.treeSVG.setCicularAngleSpan(Math.round(this.treeSVG.getCicularAngleSpan()) + 20);
            }else if(btn.name === 'ibtnMoveLegend'){
                btn.state = !btn.state
                if(btn.state){
                    btn.src = btn.iconActive
                    btn.bg = this.selIcnBg
                }else if(!btn.disabled){
                    btn.src = btn.iconNormal
                    btn.bg = ''
                }
                this.treeSVG.setMoveLegend(btn.state);
                this.resetDragCtrls('ibtnMoveTree')                  
            }else if(btn.name === 'ibtnMoveTree'){
                btn.state = !btn.state
                if(btn.state){
                    btn.src = btn.iconActive
                    btn.bg = this.selIcnBg
                }else if(!btn.disabled){
                    btn.src = btn.iconNormal
                    btn.bg = ''
                }
                this.treeSVG.setMoveTree(btn.state);
                this.resetDragCtrls('ibtnMoveLegend')
            }else if(btn.name === 'ibtnLegZoomIn'){
                this.treeSVG.zoomLegends('in')
            }else if(btn.name === 'ibtnLegZoomOut'){
                this.treeSVG.zoomLegends('out')
            }
        },
        resetDragCtrls(key){
            this.advancedButtons[key].state = false
            this.advancedButtons[key].src = this.advancedButtons[key].iconNormal
            if(key === 'ibtnMoveLegend'){
                this.treeSVG.setMoveLegend(false);
            }else{
                this.treeSVG.setMoveTree(false);
            }
        },
        importCtrlAction(btn){
            btn.src = btn.iconNormal
            if(btn.name === 'ibtnDataUploadPiechart'){
                this.uploadDatasetType = MC.PIES
            }else if(btn.name === 'ibtnDataUploadBranchColor'){
                this.uploadDatasetType = MC.BRANCHECOLORS
            }else if(btn.name === 'ibtnDataUploadBranchLabel'){
                this.uploadDatasetType = MC.BRANCHLABELS
            }else if(btn.name === 'ibtnDataUploadLabelColor'){
                this.uploadDatasetType = MC.LEAFLABELCOLORS                
            }else if(btn.name === 'ibtnDataUploadLeafLabelBKColor'){
                this.uploadDatasetType = MC.LEAFBKCOLORS
            }else if(btn.name === 'ibtnDataUploadLeafLabelDecoration'){
                this.uploadDatasetType = MC.LEAFLABELDECO
            }else if(btn.name === 'ibtnDataUploadTimeLine'){
                this.uploadDatasetType = MC.TIMELINE
            }else if(btn.name === 'ibtnDataUploadGroupLabel'){
                this.uploadDatasetType = MC.GROUPLABELS                
            }else if(btn.name === 'ibtnDataUploadColorStrips'){
                this.uploadDatasetType = MC.STRIPS
            }else if(btn.name === 'ibtnDataUploadBarPlots'){
                this.uploadDatasetType = MC.BARS
            }else if(btn.name === 'ibtnDataUploadDotPlots'){
                this.uploadDatasetType = MC.DOTPLOTS
            }else if(btn.name === 'ibtnDataUploadHeatmap'){
                this.uploadDatasetType = MC.HEATMAP                
            }else if(btn.name === 'ibtnDataUploadProteinDomains'){
                this.uploadDatasetType = MC.PROTEINDOMAINS
            }else if(btn.name === 'ibtnDataUploadBootstrapStyles'){
                this.uploadDatasetType = MC.BOOTSTRAPSTYLES
            }else if(btn.name === 'ibtnDataUploadCollapseInternally'){
                this.uploadDatasetType = MC.INTERNALCOLLAPSES
            }else if(btn.name === 'ibtnDataUploadNamedColumnPlot'){
                this.uploadDatasetType = MC.NAMEDCOLUMN
            }else if(btn.name === 'ibtnDataUploadBranchAnnotate'){
                this.uploadDatasetType = MC.LEAFANNOTATE
            }else if(btn.name === 'ibtnDataUploadLeafGraphics'){
                this.uploadDatasetType = MC.LEAFIMAGE
            }else if(btn.name === 'ibtnDataUploadGeneSyn'){
                this.uploadDatasetType = MC.GENESYTNEY
            }else if(btn.name === 'ibtnDataUploadGeneTransfer'){
                this.uploadDatasetType = MC.GENETRANSFER
            }else if(btn.name === 'ibtnDataUploadBranchMarker'){
                this.uploadDatasetType = MC.BRANCHMARKER
            }
            this.showModal(3,'','')
        },
        exportCtrlAction(btn,event){
            // console.log(event)
            // event.preventDefault()
            // var toAdd = '<?xml version="1.0" encoding="UTF-8" standalone="no"?>'
            var svgString = this.treeSVG.svgString()
            if(btn.name === 'ibtnExport2PNG'){
                saveSvgAsPng(document.getElementById("treeCanvas"), this.activeTree.treeName+"_tree.png");
            }else if(btn.name === 'ibtnExport2SVGurl'){
                var toAdd = '<?xml version="1.0" encoding="UTF-8" standalone="no"?>'
                var svgString = this.treeSVG.svgString()
                this.saveToFile(toAdd+svgString,this.activeTree.treeName+'_tree.svg',true)
            }else if(btn.name === 'ibtnSave2PDF2'){
                EventBus.$emit('loadMess', "Wait,generating PDF...")
                let width = this.treeSVG.getWinHeight()
                let height = this.treeSVG.getWinWidth()
                var x = 0,y = 0
                let name = this.activeTree.treeName.replace(/ /g,"_")+'_tree.pdf'
                axios.post(this.server_add+'query/svgtopdf',
                {
                    width:width,
                    height:height,
                    fName:name,
                    svg:svgString
                },
                {responseType: 'arraybuffer'}).then(res => {
                    if(res.status == 200){                        
                        let blob = new Blob([res.data], { type: 'application/pdf' }) //It is optional                    
                        download(res.data,name,"application/pdf")
                        EventBus.$emit('closeLoadWin', true)
                        this.$modal.hide("loadingWin")
                    }else{
                        Commons.showErrorMessage('server connection error '+res)
                    }  
                }).catch(err => {
                        Commons.showErrorMessage('server connection error '+err)
                })                
            }else if(btn.name === 'ibtnExport2JPEG'){
                saveSvgAsPng(document.getElementById("treeCanvas"), this.activeTree.treeName+"_tree.jpg",{backgroundColor:'white'});
            }else if(btn.name === 'ibtnExport2TIFF'){

            }else if(btn.name === 'ibtnExport2Nexus'){
                // EventBus.$emit('loadMess', "Wait,generating nexus file.")
                var treestr = this.treeSVG.getPhyloTree().toTreeString("nexus", true, true, false);
                this.saveToFile(treestr,this.activeTree.treeName+'_nexus.txt',false)
            }else if(btn.name === 'ibtnExport2Nhx'){
                // EventBus.$emit('loadMess', "Wait,generating nhx file...")
                var treestr = this.treeSVG.getPhyloTree().toTreeString("nhx", true, true, false);
                this.saveToFile(treestr,this.activeTree.treeName+'_nhx.txt',false)
            }else if(btn.name === 'ibtnExport2PhyloXML'){
                // EventBus.$emit('loadMess', "Wait,generating xml file...")
                var treestr = this.treeSVG.getPhyloTree().toXMLString();
                this.saveToFile(treestr,this.activeTree.treeName+'_phyloxml.txt',false)
            }else if(btn.name === 'ibtnExport2Newick'){
                // EventBus.$emit('loadMess', "Wait,generating newick file...")
                var treestr = this.treeSVG.getPhyloTree().toTreeString("newick", true, true, false);
                this.saveToFile(treestr,this.activeTree.treeName+'_newick.txt',false)
            }
            else{
                console.log(btn.name)
            }
        },
        // showLoadingMod(props){
        //     EventBus.$emit('loadModProps', props)
        // },
        saveToFile(content, fname, bool){
            var imgType = "image/svg+xml;charset=utf-8"
            var fType = "text/plain;charset=utf-8"
            if(bool){
                fType = imgType
            }
            var blob = new Blob([content], {type:fType});
            FileSaver.saveAs(blob, fname);            
        },        
        showModal(typ,contentStr,dataSet) {
            this.modelType = typ
            this.modContentStr = contentStr
            if(typ === 1){ //file upload
                this.treeUpload = true
                this.showFileTyp = true
                this.showFileUpload = true
            }else if(typ === 2){ //file edit
                this.treeUpload = false
                this.showFileTyp = true
                this.showFileUpload = true
                this.rawTxtAr = contentStr
                EventBus.$emit('edit-tree', this.rawTxtAr)
            }else if(typ === 3){ //dataset upload
                this.treeUpload = false
                this.showFileTyp = false
                this.showFileUpload = true
                EventBus.$emit('dataset-type',this.uploadDatasetType)
            }
            this.isModalVisible = true;
            this.cancelUsed = false

            let propArray = [this.modelType,this.modContentStr,this.treeUpload,this.showFileTyp,this.showFileUpload,this.isModalVisible,this.cancelUsed,this.userId]
            EventBus.$emit('modal-props', propArray)

            this.$modal.show('modalScoped');            
        },        
        loadDemoProjects(){
            axios.post(this.server_add+'query/listProTree',
          {
            projectId: 2,
            userId:1
          }).then(tree => {
              if(tree.status == 200 && tree.data.length >= 1){
                  for(var ind in tree.data){
                      var tr = tree.data[ind]
                      tr['text'] = tr.treeName
                      tr['value'] = tr
                      this.demoTreeOptions.push(tr)
                  }
              }
          }).catch(err => {
            console.log(err)
          })
        },

        showModalSm(type){
            if(type === 1){ //copy tree
                this.projTreeList = []                
                this.datasetLis = []
                for(var ind in this.name2catpanel){
                    var te = this.name2catpanel[ind].getChildPans()
                    var temLis = []
                    for(var cat in te){
                        var cpan = te[cat]
                        temLis.push({value:cpan.getDBSerial(),text:cpan.name,'type':ind})
                    }
                    if(temLis.length>=1){
                        this.datasetLis.push({label:ind,options:temLis})
                    }
                }
                // this.$store.commit('datsetListChange', this.datasetLis)
                axios.post(this.server_add+'query/getProjectTrees',
                {
                    userId:this.userId
                }).then(tree => {
                    if(tree.status == 200){
                        var projMap = tree.data
                        for(var ind in projMap){
                            var proj = projMap[ind]
                            if(proj.length >=1){
                                var projName = proj[0].projectName
                                var tlis = []
                                for(var pos in proj){
                                    var tre = proj[pos]
                                    tlis.push({value:tre.treeID,text:tre.treeName,'userId':proj[0].UserID,'projectName':projName,'projId':ind})
                                }
                                if(tlis.length>=1){
                                    this.projTreeList.push({label:projName,options:tlis})
                                }
                            }
                        }
                        // this.$store.commit('projTreeListChange', this.projTreeList)
                        this.$modal.show('SmallModal');
                    }
                }).catch(err => {
                    console.log(err)
                })                
            }else if(this.smModelType == 2){ //share tree type
                //do nothing
                this.$modal.show('SmallModal');
            }
        },        
        enableTabs(){
            if(this.promoMode === 'LIVE'){
                this.treeTabDis = false
                this.genTabDis = false
                this.advTabDis = false
                this.importTabDis = false
                this.exportTabDis = false
            }else{
                this.treeTabDis = true
                this.genTabDis = false
                this.advTabDis = false
                this.importTabDis = true
                this.exportTabDis = false
            }
        },
        loadTreesOftheProj(proj) {
          axios.post(this.server_add+'query/listProTree',
          {
            projectId: proj.id,
            userId:proj.userId
          }).then(tree => {
              if(tree.status == 200){
                  for(var ind in tree.data){
                      var tr = tree.data[ind]
                      this.treeList.push(tr.treeName)
                  }
              }
          }).catch(err => {
            console.log(err)
          })
        },
        editTree(){
            EventBus.$emit('setModalFormatSel', this.activeTree.treeFormat)
            this.showModal(2,this.activeTree.treeContentString,'')
        },
        copyTree(){
            this.smModelType = 1
            EventBus.$emit('smModelType', this.smModelType)
            this.showModalSm(1)
        },
        shareTree(){
            this.smModelType = 2
            EventBus.$emit('smModelType', this.smModelType)
            this.showModalSm(2)
        },
        delTree(){
            if(Commons.isThisMyTree(this.activeTree.userId,this.userId)){
                this.$bvModal.msgBoxConfirm('Please confirm that you want to delete everything.', {
                title: 'Please Confirm',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
                })
                .then(value => {
                    if(value){
                        axios.post(this.server_add+'query/delTree',
                        {
                            treeId: this.activeTree.dbserial                            
                            }).then(res => {
                            if(res.status == 200){
                                if(res.data.hasOwnProperty('error')){
                                    this.log_check_status = res.data.error
                                }else{
                                    for(var ind in this.projList){
                                        let pro = this.projList[ind]
                                        if(pro.id === this.activeProj.id){
                                            for(var tid in pro.siblings){
                                                let tr = pro.siblings[tid]
                                                if(tr.ID != this.activeTree.dbserial){
                                                    EventBus.$emit('new-tree', {'proj':pro.id,'tree':tr.ID})
                                                    break
                                                }
                                            }
                                            break
                                        }
                                    }                                    
                                }
                            }else{
                                Commons.showErrorMessage('server connection error '+res)
                            }  
                        }).catch(err => {
                                Commons.showErrorMessage('server connection error '+err)
                        })
                    }
                })
                .catch(err => {
                    Commons.showErrorMessage('server connection error '+err)
                })                
            }else{
                Commons.showErrorMessage('You cant make changes to this tree!!')
            }
        },
        enableDrag(){
            for(var key in this.advancedButtons){
                var tem = this.advancedButtons[key]
                if(tem.name == 'ibtnMoveLegend' || tem.name == 'ibtnMoveTree'){
                    tem.disabled = false
                    tem.src = tem.iconNormal
                }
                if(this.treeSVG != null){
                    // console.log(this.treeSVG.getLegDragStatus(),this.treeSVG.getTreeDragStatus())
                    if(tem.name == 'ibtnMoveTree'){
                        tem.state = this.treeSVG.getTreeDragStatus()
                        if(tem.state){
                            tem.src = tem.iconActive
                            tem.bg = this.selIcnBg
                        }
                    }
                    if(tem.name == 'ibtnMoveLegend'){
                        tem.state = this.treeSVG.getLegDragStatus()
                        if(tem.state){
                            tem.src = tem.iconActive
                            tem.bg = this.selIcnBg
                        }
                    }                    
                }
            }
        },
        //tree actions
        switchTreeMode(btn){
            var mode = treePlotMode.RECT_CLADOGRAM
            switch(parseInt(btn.actionId)){
                case 1:
                    mode = treePlotMode.RECT_CLADOGRAM
                    this.enableDisableBtnGrp(this.advancedButtons,true)
                break;
                case 2:
                    mode = treePlotMode.RECT_PHYLOGRAM
                    this.enableDisableBtnGrp(this.advancedButtons,true)
                break;
                case 3:
                    mode = treePlotMode.SLANTED_CLADOGRAM_NORMAL
                    this.enableDisableBtnGrp(this.advancedButtons,true)
                break;
                case 4:
                    mode = treePlotMode.CIRCULAR_CLADOGRAM
                    this.enableDisableBtnGrp(this.advancedButtons,false)
                break;
                case 5:
                    mode = treePlotMode.CIRCULAR_PHYLOGRAM
                    this.enableDisableBtnGrp(this.advancedButtons,false)
                break;
            }
            this.enableDrag()
            this.plotmode = mode;
            this.curActiveTreeView++;
            this.treeSVG.switchTreeView(mode)
            this.setToolbarButtonStatus(this.treeSVG)
        },
        switchTreeEdge(treeStyle){            
            this.treeSVG.setTreeCurveStyle(parseInt(treeStyle))
        },

        setToolbarButtonStatus(treeSvg){
            var isvalid = (treeSvg == null) ? false : true;
            var dataLoaded = isvalid && treeSvg.data_loaded 
            if(dataLoaded){
                var hasBootStrap = isvalid && treeSvg.hasBootStrap() ? true : false;
                var hasBranchLength = isvalid && treeSvg.hasBranchLength() ? true : false;
                var showBootStrap = isvalid && treeSvg.getShowBootStrapScores() ? true : false;
                var showBranthLength = isvalid && treeSvg.getShowBranchLength() ? true : false;
                var isSelectedBootStrap = isvalid && treeSvg.getBootStrapFontItalic() ? true : false;
                var isSelectedBranchLength = isvalid && treeSvg.getBranchLengthFontItalic() ? true : false;
                var isMegaMode = isvalid && treeSvg.isMegaMode() ? true : false;
                var showLeafLables = isvalid && treeSvg.getShowLeafLable() ? true : false;
                var alignLeafLabels = isvalid && treeSvg.showNodeLabel && treeSvg.alignLeafLabels
                this.plotmode = isvalid && treeSvg.getPlotMode()

                this.enableDisableBtnGrp(this.genGrpOneButtons,!isvalid)
                this.enableDisableBtnGrp(this.genGrpThreeButtons,!isvalid)
                // this.enableDisableBtnGrp(this.genGrpLefPropButtons,showLeafLables)
                this.enableDisableBtnGrp(this.genGrpCanFitPropButtons,!isvalid)
                this.enableDisableBtnGrp(this.genGrpScalePropButtons,!isvalid)
                this.enableDisableBtnGrp(this.genGrpFontButtons,!isvalid)
                this.enableDisableBtnGrp(this.genGrplineButtons,!isvalid)
                this.enableDisableBtnGrp(this.importButtons,!isvalid)
                this.enableDisableBtnGrp(this.importButtonIconTwo,!isvalid)
                this.enableDisableBtnGrp(this.importButtonIconThree,!isvalid)

                this.enableDisableBtnGrp(this.exportButtons,!isvalid)
                this.enableDisableBtnGrp(this.exportButtonsTwo,!isvalid)

                this.enableBtn('ibtnRectClado',this.genGrpTreeVStylButtons,!isvalid)
                this.enableBtn('ibtnSlantedClado',this.genGrpTreeVStylButtons,!isvalid)
                this.enableBtn('ibtnCircularClado',this.genGrpTreeVStylButtons,!isvalid)            
                this.enableBtn('ibtnRectPhylo',this.genGrpTreeVStylButtons,!(isvalid && hasBranchLength))
                this.enableBtn('ibtnCircularPhylo',this.genGrpTreeVStylButtons,!(isvalid && hasBranchLength))

                this.enableBtn(0,this.genGrpLefPropButtons,!showLeafLables)
                if(showLeafLables){
                    this.enableBtn(2,this.genGrpLefPropButtons,false)
                    if(this.plotmode == treePlotMode.RECT_PHYLOGRAM || this.plotmode == treePlotMode.CIRCULAR_PHYLOGRAM){
                        this.enableBtn(1,this.genGrpLefPropButtons,false && hasBranchLength)
                    }
                }else{
                    this.enableBtn(2,this.genGrpLefPropButtons,true)
                    this.enableBtn(1,this.genGrpLefPropButtons,true)
                }

                this.enableBtn(0,this.genGrpHTModesButtons,!(isvalid))
                this.enableBtn(1,this.genGrpHTModesButtons,!( hasBootStrap))
                this.enableBtn(2,this.genGrpHTModesButtons,!( hasBootStrap))
                this.enableBtn(3,this.genGrpHTModesButtons,!( hasBootStrap && showBootStrap))
                this.enableBtn(0,this.genGrpBranchPropButtons,!( hasBranchLength))
                this.enableBtn(1,this.genGrpBranchPropButtons,!( hasBranchLength && showBranthLength))
                
                // this.enableBtn(1,this.genGrpBranchPropButtons,!(hasBranchLength && showBranthLength))

                this.setSelectedBtn(3,this.genGrpHTModesButtons,(hasBootStrap && showBootStrap && isSelectedBootStrap))
                this.setSelectedBtn(1,this.genGrpBranchPropButtons,(hasBootStrap && showBootStrap && isSelectedBranchLength))
                this.setSelectedBtn(2,this.genGrpBranchPropButtons,(hasBranchLength && showBranthLength))
                if(hasBranchLength && showBranthLength){
                    this.enableBtn(3,this.genGrpBranchPropButtons,!( hasBranchLength && showBranthLength))
                }
                this.setSelectedBtn(1,this.genGrpHTModesButtons,isMegaMode)
                this.setSelectedBtn(1,this.genGrpLefPropButtons,alignLeafLabels)
                this.setSelectedBtn(2,this.genGrpLefPropButtons,(isvalid && treeSvg.getShowLine2Leaflabel()))
                this.setSelectedBtn(0,this.genGrpLefPropButtons,(showLeafLables))

                //tree view style 
                // console.log(this.plotmode,this.plotmode === treePlotMode.RECT_PHYLOGRAM)
                if(this.plotmode === treePlotMode.RECT_CLADOGRAM){
                    this.setSelectedBtn('ibtnRectClado',this.genGrpTreeVStylButtons,true)
                    this.resetTreeStyle('ibtnRectClado',this.genGrpTreeVStylButtons)
                }else if(this.plotmode === treePlotMode.RECT_PHYLOGRAM){
                    this.setSelectedBtn('ibtnRectPhylo',this.genGrpTreeVStylButtons,true)
                    this.resetTreeStyle('ibtnRectPhylo',this.genGrpTreeVStylButtons)
                }else if(this.plotmode === treePlotMode.SLANTED_CLADOGRAM_NORMAL){
                    this.setSelectedBtn('ibtnSlantedClado',this.genGrpTreeVStylButtons,true)
                    this.resetTreeStyle('ibtnSlantedClado',this.genGrpTreeVStylButtons)
                }else if(this.plotmode === treePlotMode.CIRCULAR_CLADOGRAM){
                    this.setSelectedBtn('ibtnCircularClado',this.genGrpTreeVStylButtons,true)
                    this.resetTreeStyle('ibtnCircularClado',this.genGrpTreeVStylButtons)
                }else if(this.plotmode === treePlotMode.CIRCULAR_PHYLOGRAM){
                    this.setSelectedBtn('ibtnCircularPhylo',this.genGrpTreeVStylButtons,true)
                    this.resetTreeStyle('ibtnCircularPhylo',this.genGrpTreeVStylButtons)
                }
                
                // tree curve styles ...
                if(isvalid){
                    // console.log(treeSvg.getTreeCurveStyle(),this.genGrpThreeButtons)
                    switch (treeSvg.getTreeCurveStyle()) {
                        case 1 :
                            // this.enableBtn(0,this.genGrpThreeButtons,!(isvalid &&  true))
                            this.setSelectedBtn('ibtnTreeCurveStyle1',this.genGrpThreeButtons,true)
                            // this.enableBtn(1,this.genGrpThreeButtons,!(isvalid &&  false))
                            this.resetTreeStyle('ibtnTreeCurveStyle1',this.genGrpThreeButtons)
                            break;
                        case 2 :
                            // this.enableBtn(0,this.genGrpThreeButtons,!(isvalid &&  false))
                            this.resetTreeStyle('ibtnTreeCurveStyle2',this.genGrpThreeButtons)
                            // this.enableBtn(1,this.genGrpThreeButtons,!(isvalid &&  true))
                            this.setSelectedBtn('ibtnTreeCurveStyle2',this.genGrpThreeButtons,true)
                            break;
                    }
                }
                if(this.promoMode === 'LIVE') {
                    this.editTreeDis = false
                    this.delTreeDis = false
                    this.copyTreeDis = false
                    this.shareTreeDis = false
                }
                this.enableDrag()            
            }
        },
        resetTreeStyle(styleKey,StyleList){
            for(let key in StyleList){
                if(key != styleKey){
                var btn = StyleList[key]
                btn.state = false
                btn.bg = ''
                }
            }
        },
        disableAllBtns(){
            this.enableDisableBtnGrp(this.genGrpOneButtons,true)
            this.enableDisableBtnGrp(this.genGrpTreeVStylButtons,true)
            this.enableDisableBtnGrp(this.genGrpThreeButtons,true)
            this.enableDisableBtnGrp(this.genGrpHTModesButtons,true)
            this.enableDisableBtnGrp(this.genGrpBranchPropButtons,true)
            this.enableDisableBtnGrp(this.genGrpLefPropButtons,true)
            this.enableDisableBtnGrp(this.genGrpCanFitPropButtons,true)
            this.enableDisableBtnGrp(this.genGrpScalePropButtons,true)
            this.enableDisableBtnGrp(this.importButtons,true)
            this.enableDisableBtnGrp(this.exportButtons,true)
            this.enableDisableBtnGrp(this.exportButtonsTwo,true)
            this.enableDisableBtnGrp(this.advancedButtons,true)
            this.editTreeDis = true
            this.delTreeDis = true
            this.copyTreeDis = true
            this.shareTreeDis = true
        },
    },
}
</script>

<style >
.header {
  position:fixed; /* fixing the position takes it out of html flow - knows
                   nothing about where to locate itself except by browser
                   coordinates */
  left:0;           /* top left corner should start at leftmost spot */
  top:30;            /* top left corner should start at topmost spot */
  width:100vw;      /* take up the full browser width */
  z-index:200;  /* high z index so other content scrolls underneath */
  height:120px;     /* define height for content */
  min-width: 960px;
}

.icon {
  width: 30px;
  height: 30px;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.eicons {
    padding-top:0px;
    padding-bottom:0px
}

.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: #fff !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    color:black;
    font-weight: bold;
    text-decoration: none;
  }

  .md-theme-default a:not(.md-button){
      color:black;
  }
</style>

