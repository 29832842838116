import PlotBase from "./PlotBase";
import treePlotMode from '../treeObjects/treePlotMode'
import StringOps from '../utils/StringOps'
import Point from './Point'
import RgbaColor from '../utils/RgbaColor'

const FontStyle = {
    'NORMAL':'normal',
    'ITALIC':'italic',
    'OBLIQUE':'oblique',
}
Object.freeze(FontStyle)

class LeafAnnotation extends PlotBase{
    constructor(layer_branchAnnotate, disable_all_dataset, treeSVG){
        super()

        this.treeSVG = treeSVG
        this.BranchLengthPositions = this.getBranchLengthPositions()

        this.ndCon = null;
        this.row2data = {};
        this.id2originalID = {}
        this.defaultColor = "white";
        this.default_font_family = "Arial"
        this.plotWidth = 40;
        this.maxWidth = 0
        this.style = 1
    }

    setAnnotationStyle(style){
        this.style = style
    }

    getAngleSpan(){
        return this.treeSVG.getAngleSpan()
    }

    getBranchLengthPositions(){
        return this.treeSVG.getBranchLengthPositions()
    }


    setPlotMode(plotmode){
        this.plotmode = plotmode
    }

    setDataSet(newID, mdatCon) {
        this.ndCon = mdatCon;
        this.datasetID = newID;
        this.setActive(true);
        this.row2data = this.ndCon.getTreeNodeID2Data(); //
        this.id2originalID = this.ndCon.getId2originalID()
        this.datasetContentAsString = mdatCon.getOriginal_datastring(); // get original user input as String ;
        this.setLegendEntry(mdatCon.getLegend());
    }

    getLegend(){
        return this.ndCon.getLegend()
    }

    plotLeafAnnotate(topPts,botPts,topID,botID,cdata,rowplot,fontSize,angleSpanPerHeight,leaf_internal_id,mostright,phylotree,pxPerHeight,plotmode,rootXY,BranchLengthPositions){
        if(topPts != null && this.style === 2){
            var nodeids = [];
            nodeids.push(topID);
            
            if (botPts == null) {
                botPts = topPts;
            } else {
                nodeids.push(botID); // if not null
            }
            
            var hex = RgbaColor.getHexMap()[cdata.getBackgroundColor()]
            var linearGradient = rowplot.gradient('linear', function(stop) {
                stop.at(0, '#FCFCFC')
                stop.at(1, hex )
            })
            var max_text_width = this.ndCon.maxWidth*fontSize                    

            if ((plotmode == treePlotMode.CIRCULAR_CLADOGRAM
                || plotmode == treePlotMode.CIRCULAR_PHYLOGRAM)) {
                if (this.treeSVG.getCircularModeClockwise()) {
                    let tmp = topPts;
                    topPts = botPts;
                    botPts = tmp;
                }
                var angle1 = (topPts.angle - angleSpanPerHeight * (1 + Math.log10(topPts.number_leaf_decendents)) / 2);
                var angle2 = (botPts.angle + angleSpanPerHeight * (1 + Math.log10(botPts.number_leaf_decendents)) / 2);
                var angleSpanCurrentFan = (angleSpanPerHeight * (Math.abs(botPts.verticalLevel - topPts.verticalLevel) + (1 + Math.log10(topPts.number_leaf_decendents)) / 2 + (1 + Math.log10(botPts.number_leaf_decendents)) / 2));

                // fine-tune angles
                angle1 += (angleSpanPerHeight * 0.05 / 2);
                angle2 -= (angleSpanPerHeight * 0.05 / 2);
                
                var outerRadius = max_text_width + mostright - rootXY.x;
                var innerRadius = 0;

                var lca = phylotree.getLCA(nodeids);
                if(lca != null){
                    var lcapos = BranchLengthPositions[lca.getInternalID()];
                    innerRadius = lcapos.pt.x - rootXY.x;
                    var fan = this.makeFanPlot(rootXY, innerRadius, outerRadius, angle1, angle2,
                            angleSpanCurrentFan, rowplot); //5963
                    fan.attr("stroke", "none");
                    fan.attr("fill", linearGradient);
                    fan.backward()
                }else{
                    console.log(leaf_internal_id,topID,botID,nodeids)
                }
            }else{
                var y1 =  (topPts.pt.y
                    - pxPerHeight * (1 + Math.log10(topPts.number_leaf_decendents)) / 2);
                var y2 =  (botPts.pt.y
                    + pxPerHeight * (1 + Math.log10(botPts.number_leaf_decendents)) / 2);

                // fine-tune y1 and y2
                y1 += (pxPerHeight * 0.05 / 2);
                y2 -= (pxPerHeight * 0.05 / 2);

                var recty = y1;
                var recth = y2 - y1;
                var rectx = 0, rectw = 0;
                var lca = phylotree.getLCA(nodeids);
                if(lca != null){
                    var lcapos = BranchLengthPositions[lca.getInternalID()];
                    rectx = lcapos.pt.x
                    rectw = mostright - lcapos.pt.x + (max_text_width);
                    if(this.style === 2){ //+Math.abs(start_pos-startpos.x)
                        let boxHt = botPts.pt.y - topPts.pt.y
                        // var background = rowplot.rect(this.ndCon.getMaxWidth()*fontSize+Math.abs(start_pos-startpos.x),boxHt+20).move(startpos.x,startpos.y-9)                                        
                        let background = rowplot.rect(rectw, recth).move(rectx, recty).radius(0,0)                    
                        background.fill(linearGradient)
                        background.backward()                                                                            
                    }
                }
            }
        }
        if(topPts != null ){
            let leafPosition1 = {'pt':{'x':mostright,'y':topPts.pt.y},'angle':topPts.angle}
            this.drawLabel(rowplot,leafPosition1,cdata,rootXY,plotmode)
        }
        if(botPts != null ){
            let leafPosition2 = {'pt':{'x':mostright,'y':botPts.pt.y},'angle':botPts.angle}
            this.drawLabel(rowplot,leafPosition2,cdata,rootXY,plotmode)                        
        }
    }

    drawLabel(rowplot, leafPosition, cdata ,rootXY,plotmode){ //leafPosition = {pt:{x:start_pos,y:startpos.y}}
        let fontSize = cdata.getFontSize()
        var label = rowplot.text(cdata.text)
        label.font({
            size: fontSize     
            // , anchor: 'middle'
            , style:cdata.isItalic() ? FontStyle.ITALIC : FontStyle.NORMAL
            , fill:cdata.getFontColor()
        })
        label.attr('font-family',this.default_font_family)
        if(this.style === 2){
            label.move(leafPosition.pt.x+fontSize,leafPosition.pt.y-fontSize)
        }else{
            label.move(leafPosition.pt.x+fontSize,leafPosition.pt.y-(fontSize))
        }
        // label.attr('stroke',cdata.getFontColor())
        label.attr('dominant-baseline',"middle")
        let bbox = label.bbox();
        var rect = rowplot.rect(this.ndCon.getMaxWidth()*fontSize,bbox.height+(fontSize/2)).move(leafPosition.pt.x-fontSize,leafPosition.pt.y-(fontSize))
        if(cdata.hasShape() && this.style === 1){
            rect.attr({
                fill:cdata.getBackgroundColor(),
                stroke:cdata.getLineColor(),
                'stroke-width':cdata.getLineWidth(),
            })
            rect.attr('stroke',cdata.getLineColor())
            if(cdata.isBorderDashed()){
                rect.attr('stroke-dasharray','5,5')
            }
            label.forward()
        }else{
            rect.fill("none")
        }
        label.forward()
        label.front()
        if ((plotmode == treePlotMode.CIRCULAR_CLADOGRAM
            || plotmode == treePlotMode.CIRCULAR_PHYLOGRAM)) {            
                label.transform({rotation: 180 - leafPosition.angle, cx: rootXY.x, cy:rootXY.y})
                rect.transform({rotation: 180 - leafPosition.angle, cx: rootXY.x, cy:rootXY.y})
        }
        if(this.maxWidth <= rect.width()){
            this.maxWidth = rect.width()
        }
        return bbox                        
    }

    makeFanPlot( center, innerRadius, outterRadius, angle1,
        angle2, angleSpan, parent_layer) {
        var dxy = Point.getCoordinatesOnCircle(center, innerRadius, angle2);
        var pxy = Point.getCoordinatesOnCircle(center, innerRadius, angle1); // xy of current node at parent circle

        var segs = new Point();
        var dxy2 = Point.getCoordinatesOnCircle(center, outterRadius, angle2);
        var pxy2 = Point.getCoordinatesOnCircle(center, outterRadius, angle1);

        segs.appendSegItem(Point.createPointStr(dxy.x, dxy.y)); // move to p1
        segs.appendSegItem(Point.createPointStr(dxy2.x, dxy2.y,'L')); // line from p1 to p2;
        segs.appendSegItem(Point.createArcStr(pxy2.x, pxy2.y, outterRadius, 0,
                angleSpan > 180 ? 1: 0, 1)); // arc from p2 to p3
        segs.appendSegItem(Point.createPointStr(pxy.x, pxy.y, 'L')); // line from p3 to p4;
        segs.appendSegItem(Point.createArcStr(dxy.x, dxy.y, innerRadius, 0,
                angleSpan > 180 ? 1: 0, 0)); // arc from p4 to p1
        // segs.appendSegItem(Point.createSVGPathSegClosePath()); // close path
        const path = parent_layer.path(segs.getSegPathListStr());
        return path;
    }

    getMaxWidth(){
        return this.maxWidth
    }

    getDatasetFromParser(){
        return this.ndCon
    }
}

export default LeafAnnotation