
class GeneAttribute {
    constructor() {
        this.name = ''
        this.id = ''
        this.shape = 'gene_rect_1'
        this.showLable = false
        this.showArrow = false
        this.direction = 0 // no direction, 1= left direction,2=right direction

        this.width = 20
        this.height = 20
        this.bkcolor = 'lightblue'
        this.fillbg = true
        this.fontColor = 'black'
        this.strokeColor = 'black'
        this.bDashedLine = false
        this.fontItalic = false
        this.fontSize = 10
        this.showGene = false
    }

    setShowGene(bool){
        this.showGene = bool
    }
    isShowGene(){
        return this.showGene
    }
    
    setName(name) { 
        this.name = name; 
    }
    getName(){
         return this.name; 
    } 
    
    setId(id) { 
        this.id = id; 
    } 
    getId(){
         return this.id; 
    } 

    setShape(shape) { 
        this.shape = shape; 
    } 
    getShape(){
         return this.shape; 
    } 

    setShowLable(showLable) { 
        this.showLable = showLable; 
    } 
    getShowLable(){
         return this.showLable; 
    } 

    setShowArrow(showArrow) { 
        this.showArrow = showArrow; 
    } 
    getShowArrow(){
         return this.showArrow; 
    } 

    setDirection(direction) { 
        this.direction = direction; 
    } 
    getDirection(){
         return this.direction; 
    } 

    setWidth(width) { 
        this.width = width; 
    } 
    getWidth(){
         return this.width; 
    } 

    setHeight(height) { 
        this.height = height; 
    } 
    getHeight(){
         return this.height; 
    } 

    setBkcolor(bkcolor) { 
        this.bkcolor = bkcolor; 
    } 
    getBkcolor(){
         return this.bkcolor; 
    } 

    setFillbg(fillbg) { 
        this.fillbg = fillbg; 
    } 
    getFillbg(){
         return this.fillbg; 
    } 

    setFontColor(color) { 
        this.fontColor = color; 
    } 
    getColor(){
         return this.fontColor; 
    } 

    setStrokeColor(strokeColor) { 
        this.strokeColor = strokeColor; 
    } 
    getStrokeColor(){
         return this.strokeColor; 
    } 

    setStrokeType(strokeType) { 
        if(strokeType === 'line'){
            this.bDashedLine = false
        }else{
            this.bDashedLine = true
        }
    } 
    getStrokeType(){
         return this.bDashedLine; 
    } 

    setFontItalic( b) {
		this.fontItalic = b;
	}
	
	getFontItalic(){
		return this.fontItalic;
    }
    
    setFontSize( fontsize) {
		this.fontSize = fontsize;
	}
	
	getFontSize(){
		return this.fontSize;
	}

}

export default GeneAttribute