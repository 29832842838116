import DataContainerParserBase from './DataContainerParserBase'
import GeneAttribute from './GeneAttribute'
import StringOps from '../utils/StringOps'

class GeneSyntenyDataContainerParser extends DataContainerParserBase{
    constructor(datasetid, datasetContent, phylotree){
        super()

        this.id2originalID = {}
        this.row2data = {}
        this.geneWidth = 20
        this.geneHeight = 10

        if(datasetid.length >=1 && datasetContent.length >=1){
            this.setDatasetID(datasetid);
            this.setOriginal_datastring(datasetContent);
        }


        var lines = StringOps.JsArrayStringToArrayList( StringOps.splitStringByNewLine( datasetContent ) );
        for (var idx = 0; idx < lines.length; idx++) {
            var line = lines[idx].trim();
            if (line.trim().length > 0 && ! line.startsWith("#")) { // ignore annotation lines
                var lineparts = StringOps.JsArrayStringToArrayList( StringOps.splitStringByTab( line ) );
                var part1 = lineparts[0].trim();

                // Modifiers -----------------------------
                if(StringOps.equalsIgnoreCase(part1,'!genesynteny')){
                    if (lineparts.length >= 2) {
                        this.geneProp = this.splitKeyValuePairs(lineparts[1].trim())
                        this.setGeneWidth(this.geneProp.hasOwnProperty("width") ? parseInt(this.geneProp["width"].trim()) : this.geneWidth)
                        this.setGeneHeight(this.geneProp.hasOwnProperty("height") ? parseInt(this.geneProp["height"].trim()) : this.geneHeight)
                    } else {
                        this.setError("line " + idx + ": error parsing genesynteny properties.");
                        this.setValid(false);
                    }
                }else if ( part1.startsWith("!")) {
                    this.parseModifier(lineparts, idx + 1);
                }
                //values ---------------------------------
                else if (lineparts.length >= 2) {
                    var internal_id = '';
                    var ids = StringOps.JsArrayStringToArrayList( StringOps.splitStringBySeperator(part1, ","));
                    var lca = phylotree.getLCA(ids);
                    let genes = lineparts[1].trim().split(",")

                    if (lca != null) {
                        internal_id = lca.getInternalID();
                    }
                    if (internal_id != null) {                   
                        let propAr =  this.getGeneToPosMap(genes,this.getGeneValuesType())
                        // console.log(propAr)
                        this.id2originalID[internal_id] =  ids;
                        this.row2data[internal_id] = propAr                        
                    }
                    // if(genes.length < this.getMaxGenesCount()){
                    //     this.valid = false
                    //     this.setError("line " + idx + ": error total genes count doesnt match at "+part1);
                    // }
                }
            }
        }
        
        // console.log(this.arLegendTexts,this.arColorsAttrs,this.getMaxGenesCount())
        // if(this.getGroups_size() == this.getColors_size() == this.getMaxGenesCount() ){            
        // }else{
        //     this.valid = false
        //     this.setError("error: the numbers of legends, legend colors, total gene numbers don't match;");
        // }
        if (Object.keys(this.row2data).length <=0) {
            this.valid = false
            this.setError("error: at least one valid colorset data should be given");
        }
 
        if (!this.isSizesMatchGroupsSizes()) {
             this.valid = false
             this.setError("error: the numbers of legends and legend colors don't match;"); // TODO: ciculate colors if there are not enough colors
        }
    }

    setGeneWidth(width){
        this.geneWidth = width
    }
    getGeneWidth(){
        return this.geneWidth
    }

    setGeneHeight(height){
        this.geneHeight = height
    }
    getGeneHeight(){
        return this.geneHeight
    }

    getGeneToPosMap(geneAr,type){
        let emptyAr = []
        if(this.getGroups_size() > 1){
            var missed_genes = [],added_genes = []
            let gene_map = {}
            for(let ind in this.getLegendTexts()){
                let color = this.getLegendColors()[ind]
                let leg_text = this.getLegendTexts()[ind]
                let geneAttri = new GeneAttribute()
                gene_map[leg_text] = geneAttri
                // console.log(ind,leg_text,geneAr)
                for(let arPos in geneAr){
                    let geneInfo = geneAr[arPos]
                    let temAr = geneInfo.split(':')
                    if(temAr[0].trim() === leg_text){
                        geneAttri.setShowGene(true)
                        if(temAr.length==1){ // name 
                            geneAttri.setName(temAr[0])
                        }else if(temAr.length == 2){ //1=name,2=direction
                            geneAttri.setName(temAr[0])
                            geneAttri.setDirection(temAr[1])
                        }
                        this.addGeneProp(geneAttri,color)
                        added_genes.push(temAr[0].trim())
                        break
                    }else{
                        missed_genes.push(temAr[0].trim())
                    }
                }
            }            
            emptyAr = Object.values(gene_map)
            // if(missed_genes.length >= 1){
            //     var missed = missed_genes.filter(p=>!added_genes.includes(p))
            //     // console.log('missed',missed,geneAr,gene_map)
            // }
        }
        return emptyAr
    }

    addGeneProp(geneAttri,geneColor){
        geneAttri.setShowLable(this.showGeneName)
        geneAttri.setShowArrow(this.showGeneDir)
        geneAttri.setBkcolor(geneColor)
        geneAttri.setWidth(this.geneProp.hasOwnProperty("width") ? parseInt(this.geneProp["width"].trim()) : this.geneWidth)
        geneAttri.setHeight(this.geneProp.hasOwnProperty("height") ? parseInt(this.geneProp["height"].trim()) : this.geneHeight)
        geneAttri.setShape(this.geneProp.hasOwnProperty("style") ? 'gene_rect_'+ this.geneProp["style"].trim() : 'gene_rect_1')
        geneAttri.setFillbg(this.geneProp.hasOwnProperty("fillbg") ? parseInt(this.geneProp["fillbg"]) === 1 : false)
        geneAttri.setStrokeColor(this.geneProp.hasOwnProperty("strokecolor") ? this.geneProp["strokecolor"] : geneColor ) 
        geneAttri.setStrokeType(this.geneProp.hasOwnProperty("stroketype") ? this.geneProp["stroketype"] : 'line')
        geneAttri.setFontColor(this.geneProp.hasOwnProperty("fontcolor") ? this.geneProp["fontcolor"] : 'black')
        geneAttri.setFontItalic(this.geneProp.hasOwnProperty("fontItalic") ? this.geneProp["fontItalic"] === 1 : false)
        geneAttri.setFontSize(this.geneProp.hasOwnProperty("fontsize") ? this.geneProp["fontsize"] : 8)
    }

    getTreeNodeID2Data() {
        return this.row2data; // note ID is internal ID
    }

    getId2originalID(){
        return this.id2originalID
    }
}

export default GeneSyntenyDataContainerParser