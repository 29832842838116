import PyTree from '../treeParser/PyTree'
import UserDataDataset4Tree from '../treeObjects/UserDataDataset4Tree'
import MC from '../treeObjects/MC'
import EventBus from '../components/EventBus'

class CommonEvol{

    static checkInputFile(fileStr){
        const pyTree = new PyTree('','','')
        var isTreeFile = pyTree.detectTreeFormat(fileStr)
        return isTreeFile
    }

    static checkTreeFormat(treeName,treeFormat,treeContentStr){
        const pyTree = new PyTree(treeName,treeFormat,treeContentStr)            
        return pyTree
    }

    static checkDatasetFormat(activeProj,activeTree,name2catpanel,datasetName,datasetContent,datatype){
        var catpanelname = CommonEvol.getDataTypeName(datatype);
        var projectName = activeProj.projectName
        var treeName = activeTree.treeName
        var success = false;
        if(name2catpanel.hasOwnProperty(catpanelname)){
            var cp = name2catpanel[catpanelname]
            if(cp != null){
                var uddt = new UserDataDataset4Tree()
                uddt.setName(datasetName);
                uddt.setContent(datasetContent);
                uddt.setDesc("");
                uddt.setActive(true);
                uddt.setTypeOrFormat(datatype);
                uddt.setSaveDatasetToServer(true);
                success = cp.checkAddDataSet2Tree(uddt);
                // console.log(success,uddt)
                return {'ds':success,'udd':uddt}
            }
        }else{
            console.log(catpanelname,datatype,name2catpanel)
        }
        return null
    }

    static getDataTypeName(datatype){
        return (datatype == (MC.BARS) || datatype == (MC.STRIPS) || datatype == (MC.PROTEINDOMAINS)
            || datatype == (MC.GROUPLABELS) || datatype == (MC.DOTPLOTS) || datatype == (MC.HEATMAP)
            || datatype == (MC.NAMEDCOLUMN) || datatype == MC.GENESYTNEY )

                    ? MC.CHARTS
                    : datatype;
    }

    static getCatPanTypes(){
        return [MC.PIES, MC.BRANCHECOLORS,MC.BRANCHSTROKE,MC.BRANCHLABELS,MC.LEAFANNOTATE, MC.LEAFLABELCOLORS,
			MC.LEAFBKCOLORS, MC.LEAFLABELDECO, MC.LEAFIMAGE, MC.CHARTS, MC.BOOTSTRAPSTYLES, MC.INTERNALCOLLAPSES
            , MC.TIMELINE,MC.BRANCHMARKER,MC.GENETRANSFER]
    }

    static isThisMyTree(treeUserId,currentUserId){
        // return activeTree.userId == this.userId
        return treeUserId == currentUserId
    }

    static showErrorMessage(errorMsg,status) {
        EventBus.$emit('showMess',{'mess':errorMsg,'title':status})
    }
}

export default CommonEvol