
class MC{
    static SAVEDVIEWS = "Saved Views" 
    static PIES = "Pies" 
    static BRANCHECOLORS = "Branch Colors" 
    static BRANCHSTROKE = "Barnch Stroke"
    static BRANCHLABELS = "Branch Labels"
    static LEAFANNOTATE = "Leaf Annotations"
    static LEAFLABELCOLORS = "Leaf Colors" 
    static LEAFBKCOLORS = "Leaf BK Colors"
    static LEAFIMAGE = "Leaf Image"
    static GENESYTNEY = "Gene Synteny"
    static BRANCHMARKER = "Branch marker"
    static CHARTS = "Charts" 
    static BARS = "Bars" 
    static STRIPS = "Strips" 
    static PROTEINDOMAINS = "Protein Domains" 
    static LEAFLABELDECO = "Leaf Decoration" 
    static GROUPLABELS = "Group Labels" 
    static demoproject = "DEMOS"
    static DOTPLOTS = "DotPlots" 
    static HEATMAP = "Heatmap" 
    static BOOTSTRAPSTYLES = "Bootstrap value styles" 
    static INTERNALCOLLAPSES = "Internal collapses" 
    static NAMEDCOLUMN = "Named columns"  
    static TIMELINE = "Time line"
    static GENETRANSFER = "Gene transfer"
}

export default MC