import DataContainerParserBase from './DataContainerParserBase'
import StringOps from '../utils/StringOps'

class HeatmapDataContainer extends DataContainerParserBase{
    constructor(newdatasetid, newdataString, phylotree){
        super()

        this.row2data = {}
        this.id2originalID = {}
        this.minValue = 0
        this.maxValue = 0

        this.setDatasetID(newdatasetid);
		this.setActive(true);
		this.setOriginal_datastring(newdataString);

		this.numericMatrixDataParser(newdataString, phylotree);
    }

    /**
	 * >>>>> created Nov 5, 2013 
	 * recalculate internalIDs; this necessary if tree is rerooted ...
	 * @param phylotree : the rerooted tree ... 
	 */
	reMapDataAfterTreeReroot(phylotree) {

		console.log("  --> recalculate some data in NumericMatrixData --> ");
		/**
		 * all the following hashMaps will be recalculate
		 * row2data ( but not 'column2data' ), id2sum, id2radius, id2radiusByArea
		 */
		for (var ind in  this.id2originalID) {
			var oldInternalId = ind
			var ids = this.id2originalID[ind]

			/**
			 * get internal ID on current phylotree 
			 */
			var lca = phylotree.getLCA(ids);
			if (lca != null) {
				var internal_id = lca.getInternalID();
				if (internal_id != null && internal_id.length >=1) {
					/**
					 * if old internal ID does not match to new internal id,
					 * change values 
					 */
					if (!oldInternalId === (internal_id)) {
						this.row2data[internal_id] =  this.row2data[oldInternalId];
					}
				}
			}// if lca if not null    		
		}// entry set
    }
    
    numericMatrixDataParser(newdataString, phylotree) {
		/*
		 * prepare to process input data Nov 07, 2011; now annotation line
		 * starts with #; action line starts with !
		 */
		var lines = StringOps.JsArrayStringToArrayList( StringOps.splitStringByNewLine( newdataString ) );
		for (var idx = 0; idx < lines.length; idx++) {
			var current_line_index = idx + 1;
			var line = lines[idx].trim();
			if (line.trim().length > 0 && !line.startsWith("#")) { // Nov 7, 2011;
				var lineparts =
                StringOps.JsArrayStringToArrayList( StringOps.splitStringByTab( line ) );
				var part1 = lineparts[0].trim();

				// modifiers ---------------------------------------
				if (part1.startsWith("!")) {
					this.parseModifier(lineparts, current_line_index);
				}
				// values ------------------------------------------
				else if (lineparts.length >= 2) {
					var part2 = lineparts[1].trim();

					/*
					 * deal with data
					 */
					var internal_id = null;
					var ids =
                    StringOps.JsArrayStringToArrayList( StringOps.splitStringBySeperator( part1, ","));
					var lca = phylotree.getLCA(ids);
					if (lca != null) {
						internal_id = lca.getInternalID();
					}

					if (internal_id != null) {
						/**
						 * Nov 5, 2013; keep tracking the internal-id <=> ids relationships
						 */
						this.id2originalID[internal_id] =  ids;

						/**
						 *  get data
						 */
						var colcount = 0;
                        var ardata = [];
                        var ar = StringOps.JsArrayStringToArrayList( StringOps.splitStringBySeperator(part2, ","))
						for (var ind in ar) {
							var strdata = ar[ind]
							try {
								var fdata = parseFloat(strdata);
								ardata.push(fdata);
								colcount ++;
							} catch (e) {
								this.setError("error parsing data at line: " + current_line_index + "; float expected, got " + strdata);
								this.setValid(false);
							}
						}
						
						// push data in ...
						if( colcount > 0 ){
							this.row2data[internal_id] =  ardata;
							this.setColumnCountMax(colcount);
						}
					} // internal id 
				}
			} // end of if line isn't empty
		} // iterate string fragments split from pcdatastring

		/*
		 * check if there is any valid data
		 */
		if (this.row2data.length <=0) {
			this.setError("no valid data parsed, please check your input!!");
			this.setValid(false);
		} // if treenodeid2data empty

		// at the end, iterate treenodeid2data and get group2data; March 23, 2011
		// also calculate the max and min values 
		var temp = [];

		if (this.isValid()) {
			for (var ind in this.row2data) {
                var row = this.row2data[ind]
				temp.push.apply(temp,row); // 
			}// iterate treenodeid2data;

			/**
			 * April 2, 2015 .. when calculate min and max values, the user input max and min will also be taken into account --
			 */
			if (this.isMaxValueSet()) {
				temp.push(this.getMaxPixelRadius());
			}
			if (this.isMinValueSet()) {
				temp.push(this.getMinPixelRadius());
			}

			if (temp.length >=1) {
				this.minValue = Math.min(...temp);
				this.maxValue = Math.max(...temp);
			} else {
				this.setError(" unknown error, please check your input!!");
				this.setValid(false);
			}
			// console.log(temp,this.getMaxPixelRadius(),this.getMaxValue(),Math.max(...temp))
		}
    }
    
    getTreeNodeID2Data() {
		return this.row2data; // note ID is internal ID
	}

	getMaxValue() {
		return this.maxValue;
	}

	getMinValue() {
		return this.minValue;
	}

	getColumnCountMax() {
		return this.columnCountMax;
	}

	setColumnCountMax( columnCountMax) {
		if( this.columnCountMax < columnCountMax ){
			this.columnCountMax = columnCountMax;			
		}
	}
}

export default HeatmapDataContainer