import StringOps from "../utils/StringOps";
import PlotBase from "./PlotBase";
import Point from "./Point";
import treePlotMode from '../treeObjects/treePlotMode'


class GeneSynteny extends PlotBase{
    constructor(layer_tree_charts, disable_all_dataset, treeSVG){
        super()

        this.treeSVG = treeSVG
        this.layer_tree_charts = layer_tree_charts
        this.disable_all_dataset = disable_all_dataset
        this.chart_plots_enabled = this.treeSVG.chart_plots_enabled
        this.currentMostRightPosition = this.treeSVG.getLeafMostRightPosition()
        this.plotmode = treeSVG.getPlotMode()
        this.phylotree = treeSVG.getPhyloTree()
        this.LeafInternalID2NodePosisionts = this.getLeafInternalID2NodePosisionts()
        this.dataset = null
        this.row2data = {}
        this.id2originalID = {}

        this.defaultColor = "white";
        this.default_plot_width = 100
        this.plotWidth = this.default_plot_width;
        this.dataset = null
        this.angle_span = this.treeSVG.angle_span

        this.layer_grid = null
        this.layer_axis = null
        this.pwidth_per_value = 0;


    }

    setGeneDataSet(newID, newdataset) {
        this.dataset = newdataset;
        this.datasetID = newID;
        this.setActive(true);
        this.row2data = this.dataset.getTreeNodeID2Data(); //
        this.id2originalID = this.dataset.getId2originalID()
        this.datasetContentAsString = newdataset.getOriginal_datastring(); // get original user input as String ;
                                                                            // April 5, 2011;
        this.opacity = this.dataset.getOpacity();
        this.plotWidth = this.dataset.getPlotwidth();
        this.hmDomainAccession2SVGElements = {}
        this.hmDomainAccessioin2LegendSVGElement = {}
        this.setLegendEntry(newdataset.getLegend());
        this.pxPerHeight = this.treeSVG.pxPerHeight
        this.space_between_datasets = this.treeSVG.space_between_datasets
    }

    setLeafInternalID2NodePosisionts(LeafInternalID2NodePosisionts){
        this.LeafInternalID2NodePosisionts = LeafInternalID2NodePosisionts
    }

    setRootXY(rootXY){
        this.rootXY = rootXY
    }

    getLeafMostRightPosition(){
        return this.currentMostRightPosition
    }

    setLeafMostRightPosition(currentMostRightPosition){
        this.currentMostRightPosition = currentMostRightPosition
    }

    setPlotMode(plotmode){
        this.plotmode = plotmode
    }

    getAngleSpan(){
        return this.treeSVG.getAngleSpan()
    }

    getLeafInternalID2NodePosisionts(){
        return this.treeSVG.getLeafInternalID2NodePosisionts()
    }

    addHighlightToRowPlotByLeafInternalID(leaf_internal_id) {
        if (this.hmInternalID2RowPlot.hasOwnProperty(leaf_internal_id)) {
            this.hmInternalID2RowPlot[leaf_internal_id].attr("stroke", "red");
        }
    }

    removeHighlightFromRowPlotByLeafInternalID(leaf_internal_id) {
        if (this.hmInternalID2RowPlot.hasOwnProperty(leaf_internal_id)) {
            this.hmInternalID2RowPlot[leaf_internal_id].attr("stroke",null);
        }
    }

    makeOrUpdatePlot() {
        this.initLayer(this.layer_tree_charts)
        this.layer_grid = this.layer_tree_charts.group().id('layed_grid')
        this.layer_axis = this.layer_tree_charts.group().id('layer_axis')
        this.LeafInternalID2NodePosisionts = this.getLeafInternalID2NodePosisionts()

        const groups = this.dataset.getLegendTexts();
        const colors = this.dataset.getLegendColors();
        const strokecolors = this.dataset.getLegendStrokeColors();
        const defaultStrokeColor = this.dataset.getDefaultStrokeColor();
        const strokewidth = this.dataset.getDefaultStrokeWidth();
        this.currentMostRightPosition = this.getLeafMostRightPosition()
        this.LeafInternalID2NodePosisionts = this.getLeafInternalID2NodePosisionts()
        this.phylotree = this.treeSVG.getPhyloTree()
        this.rootXY = this.treeSVG.getRootXY()
        this.angle_span = this.getAngleSpan()
        this.hmInternalID2RowPlot = {};

        if (this.active && !this.disable_all_dataset && this.chart_plots_enabled) {
            var mostright = this.currentMostRightPosition + this.space_between_datasets;
            // var strokewidth = this.dataset.getDefaultStrokeWidth();
            this.def_gene_length = this.dataset.getGeneWidth() //get it from the user
            this.def_gene_height = this.dataset.getGeneHeight()
            var def_gene_space = 3
            var plotwidth = 0
            this.hmDomainAccession2SVGElements = {}

            let makegrid = false;
            if (this.dataset.isPlotgrid()) {
                Point.clearAllChildNodesFromSVGGElement(this.layer_grid);
                this.layer_grid.attr("stroke-width", this.line_width_grid + "");
                this.layer_grid.attr("stroke", "grey");
                this.layer_grid.attr("stroke-dasharray", "4,4"); //now all have this dash
                this.layer_grid.attr("fill", "none"); // for path only
                makegrid = true;
            } else {
                Point.deleteChildNodeFromLayerTree(this.layer_grid);
            }

            // grid axis; grid labels
            if (this.dataset.isShowGridLabel()) {
                Point.clearAllChildNodesFromSVGGElement(this.layer_axis);
                makegrid = true;
            } else {
                Point.deleteChildNodeFromLayerTree(this.layer_axis);
            }

            if (makegrid) {
                this.drawGridLinesAndLabels(1, 5, this.dataset.getMaxGenesCount(), mostright, this.pwidth_per_value,
                this.pxPerHeight, 0, this.layer_grid, this.layer_axis, false, null, null);
            } // make grid

            Point.clearAllChildNodesFromSVGGElement(this.layer);
            this.layer.attr("fill-opacity", (this.opacity));

            for (var entry in this.LeafInternalID2NodePosisionts) {
                const leaf_internal_id = entry;
                var startpos = this.LeafInternalID2NodePosisionts[entry].pt;
                var angle = this.LeafInternalID2NodePosisionts[entry].angle;
                let leaf_external_id = this.phylotree.getExternalIDbyInternalID(leaf_internal_id);

                if(this.row2data.hasOwnProperty(leaf_internal_id)){
                    let rowDat = this.row2data[leaf_internal_id]
                    let geneChart = this.layer.group().id('geneChart');
                    this.hmInternalID2RowPlot[leaf_internal_id] =  geneChart;
                
                    var that = this
                    geneChart.mouseover(function(evt) {
                        that.treeSVG.addHighlightToAllRowplotsByInternalLeafID(leaf_internal_id);
                    })
                    geneChart.mouseout(function(evt) {
                        that.treeSVG.removeHighlightFromAllRowplotsByInternalLeafID(leaf_internal_id);
                    })

                    //draw line for gene chart
                    var totalGeneLength = this.dataset.getMaxGenesCount() * this.def_gene_length
                    var line = geneChart.line(mostright, startpos.y,
                        mostright + totalGeneLength + (this.dataset.getMaxGenesCount() * def_gene_space), startpos.y);
                    line.attr("stroke", "grey");
                    line.attr("stroke-width", "3px");
                    plotwidth = totalGeneLength + (this.dataset.getMaxGenesCount() * def_gene_space)
                    if ((this.plotmode == treePlotMode.CIRCULAR_CLADOGRAM
                        || this.plotmode == treePlotMode.CIRCULAR_PHYLOGRAM)) {            
                            line.transform({rotation: 180 - angle, cx: this.rootXY.x, cy:this.rootXY.y})
                    }
                    
                    var cur_x = mostright

                    for(let idx in rowDat){
                        var value = rowDat[idx]
                        // console.log(value)
                        if(value.isShowGene()){
                            let gene = this.drawGene(cur_x,startpos.y-(this.def_gene_height/2),this.def_gene_length,this.def_gene_height, strokewidth, angle, geneChart,value)                            
                            cur_x+=this.def_gene_length+def_gene_space
                        }else{
                            cur_x+=this.def_gene_length+def_gene_space
                        }
                    }

                }
            }
            this.currentMostRightPosition += (plotwidth + this.space_between_datasets);
        }
    }

    drawGene(startx, starty, itemwidth, itemheight,
        strokewidth, angle, group_layer,geneProp){
        var ashape = group_layer.group().id('ashape')
        var shape = geneProp.getShape()
        var strokecolor = geneProp.getStrokeColor()
        var color = geneProp.getColor()
        var rx = 2, ry = 2
        var gene_name = geneProp.getName()

        var label = ashape.text(gene_name).move(startx+2, starty+2).fill('black')
        label.font({
            size:geneProp.getFontSize(),
            style:geneProp.getFontItalic() ? 'italic' : 'normal'
        })
        label.attr('fill',geneProp.setFontColor())

        if(StringOps.equalsIgnoreCase(shape,("gene_rect_1"))) { // default is rect
            var rect = ashape.rect(itemwidth, itemheight).move(startx, starty)
            rect.radius(rx, ry)            
            var arrow = this.drawArrow(startx, starty+itemheight+5,itemwidth,itemheight,angle,group_layer,geneProp)
            if(!geneProp.getShowArrow()){
                arrow.hide()
            }            
        }else if(StringOps.equalsIgnoreCase(shape,("gene_rect_2"))) { // default is rect
            var polyAr = []
            var rect = null
            if(geneProp.getDirection() == 1){
                polyAr.push(Point.createPointStr(startx,starty+itemheight/2,'M'))
                polyAr.push(Point.createPointStr(startx+this.def_gene_height,starty,'L'))
                polyAr.push(Point.createPointStr(startx+itemwidth,starty,'L'))
                polyAr.push(Point.createPointStr(startx+itemwidth,starty+itemheight,'L'))
                polyAr.push(Point.createPointStr(startx+this.def_gene_height,starty+itemheight,'L'))
                polyAr.push(Point.createPointStr(startx,starty+itemheight/2,'L'))
                rect = ashape.path(polyAr.join(' ')).move(startx, starty)
            }else if(geneProp.getDirection() == 2){
                polyAr.push(Point.createPointStr(startx,starty+itemheight/2,'M'))
                polyAr.push(Point.createPointStr(startx,starty,'L'))
                polyAr.push(Point.createPointStr(startx+itemwidth-this.def_gene_height,starty,'L'))
                polyAr.push(Point.createPointStr(startx+itemwidth+1,starty+itemheight/2,'L'))
                polyAr.push(Point.createPointStr(startx+itemwidth-this.def_gene_height,starty+itemheight,'L'))
                polyAr.push(Point.createPointStr(startx,starty+itemheight,'L'))
                rect = ashape.path(polyAr.join(' ')).move(startx, starty)
            }else if(geneProp.getDirection() == 0){
                rect = ashape.rect(itemwidth, itemheight).move(startx, starty)
                rect.radius(rx, ry)            
            }                            
        }
        rect.attr("fill", geneProp.getBkcolor());
        rect.attr("stroke", strokecolor);
        rect.attr("stroke-width", strokewidth + "");
        if(geneProp.getFillbg()){
            rect.attr("fill", color);
        }
        if(!this.dataset.isShowGeneLabel()){
            label.hide()
        }
        label.forward()
        if (angle != 0) {
            rect.transform({rotation:180-angle, cx:this.rootXY.x, cy:this.rootXY.y})
            label.transform({rotation:180-angle, cx:this.rootXY.x, cy:this.rootXY.y})
        }
    }

    drawArrow(startx, starty, itemwidth, itemheight,angle, group_layer,geneProp){        
        let arrow_layer = group_layer.group().id('arrow')
        let start = arrow_layer.polygon('10 0, 10 7, 0 3.5').fill('grey')
        let end = arrow_layer.polygon('0 0, 10 3.5, 0 7').fill('grey')
        var line = arrow_layer.line(startx,starty,startx+itemwidth,starty).fill('grey')
        
        if(geneProp.getDirection() == 1){
            start.show()
            start.move(startx-1,starty-3)
            end.hide()
        }else if(geneProp.getDirection() == 2){
            end.move(startx+itemwidth-7,starty-4)
            end.show()
            start.hide()
        }else if(geneProp.getDirection() == 0){
            start.hide()
            end.hide()
            line.hide()
        }
        line.stroke({
            color: 'grey', width: 2
        })
        // line.attr("stroke", "grey");
        // line.attr("stroke-width", "2px");
        if (angle != 0) {
            line.transform({rotation:180-angle, cx:this.rootXY.x, cy:this.rootXY.y})
            start.transform({rotation:180-angle, cx:this.rootXY.x, cy:this.rootXY.y})
            end.transform({rotation:180-angle, cx:this.rootXY.x, cy:this.rootXY.y})            
        }
        line.backward()
        start.forward()
        end.forward()
        return arrow_layer
    }
    

    drawGridLinesAndLabels( groupSize, gridlineCount, maxvalue, plotstart,
        widthPerValue, pixPerProtein, plot_width_per_subpanel, layer_grid,
          layer_gridlabel,  isLogTransform, hmUserInputGrids,
         hmGridShadeOptions) {
     
       // get leaf information
       var firstleaf_id = this.phylotree.getFirstLeafNode().getInternalID();
       var lastleaf_id = this.phylotree.getLastLeafNode().getInternalID();
     
       var firstleafPos = this.LeafInternalID2NodePosisionts[firstleaf_id];
       var lastleafPos = this.LeafInternalID2NodePosisionts[lastleaf_id];
     
       var anglespan_per_leaf_label = this.getAngleSpan() / this.phylotree.getLeafNodes().length;
       var anglespan_of_leaf_lables = this.getAngleSpan() - anglespan_per_leaf_label;
     
       // grid shade options 
       var bGridShadeDataValid = hmGridShadeOptions != null && hmGridShadeOptions.length >=1;
     
       /**
        * grid assemble positions at which grid lines to be
        * plotted ...
        */
       var gridValues = [];
     
       var isUserInputGridsAvailable = false;
       if (hmUserInputGrids != null && hmUserInputGrids.length >=1) {
         for (var f in hmUserInputGrids) {
           gridValues.push(hmUserInputGrids[f]); // no sanity check --
         }
       }
     
       if (gridValues.length<=0) {
         // nice axis, [ start, end, step ]
        var niceaxis = StringOps.AxisStartEndStep(0, maxvalue, gridlineCount);
         for (var f = niceaxis[0]; f < niceaxis[1]; f += niceaxis[2]) {
           gridValues.push(f);
         }
       } else {
         isUserInputGridsAvailable = true;
       }
     
       if (isLogTransform) {
         maxvalue = Math.log(maxvalue);
       }
     
       /**
        * plot real start / end in pixel --
        */
       var plotstart_current_group = plotstart; // 0
     
       /*
        * refine grid and axis add numbers for grid lines 
        * 
        */
       for (var g = 0; g < groupSize; g++) {
         for (var grididx = 0; grididx < gridValues.length; grididx++) {
           var gridValue = gridValues[grididx];
           var canPlotShaddedStripBetweenGrids = grididx % 2 == 0 && (gridValues.length - grididx) > 1;
           
           var gridlabel = isUserInputGridsAvailable ? hmUserInputGrids[gridValue]
               : ( gridValue == gridValue) ? gridValue+""
                   : gridValue+"";
     
           if (isLogTransform) {
             if (gridValue != 0) {
               gridValue = Math.log(gridValue);
             }
             if (gridValue < 0) {
               gridValue = 0;
             }
           }
     
           var plotPosInPixel = (plotstart_current_group + gridValue * widthPerValue);
     
           // String color = grididx == 0 ? "grey" : "grey"; // make them all
           // grey
     
           var next_gridValue = 0, next_plotPosInPixel = 0;
           var next_gridlabel = "";
           if (canPlotShaddedStripBetweenGrids && bGridShadeDataValid) {
             next_gridValue = gridValues[grididx + 1];
             next_gridlabel = isUserInputGridsAvailable ? hmUserInputGrids[next_gridValue]
                 : (next_gridValue == next_gridValue) ? ( next_gridValue+"")
                     : (next_gridValue+"");
     
             // transform
             if (isLogTransform) {
               if (next_gridValue != 0) {
                 next_gridValue = Math.log(next_gridValue);
               }
               if (next_gridValue < 0) {
                 next_gridValue = 0;
               }
             }
     
             next_plotPosInPixel = (plotstart_current_group + next_gridValue * widthPerValue);
           }
     
           // if circular mode
           if ((this.plotmode === (treePlotMode.CIRCULAR_CLADOGRAM)
               || this.plotmode === (treePlotMode.CIRCULAR_PHYLOGRAM))) {
             var aradius = plotPosInPixel - this.rootXY.x;
     
             var startpoint = Point.getCoordinatesOnCircle(this.rootXY, aradius, firstleafPos.angle);
             var endpoint = Point.getCoordinatesOnCircle(this.rootXY, aradius, lastleafPos.angle);
     
             var sweepFlag = this.treeSVG.circular_tree_plot_in_clockwise_mode;
             var large_arc = (anglespan_of_leaf_lables >= 180) ? 1 : 0; //  use global
                                                 // angle_span?
     
             // boolean largeArcFlag = angle >= 180 ? true : false;
             var segs = new Point();
             segs.appendSegItem(Point.createPointStr(startpoint.x, startpoint.y));
             segs.appendSegItem(Point.createArcStr(endpoint.x, endpoint.y, aradius, 0,
                 large_arc, sweepFlag ? 1 : 0));
             const path = layer_grid.path(segs.getSegPathListStr());
     
             /**
              * ******************************************************************** 
              * 0~1， 2~3， 4~5  ...
              * ********************************************************************
              */
             if (canPlotShaddedStripBetweenGrids && bGridShadeDataValid) {
               var next_aradius = next_plotPosInPixel - this.rootXY.x;
               var next_startpoint = Point.getCoordinatesOnCircle(this.rootXY, next_aradius,
                   firstleafPos.angle);
               var next_endpoint = Point.getCoordinatesOnCircle(this.rootXY, next_aradius, lastleafPos.angle);
     
               //
               var fansegs = new Point();
     
               fansegs.appendSegItem(Point.createPointStr(startpoint.x, startpoint.y)); // move to p1
               fansegs.appendSegItem(
                   Point.createPointStr(next_startpoint.x, next_startpoint.y,'L')); // line from p1 to p2;
               fansegs.appendSegItem(Point.createArcStr(next_endpoint.x, next_endpoint.y,
                   next_aradius, 0, large_arc, sweepFlag ? 1 : 0)); // arc from p2 to p3
               fansegs.appendSegItem(Point.createPointStr(endpoint.x, endpoint.y,'L')); // line from p3 to p4;
               fansegs.appendSegItem(Point.createSVGPathSegArcAbs(startpoint.x, startpoint.y,
                   aradius, 0, large_arc, !sweepFlag ? 1 : 0)); // arc from p4 to p1

               const shaddedfan = layer_grid.path(fansegs.getSegPathListStr());
               // fill color
               shaddedfan.attr("fill", hmGridShadeOptions["fill"]);
               shaddedfan.attr("fill-opacity", hmGridShadeOptions["opacity"]);
     
               const msg = gridlabel + " -- " + next_gridlabel;
             }
     
             /**
              * *****************************************************grid label ...
              * *****************************************************
              */
             var angle = this.treeSVG.circular_tree_plot_in_clockwise_mode ? lastleafPos.angle
                 : firstleafPos.angle;
     
             // some variables --
             var current_s, r2Angle, y;
             var textanchor;
     
             if (angle > 0 && angle < 180) {
               current_s = plotPosInPixel - 10;
               r2Angle = 270;
               textanchor = "start";
               y = lastleafPos.pt.y + this.treeSVG.default_tick_label_font_size * 0.33;
             } else {
               current_s = plotPosInPixel + 10;
               r2Angle = 90;
               textanchor = "end";
               y = lastleafPos.pt.y + this.treeSVG.default_tick_label_font_size * 0.33;
             }
     
            // text label
            const txtGridLabel = layer_gridlabel.text(gridlabel).move(current_s-10, y+10)
            txtGridLabel.font({size:this.treeSVG.default_tick_label_font_size});
            txtGridLabel.font({anchor:textanchor}); // Align center     
             
            var textpoint = this.treeSVG.circular_tree_plot_in_clockwise_mode ? endpoint : startpoint;
            // txtGridLabel.transform({rotation:r2Angle, cx:textpoint.x, cy:textpoint.y})
            txtGridLabel.font({anchor:StringOps.equalsIgnoreCase(textanchor,("start")) ? "end" : "start"})
     
            if (angle != 0) {
               txtGridLabel.transform({rotation:180-angle, cx:this.rootXY.x, cy:this.rootXY.y})
            } // circular
     
            
            txtGridLabel.mouseover(function(){
                path.attr("stroke", "red");
                path.attr("stroke-width", "0.5px");
                this.attr("fill", "red");
            })
            txtGridLabel.mouseout(function(){
                path.attr("stroke",null);
                path.attr("stroke-width",null);
                this.attr("fill",null);
            })
    
            path.mouseover(function() {
                this.attr("stroke", "red");
                this.attr("stroke-width", "0.5px");
                 txtGridLabel.attr("fill", "red");
            })
            path.mouseout(function() {
                this.attr("stroke",null);
                this.attr("stroke-width",null);
                txtGridLabel.attr("fill",null);
            })     
           } else {
          
             var y = lastleafPos.pt.y + pixPerProtein;
     
             var txtGridLabel = layer_gridlabel.text(gridlabel).move(plotPosInPixel, y)             
             txtGridLabel.font({size:this.treeSVG.default_tick_label_font_size});
             txtGridLabel.attr("text-anchor", "middle"); // April 14, 2011; Align center
     
             const line = layer_grid.line(plotPosInPixel,
                firstleafPos.pt.y - pixPerProtein * 0.8 / 2, plotPosInPixel,
                lastleafPos.pt.y + pixPerProtein * 0.8 / 2)
     
             /**
              * ******************************************************************** 画
              * rect, 0~1， 2~3， 4~5 ...
              * ********************************************************************
              */
             if (canPlotShaddedStripBetweenGrids && bGridShadeDataValid) {
               //
               const shaddedrect = layer_grid.rect(next_plotPosInPixel - plotPosInPixel,
                lastleafPos.pt.y - firstleafPos.pt.y + pixPerProtein * 0.8).move(plotPosInPixel,
                    firstleafPos.pt.y - pixPerProtein * 0.8 / 2).radius(0,0)     
               shaddedrect.attr("fill", hmGridShadeOptions["fill"]);
               shaddedrect.attr("fill-opacity", hmGridShadeOptions["opacity"]);
     
               const msg = gridlabel + " -- " + next_gridlabel;
             }
     
             /**
              * mouse events 
              */
             txtGridLabel.mouseover(function(){
                line.attr("stroke", "red");
                line.attr("stroke-width", "0.8px");
                this.attr("fill", "red");
             })
             txtGridLabel.mouseout(function(){
                line.attr("stroke", null);
                line.attr("stroke-width", null);
                this.attr("fill", null);
             })

             line.mouseover(function(){
                this.attr("stroke", "red");
                this.attr("stroke-width", "0.8px");
                txtGridLabel.attr("fill", "red");
             })
             line.mouseout(function(){
                this.attr("stroke", null);
                this.attr("stroke-width", null);
                txtGridLabel.attr("fill", null);
             })
           }
         } // s
         plotstart_current_group += (plot_width_per_subpanel + this.space_between_columns_within_a_dataset);
       } // for
    }
}

export default GeneSynteny