import PlotBase from "./PlotBase";
import treePlotMode from '../treeObjects/treePlotMode'
import Point from '../treePainter/Point'
import StringOps from "../utils/StringOps";
import MC from '../treeObjects/MC'

const FontStyle = {
    'NORMAL':'normal',
    'ITALIC':'italic',
    'OBLIQUE':'oblique',
    }
Object.freeze(FontStyle)

const FontWeight = {
    'NORMAL':'normal',
    'BOLD':'bold',
    'BOLDER':'bolder',
    'LIGHTER':'lighter',
    }
Object.freeze(FontWeight)

class ColumnPlots extends PlotBase{
    constructor(layer_tree_charts, disable_all_dataset, treeSVG){
        super()
        this.layer_tree_charts = layer_tree_charts
        this.disable_all_dataset = disable_all_dataset
        this.chart_plots_enabled = treeSVG.chart_plots_enabled
        this.treeSVG = treeSVG
        this.currentMostRightPosition = this.treeSVG.getLeafMostRightPosition()
        this.plotmode = treeSVG.getPlotMode()
        this.phylotree = treeSVG.getPhyloTree()
        this.LeafInternalID2NodePosisionts = this.getLeafInternalID2NodePosisionts()
        this.hmLeafID2NodePositions = this.gethmLeafID2NodePositions()
        this.pxPerHeight = this.treeSVG.pxPerHeight
        this.space_between_datasets = this.treeSVG.space_between_datasets

        this.layer_column_labels = null
        this.layer_grid = null
        this.cdCon = null
    }

    setDataSet( datasetID, data) {
        this.cdCon = data;
        this.datasetID = datasetID;
        this.setActive(true);
        this.datasetContentAsString = this.cdCon.getOriginal_datastring();
        this.opacity = this.cdCon.getOpacity();
        this.setLegendEntry(this.cdCon.getLegend());
    }

    setLeafInternalID2NodePosisionts(LeafInternalID2NodePosisionts){
        this.LeafInternalID2NodePosisionts = LeafInternalID2NodePosisionts
    }

    setRootXY(rootXY){
        this.rootXY = rootXY
    }

    setPlotMode(plotmode){
        this.plotmode = plotmode
    }

	makeOrUpdatePlot() {
        //
        this.currentMostRightPosition = this.getLeafMostRightPosition()
        if (this.active && !this.disable_all_dataset && this.chart_plots_enabled) {
            var current_start = this.currentMostRightPosition + this.space_between_datasets;
            var isCircular = this.plotmode === (treePlotMode.CIRCULAR_CLADOGRAM)
                    || this.plotmode === (treePlotMode.CIRCULAR_PHYLOGRAM);

            /**
             * decide whether show grid labels
             */
            if (this.cdCon.isShowGridLabel() && !isCircular) {
                this.layer_column_labels = this.layer_tree_charts.group()
                Point.clearAllChildNodesFromSVGGElement(this.layer_column_labels);
                this.layer_column_labels.id(MC.NAMEDCOLUMN + ": " + this.cdCon.getDatasetID() + ": column labels");
            } else {
                Point.deleteChildNodeFromLayerTree(this.layer_column_labels);
            }

            // if grid
            if (this.cdCon.isShowGridLabel() || this.cdCon.isPlotgrid()) {
                this.layer_grid = this.layer_tree_charts.group()
                Point.clearAllChildNodesFromSVGGElement(this.layer_grid);
                this.layer_grid.id(MC.NAMEDCOLUMN + ": " + this.cdCon.getDatasetID() + ": grid");
            } else {
                Point.deleteChildNodeFromLayerTree(this.layer_grid);
            }

            /**
             * add this layer to the charts / main layer
             */
            this.initLayer(this.layer_tree_charts)
            Point.clearAllChildNodesFromSVGGElement(this.layer);
            this.layer.attr("fill-opacity", (this.opacity));
            this.layer.id(MC.NAMEDCOLUMN + ": " + this.cdCon.getDatasetID());

            /**
             * get the first leaf and last leaf post
             */
            var firstleafPos = this.LeafInternalID2NodePosisionts[this.phylotree.getFirstLeafNode().getInternalID()];
            var lastleafPos = this.LeafInternalID2NodePosisionts[this.phylotree.getLastLeafNode().getInternalID()];

            var angleSpanPerHeight = this.treeSVG.angle_span / this.phylotree.getMaxVerticalLevel();

            /**
             * prepare for plotting grid ...
             */
            var p1Leaf = firstleafPos, p2Leaf = lastleafPos;
            if (this.treeSVG.getCircularModeClockwise()) {
                var tmp = p1Leaf;
                p1Leaf = p2Leaf;
                p2Leaf = tmp;
            }

            var angle1_1stleaf = (p1Leaf.angle
                    - angleSpanPerHeight * (1 + Math.log10(p1Leaf.number_leaf_decendents)) / 2);
            var angle2_lastleaf = (p2Leaf.angle
                    + angleSpanPerHeight * (1 + Math.log10(p2Leaf.number_leaf_decendents)) / 2);

            /**
             * plot data column by column
             */
            for (var ind in this.cdCon.getparsedData()) {
                var columndata = this.cdCon.getparsedData()[ind]
                /**
                 * ============================================================== first, plot
                 * grid label & grid line
                 * ==============================================================
                 */
                var labelstyle = columndata.getColumnNameData();
                if (this.cdCon.isShowGridLabel() && !isCircular) {

                    /**
                     * create a SVG text object
                     */
                    var txtGridLabel = this.layer_column_labels.text(labelstyle.getName()).move(0,0)                    

                    var textx = current_start + (labelstyle.getWidth() / 2);
                    // adjust textx according to text angle --
                    if (labelstyle.getTextangle() < 0 && labelstyle.getTextangle() > -180) {
                        textx += labelstyle.getFontsize() * 0.25;
                        txtGridLabel.attr("text-anchor", "start"); // March 31, 2015 ... align to start ...
                    } else {
                        textx -= labelstyle.getFontsize() * 0.25;
                        txtGridLabel.attr("text-anchor", "end"); // March 31, 2015 ... align to start ...
                    }

                    /**
                     * move it to the corrent position
                     */
                    var texty = (firstleafPos.pt.y - this.pxPerHeight
                            * (1 + Math.log10(this.phylotree.getFirstLeafNode().getNumberOfLeafDescendents())) / 2);
                    txtGridLabel.move((textx-5), (texty-20));

                    /**
                     * set font size, color, italic, bold
                     */
                    txtGridLabel.font({size:labelstyle.getFontsize()})
                    if (labelstyle.getColor() != ("black")) { 
                        txtGridLabel.font({fill:labelstyle.getColor()})
                    }
                    if (labelstyle.isFontitalic()) {
                        txtGridLabel.font({style:FontStyle.ITALIC})
                    }
                    if (labelstyle.isFontBold()) {
                        txtGridLabel.font({weight:FontWeight.BOLD})
                    }

                    /**
                     * make grid / vertical line --
                     */
                    txtGridLabel.transform({rotation:labelstyle.getTextangle(), cx: textx, cy: texty})
                    // append it to current layer
                } // plot column label

                /**
                 * then a grid line ---
                 */
                if (this.cdCon.isShowGridLabel() || this.cdCon.isPlotgrid()) {
                    // also get the last leaf
                    if (isCircular) {

                        var arc = this.makeArcPlot(this.rootXY,
                                current_start - this.rootXY.x + labelstyle.getWidth() / 2, angle1_1stleaf,
                                angle2_lastleaf, this.getAngleSpan(), this.layer_grid);

                        arc.attr("stroke", "darkgrey");
                        arc.attr("fill", "none");
                    } else {
                        var y1 = (firstleafPos.pt.y - this.pxPerHeight
                                * (1 + Math.log10(this.phylotree.getFirstLeafNode().getNumberOfLeafDescendents())) / 2);
                        var y2 = (lastleafPos.pt.y + this.pxPerHeight
                                * (1 + Math.log10(this.phylotree.getLastLeafNode().getNumberOfLeafDescendents())) / 2);
                        var line = this.layer_grid.line(current_start + (labelstyle.getWidth() / 2),
                        y1, current_start + (labelstyle.getWidth() / 2), y2)
                        line.attr("stroke", "darkgrey");
                    }
                }

                /**
                 * ============================================================== plot the main
                 * layer -- ==============================================================
                 */
                for (var ind in columndata.getFragments()) {
                    var fragment = columndata.getFragments()[ind]
                    var leafIDleft = fragment.getStartLeafID();
                    var leafIDright = fragment.getEndLeafID();
                    
                    var p1 = this.gethmLeafID2NodePositions().hasOwnProperty(leafIDleft)
                            ? this.gethmLeafID2NodePositions()[leafIDleft]
                            : null;
                    var p2 = this.gethmLeafID2NodePositions().hasOwnProperty(leafIDright)
                            ? this.gethmLeafID2NodePositions()[leafIDright]
                            : null;
                    // console.log(p1,p2,fragment)
                    /**
                     * case 1: both p1 and p2 are not null
                     */
                    if (p1 != null && p2 != null) {

                        /**
                         * swap p1 and p2 if both are not null and p1.vlevel > p2.vlevl
                         */
                        if (p1.verticalLevel > p2.verticalLevel) {
                            var tmp = p1;
                            p1 = p2;
                            p2 = tmp;
                        }

                        /**
                         * if circular mode
                         */
                        if (isCircular) {

                            if (this.treeSVG.getCircularModeClockwise()) {
                                var tmp = p1;
                                p1 = p2;
                                p2 = tmp;
                            }

                            /**
                             * Dec 16, 2015; get the start and end angles of current fan plot ...
                             */
                            var angle1 = (p1.angle
                                    - angleSpanPerHeight * (1 + Math.log10(p1.number_leaf_decendents)) / 2);
                            var angle2 = (p2.angle
                                    + angleSpanPerHeight * (1 + Math.log10(p2.number_leaf_decendents)) / 2);
                            var angleSpanCurrentFan = (angleSpanPerHeight
                                    * (Math.abs(p2.verticalLevel - p1.verticalLevel)
                                            + (1 + Math.log10(p1.number_leaf_decendents)) / 2
                                            + (1 + Math.log10(p2.number_leaf_decendents)) / 2));

                            // make a fan !!
                            var fan = this.makeFanPlot(this.rootXY,
                                    current_start + labelstyle.getWidth() / 2 - fragment.getWidth() / 2
                                            - this.rootXY.x,
                                    current_start + labelstyle.getWidth() / 2 + fragment.getWidth() / 2
                                            - this.rootXY.x,
                                    angle1, angle2, angleSpanCurrentFan, this.layer);

                            if (fragment.getStrokeColor().length >=1) {
                                fan.attr("stroke", fragment.getStrokeColor());
                                fan.attr("stroke-width", fragment.getStrokeWidth() + "px");
                            }
                            fan.attr("fill", fragment.getColor());
                        }
                        /**
                         * if not circular mode ...
                         */
                        else {
                            /**
                             * plot a rect between p1 and p2 rect will take all of total width
                             */
                            var y1 =  (p1.pt.y
                                    - this.pxPerHeight * (1 + Math.log10(p1.number_leaf_decendents)) / 2);
                            var y2 = (p2.pt.y
                                    + this.pxPerHeight * (1 + Math.log10(p2.number_leaf_decendents)) / 2);

                            // fine-tune y1 and y2
                            y1 += 5;
                            y2 -= 5;

                            const rect = this.layer.rect(fragment.getWidth(), y2 - y1 ).move(current_start + labelstyle.getWidth() / 2 - fragment.getWidth() / 2, y1)
                            rect.radius(fragment.getRoundedCorner(),fragment.getRoundedCorner())

                            if (fragment.getStrokeColor().length>=1) {
                                rect.attr("stroke", fragment.getStrokeColor());
                                rect.attr("stroke-width", fragment.getStrokeWidth() + "px");
                            }
                            rect.attr("fill", fragment.getColor());

                            /**
                             * if set, plot a text label
                             */
                            if (fragment.getName().length>=1) {

                                var textx = current_start + labelstyle.getWidth() / 2;
                                var texty = p1.pt.y
                                        + (p2.verticalLevel - p1.verticalLevel) * this.pxPerHeight / 2;
                                var texty2 = p1.pt.y
                                + (p2.verticalLevel - p1.verticalLevel) * this.pxPerHeight / 2;

                                if (fragment.getTextangle() <= 0 && fragment.getTextangle() > -180) {
                                    texty += fragment.getFontsize() * 0.33;
                                } else {
                                    textx -= fragment.getFontsize() * 0.33;
                                }
                                var txt = this.layer.text(fragment.getName()).move(textx, texty)                                
                                txt.attr("text-anchor", "middle");
                                txt.attr("font-size", fragment.getFontsize() + "");

                                if (fragment.getTextangle() != 0) {
                                    txt.translate(-15,0)                                    
                                    txt.transform({rotation:fragment.getTextangle(), cx:textx, cy:texty2 })
                                }
                                // console.log('i am tired,dont call me again, i am at ',textx,fragment.getName(), fragment.getStyle())
                                // layer.appendChild(txt);
                            } // if there is a label
                        } // if circular mode or not ...
                    } // if both p1 and p2 are valid ...
                    /**
                     * case 2, only p1 is not null
                     */
                    else if (p1 != null) {

                        /**
                         * plot a shape get the position of current object; NOTE: here i also consider
                         * possibilities that the column width (global) is not the same as the width of
                         * current object
                         */
                        if (!StringOps.equalsIgnoreCase( fragment.getStyle(),("none"))) {
                            var x1 = (current_start  + labelstyle.getWidth() / 2) - fragment.getWidth() / 2;
                            var y1 = p1.pt.y - (fragment.getWidth() / 2);
                            // console.log('the x1 and y1 ',x1,y1,p1,fragment.getWidth())
                            var ashape = this.treeSVG.makeAShape(fragment.getStyle(), x1, y1, fragment.getWidth(),
                                    fragment.getWidth(), fragment.getColor(), fragment.getStrokeColor(),
                                    fragment.getStrokeWidth(), p1.angle, this.layer, fragment.getRoundedCorner(),
                                    fragment.getRoundedCorner());
                            // layer.appendChild(ashape);
                        }

                        /**
                         * if set, plot a text label
                         */
                        if (fragment.getName().length>=1) {
                            // console.log('frag ahas shape and text ',fragment.getName())
                            var textx = 0, texty = 0;
                            if (isCircular) {
                                var textXY = Point.getCoordinatesOnCircle(this.rootXY,
                                        current_start - this.rootXY.x+ labelstyle.getWidth() / 2, p1.angle);
                                textx = textXY.x;
                                texty = textXY.y;
                            } else {
                                textx = current_start + labelstyle.getWidth() / 2;
                                texty = p1.pt.y;
                            }

                            var texty2 = texty;

                            if (fragment.getTextangle() <= 0 && fragment.getTextangle() > -180) {
                                texty += fragment.getFontsize() * 0.33;
                            } else {
                                textx -= fragment.getFontsize() * 0.33;
                            }

                            /**
                             * if circular mode; change to XY
                             */
                            var txt = this.layer.text(fragment.getName()).move(textx, texty-13)
                            txt.attr("text-anchor", "middle");
                            txt.attr("font-size", fragment.getFontsize() + "");
                            // console.log('i am also text ',fragment.getName())

                            if (fragment.getTextangle() != 0) {                                
                                txt.transform({rotations:fragment.getTextangle(), cx:textx, cy:texty2})
                            }
                        }
                    }
                }

                // increase realstart position
                current_start += labelstyle.getWidth() + labelstyle.getSpaceBetweenColumns();

                /**
                 * at the end of each cycle, update currentMostRightPosition; Dec 5, 2015;
                 */
                this.currentMostRightPosition += labelstyle.getWidth() + labelstyle.getSpaceBetweenColumns();
            } // for each column

            /**
             * at the very end, add additional space
             */
            this.currentMostRightPosition += this.space_between_datasets;
        } else {
            Point.deleteChildNodeFromLayerTree(this.layer);
        } // is active
    }

    makeFanPlot( center, innerRadius, outterRadius, angle1,
        angle2, angleSpan, parent_layer) {
        var dxy = Point.getCoordinatesOnCircle(center, innerRadius, angle2);
        var pxy = Point.getCoordinatesOnCircle(center, innerRadius, angle1); // xy of current node at parent circle

        var segs = new Point();
        var dxy2 = Point.getCoordinatesOnCircle(center, outterRadius, angle2);
        var pxy2 = Point.getCoordinatesOnCircle(center, outterRadius, angle1);

        segs.appendSegItem(Point.createPointStr(dxy.x, dxy.y)); // move to p1
        segs.appendSegItem(Point.createPointStr(dxy2.x, dxy2.y,'L')); // line from p1 to p2;
        segs.appendSegItem(Point.createArcStr(pxy2.x, pxy2.y, outterRadius, 0,
                angleSpan > 180 ? 1: 0, 1)); // arc from p2 to p3
        segs.appendSegItem(Point.createPointStr(pxy.x, pxy.y, 'L')); // line from p3 to p4;
        segs.appendSegItem(Point.createArcStr(dxy.x, dxy.y, innerRadius, 0,
                angleSpan > 180 ? 1: 0, 0)); // arc from p4 to p1
        // segs.appendSegItem(Point.createSVGPathSegClosePath()); // close path
        const path = parent_layer.path(segs.getSegPathListStr());
        return path;
    }

    makeArcPlot( center, radius, angle1, angle2, angleSpan, parent_layer) {
		var dxy = Point.getCoordinatesOnCircle(center, radius, angle2);
		var pxy = Point.getCoordinatesOnCircle(center, radius, angle1);

		var segs = new Point();
		segs.appendSegItem(Point.createPointStr(pxy.x, pxy.y));
		segs.appendSegItem(Point.createArcStr(dxy.x, dxy.y, radius, 0, angleSpan > 180 ? 1 : 0, 0));
        var path = parent_layer.path(segs.getSegPathListStr());
        return path;
    }
    
    getAngleSpan(){
        return this.treeSVG.getAngleSpan()
    }

    getLeafInternalID2NodePosisionts(){
        return this.treeSVG.getLeafInternalID2NodePosisionts()
    }

    gethmLeafID2NodePositions(){
        return this.treeSVG.gethmLeafID2NodePositions()
    }

    getLeafMostRightPosition(){
        return this.currentMostRightPosition
    }

    setLeafMostRightPosition(currentMostRightPosition){
        this.currentMostRightPosition = currentMostRightPosition
    }
}

export default ColumnPlots