<template>
<div>
    <div class="coverImagePan">            
        <b-container class="bv-example-row bv-example-row-flex-cols" style="width:80%;">                
            <br/>                
            <b-row>
                <b-col style="padding-top:100px">
                    <h2>You can do more with Phylogenetic trees</h2>
                    <h4>Add colourfull visualization plots and annotation datasets to your tree!!</h4>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-button style="margin:20px;width:180px" variant="warning" @click="trialLogin" pill>TRY ME</b-button>
                    <b-button style="margin:20px;width:180px;color:black;text-decoration:none" variant="success" to="/login" pill>REGISTER</b-button>
                </b-col>
            </b-row>                
        </b-container>
        <b-row style="margin-left:5px;margin-top:110px">
            <p style="font-size:1.2em;color:black;"><i>Tree source, <a href="https://peerj.com/articles/9023/" target="_blank" style="color:black">click here</a></i></p>
        </b-row>                    
    </div>
    <b-carousel
    id="carousel-fade"
    style="text-shadow: 0px 0px 2px #000"
    :interval="10000"
    fade
    controls
    indicators
    img-width="1024"
    img-height="480"
  >
    <b-carousel-slide
      caption="Drag and drop file upload"
      text-html='<p style="background-color:white;color:black;font-size:20px;font-weight:bold;line-height: 40px;padding:0 10% 0 10%;">Tree file and annotations file upload made easy with drag and drop support.To know more about file upload,check the help section <a href="https://www.evolgenius.info/help/#tree-upload" target="_blank"> View here</a><p>'
      img-src="https://www.evolgenius.info/pics/01.png"
    ></b-carousel-slide>
    <b-carousel-slide
      caption="Embed Tree anywhere"
      text-html='<p style="background-color:white;color:black;font-size:20px;font-weight:bold;line-height: 40px;padding:0 10% 0 10%;">Attach fully functional phylogenetic tree along with annotation plot to any website using the new ember tree option.To know more about embed tree,check the user help section <a href="https://www.evolgenius.info/help/#share-trees" target="_blank"> View here</a>.</p>'
      img-src="https://www.evolgenius.info/pics/02.png"
    ></b-carousel-slide>
    <b-carousel-slide
      caption="20+ annotations supports"
      text-html='<p style="background-color:white;color:black;font-size:20px;font-weight:bold;line-height: 40px;padding:0 10% 0 10%;">We support more than 20 annotational types that add additional visulization to the phylogenetic trees. To know more about annotation types,check the user help section <a href="https://www.evolgenius.info/help/#annotations" target="_blank"> View here</a>.</p>'
      img-src="https://www.evolgenius.info/pics/03.png"
    ></b-carousel-slide>
    <b-carousel-slide
      caption="Support multiple input/output formats"
      text-html='<p style="background-color:white;color:black;font-size:20px;font-weight:bold;line-height: 40px;padding:0 10% 0 10%;">We support 4 input tree file formats like newick,nexus etc.. to 8 export from formats like PNG,JPG,SVG,PDF etc,.To know more about supported file types,check the user help section <a href="https://www.evolgenius.info/help/#data-export" target="_blank"> View here</a>.</p>'
      img-src="https://www.evolgenius.info/pics/05.png"
    ></b-carousel-slide>
    <b-carousel-slide
      caption="Export Publication ready figures"
      text-html='<p style="background-color:white;color:black;font-size:20px;font-weight:bold;line-height: 40px;padding:0 10% 0 10%;">Users can edit,view tree canvas, change the size of the canvas to fit the plot and export it to publication-ready graphical formats that reproduce exactly what is shown on the canvas.</p>'
      img-src="https://www.evolgenius.info/pics/04.png"
    ></b-carousel-slide>    
    </b-carousel>
    <br>
    <h2>Usage overview</h2>
    <br><br>
    <b-container>
        <b-row style="margin-left:30px;margin-right:30px;width:100%" align-v="center">
            <b-row style="width:95%;height:300px">
                <b-col style="height:100%">
                    <GChart
                    :settings="{ packages: ['annotationchart'] }"
                    type="AnnotationChart"
                    :create-chart="drawTmChart"
                    :data="chartData"
                    :options="chartOptions"
                    @ready="onChartReady"
                    />
                </b-col>
                <b-col style="width:95%;height:100%">
                    <b-row>
                        <b-col>
                            <md-card>
                                <md-card-header>                                                
                                    <md-card-header-text>
                                    <div class="md-title">Users</div>
                                    <div class="md-subhead">{{totUsers}}</div>
                                    </md-card-header-text>        
                                </md-card-header>
                            </md-card>
                        </b-col>
                        <b-col>
                            <md-card>
                                <md-card-header>                                                
                                    <md-card-header-text>
                                    <div class="md-title">Trees</div>
                                    <div class="md-subhead">{{totTrees}}</div>
                                    </md-card-header-text>        
                                </md-card-header>
                            </md-card>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <md-card>
                                <md-card-header>                                        
                                    <md-card-header-text style="margin-right:5px">
                                    <div class="md-title">Dataset</div>
                                    <div class="md-subhead">{{totDatsets}}</div>
                                    </md-card-header-text>        
                                </md-card-header>
                            </md-card>
                        </b-col>
                        <b-col align-self="center" >
                            <h4><a href="https://evolgenius.info//evol/evolStat.php" target="_blank" style="text-decoration:none">View More</a></h4>
                            <a href="https://evolgenius.info//evol/evolStat.php" target="_blank"><font-awesome-icon icon="arrow-circle-right"  class="iconsz" style="color:blue;"></font-awesome-icon></a> 
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>                                
        </b-row>
    </b-container>
</div>
</template>

<script>
import EventBus from './EventBus'
import router from '../router'
import axios from 'axios'
import { GChart } from "vue-google-charts";

export default {
    components: {
        GChart
    },
    data() {
        return {
            server_add : this.$hostname,
            totTrees:0,
            totUsers:0,
            totDatsets:0,
            chartData: [
            ],
            chartOptions: {
                chart: {
                    title: 'Company Performance',

                }
            },
            userNotLogged:true,
        }
    },
    mounted() {
        if(localStorage.getItem("usertoken") != null){
            this.userNotLogged = false
            router.push({ name: 'ToolWindow' })
        }else{
            this.getUserStatData()
            // console.log("user login is empty")
        }
    },
    computed: {
    //   swiper() {
    //     return this.$refs.mySwiper.$swiper
    //   }
    },
    methods: {
    trialLogin(){
        var trial_dat = {}
        axios.post(this.server_add+'query/getMaxUserId',
            {
            }).then(res => {
              if(res.status == 200){
                // console.log(res)
                if(res.data.hasOwnProperty('error')){
                  console.log(res.data) 
                  this.log_check_status = res.data.error
                }else{
                  localStorage.setItem('usertoken', JSON.stringify(res.data))                  
                  router.push({ name: 'ToolWindow' })
                  this.emitMethod()
                }
              }else{
                console.log(res)
              }  
          }).catch(err => {
            console.log(err)
        })
    },
    getUserStatData(){
          //viewStat
        axios.post(this.server_add+'query/viewThreeStat',
        {            
        }).then(stat => {
            if(stat.status == 200){
                var dat = stat.data
                this.totTrees = dat.tree
                this.totUsers = dat.user
                this.totDatsets = dat.dat
            }
        }).catch(err => {
            console.log(err)
        })
    },
    drawTmChart(el, google){
        if(el === undefined){
                return
        }
        return new google.visualization.AnnotationChart(el)
    },
    onChartReady(chart, google) {
        axios.post(this.server_add+'query/viewStat',
        {            
        }).then(stat => {
            if(stat.status == 200 && this.userNotLogged){
                const data = new google.visualization.DataTable();
                var dat = stat.data

                data.addColumn('date', 'Date');
                data.addColumn('number', 'new trees'); 
                data.addColumn('string', 'n1'); 
                data.addColumn('number', 'datasets'); 
                data.addColumn('string', 'n2'); 
                data.addColumn('number', 'new users'); 
                data.addColumn('string', 'n3'); 
                data.addColumn('number', 'active users'); 
                data.addColumn('string', 'n4'); 

                dat.forEach(element => {
                    data.addRow([new Date(element[0]),element[1],'',element[2],'',element[3],'',element[4],'']);
                });
                this.chartData = data
            }
        }).catch(err => {
            console.log(err)
        })
      },
      emitMethod () {
          EventBus.$emit('logged-in', '100')
      },
      next() {
        this.$refs.flickity.next();
      },    
      previous() {
        this.$refs.flickity.previous();
      }
    },
}
</script>
<style>
.coverImagePan {
	color: #333333;
	height: 500px;
	width:100%; 
   	background-image: url(https://www.evolgenius.info/pics/tes-bg.png); /* your image */
   	background-repeat: no-repeat;
   	background-size: 100%;
    padding-top: 6em;   
}

.coverBgPan{
	background-color: 	#E6E6E6;
}
.welcomeMes{
	font-size: 24px;
	font-weight: bold;
	text-decoration: none;
	color:  rgb(15, 15, 15);    /* #185ea8; */
	margin-left: 60px;
	margin-top: 5px;
	align-content: center;
}

.welcomeMesMain{
	margin-top:-20px;
	font-size: 65px;
	font-weight: normal;
	text-decoration: none;
	color: black;
	align-content: center;
	font-family: tabitha;
    padding-top:10px;
}

.welcomHead{
	border-bottom: 2px solid #58FAAC;
	width: 170px;
    margin-top: 4px;
}

.welcomHead2{
	border-bottom: 2px solid #58FAAC;
	width: 210px;
    margin-top: 4px;
}

.cardstyle{
	text-align: center;
	width:100%;
	height:130px;
	margin-top:5px;
	margin-bottom:10px;	
	margin-right:5px;
	-webkit-box-shadow: 0 10px 6px -6px #777;
	-moz-box-shadow: 0 10px 6px -6px #777;
	box-shadow: 0 10px 6px -6px #777;
}

</style>

