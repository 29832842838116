
class GroupLabelItemAttributes{
    constructor(){
        this.startLeafID = ""
        this.endLeafID = ""
        this.lineColor = "black"
        this.textOrientation = "horizontal"
        this.textAlign = "center"
        this.fontcolor = "black"
        this.groupLabel = ""
        this.backgroundcolor = ""
        this.bDashedLine = false 
        this.fontItalic = false
        this.fontSize = 14
        this.lineWidth = 1
    }

    getStartLeafID() {
		return this.startLeafID;
	}

	setStartLeafID( startLeafID) {
		this.startLeafID = startLeafID;
	}

	getEndLeafID() {
		return this.endLeafID;
	}

	setEndLeafID( endLeafID) {
		this.endLeafID = endLeafID;
	}

	getLineColor() {
		return this.lineColor;
	}

	setLineColor( lineColor) {
		this.lineColor = lineColor;
	}

	getTextOrientation() {
		return this.textOrientation;
	}

	setTextOrientation( textOrientation) {
		this.textOrientation = textOrientation;
	}

	isbDashedLine() {
		return this.bDashedLine;
	}

	setbDashedLine( bDashedLine) {
		this.bDashedLine = bDashedLine;
	}

	setFontColor( string) {
		this.fontcolor = string;
	}
	getFontColor(){
		return this.fontcolor;
	}

	setFontItalic( b) {
		this.fontItalic = b;
	}
	
	getFontItalic(){
		return this.fontItalic;
	}

	setFontSize( fontsize) {
		this.fontSize = fontsize;
	}
	
	getFontSize(){
		return this.fontSize;
	}

	setLineWidth( line_width) {
		this.lineWidth = line_width;
	}
	
	getLineWidth(){
		return this.lineWidth;
	}

	setGroupLabel( text) {
		this.groupLabel = text;
	}
	
	getGroupLabel(){
		return this.groupLabel;
	}

	getTextAlign() {
		return this.textAlign;
	}

	setTextAlign( textAlign) {
		this.textAlign = textAlign;
	}

	setBkColor( string) {
		this.backgroundcolor = string;
	}
	
	getBkColor(){
		return this.backgroundcolor;
	}
}

export default GroupLabelItemAttributes