
class PlotBase {
    constructor(){
        this.plot_start = 0, 
        this.plotWidth = 0, 
        this.plot_end = 0, 
        this.line_width_grid = 0.3;
        this.active = false, 
        this.dbOrderSet = false;
        this.defaultColor = "black", 
        this.datasetID = "", 
        this.plottype = "rect", // the default plot type
		this.datasetContentAsString = "", // the original
		this.linestyle = "";// Nov 22, 2011
        this.layer = null
        
        this.radius = 0, 
        this.checkLineWidth = 0, 
        this.opacity = 1, // April 7, 2011;
		this.barheightPCT = 60; // Apr 29, 2013; pct space available for the bar
        this.dbOrder = 10000
        
        this.hmInternalID2RowPlot = {}
        this.legend = null
        this.chart_plots_enabled = true
        this.showLeafLabel = true
    }

    initLayer(layer_tree_charts){
        this.layer = layer_tree_charts.group().id('plot_layer')
    }

    setChartsEnabled(b){
        this.chart_plots_enabled = b
    }

    getChartsEnabled(){
        return this.chart_plots_enabled
    }

    setDBOrder( i) {
        this.dbOrder = i;
        this.dbOrderSet = true;
    }

    getDBOrder() {
        return this.dbOrder;
    }

    isDBOrderSet() {
        return this.dbOrderSet;
    }

    setLegendEntry( le) {
        this.legend = le;
    }

    getLegendEntry() {
        return this.legend;
    }

    getType() {
        return this.plottype;
    }

    getDataSetID() {
        return this.datasetID;
    }

    getDefaultColor() {
        return this.defaultColor;
    }

    getActive() {
        return this.active;
    }

    setStripType( newstriptype) {
        this.plottype = newstriptype;
    }

    setDataID( newid) {
        this.datasetID = newid;
    }

    setDefaultColor( dcolor) {
        this.defaultColor = dcolor;
    }

    setActive( status) {
        this.active = status;
        return this.active;
    }

    getOriginalUserInputAsString() {
        return this.datasetContentAsString;
    }// April 5, 2011;

    setDatasetContentAsString( s) {
        this.datasetContentAsString = s;
    }

    setOpacity( o) {
        this.opacity = o;
        if (this.opacity > 1) {
            this.opacity = 1;
        } else if (this.opacity < 0.5) {
            this.opacity = 0.5;
        }
        return this.opacity;
    }// April 7, 2011;

    getOpacity() {
        return this.opacity;
    }

    showLeafLab(bool){
        this.showLeafLabel = bool
    }

    isShowLeafLab(){
        return this.showLeafLabel
    }

    addHighlightToRowPlotByLeafInternalID(leaf_internal_id) {        
    }

    removeHighlightFromRowPlotByLeafInternalID(leaf_internal_id) {        
    }
}

export default PlotBase