import MC from '../treeObjects/MC'
const _ = require("underscore")

class DataTemplates{

    constructor(leafnames,typeOrformat){
        this.typeOrformat = typeOrformat
        this.leafnames = leafnames
        this.legendStr = ''
        this.headStr = ''
        this.bodyStr = ''
        this.seperator = "|"
        this.leafNameAr = []
        for (var entry in this.leafnames) {
            const leaf_internal_id = entry;
            if(!leaf_internal_id.startsWith("LEF")){
                this.leafNameAr.push(leaf_internal_id)
            }
        }
    }

    genLegends(){        
        let legendStr = ''
        "!Groups or !LegendText A/B/C"
        "!LegendStyle or !Style rect/circle/star"
        "!LegendColors or !Colors red/green/blue"
        "!Title or !Legend some title"
        "!ShowLegends 1/0"
    }

    genHeader(){
        let headerStr = ''
        var spacerStr = '##optional\n!seperator='+this.seperator+'\n'
        if(this.typeOrformat === MC.PIES){		
            // headerStr = spacerStr	
		}else if(this.typeOrformat === MC.BOOTSTRAPSTYLES){
            // headerStr = spacerStr
		}else if(this.typeOrformat === MC.LEAFLABELDECO){
            // headerStr = spacerStr
		}else if(this.typeOrformat === MC.LEAFBKCOLORS){
            // headerStr = spacerStr
		}else if(this.typeOrformat === MC.LEAFLABELCOLORS){
            // headerStr = spacerStr
		}else if(this.typeOrformat === MC.BRANCHECOLORS){
            // headerStr = spacerStr
		}else if(this.typeOrformat === MC.TIMELINE){
            var tem = ""
            // tem +=spacerStr
            tem += '!TimeLine\tTotalTime=70,TimeUnit=Millions of Years\n'

            tem += '!TimeLineAxis\n'    
            tem += '\tPos=Top,\n'
            tem +='\tTicks=20,10,5,\n'
            tem +='\tTickLabelStyle=10,grey,0,0,\n'
            tem +='\tGrid=0\n'

            tem +='!TimeLineStrips\n'   
            tem +='\top=1,\n'
            tem +='\tStrips=-11.67,11.67,35,58.33,81.67,\n'
            tem +='\tStripColors=#F4F5F6,#E8E9EA,\n'
            tem +='\tStripLabels=now,25,50,70,100,\n'
            tem +='\tStripLabelStyle=12,#77787B,0,0,\n'
            tem +='\tStripLabelPos=bottom,\n'
            tem +='\tStripMarginPx=3\n'
            headerStr = tem 
		}else if(this.typeOrformat === MC.BARS){
            var tem = ""
            // tem +=spacerStr
            tem +='##mandatory property\n!groups\tbad,good,maybe\n'
            tem +='##mandatory property\n!colors\tred,green,yellow\n'
            headerStr = tem
		}else if(this.typeOrformat === MC.GROUPLABELS){
            var tem = ""
            // tem +=spacerStr
            tem = "##style 1\n##!grouplabel\tcolor=pink,fontsize=14,fontcolor=black,linewidth=2\n"
            tem +="##style 2\n##!grouplabel\tstyle=2,color=pink,show=1,marginPCT=0.05,fontsize=14,fontcolor=white,fontitalic=0,textalign=middle,textorientation=horizontal,linewidth=2\n"
            tem +="##style 3\n##!grouplabel\tstyle=3,color=pink,show=1,marginPCT=0.05,fontsize=14,fontcolor=white,fontitalic=0,textalign=middle,textorientation=horizontal,linewidth=2\n"
            tem +="##style 4\n##!grouplabel\tstyle=4,color=pink,show=1,marginPCT=0.05,fontsize=14,fontcolor=white,fontitalic=0,textalign=middle,textorientation=horizontal,linewidth=2\n"
            tem +="##style 5\n##!grouplabel\tstyle=5,color=pink,show=1,marginPCT=0.05,fontsize=14,fontcolor=white,fontitalic=0,textalign=middle,textorientation=horizontal,linewidth=2\n"
            tem +="!op\t0.8\n"
            headerStr = tem            
		}else if(this.typeOrformat === MC.DOTPLOTS){
            var tem = ""
            // tem +=spacerStr
            tem +='##mandatory property\n!groups\tbad,good,maybe\n'
            tem +='##mandatory property\n!colors\tred,green,yellow\n'
            tem +="!dotplots\tshape=circle,margin=2,colwidth=30,roundedcorner=3\n"
            tem +="!showdataValue\tshow=1,fontsize=12,fontcolor=black\n"
            headerStr = tem
		}else if(this.typeOrformat === MC.HEATMAP){
            var tem = ""
            // tem +=spacerStr
            tem +="#!defaultStrokeColor\tgold\n"
            tem +="#!defaultStrokeWidth\t2\n"
            tem +="!colorgradient\tgreen, yellow,red\n"
            tem +="!colorgradientMarkLabel\t0,5,10,15,20,25\n"
            tem +="!showHeatMapColumnLabel\t1\n"
            tem +="##number of column labels should equal the columns in the data\n!heatmapColumnLabels\te1,e2,e3,control\n" 
            tem +="!heatmap\tmargin=2,colwidth=30,roundedcorner=2\n"
            tem +="!columLabelStyle\tshow=1,fontsize=20,fontitalic=0,fontbold=1,textangle=60,fontcolor=red\n"
            headerStr = tem
		}else if(this.typeOrformat === MC.GENESYTNEY){
            var tem = ""
            // tem +=spacerStr
            tem +="!groups\tg1,g2,g3\n!Colors\tred,green,blue\n"
            tem +="!genesynteny\tstyle=2,fontcolor=black,fontsize=8\n"
            tem +="!totgenes\t3\n"
            tem +="!showGeneLabel\t0\n"
            tem +="!showGeneDirection\t1\n"
            headerStr = tem
		}else if(this.typeOrformat === MC.GENETRANSFER){
            var tem = ""
            tem += "#!genetransfer_style\t1\n"
            tem += "!genetransfer_style\t2\n"
            headerStr = tem
        }else if(this.typeOrformat === MC.NAMEDCOLUMN){
            var tem = ""
            // tem +=spacerStr
            tem +="!columnplots\tstrokewidth=2,fontbold=1,fontsize=16,textangle=-45,spacebetweencolumns=15\n"
            headerStr = tem
		}else if(this.typeOrformat === MC.STRIPS){
            var tem = ""
            // tem +=spacerStr
            tem +='##mandatory parameter!!this defines the number of shapes and their order.\n!type\tstrip,rect,circle,star,check,triangle\n'
            tem +='!defaultstrokewidth\t2\n'
            tem +='##default plot width is 100\n!plotwidth\t200\n'
            tem +='##normally user-supplied colors will be recycled;this parameter now prevents color recycling.\n!colorRecycle\t1\n\n'
            headerStr = tem
		}else if(this.typeOrformat === MC.PROTEINDOMAINS){
            // headerStr = spacerStr
		}else if(this.typeOrformat === MC.INTERNALCOLLAPSES){
            // headerStr = spacerStr
		}else if(this.typeOrformat === MC.BRANCHLABELS){
            // headerStr = spacerStr
            // var tem = ""
            // tem +='!groups\tbad,good\n'
            // tem +='!colors\tred,green\n'
            // tem +='##mandatory property\n!style\tbranch_label\n'
            // headerStr = tem
		}else if(this.typeOrformat === MC.LEAFANNOTATE){
            var tem = '##default style is 1\n##style 2 is gradient based\n!leaf_annotation_style\t2\n'
            // tem +=spacerStr
            headerStr = tem
		}else if(this.typeOrformat === MC.LEAFIMAGE){
            // headerStr = spacerStr
		}else if(this.typeOrformat === MC.BRANCHMARKER){
            var tem = ""
            // tem +=spacerStr
            tem +='##mandatory property\n!groups\tbad,good\n'
            tem +='##mandatory property\n!colors\tred,green\n'
            headerStr = tem
		}else{
            console.log(this.typeOrformat)
        }
        return headerStr
    }

    genBody(){
        let bodyStr = ''
        if(this.typeOrformat === MC.PIES){			
		}else if(this.typeOrformat === MC.BOOTSTRAPSTYLES){
            bodyStr = this.bootstrapStyleTemplate()
		}else if(this.typeOrformat === MC.LEAFLABELDECO){
            bodyStr = this.leafLabDecoTemplate()
		}else if(this.typeOrformat === MC.LEAFBKCOLORS){
            bodyStr = this.leafBkColorsTemplate()
		}else if(this.typeOrformat === MC.LEAFLABELCOLORS){
            bodyStr = this.leafLabColorsTemplate()
		}else if(this.typeOrformat === MC.BRANCHECOLORS){
            bodyStr = this.branchColorsTemplate()
		}else if(this.typeOrformat === MC.TIMELINE){
            //use header section
		}else if(this.typeOrformat === MC.BARS){
            bodyStr = this.barTemplate()
		}else if(this.typeOrformat === MC.GROUPLABELS){
            bodyStr = this.groupLabelTemplate()
		}else if(this.typeOrformat === MC.DOTPLOTS){
            bodyStr = this.dotPlotTemplates()
		}else if(this.typeOrformat === MC.HEATMAP){
            bodyStr = this.heatMapTemplates()
		}else if(this.typeOrformat === MC.GENESYTNEY){
            bodyStr = this.geneSyntenyTemplate()
		}else if(this.typeOrformat === MC.NAMEDCOLUMN){
            bodyStr = this.genNamedColumn()
		}else if(this.typeOrformat === MC.STRIPS){
            bodyStr = this.stripsTemplate()
		}else if(this.typeOrformat === MC.PROTEINDOMAINS){
            bodyStr = this.protDomainTemplate()
		}else if(this.typeOrformat === MC.INTERNALCOLLAPSES){
		}else if(this.typeOrformat === MC.BRANCHLABELS){
            bodyStr = this.branchLabelTemplates()
		}else if(this.typeOrformat === MC.LEAFANNOTATE){
            bodyStr = this.leafAnnotateTemplate()
		}else if(this.typeOrformat === MC.LEAFIMAGE){
            bodyStr = this.leafImageTemplate()
		}else if(this.typeOrformat === MC.BRANCHMARKER){
            bodyStr = this.branchMarkerTemplate()
		}else if(this.typeOrformat === MC.GENETRANSFER){
            bodyStr = this.geneTransTemplate()
		}
        else{
            console.log(this.typeOrformat)
        }
        return bodyStr
    }

    pieTemplate(){

    }

    barTemplate(){
        var tempBarStr = '4,8,18'
        let wholeLis = ''
        for (var entry in this.leafnames) {
            const leaf_internal_id = entry;
            if(!leaf_internal_id.startsWith("LEF")){
                wholeLis += leaf_internal_id+"\t"+tempBarStr +"\n"
            }
        }
        return wholeLis
    }

    bootstrapStyleTemplate(){
        var tempStr = "## to hide the bootstrap values based on range\n"+
        "0,75"+"\t"+"show=0\n"+
        "## to color bootstrap values based on range\n"+
        "80,90"+"\t"+"color=red,size=12\n"+
        "100,120"+"\t"+"color=darkred,size=14\n"
        return tempStr
    }

    leafLabDecoTemplate(){
        var temAr = ["star,red","rect,red\tcheck,green","star,white:red\ttriangle,white:green","circle,white:green"]//.split("\n")

        var tempBarStr = ''
        let wholeLis = '##single decoration shape\n##double decoration shapes seperated by tab space\n##leaf_name\tshape,fill_color\n##leaf_name\tshape,fill_color:border_color\n'
        for (var entry in this.leafnames) {
            const leaf_internal_id = entry;
            if(!leaf_internal_id.startsWith("LEF")){
                tempBarStr = temAr[Math.floor((Math.random() * temAr.length-1) + 1)];
                wholeLis += leaf_internal_id+"\t"+tempBarStr +"\n"
            }
        }
        return wholeLis
    }

    leafBkColorsTemplate(){
        var temAr = ["red","grey\tad","yellow\tprefix","blue\tsuffix"]
        let wholeLis = ''
        var propStr = ''
        for (var entry in this.leafnames) {
            const leaf_internal_id = entry;
            propStr = temAr[Math.floor((Math.random() * temAr.length-1) + 1)];
            if(!leaf_internal_id.startsWith("LEF")){
                wholeLis += leaf_internal_id+"\t"+propStr +"\n"
            }
        }
        return wholeLis
    }

    leafLabColorsTemplate(){
        var temAr = ["red","grey\tad","yellow\tprefix","blue\tsuffix"]
        let wholeLis = ''
        var propStr = ''
        for (var entry in this.leafnames) {
            const leaf_internal_id = entry;
            propStr = temAr[Math.floor((Math.random() * temAr.length-1) + 1)];
            if(!leaf_internal_id.startsWith("LEF")){
                wholeLis += leaf_internal_id+"\t"+propStr +"\n"
            }
        }
        return wholeLis
    }

    branchColorsTemplate(){
        var temAr = ["red","red\ttoroot","grey\tad","yellow\tprefix","blue\tsuffix"]
        let wholeLis = ''
        var propStr = ''
        for (var entry in this.leafnames) {
            const leaf_internal_id = entry;
            propStr = temAr[Math.floor((Math.random() * temAr.length-1) + 1)];
            if(!leaf_internal_id.startsWith("LEF")){
                wholeLis += leaf_internal_id+"\t"+propStr +"\n"
            }
        }
        return wholeLis
    }

    timelineTemplate(){
        //should generate in header section
    }

    groupLabelTemplate(){
        var temAr = ["bkcolor=#404AC3,text=chicken","bkcolor=green,text=rodent,fontcolor=darkred","text=mammal"]
        let wholeLis = '##style 1\tleaf_name\ttext=mammal,color=darkgreen,textorientation=vertical,linewidth=4,fontsize=16,linestyle=dashed\n'
        wholeLis +="##style 2-5\tleaf_name\tbkcolor=#BE4144,text=mammal,textorientation=vertical,linewidth=4,fontsize=16\n"
        var propStr = ''
        for (var entry in this.leafnames) {
            const leaf_internal_id = entry;
            propStr = temAr[Math.floor((Math.random() * temAr.length-1) + 1)];
            if(!leaf_internal_id.startsWith("LEF")){
                wholeLis += leaf_internal_id+"\t"+propStr +"\n"
            }
        }
        return wholeLis
    }

    dotPlotTemplates(){
        var tempBarStr = '8,10,9'
        let wholeLis = ''
        for (var entry in this.leafnames) {
            const leaf_internal_id = entry;
            if(!leaf_internal_id.startsWith("LEF")){
                wholeLis += leaf_internal_id+"\t"+tempBarStr +"\n"
            }
        }
        return wholeLis
    }

    heatMapTemplates(){
        var tempBarStr = '8,10,9'
        let wholeLis = ''
        for (var entry in this.leafnames) {
            const leaf_internal_id = entry;
            if(!leaf_internal_id.startsWith("LEF")){
                wholeLis += leaf_internal_id+"\t"+tempBarStr +"\n"
            }
        }
        return wholeLis
    }

    geneSyntenyTemplate(){
        var temAr = ["g1:1,g2:0,g3:0","g1:1,g3:1","g2:0,g3:1","g3:2"]//.split("\n")

        var tempBarStr = ''
        let wholeLis = ''
        for (var entry in this.leafnames) {
            const leaf_internal_id = entry;
            if(!leaf_internal_id.startsWith("LEF")){
                tempBarStr = temAr[Math.floor((Math.random() * temAr.length-1) + 1)];
                wholeLis += leaf_internal_id+"\t"+tempBarStr +"\n"
            }
        }
        return wholeLis
    }

    geneTransTemplate(){
        var leafGroups = _.chunk(_.shuffle(this.leafNameAr),Math.floor((Math.random() * this.leafNameAr.length-1) + 1))
        let geneProps = ['dir=1:0,color=darkgreen,linewidth=6','dir=0:1,radius=260,color=red,linewidth=2','dir=1:1,color=black,linewidth=2','dir=1:0,color=yellow,linewidth=3']
        let wholeLis = ''
        var totGrps = 0
        leafGroups.forEach(element => {
            var fir = _.first(element)
            var las = _.last(element)
            if(fir != las){
                var prop = geneProps[Math.floor((Math.random() * geneProps.length-1) + 1)]
                wholeLis += ''+fir+':'+las+'\t'+prop+'\n'
                totGrps+=1
            }
        })
        if(totGrps == 0){
            return this.geneTransTemplate()
        }
        return wholeLis
    }


    genNamedColumn(){
        var leafGroups = _.chunk(this.leafNameAr,3)
        let wholeLis = "## a column of data starts with a 'namedcolumn'\n"+'##it defines global options for the corresponding column\n\n'
        var colorAr = ['red','blue','green','purple']
        var counter = 1
        leafGroups.forEach(element => {
            var fir = _.first(element)
            var las = _.last(element)
            var clr = colorAr[Math.floor((Math.random() * colorAr.length-1) + 1)]
            wholeLis +='namedcolumn=col'+counter+',color='+clr+':darker\n'+
            '\t## followed by the following fragments\n'
            if(element.length >= 2){            
                wholeLis +='\tfrom='+fir+',to='+las+'\n'
            }
            wholeLis +='\tfrom=D,color=white:'+clr+',text=?,width=20,style=circle,textangle=0\n\n'          
            counter++
        });
        return wholeLis
    }

    stripsTemplate(){
        var tempAr = ['red,green,blue','purple,darkred,lightgreen','lightblue','darkgreen,grey,pink','lightblue,white:lightblue','grey,darkgreen:brighter,grey:darker,pink:darker']
        var wholeLis = ''
        var propStr = ''
        for (var entry in this.leafnames) {
            const leaf_internal_id = entry;
            if(!leaf_internal_id.startsWith("LEF")){
                wholeLis += leaf_internal_id+"\t"+tempAr[Math.floor((Math.random() * tempAr.length-1) + 1)] +"\n"
            }
        }
        return wholeLis
    }

    protDomainTemplate(){
        var temAr = ['604\t397,442,Kelch_1,Pfam-A,,,PF01344,7.9e-14,61.10\t349,389,Kelch_1,Pfam-A,,,PF01344,4.7e-12,55.40\t444,489,Kelch_1,Pfam-A,,,PF01344,8.7e-12,54.60\t491,536,Kelch_1,Pfam-A,,,PF01344,2.5e-10,49.90\t310,347,Kelch_1,Pfam-A,,,PF01344,1.8e-07,40.80\t538,583,Kelch_1,Pfam-A,,,PF01344,1.8e-07,40.80\t53,160,BTB,Pfam-A,,,PF00651,1e-29,113.00\t165,267,BACK,Pfam-A,,,PF07707,2.1e-34,127.70\n',
        '625\t452,497,Kelch_1,Pfam-A,,,PF01344,2.4e-11,53.10\t405,450,Kelch_1,Pfam-A,,,PF01344,1.1e-08,44.70\t552,597,Kelch_1,Pfam-A,,,PF01344,1.4e-07,41.10\t499,549,Kelch_1,Pfam-A,,,PF01344,0.0019,27.90\t358,403,Kelch_1,Pfam-A,,,PF01344,0.0025,27.50\t65,174,BTB,Pfam-A,,,PF00651,5.8e-23,91.20\t179,281,BACK,Pfam-A,,,PF07707,8.7e-31,116.00\n',
        '652\t445,490,Kelch_1,Pfam-A,,,PF01344,5e-12,55.30\t398,443,Kelch_1,Pfam-A,,,PF01344,4.2e-08,42.80\t492,543,Kelch_1,Pfam-A,,,PF01344,2.1e-06,37.30\t350,395,Kelch_1,Pfam-A,,,PF01344,7.7e-05,32.30\t545,590,Kelch_1,Pfam-A,,,PF01344,0.0038,26.90\t314,348,Kelch_1,Pfam-A,,,PF01344,0.12,22.10\t56,165,BTB,Pfam-A,,,PF00651,1.2e-25,99.90\t170,272,BACK,Pfam-A,,,PF07707,1.2e-29,112.40\n',
        '708\t456,501,Kelch_1,Pfam-A,,,PF01344,7e-14,61.30\t550,595,Kelch_1,Pfam-A,,,PF01344,3.7e-11,52.50\t650,695,Kelch_1,Pfam-A,,,PF01344,9e-11,51.30\t597,648,Kelch_1,Pfam-A,,,PF01344,4.3e-09,45.90\t503,548,Kelch_1,Pfam-A,,,PF01344,2.4e-07,40.40\t420,454,Kelch_1,Pfam-A,,,PF01344,0.02,24.60\t163,270,BTB,Pfam-A,,,PF00651,3.7e-26,101.50\t275,376,BACK,Pfam-A,,,PF07707,8.6e-30,112.80\n',
        '657\t404,449,Kelch_1,Pfam-A,,,PF01344,9.2e-14,60.90\t498,543,Kelch_1,Pfam-A,,,PF01344,7.1e-10,48.40\t545,596,Kelch_1,Pfam-A,,,PF01344,3.5e-08,43.00\t598,641,Kelch_1,Pfam-A,,,PF01344,7.8e-08,41.90\t451,496,Kelch_1,Pfam-A,,,PF01344,9.5e-08,41.60\t368,402,Kelch_1,Pfam-A,,,PF01344,0.029,24.10\t111,218,BTB,Pfam-A,,,PF00651,6.3e-25,97.60\t223,324,BACK,Pfam-A,,,PF07707,3e-29,111.10\t1,69,Pfam-B_240589,Pfam-B,,,PB240589,,\n',
        '751\t592,640,Kelch_6,Pfam-A,,,PF13964,0.0039,27.40\t498,543,Kelch_1,Pfam-A,,,PF01344,2.2e-13,59.70\t592,637,Kelch_1,Pfam-A,,,PF01344,2.8e-10,49.80\t545,590,Kelch_1,Pfam-A,,,PF01344,7.3e-10,48.40\t692,737,Kelch_1,Pfam-A,,,PF01344,1.4e-08,44.30\t639,690,Kelch_1,Pfam-A,,,PF01344,4.5e-07,39.50\t462,496,Kelch_1,Pfam-A,,,PF01344,0.14,21.90\t205,312,BTB,Pfam-A,,,PF00651,3.2e-26,101.70\t317,418,BACK,Pfam-A,,,PF07707,2.9e-28,107.90\n']

        var wholeLis = ''
        var propStr = ''
        for (var entry in this.leafnames) {
            const leaf_internal_id = entry;
            propStr = temAr[Math.floor((Math.random() * temAr.length-1) + 1)];
            if(!leaf_internal_id.startsWith("LEF")){
                wholeLis += leaf_internal_id+"\t"+propStr +"\n"
            }
        }
        return wholeLis
    }

    branchLabelTemplates(){
        var temArProp = ['##groups_class id feild is mandatory!!\n!groups_class\tid=T1,text=bad,textalign=middle,shape=rect,bkcolor=red,color=black,fontsize=8\n',
        '!groups_class\tid=T2,text=good,textalign=middle,shape=circle,bkcolor=green,color=black,fontsize=8\n',
        '!groups_class\tid=T3,text=sooo,textalign=end,shape=rect,bkcolor=yellow,color=black\n']

        var temAr = ['T1','T2','T3']
        var wholeLis = temArProp[0]+temArProp[1]+temArProp[2]
        var propStr = ''
        for (var entry in this.leafnames) {
            const leaf_internal_id = entry;
            propStr = temAr[Math.floor((Math.random() * temAr.length-1) + 1)];
            if(!leaf_internal_id.startsWith("LEF")){
                wholeLis += leaf_internal_id+"\t"+propStr +"\n"
            }
        }
        return wholeLis
    }

    leafAnnotateTemplate(){
        var temArProp = ['!groups_class\tid=T1,text=bad,textalign=middle,shape=rect,bkcolor=red,color=black,fontsize=8\n',
        '!groups_class\tid=T2,text=good,textalign=middle,shape=circle,bkcolor=green,color=black,fontsize=8\n',
        '!groups_class\tid=T3,text=sooo,textalign=end,shape=rect,bkcolor=yellow,color=black\n']

        var temAr = ['T1','T2','T3']
        var wholeLis = temArProp[0]+temArProp[1]+temArProp[2]
        var propStr = ''
        for (var entry in this.leafnames) {
            const leaf_internal_id = entry;
            propStr = temAr[Math.floor((Math.random() * temAr.length-1) + 1)];
            if(!leaf_internal_id.startsWith("LEF")){
                wholeLis += leaf_internal_id+"\t"+propStr +"\n"
            }
        }
        return wholeLis
    }

    leafImageTemplate(){
        var srcAr = ['src=https://www.tutorialspoint.com/videotutorials/images/coding_ground_home.jpg','src=',
        'src=https://www.tutorialspoint.com/videotutorials/images/coding_ground_home.jpg']

        var tempBarStr = ''
        let wholeLis = ''
        for (var entry in this.leafnames) {
            const leaf_internal_id = entry;
            if(!leaf_internal_id.startsWith("LEF")){
                tempBarStr = srcAr[Math.floor((Math.random() * srcAr.length-1) + 1)];
                wholeLis += leaf_internal_id+"\t"+tempBarStr +"\n"
            }
        }
        return wholeLis
    }

    branchMarkerTemplate(){
        var temArProp = ['!groups_class\tid=T1,bkcolor=red,color=black,shape=star,width=20,height=20\n',
        '!groups_class\tid=T2,bkcolor=green,color=black,shape=circle,width=20,height=20\n',
        '!groups_class\tid=T3,bkcolor=red,color=black,shape=cross,width=20,height=20\n',
        '!groups_class\tid=T4,bkcolor=green,color=black,shape=check,width=15,height=15\n',
        '!groups_class\tid=T5,bkcolor=green,color=black,src=https://www.tutorialspoint.com/videotutorials/images/coding_ground_home.jpg,width=20,height=15\n'
        ]

        var temAr = ['T1','T2','T3','T4','T5']
        var wholeLis = temArProp[0]+temArProp[1]+temArProp[2]+temArProp[3]+temArProp[4]
        var propStr = ''
        for (var entry in this.leafnames) {
            const leaf_internal_id = entry;
            propStr = temAr[Math.floor((Math.random() * temAr.length-1) + 1)];
            if(!leaf_internal_id.startsWith("LEF")){
                wholeLis += leaf_internal_id+"\t"+propStr +"\n"
            }
        }
        return wholeLis
    }
}

export default DataTemplates