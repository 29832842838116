
class BranchLabelItemDefAttribute{
    constructor(){
        this.defId = ''
        this.text = ''
        this.lineColor = "black"
        this.textAlign = "middle"
        this.backgroundcolor = "lightblue"
        this.shape = "rect"
        this.lineWidth = 1
        this.bDashedLine = false
        this.drawShap = false

        this.fontcolor = "black" 
        this.fontItalic = false
        this.fontSize = 10

        this.img_src = '' //TODO set some default image
        this.image_width = 20
        this.image_height = 20

        this.defBkcolor = "lightblue"
    }

    drawShape(bool){
        this.drawShap = bool
    }

    hasShape(){
        return this.drawShap
    }

    setDefId(id){
        this.defId = id
    }

    getDefId(){
        return this.defId
    }

    setText(text){
        this.text = text
    }

    getText(){
        return this.text
    }

    setImage(src){
        if(src.length >= 1){
            this.img_src = src
        }
    }

    getImage(){
        return this.img_src
    }

    setImageWidth(width){
        this.image_width = width
    }

    getImageWidth(){
        return this.image_width
    }

    setImageHeight(height){
        this.image_height = height
    }

    getImageHeight(){
        return this.image_height
    }

    setLineColor(lineClr){
        this.lineColor = lineClr
    }

    setTextAlign(align){
        this.textAlign = align
    }

    getTextAlign(){
        return this.textAlign
    }

    setFontColor(fClr){
        this.fontcolor = fClr
    }

    setBackground(bClr){
        this.backgroundcolor = bClr
    }

    setShape(shape){
        this.shape = shape
    }

    setFontItalic(bool){
        this.fontItalic = bool
    }

    setFontSz(font){
        this.fontSize = font
    }

    setLineWidth(width){
        this.lineWidth = width
    }

    getLineWidth(){
        return this.lineWidth
    }

    getDefBkColor(){
        return this.defBkcolor
    }

    setbDashedLine(bool){
        this.bDashedLine = bool
    }

    getFontColor(){
        return this.fontcolor
    }

    getFontSize(){
        return this.fontSize
    }

    getShape(){
        return this.shape
    }

    isItalic(){
        return this.fontItalic
    }

    isBorderDashed(){
        return this.bDashedLine
    }

    getBackgroundColor(){
        return this.backgroundcolor
    }

    getLineColor(){
        return this.lineColor
    }

}

export default BranchLabelItemDefAttribute