<template>
    <div class="treebg" >
      <div class="container h-100">
        <div class="row" style="width:100%;height:100%;">
            <div class="col-md-6 mt-5 mx-auto ">
                <form v-on:submit.prevent="register">
                    <h1 class="h3 mb-3 font-weight-normal">Please Register</h1>
                    <div class="form-group">
                        <!-- <label for="email">First name(Optional)</label> -->
                        <input type="text" v-model="fName" class="form-control" name="name" placeholder="First Name(Optional)">
                    </div>
                    <div class="form-group">
                        <!-- <label for="email">Last Name(Optional)</label> -->
                        <input type="text" v-model="lName" class="form-control" name="name" placeholder="Last Name(Optional)">
                    </div>
                    <div class="form-group">
                        <!-- <label for="email">Institute name(Optional)</label> -->
                        <input type="text" v-model="insName" class="form-control" name="name" placeholder="Institute name(Optional)">
                    </div>
                    <div class="form-group">
                        <!-- <label for="email">Email Address</label> -->
                        <input type="email" v-model="email" class="form-control" name="email" placeholder="Email Address" required>
                    </div>
                    <div class="form-group">
                        <!-- <label for="password">Password</label> -->
                        <input type="password" v-model="password" class="form-control" name="email" placeholder="Enter Password" required>
                    </div>
                    <div class="form-group">
                        <!-- <label for="password">Password</label> -->
                        <input type="password" v-model="rePassword" class="form-control" name="email" :state="validateNewPass" placeholder="Re type Password" required>
                    </div>
                    <button class="btn btn-lg btn-primary btn-block" type="submit">Sign Up</button>
                </form>
            </div>
            <div class="col-md-6 mt-5 my-auto">
                <form v-on:submit.prevent="login">
                    <h1 class="h3 mb-3 font-weight-normal">Please sign in</h1>
                    <div class="form-group">
                        <!-- <label for="email">Email Address</label> -->
                        <input type="email" v-model="email" class="form-control" name="email" placeholder="Enter Email" required>
                    </div>
                    <div class="form-group">
                        <!-- <label for="password">Password</label> -->
                        <input type="password" v-model="password" class="form-control" name="email" placeholder="Enter Password" required>
                    </div>
                    <button class="btn btn-lg btn-primary btn-block" type="submit">Sign in</button>
                </form>
            </div>
        </div>
        <br>
        <br>
        <br>
        <br>
      </div>
    </div>
</template>

<script>
import axios from 'axios'
import router from '../router'
import EventBus from './EventBus'
import Commons from '../utils/CommonEvol'
const CryptoJS = require("crypto-js");

export default {
  data () {
    return {
      server_add : this.$hostname,
      fName:'',
      lName:'',
      insName:'',
      email: '',
      password: '',
      rePassword:''
    }
  },
  computed:{
      validateNewPass(){        
        return this.password === this.rePassword
      },
      bgStyle(){
        return {
          'width':'100%',
                    'height':`${window.innerHeight-50}`,          
        }
      }
  },
  methods: {
    login () {
      var encrypted = CryptoJS.AES.encrypt(this.password, "DamOpenCollegeSoon2019");
      axios.post(this.server_add+'query/login',
        {
          email: this.email,
          password: encrypted.toString()
        }).then(res => {
          if(res.status == 200){
            if(res.data.hasOwnProperty('error')){
              console.log(res.data)
            }else{
              localStorage.setItem('usertoken', JSON.stringify(res.data))
              this.email = ''
              this.password = ''
              router.push({ name: 'ToolWindow' })
              this.emitMethod() 
            }
          }else{
            console.log(res)
          }  
      }).catch(err => {
        Commons.showErrorMessage(err,'Error')
      })
    },
    register(){
      var encrypted = CryptoJS.AES.encrypt(this.password, "DamOpenCollegeSoon2019");
      axios.post(this.server_add+'query/register',
        {
          email: this.email,
          password: encrypted.toString(),
          first_name:this.fNamae,
          last_name:this.lName,
          institute:this.insName
        }).then(res => {
          if(res.status == 200){
            if(res.data.hasOwnProperty('error')){
              this.showMsgBox(res.data.error)
            }else{
              localStorage.setItem('usertoken', JSON.stringify(res.data))
              this.email = ''
              this.password = ''
              this.fNamae = ''
              this.lName = ''
              this.insName = ''
              router.push({ name: 'ToolWindow' })
              this.emitMethod() 
            }
          }else{
            console.log(res)
          }  
      }).catch(err => {
        Commons.showErrorMessage(err,'Error')
      })
    },
    emitMethod () {
      EventBus.$emit('logged-in', '100')
    },
    showMsgBox(error_msg) {
        this.boxTwo = ''
        this.$bvModal.msgBoxOk(error_msg, {
          title: 'Error',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
          .then(value => {
            // this.boxTwo = value
          })
          .catch(err => {
            // An error occurred
          })
      }
  }
}
</script>


<style>
  .vcenter {
    display: inline-block;
    vertical-align: middle;
    float: none;
  }
  .treebg{
    height: 100%;
	  width:100%; 
   	background-image: url(https://www.evolgenius.info/pics/reg-bg.png); /* your image */
   	background-repeat: no-repeat;
   	background-size: 100%;
     display: block;
     /* min-height: 100vh */
  }
</style>