import DataContainerParserBase from './DataContainerParserBase'
import StringOps from '../utils/StringOps'

class TimeLineDataContainerParser extends DataContainerParserBase{
    constructor(newdatasetid, newdataString) {
        super()

            /**
         * !TimeLine 
         * !TimeLine   TotalTime=20,TimeUnit=Million Years
         */
        this.timeLineParams = {}
        
        /**
         * timeline Axis
         * !TimeLineAxis	
         * 		Pos=Top|Bottom,
         * 		Ticks=10[,5[,1]],
         *   	TickColors=darkblue,blue,lightblue,
         *   	
         * 		TickLables=label 1,label 2,label 3,
         * 		TickLabelsStyle=12,grey,1,1,
         * 		Grid=1,
         * 		GridStyle=1,red,1
         */
        this.axisParams = {}
        
        /**
         * Timeline Lines
         * !TimeLineLines Lines=0,2,4,5,8,LineLabels=label 1,label 2, label 3,LineLabelStyle=12,black,1,1
         */
        this.lineParams = {}
        
        /**
         * TimeLine Strips
         * !TimeLineStrips    
         *       Strips=0,3,5,6,
         *       StripColors=col1,col2,col3,
         *       StripLabels=label 1,label 2,label3,
         *       StripLabelStyle=12,red,1,1,
         *       StripLabelPos=Top|Bottom,
         *       StrokeWidth=0,
         *       StrokeColor=white
         */
        this.stripsParams = {}
    
        this.setDatasetID(newdatasetid);
    	this.setOriginal_datastring(newdataString);
        
    	// by default, set it active
        this.setActive(true);
        
        var modifier2value = {};
        var currentModifier = "";
        var ar = StringOps.JsArrayStringToArrayList( StringOps.splitStringByNewLine( newdataString ) ) 
    	for( var ind in ar){
            var line = ar[ind]
    		line = line.trim();
            if (line.length>=1 && ! line.startsWith("#")) { 
                var lineparts = StringOps.JsArrayStringToArrayList( StringOps.splitStringByTab(line) ); //split by tab"\t" 
                var part1 = lineparts[0].trim();
                
                var valuePart = "";
                
                // modifiers ---------------------------------------
                if ( part1.startsWith("!")) {
                    currentModifier = part1;
                    if( lineparts.length >= 2 ){
                    	valuePart = lineparts[1].trim();
                    }
                    
                    if( !modifier2value.hasOwnProperty(currentModifier) ){
                    	modifier2value[currentModifier] =  "";
                    }
                    
                } else {
                	valuePart = part1;
//                	if( !valuePart.endsWith(",") ){
//                		valuePart += ","; // if current line does not ends with ','
//                	}
                }
                
                if(currentModifier.length>=1 && valuePart.length>=1 && modifier2value.hasOwnProperty(currentModifier) ){
                	modifier2value[currentModifier] =  modifier2value[currentModifier] + valuePart;
                }
            } // end of if line isn't empty
    	} // end of for each lines ...
    	
    	/**
    	 * now parse the user-input parameters ...
    	 */
    	for( var kv in modifier2value ){
    		var modifier = kv;
    		var value =modifier2value[kv];
    		if( StringOps.equalsIgnoreCase(modifier,"!TimeLine")){
    			this.timeLineParams = this.parseValue( value );
    		} else if ( StringOps.equalsIgnoreCase(modifier,"!TimeLineAxis") ){
    			this.axisParams = this.parseValue( value );
    		} else if ( StringOps.equalsIgnoreCase(modifier,"!TimeLineLines") ){
    			this.lineParams = this.parseValue( value );
    		} else if ( StringOps.equalsIgnoreCase(modifier,"!TimeLineStrips") ){
    			this.stripsParams = this.parseValue( value );
    		}
    	}
    	
    	/**
    	 * at the end, check if the data is valid ...
    	 */
    	if ( this.timeLineParams == null || !( this.timeLineParams.hasOwnProperty("TotalTime".toLowerCase()) && this.timeLineParams.hasOwnProperty("TimeUnit".toLowerCase()) ) ) {
            this.setError("error: modifier '!TimeLine' is mandatory");
        }
    }

    parseValue( value ){
//		Log.info("Input is :" + value );
		var kvpairs = {};
        var currentKey = "";
        var ar = StringOps.JsArrayStringToArrayList( StringOps.splitStringBySeperator(value.trim(), ",") ) 
		for (var ind in ar) {
            var kv = ar[ind]
			if( kv.includes("=") ){
				var al = StringOps.JsArrayStringToArrayList( StringOps.splitStringBySeperator(kv, "=") );
				currentKey = al[0].trim().toLowerCase();
				if( !kvpairs.hasOwnProperty( currentKey ) ){
					kvpairs[currentKey]  = [];
				}
				kvpairs[currentKey].push( al[1].trim() ); // keep case
			} else if( currentKey.length >= 1 ) {
				kvpairs[currentKey].push( kv.trim() ); // keep case ... 
			}
        }
		return kvpairs;
	}

	getAxisParams() {
		return this.axisParams;
	}

	getLineParams() {
		return this.lineParams;
	}

	getStripsParams() {
		return this.stripsParams;
	}

	getTimeLineParams() {
		return this.timeLineParams;
	}

}

export default TimeLineDataContainerParser