<template>
    <div>
        <b-row>
            <b-col>
                <b-button variant="link" @click.prevent="login" title="Social login">
                    <img src="image/social-media.png" alt="" width="40px">
                </b-button>                    
            </b-col>
        </b-row>
    </div>
</template>

<script>

export default {
    components:{

    },
    data() {
        return {
            
        }
    },
    methods: {
        login() {
            this.$auth.loginWithRedirect();
        },
        logout() {
            this.$auth.logout();
            this.$router.push({ path: "/" });
        }
    },
}
</script>
<style>

</style>