import LegendEntry from './LegendEntry'
import Rainbow from '../utils/Rainbow'
import StringOps from '../utils/StringOps'
import LegendItem from './LegendItem'
import ColorAttributes from './ColorAttributes'

class DataContainerParserBase {

    constructor( newdatasetid,  newdataString){
        this.legend = new LegendEntry()
        this.rainbow = new Rainbow()

        this.datasetID = "", 
        this.description = "", // description of this piechart data
        this.original_datastring = "",
        this.legendTitle = "", // NOTE: title will be used as title in legends, if empty, the
														// datasetID will be used instead
        this.error_messages = "", 
        this.lineStyle = "", 
        this.defaultStrokeColor = "none"
        this.showDataFontColor = "black", 
        this.showDataTextAlign = "middle" // can be middle, start, end
        this.groupLabelFontColor = "black", 
        this.groupLabelLineColor = "black", 
        this.grouplabelTextAlign = "middle",
        this.groupLabelTextOrientation = "horizontal", 
        this.groupLabelBkColor = "lightblue" // grouplabelBkground color
		this.dotPlotsShape = "circle"
        this.columnLabelFontColor = "" 

        this.branchLabelBkColor = "lightblue"
        this.branchLabelFontColor = "black"
        this.branchLabelLineColor = "black"
        this.branchLabelLinewidth = "black"
        this.branchLabelDashedLine = false
        this.branchLabelTextAlign = "middle"
        this.branchLabelFontItalic = false
        this.branchLabelFontSize = 14
            
		this.bootstrapValueStyle = "numeric" 
		this.bootstrapValueColor = "black"
        this.barRowdataReorderStyle = "no" // valid values are: no, asc, desc
        
        this.defaultSpacer = "\t"
        this.spacerTypes = ["\t","|","$"]
        this.hasNewSPacer = false
        this.newSpacer = this.defaultSpacer

        this.isUserInputOpacity = false, 
        this.valid = true, 
        this.showLegends = true, // default is true
        this.active = true, 
        this.showDomainNames = false, 
        this.showGridLabel = false, 
        this.plotgrid = false, 
        this.showShadow = false,

			/**
			 * *for strip plot ; 
			 */
		this.isStripHeightPXSet = false,

			/**
			 * *for bar plots
			 */
        this.fanplot = false, 
        this.alignindividualcolumn = false, 
        this.isItemHeightPCTProvided = false, 
		this.isItemHeightPXProvided = false,
			/**
			 * *for pie only
			 */
		this.byarea = true, // for piechart only, the user input n

			/**
			 * some boolean variables for parsing legends ...
			 */
        this.bLegendTextsParsed = false, 
        this.bLegendColorsParsed = false,

			/**
			 * specify the default position of leaf label decorations
			 */
		this.bDecoWithLeafLabel = true

			/**
			 * show data / values, default is false and is showData font
			 * italic
			 */
        this.bShowData = false, 
        this.bShowDataFontItalic = false
        this.bGroupLabelShow = true, 
        this.bGroupLabelFontItalic = false, 
        this.bGrouplabelDashedLine = false

			/**
			 * dotplots: 
			 */
        this.dotPlotsStrokeDashed = false, 
        this.dotPlotsScaleByCol = false

			/**
			 * maxvalueset, minvalueset
			 */
        this.maxValueSet = false, 
        this.minValueSet = false

        this.showDataValueColorSet = false, 
        this.valuesToHideSet = false 

        this.columnLableFontItalic = false, 
        this.columnLableFontBold = false

		this.showBootstrapValueStyle = true

		this.recyclecolor = true 
		this.logtransform = false 
        this.bHeatmapDotplotMode = false
        
        this.plotwidth = 100, // plot width by default
        this.linewidth = 0, 
        this.opacity = 1, // opacity; ranging from 0~1
        this.maxPixelRadius = 40, 
        this.minPixelRadius = 10, // use 10; April 20, 2012
		this.objectRadius = 0, // for colorful objects

	    /**
		* -- pixel / percentage height for strip
		*/
        this.stripHeightPCT = 100, 
        this.stripHeightPX = 20,

		/**
		 * for color shapes, check, default line width = 2f
		 */
		this.checklinewidth = 2,

		this.itemheightPCT = 80, // by default it will take 80% of available height ; 
		this.itemHeightPX = 10,

		this.defaultStrokeWidth = 1

        this.groupLabelLinewidth = 1, 
        this.groupLabelMargin = 0.05 
        this.valuesToHideStartValue = 0, 
        this.valuesToHideEndValue = 0 
        this.columnLabelTextAngle = -60 
        
        this.showDataFontSize = 10, 
        this.grouplabelFontSize = 14, 
        this.groupLabelStyle = 1 

        /**
         * dotplots: plotColMargin and plotColWidth will
         * be shared with heatmap ...
         */
        this.plotColMargin = 0, 
        this.plotColWidth = 20, 
        this.dotPlotsRoundedCorner = 0, 
        this.colorGradientPixelHeight = 100 
        
        this.colorGradientPixalWidth = 15 
		this.columnLabelFontSize = 0 
        this.bootstrapValueSize = 10, 
        this.bootstrapValueShowPlace = 1

        this.hmGrid2Label = {};

        //gene synteny
        this.totGenes = 0
        this.geneValuesType = 1
        this.isGeneRandColor = false
        this.showGeneName = false
        this.geneProp = {}
        this.showGeneDir = false                 

        this.geneTransStyle = '1'

        /**
         * !gridshades fill=ghostwhite,opacity=0.4
         * !shadesbetweengridlines fill=ghostwhite,opacity=0.4
         */
	    this.hmGridShadeOptions = {};
        this.arColorsAttrs = [];
        this.arLegendTexts = [], // categories (will be show in legend)
		this.arLegendShapes = [], 
		this.arColorObjectsShapes = [] 
		this.arHeatmapColumnLables = [] 
		this.arHeatmapColorGradientColors = [] 
        this.arColorGradientMarkLabels = [];

        this.leaf_annotation_style = 1
        this.showLeafLabel = true

        this.groupsDef = []
        
        this.datatype = ''
        this.setDatasetID(newdatasetid);
		this.setOriginal_datastring(newdataString);
    }

	setStripHeightPX( floatValue) {
		if (floatValue > 0) {
			this.stripHeightPX = floatValue;
			this.isStripHeightPXSet = true;
		}
	}

    getCheckLineWidth() {
		return this.checklinewidth;
    }
    
    addALegendShape(shape) {
		this.arLegendShapes.push(shape);
    }
    
	setStripHeightPCT(floatValue) {
		if (floatValue > 100) {
			floatValue = 100;
		} else if (floatValue < 1) {
			floatValue *= 100;
		}
		this.stripHeightPCT = floatValue;
    }
    
    getStripPixelHeight( availableHeightPx) {
		if (this.isStripHeightPXSet) {
			return availableHeightPx == 0 ? this.stripHeightPX
					: (this.stripHeightPX > availableHeightPx ? availableHeightPx : this.stripHeightPX);
		} else {
			var stripHeight = this.stripHeightPCT * availableHeightPx / 100;
			return availableHeightPx == 0 ? stripHeight
					: (stripHeight > availableHeightPx ? availableHeightPx : stripHeight);
		}
    }
    
    getShapePixelHeight( availableHeightPx) {
		if (this.isItemHeightPXProvided) {
			return availableHeightPx == 0 ? this.itemHeightPX
					: (this.itemHeightPX > availableHeightPx ? availableHeightPx : this.itemHeightPX);
		} else {
			var itemHeight = this.itemheightPCT * availableHeightPx / 100;
			return availableHeightPx == 0 ? this.itemHeightPX
					: (itemHeight > availableHeightPx ? availableHeightPx : itemHeight);
		}
    }
    
    /**
	 * calculate pixel item height according to available height
	 * rules: 1. by default itemHeightPX = 20 is used 2. if user input both
	 * itemHeightPX and itemHeightPCT, the larger value will be used 3. however, the
	 * larger value cannot be larger than availableHeightPx
	 * 
	 * @param availableHeightPx
	 * @return
	 */
	getItemPixelHeight(availableHeightPx) {
		if (this.isItemHeightPCTProvided) {
			var itemHeight = this.itemheightPCT * availableHeightPx / 100;
			return availableHeightPx == 0 ? this.itemHeightPX
					: (itemHeight > availableHeightPx ? availableHeightPx : itemHeight);
		} else {
			return availableHeightPx == 0 ? this.itemHeightPX
					: (this.itemHeightPX > availableHeightPx ? availableHeightPx : this.itemHeightPX);
		}
    }
    
    setItemHeightPX(floatValue) {
		if (floatValue > 0) {
			this.itemHeightPX = floatValue;
			this.isItemHeightPXProvided = true;
		}
    }
    
    setItemHeightPCT( barheightPCT) {
		if (barheightPCT > 100) {
			barheightPCT = 100;
		} else if (barheightPCT < 1) {
			barheightPCT *= 100;
		}
		this.itemheightPCT = barheightPCT;
		this.isItemHeightPCTProvided = true;
    }
    
    setDatasetID(datasetID) {
		this.datasetID = datasetID;
    }

    getDatasetID(){
        return this.datasetID
    }

    /**
	 * @return the description
	 */
	getDescription() {
		return this.description;
	}

	/**
	 * @param description
	 * the description to set
	 */
	setDescription( description) {
		this.description = description;
	}
    
    getOriginal_datastring() {
		return this.original_datastring;
	}

    setOriginal_datastring( original_datastring) {
		this.original_datastring = original_datastring;
    }
    
    getTitle() {
		return StringOps.isEmpty(this.legendTitle) ? this.datasetID : this.legendTitle;
	}

	setTitle(title) {
		this.legendTitle = title;
    }

    setGroupLabelBkColor( string) {
		this.groupLabelBkColor = string;
	}

	getGroupLabelBkColor() {
		return this.groupLabelBkColor;
	}

	setGroupLabelStyle( style) {
		this.groupLabelStyle = style;
	}

	getGroupLabelStyle() {
		return this.groupLabelStyle;
	}

	// March 23, 2014 --
	getDefaultStrokeWidth() {
		return this.defaultStrokeWidth;
    }

    getLeafAnnotateStyle(){
        return this.leaf_annotation_style
    }

    setGeneTransferStyle(style){
        this.geneTransStyle = style
    }

    getGeneTransStyle(){
        return this.geneTransStyle
    }

    parseModifier(lineparts, lineidx) {
		if (lineparts.length > 0) {
            var part1 = lineparts[0].trim().toLowerCase();
            // console.log(part1,lineparts.length)
            switch(part1){
                case "!seperator".toLowerCase():
                case "!Seperator".toLowerCase():
                    // if (lineparts.length >= 2){
                    //     if(this.spacerTypes.includes(lineparts[1].trim())){
                    //         this.newSpacer = lineparts[1].trim();
                    //         this.hasNewSPacer = true
                    //     }else{
                    //         this.hasNewSPacer = false
                    //         this.setError("line " + lineidx + ": error parsing values for " + part1 +"; unknown seperator provided!!");
                    //         this.setValid(false);
                    //     }
                    // }
                break;
                case "!LegendTexts".toLowerCase():
                case "!Groups".toLowerCase():
                case "!LegendText".toLowerCase():
                    if (lineparts.length >= 2){
                        var tem = StringOps.JsArrayStringToArrayList(StringOps.splitStringBySeperator(lineparts[1].trim(), ","))
                        this.arLegendTexts.push.apply(this.arLegendTexts,tem);
                        this.bLegendTextsParsed = true; 
                        // console.log('dcpb 317 ',part1,"!LegendText".toLowerCase(),this.arLegendTexts,tem)
                    }else {
                        this.setError("line " + lineidx + ": error parsing values for " + part1);
                        this.setValid(false);
                    }
                break;
                case "!LegendColors".toLowerCase():
                case "!Colors".toLowerCase():
                case "!legendColor".toLowerCase():
                    if (lineparts.length >= 2) {
                        var cAr = StringOps.JsArrayStringToArrayList(StringOps.splitStringBySeperator(lineparts[1].trim(), ","))
                        for (var ind in cAr) {
                            var c = cAr[ind]
                            this.arColorsAttrs.push(new ColorAttributes({'attribute':c}));
                        }
                        this.bLegendColorsParsed = true;
                    } else {
                        this.setError("line " + lineidx + ": error parsing values for " + part1);
                        this.setValid(false);
                    }
                break;
                case "!legendtitle".toLowerCase():
                case "!title".toLowerCase():
                case "!legend".toLowerCase():
                case "!legends".toLowerCase():
                    if (lineparts.length >= 2) {
                        this.legendTitle = lineparts[1].trim();
                    } else {
                        this.setError("line " + lineidx + ": error parsing values for " + part1);
                        this.setValid(false);
                    }
                break;
                case "!leaf_annotation_style".toLowerCase():
                    if(lineparts.length >= 2){
                        this.leaf_annotation_style = parseInt(lineparts[1].trim())
                    }else {
                        this.setError("line " + lineidx + ": error parsing values for " + part1);
                        this.setValid(false);
                    }
                break;
                case "!legendstyle".toLowerCase():
                case "!legendStyles".toLowerCase():
                case "!style".toLowerCase():
                case "!legendShapes".toLowerCase():
                    if (lineparts.length >= 2) {
                        var temm = StringOps.JsArrayStringToArrayList(StringOps.splitStringBySeperator(lineparts[1].trim(), ","))
                        this.arLegendShapes.push.apply(this.arLegendShapes,temm);
                    } else {
                        this.setError("line " + lineidx + ": error parsing values for " + part1);
                        this.setValid(false);
                    }
                break;
                case "!showLegends".toLowerCase():
                case "!showLegend".toLowerCase():
                    if (lineparts.length >= 2) {
                        try {
                            this.showLegends = parseInt(lineparts[1].trim()) > 0 ? true : false;
                        } catch (error) {
                            this.setError("line " + lineidx + ": error parsing value for " + part1);
                            this.setValid(false);
                        }
                    } else {
                        this.showLegends = true;
                    }
                    this.legend.setShowLegends(this.showLegends);
                break;
                case "!ShowLeafLabel".toLowerCase():
                case "!showLeafLabel".toLowerCase():
                    if (lineparts.length >= 2) {
                        try {
                            var status = parseInt(lineparts[1].trim());
                            if (status > 0) {
                                this.showLeafLabel = true
                            }else{
                                this.showLeafLabel = false
                            }
                        } catch ( error ) {
                            this.setError("line " + lineidx + ": error parsing value for " + part1);
                            this.setValid(false);
                        }
                    }else{
                        this.setError("line " + lineidx + ": error parsing value for " + part1);
                        this.setValid(false);
                    }
                break;
                case "!showData".toLowerCase():
                case "!showValue".toLowerCase():
                case "!showValues".toLowerCase():
                    if (lineparts.length >= 2) {
                        try {
                            this.bShowData = parseInt(lineparts[1].trim()) > 0 ? true : false;
                        } catch (error) {
                            this.setError("line " + lineidx + ": error parsing value for " + part1);
                            this.setValid(false);
                        }
                    } else {
                        this.bShowData = true;
                    }
                break;
                case "!showDataFontSize".toLowerCase():
                case "!showValueFontSize".toLowerCase():
                case "!showValuesFontSize".toLowerCase():
                    if (lineparts.length >= 2) {
                        try {
                            var fontsize = parseInt(lineparts[1].trim());
                            if (fontsize > 0) {
                                this.setShowDataFontSize(fontsize);
                            }
                        } catch ( error ) {
                            this.setError("line " + lineidx + ": error parsing value for " + part1);
                            this.setValid(false);
                        }
                    }
                break;
                case "!showDataFontItalic".toLowerCase():
                case "!showValueFontItalic".toLowerCase():
                case "!showValuesFontItalic".toLowerCase():
                    if (lineparts.length >= 2) {
                        try {
                            this.bShowDataFontItalic = parseInt(lineparts[1].trim()) > 0 ? true : false;
                        } catch (error) {
                            this.setError("line " + lineidx + ": error parsing value for " + part1);
                            this.setValid(false);
                        }
                    } else {
                        this.bShowDataFontItalic = true;
                    }
                break;
                case "!showDataFontColor".toLowerCase():
                case "!showValueFontColor".toLowerCase():
                case "!showValuesFontColor".toLowerCase():
                    if (lineparts.length >= 2) {
                        this.setShowDataFontColor(lineparts[1].trim());
                        this.setShowDataValueColorSet(true);
                    }
                break;
                case "!showDataTextAlign".toLowerCase():
                case "!showValueTextAlign".toLowerCase():
                case "!showValuesTextAlign".toLowerCase():
                    if (lineparts.length >= 2) {
                        var textalign = lineparts[1].trim();
                        if (StringOps.equalsIgnoreCase(textalign,"middle") || StringOps.equalsIgnoreCase(textalign,"start")
                                || StringOps.equalsIgnoreCase(textalign,"end")) {
                            this.showDataTextAlign = textalign;
                        }
                    }
                break;
                case '!showDataValue'.toLowerCase():                    
                    if (lineparts.length >= 2) {
                        var kvs = this.splitKeyValuePairs(lineparts[1].trim());

                        // show or not --
                        if (kvs.hasOwnProperty("show")) {
                            try {
                                this.bShowData = parseInt(kvs["show"]) > 0 ? true : false;
                            } catch (e) {
                                this.bShowData = true; // default is true
                            }
                        }
                        // font size // text size
                        if (kvs.hasOwnProperty("fontsize") || kvs.hasOwnProperty("textsize")) {
                            try {
                                var text = kvs.hasOwnProperty("fontsize") ? kvs["fontsize"] : kvs["textsize"];
                                this.setShowDataFontSize(parseInt(text));
                            } catch (e) {
                            }
                        }

                        // font italic / textitalic
                        if (kvs.hasOwnProperty("fontitalic") || kvs.hasOwnProperty("textitalic")) {
                            try {
                                var text = kvs.hasOwnProperty("fontitalic") ? kvs["fontitalic"] : kvs["textitalic"];
                                this.bShowDataFontItalic = parseInt(text) > 0 ? true : false;
                            } catch (e) {
                                this.bShowDataFontItalic = false; // default is true
                            }
                        }

                        // fontcolor / textcolor
                        if (kvs.hasOwnProperty("fontcolor") || kvs.hasOwnProperty("textcolor")) {
                            this.setShowDataFontColor(
                                    kvs.hasOwnProperty("fontcolor") ? kvs["fontcolor"] : kvs["textcolor"]);
                            this.setShowDataValueColorSet(true);
                        }

                        // text align / font align
                        if (kvs.hasOwnProperty("textalign") || kvs.hasOwnProperty("fontalign")) {
                            var textalign = kvs.hasOwnProperty("textalign") ? kvs["textalign"] : kvs["fontalign"];
                            if (StringOps.equalsIgnoreCase(textalign,"middle") || StringOps.equalsIgnoreCase(textalign,"start")
                                || StringOps.equalsIgnoreCase(textalign,"end")) {
                                this.showDataTextAlign = textalign;
                            }
                        } //

                        /**
                         * values to hide ; Nov 28, 2015 ... valuestohide=value1[:value2]
                         */
                        if (kvs.hasOwnProperty("valuestohide") || kvs.hasOwnProperty("valuetohide")) {
                            var values = kvs.hasOwnProperty("valuestohide") ? kvs["valuestohide"]
                                    : kvs["valuetohide"];
                            var vs = StringOps.JsArrayStringToArrayList(StringOps.splitStringBySeperator(values, ":"));
                            if (vs.length >= 1) {
                                try {
                                    var value1 = parseFloat(vs[0].trim());
                                    this.setValuesToHideSet(true); // this indicates that values to hide values are set
                                    this.setValuesToHideStartValue(value1);

                                    if (vs.length == 1) {
                                        this.setValuesToHideEndValue(value1); // if only one value is set, set end value ==
                                                                                // start value
                                    } else if (vs.length >= 2) {
                                        var value2 = parseFloat(vs[1].trim());

                                        // here check the relative values of value1 and value2 to make sure that value1
                                        // <= value2
                                        if (value1 > value2) {
                                            var tmp = value1;
                                            value1 = value2;
                                            value2 = tmp;
                                        }

                                        // set both value1 and value2
                                        this.setValuesToHideStartValue(value1);
                                        this.setValuesToHideEndValue(value2);
                                    }
                                } catch ( e) {
                                }
                            } // if available ...
                        }
                    }
                break;
                case "!bootstrapValueStyle".toLowerCase():
                case "!bootstrapValueStyles".toLowerCase():
                    if (lineparts.length >= 2) {
                        var kvs = this.splitKeyValuePairs(lineparts[1].trim());
                        // show=1|0,style=numeric|shape,size=10,place=1|2,shape=rect,color=color[:color]
                        if (kvs.hasOwnProperty("show")) {
                            this.setShowBootstrapValueStyle(kvs["show"] != ("0"));
                        }
                        // console.log(this.datasetID,kvs,this.showBootstrapValueStyle)

                        // style --
                        if (kvs.hasOwnProperty("style")) {
                            this.setBootstrapValueStyle(kvs["style"]);
                        }

                        // size
                        if (kvs.hasOwnProperty("size")) {
                            try {
                                this.setBootstrapValueSize(parseInt(kvs["size"]));
                            } catch ( error ) {

                            }
                        }

                        // place
                        if (kvs.hasOwnProperty("place")) {
                            try {
                                this.setBootstrapValueShowPlace(parseInt(kvs["place"]));
                            } catch ( error ) {

                            }
                        }

                        // fill_color and stroke_color
                        if (kvs.hasOwnProperty("color")) {
                            var es = StringOps
                                    .JsArrayStringToArrayList(StringOps.splitStringBySeperator(kvs["color"], ","));

                            var length = es.length;
                            if (length >= 1) {
                                this.setBootstrapValueColor(es[0].trim());
                            }
                            if (length >= 2) {
                                this.setDefaultStrokeColor(es[1].trim());
                            }
                        }

                        // strokewidth
                        if (kvs.hasOwnProperty("strokewidth")) {
                            try {
                                this.defaultStrokeWidth = parseFloat(kvs["strokewidth"]);
                            } catch ( error ) {
                            }
                        }
                    } //
                break;
                case "!scalebycol".toLowerCase():
                case "!scalingbycol".toLowerCase():
                    if (lineparts.length >= 2) {
                        try {
                            this.dotPlotsScaleByCol = parseInt(lineparts[1].trim()) > 0 ? true : false;
                        } catch (error) {
                            this.setError("line " + lineidx + ": error parsing value for " + part1);
                            this.setValid(false);
                        }
                    } else {
                        this.dotPlotsScaleByCol = true;
                    }
                break;
                case "!dotplots".toLowerCase():
                case "!dotplot".toLowerCase():
                    if (lineparts.length >= 2) {
                        var kvs = this.splitKeyValuePairs(lineparts[1].trim());

                        // style / shape
                        if (kvs.hasOwnProperty("shape")) {
                            var shape = kvs["shape"];
                            if (shape === ("circle") || shape === ("rect")) {
                                this.setDotPlotsShape(shape);
                            }
                        }

                        // margin
                        if (kvs.hasOwnProperty("margin")) {
                            if (lineparts.length >= 2) {
                                try {
                                    var margin = parseInt(kvs["margin"]);
                                    if (margin < 0) {
                                        margin = 0;
                                    }
                                    this.setPlotColMargin(margin);
                                } catch (error) {
                                    this.setError("line " + lineidx + ": error parsing value for " + part1);
                                    this.setValid(false);
                                }
                            }
                        }

                        // col width
                        if (kvs.hasOwnProperty("colwidth")) {
                            if (lineparts.length >= 2) {
                                try {
                                    this.setPlotColWidth(parseInt(kvs["colwidth"]));
                                } catch (error) {
                                    this.setError("line " + lineidx + ": error parsing value for " + part1);
                                    this.setValid(false);
                                }
                            }
                        }

                        // rounded corner --
                        if (kvs.hasOwnProperty("roundedcorner")) {
                            if (lineparts.length >= 2) {
                                try {
                                    this.setRectRoundedCorner(parseInt(kvs["roundedcorner"]));
                                } catch (error) {
                                    this.setError("line " + lineidx + ": error parsing value for " + part1);
                                    this.setValid(false);
                                }
                            }
                        }
                    }
                break;
                case "!dataMatrixColumLabelStyle".toLowerCase():
                case "!ColumLabelStyle".toLowerCase():
                case "!dataMatrixColumnLabelStyle".toLowerCase():
                case "!ColumnLabelStyle".toLowerCase():
                    if (lineparts.length >= 2) {
                        var kvs = this.splitKeyValuePairs(lineparts[1]);
                        // show=1,fontsize=14,color=black, fontitalic=1,fontbold=1,textangle=-60
                        if (kvs.hasOwnProperty("show")) {
                            try {
                                this.setShowGridLabel(kvs["show"] != ("0"));
                            } catch (e) {
                            }
                        }
                        if (kvs.hasOwnProperty("textangle")) {
                            try {
                                this.setColumnLabelTextAngle(parseFloat(kvs["textangle"]));
                            } catch (e) {
                            }
                        }
                        if (kvs.hasOwnProperty("fontsize")) {
                            try {
                                this.setColumnLabelFontSize(parseInt(kvs["fontsize"]));
                            } catch (e) {
    
                            }
                        }
                        // fontcolor
                        if (kvs.hasOwnProperty("color") || kvs.hasOwnProperty("fontcolor")) {
                            this.setColumnLabelFontColor(
                                    kvs.hasOwnProperty("color") ? kvs["color"] : kvs["fontcolor"]);
                        }
                        // font italic
                        if (kvs.hasOwnProperty("fontitalic")) {
                            try {
                                this.setColumnLableFontItalic(kvs["fontitalic"] != ("0"));
                            } catch ( e) {
                            }
                        }
                        // font bold
                        if (kvs.hasOwnProperty("fontbold")) {
                            try {
                                this.setColumnLableFontBold(kvs["fontbold"] != ("0"));
                            } catch ( error ) {
                            }
                        }
                    }
                break;
                case "!heatmap".toLowerCase():
                case "!heatmaps".toLowerCase():
                    if (lineparts.length >= 2) {
                        var kvs = this.splitKeyValuePairs(lineparts[1].trim());
    
                        // col margin ; march 23, 2015 --
                        if (kvs.hasOwnProperty("margin")) {
                            try {
                                this.setPlotColMargin(parseInt(kvs["margin"]));
                            } catch ( e) {
                                this.setPlotColMargin(0);
                            }
                        } else {
                            this.setPlotColMargin(0);
                        }
    
                        // colwidth = 20
                        if (kvs.hasOwnProperty("colwidth")) {
                            try {
                                this.setPlotColWidth(parseInt(kvs["colwidth"]));
                            } catch (e) {
                                this.setPlotColWidth(0);
                            }
                        } else {
                            this.setPlotColWidth(0);
                        }
    
                        //
                        if (kvs.hasOwnProperty("roundedcorner")) {
                            try {
                                this.setRectRoundedCorner(parseInt(kvs["roundedcorner"]));
                            } catch (e) {
                                this.setError("line " + lineidx + ": error parsing value for " + part1);
                                this.setValid(false);
                            }
                        }
                        // dot plot mode; Feb 23, 2018;
                        if (kvs.hasOwnProperty("dotplotmode")) {
                            this.setbHeatmapDotplotMode( kvs["dotplotmode"] != ("0") );
                        }
                        
                        // dot plot shape, default is circle, can be rect --
                        if (kvs.hasOwnProperty("dotplotshape")) {
                            var shape = kvs["dotplotshape"];
                            if (shape === ("circle") || shape === ("rect")) {
                                this.setDotPlotsShape(shape);
                            }
                        }
                    }
                break;
                case "!colorgradient".toLowerCase():
                case "!colorspectrum".toLowerCase():
                    if (lineparts.length >= 2) {
                        try {
                            var cs = [];
                            var tem = StringOps.JsArrayStringToArrayList(
                                StringOps.splitStringBySeperator(lineparts[1].trim(), ","))
                            for (var ind in tem) {
                                var s = tem[ind]
                                cs.push(s.trim());
                            }
    
                            this.setArHeatmapColorGradientColors(cs);
    
                            var css = [];
                            var idx_color = 0;
                            for (var ind in cs) {
                                var s = cs[ind]
                                css.push(s);
                            }
                            this.getRainbow().setSpectrum(css);
    
                            if (cs.length <= 1) {
                                this.setError("line " + lineidx + ": error, at least two colors should be specified for '"
                                        + part1 + "'");
                                this.setValid(false);
                            }
    
                        } catch (e) {
                            this.setError("line " + lineidx + ": error, invalid color name(s) found in " + part1 + "\t'"
                                    + lineparts[1] + "'\n\tor at least two valid colors should be specified!!");
                            this.setValid(false);
                            e.printStackTrace();
                        }
                        // catch (InvalidColourException e) {
                        //     this.setError("line " + lineidx + ": error, invalid color name(s) found in " + part1 + "\t'"
                        //             + lineparts[1] + "'\n\tor at least two valid colors should be specified!!");
                        //     this.setValid(false);
                        //     e.printStackTrace();
                        // }
                    } else {
                        this.setError("line " + lineidx + ": error, please provide colors for " + part1);
                        this.setValid(false);
                    }
                break;
                case "!heatmapColumnLabels".toLowerCase():
                case "!heatmapColumnLabel".toLowerCase():
                    if (lineparts.length >= 2) {
                        this.arHeatmapColumnLables = [];
                        var tem = StringOps.JsArrayStringToArrayList(StringOps.splitStringBySeperator(lineparts[1].trim(), ","))
                        for (var ind in tem) {
                            var s = tem[ind]
                            this.arHeatmapColumnLables.push(s.trim());
                        }
                    } else {
                        this.setError("line " + lineidx + ": error parsing " + part1);
                    }
                break;
                case "!colorgradientMarkLabel".toLowerCase():
                case "!colorgradientMarkLabels".toLowerCase():
                    if (lineparts.length >= 2) {
                        this.arColorGradientMarkLabels = [];
                        var tem = StringOps
                        .JsArrayStringToArrayList(StringOps.splitStringBySeperator(lineparts[1].trim(), ","))
                        for (var ind in tem) {
                            var s = tem[ind]
                            this.arColorGradientMarkLabels.push(parseFloat(s.trim()));
                        }
                        // if(this.arColorGradientMarkLabels.length>=1){
						// 	this.setShowGridLabel(true);
                        // }
                    } else {
                        this.setError("line " + lineidx + ": error parsing " + part1);
                    }
                break;
                case "!colorGradientPixelHeight".toLowerCase():
                case "!colorGradientHeight".toLowerCase():
                    if (lineparts.length >= 2) {
                        this.setColorGradientPixelHeight(parseInt(lineparts[1].trim()));
                    } else {
                        this.setError("line " + lineidx + ": error parsing " + part1);
                    }
                break;
                case "!colorGradientPixelWidth".toLowerCase():
                case "!colorGradientWidth".toLowerCase():
                    if (lineparts.length >= 2) {
                        this.setColorGradientPixelWidth(parseInt(lineparts[1].trim()));
                    } else {
                        this.setError("line " + lineidx + ": error parsing " + part1);
                    }
                break;
                case "!colmargin".toLowerCase():
                case "!columnmargin".toLowerCase():
                    if (lineparts.length >= 2) {
                        try {
                            this.setPlotColMargin(parseInt(lineparts[1].trim()));
                        } catch (error) {
                            this.setPlotColMargin(0);
                        }
                    } else {
                        this.setPlotColMargin(0);
                    }
                break;
                case "!colwidth".toLowerCase():
                case "!columnwidth".toLowerCase():
                    if (lineparts.length >= 2) {
                        try {
                            this.setPlotColWidth(parseInt(lineparts[1].trim()));
                        } catch (error) {
                            this.setPlotColWidth(20);
                        }
                    } else {
                        this.setPlotColWidth(20);
                    }
                break;
                case "!grouplabel".toLowerCase():
                case "!grouplabels".toLowerCase():
                    if (lineparts.length >= 2) {
                        var kvs = this.splitKeyValuePairs(lineparts[1].trim());
    
                        // style ; march 23, 2015 --
                        if (kvs.hasOwnProperty("style")) {
                            try {
                                this.setGroupLabelStyle(parseInt(kvs["style"]));
                            } catch (error) {
                                this.setGroupLabelStyle(1);
                            }
                        } else {
                            this.setGroupLabelStyle(1);
                        }
    
                        // margin ; march 23, 2015 --
                        if (kvs.hasOwnProperty("marginpct")) {
                            try {
                                this.setGroupLabelMargin(parseFloat(kvs["marginpct"]));
                            } catch (error) {
                                // default is 2
                            }
                        } else {
                            this.setGroupLabelMargin(0.05);
                        }
    
                        // march 23, 2015 ; group label background color
                        if (kvs.hasOwnProperty("backgroundcolor")) {
                            this.setGroupLabelBkColor(kvs["backgroundcolor"]);
                        }
    
                        if (kvs.hasOwnProperty("bkcolor")) {
                            this.setGroupLabelBkColor(kvs["bkcolor"]);
                        }
    
                        // show or not
                        if (kvs.hasOwnProperty("show")) {
                            try {
                                this.bGroupLabelShow = parseInt(kvs["show"]) > 0 ? true : false;
                            } catch (error) {
                                this.bGroupLabelShow = true; // default is true
                            }
                        }
    
                        // color will be applied to text and line
                        if (kvs.hasOwnProperty("color")) {
                            this.setGroupLabelFontColor(kvs["color"]);
                            this.setGroupLabelLineColor(kvs["color"]);
                        }
    
                        // font color / text color
                        if (kvs.hasOwnProperty("fontcolor") || kvs.hasOwnProperty("textcolor")) {
                            this.setGroupLabelFontColor(
                                    kvs.hasOwnProperty("fontcolor") ? kvs["fontcolor"] : kvs["textcolor"]);
                        }
    
                        // font italic / text italic
                        if (kvs.hasOwnProperty("fontitalic") || kvs.hasOwnProperty("textitalic")) {
                            try {
                                var text = kvs.hasOwnProperty("fontitalic") ? kvs["fontitalic"] : kvs["textitalic"];
                                this.bGroupLabelFontItalic = parseInt(text) > 0 ? true : false;
                            } catch (error) {
                                this.bGroupLabelFontItalic = true; // default is true
                            }
                        }
    
                        // font fontsize / text size
                        if (kvs.hasOwnProperty("fontsize") || kvs.hasOwnProperty("textsize")) {
                            try {
                                var text = kvs.hasOwnProperty("fontsize") ? kvs["fontsize"] : kvs["textsize"];
                                this.setGrouplabelFontSize(parseInt(text));
                            } catch (error) {
                            }
                        }
    
                        // line color
                        if (kvs.hasOwnProperty("linecolor")) {
                            this.setGroupLabelLineColor(kvs["linecolor"]);
                        }
                        // line width
                        if (kvs.hasOwnProperty("linewidth")) {
                            try {
                                this.setGroupLabelLinewidth(parseFloat(kvs["linewidth"]));
                            } catch (error) {
                            }
                        }
                        // dashed line
                        if (kvs.hasOwnProperty("linestyle")) {
                            this.setbGrouplabelDashedLine(StringOps.equalsIgnoreCase(kvs["linestyle"],("dashed")));
                        }
    
                        // text align / font align
                        if (kvs.hasOwnProperty("textalign") || kvs.hasOwnProperty("fontalign")) {
                            var textalign = kvs.hasOwnProperty("textalign") ? kvs["textalign"] : kvs["fontalign"];
                            if (StringOps.equalsIgnoreCase(textalign,("middle")) || StringOps.equalsIgnoreCase(textalign,("start"))
                                    || StringOps.equalsIgnoreCase(textalign,("end"))) {
                                this.grouplabelTextAlign = textalign.toLowerCase();
                            }
                        }
    
                        // text orientation // font orientation
                        if (kvs.hasOwnProperty("textorientation") || kvs.hasOwnProperty("fontorientation")) {
                            var textorientation = kvs.hasOwnProperty("textorientation") ? kvs["textorientation"]
                                    : kvs["fontorientation"];
                            if(StringOps.equalsIgnoreCase(textorientation,("vertical")) || StringOps.equalsIgnoreCase(textorientation,("horizontal"))){
                                this.setGroupLabelTextOrientation(textorientation.toLowerCase());
                            }
                        }
                    }
                break;
                case "!stripHeightPCT".toLowerCase():
                    try {
                        this.setStripHeightPCT(parseFloat(lineparts[1].trim()));
                    } catch (error) {
                        this.setError("line " + lineidx + ": error parsing value for strip height (!stripHeightPCT);");
                    }
                break;
                case "!branchLabel".toLowerCase():
                    if (lineparts.length >= 2) { 
                        var kvs = this.splitKeyValuePairs(lineparts[1].trim());
    
                        // group label background color
                        if (kvs.hasOwnProperty("backgroundcolor")) {
                            this.setBranchLabelBkColor(kvs["backgroundcolor"]);
                        }
    
                        if (kvs.hasOwnProperty("bkcolor")) {
                            this.setBranchLabelBkColor(kvs["bkcolor"]);
                        }
    
                        // color will be applied to text and line
                        if (kvs.hasOwnProperty("color")) {
                            this.setBranchLabelFontColor(kvs["color"]);
                            this.setBranchLabelLineColor(kvs["color"]);
                        }
    
                        // font color / text color
                        if (kvs.hasOwnProperty("fontcolor") || kvs.hasOwnProperty("textcolor")) {
                            this.setBranchLabelFontColor(
                                    kvs.hasOwnProperty("fontcolor") ? kvs["fontcolor"] : kvs["textcolor"]);
                        }
    
                        // font italic / text italic
                        if (kvs.hasOwnProperty("fontitalic") || kvs.hasOwnProperty("textitalic")) {
                            try {
                                var text = kvs.hasOwnProperty("fontitalic") ? kvs["fontitalic"] : kvs["textitalic"];
                                this.branchLabelFontItalic = parseInt(text) > 0 ? true : false;
                            } catch (error) {
                                this.branchLabelFontItalic = true; // default is true
                            }
                        }
    
                        // font fontsize / text size
                        if (kvs.hasOwnProperty("fontsize") || kvs.hasOwnProperty("textsize")) {
                            try {
                                var text = kvs.hasOwnProperty("fontsize") ? kvs["fontsize"] : kvs["textsize"];
                                this.setBranchLabelFontSize(parseInt(text));
                            } catch (error) {
                            }
                        }
    
                        // line color
                        if (kvs.hasOwnProperty("linecolor")) {
                            this.setBranchLabelLineColor(kvs["linecolor"]);
                        }
                        // line width
                        if (kvs.hasOwnProperty("linewidth")) {
                            try {
                                this.setBranchLabelLinewidth(parseFloat(kvs["linewidth"]));
                            } catch (error) {
                            }
                        }
                        // dashed line
                        if (kvs.hasOwnProperty("linestyle")) {
                            this.setBranchLabelDashedLine(StringOps.equalsIgnoreCase(kvs["linestyle"],("dashed")));
                        }
    
                        // text align / font align
                        if (kvs.hasOwnProperty("textalign") || kvs.hasOwnProperty("fontalign")) {
                            var textalign = kvs.hasOwnProperty("textalign") ? kvs["textalign"] : kvs["fontalign"];
                            if (StringOps.equalsIgnoreCase(textalign,("middle")) || StringOps.equalsIgnoreCase(textalign,("start"))
                                    || StringOps.equalsIgnoreCase(textalign,("end"))) {
                                this.branchLabelTextAlign = textalign.toLowerCase();
                            }
                        }
                    }
                break;
                case "!stripHeightPX".toLowerCase():
                    try {
                        this.setStripHeightPX(parseFloat(lineparts[1].trim()));
                    } catch (error) {
                        this.setError("line " + lineidx + ": error parsing value for strip height (!stripHeightPX);");
                    }
                break;
                case "!recyclecolor".toLowerCase():
                case "!colorrecycle".toLowerCase():
                    this.setRecyclecolor(!lineparts[1].trim() === ("0"));
                break;
                case "!logtransform".toLowerCase():
                    this.setLogtransform(!lineparts[1].trim() === ("0"));
                break;
                case "!RowdataReorder".toLowerCase():
                    this.setBarRowdataReorderStyle("no");
                    if (lineparts.length >= 2) {
                        var part2 = lineparts[1].trim();
                        if (StringOps.equalsIgnoreCase(part2,("asc"))) {
                            this.setBarRowdataReorderStyle("asc");
                        } else if (StringOps.equalsIgnoreCase(part2,("desc"))) {
                            this.setBarRowdataReorderStyle("desc");
                        }
                    }
                break;
                case "!plotwidth".toLowerCase():
                case "!width".toLowerCase():
                    try {
                        this.plotwidth = parseFloat(lineparts[1].trim());
                    } catch (error) {
                        this.setError("line " + lineidx + ": error parsing float value for plotwidth!!");
                        this.setValid(false);
                    }
                break;
                case "!itemHeightPCT".toLowerCase():
                case "!barHeightPCT".toLowerCase():
                    try {
                        this.setItemHeightPCT(parseFloat(lineparts[1].trim()));
                    } catch (error) {
                        this.setError("line " + lineidx
                                + ": error parsing value for height of item / bar / protein-domain (!itemHeightPCT)");
                    }
                break;
                case "!itemHeightPX".toLowerCase():
                case "!barHeightPX".toLowerCase():
                case "!plotheight".toLowerCase():
                case "!height".toLowerCase():
                    try {
                        this.setItemHeightPX(parseFloat(lineparts[1].trim()));
                    } catch (error) {
                        this.setError("line " + lineidx
                                + ": error parsing value for height of item / bar / protein-domain (!itemHeightPX)");
                    }
                break;
                case "!opacity".toLowerCase():
                case "!op".toLowerCase():
                    try {
                        this.opacity = parseFloat(lineparts[1].trim());
                        this.setIsUserInputOpacity(true);
                    } catch (error) {
                        this.setError("line " + lineidx + ": error parsing value for opacity;");
                        this.setValid(false);
                    }
                break;
                case "!showshadow".toLowerCase():
                case "!shadow".toLowerCase():
                    if (lineparts.length >= 2) {
                        try {
                            this.showShadow = parseInt(lineparts[1].trim()) > 0 ? true : false;
                            this.setIsUserInputOpacity(true);
                        } catch (error) {
                            this.setError("line " + lineidx + ": error parsing value for showShadow;");
                        }
                    } else {
                        this.showShadow = true;
                    }
                break;
                case "!linestyle".toLowerCase():
                case "!lty".toLowerCase():
                    try {
                        this.lineStyle = lineparts[1].trim();
                    } catch (error) {
                        this.setError("line " + lineidx + ": error parsing value for line style;");
                        this.setValid(false);
                    }
                break;
                case "!grid".toLowerCase():
                case "!showGrid".toLowerCase():
                    if (lineparts.length >= 2) {
                        try {
                            this.plotgrid = parseInt(lineparts[1].trim()) > 0 ? true : false;
                        } catch (error) {
                            this.setError("line " + lineidx + ": error parsing value for showGrid;");
                        }
                    } else {
                        this.plotgrid = true;
                    }
                break;
                case "!axis".toLowerCase():
                case "!showGridLabel".toLowerCase():
                case "!showHeatmapColumnLabels".toLowerCase():
                case "!showHeatmapColumnLabel".toLowerCase():
                case "!gridlabel".toLowerCase():
                    if (lineparts.length >= 2) {
                        try {
                            this.showGridLabel = parseInt(lineparts[1].trim()) > 0 ? true : false;
                        } catch (error) {
                            this.setError("line " + lineidx + ": error parsing value for showGridLabel;");
                        }
                    } else {
                        this.showGridLabel = true;
                    }
                break;
                case "!gridlabels".toLowerCase():
                case "!grids".toLowerCase():
                    if (lineparts.length >= 2) {
                        var kvs = this.splitKeyValuePairs2(lineparts[1].trim());
                        for (var ind in kvs) {
                            var floatvalue = ind
                            var label = kvs[ind];

                            try {
                                var f = parseFloat(floatvalue);
                                this.hmGrid2Label[f] =  label;
                            } catch (error) {
                                this.setError("line " + lineidx + ": error parsing value for !gridlabels or !grids;");
                            }
                        }
                    }
                break;
                case "!gridshades".toLowerCase():
                case "!shadesbetweengridlines".toLowerCase():
                    if (lineparts.length >= 2) {
                        this.splitKeyValuePairs(lineparts[1].trim(), this.hmGridShadeOptions);
                    }
                    // sanity check
                    if (!this.hmGridShadeOptions.hasOwnProperty("fill")) {
                        this.hmGridShadeOptions["fill"] =  "ghostwhite";
                    }
                    if (!this.hmGridShadeOptions.hasOwnProperty("opacity")) {
                        this.hmGridShadeOptions["opacity"] =  "0.4";
                    }
                break;
                case "!minradius".toLowerCase():
                case "!minvalue".toLowerCase():
                    if (lineparts.length >= 2) {
                        try {
                            this.minPixelRadius = parseFloat(lineparts.get(1).trim());
                            this.setMinValueSet(this.minPixelRadius);
                        } catch (error) {
                            this.setError("line " + lineidx + ": error parsing value for !minradius or !minvalue;");
                        }
                    }
                break;
                case "!maxradius".toLowerCase():
                case "!maxvalue".toLowerCase():
                    if (lineparts.length >= 2) {
                        try {
                            this.maxPixelRadius = parseFloat(lineparts.get(1).trim());
                            this.setMaxValueSet(this.maxPixelRadius);
                        } catch (error) {
                            this.setError("line " + lineidx + ": error parsing value for !maxradius or !maxvalue;");
                        }
                    }
                break;
                case "!area".toLowerCase():
                case "!byarea".toLowerCase():
                    if (lineparts.length >= 2) {
                        try {
                            this.byarea = parseInt(lineparts[1].trim()) > 0 ? true : false;
                        } catch ( e) {
                            this.setError("line " + lineidx + ": error parsing value for byarea!!");
                            this.setValid(false);
                        }
                    } else {
                        this.byarea = true;
                    }
                break;                
                case "!radius".toLowerCase():
                case "!radiuses".toLowerCase():
                case "!byradii".toLowerCase():
                case "!radii".toLowerCase():
                case "!byradius".toLowerCase():
                case "!byradiuses".toLowerCase():
                    if (lineparts.length >= 2) {
                        try {
                            this.byarea = parseInt(lineparts[1].trim()) > 0 ? false : true;
                        } catch (error) {
                            this.setError("line " + lineidx + ": error parsing value for " + part1);
                            this.setValid(false);
                        }
                    } else {
                        this.byarea = false;
                    }
                break;
                case "!fanplot".toLowerCase():
                case "!fan".toLowerCase():
                    if (lineparts.length >= 2) {
                        try {
                            this.fanplot = parseInt(lineparts[1].trim()) > 0 ? true : false;
                        } catch (error) {
                            this.setError("line " + lineidx + ": error parsing value for modifier !fanplot / !fan");
                            this.setValid(false);
                        }
                    } else {
                        this.fanplot = true;
                    }
                break;
                case "!alignIndividualColumn".toLowerCase():
                case "!align".toLowerCase():
                    if (lineparts.length >= 2) {
                        try {
                            this.alignindividualcolumn = parseInt(lineparts[1].trim()) > 0 ? true : false;
                        } catch (error) {
                            this.setError("line " + lineidx + ": error parsing value for " + part1);
                            this.setValid(false);
                        }
                    } else {
                        this.alignindividualcolumn = true;
                    }
                break;
                case "!showdomainname".toLowerCase():
                case "!showdomainnames".toLowerCase():
                    if (lineparts.length >= 2) {
                        try {
                            this.showDomainNames = parseInt(lineparts[1].trim()) > 0 ? true : false;
                        } catch (error) {
                            this.setError("line " + lineidx + " error parsing value for " + part1);
                            this.setValid(false);
                        }
                    } else {
                        this.showDomainNames = true;
                    }
                break;
                case "!type".toLowerCase():
                case "!shape".toLowerCase():
                case "!types".toLowerCase():
                case "!shapes".toLowerCase():
                    if (lineparts.length >= 2) {
                        this.arColorObjectsShapes = [];
                        var tem = StringOps.JsArrayStringToArrayList(StringOps.splitStringBySeperator(lineparts[1].trim(), ","))
                        for (var ind in tem) {
                            var s = tem[ind]
                            this.arColorObjectsShapes.push(s.trim());
                        }
                    } else {
                        this.setError("line " + lineidx + ": error parsing " + part1);
                        this.setValid(false);
                    }
                break;
                case "!checkLineWidth".toLowerCase():
                case "!checklwd".toLowerCase():
                    if (lineparts.length >= 2) {
                        try {
                            this.checklinewidth = parseFloat(lineparts[1].trim());
                        } catch ( e) {
                            // System.err.println(" .. parsing for default stroke width value failed; with use default ");
                        }
                    } else {
                        this.setError("line " + lineidx + ": error parsing check line width ");
                        this.setValid(false);
                    }
                break;
                case "!defaultstrokecolor".toLowerCase():
                case "!strokecolordefault".toLowerCase():
                case "!strokecolor".toLowerCase():
                    if (lineparts.length >= 2) {
                        this.defaultStrokeColor = lineparts[1].trim();
                    } else {
                        this.setError("line " + lineidx + ": error parsing default stroke color ");
                        this.setValid(false);
                    }
                break;
                case "!defaultstrokewidth".toLowerCase():
                case "!defaultstroke-width".toLowerCase():
                case "!strokewidth".toLowerCase():
                case "!stroke-width".toLowerCase():
                    if (lineparts.length >= 2) {
                        try {
                            this.defaultStrokeWidth = parseFloat(lineparts[1].trim());
                            this.legend.setStrokeWidth(this.defaultStrokeWidth); //
                        } catch ( e) {
                            this.setValid(false);
                            // System.err.println(" .. parsing for default stroke width value failed; with use default ");
                        }
                    } else {
                        this.setError("line " + lineidx + ": error parsing check line width ");
                        this.setValid(false);
                    }
                break;
                case "!decoposition".toLowerCase():
                case "!leafdecoposition".toLowerCase():
                    if (lineparts.length >= 2) {
                        var value = lineparts[1].trim();
                        if (value.equalsIgnoreCase("withleaflabel")) {
                            this.setbDecoWithLeafLabel(true);
                        } else {
                            this.setbDecoWithLeafLabel(false);
                        }
                    } else {
                        this.setError("line " + lineidx + ": error parsing leaf label position ");
                        this.setValid(false);
                    }
                break;
                case "!decowithleaf".toLowerCase():
                    if (lineparts.length >= 2) {
                        try {
                            this.setbDecoWithLeafLabel(parseInt(lineparts[1].trim()) > 0);
                        } catch ( e) {
                            this.setValid(false);
                            // System.err.println(" .. parsing for leaf label position failed; with use default ");
                            this.setError("line " + lineidx + ": error parsing leaf label (deconwithleaf)");
                        }
                    } else {
                        this.setbDecoWithLeafLabel(true);
                    }
                break;
                case "!decowithbranch".toLowerCase():
                    if (lineparts.length >= 2) {
                        try {
                            this.setbDecoWithLeafLabel(!(parseInt(lineparts[1].trim()) > 0));
                        } catch ( e) {
                            this.setValid(false);
                            // System.err.println(" .. parsing for leaf label position failed; with use default ");
                        }
                    } else {
                        this.setbDecoWithLeafLabel(false);
                    }
                break
                case "!genetransfer_style".toLowerCase():
                    if(lineparts.length >= 2){
                        this.setGeneTransferStyle(parseInt(lineparts[1].trim()))
                    }else {
                        this.setError("line " + lineidx + ": error parsing showGeneLabel properties.");
                        this.setValid(false);
                    }
                break
                case "!showGeneLabel".toLowerCase():
                    if (lineparts.length >= 2) {
                        this.showGeneLabel(parseInt(lineparts[1].trim()) == 1)
                    } else {
                        this.setError("line " + lineidx + ": error parsing showGeneLabel properties.");
                        this.setValid(false);
                    }
                break
                case "!showGeneDirection".toLowerCase():
                    if (lineparts.length >= 2) {
                        this.showGeneDirection(parseInt(lineparts[1].trim()) == 1)
                    } else {
                        this.setError("line " + lineidx + ": error parsing showGeneDirection properties.");
                        this.setValid(false);
                    }
                break
                case "!totgenes".toLowerCase():
                    if(lineparts.length >=2){
                        var part2 = lineparts[1].trim();
                        this.totGenes = parseInt(part2)
                    }else{
                        this.setError("line " + lineidx + ": error parsing total genes count.");
                        this.setValid(false);
                    }
                break
                case "!genevalues".toLowerCase():
                    if(lineparts.length >=2){
                        var part2 = lineparts[1].trim();
                        this.geneValuesType = parseInt(part2)
                    }else{
                        this.setError("line " + lineidx + ": error parsing genevalues.");
                        this.setValid(false);
                    }
                break
                default:
                    console.log('i am out ',part1)
                    this.setError('cant parse at line'+ lineidx+ ' with this:'+part1)
                    this.setValid(false);
                    break
            }

			/**
			 * **************************************************** >>>show
			 * bootstrap value style, supported options !bootstrapValueStyle
			 * show=1|0,style=numeric|shape,size=10,place=1|2,shape=rect,color=fill_color[:stroke_color],replaceWith=*
			 * 
			 * >>>> Feb 5, 2018; added support for replaceWith = *
			 * 
			 * ****************************************************
			 */

			/**
			 * **************************************************** >>> !dotplots /
			 * !dotplot; + !scalebycol !dotplots or !dotplot
			 * shape=circle,margin=0,colwidth=20,roundedcorner=0
			 * 
			 * >>>> dotplot column lable style ## -- or !columnLabelStyle
			 * !dataMatrixColumLabelStyle show=1,fontsize=14,fontcolor=black,
			 * fontitalic=1,fontbold=1 ****************************************************
			 */

			/**
			 * **************************************************** >>>>>> heatmap,>>>>>> Supported modifiers (a lot modifiers from the pie chart will be
			 * supported): + !defaultstrokecolor or !strokecolordefault or !strokecolor +
			 * !defaultstrokewidth or !defaultstroke-width or !strokewidth
			 * 
			 * + !colorgradient or !colorspectrum + !colorgradientMarkLabel or
			 * !colorgradientMarkLabels + !heatmapColumnLables / !heatmapColumnLabel +
			 * !showHeatmapColumnLabel / !showHeatmapColumnLabels (goes to grid label)
			 * 
			 * + !colmargin / !columnmargin; + !colwidth / !columnwidth ; +
			 * !colorGradientPixelWidth / !colorGradientWidth + !colorGradientPixelHeight /
			 * !colorGradientPixelHeight
			 * 
			 * and new style dotplots ... + !heatmap margin=0,colwidth=20 + !maxvalue (this
			 * goes to !maxradius) + !minvalue (this goes to !minradius)
			 * 
			 * !heatmap margin=0,colwidth=20, roundedcorner=0, dotplotmode=1, dotplotshape=circle
			 * 
			 * +
			 * 
			 * Notes: + showdatavalue is supported just fine but the text will only be
			 * aligned to center ...
			 * 
			 * ****************************************************
			 */

			/**
			 * !colorgradientMarkLabel or !colorgradientMarkLabels
			 */

			/**
			 * works for dotplot and heatmap !colmargin / !columnmargin;
			 * !colwidth / !columnwidth ;
			 */

			/**
			 * **************************************************** >>>>>> group label,
			 * added style -- added
			 * margin !grouplabel
			 * style=1,show=1,color=red,fontcolor=green,fontitalic=1,fontsize=1,linecolor=green,
			 * linewidth=2,linetype=dashed,textalign=start|end|center,textorientation=vertical|horizontal,margin=2
			 * ****************************************************
			 */

			/**
			 * *********************************************** for color strips / shapes --
			 * ************************************************
			 */



			/**
			 * *********************************************** plot width, height, opacity,
			 * shadow, line style. ************************************************
			 */
			/**
				 * *********************************************** grid, grid interval, axis,
				 * axis labels. ************************************************
				 */
			
			/**
			 * !gridlabels 10,20,50,100,1000,3000 !gridlabels
			 * 10=group1,20=group2,50=my group3,100=my group 4,1000,3000
			 */
			

			/**
			 * !gridshades fill=ghostwhite,opacity=0.4
			 * !shadesbetweengridlines fill=ghostwhite,opacity=0.4
			 */
			
			/**
			 * *********************************************** pie chart specific -
			 * radiuses. ************************************************
			 */
			 /**
				 * *********************************************** bar chart specific.
				 * ************************************************
				 */
			 /**
				 * *********************************************** protein domain chart
				 * specific. ************************************************
				 */
             /**
				 * ************************************************ color objects.
				 * ************************************************
				 */

			/**
			 * decoration for leaf decorations the following modifiers will
			 * be supported * !decoposition withleaflabel|withtreebranch !leafdecoposition
			 * withleaflabel|withtreebranch !decowithleaf !decowithbranch
			 */
			// end of modifiers for leaf decoration position ...

		}

		/**
		 * make legend
		 */
		if (this.showLegends && this.bLegendColorsParsed && this.bLegendTextsParsed) {
			this.makeLegend();
		}
	} // parser --

    splitKeyValuePairs( kvs,  kvpairs = {}) {
        var lis = StringOps.JsArrayStringToArrayList(StringOps.splitStringBySeperator(kvs.trim(), ","))
		for (var ind in  lis) {
            var kv = lis[ind]
            var al = StringOps.JsArrayStringToArrayList(StringOps.splitStringByEqualSignIgnoreHTMLTags(kv));
            // console.log('1411 ',kvs,al)
            if (al.length >= 2) {
				kvpairs[al[0].trim().toLowerCase()] =  al[1].trim();
			}
        }
        return kvpairs
    }

    splitKeyValuePairs2( kvs) {
        var kvpairs = {};
        var lis = StringOps.JsArrayStringToArrayList(StringOps.splitStringBySeperator(kvs.trim(), ","))
		for (var ind in lis ) {
            var kv = lis[ind]
			var al = StringOps.JsArrayStringToArrayList(StringOps.splitStringByEqualSignIgnoreHTMLTags(kv));
			if (al.length >= 2) {
				kvpairs[al[0].trim().toLowerCase()] =  al[1].trim();
			} else {
				kvpairs[kv.trim().toLowerCase()] =  kv.trim();
			}
		}
		return kvpairs;
    }
    
    makeLegend() {
		var bShowLegend = this.isShowLegends();
		var colors = this.getLegendColors();
		var legendTexts = this.getLegendTexts();
		var legendTitle = this.getTitle();
		var legendShapes = this.getLegendShapes();
		var legendStrokeColors = this.getLegendStrokeColors();

		this.legend.removeAllLegendItems();

		if (colors != null && legendTexts != null) { // these should not be null; they could be empty --
			if (colors.length > 0 && legendTexts.length > 0 && colors.length == legendTexts.length) {
				this.legend.setTitle(legendTitle);
				this.legend.setShowLegends(bShowLegend);
				for (var cgidx = 0; cgidx < colors.length; cgidx++) {
					this.legend.addLegendItem(new LegendItem(colors[cgidx], legendTexts[cgidx], 15,
							legendShapes[cgidx], legendStrokeColors[cgidx]));
				}
			} // if data is valid
		}
	}
    
    isShowLegends() {
		return this.showLegends;
    }
    
    setShowLegends( showLegends) {
		this.showLegends = showLegends;
    }
    
    getLegendColors() {
		var colors = [];
		for (var i = 0; i < this.arLegendTexts.length; i++) {
			if (!StringOps.isEmpty( this.arColorsAttrs)) {
				colors.push(this.arColorsAttrs[i % this.arColorsAttrs.length].getColor());
			} else {
				colors.push("");
			}
		}
		return colors;
    }

    showLeafLab(bool){
        this.showLeafLabel = bool
    }

    isShowLeafLab(){
        return this.showLeafLabel
    }
    
    showGeneLabel(bool){
        this.showGeneName = bool
    }

    isShowGeneLabel(){
        return this.showGeneName
    }

    getGeneProp(){
        return this.geneProp
    }

    showGeneDirection(dir){
        this.showGeneDir = dir
    }

    getGeneDirection(){
        return this.showGeneDir
    }

    getMaxGenesCount(){
        return this.totGenes
    }

    getGeneValuesType(){
        return this.geneValuesType
    }

    getLegendTexts() {
		return this.arLegendTexts;
    }
    
    getLegendShapes() {
		var shapes = [];
		for (var i = 0; i < this.arLegendTexts.length; i++) {
			if (this.arLegendShapes.length>=1) {
				shapes.push(this.arLegendShapes[i % this.arLegendShapes.length]);
			} else {
				shapes.push("rect");
			}
		}
		return shapes;
	}

	getLegendShapesOriginal() {
		return this.arLegendShapes;
    }
    
    getLegendStrokeColors() {
		var colors = [];
		for (var i = 0; i < this.arLegendTexts.length; i++) {
			if (!StringOps.isEmpty(this.arColorsAttrs)) {
				var strokecolor = this.arColorsAttrs[i % this.arColorsAttrs.length].getStrokeColor();
				if (StringOps.isEmpty(strokecolor)) {
					strokecolor = this.defaultStrokeColor;
				}
				colors.push(strokecolor);
			} else {
				colors.push(this.defaultStrokeColor);
			}
		}
		return colors;
    } //
    
    getGroup2ColorHash() {
		var cat2col = {};
		var colors = this.getLegendColors(); // this will make sure that the sizes of colors and legendtext
														// match
		var strokecolors = this.getLegendStrokeColors();
		for (var idx = 0; idx < colors.length; idx++) {
			cat2col[this.arLegendTexts[idx]] =  new ColorAttributes({'col':colors[idx], 'scol':strokecolors[idx]}); // March
		}
		return cat2col;
    }

    setValid( valid) {
		this.valid = valid;
    }
    
	/**
	 * @return the plotwidth
	 */
	getPlotwidth() {
		return this.plotwidth;
	}

	/**
	 * @param plotwidth
	 * the plotwidth to set
	 */
	setPlotwidth( plotwidth) {
		this.plotwidth = plotwidth;
	}

	/**
	 * @return the linewidth
	 */
	getLinewidth() {
		return this.linewidth;
	}

	/**
	 * @param linewidth
	 * the linewidth to set
	 */
	setLinewidth(linewidth) {
		this.linewidth = linewidth;
	}

	/**
	 * @return the opacity
	 */
	getOpacity() {
		return this.opacity;
	}

    isIsUserInputOpacity() {
		return this.isUserInputOpacity;
	}

	/**
	 * @param isUserInputOpacity
	 *            the isUserInputOpacity to set
	 */
	setIsUserInputOpacity( isUserInputOpacity) {
		this.isUserInputOpacity = isUserInputOpacity;
	}

	/**
	 * @return the valid
	 */
	isValid() {
		return this.valid;
	}

	//
	getDataStatus() {
		return this.valid;
	}

	isDataValid() {
		return this.valid;
    }
    
	/**
	 * @param op
	 * the opacity to set
	 */
	setOpacity( op) {
		if (op > 1) {
			op = 1;
		} else if (op < 0) {
			op = 0;
		}
		this.opacity = op;
	}

	/**
	 * @return the error_messages
	 */
	getErrorMessage() {
		return this.error_messages;
	}

	/**
	 * @param error_messages
	 * the error_messages to set
	 */
	addError_message( err) {
		this.error_messages = err;
	}

	setError( err) {
		this.error_messages = err;
		this.valid = false;
	}

	/**
	 * @return the active
	 */
	isActive() {
		return this.active && this.valid;
	}

	/**
	 * @param active
	 *            the active to set
	 */
	setActive( active) {
		this.active = active;
	}

	deactivateDataSet() {
		this.active = false;
	}

	/**
	 * @return the showDomainNames
	 */
	isShowDomainNames() {
		return this.showDomainNames;
	}

	/**
	 * @param showDomainNames
	 * the showDomainNames to set
	 */
	setShowDomainNames(showDomainNames) {
		this.showDomainNames = showDomainNames;
	}

	/**
	 * @return the showGridLabel
	 */
	isShowGridLabel() {
		return this.showGridLabel;
	}

	/**
	 * @param showGridLabel
	 * the showGridLabel to set
	 */
	setShowGridLabel( showGridLabel) {
		this.showGridLabel = showGridLabel;
	}

	/**
	 * @return the plotgrid
	 */
	isPlotgrid() {
		return this.plotgrid;
	}

	/**
	 * @param plotgrid
	 * the plotgrid to set
	 */
	setPlotgrid( plotgrid) {
		this.plotgrid = plotgrid;
	}

	/**
	 * @return the showShadow
	 */
	isShowShadow() {
		return this.showShadow;
	}

	/**
	 * @param showShadow
	 * the showShadow to set
	 */
	setShowShadow( showShadow) {
		this.showShadow = showShadow;
	}

	/**
	 * @return the fanplot
	 */
	isFanplot() {
		return this.fanplot;
	}

	/**
	 * @param fanplot
	 * the fanplot to set
	 */
	setFanplot( fanplot) {
		this.fanplot = fanplot;
	}

	/**
	 * @return the alignindividualcolumn
	 */
	isAlignindividualcolumn() {
		return this.alignindividualcolumn;
	}

	/**
	 * @param alignindividualcolumn
	 * the alignindividualcolumn to set
	 */
	setAlignindividualcolumn( alignindividualcolumn) {
		this.alignindividualcolumn = alignindividualcolumn;
	}

	/**
	 * @return the byarea
	 */
	isByarea() {
		return this.byarea;
	}

	/**
	 * @param byarea
	 * the byarea to set
	 */
	setByarea( byarea) {
		this.byarea = byarea;
	}

	/**
	 * @return the maxPixelRadius
	 */
	getMaxPixelRadius() {
		return this.maxPixelRadius;
	}

	/**
	 * @param maxPixelRadius
	 * the maxPixelRadius to set
	 */
	setMaxPixelRadius( maxPixelRadius) {
		this.maxPixelRadius = maxPixelRadius;
	}

	/**
	 * @return the minPixelRadius
	 */
	getMinPixelRadius() {
		return this.minPixelRadius;
	}

	/**
	 * @param minPixelRadius
	 * the minPixelRadius to set
	 */
	setMinPixelRadius(minPixelRadius) {
		this.minPixelRadius = minPixelRadius;
	}

	/**
	 * @return the groups_size
	 */
	getGroups_size() {
		return this.arLegendTexts.length;
	}

	/**
	 * March 20, 2014
	 */
	setColors(cols) {
		this.arColorsAttrs = [];
		for (var ind in cols) {
            var color = cols[ind]
			this.arColorsAttrs.push(new ColorAttributes({'col':color,'scol': ""})); // background color is empty
		}
	}

	/**
	 * @return the colors_size
	 */
	getColors_size() {
		return this.arColorsAttrs.length;
	}

	/**
	 * @return the objectRadius
	 */
	getObjectRadius() {
		return this.objectRadius;
	}

    getGroupsCount() {
		return this.arLegendTexts.length;
	}

	getGroupByIndex(index) {
		return this.arLegendTexts[index];
	}

	/*
	 * ***********************************************
	 *
	 * May 11, 2012: if groups_size and colors_size are both 0, match is also true
	 *
	 * ***********************************************
	 */
	isSizesMatchGroupsSizes() {
		return this.getGroups_size() == this.getColors_size();
    }
    
    /**
	 * march 19, 2014; the shapes for color objects; note this is different from
	 * legend shapes
	 */
	getColorObjectShapes() {
		return this.arColorObjectsShapes;
	}

	setLegendTexts(ar) {
		this.arLegendTexts = [];
		this.arLegendTexts.push.apply(this.arLegendTexts,ar);
	}

	/**
	 * @return the lineStyle
	 */
	getLineStyle() {
		return this.lineStyle;
	}

	getLegend() {
		return this.legend;
	}

	setLegend( legend) {
		this.legend = legend;
	}

	getDefaultStrokeColor() {
		return this.defaultStrokeColor;
	}

	setDefaultStrokeColor( defaultStrokeColor) {
		this.defaultStrokeColor = defaultStrokeColor;
	}

	// set the default stroke color to white
	setDefaultStrokeColorWhite() {
		this.defaultStrokeColor = "white";
	}

	isbDecoWithLeafLabel() {
		return this.bDecoWithLeafLabel;
	} // March 24, 2014

	setbDecoWithLeafLabel( bDecoWithLeafLabel) {
		this.bDecoWithLeafLabel = bDecoWithLeafLabel;
	} // March 24, 2014; make this private ...

	isbShowData() {
		return this.bShowData;
	}

	setbShowData( bShowData) {
		this.bShowData = bShowData;
	}

	getShowDataFontSize() {
		return this.showDataFontSize;
	}

	setShowDataFontSize( showDataFontSize) {
		this.showDataFontSize = showDataFontSize;
	}

	isbShowDataFontItalic() {
		return this.bShowDataFontItalic;
	}

	setbShowDataFontItalic( bShowDataFontItalic) {
		this.bShowDataFontItalic = bShowDataFontItalic;
	}

	getShowDataFontColor() {
		return this.showDataFontColor;
	}

	setShowDataFontColor( showDataFontColor) {
		this.showDataFontColor = showDataFontColor;
	}

	getShowDataTextAlign() {
		return this.showDataTextAlign;
	}

	setShowDataTextAlign( showDataTextAlign) {
		this.showDataTextAlign = showDataTextAlign;
	}

	getGroupLabelFontColor() {
		return this.groupLabelFontColor;
	}

	setGroupLabelFontColor( groupLabelFontColor) {
		this.groupLabelFontColor = groupLabelFontColor;
	}

	getGroupLabelLineColor() {
		return this.groupLabelLineColor;
	}

	setGroupLabelLineColor( groupLabelLineColor) {
		this.groupLabelLineColor = groupLabelLineColor;
	}

	isbGroupLabelShow() {
		return this.bGroupLabelShow;
	}

	setbGroupLabelShow( bGroupLabelShow) {
		this.bGroupLabelShow = bGroupLabelShow;
	}

	isbGroupLabelFontItalic() {
		return this.bGroupLabelFontItalic;
	}

	setbGroupLabelFontItalic(bGroupLabelFontItalic) {
		this.bGroupLabelFontItalic = bGroupLabelFontItalic;
	}

	isbGrouplabelDashedLine() {
		return this.bGrouplabelDashedLine;
	}

	setbGrouplabelDashedLine(bGrouplabelDashedLine) {
		this.bGrouplabelDashedLine = bGrouplabelDashedLine;
	}

	getGroupLabelLinewidth() {
		return this.groupLabelLinewidth;
	}

	setGroupLabelLinewidth( groupLabelLinewidth) {
		this.groupLabelLinewidth = groupLabelLinewidth;
	}

	getGrouplabelFontSize() {
		return this.grouplabelFontSize;
	}

	setGrouplabelFontSize( grouplabelFontSize) {
		this.grouplabelFontSize = grouplabelFontSize;
	}

	getGrouplabelTextAlign() {
		return this.grouplabelTextAlign;
	}

	setGrouplabelTextAlign(grouplabelTextAlign) {
		this.grouplabelTextAlign = grouplabelTextAlign;
	}

	getGroupLabelTextOrientation() {
		return this.groupLabelTextOrientation;
	}

	setGroupLabelTextOrientation(groupLabelTextOrientation) {
		this.groupLabelTextOrientation = groupLabelTextOrientation;
	}

	getGroupLabelMargin() {
		return this.groupLabelMargin;
	}

	setGroupLabelMargin( groupLabelMargin) {
		this.groupLabelMargin = groupLabelMargin;
	}

	getDotPlotsShape() {
		return this.dotPlotsShape;
	}

	setDotPlotsShape(dotPlotsShape) {
		this.dotPlotsShape = dotPlotsShape;
	}

	isDotPlotsStrokeDashed() {
		return this.dotPlotsStrokeDashed;
	}

	setDotPlotsStrokeDashed( dotPlotsStrokeDashed) {
		this.dotPlotsStrokeDashed = dotPlotsStrokeDashed;
	}

	getPlotColMargin() {
		return this.plotColMargin;
	}

	setPlotColMargin( dotPlotsMargin) {
		this.plotColMargin = dotPlotsMargin;
	}

	getPlotColWidth() {
		return this.plotColWidth;
	}

	setPlotColWidth(dotPlotsColWidth) {
		this.plotColWidth = dotPlotsColWidth;
	}

	isDotPlotsScaleByCol() {
		return this.dotPlotsScaleByCol;
	}

	setDotPlotsScaleByCol( dotPlotsScaleByCol) {
		this.dotPlotsScaleByCol = dotPlotsScaleByCol;
	}

	getRectRoundedCorner() {
		return this.dotPlotsRoundedCorner;
	}

	setRectRoundedCorner( dotPlotsRoundedCorner) {
		this.dotPlotsRoundedCorner = dotPlotsRoundedCorner;
	}

	getRainbow() {
		return this.rainbow;
	}

	isMaxValueSet() {
		return this.maxValueSet;
	}

	setMaxValueSet( maxValueSet) {
		this.maxValueSet = maxValueSet;
	}

	isMinValueSet() {
		return this.minValueSet;
	}

	setMinValueSet( minValueSet) {
		this.minValueSet = minValueSet;
	}

	getArHeatmapColumnLables() {
		return this.arHeatmapColumnLables;
	}

	getArColorGradientMarkLabels() {
		return this.arColorGradientMarkLabels;
	}

	isShowDataValueColorSet() {
		return this.showDataValueColorSet;
	}

	setShowDataValueColorSet( showDataValueColorSet) {
		this.showDataValueColorSet = showDataValueColorSet;
	}

	getColorGradientPixelHeight() {
		return this.colorGradientPixelHeight;
	}

	setColorGradientPixelHeight( colorGradientPixelHeight) {
		this.colorGradientPixelHeight = colorGradientPixelHeight;
	}

	getArHeatmapColorGradientColors() {
		return this.arHeatmapColorGradientColors;
	}

	setArHeatmapColorGradientColors(arHeatmapColorGradientColors) {
		this.arHeatmapColorGradientColors = arHeatmapColorGradientColors;
	}

	getColorGradientPixelWidth() {
		return this.colorGradientPixalWidth;
	}

	setColorGradientPixelWidth(colorGradientPixalWidth) {
		this.colorGradientPixalWidth = colorGradientPixalWidth;
	}

	isValuesToHideSet() {
		return this.valuesToHideSet;
	}

	setValuesToHideSet( valuesToHideSet) {
		this.valuesToHideSet = valuesToHideSet;
	}

	getValuesToHideStartValue() {
		return this.valuesToHideStartValue;
	}

	setValuesToHideStartValue( valuesToHideStartValue) {
		this.valuesToHideStartValue = valuesToHideStartValue;
	}

	getValuesToHideEndValue() {
		return this.valuesToHideEndValue;
	}

	setValuesToHideEndValue( valuesToHideEndValue) {
		this.valuesToHideEndValue = valuesToHideEndValue;
	}

	isColumnLableFontItalic() {
		return this.columnLableFontItalic;
	}

	setColumnLableFontItalic( columnLableFontItalic) {
		this.columnLableFontItalic = columnLableFontItalic;
	}

	getColumnLabelFontSize() {
		return this.columnLabelFontSize;
	}

	setColumnLabelFontSize( columnLabelFontSize) {
		this.columnLabelFontSize = columnLabelFontSize;
	}

	getColumnLabelFontColor() {
		return this.columnLabelFontColor;
	}

	setColumnLabelFontColor(columnLabelFontColor) {
		this.columnLabelFontColor = columnLabelFontColor;
	}

	isColumnLableFontBold() {
		return this.columnLableFontBold;
	}

	setColumnLableFontBold( columnLableFontBold) {
		this.columnLableFontBold = columnLableFontBold;
	}

	getColumnLabelTextAngle() {
		return this.columnLabelTextAngle;
	}

	setColumnLabelTextAngle( columnLabelTextAngle) {
		this.columnLabelTextAngle = columnLabelTextAngle;
	}

	isShowBootstrapValueStyle() {
		return this.showBootstrapValueStyle;
	}

	setShowBootstrapValueStyle( showBootstrapValueStyle) {
		this.showBootstrapValueStyle = showBootstrapValueStyle;
	}

	getBootstrapValueSize() {
		return this.bootstrapValueSize;
	}

	setBootstrapValueSize( bootstrapValueSize) {
		this.bootstrapValueSize = bootstrapValueSize;
	}

	getBootstrapValueShowPlace() {
		return this.bootstrapValueShowPlace;
	}

	setBootstrapValueShowPlace( bootstrapValueShowPlace) {
		this.bootstrapValueShowPlace = bootstrapValueShowPlace;
	}

	getBootstrapValueStyle() {
		return this.bootstrapValueStyle;
	}

	setBootstrapValueStyle(bootstrapValueStyle) {
		this.bootstrapValueStyle = bootstrapValueStyle;
	}

	getBootstrapValueColor() {
		return this.bootstrapValueColor;
	}

	setBootstrapValueColor(bootstrapValueColor) {
		this.bootstrapValueColor = bootstrapValueColor;
	}

	isRecyclecolor() {
		return this.recyclecolor;
	}

	setRecyclecolor( recyclecolor) {
		this.recyclecolor = recyclecolor;
	}

	isLogtransform() {
		return this.logtransform;
	}

	setLogtransform( logtransform) {
		this.logtransform = logtransform;
	}

	 getGridLabels() {
		return this.hmGrid2Label;
	}

	 getGridShadeOptions() {
		return this.hmGridShadeOptions;
	}

	getBarRowdataReorderStyle() {
		return this.barRowdataReorderStyle;
	}

	setBarRowdataReorderStyle(barRowdataReorderStyle) {
		this.barRowdataReorderStyle = barRowdataReorderStyle;
	}

	isbHeatmapDotplotMode() {
		return this.bHeatmapDotplotMode;
	}

	setbHeatmapDotplotMode(bHeatmapDotplotMode) {
		this.bHeatmapDotplotMode = bHeatmapDotplotMode;
    }
    
    setBranchLabelBkColor(bkColor){
        this.branchLabelBkColor = bkColor
    }

    getBranchLabelBkColor(bkColor){
        return this.branchLabelBkColor
    }

    setBranchLabelFontColor(fontColor){
        this.branchLabelFontColor = fontColor
    }

    setBranchLabelLineColor(lineColor){
        this.branchLabelLineColor = lineColor
    }

    setBranchLabelFontSize(fontSize){
        this.branchLabelFontSize = fontSize
    }

    getBranchLabelFontSize(){
        return this.branchLabelFontSize
    }

    setBranchLabelLinewidth(lineWidth){
        this.branchLabelLinewidth = lineWidth
    }

    getBranchLabelLineWidth(){
        return this.branchLabelLinewidth
    }

    setBranchLabelDashedLine(bool){
        this.branchLabelDashedLine = bool
    }

    isbBranchLabelDashedLine(){
        return this.branchLabelDashedLine
    }

    getBranchLabelFontItalic(){
        return this.branchLabelFontItalic
    }

    setBranchLabelTextAlign(){
        return this.branchLabelTextAlign
    }

    getBranchLabelTextAlign(){
        return this.branchLabelTextAlign
    }
}

export default DataContainerParserBase
