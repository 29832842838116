import ColorAttributes from './ColorAttributes'

class BootstrapValueStyleAttributesContainer extends ColorAttributes{

    constructor(){
        super()
        this.minvalue = 0
        this.maxvalue = 0
        this.place = 1
        this.replacedWithSet = false
        this.replaceWithString = ""
        this.setStyle("numeric")
    }
	
	setMinValue(f){
		this.minvalue = f;
	}
	
	setMaxValue(f){
		this.maxvalue = f
	}
	
	isInputValueInRange(inRange){
		return inRange >= this.minvalue && inRange <= this.maxvalue; 
	}

	getPlace() {
		return this.place;
	}

	setPlace( place) {
		this.place = place;
	}

	getMinValue() {
		return this.minvalue;
	}

	getMaxValue() {
		return this.maxvalue;
	}

	isReplacedWithSet() {
		return this.replacedWithSet;
	}

	setReplacedWithSet( replacedWithSet) {
		this.replacedWithSet = replacedWithSet;
	}

	getReplaceWithString() {
		return this.replaceWithString;
	}

	setReplaceWithString( replaceWithString) {
		this.replaceWithString = replaceWithString;
    }	
    
}

export default BootstrapValueStyleAttributesContainer