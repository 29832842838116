import StringOps from './StringOps'

class RgbaColor{
    /**
     * Creates an RgbaColor
     *
     * @param r the red component (0-255)
     * @param g the green component (0-255)
     * @param b the blue component (0-255)
     * @param a the alpha component (0-1)
     */
    constructor(options = {}){
        this.colorNames2Hex = {}
        this.r = options && options.r || 0;
        this.g = options && options.g || 0;
        this.b = options && options.b || 0;
        this.a = options && options.a || 1;
        this.initiate();

        if(options && options.color &&options.color.length>=1){
            var color = options.color
            color = color.toLowerCase();
    	
            if( this.colorNames2Hex.hasOwnProperty(color) ){
                RgbaColor.fromHex( this.colorNames2Hex[color] );
            }else if ( color.startsWith("#") ) {
                RgbaColor.fromHex(color);
            } else if (color.startsWith("rgba")) {
                this.fromRgba(color);
            } else if (color.startsWith("rgb")) {
                this.fromRgb(color);
            } else {
                this.getDefaultColor();
            }
        }

    }

    static from(color){
        color = color.toLowerCase();
        const colorHexMap = RgbaColor.getHexMap()
        if( colorHexMap != null && colorHexMap.hasOwnProperty(color) ){
            return RgbaColor.fromHex( colorHexMap[color].toLowerCase() );
        }else if ( color.startsWith("#") ) {
            return RgbaColor.fromHex(color);
        } else if (color.startsWith("rgba")) {
            return this.fromRgba(color);
        } else if (color.startsWith("rgb")) {
            return this.fromRgb(color);
        } else {
            return this.getDefaultColor();
        }
    }

    /**
     * Parses an RgbaColor from a hexadecimal value.
     *
     * @return returns the parsed color
     */
    static fromHex( hex) {
        if (hex.length == 0 || hex.charAt(0) != '#') {
            return this.getDefaultColor();
        }

        // #rgb
        if (hex.length == 4) {
            return new RgbaColor({r:RgbaColor.parseHex(hex, 1, 2),
                    g:RgbaColor.parseHex(hex, 2, 3),
                    b:RgbaColor.parseHex(hex, 3, 4)});

        } // #rrggbb
        else if (hex.length == 7) {            
            return new RgbaColor({r:RgbaColor.parseHex(hex, 1, 3),
                    g:RgbaColor.parseHex(hex, 3, 5),
                    b:RgbaColor.parseHex(hex, 5, 7)});

        } else {
            return this.getDefaultColor();
        }
    }

    /**
     * Parses an RgbaColor from an rgb value.
     *
     * @return returns the parsed color
     */
    fromRgb( rgb) {
        if (rgb.length == 0) {
            return this.getDefaultColor();
        }

        var parts = this.getRgbParts(rgb).split(",");
        if (parts.length == 3) {
            return new RgbaColor({r:RgbaColor.parseInt(parts[0]),
                    g:RgbaColor.parseInt(parts[1]),
                    b:RgbaColor.parseInt(parts[2])});
        } else {
            return this.getDefaultColor();
        }
    }

    /**
     * Parses an RgbaColor from an rgba value.
     *
     * @return returns the parsed color
     */
    fromRgba( rgba) {
        if (rgba.length == 0) {
            return this.getDefaultColor();
        }

        var parts = this.getRgbaParts(rgba).split(",");
        if (parts.length == 4) {
            return new RgbaColor({r:RgbaColor.parseInt(parts[0]),
                    g:RgbaColor.parseInt(parts[1]),
                    b:RgbaColor.parseInt(parts[2]),
                    a:this.parseFloat(parts[3])});
        } else {
            return this.getDefaultColor();
        }
    }

    getDefaultColor() {
        return new RgbaColor({r:255,g: 255,b: 255});
    }

    HEX = "0123456789abcdef";

    static parseHex( hex, s, e) {
        var v = RgbaColor.parseInt(hex.substring(s, e), 16);
        // handle single character parsing (e.g. #abc = #aabbcc)
        return (s + 1 == e) ? 16 * v + v : v;
    }

    getRgbParts( str) {
      var re = new RegExp("rgb\\s*\\(\\s*([0-9]+).*,\\s*([0-9]+).*,\\s*([0-9]+).*\\)", "gi");
      return str.replace(re, "$1,$2,$3");
    }

    getRgbaParts( str)  {
      var re = new RegExp("rgba\\s*\\(\\s*([0-9]+).*,\\s*([0-9]+).*,\\s*([0-9]+).*,\\s*([0-9]*\\.?[0-9]+).*\\)", "gi");
      return str.replace(re, "$1,$2,$3,$4");
    }

    static parseInt( val,radix = 10) {
      return parseInt(val, radix) || 0;
    }

    parseFloat( val, radix = 10) {
      return parseFloat(val, radix) || 0;
    }

    static getDefaultColor() {
        return new RgbaColor({r:255,g: 255,b: 255});
    }

    /**
     * Returns the value of the red component (0-255)
     */
    r() {
        return this.r;
    }

    /**
     * Returns the value of the green component (0-255)
     */
    g() {
        return this.g;
    }

    /**
     * Returns the value of the blue component (0-255)
     */
    b() {
        return this.b;
    }

    /**
     * Returns the value of the alpha component (0-1)
     */
    a() {
        return this.a;
    }

    /**
     * Returns the color in hexadecimal format (#rrggbb)
     */
    toHex() {
        var rs = this.hexpad(StringOps.toHexString(this.r));
        var gs = this.hexpad(StringOps.toHexString(this.g));
        var bs = this.hexpad(StringOps.toHexString(this.b));
        return "#" + rs + gs + bs;
    }
    
    toHex2() {
        var rs = this.hexpad(StringOps.toHexString(this.r));
        var gs = this.hexpad(StringOps.toHexString(this.g));
        var bs = this.hexpad(StringOps.toHexString(this.b));
        return rs + gs + bs;
    }

    hexpad( s) {
        return (s.length == 1) ? "0" + s : s;
    }

    /**
     * Returns the color in rgb format, e.g. rgb(r,g,b)
     */
    toRgb() {
        return "rgb(" + this.r + "," + this.g + "," + this.b + ")";
    }

    /**
     * Returns the color in rgba format, e.g. rgba(r,g,b,a)
     */
    toRgba() {
        return "rgba(" + this.r + "," + this.g + "," + this.b + "," + this.a + ")";
    }

    toString() {
        return this.toHex();
    }
    
    // the following codes are from 
    // http://code.google.com/p/gwt-grin/source/browse/trunk/src/org/gwt/grin/client/util/Color.java?r=12
    brighter(factor) {
        var r = r();
        var g = g();
        var b = b();
        
        return RgbaColor.fromHex( this.brightColor(r,g,b,factor) );
    }

    darker(factor) {
        return new RgbaColor({r:Math.max( (this.r() * factor), 0), g:Math.max(
                (this.g() * factor), 0), b:Math.max(
                (this.b() * factor), 0)});
    }
    
    // return an Hex 
    brightColor( r, g, b, percent){
      return '#' +
       ((0|(1<<8) + r + (256 - r) * percent / 100).toString(16)).substr(1) +
       ((0|(1<<8) + g + (256 - g) * percent / 100).toString(16)).substr(1) +
       ((0|(1<<8) + b + (256 - b) * percent / 100).toString(16)).substr(1);      
    }

    /*
     * from Color names : Color Names Supported by All Browsers
     * http://www.w3schools.com/html/html_colornames.asp
     * 
     * 147 color names are defined in the HTML and CSS color specification (16 basic color names plus 130 more).
     * 
     * Weihua Chen: Feb 24, 2012
    */
    initiate() {
        this.colorNames2Hex["aliceblue"] = "#F0F8FF"
        this.colorNames2Hex["antiquewhite"] = "#FAEBD7"
        this.colorNames2Hex["aqua"] = "#00FFFF"
        this.colorNames2Hex["aquamarine"] = "#7FFFD4"
        this.colorNames2Hex["azure"] = "#F0FFFF"
        this.colorNames2Hex["beige"] = "#F5F5DC"
        this.colorNames2Hex["bisque"] = "#FFE4C4"
        this.colorNames2Hex["black"] = "#000000"
        this.colorNames2Hex["blanchedalmond"] = "#FFEBCD"
        this.colorNames2Hex["blue"] = "#0000FF"
        this.colorNames2Hex["blueviolet"] = "#8A2BE2"
        this.colorNames2Hex["brown"] = "#A52A2A"
        this.colorNames2Hex["burlywood"] = "#DEB887"
        this.colorNames2Hex["cadetblue"] = "#5F9EA0"
        this.colorNames2Hex["chartreuse"] = "#7FFF00"
        this.colorNames2Hex["chocolate"] = "#D2691E"
        this.colorNames2Hex["coral"] = "#FF7F50"
        this.colorNames2Hex["cornflowerblue"] = "#6495ED"
        this.colorNames2Hex["cornsilk"] = "#FFF8DC"
        this.colorNames2Hex["crimson"] = "#DC143C"
        this.colorNames2Hex["cyan"] = "#00FFFF"
        this.colorNames2Hex["darkblue"] = "#00008B"
        this.colorNames2Hex["darkcyan"] = "#008B8B"
        this.colorNames2Hex["darkgoldenrod"] = "#B8860B"
        this.colorNames2Hex["darkgray"] = "#A9A9A9"
        this.colorNames2Hex["darkgrey"] = "#A9A9A9"
        this.colorNames2Hex["darkgreen"] = "#006400"
        this.colorNames2Hex["darkkhaki"] = "#BDB76B"
        this.colorNames2Hex["darkmagenta"] = "#8B008B"
        this.colorNames2Hex["darkolivegreen"] = "#556B2F"
        this.colorNames2Hex["darkorange"] = "#FF8C00"
        this.colorNames2Hex["darkorchid"] = "#9932CC"
        this.colorNames2Hex["darkred"] = "#8B0000"
        this.colorNames2Hex["darksalmon"] = "#E9967A"
        this.colorNames2Hex["darkseagreen"] = "#8FBC8F"
        this.colorNames2Hex["darkslateblue"] = "#483D8B"
        this.colorNames2Hex["darkslategray"] = "#2F4F4F"
        this.colorNames2Hex["darkslategrey"] = "#2F4F4F"
        this.colorNames2Hex["darkturquoise"] = "#00CED1"
        this.colorNames2Hex["darkviolet"] = "#9400D3"
        this.colorNames2Hex["deeppink"] = "#FF1493"
        this.colorNames2Hex["deepskyblue"] = "#00BFFF"
        this.colorNames2Hex["dimgray"] = "#696969"
        this.colorNames2Hex["dimgrey"] = "#696969"
        this.colorNames2Hex["dodgerblue"] = "#1E90FF"
        this.colorNames2Hex["firebrick"] = "#B22222"
        this.colorNames2Hex["floralwhite"] = "#FFFAF0"
        this.colorNames2Hex["forestgreen"] = "#228B22"
        this.colorNames2Hex["fuchsia"] = "#FF00FF"
        this.colorNames2Hex["gainsboro"] = "#DCDCDC"
        this.colorNames2Hex["ghostwhite"] = "#F8F8FF"
        this.colorNames2Hex["gold"] = "#FFD700"
        this.colorNames2Hex["goldenrod"] = "#DAA520"
        this.colorNames2Hex["gray"] = "#808080"
        this.colorNames2Hex["grey"] = "#808080"
        this.colorNames2Hex["green"] = "#008000"
        this.colorNames2Hex["greenyellow"] = "#ADFF2F"
        this.colorNames2Hex["honeydew"] = "#F0FFF0"
        this.colorNames2Hex["hotpink"] = "#FF69B4"
        this.colorNames2Hex["indianred"] = "#CD5C5C"
        this.colorNames2Hex["indigo"] = "#4B0082"
        this.colorNames2Hex["ivory"] = "#FFFFF0"
        this.colorNames2Hex["khaki"] = "#F0E68C"
        this.colorNames2Hex["lavender"] = "#E6E6FA"
        this.colorNames2Hex["lavenderblush"] = "#FFF0F5"
        this.colorNames2Hex["lawngreen"] = "#7CFC00"
        this.colorNames2Hex["lemonchiffon"] = "#FFFACD"
        this.colorNames2Hex["lightblue"] = "#ADD8E6"
        this.colorNames2Hex["lightcoral"] = "#F08080"
        this.colorNames2Hex["lightcyan"] = "#E0FFFF"
        this.colorNames2Hex["lightgoldenrodyellow"] = "#FAFAD2"
        this.colorNames2Hex["lightgray"] = "#D3D3D3"
        this.colorNames2Hex["lightgrey"] = "#D3D3D3"
        this.colorNames2Hex["lightgreen"] = "#90EE90"
        this.colorNames2Hex["lightpink"] = "#FFB6C1"
        this.colorNames2Hex["lightsalmon"] = "#FFA07A"
        this.colorNames2Hex["lightseagreen"] = "#20B2AA"
        this.colorNames2Hex["lightskyblue"] = "#87CEFA"
        this.colorNames2Hex["lightslategray"] = "#778899"
        this.colorNames2Hex["lightslategrey"] = "#778899"
        this.colorNames2Hex["lightsteelblue"] = "#B0C4DE"
        this.colorNames2Hex["lightyellow"] = "#FFFFE0"
        this.colorNames2Hex["lime"] = "#00FF00"
        this.colorNames2Hex["limegreen"] = "#32CD32"
        this.colorNames2Hex["linen"] = "#FAF0E6"
        this.colorNames2Hex["magenta"] = "#FF00FF"
        this.colorNames2Hex["maroon"] = "#800000"
        this.colorNames2Hex["mediumaquamarine"] = "#66CDAA"
        this.colorNames2Hex["mediumblue"] = "#0000CD"
        this.colorNames2Hex["mediumorchid"] = "#BA55D3"
        this.colorNames2Hex["mediumpurple"] = "#9370D8"
        this.colorNames2Hex["mediumseagreen"] = "#3CB371"
        this.colorNames2Hex["mediumslateblue"] = "#7B68EE"
        this.colorNames2Hex["mediumspringgreen"] = "#00FA9A"
        this.colorNames2Hex["mediumturquoise"] = "#48D1CC"
        this.colorNames2Hex["mediumvioletred"] = "#C71585"
        this.colorNames2Hex["midnightblue"] = "#191970"
        this.colorNames2Hex["mintcream"] = "#F5FFFA"
        this.colorNames2Hex["mistyrose"] = "#FFE4E1"
        this.colorNames2Hex["moccasin"] = "#FFE4B5"
        this.colorNames2Hex["navajowhite"] = "#FFDEAD"
        this.colorNames2Hex["navy"] = "#000080"
        this.colorNames2Hex["oldlace"] = "#FDF5E6"
        this.colorNames2Hex["olive"] = "#808000"
        this.colorNames2Hex["olivedrab"] = "#6B8E23"
        this.colorNames2Hex["orange"] = "#FFA500"
        this.colorNames2Hex["orangered"] = "#FF4500"
        this.colorNames2Hex["orchid"] = "#DA70D6"
        this.colorNames2Hex["palegoldenrod"] = "#EEE8AA"
        this.colorNames2Hex["palegreen"] = "#98FB98"
        this.colorNames2Hex["paleturquoise"] = "#AFEEEE"
        this.colorNames2Hex["palevioletred"] = "#D87093"
        this.colorNames2Hex["papayawhip"] = "#FFEFD5"
        this.colorNames2Hex["peachpuff"] = "#FFDAB9"
        this.colorNames2Hex["peru"] = "#CD853F"
        this.colorNames2Hex["pink"] = "#FFC0CB"
        this.colorNames2Hex["plum"] = "#DDA0DD"
        this.colorNames2Hex["powderblue"] = "#B0E0E6"
        this.colorNames2Hex["purple"] = "#800080"
        this.colorNames2Hex["red"] = "#FF0000"
        this.colorNames2Hex["rosybrown"] = "#BC8F8F"
        this.colorNames2Hex["royalblue"] = "#4169E1"
        this.colorNames2Hex["saddlebrown"] = "#8B4513"
        this.colorNames2Hex["salmon"] = "#FA8072"
        this.colorNames2Hex["sandybrown"] = "#F4A460"
        this.colorNames2Hex["seagreen"] = "#2E8B57"
        this.colorNames2Hex["seashell"] = "#FFF5EE"
        this.colorNames2Hex["sienna"] = "#A0522D"
        this.colorNames2Hex["silver"] = "#C0C0C0"
        this.colorNames2Hex["skyblue"] = "#87CEEB"
        this.colorNames2Hex["slateblue"] = "#6A5ACD"
        this.colorNames2Hex["slategray"] = "#708090"
        this.colorNames2Hex["slategrey"] = "#708090"
        this.colorNames2Hex["snow"] = "#FFFAFA"
        this.colorNames2Hex["springgreen"] = "#00FF7F"
        this.colorNames2Hex["steelblue"] = "#4682B4"
        this.colorNames2Hex["tan"] = "#D2B48C"
        this.colorNames2Hex["teal"] = "#008080"
        this.colorNames2Hex["thistle"] = "#D8BFD8"
        this.colorNames2Hex["tomato"] = "#FF6347"
        this.colorNames2Hex["turquoise"] = "#40E0D0"
        this.colorNames2Hex["violet"] = "#EE82EE"
        this.colorNames2Hex["wheat"] = "#F5DEB3"
        this.colorNames2Hex["white"] = "#FFFFFF"
        this.colorNames2Hex["whitesmoke"] = "#F5F5F5"
        this.colorNames2Hex["yellow"] = "#FFFF00"
        this.colorNames2Hex["yellowgreen"] = "#9ACD32"
    }

    static getHexMap(){
        const colorNames2Hex = {}
        colorNames2Hex["aliceblue"] = "#F0F8FF"
        colorNames2Hex["antiquewhite"] = "#FAEBD7"
        colorNames2Hex["aqua"] = "#00FFFF"
        colorNames2Hex["aquamarine"] = "#7FFFD4"
        colorNames2Hex["azure"] = "#F0FFFF"
        colorNames2Hex["beige"] = "#F5F5DC"
        colorNames2Hex["bisque"] = "#FFE4C4"
        colorNames2Hex["black"] = "#000000"
        colorNames2Hex["blanchedalmond"] = "#FFEBCD"
        colorNames2Hex["blue"] = "#0000FF"
        colorNames2Hex["blueviolet"] = "#8A2BE2"
        colorNames2Hex["brown"] = "#A52A2A"
        colorNames2Hex["burlywood"] = "#DEB887"
        colorNames2Hex["cadetblue"] = "#5F9EA0"
        colorNames2Hex["chartreuse"] = "#7FFF00"
        colorNames2Hex["chocolate"] = "#D2691E"
        colorNames2Hex["coral"] = "#FF7F50"
        colorNames2Hex["cornflowerblue"] = "#6495ED"
        colorNames2Hex["cornsilk"] = "#FFF8DC"
        colorNames2Hex["crimson"] = "#DC143C"
        colorNames2Hex["cyan"] = "#00FFFF"
        colorNames2Hex["darkblue"] = "#00008B"
        colorNames2Hex["darkcyan"] = "#008B8B"
        colorNames2Hex["darkgoldenrod"] = "#B8860B"
        colorNames2Hex["darkgray"] = "#A9A9A9"
        colorNames2Hex["darkgrey"] = "#A9A9A9"
        colorNames2Hex["darkgreen"] = "#006400"
        colorNames2Hex["darkkhaki"] = "#BDB76B"
        colorNames2Hex["darkmagenta"] = "#8B008B"
        colorNames2Hex["darkolivegreen"] = "#556B2F"
        colorNames2Hex["darkorange"] = "#FF8C00"
        colorNames2Hex["darkorchid"] = "#9932CC"
        colorNames2Hex["darkred"] = "#8B0000"
        colorNames2Hex["darksalmon"] = "#E9967A"
        colorNames2Hex["darkseagreen"] = "#8FBC8F"
        colorNames2Hex["darkslateblue"] = "#483D8B"
        colorNames2Hex["darkslategray"] = "#2F4F4F"
        colorNames2Hex["darkslategrey"] = "#2F4F4F"
        colorNames2Hex["darkturquoise"] = "#00CED1"
        colorNames2Hex["darkviolet"] = "#9400D3"
        colorNames2Hex["deeppink"] = "#FF1493"
        colorNames2Hex["deepskyblue"] = "#00BFFF"
        colorNames2Hex["dimgray"] = "#696969"
        colorNames2Hex["dimgrey"] = "#696969"
        colorNames2Hex["dodgerblue"] = "#1E90FF"
        colorNames2Hex["firebrick"] = "#B22222"
        colorNames2Hex["floralwhite"] = "#FFFAF0"
        colorNames2Hex["forestgreen"] = "#228B22"
        colorNames2Hex["fuchsia"] = "#FF00FF"
        colorNames2Hex["gainsboro"] = "#DCDCDC"
        colorNames2Hex["ghostwhite"] = "#F8F8FF"
        colorNames2Hex["gold"] = "#FFD700"
        colorNames2Hex["goldenrod"] = "#DAA520"
        colorNames2Hex["gray"] = "#808080"
        colorNames2Hex["grey"] = "#808080"
        colorNames2Hex["green"] = "#008000"
        colorNames2Hex["greenyellow"] = "#ADFF2F"
        colorNames2Hex["honeydew"] = "#F0FFF0"
        colorNames2Hex["hotpink"] = "#FF69B4"
        colorNames2Hex["indianred"] = "#CD5C5C"
        colorNames2Hex["indigo"] = "#4B0082"
        colorNames2Hex["ivory"] = "#FFFFF0"
        colorNames2Hex["khaki"] = "#F0E68C"
        colorNames2Hex["lavender"] = "#E6E6FA"
        colorNames2Hex["lavenderblush"] = "#FFF0F5"
        colorNames2Hex["lawngreen"] = "#7CFC00"
        colorNames2Hex["lemonchiffon"] = "#FFFACD"
        colorNames2Hex["lightblue"] = "#ADD8E6"
        colorNames2Hex["lightcoral"] = "#F08080"
        colorNames2Hex["lightcyan"] = "#E0FFFF"
        colorNames2Hex["lightgoldenrodyellow"] = "#FAFAD2"
        colorNames2Hex["lightgray"] = "#D3D3D3"
        colorNames2Hex["lightgrey"] = "#D3D3D3"
        colorNames2Hex["lightgreen"] = "#90EE90"
        colorNames2Hex["lightpink"] = "#FFB6C1"
        colorNames2Hex["lightsalmon"] = "#FFA07A"
        colorNames2Hex["lightseagreen"] = "#20B2AA"
        colorNames2Hex["lightskyblue"] = "#87CEFA"
        colorNames2Hex["lightslategray"] = "#778899"
        colorNames2Hex["lightslategrey"] = "#778899"
        colorNames2Hex["lightsteelblue"] = "#B0C4DE"
        colorNames2Hex["lightyellow"] = "#FFFFE0"
        colorNames2Hex["lime"] = "#00FF00"
        colorNames2Hex["limegreen"] = "#32CD32"
        colorNames2Hex["linen"] = "#FAF0E6"
        colorNames2Hex["magenta"] = "#FF00FF"
        colorNames2Hex["maroon"] = "#800000"
        colorNames2Hex["mediumaquamarine"] = "#66CDAA"
        colorNames2Hex["mediumblue"] = "#0000CD"
        colorNames2Hex["mediumorchid"] = "#BA55D3"
        colorNames2Hex["mediumpurple"] = "#9370D8"
        colorNames2Hex["mediumseagreen"] = "#3CB371"
        colorNames2Hex["mediumslateblue"] = "#7B68EE"
        colorNames2Hex["mediumspringgreen"] = "#00FA9A"
        colorNames2Hex["mediumturquoise"] = "#48D1CC"
        colorNames2Hex["mediumvioletred"] = "#C71585"
        colorNames2Hex["midnightblue"] = "#191970"
        colorNames2Hex["mintcream"] = "#F5FFFA"
        colorNames2Hex["mistyrose"] = "#FFE4E1"
        colorNames2Hex["moccasin"] = "#FFE4B5"
        colorNames2Hex["navajowhite"] = "#FFDEAD"
        colorNames2Hex["navy"] = "#000080"
        colorNames2Hex["oldlace"] = "#FDF5E6"
        colorNames2Hex["olive"] = "#808000"
        colorNames2Hex["olivedrab"] = "#6B8E23"
        colorNames2Hex["orange"] = "#FFA500"
        colorNames2Hex["orangered"] = "#FF4500"
        colorNames2Hex["orchid"] = "#DA70D6"
        colorNames2Hex["palegoldenrod"] = "#EEE8AA"
        colorNames2Hex["palegreen"] = "#98FB98"
        colorNames2Hex["paleturquoise"] = "#AFEEEE"
        colorNames2Hex["palevioletred"] = "#D87093"
        colorNames2Hex["papayawhip"] = "#FFEFD5"
        colorNames2Hex["peachpuff"] = "#FFDAB9"
        colorNames2Hex["peru"] = "#CD853F"
        colorNames2Hex["pink"] = "#FFC0CB"
        colorNames2Hex["plum"] = "#DDA0DD"
        colorNames2Hex["powderblue"] = "#B0E0E6"
        colorNames2Hex["purple"] = "#800080"
        colorNames2Hex["red"] = "#FF0000"
        colorNames2Hex["rosybrown"] = "#BC8F8F"
        colorNames2Hex["royalblue"] = "#4169E1"
        colorNames2Hex["saddlebrown"] = "#8B4513"
        colorNames2Hex["salmon"] = "#FA8072"
        colorNames2Hex["sandybrown"] = "#F4A460"
        colorNames2Hex["seagreen"] = "#2E8B57"
        colorNames2Hex["seashell"] = "#FFF5EE"
        colorNames2Hex["sienna"] = "#A0522D"
        colorNames2Hex["silver"] = "#C0C0C0"
        colorNames2Hex["skyblue"] = "#87CEEB"
        colorNames2Hex["slateblue"] = "#6A5ACD"
        colorNames2Hex["slategray"] = "#708090"
        colorNames2Hex["slategrey"] = "#708090"
        colorNames2Hex["snow"] = "#FFFAFA"
        colorNames2Hex["springgreen"] = "#00FF7F"
        colorNames2Hex["steelblue"] = "#4682B4"
        colorNames2Hex["tan"] = "#D2B48C"
        colorNames2Hex["teal"] = "#008080"
        colorNames2Hex["thistle"] = "#D8BFD8"
        colorNames2Hex["tomato"] = "#FF6347"
        colorNames2Hex["turquoise"] = "#40E0D0"
        colorNames2Hex["violet"] = "#EE82EE"
        colorNames2Hex["wheat"] = "#F5DEB3"
        colorNames2Hex["white"] = "#FFFFFF"
        colorNames2Hex["whitesmoke"] = "#F5F5F5"
        colorNames2Hex["yellow"] = "#FFFF00"
        colorNames2Hex["yellowgreen"] = "#9ACD32"
        return colorNames2Hex
    }

}

export default RgbaColor