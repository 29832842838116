
class PhyloParser {
    constructor(){
        this.parser = require('fast-xml-parser');
        let he = require('he')
        this.options = {
            attributeNamePrefix : "",
            attrNodeName: "attr", //default is 'false'
            textNodeName : "text",
            ignoreAttributes : false,
            ignoreNameSpace : false,
            allowBooleanAttributes : false,
            parseNodeValue : true,
            parseAttributeValue : false,
            trimValues: true,
            cdataTagName: "__cdata", //default is 'false'
            cdataPositionChar: "\\c",
            parseTrueNumberOnly: false,
            arrayMode: false, //"strict"
            attrValueProcessor: (val, attrName) => he.decode(val, {isAttributeValue: true}),//default is a=>a
            tagValueProcessor : (val, tagName) => he.decode(val), //default is a=>a
            stopNodes: ["parse-me-as-string"]
        };
        this.xmlJson = ''        
        this.parse_err = ''
    }

    xmlToJson(xml){        
        if( this.parser.validate(xml) === true) { //optional (it'll return an object in case it's not valid)
            try{ 
                this.xmlJson = this.parser.parse(xml,this.options,true)['phyloxml'];
                return true
            }catch(error){
                this.parse_err = error.message
            }
        }
        return false
    }

    getParseError(){
        return this.parse_err
    }

    getElementsByTagName(tag_name){
        if(this.xmlJson.hasOwnProperty(tag_name)){
            return this.xmlJson[tag_name]
        }
        return null
    }

    getChildNodes(node){
        if(node.hasOwnProperty('clade')){
            return node['clade']
        }
        return null
    }

    getAttributes(node){
        if(node.hasOwnProperty('attr')){
            return node['attr']
        }
    }

    isRooted(node){
        var attr = this.getAttributes(node)
        if(attr != null){
            let hasRoot = this.checkAttrProp(attr,'rooted')
            if(hasRoot){
                return Boolean(attr['rooted'])
            }
        }
        return false
    }

    checkAttrProp(node,propName){
        return node.hasOwnProperty(propName)
    }

    getAttrProp(node,propName){ //look for attr values
        node['attr'].hasOwnProperty(propName) ? node['attr'][propName] : null
    }

    getPropValueByItemID(node,propName){ //look for prop value of node
        // console.log(node,propName)
        node.hasOwnProperty(propName) ? node[propName] : null
    }

}

export default PhyloParser
