
class DomainsOfAProtein{
    constructor(){
        this.protein_length = 0;
        this.domains = []
    }

    addDomain( pd ){
        if( !this.domains.includes( pd ) ){
            this.domains.push(pd);
        }
    }
    
    /**
     * @return the protein_length
     */
    getProtein_length() {
        return this.protein_length;
    }

    /**
     * @param protein_length the protein_length to set
     */
    setProtein_length( protein_length) {
        this.protein_length = protein_length;
    }

    /**
     * @return the domains
     */
    getDomains() {
        return this.domains;
    }

    /**
     * @param domains the domains to set
     */
    setDomains(domains) {
        this.domains = domains;
    }
}

export default DomainsOfAProtein