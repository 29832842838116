import DataContainerParserBase from './DataContainerParserBase'
import StringOps from '../utils/StringOps'
import ColorAttributesContainer from './ColorAttributesContainer'

class ColorDataContainerParser extends DataContainerParserBase{
    constructor(datasetid, datasetContent, datatype) {
        super()
        this.hasStroke = false

        if(datasetid.length >=1 && datasetContent.length >=1){
            this.setDatasetID(datasetid);
            this.setOriginal_datastring(datasetContent);
        }

        this.colorsets = []

        /*
        * sample data: @ annotations !Legends text1,text2,text3,text4
        * !LegendColors color1,color2,color3,color4 
        * !type rect | star |
        * !showLegends 1
        *
        * A,B color mode A color mode B color
        *
        * // NOTE; mode is optional; mode could be one of the following: AD ==
        * all descendents prefix == any label ID start with A (for leaf label)
        * suffix == any label ID end with A (for leaf label) others, to be
        * added
        *
        * lines start with '@' will be ignored
        *
        * add other columns
        *
        * now #type and color could be multiple, both should
        * seperate with ","
        *
        * now annotation line starts with #; action line starts
        * with !
        */
       var lines = StringOps.JsArrayStringToArrayList( StringOps.splitStringByNewLine( datasetContent ) );
       for (var idx = 0; idx < lines.length; idx++) {
           var line = lines[idx].trim();
           if (line.trim().length > 0 && ! line.startsWith("#")) { // ignore annotation lines
        	   var lineparts = 
               StringOps.JsArrayStringToArrayList( StringOps.splitStringByTab( line ) );
               var part1 = lineparts[0].trim();
            //    console.log(line,lineparts)
               // Modifiers -----------------------------
               if ( part1.startsWith("!")) {
                   this.parseModifier(lineparts, idx + 1);
               } 
               //values ---------------------------------
               else if (lineparts.length >= 2) {
                   var part2 = lineparts[1].trim();
                   this.colorsets.push(new ColorAttributesContainer(
                    StringOps.JsArrayStringToArrayList( StringOps.splitStringBySeperator(part1, ",") ),
                    StringOps.JsArrayStringToArrayList( StringOps.splitStringBySeperator(part2, ",") ),
                           lineparts.length >= 3 ? lineparts[2].trim() : "",lineparts.length >= 4 ? lineparts[3].trim() : ""));
               }
            //    else if (lineparts.length == 1){
            //     this.valid = false
            //     this.setError("error at "+idx+" number of parts of the line doesnt match");
            //    }
               if(lineparts.length>=4){
                this.hasStroke = true
               }
           }
       }// end of for

       /*
        * check if current dataset is valid
        */
       if (this.colorsets.length <=0) {
           this.valid = false
           this.setError("error: at least one valid colorset data should be given");
       }

       if (!this.isSizesMatchGroupsSizes()) {
            this.valid = false
            this.setError("error: the numbers of legends and legend colors don't match;"); // TODO: ciculate colors if there are not enough colors
       }
    }

    hasStrokeInfo(){
        return this.hasStroke
    }

    getColorsets() {
        return this.colorsets;
    }
}

export default ColorDataContainerParser