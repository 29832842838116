import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/components/Home'
import Login from '@/components/Login'
import About from '@/components/Aboutus'
import Help from '@/components/Help'
// import ToolWindow from '@/components/ToolWindow'
import ToolCanvasWindow from '@/components/CanvasComponent'
import CanvasWindowShareColab from '@/components/CanvasComponentShareColab'
import EmbedCanvasWindow from '@/components/CanvasComponentEmbed'

import Projects from '@/components/Projects'

Vue.use(Router)

let router = new Router({
  // mode:"history",
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: {
        title:"Evolview:Home",
        guest: true,
        isTrial: false,
        requiresAuth: false
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        title:"Evolview:Login",
        guest: true,
        isTrial: false,
        requiresAuth: false
      }
    },
    {
      path: '/about',
      name: 'About',
      component: About,
      meta: {
        title:"Evolview:About",
        guest: true,
        isTrial: false,
        requiresAuth: false
      }
    },
    {
      path: '/help',
      name: 'Help',
      component: Help,
      meta: {
        title:"Evolview:Help",
        guest: true,
        isTrial: false,
        requiresAuth: false
      }
    },
    {
      path: '/treeview',
      name: 'ToolWindow',
      component: ToolCanvasWindow,
      meta: {
        title:"Evolview:Tree View",
        guest: false,
        isTrial: false,
        requiresAuth: true
      }
    },
    {
      path: '/shared/:shareId',
      name: 'SharedToolWindow/:shareId',
      component: CanvasWindowShareColab,
      meta: {
        title:"Evolview:Shared Tree View",
        guest: false,
        isTrial: true,
        requiresAuth: false
      },
    },
    {
      path: '/collab/:shareId',
      name: 'SharedToolWindow/:shareId',
      component: CanvasWindowShareColab,
      meta: {
        title:"Evolview:Collaborate Tree View",
        guest: false,
        isTrial: true,
        requiresAuth: false
      },
    },
    {
      path: '/embed/:embedId',
      name: 'EmbedToolWindow/:embedId',
      component: EmbedCanvasWindow,
      meta: {
        title:"Evolview:Embed Tree View",
        guest: true,
        isTrial: true,
        requiresAuth: false
      },
    },
    {
      path: '/projects',
      name: 'Projects',
      component: Projects,
      meta: {
        title:'Evolview:Project Management',
        guest: false,
        isTrial: false,
        requiresAuth: true
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  const record = to.matched.some(record => record.meta.requiresAuth)
  document.title = to.meta.title
  // next()

  if (record) {
    const isTrial = to.matched.some(isTrial => isTrial.meta.isTrial)
    if (isTrial) {
      next()
    } else if (localStorage.getItem('usertoken') == null && !isTrial) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (localStorage.getItem('usertoken') == null) {
      next()
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
