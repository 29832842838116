import DataContainerParserBase from './DataContainerParserBase'
import StringOps from '../utils/StringOps'
import RandomColorNames from '../utils/RandomColorNames'
import DomainsOfAProtein from './DomainsOfAProtein'
import ProteinDomain from './ProteinDomain'

class ProteinDomainDataContainerParser extends DataContainerParserBase{

    constructor(phylotree,options = {}){
        super()

        this.gene2domains = {};
        this.maxProteinLength = 0;
        this.default_plot_width = 300;
        this.colors = new RandomColorNames();
        this.isDataModified = false;

        if(options && phylotree != null){
            this.setDatasetID(options && options.datasetid);
            this.setOriginal_datastring(options && options.datasetContent);
            this.phylotree = phylotree
        }
        this.setPlotwidth(this.default_plot_width);
        
        let sbModifiers = []
        let sbData = []

        this.alldomains = []
        if(options && options.datasetContent.length >=1){
            var lines = StringOps.JsArrayStringToArrayList( StringOps.splitStringByNewLine( options.datasetContent ) );
            for (var idx = 0; idx < lines.length; idx++) {
                var lineSerial = idx + 1;
                var line = lines[idx].trim(); 
                if (line.trim().length > 0 && ! line.startsWith("#")) { // ignore annotation lines
                    
                    var lineparts = StringOps.JsArrayStringToArrayList(
                        StringOps.splitStringByTab( line )
                            );
                    
                    var part1 = lineparts[0].trim();
    
                    /**
                     * **************************
                     * if modifiers. 
                     ***************************
                     */
                    if (part1.startsWith("!")) {
                        this.parseModifier(lineparts, idx + 1); // all modifiers would be parsed by the parent class 
                        // sbModifiers+=line+"\n"
                        sbModifiers.push(line)
                        sbModifiers.push("\n"); 
                    }/**
                        * **************************
                        * if data. 
                        **************************
                        */
                    else if (lineparts.length >= 2) {
                        var part2 = lineparts[1].trim();
                        // sbData+=line+"\n"
                        sbData.push(line)
                        sbData.push("\n"); 
                        
                        /**
                         * ********************************************
                         * now parse the domain architecture
                         * ********************************************
                         */
                        try {
                            var dap = new DomainsOfAProtein();
    
                            var protein_length = parseInt(part2.trim());
                            dap.setProtein_length(protein_length);
    
                            // set max protein length
                            if (this.maxProteinLength < protein_length) {
                                this.maxProteinLength = protein_length;
                            }
    
                            // domains 
                            for (var lp = 2; lp < lineparts.length; lp++) {
                                // get a domain architecture and split it into a arraylist
                                var darchitString = lineparts[lp].trim();
                                
                                /**
                                 * darchitString may be surrounded by characters like ' or ", remove them first
                                 */
                                darchitString = darchitString.replace("'", "").replace("\"", "");
                                
                                
                                /**
                                 * move the parser to class ProteinDomain
                                 */
                                // split the comain architecture / structure 
                                var adomain = new ProteinDomain( {'attrstr':darchitString });
                                if( adomain.isValid() ){
                                    var domainName = adomain.getName().trim();
                                    if( domainName.length>=1 && !this.alldomains.includes(domainName) ){
                                        this.alldomains.push(domainName);
                                    }
                                    dap.addDomain(adomain);
                                } else {
                                    this.setError("line " + lineSerial + " error parsing protein domains\ndomain arcitecture not complete : '" + darchitString + "'\n");
                                    break;
                                }
                            }// iterate domain architecture strings
    
                            /**
                             * ********************************************
                             * at the end, add domains of a protein to HashMap ....
                             * ********************************************
                             */
                            var leafnodeIDs = StringOps.JsArrayStringToArrayList( StringOps.splitStringBySeperator( part1, ",") );
                            if (leafnodeIDs.length >=1) {
                                var leafid = leafnodeIDs[0].trim();
                                this.gene2domains[leafid] =  dap;
                                
                                /**
                                 * in case of two or more leaf node IDs, i.e. leafnodeIDs.size() >= 2,
                                 */
                                if( leafnodeIDs.length >= 2 ){
                                    var lca = this.phylotree.getLCA(leafnodeIDs);
                                    if( lca != null ){
                                        this.gene2domains[lca.getInternalID()] =  dap;
                                    }
                                } 
                            }
                        } catch (e) {
                            this.setError("line " + lineSerial + 1 + " error parsing protein domains;");
                        }
                    }// if length >= 2
                } // end of if line isn't empty and is not annotation line
            } // for

        /**
		 * *********************************************************************
		 * here, I would check if user input groups cover all possible domains,
		 * if not, I'll reset 'groups' and assign random colors to them.
		 * *********************************************************************
		 */
		// get groups from the base class; this important; otherwise
		// the colors will be randomlized each time the domains are loaded
		var groups = this.getLegendTexts(); // get groups from parent class
		var allcovered = true;
		for (var ind in this.alldomains) {
            var domain = this.alldomains[ind]
			if (!groups.includes(domain)) {
				allcovered = false;
				break;
			}
		}

		/**
		 * if not covered ...
		 * get random colors
		 * modify the original string 
		 */
		if (!allcovered) {
			this.setLegendTexts(this.alldomains);
			var cols = this.colors.getRandomColors(this.alldomains.length);
			this.setColors( cols );
			
			// create new !groups and !colors and add them to the end of modifiers
			// actually it is also possible to add them to the end of the dataset .... 
			this.isDataModified = true;
			
			var sbNewModifiers = [];
            sbNewModifiers.push("##the following modifiers are generated by EvolView!!")
            sbNewModifiers.push("\n");
            sbNewModifiers.push("!groups")
            sbNewModifiers.push("\t")
            sbNewModifiers.push( StringOps.joinStringBySeparator( this.alldomains, "," ) )
            sbNewModifiers.push("\n");
            sbNewModifiers.push("!colors")
            sbNewModifiers.push("\t")
            sbNewModifiers.push( StringOps.joinStringBySeparator( cols, "," ) )
            sbNewModifiers.push("\n");
            sbNewModifiers.push("##<<<< automatically generated modifiers end here!!")
            sbNewModifiers.push("\n\n");
			
			// assemble a new modifiers + data
            sbModifiers.push( sbNewModifiers.join("") )
            sbModifiers.push("\n")
            sbModifiers.push( sbData.join("") );
			this.setOriginal_datastring( sbModifiers.join("") );
		} // end 

		// 
		if (!this.isSizesMatchGroupsSizes()) {
			this.setError("oh. the numbers of legends and legend colors don't match;\nYou can:\n\t1.revise your dataset to make the numbers match;\n\t2.remove legeneds and colors from your dataset\n\t  the system will get those information\n\t  automatically"); // TODO: ciculate colors if there are not enough colors
		} else {
			/**
			 * add colors to domains
			 * add support for strokecolor
			 */
			var group2Color = this.getGroup2ColorHash();
			for (var ind in this.gene2domains) {
                var dap = this.gene2domains[ind]
				for (var subInd in dap.getDomains()) {
                    var pd = dap.getDomains()[subInd]
					if (group2Color.hasOwnProperty(pd.getName()) && !pd.isbColorSpecifiedByUser() ) {
						pd.setColorAttributeObj( group2Color[pd.getName()] );
					}
				}
			}
		} // if dataset valid 
        }
    }

    getGene2domainsHash() {
		return this.gene2domains;
	}

	getDomainsByGeneID( geneid) {
		var dap = new DomainsOfAProtein();

		if (this.gene2domains.hasOwnProperty(geneid)) {
			dap = this.gene2domains[geneid];
		}

		return dap;
	}

	/**
	 * @return the maxProteinLength
	 */
	getMaxProteinLength() {
		return this.maxProteinLength;
	}

	isDataMod() {
		return this.isDataModified;
	}

}

export default ProteinDomainDataContainerParser