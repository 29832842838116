import PlotBase from "./PlotBase";
import treePlotMode from '../treeObjects/treePlotMode'
import Point from '../treePainter/Point'
import StringOps from "../utils/StringOps";


class ProteinDomains extends PlotBase{
    constructor(layer_tree_charts, disable_all_dataset, treeSVG){
        super()
        this.treeSVG = treeSVG
        this.layer_tree_charts = layer_tree_charts
        this.disable_all_dataset = disable_all_dataset
        this.chart_plots_enabled = this.treeSVG.chart_plots_enabled
        this.currentMostRightPosition = this.treeSVG.getLeafMostRightPosition()
        this.plotmode = treeSVG.getPlotMode()
        this.phylotree = treeSVG.getPhyloTree()
        this.LeafInternalID2NodePosisionts = this.getLeafInternalID2NodePosisionts()
        
        this.defaultColor = "white";
        this.default_plot_width = 100
        this.plotWidth = this.default_plot_width;
        this.dataset = null
        this.angle_span = this.treeSVG.angle_span

        this.layer_grid = null
        this.layer_axis = null
    }

    setProteinDomainDataSet(newID, newdataset) {
        this.dataset = newdataset;
        this.datasetID = newID;
        this.setActive(true);
        this.datasetContentAsString = newdataset.getOriginal_datastring(); // get original user input as String ;
                                                                            // April 5, 2011;
        this.opacity = this.dataset.getOpacity();
        this.plotWidth = this.dataset.getPlotwidth();
        this.hmDomainAccession2SVGElements = {}
        this.hmDomainAccessioin2LegendSVGElement = {}
        this.setLegendEntry(newdataset.getLegend()); // March 20, 2014 --
        // calculate other global parameters
        this.pwidth_per_value = this.plotWidth / this.dataset.getMaxProteinLength();
        this.pxPerHeight = this.treeSVG.pxPerHeight
        this.space_between_datasets = this.treeSVG.space_between_datasets

    }

    setLeafInternalID2NodePosisionts(LeafInternalID2NodePosisionts){
        this.LeafInternalID2NodePosisionts = LeafInternalID2NodePosisionts
    }

    setRootXY(rootXY){
        this.rootXY = rootXY
    }

    getLeafMostRightPosition(){
        return this.currentMostRightPosition
    }

    setLeafMostRightPosition(currentMostRightPosition){
        this.currentMostRightPosition = currentMostRightPosition
    }

    setPlotMode(plotmode){
        this.plotmode = plotmode
    }

    getAngleSpan(){
        return this.treeSVG.getAngleSpan()
    }

    getLeafInternalID2NodePosisionts(){
        return this.treeSVG.getLeafInternalID2NodePosisionts()
    }

    addHighlightToRowPlotByLeafInternalID(leaf_internal_id) {
        if (this.hmInternalID2RowPlot.hasOwnProperty(leaf_internal_id)) {
            this.hmInternalID2RowPlot[leaf_internal_id].attr("stroke", "red");
        }
    }

    removeHighlightFromRowPlotByLeafInternalID(leaf_internal_id) {
        if (this.hmInternalID2RowPlot.hasOwnProperty(leaf_internal_id)) {
            this.hmInternalID2RowPlot[leaf_internal_id].attr("stroke",null);
        }
    }

    makeOrUpdatePlot() { // PROTEIN DOMAIN
        this.initLayer(this.layer_tree_charts)
        this.layer_grid = this.layer_tree_charts.group().id('layed_grid')
        this.layer_axis = this.layer_tree_charts.group().id('layer_axis')
        this.LeafInternalID2NodePosisionts = this.getLeafInternalID2NodePosisionts()

        if (this.active && !this.disable_all_dataset && this.chart_plots_enabled) {
            var mostright = this.currentMostRightPosition + this.space_between_datasets;
            var strokewidth = this.dataset.getDefaultStrokeWidth();

            this.hmDomainAccession2SVGElements = {}

            /*
             * deal with grid and axis, if available
             */
            var makegrid = false;
            if (this.dataset.isPlotgrid()) {
                Point.clearAllChildNodesFromSVGGElement(this.layer_grid);
                this.layer_grid.attr("stroke-width", this.line_width_grid + "");
                this.layer_grid.attr("stroke", "grey");
                this.layer_grid.attr("stroke-dasharray", "4,4"); // Apr 25, 2012; now all have this dash
                this.layer_grid.attr("fill", "none"); // for path only
                makegrid = true;
            } else {
                Point.deleteChildNodeFromLayerTree(this.layer_grid);
            }

            // grid axis; grid labels
            if (this.dataset.isShowGridLabel()) {
                Point.clearAllChildNodesFromSVGGElement(this.layer_axis);
                makegrid = true;
            } else {
                Point.deleteChildNodeFromLayerTree(this.layer_axis);
            }

            if (makegrid) {
                this.drawGridLinesAndLabels(1, 5, this.dataset.getMaxProteinLength(), mostright, this.pwidth_per_value,
                this.pxPerHeight, 0, this.layer_grid, this.layer_axis, false, null, null);
            } // make grid
                // <<<<<<<<<<< grid and axis <<<<<<<<<<<

            /*
             * >>>>>>>>>> the main layer >>>>>>>>>>>>>
             */
            Point.clearAllChildNodesFromSVGGElement(this.layer);
            this.layer.attr("fill-opacity", (this.opacity));

            /*
             * ******************************************** May 14, 2012: get plot height
             * here height refers to height per protein element therefore it cannot be
             * higher than pxPerHeight ********************************************
             */
            var plotheightPerProtein = (StringOps.equalsIgnoreCase(this.plotmode,treePlotMode.CIRCULAR_CLADOGRAM)
                    || StringOps.equalsIgnoreCase(this.plotmode,(treePlotMode.CIRCULAR_PHYLOGRAM))
                            ? this.dataset.getItemPixelHeight((mostright * 2 * Math.PI / 360 * this.angle_span
                                    / this.phylotree.getMaxVerticalLevel()))
                            : this.dataset.getItemPixelHeight(this.pxPerHeight));

            // a hard limit ??? --
            if (plotheightPerProtein > 40) {
                plotheightPerProtein = 40;
            }

            var showShadow = this.dataset.isShowShadow();

            /**
             * ****************************************** Plot domain architecture for all
             * proteins iterate hashPositionOfLeafNodes
             ******************************************
             */
            for (var entry in this.LeafInternalID2NodePosisionts) {
                const leaf_internal_id = entry;
                var startpos = this.LeafInternalID2NodePosisionts[entry].pt;
                var angle = this.LeafInternalID2NodePosisionts[entry].angle;
                var leaf_external_id = this.phylotree.getExternalIDbyInternalID(leaf_internal_id);
                var domains = this.dataset.getDomainsByGeneID(leaf_external_id);
                if (domains.getDomains().length <=0) {
                    domains = this.dataset.getDomainsByGeneID(leaf_internal_id);
                }

                /**
                 * ************************************* plot domains for a protein
                 * **************************************
                 */
                // -- each protein will have its own svgg group --
                const domainchart = this.layer.group().id('domainchart');
                this.hmInternalID2RowPlot[leaf_internal_id] =  domainchart;

                /**
                 * Aug 17, 2017 --
                 */
                var that = this
                domainchart.mouseover(function(evt) {
                    // setTimeout(() => {
                        that.treeSVG.addHighlightToAllRowplotsByInternalLeafID(leaf_internal_id)
                    // },10);
                })
                domainchart.mouseout(function(evt) {
                    // setTimeout(() => {
                        that.treeSVG.removeHighlightFromAllRowplotsByInternalLeafID(leaf_internal_id)
                    // }, 2000);                    
                })

                // ==== first of all, plot a horizontal line representing the protein (length)
                // ====
                var proteinlength = domains.getProtein_length();
                if (proteinlength > 0) {
                    var line = domainchart.line(mostright, startpos.y,
                            mostright + proteinlength * this.pwidth_per_value, startpos.y);
                    line.attr("stroke", "black");
                    line.attr("stroke-width", "0.5px");
                }

                // ==== plot domains ====
                // -- Dec 07, 2016 ...
                for (var ind in domains.getDomains()) {
                    const pd = domains.getDomains()[ind]
                    var g = domainchart.group().id('g');
                    const rect = g.rect(this.pwidth_per_value * (pd.getStop() - pd.getStart()), plotheightPerProtein)
                            .move(mostright + this.pwidth_per_value * pd.getStart(),startpos.y - plotheightPerProtein / 2);

                    var strokecolor = pd.getStrokeColor();
                    if (strokecolor.length>=1 && !StringOps.equalsIgnoreCase( strokecolor,"none")) {
                        rect.attr("stroke", strokecolor); // March 21, 2014
                        rect.attr("stroke-width", strokewidth + "");
                    }
                    rect.attr("fill", pd.getColor());
                    // add drop shadow if datasource is not pfam-B
                    if (!pd.isPfamB() && showShadow) {
                        // rect.getStyle().setSVGProperty(SVGConstants.SVG_FILTER_ATTRIBUTE,
                        //         DOMHelper.toUrl("dropshadow"));
                    }                    

                    // Aug 18, 2017; added g to hmDomainAccession2SVGElements
                    if (!pd.getAccession().length>=1
                            && !this.hmDomainAccession2SVGElements.hasOwnProperty(pd.getAccession())) {
                        this.hmDomainAccession2SVGElements[pd.getAccession()] =  [];
                    }
                    if (!pd.getAccession().length>=1) {
                        this.hmDomainAccession2SVGElements[pd.getAccession()].push(g);
                    }

                    rect.mouseover(function(evt) {
                        if (pd.getAccession().length <=0) {
                            that.addHighlightToDomainAndLegendItemsByDomainAccession(pd.getAccession(), "legend");
                        }
                        that.treeSVG.makeToolTipFlextableForDomain(evt,pd)
                    })
                    rect.mouseout(function(evt) {
                        if (pd.getAccession().length <=0) {
                            that.removeHighlightFromDomainAndLegendItemsByDomainAccession(pd.getAccession(),
                                    "legend");
                        }
                        that.treeSVG.makeDatSetTooltipHide()
                    })

                    /**
                     * ********************************************** tooltip for each domain
                     * **********************************************
                     */
                    
                }

                // rorate the whole domain it if necessary
                if (angle != 0) {
                    domainchart.transform({rotation:180-angle,cx: this.rootXY.x, cy:this.rootXY.y})
                } // circular
            } // iterate hashPositionOfLeafNodes
            this.currentMostRightPosition += (this.plotWidth + this.space_between_datasets);
        } else {
            Point.deleteChildNodeFromLayerTree(this.layer);
            Point.deleteChildNodeFromLayerTree(this.layer_axis);
            Point.deleteChildNodeFromLayerTree(this.layer_grid);
        } // is active
    } // makeorupdate plot

    addHighlightToDomainAndLegendItemsByDomainAccession(accession, targetElements) {
        if (targetElements === ("domains") && accession.length >=1
                && this.hmDomainAccession2SVGElements.hasOwnProperty(accession)) {
            for (var ind in this.hmDomainAccession2SVGElements[accession]) {
                var gele = this.hmDomainAccession2SVGElements[accession][ind]
                gele.attr("stroke", "red");
            }
        }

        if (targetElements === ("legend") && accession.length >=1
                && this.hmDomainAccessioin2LegendSVGElement.hasOwnProperty(accession)) {
            this.hmDomainAccessioin2LegendSVGElement[accession].attr("stroke", "red");
        }
    }

    removeHighlightFromDomainAndLegendItemsByDomainAccession( accession, targetElements) {
        if (targetElements === ("domains") && accession.length >=1
                && this.hmDomainAccession2SVGElements.hasOwnProperty(accession)) {
            for (var ind in this.hmDomainAccession2SVGElements[accession]) {
                var gele = this.hmDomainAccession2SVGElements[accession][ind]
                gele.attr("stroke",null);
            }
        }

        if (targetElements === ("legend") && accession.length >=1
                && this.hmDomainAccessioin2LegendSVGElement.hasOwnProperty(accession)) {
            this.hmDomainAccessioin2LegendSVGElement[accession].attr("stroke",null);
        } //
    } //

    drawGridLinesAndLabels( groupSize, gridlineCount, maxvalue, plotstart,
        widthPerValue, pixPerProtein, plot_width_per_subpanel, layer_grid,
          layer_gridlabel,  isLogTransform, hmUserInputGrids,
         hmGridShadeOptions) {
     
       // get leaf information
       var firstleaf_id = this.phylotree.getFirstLeafNode().getInternalID();
       var lastleaf_id = this.phylotree.getLastLeafNode().getInternalID();
     
       var firstleafPos = this.LeafInternalID2NodePosisionts[firstleaf_id];
       var lastleafPos = this.LeafInternalID2NodePosisionts[lastleaf_id];
     
       // Feb 12, 2012
       var anglespan_per_leaf_label = this.getAngleSpan() / this.phylotree.getLeafNodes().length;
       var anglespan_of_leaf_lables = this.getAngleSpan() - anglespan_per_leaf_label;
     
       // grid shade options Aug 15, 2017 --
       var bGridShadeDataValid = hmGridShadeOptions != null && hmGridShadeOptions.length >=1;
     
       /**
        * Aug 13, 2017 ; 计算 grid 画的位置 Assemble positions at which grid lines to be
        * plotted ...
        */
       var gridValues = [];
     
       var isUserInputGridsAvailable = false;
       if (hmUserInputGrids != null && hmUserInputGrids.length >=1) {
         for (var f in hmUserInputGrids) {
           gridValues.push(hmUserInputGrids[f]); // no sanity check --
         }
       }
     
       if (gridValues.length<=0) {
         // nice axis, [ start, end, step ]
        var niceaxis = StringOps.AxisStartEndStep(0, maxvalue, gridlineCount);
         for (var f = niceaxis[0]; f < niceaxis[1]; f += niceaxis[2]) {
           gridValues.push(f);
         }
       } else {
         isUserInputGridsAvailable = true;
       }
     
       if (isLogTransform) {
         maxvalue = Math.log(maxvalue);
       }
     
       /**
        * plot real start / end in pixel --
        */
       var plotstart_current_group = plotstart; // 0
     
       /*
        * -- Apr 25, 2012 -- refine grid and axis add numbers for grid lines >>> Aug
        * 13, 2017 --
        */
       for (var g = 0; g < groupSize; g++) {
         for (var grididx = 0; grididx < gridValues.length; grididx++) {
           var gridValue = gridValues[grididx];
           var canPlotShaddedStripBetweenGrids = grididx % 2 == 0 && (gridValues.length - grididx) > 1;
           
           var gridlabel = isUserInputGridsAvailable ? hmUserInputGrids[gridValue]
               : ( gridValue == gridValue) ? gridValue+""
                   : gridValue+"";
     
           
           if (isLogTransform) {
             if (gridValue != 0) {
               gridValue = Math.log(gridValue);
             }
             if (gridValue < 0) {
               gridValue = 0;
             }
           }
     
           var plotPosInPixel = (plotstart_current_group + gridValue * widthPerValue);
     
           // String color = grididx == 0 ? "grey" : "grey"; // Apr 25, 2012; make them all
           // grey
     
           var next_gridValue = 0, next_plotPosInPixel = 0;
           var next_gridlabel = "";
           if (canPlotShaddedStripBetweenGrids && bGridShadeDataValid) {
             next_gridValue = gridValues[grididx + 1];
             next_gridlabel = isUserInputGridsAvailable ? hmUserInputGrids[next_gridValue]
                 : (next_gridValue == next_gridValue) ? ( next_gridValue+"")
                     : (next_gridValue+"");
     
             // transform
             if (isLogTransform) {
               if (next_gridValue != 0) {
                 next_gridValue = Math.log(next_gridValue);
               }
               if (next_gridValue < 0) {
                 next_gridValue = 0;
               }
             }
     
             next_plotPosInPixel = (plotstart_current_group + next_gridValue * widthPerValue);
           }
     
           // if circular mode
           if ((this.plotmode === (treePlotMode.CIRCULAR_CLADOGRAM)
               || this.plotmode === (treePlotMode.CIRCULAR_PHYLOGRAM))) {
                var aradius = plotPosInPixel - this.rootXY.x;
        
                var startpoint = Point.getCoordinatesOnCircle(this.rootXY, aradius, firstleafPos.angle);
                var endpoint = Point.getCoordinatesOnCircle(this.rootXY, aradius, lastleafPos.angle);
        
                var sweepFlag = this.treeSVG.circular_tree_plot_in_clockwise_mode === true ? 1 : 0;
                var large_arc = (anglespan_of_leaf_lables >= 180) ? 1 : 0; // Feb 12, 2011; use global
                                                    // angle_span?
                  
                // boolean largeArcFlag = angle >= 180 ? true : false;
                var segs = new Point();
                segs.appendSegItem(Point.createPointStr(startpoint.x, startpoint.y));
                segs.appendSegItem(Point.createArcStr(endpoint.x, endpoint.y, aradius, 0,
                    large_arc, sweepFlag));
                const path = layer_grid.path(segs.getSegPathListStr());
     
                /**
                 * ******************************************************************** 画 扇面，位于
                 * 0~1， 2~3， 4~5 之间 ...
                 * ********************************************************************
                 */
                if (canPlotShaddedStripBetweenGrids && bGridShadeDataValid) {
                var next_aradius = next_plotPosInPixel - this.rootXY.x;
                var next_startpoint = Point.getCoordinatesOnCircle(this.rootXY, next_aradius,
                    firstleafPos.angle);
                var next_endpoint = Point.getCoordinatesOnCircle(this.rootXY, next_aradius, lastleafPos.angle);
        
                //
                var fansegs = new Point();
        
                fansegs.appendSegItem(Point.createPointStr(startpoint.x, startpoint.y)); // move to p1
                fansegs.appendSegItem(
                    Point.createPointStr(next_startpoint.x, next_startpoint.y,'L')); // line from p1 to p2;
                fansegs.appendSegItem(Point.createArcStr(next_endpoint.x, next_endpoint.y,
                    next_aradius, 0, large_arc, sweepFlag)); // arc from p2 to p3
                fansegs.appendSegItem(Point.createPointStr(endpoint.x, endpoint.y,'L')); // line from p3 to p4;
                fansegs.appendSegItem(Point.createSVGPathSegArcAbs(startpoint.x, startpoint.y,
                    aradius, 0, large_arc, !sweepFlag)); // arc from p4 to p1

                const shaddedfan = layer_grid.path(fansegs.getSegPathListStr());
                // fill color
                shaddedfan.attr("fill", hmGridShadeOptions["fill"]);
                shaddedfan.attr("fill-opacity", hmGridShadeOptions["opacity"]);
        
                const msg = gridlabel + " -- " + next_gridlabel;
                }
     
                /**
                 * ***************************************************** 画 grid label ...
                 * *****************************************************
                 */
                var angle = this.treeSVG.circular_tree_plot_in_clockwise_mode ? lastleafPos.angle
                    : firstleafPos.angle;
     
                // some variables --
                var current_s, r2Angle, y;
                var textanchor;
        
                if (angle > 0 && angle < 180) {
                    current_s = plotPosInPixel - 10;
                    r2Angle = 270;
                    textanchor = "start";
                    y = lastleafPos.pt.y + this.treeSVG.default_tick_label_font_size * 0.33;
                } else {
                    current_s = plotPosInPixel + 10;
                    r2Angle = 90;
                    textanchor = "end";
                    y = lastleafPos.pt.y + this.treeSVG.default_tick_label_font_size * 0.33;
                }
     
                // text label
                const txtGridLabel = layer_gridlabel.text(gridlabel).move(current_s-10, y+10)
                txtGridLabel.font({size:this.treeSVG.default_tick_label_font_size});
                txtGridLabel.font({anchor:textanchor})
                var textpoint = this.treeSVG.circular_tree_plot_in_clockwise_mode ? endpoint : startpoint;
                // txtGridLabel.transform({rotation:r2Angle, cx:textpoint.x, cy:textpoint.y})
                txtGridLabel.font({anchor:StringOps.equalsIgnoreCase(textanchor,("start")) ? "end" : "start"})
                
                if (angle != 0) {
                // and then rotate
                txtGridLabel.transform({rotation:180-angle, cx:this.rootXY.x, cy:this.rootXY.y})
                } // circular
     
                /**
                 * mouse events 
                 */
                txtGridLabel.mouseover(function(){
                    path.attr("stroke", "red");
                    path.attr("stroke-width", "0.5px");
                    this.attr("fill", "red");
                })
                txtGridLabel.mouseout(function(){
                    path.attr("stroke",null);
                    path.attr("stroke-width",null);
                    this.attr("fill",null);
                })
        
                path.mouseover(function() {
                    this.attr("stroke", "red");
                    this.attr("stroke-width", "0.5px");
                    txtGridLabel.attr("fill", "red");
                })
                path.mouseout(function() {
                    this.attr("stroke",null);
                    this.attr("stroke-width",null);
                    txtGridLabel.attr("fill",null);
                })     
           } else {
     
             var y = lastleafPos.pt.y + pixPerProtein;
     
             var txtGridLabel = layer_gridlabel.text(gridlabel).move(plotPosInPixel, y)             
             txtGridLabel.font({size:this.treeSVG.default_tick_label_font_size});
             txtGridLabel.attr("text-anchor", "middle"); // April 14, 2011; Align center
     
             const line = layer_grid.line(plotPosInPixel,
                firstleafPos.pt.y - pixPerProtein * 0.8 / 2, plotPosInPixel,
                lastleafPos.pt.y + pixPerProtein * 0.8 / 2)
     
             /**
              * ******************************************************************** 画
              * rect，位于 0~1， 2~3， 4~5 之间 ...
              * ********************************************************************
              */
             if (canPlotShaddedStripBetweenGrids && bGridShadeDataValid) {
               //
               const shaddedrect = layer_grid.rect(next_plotPosInPixel - plotPosInPixel,
                lastleafPos.pt.y - firstleafPos.pt.y + pixPerProtein * 0.8).move(plotPosInPixel,
                    firstleafPos.pt.y - pixPerProtein * 0.8 / 2).radius(0,0)     
               shaddedrect.attr("fill", hmGridShadeOptions["fill"]);
               shaddedrect.attr("fill-opacity", hmGridShadeOptions["opacity"]);
     
               const msg = gridlabel + " -- " + next_gridlabel;
             }
     
             /**
              * mouse events -- 老鼠事件！！！; Aug 14, 2017 ...
              */
             txtGridLabel.mouseover(function(){
                line.attr("stroke", "red");
                line.attr("stroke-width", "0.8px");
                this.attr("fill", "red");
             })
             txtGridLabel.mouseout(function(){
                line.attr("stroke", null);
                line.attr("stroke-width", null);
                this.attr("fill", null);
             })

             line.mouseover(function(){
                this.attr("stroke", "red");
                this.attr("stroke-width", "0.8px");
                txtGridLabel.attr("fill", "red");
             })
             line.mouseout(function(){
                this.attr("stroke", null);
                this.attr("stroke-width", null);
                txtGridLabel.attr("fill", null);
             })
           }
         } // s
         plotstart_current_group += (plot_width_per_subpanel + this.space_between_columns_within_a_dataset);
       } // for
    }
    
}

export default ProteinDomains