
class ABarPlotValueAndAttributes{
    constructor(){
        this.barWidthInPixel = 0;
        this.barWidwhRealValue = 0;
	    this.fillColor = "white"; 
	    this.strokeColor = "white";
    }

    loadAttributes(barWidthF, barWidthTrueValue, fillColorS, strokeColorS ){
        this.setBarWidth(barWidthF);
		this.setFillColor(fillColorS);
		this.setStrokeColor(strokeColorS);
		this.setBarWidwhRealValue(barWidthTrueValue);
    }

    getBarWidth() {
		return this.barWidthInPixel;
	}

	setBarWidth( barWidth) {
		this.barWidthInPixel = barWidth;
	}

	getFillColor() {
		return this.fillColor;
	}

	setFillColor( fillColor) {
		this.fillColor = fillColor;
	}

	getStrokeColor() {
		return this.strokeColor;
	}

	setStrokeColor( strokeColor) {
		this.strokeColor = strokeColor;
	}

	getBarWidwhRealValue() {
		return this.barWidwhRealValue;
	}

	setBarWidwhRealValue( barWidwhRealValue) {
		this.barWidwhRealValue = barWidwhRealValue;
	}
}

export default ABarPlotValueAndAttributes