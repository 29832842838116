
class BranchMarkerAttribute {
    constructor(){
        this.defId = ''
        this.shape = 'star'
        this.stroke_color = 'red'
        this.bgColor = 'red'
        this.isDashedLine = false
        this.fillBg = false
        this.isImage = false
        this.imgSrc = ''
        this.width = 20
        this.height = 20
    }

    setId(id){
        this.defId = id
    }
    getDefId(){
        return this.defId
    }

    setShape(shape){
        this.shape = shape
    }
    getShape(){
        return this.shape
    }

    setStrokeColor(color){
        this.stroke_color = color
    }
    getStrokeColor(color){
        return this.stroke_color
    }

    setBgColor(color){
        this.bgColor = color
    }
    getBgColor(){
        return this.bgColor
    }

    setFillBg(bool){
        this.fillBg = bool
    }
    isFillBg(){
        return this.fillBg
    }

    setBgImg(bool){
        this.isImage = bool
    }
    isBgImage(){
        return this.isImage
    }

    setImgSrc(src){
        this.imgSrc = src
    }
    getImgSrc(){
        return this.imgSrc
    }

    setWidth(width){
        this.width = width
    }
    getWidth(){
        return this.width
    }

    setHeight(height){
        this.height = height
    }
    getHeight(){
        return this.height
    }

    setLineType(dashLine){
        this.isDashedLine = dashLine
    }

    getDashLine(){
        return this.isDashedLine
    }


}
export default BranchMarkerAttribute