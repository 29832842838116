
class LegendEntry{

    constructor(){
        this.title = "";
        this.items = [];
        this.space_between_legend_item = 3, 
        this.strokewidth = 1;
        this.height_per_legenditem = 12;
        this.show = false;
    }
	
	getStrokeWidth(){
		return this.strokewidth;
	}
	
	setStrokeWidth(  f ){
		this.strokewidth = f;
	}

	setTitle( ti) {
		this.title = ti;
	}

	addLegendItem( newitem) {
		this.items.push(newitem);
	}

	calcLegendHeight() {
		var height = 0;
		var items_count = this.items.length;

		if (items_count > 0) {
			height += items_count * this.height_per_legenditem + (items_count - 1) * this.space_between_legend_item;
		}

		return height;
	}

	setHeightPerLegendItem(f) {
		this.height_per_legenditem = f;
	}

	getTitle() {
		return this.title;
	}

	getLegendItems() {
		return this.items;
	}

	setShowLegends( b) {
		this.show = b;
	}

	getShowLegends() {
		return this.show;
	}
	
	removeAllLegendItems(){
		this.items = [];
	}
}

export default LegendEntry