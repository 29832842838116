import RgbaColor from './RgbaColor'

class ColorUtils{
    constructor(){
        this.colorNames2Hex = {}
        this.init()
    }

    init(){
        this.colorNames2Hex["aliceblue"] =  "#F0F8FF"
        this.colorNames2Hex["antiquewhite"] =  "#FAEBD7"
        this.colorNames2Hex["aqua"] =  "#00FFFF"
        this.colorNames2Hex["aquamarine"] =  "#7FFFD4"
        this.colorNames2Hex["azure"] =  "#F0FFFF"
        this.colorNames2Hex["beige"] =  "#F5F5DC"
        this.colorNames2Hex["bisque"] =  "#FFE4C4"
        this.colorNames2Hex["black"] =  "#000000"
        this.colorNames2Hex["blanchedalmond"] =  "#FFEBCD"
        this.colorNames2Hex["blue"] =  "#0000FF"
        this.colorNames2Hex["blueviolet"] =  "#8A2BE2"
        this.colorNames2Hex["brown"] =  "#A52A2A"
        this.colorNames2Hex["burlywood"] =  "#DEB887"
        this.colorNames2Hex["cadetblue"] =  "#5F9EA0"
        this.colorNames2Hex["chartreuse"] =  "#7FFF00"
        this.colorNames2Hex["chocolate"] =  "#D2691E"
        this.colorNames2Hex["coral"] =  "#FF7F50"
        this.colorNames2Hex["cornflowerblue"] =  "#6495ED"
        this.colorNames2Hex["cornsilk"] =  "#FFF8DC"
        this.colorNames2Hex["crimson"] =  "#DC143C"
        this.colorNames2Hex["cyan"] =  "#00FFFF"
        this.colorNames2Hex["darkblue"] =  "#00008B"
        this.colorNames2Hex["darkcyan"] =  "#008B8B"
        this.colorNames2Hex["darkgoldenrod"] =  "#B8860B"
        this.colorNames2Hex["darkgray"] =  "#A9A9A9"
        this.colorNames2Hex["darkgrey"] =  "#A9A9A9"
        this.colorNames2Hex["darkgreen"] =  "#006400"
        this.colorNames2Hex["darkkhaki"] =  "#BDB76B"
        this.colorNames2Hex["darkmagenta"] =  "#8B008B"
        this.colorNames2Hex["darkolivegreen"] =  "#556B2F"
        this.colorNames2Hex["darkorange"] =  "#FF8C00"
        this.colorNames2Hex["darkorchid"] =  "#9932CC"
        this.colorNames2Hex["darkred"] =  "#8B0000"
        this.colorNames2Hex["darksalmon"] =  "#E9967A"
        this.colorNames2Hex["darkseagreen"] =  "#8FBC8F"
        this.colorNames2Hex["darkslateblue"] =  "#483D8B"
        this.colorNames2Hex["darkslategray"] =  "#2F4F4F"
        this.colorNames2Hex["darkslategrey"] =  "#2F4F4F"
        this.colorNames2Hex["darkturquoise"] =  "#00CED1"
        this.colorNames2Hex["darkviolet"] =  "#9400D3"
        this.colorNames2Hex["deeppink"] =  "#FF1493"
        this.colorNames2Hex["deepskyblue"] =  "#00BFFF"
        this.colorNames2Hex["dimgray"] =  "#696969"
        this.colorNames2Hex["dimgrey"] =  "#696969"
        this.colorNames2Hex["dodgerblue"] =  "#1E90FF"
        this.colorNames2Hex["firebrick"] =  "#B22222"
        this.colorNames2Hex["floralwhite"] =  "#FFFAF0"
        this.colorNames2Hex["forestgreen"] =  "#228B22"
        this.colorNames2Hex["fuchsia"] =  "#FF00FF"
        this.colorNames2Hex["gainsboro"] =  "#DCDCDC"
        this.colorNames2Hex["ghostwhite"] =  "#F8F8FF"
        this.colorNames2Hex["gold"] =  "#FFD700"
        this.colorNames2Hex["goldenrod"] =  "#DAA520"
        this.colorNames2Hex["gray"] =  "#808080"
        this.colorNames2Hex["grey"] =  "#808080"
        this.colorNames2Hex["green"] =  "#008000"
        this.colorNames2Hex["greenyellow"] =  "#ADFF2F"
        this.colorNames2Hex["honeydew"] =  "#F0FFF0"
        this.colorNames2Hex["hotpink"] =  "#FF69B4"
        this.colorNames2Hex["indianred"] =  "#CD5C5C"
        this.colorNames2Hex["indigo"] =  "#4B0082"
        this.colorNames2Hex["ivory"] =  "#FFFFF0"
        this.colorNames2Hex["khaki"] =  "#F0E68C"
        this.colorNames2Hex["lavender"] =  "#E6E6FA"
        this.colorNames2Hex["lavenderblush"] =  "#FFF0F5"
        this.colorNames2Hex["lawngreen"] =  "#7CFC00"
        this.colorNames2Hex["lemonchiffon"] =  "#FFFACD"
        this.colorNames2Hex["lightblue"] =  "#ADD8E6"
        this.colorNames2Hex["lightcoral"] =  "#F08080"
        this.colorNames2Hex["lightcyan"] =  "#E0FFFF"
        this.colorNames2Hex["lightgoldenrodyellow"] =  "#FAFAD2"
        this.colorNames2Hex["lightgray"] =  "#D3D3D3"
        this.colorNames2Hex["lightgrey"] =  "#D3D3D3"
        this.colorNames2Hex["lightgreen"] =  "#90EE90"
        this.colorNames2Hex["lightpink"] =  "#FFB6C1"
        this.colorNames2Hex["lightsalmon"] =  "#FFA07A"
        this.colorNames2Hex["lightseagreen"] =  "#20B2AA"
        this.colorNames2Hex["lightskyblue"] =  "#87CEFA"
        this.colorNames2Hex["lightslategray"] =  "#778899"
        this.colorNames2Hex["lightslategrey"] =  "#778899"
        this.colorNames2Hex["lightsteelblue"] =  "#B0C4DE"
        this.colorNames2Hex["lightyellow"] =  "#FFFFE0"
        this.colorNames2Hex["lime"] =  "#00FF00"
        this.colorNames2Hex["limegreen"] =  "#32CD32"
        this.colorNames2Hex["linen"] =  "#FAF0E6"
        this.colorNames2Hex["magenta"] =  "#FF00FF"
        this.colorNames2Hex["maroon"] =  "#800000"
        this.colorNames2Hex["mediumaquamarine"] =  "#66CDAA"
        this.colorNames2Hex["mediumblue"] =  "#0000CD"
        this.colorNames2Hex["mediumorchid"] =  "#BA55D3"
        this.colorNames2Hex["mediumpurple"] =  "#9370D8"
        this.colorNames2Hex["mediumseagreen"] =  "#3CB371"
        this.colorNames2Hex["mediumslateblue"] =  "#7B68EE"
        this.colorNames2Hex["mediumspringgreen"] =  "#00FA9A"
        this.colorNames2Hex["mediumturquoise"] =  "#48D1CC"
        this.colorNames2Hex["mediumvioletred"] =  "#C71585"
        this.colorNames2Hex["midnightblue"] =  "#191970"
        this.colorNames2Hex["mintcream"] =  "#F5FFFA"
        this.colorNames2Hex["mistyrose"] =  "#FFE4E1"
        this.colorNames2Hex["moccasin"] =  "#FFE4B5"
        this.colorNames2Hex["navajowhite"] =  "#FFDEAD"
        this.colorNames2Hex["navy"] =  "#000080"
        this.colorNames2Hex["oldlace"] =  "#FDF5E6"
        this.colorNames2Hex["olive"] =  "#808000"
        this.colorNames2Hex["olivedrab"] =  "#6B8E23"
        this.colorNames2Hex["orange"] =  "#FFA500"
        this.colorNames2Hex["orangered"] =  "#FF4500"
        this.colorNames2Hex["orchid"] =  "#DA70D6"
        this.colorNames2Hex["palegoldenrod"] =  "#EEE8AA"
        this.colorNames2Hex["palegreen"] =  "#98FB98"
        this.colorNames2Hex["paleturquoise"] =  "#AFEEEE"
        this.colorNames2Hex["palevioletred"] =  "#D87093"
        this.colorNames2Hex["papayawhip"] =  "#FFEFD5"
        this.colorNames2Hex["peachpuff"] =  "#FFDAB9"
        this.colorNames2Hex["peru"] =  "#CD853F"
        this.colorNames2Hex["pink"] =  "#FFC0CB"
        this.colorNames2Hex["plum"] =  "#DDA0DD"
        this.colorNames2Hex["powderblue"] =  "#B0E0E6"
        this.colorNames2Hex["purple"] =  "#800080"
        this.colorNames2Hex["red"] =  "#FF0000"
        this.colorNames2Hex["rosybrown"] =  "#BC8F8F"
        this.colorNames2Hex["royalblue"] =  "#4169E1"
        this.colorNames2Hex["saddlebrown"] =  "#8B4513"
        this.colorNames2Hex["salmon"] =  "#FA8072"
        this.colorNames2Hex["sandybrown"] =  "#F4A460"
        this.colorNames2Hex["seagreen"] =  "#2E8B57"
        this.colorNames2Hex["seashell"] =  "#FFF5EE"
        this.colorNames2Hex["sienna"] =  "#A0522D"
        this.colorNames2Hex["silver"] =  "#C0C0C0"
        this.colorNames2Hex["skyblue"] =  "#87CEEB"
        this.colorNames2Hex["slateblue"] =  "#6A5ACD"
        this.colorNames2Hex["slategray"] =  "#708090"
        this.colorNames2Hex["slategrey"] =  "#708090"
        this.colorNames2Hex["snow"] =  "#FFFAFA"
        this.colorNames2Hex["springgreen"] =  "#00FF7F"
        this.colorNames2Hex["steelblue"] =  "#4682B4"
        this.colorNames2Hex["tan"] =  "#D2B48C"
        this.colorNames2Hex["teal"] =  "#008080"
        this.colorNames2Hex["thistle"] =  "#D8BFD8"
        this.colorNames2Hex["tomato"] =  "#FF6347"
        this.colorNames2Hex["turquoise"] =  "#40E0D0"
        this.colorNames2Hex["violet"] =  "#EE82EE"
        this.colorNames2Hex["wheat"] =  "#F5DEB3"
        this.colorNames2Hex["white"] =  "#FFFFFF"
        this.colorNames2Hex["whitesmoke"] =  "#F5F5F5"
        this.colorNames2Hex["yellow"] =  "#FFFF00"
        this.colorNames2Hex["yellowgreen"] =  "#9ACD32"
    }

    ColorLuminance( hex, lum) {
        // validate hex string
        hex = hex.replace(/[^0-9a-f]/gi, '');
        if (hex.length < 6) {
            hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
        }
        lum = lum || 0;
        // convert to decimal and change luminosity
        var rgb = "#", c, i;
        for (i = 0; i < 3; i++) {
            c = parseInt(hex.substr(i*2,2), 16);
            c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
            rgb += ("00"+c).substr(c.length);
        }
        return rgb;
    }

    ChangeColorBrightness( colorstr, correctionFactor){
        var red = 0
        var green = 0
        var blue = 0
    	
    	var hex = colorstr.toUpperCase();
    	if( this.colorNames2Hex.hasOwnProperty(colorstr.toLowerCase()) ){
    		hex = this.colorNames2Hex[ colorstr.toLowerCase() ];
    	}
    	
    	if( hex.startsWith("#") ){
            if( hex.length == 4 ){
                hex = "#" + hex.charAt(1) + hex.charAt(1) +
                        hex.charAt(2) + hex.charAt(2) +
                        hex.charAt(3) + hex.charAt(3);
            }
            
            if( hex.length >= 7 ){
                red = parseInt(hex.substring(1, 3), 16).valueOf();
                green = parseInt(hex.substring(3, 5), 16).valueOf();
                blue = parseInt(hex.substring(5, 7), 16).valueOf();
            }
        }
    	
        if (correctionFactor < 0) {
            correctionFactor = 1 + correctionFactor;
            red *= correctionFactor;
            green *= correctionFactor;
            blue *= correctionFactor;
        } else {
            red = (255 - red) * correctionFactor + red;
            green = (255 - green) * correctionFactor + green;
            blue = (255 - blue) * correctionFactor + blue;
        }
        
        // convert to hex again 
        var rs = this.hexpad('\\x0' + red.toString(16))//(Integer.toHexString(red));
        var gs = this.hexpad('\\x0' + green.toString(16))//(Integer.toHexString(green));
        var bs = this.hexpad('\\x0' + blue.toString(16))//(Integer.toHexString(blue));
        return "#" + rs + gs + bs;
    }

    hexpad( s) {
        return (s.length == 1) ? "0" + s : s;
    }

    static BlackOrWhiteOnABackground(bgColor ){
        // compare with black
        var black = ColorUtils.lumdiff( bgColor.r, bgColor.g, bgColor.b, 0, 0, 0 );
        var white = ColorUtils.lumdiff( bgColor.r, bgColor.g, bgColor.b, 255, 255, 255 );
        //System.out.println( bgColor.toHex() + "; " + black + "; " + white );
        return (black > white) ? "black" : "white";
    }

        /** originally from 
     * http://www.splitbrain.org/blog/2008-09/18-calculating_color_contrast_with_php
     * this is the most complex but seems to work best. It uses the luminosity to
     * calculate the difference between the given colors. The returned value
     * should be bigger than 5 for best readability.
     */
    static lumdiff( R1, G1, B1, R2, G2, B2) {
        var L1 = 0.2126 * Math.pow(R1 / 255, 2.2)
                + 0.7152 * Math.pow(G1 / 255, 2.2)
                + 0.0722 * Math.pow(B1 / 255, 2.2);

        var L2 = 0.2126 * Math.pow(R2 / 255, 2.2)
                + 0.7152 * Math.pow(G2 / 255, 2.2)
                + 0.0722 * Math.pow(B2 / 255, 2.2);

        return (L1 > L2) ? (L1 + 0.05) / (L2 + 0.05) : (L2 + 0.05) / (L1 + 0.05);
    }  
}

export default ColorUtils