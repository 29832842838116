import Vue from "vue";
import createAuth0Client from "@auth0/auth0-spa-js";
import axios from 'axios'
import router from '../router'
import Db from '../dbop/Db'
import EventBus from '../components/EventBus'

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

let instance;

export const getInstance = () => instance;

export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}) => {
  if (instance) return instance;

  instance = new Vue({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: null,
        popupOpen: false,
        error: null
      };
    },
    methods: {
      async loginWithPopup(options, config) {
        this.popupOpen = true;

        try {
          await this.auth0Client.loginWithPopup(options, config);
          this.user = await this.auth0Client.getUser();
          this.isAuthenticated = await this.auth0Client.isAuthenticated();
          this.error = null;
        } catch (e) {
          console.error(e);
          this.error = e;
        } finally {
          this.popupOpen = false;
        }
      },
      async handleRedirectCallback() {
        this.loading = true;
        try {
          await this.auth0Client.handleRedirectCallback();
          this.user = await this.auth0Client.getUser();
          this.isAuthenticated = true;
          this.error = null;
        } catch (e) {
          this.error = e;
        } finally {
          this.loading = false;
        }
      },
      loginWithRedirect(o) {
        return this.auth0Client.loginWithRedirect(o);
      },
      getIdTokenClaims(o) {
        return this.auth0Client.getIdTokenClaims(o);
      },
      getTokenSilently(o) {
        return this.auth0Client.getTokenSilently(o);
      },
      getTokenWithPopup(o) {
        return this.auth0Client.getTokenWithPopup(o);
      },
      logout(o) {
        return this.auth0Client.logout(o);
      }
    },
    async created() {
      this.auth0Client = await createAuth0Client({
        ...options,
        client_id: options.clientId,
        redirect_uri: redirectUri
      });

      try {
        if (
          window.location.search.includes("code=") &&
          window.location.search.includes("state=")
        ) {
          const { appState } = await this.auth0Client.handleRedirectCallback();
          this.error = null;
          onRedirectCallback(appState);
        }
      } catch (e) {
        this.error = e;
      } finally {
        this.isAuthenticated = await this.auth0Client.isAuthenticated();
        this.user = await this.auth0Client.getUser();
        this.loading = false;
        if(this.user!= undefined && this.user != null){
          EventBus.$emit('loadMess', "Wait,signing in...")
          EventBus.$emit('hide-canvas', {'state':true,'src':'authwrapper','type':'normal'})
          var arSourceAndID = this.user.user_id.split('|');

          var id = arSourceAndID[1];
          var source = arSourceAndID[0];
          var name = "";
          var email = "";

          if (this.user.name) {
            name = this.user.name;
          } else {
            name = this.user.nickname;
          }

          if ('email' in this.user) {
            email = this.user.email;
          }          
          EventBus.$emit('authUser',this.user)
          var doLogin = Db.socialLogin(this.$hostname,id,source,name)
          doLogin.then(res=>{
            if(res.status == 200){
                //console.log(res)
                if(res.data.hasOwnProperty('error')){
                  console.log(res.data) 
                  this.log_check_status = res.data.error
                }else{
                  localStorage.setItem('usertoken', JSON.stringify(res.data))
                  // this.userName = res.data.name
                  // this.$root.$emit('bv::toggle::collapse', 'show_login')
                  // this.loginType = 0
                  // this.email = ''
                  // this.password = ''
                  router.push({ name: 'ToolWindow' })
                  EventBus.$emit('logged-in', '100')
                  EventBus.$emit('logtype',2)
                  // this.afterLogin()
                }                            
            }
          }).catch(err => {
            console.log('server connection error '+err)
          }) 
        }
      }
    }
  });

  return instance;
};

export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth = useAuth0(options);
  }
};
