import PlotBase from "./PlotBase";
import treePlotMode from '../treeObjects/treePlotMode'
import Point from '../treePainter/Point'
import StringOps from "../utils/StringOps";

class ColorStrips extends PlotBase{
    constructor(layer_tree_charts, disable_all_dataset, treeSVG){
        super()
        this.layer_tree_charts = layer_tree_charts
        this.disable_all_dataset = disable_all_dataset
        this.chart_plots_enabled = treeSVG.chart_plots_enabled
        this.treeSVG = treeSVG
        this.currentMostRightPosition = this.treeSVG.getLeafMostRightPosition()
        this.plotmode = treeSVG.getPlotMode()
        this.phylotree = treeSVG.getPhyloTree()
        this.LeafInternalID2NodePosisionts = this.getLeafInternalID2NodePosisionts()
        this.hmLeafID2NodePositions = this.gethmLeafID2NodePositions()
        this.BranchLengthPositions = this.getBranchLengthPositions()
        this.pxPerHeight = this.treeSVG.pxPerHeight
        this.space_between_datasets = this.treeSVG.space_between_datasets

        this.colorContainer = null
        this.id2colors = {}
        this.arColorObjectShapes = []
        this.isRecycleColor = true
        this.defaultColor = "white";
    }

    setLeafInternalID2NodePosisionts(LeafInternalID2NodePosisionts){
        this.LeafInternalID2NodePosisionts = LeafInternalID2NodePosisionts
    }

    setRootXY(rootXY){
        this.rootXY = rootXY
    }

    setPlotMode(plotmode){
        this.plotmode = plotmode
    }

    getAngleSpan(){
        return this.treeSVG.getAngleSpan()
    }

    getLeafInternalID2NodePosisionts(){
        return this.treeSVG.getLeafInternalID2NodePosisionts()
    }

    gethmLeafID2NodePositions(){
        return this.treeSVG.gethmLeafID2NodePositions()
    }

    getBranchLengthPositions(){
        return this.treeSVG.getBranchLengthPositions()
    }

    getLeafMostRightPosition(){
        return this.currentMostRightPosition
    }

    setLeafMostRightPosition(currentMostRightPosition){
        this.currentMostRightPosition = currentMostRightPosition
    }

    setColorData( datasetID, coldatCon) {
        /*
         * all old data will be deleted/ discarded
         */
        this.datasetContentAsString = coldatCon.getOriginal_datastring();
        this.id2colors = {};// internal ID 2 colors; March 21, 2014;
        this.radius = coldatCon.getObjectRadius();
        this.plotWidth = coldatCon.getPlotwidth();
        this.arColorObjectShapes = coldatCon.getColorObjectShapes(); // March 14, 2014
        this.checkLineWidth = coldatCon.getCheckLineWidth(); // May 31, 2013
        this.opacity = coldatCon.getOpacity();
        this.datasetID = datasetID; // may 24, 2011;
        this.linestyle = coldatCon.getLineStyle();
        this.setLegendEntry(coldatCon.getLegend()); // March 20, 2014 --

        // May 30, 2013 --
        this.colorContainer = coldatCon;

        // if plottypes is empty, add strip as default; March 22, 2011
        if (this.arColorObjectShapes.length <=0) {
            this.arColorObjectShapes.push(this.plottype);
        }
        /*
         * colors
         */
        for (var ind in coldatCon.getColorsets()) {
            var colorset  = coldatCon.getColorsets()[ind]
            var acolor = colorset.getColors();
            var mode = colorset.getMode();
            var leafID = colorset.getNodeIDs()[0]; // only the first element will be used for now

            /**
             * Dec 16, 2015; * added support for collapse at internal nodes ... regardless
             * 'mode'
             */
            if (colorset.getNodeIDs().length >= 2) {
                var lca = this.phylotree.getLCA(colorset.getNodeIDs());
                if (lca != null) {
                    this.id2colors[lca.getInternalID()] =  acolor;
                }
            } //

            /**
             * set colors according to modes
             */
            if (mode.length <=0) {
                var node = this.phylotree.getNodeByID(leafID);
                if (node != null) {
                    this.id2colors[node.getInternalID()] =  acolor;
                }
            } else {
                leafID = leafID.toLowerCase();
                if (StringOps.equalsIgnoreCase( mode,("prefix"))) {
                    for (var ind in this.phylotree.getLeafNodes()) {
                        var node = this.phylotree.getLeafNodes()[ind]
                        if (node.getID().toLowerCase().startsWith(leafID)) {
                            this.id2colors[node.getInternalID()] =  acolor;
                        }
                    } // iterate leaf nodes
                } else if (StringOps.equalsIgnoreCase(mode,("suffix"))) {
                    for (var ind in this.phylotree.getLeafNodes()) {
                        var node = this.phylotree.getLeafNodes()[ind]
                        if (node.getID().toLowerCase().endsWith(leafID)) {
                            this.id2colors[node.getInternalID()] =  acolor;
                        }
                    } // iterate leaf nodes
                } else if (StringOps.equalsIgnoreCase(mode,("anywhere"))) {
                    for (var ind in this.phylotree.getLeafNodes()) {
                        var node = this.phylotree.getLeafNodes()[ind]
                        if (node.getID().toLowerCase().includes(leafID)) {
                            this.id2colors[node.getInternalID()] =  acolor;
                        }
                    } // iterate leaf nodes

                } else if (StringOps.equalsIgnoreCase(mode,("AD"))) {
                    var node = this.phylotree.getLCA(colorset.getNodeIDs());
                    if (node != null) {
                        this.addColorToAllDescendentLeaf(node, acolor);
                    }
                }
            } // different modes
        } // iterate colorsets
        this.setActive(true);
    } // setColorDataSet

    addColorToAllDescendentLeaf( node, colors) {
        if (node.isleaf()) {
            this.id2colors[node.getInternalID()] =  colors;
        } else {
            for (var ind in node.getDescendents()) {
                var dnode = node.getDescendents()[ind]
                this.addColorToAllDescendentLeaf(dnode, colors);
            }
        }
    } // addColorToAllDescendentLeaf

    makeOrUpdatePlot() { // color data
        /*
         * replot everything; to be changed in the future
         *
         */
        
        // default stroke color
        var defaultStrokeColor = this.colorContainer.getDefaultStrokeColor();
        // default stroke width
        var strokewidth = this.colorContainer.getDefaultStrokeWidth();

        if (this.active && !this.disable_all_dataset && this.chart_plots_enabled) {
            this.initLayer(this.layer_tree_charts) // sep 22, 2014; now all charts will be added layer_tree_charts
            Point.clearAllChildNodesFromSVGGElement(this.layer);
            this.layer.attr("fill-opacity",(this.opacity));
            /*
             * >>>>>>>>>>>> SOME GLOBAL VARIABLES >>>>>>>>>>>
             */
            var mostright = this.currentMostRightPosition + this.space_between_datasets;
            var isCircular = this.plotmode === (treePlotMode.CIRCULAR_CLADOGRAM)
                    || this.plotmode === (treePlotMode.CIRCULAR_PHYLOGRAM);

            /**
             * calculate plot width for the shapes
             */
            var widthPerShape = this.plotWidth / this.arColorObjectShapes.length; // by default take 80 percent of
                                                                                    // the width
            var widthPerShape4plot = widthPerShape * 0.8;
            var widthStrip = widthPerShape * 0.8;

            /**
             * calculate plot height
             */
            var pixelHeightCircularMode = (mostright * Math.PI * (this.getAngleSpan() / 360)
                    / this.phylotree.getMaxVerticalLevel());
            var heightNonStripShapes = (isCircular) ? this.colorContainer.getShapePixelHeight(pixelHeightCircularMode)
                    : this.colorContainer.getShapePixelHeight(this.pxPerHeight);

            /**
             * adjust widthheight at the end ... for non-strip color objects ...
             */
            var pixelWidthHeight = widthPerShape4plot < heightNonStripShapes ? widthPerShape4plot
                    : heightNonStripShapes;

            // <<<<<<<<<<<<<<<<< GLOBAL VARIABLES END HERE <<<<<<<<<<<<<<

            // iterate hashPositionOfLeafNodes
            for (var entry in this.LeafInternalID2NodePosisionts) {
                var leaf_internal_id = entry;
                var startpos = this.LeafInternalID2NodePosisionts[entry];
                var angle = startpos.angle;
                var leafnode = this.phylotree.getNodeByID(leaf_internal_id);

                var colors = this.id2colors.hasOwnProperty(leaf_internal_id)
                        ? this.id2colors[leaf_internal_id]
                        : null; // bug fix

                // plot one column for each plottypes
                for (var idx = 0; idx < this.arColorObjectShapes.length; idx++) {

                    // Jan 19, 2017; stop if colors are not recycled and idx >= colors.size ...
                    if (!this.colorContainer.isRecyclecolor() && colors != null && colors.length <= idx) {
                        break;
                    } //

                    //
                    var current_type = this.arColorObjectShapes[idx];
                    // March 22, 2011; if array colors is shorter than plottypes, colors are
                    // recycled
                    // March 21, 2014; add support for strokecolor; and default stroke color
                    var colorEx = (colors != null)
                            ? (colors.length > idx) ? colors[idx] : colors[idx % colors.length]
                            : null;
                    var color = colorEx === null ? this.defaultColor : colorEx.getColor();
                    var strokecolor = colorEx === null ? "" : colorEx.getStrokeColor();
                    if (strokecolor.length <=0 && defaultStrokeColor.length >=1
                            && !StringOps.equalsIgnoreCase(defaultStrokeColor,("none"))) {
                        strokecolor = defaultStrokeColor;
                    }

                    /**
                     * -- May 30, 2013 -- start pos is at the center where the shape shoud be
                     * plotted
                     */
                    var start_pos = mostright + idx * (widthPerShape + this.treeSVG.space_between_columns_within_a_dataset);
                    var innerRadius = start_pos - this.rootXY.x;
                    var outterRadius = innerRadius + widthPerShape;

                    // check different plot types
                    // if (!color.equalsIgnoreCase("white")) { // march 21, 2014; will not check if
                    // the color is 'white'
                    if (!StringOps.equalsIgnoreCase(current_type,("strip"))) {

                        /**
                         * --- if NOT strip --
                         */
                        var ashape = this.treeSVG.makeAShape(current_type,
                                start_pos + (widthPerShape - widthPerShape4plot) / 2,
                                startpos.pt.y - pixelWidthHeight / 2, pixelWidthHeight, pixelWidthHeight, color,
                                strokecolor, strokewidth, angle, this.layer);
                    } else {
                        /*
                         * if strips
                         */
                        // if circular mode
                        if (this.plotmode === (treePlotMode.CIRCULAR_CLADOGRAM)
                                || this.plotmode === (treePlotMode.CIRCULAR_PHYLOGRAM)) {

                            /**
                             * -- may 31, 2013 -- for strip in circular mode this part of the following
                             * calculation is newly added : * ( heightStrip / pixelHeightCircularMode ) +
                             * added support for collapsing internally ...
                             */
                            var heightStrip = this.colorContainer.getStripPixelHeight(pixelHeightCircularMode);
                            var angleSpanPerLeafNode = this.getAngleSpan() / this.phylotree.getMaxVerticalLevel()
                                    * (heightStrip / pixelHeightCircularMode);

                            if (leafnode.isCollapse()) {
                                angleSpanPerLeafNode *= (Math.log10(leafnode.getNumberOfLeafDescendents()) + 1);
                            }

                            var fan = this.makeFanPlot(this.rootXY, innerRadius, outterRadius,
                                    angleSpanPerLeafNode, this.layer); //2373

                            // March 21, 2014 : apply stroke color
                            if (strokecolor.length >=1 && !StringOps.equalsIgnoreCase(strokecolor,("none"))) {
                                fan.attr("stroke", strokecolor);
                                fan.attr("stroke-width", strokewidth + "");
                            }
                            fan.attr("fill", color);

                            // Feb 12, 2012
                            if ((this.plotmode === treePlotMode.CIRCULAR_CLADOGRAM
                                    || this.plotmode === treePlotMode.CIRCULAR_PHYLOGRAM)) {
                                fan.transform({rotation:-angle, cx:this.rootXY.x, cy:this.rootXY.y});
                            }
                        } else {
                            /**
                             * strip in non-circular mode + adjust strip height according to whether the
                             * node is collapsed !!
                             */
                            var heightStrip = this.colorContainer.getStripPixelHeight(this.pxPerHeight);
                            if (leafnode.isCollapse()) {
                                heightStrip *= (Math.log10(leafnode.getNumberOfLeafDescendents()) + 1);
                            }

                            //
                            var rect = this.layer.rect(widthStrip, heightStrip).move(start_pos + (widthPerShape - widthStrip) / 2,
                            startpos.pt.y - heightStrip / 2).radius(0,0)                            

                            // March 23, 2014
                            if (strokecolor.length >=1 && !StringOps.equalsIgnoreCase(strokecolor,("none"))) {
                                rect.attr("stroke", strokecolor);
                                rect.attr("stroke-width", strokewidth + "");
                            }
                            rect.attr("fill", color);
                        } // if circular mode
                    } // different types; rect, circle, check or others
                        // } // if color isn't default color
                } // plot a column for each plottype
            } // iterate hashPositionOfLeafNodes

            // at the end, update currentMostRightPosition; March 25, 2011;
            this.currentMostRightPosition += (this.arColorObjectShapes.length * widthPerShape
                    + (this.arColorObjectShapes.length - 1) * this.treeSVG.space_between_columns_within_a_dataset)
                    + this.space_between_datasets;
        } // is current chart is active
    }// makeOrUpdatePlot

    makeFanPlot( center, innerRadius, outterRadius, anglespan, parent_layer) {
		/*
		 * 1. get four points that mark the fan-shape locations of the four points are:
		 * 1 ---> 2 | | |arc | arc | v 4 <--- 3
		 */
		var p1 = Point.getCoordinatesOnCircle(center, innerRadius, 360 - anglespan / 2);
		var p2 = Point.getCoordinatesOnCircle(center, outterRadius, 360 - anglespan / 2);
		var p3 = Point.getCoordinatesOnCircle(center, outterRadius, anglespan / 2);
		var p4 = Point.getCoordinatesOnCircle(center, innerRadius, anglespan / 2);
        // Feb 12, 2013: large_arc
        var large_arc = (anglespan >= 180) ? 1 : 0; // Feb 12, 2013; use global angle_span?
		
		// 2. make fan using path
		var segs = new Point()
		segs.appendSegItem(Point.createPointStr(p1.x, p1.y)); // move to p1
		segs.appendSegItem(Point.createPointStr(p2.x, p2.y,'L')); // line from p1 to p2;
		segs.appendSegItem(Point.createArcStr(p3.x, p3.y, outterRadius, 0, large_arc, 0)); // arc from p2 to p3
		segs.appendSegItem(Point.createPointStr(p4.x, p4.y,'L')); // line from p3 to p4;
		segs.appendSegItem(Point.createArcStr(p1.x, p1.y, innerRadius, 0, large_arc, 1)); // arc from p4 to p1
		// segs.appendSegItem(Point.createSVGPathSegClosePath()); // close path
        var fan = parent_layer.path(segs.getSegPathListStr())
		return fan;
	}
    
}

export default ColorStrips