import ColourGradient from './ColourGradient'

class Rainbow{

    constructor(){
        this.minNum = 0;
		this.maxNum = 100;
        this.colours = ["red", "yellow", "lime", "blue"];
        this.colourGradients = []
		this.setSpectrum(this.colours);
    }

    /**
	 * Sets the spectrum of the Rainbow object. By default, the spectrum is a rainbow. 
	 * You must have a minimum of two colours, but you can specify more than two colours. 
	 * Colours can be in the form "red", "ff0000", or "#ff0000". 
	 * For example, <code>rainbow.setSpectrum("red", "yellow", "white");</code>
	 * makes the "Rainbow" a colour gradient from red to yellow to white. 
	 * @param spectrum Two or more Strings representing HTML colours,
	 * or pass in a whole String array of length 2 or greater
	 * @throws HomogeneousRainbowException if there is less than two arguments
	 * @throws InvalidColourException if one of the arguments is an invalid colour
	 */
    setSpectrum(spectrum) {
			if (spectrum.length < 2) {
			} else {
				var increment = (this.maxNum - this.minNum) / (spectrum.length - 1);
				var firstGradient = new ColourGradient();
				firstGradient.setGradient(spectrum[0], spectrum[1]);
				firstGradient.setNumberRange(this.minNum, this.minNum + increment);

				this.colourGradients = [];
				this.colourGradients.push(firstGradient);

				for (var i = 1; i < spectrum.length - 1; i++) {
					var colourGradient = new ColourGradient();
					colourGradient.setGradient(spectrum[i], spectrum[i + 1]);
					colourGradient.setNumberRange(this.minNum + increment * i, this.minNum + increment * (i + 1));
					this.colourGradients.push(colourGradient);
				}

				this.colours = spectrum;
			}
    }
    
    /**
	 * Sets the number range of the Rainbow object. By default, it is 0 to 100.
	 * @param minNumber The minimum number of the number range
	 * @param maxNumber The maximum number of the number range
	 * @throws NumberRangeException if minNumber is greater than maxNumber
	 */
	setNumberRange( minNumber, maxNumber){
		try {
			if (maxNumber > minNumber) {
				this.minNum = minNumber;
				this.maxNum = maxNumber;
				this.setSpectrum(this.colours);
			} else {
				// throw new NumberRangeException(minNumber, maxNumber);
			}
		}catch (er){
        }		
    }
    
    
    colourAt( number) {
		if (this.colourGradients.length == 1) {
			return this.colourGradients[0].colourAt(number);
		} else {
			var segment = (this.maxNum - this.minNum) / (this.colourGradients.length);
			var index = Math.min(Math.floor((Math.max(number, this.minNum) - this.minNum) / segment), this.colourGradients.length - 1);
			return this.colourGradients[index].colourAt(number);
		}
	}

}

export default Rainbow