import ColorAttributes from "./ColorAttributes";
import StringOps from '../utils/StringOps'

class DecorationItemAttributes extends ColorAttributes{
    constructor(attributesstr){
        super()
        
        if(attributesstr.length >=1){
            this.setDecorationItemAttributes(attributesstr);
        }
    }

    /**
	 * @param attributesstr - shape[:itemWidth[:itemHeight]],color:strokecolor
	 */
	setDecorationItemAttributes( attributesstr) {
		var es =StringOps.JsArrayStringToArrayList( StringOps.splitStringBySeperator( attributesstr, "," ));

		var length = es.length;
		if (length >= 1) {
			this.setShapeAttributes(es[0].trim());
		}
		if (length >= 2) {
			this.setColorAttributesByString(es[1].trim());
		}
    }
    
    /**
	 * @param string - shape[:itemWidth[:itemHeight]]
	 */
	setShapeAttributes( string) {
		var es = StringOps.JsArrayStringToArrayList( StringOps.splitStringBySeperator(string, ":") );

		var length = es.length;
		
		// shape
		if (length >= 1) {
			this.setStyle(es[0].trim());
		}
		
		// parase itemWidth 
		if (length >= 2) {
			try {
				var width = parseFloat(es[1].trim());
				this.setWidth(width);
				this.setHeight(width);
			} catch ( e) {
				console.log(" decoItemAttributes parsing error: item width ");
			}
		}
		
		// parase itemWidth 
		if (length >= 3) {
			try {
				var height = parseFloat(es[2].trim());
				this.setHeight(height);
			} catch ( e) {
				console.log(" decoItemAttributes parsing error: item width ");
			}
		} //  
	}// set shape attributes
}

export default DecorationItemAttributes