import PlotBase from "./PlotBase";
import treePlotMode from '../treeObjects/treePlotMode'
import Point from '../treePainter/Point'
import StringOps from '../utils/StringOps'

const FontStyle = {
    'NORMAL':'normal',
    'ITALIC':'italic',
    'OBLIQUE':'oblique',
    }
Object.freeze(FontStyle)

const FontWeight = {
    'NORMAL':'normal',
    'BOLD':'bold',
    'BOLDER':'bolder',
    'LIGHTER':'lighter',
    }
Object.freeze(FontWeight)

class Heatmap extends PlotBase{
    constructor(layer_tree_charts, disable_all_dataset, treeSVG){
        super()
        this.ndCon = null;
        this.row2data = {};
        this.row2radius = {}; 
        this.actualPixelColWidth = 20
        this.actualPixelRowHeight = 20;
        this.defaultColor = "white";

        this.layer_tree_charts = layer_tree_charts
        this.disable_all_dataset = disable_all_dataset
        this.chart_plots_enabled = treeSVG.chart_plots_enabled
        this.treeSVG = treeSVG
        
        this.currentMostRightPosition = this.treeSVG.getLeafMostRightPosition()
        this.plotmode = treeSVG.getPlotMode()
        this.phylotree = treeSVG.getPhyloTree()
        this.pxPerHeight = this.treeSVG.pxPerHeight
        this.space_between_datasets = this.treeSVG.space_between_datasets
        this.default_tick_label_font_size = this.treeSVG.default_tick_label_font_size
        this.circular_tree_plot_in_clockwise_mode = this.treeSVG.circular_tree_plot_in_clockwise_mode

        this.LeafInternalID2NodePosisionts = this.getLeafInternalID2NodePosisionts()
    }

    getAngleSpan(){
        return this.treeSVG.getAngleSpan()
    }

    setLeafInternalID2NodePosisionts(LeafInternalID2NodePosisionts){
        this.LeafInternalID2NodePosisionts = LeafInternalID2NodePosisionts
    }

    setRootXY(rootXY){
        this.rootXY = rootXY
    }

    setPlotMode(plotmode){
        this.plotmode = plotmode
    }

    getLeafInternalID2NodePosisionts(){
        return this.treeSVG.getLeafInternalID2NodePosisionts()
    }

    gethmLeafID2NodePositions(){
        return this.treeSVG.gethmLeafID2NodePositions()
    }

    getLeafMostRightPosition(){
        return this.currentMostRightPosition
    }

    setLeafMostRightPosition(currentMostRightPosition){
        this.currentMostRightPosition = currentMostRightPosition
    }

    getNdCon(){
        return this.ndCon
    }

    setHeatmapDataSet( newID, mdatCon) {
        this.ndCon = mdatCon;
        this.datasetID = newID;
        this.setActive(true);
        this.row2data = this.ndCon.getTreeNodeID2Data(); //
        this.datasetContentAsString = mdatCon.getOriginal_datastring(); // get original user input as String ; April
                                                                        // 5, 2011;
        this.opacity = this.ndCon.getOpacity();
        this.setLegendEntry(mdatCon.getLegend()); // march 20, 2014 --

        /**
         * note: ndCon.getMaxValue() and ndCon.getMinValue() refer to the
         * max and min of user-data plus user-input min and max --
         */
        try {
            this.ndCon.getRainbow().setNumberRange(this.ndCon.getMinValue(), this.ndCon.getMaxValue());
        } catch ( e) {
        }
    }

    recalcEssentialVariables() {
        /**
         * March 30, 2015. calculate radius ...
         */
        this.row2radius = [];
        
        // variables for dotplot mode --
        var actualPixelColumItemHeight = this.pxPerHeight - this.ndCon.getPlotColMargin();
        var realPxPerValue = actualPixelColumItemHeight > this.actualPixelColWidth ? this.actualPixelColWidth : actualPixelColumItemHeight;

        // calculate other global parameters
        // note: pwidth_per_value will be calculate on per col basis ...; march 29, 2015
        // ..
        for (var entry in this.LeafInternalID2NodePosisionts) {
            var leaf_internal_id = entry;
            if (this.row2data.hasOwnProperty(leaf_internal_id)) {
                const cdata = this.row2data[leaf_internal_id];
                var max =  this.ndCon.getMaxValue();

                var radius = [];
                for (var i = 0; i < cdata.length; i++) {
                    var value = cdata[i];
                    
                    // calculate radius; by area or not ...
                    var r = 0;
                    if (value > 0 && max > 0) {
                        r = (Math.sqrt(value) / Math.sqrt(max) / 2 * realPxPerValue);
                    }
                    radius.push(r);
                }

                //
                this.row2radius[leaf_internal_id] =  radius;
            } // if leaf internal id is contained ...
        }
    }

    addHighlightToRowPlotByLeafInternalID(leaf_internal_id) {
        if (this.hmInternalID2RowPlot.hasOwnProperty(leaf_internal_id)) {
            this.hmInternalID2RowPlot[leaf_internal_id].attr("stroke", "red");
        }
    }

    removeHighlightFromRowPlotByLeafInternalID(leaf_internal_id) {
        if (this.hmInternalID2RowPlot.hasOwnProperty(leaf_internal_id)) {
            this.hmInternalID2RowPlot[leaf_internal_id].attr("stroke",null);
        }
    }
    
    makeOrUpdatePlot() { // dotplot ...
        /**
         * moved to here from the constructor ...
         */
        this.actualPixelColWidth = this.ndCon.getPlotColWidth() - this.ndCon.getPlotColMargin();
        this.recalcEssentialVariables();
        
        var defaultStrokeColor = this.ndCon.getDefaultStrokeColor();
        var strokewidth = this.ndCon.getDefaultStrokeWidth();

        this.currentMostRightPosition = this.getLeafMostRightPosition()

        if (this.active && !this.disable_all_dataset && this.chart_plots_enabled) {
            var mostright = this.currentMostRightPosition + this.space_between_datasets;
            var isCircular = this.plotmode === (treePlotMode.CIRCULAR_CLADOGRAM)
                    || this.plotmode === (treePlotMode.CIRCULAR_PHYLOGRAM);

            /**
             * grid labels; 
             * April 3, 2015 ... 
             * NOTE: grid labels is not supported under circular mode ...
             */
            if (this.ndCon.isShowGridLabel() && !this.treeSVG.isCircular) {
                this.layer_heatmap_column_labels = this.layer_tree_charts.group().id('layer_heatmap_column_labels');
                Point.clearAllChildNodesFromSVGGElement(this.layer_heatmap_column_labels);

                // get the first leaf
                var firstleaf_id = this.phylotree.getFirstLeafNode().getInternalID();
                var firstleafPos = this.LeafInternalID2NodePosisionts[firstleaf_id];

                var realstart = mostright; // 0

                /**
                 * March 31, 2015 ... Nov 29, 2015;
                 */
                var texty = (firstleafPos.pt.y
                        - (this.pxPerHeight
                                * (1 + Math.log10(this.phylotree.getFirstLeafNode().getNumberOfLeafDescendents()))) / 2
                        - 5);

                // plot data value ... 
                var labels = this.ndCon.getArHeatmapColumnLables();
                // console.log(labels)
                for (var idxlabel in labels) {                    
                    // get font size --; Nov 29, 2015;
                    var font_size = this.ndCon.getColumnLabelFontSize() > 0 ? this.ndCon.getColumnLabelFontSize()
                            : this.default_tick_label_font_size;
                    var text_angle = this.ndCon.getColumnLabelTextAngle();

                    var textx = realstart + this.ndCon.getPlotColWidth() / 2;
                    // adjust textx according to text angle --
                    if (text_angle < 0 && text_angle > -180) {
                        textx += font_size * 1 / 4;
                    } else {
                        textx -= font_size * 1 / 4;
                    }

                    var txtGridLabel = this.layer_heatmap_column_labels.text(labels[idxlabel]+"").move(textx, texty)                    
                    txtGridLabel.font({size:font_size});

                    // set align anchor according to text angle
                    if (text_angle < 0 && text_angle > -180) {
                        txtGridLabel.attr("text-anchor", "start"); // March 31, 2015 ... align to start ...
                    } else {
                        txtGridLabel.attr("text-anchor", "end"); // March 31, 2015 ... align to start ...
                    }

                    // Nov 29, 2015; font italic --
                    if (this.ndCon.isColumnLableFontItalic()) {
                        txtGridLabel.font({style:FontStyle.ITALIC})
                    }

                    // font bold
                    if (this.ndCon.isColumnLableFontBold()) {
                        txtGridLabel.font({weight:FontWeight.BOLD})
                    }

                    // make text vertical --
                    txtGridLabel.transform({rotation:text_angle, cx:textx, cy:texty})

                    // increase realstart position
                    realstart += this.ndCon.getPlotColWidth();
                    // console.log(this.ndCon.isShowGridLabel(),txtGridLabel)
                } // s

                // Nov 29, 2015; color
                if (this.ndCon.getColumnLabelFontColor().length >=1) {
                    this.layer_heatmap_column_labels.attr("fill", this.ndCon.getColumnLabelFontColor());
                }
                this.layer_heatmap_column_labels.show()
            } else {
                this.layer_heatmap_column_labels.hide()
                Point.deleteChildNodeFromLayerTree(this.layer_heatmap_column_labels);
            }
            // <<<<<<<<<<< grid and axis <<<<<<<<<<<

            if(isCircular){
                this.layer_heatmap_column_labels.hide()
            }
            /*
             * >>>>>>>>>> the main layer >>>>>>>>>>>>>
             */
            this.initLayer(this.layer_tree_charts)
            // Point.clearAllChildNodesFromSVGGElement(this.layer);
            this.layer.attr("fill-opacity",(this.opacity));

            // Sep 28, 2014; create a SVG group element to hold data values
            const showdataSVGGelement = this.layer.group().id('hm_showdataSVGGelement')
            showdataSVGGelement.font({size:this.ndCon.getShowDataFontSize()});
            if (this.ndCon.isbShowDataFontItalic()) {
                showdataSVGGelement.font({style:FontStyle.ITALIC})
            }
            /**
             * font color; April 4, 2015; check if font color is set NOTE: by default, color
             * is black, so there is no need to set to black if it is ..
             */
            if (this.ndCon.isShowDataValueColorSet() && !StringOps.equalsIgnoreCase( this.ndCon.getShowDataFontColor(),("black"))) {
                showdataSVGGelement.attr("fill", this.ndCon.getShowDataFontColor());
            }

            /**
             * variables for circular mode
             */
            var angleSpanPerLeafNode = this.getAngleSpan() / this.phylotree.getMaxVerticalLevel()
                    * (1 - (this.ndCon.getPlotColMargin() / this.ndCon.getPlotColWidth() / 2));
            
            // iterate hashPositionOfLeafNodes
            for (var entry in this.LeafInternalID2NodePosisionts) {
                const leaf_internal_id = entry;
                var startpos = this.LeafInternalID2NodePosisionts[entry];
                var angle = startpos.angle;

                if (this.row2data.hasOwnProperty(leaf_internal_id)) {
                    const cdata 	= this.row2data[leaf_internal_id];
                    const radius 	= this.row2radius[leaf_internal_id];

                    var start_pos = mostright; // it'll be changed at the end of the for loop

                    /*
                     * May 26, 2011; each row of data now will be added into a SVGG element -- then
                     * create a tooltip for each row of data
                     */
                    const rowplot = this.layer.group().id('hm_row_plot')
                    this.hmInternalID2RowPlot[leaf_internal_id] =  rowplot;
                    var that = this
                    rowplot.mouseover(function(evt) {
                        that.treeSVG.addHighlightToAllRowplotsByInternalLeafID(leaf_internal_id);
                    })
                    rowplot.mouseout(function(evt) {
                        that.treeSVG.removeHighlightFromAllRowplotsByInternalLeafID(leaf_internal_id);
                    })
                    
                    // iterate data for current node
                    var rowdata_idx = 0;
                    for (var ind in cdata) {
                        var current_value = cdata[ind]
                        /**
                         * get stroke color and fill color
                         */
                        var color = "#" + this.ndCon.getRainbow().colourAt(current_value);
                        var strokecolor = defaultStrokeColor;
                        var node = this.phylotree.getNodeByID(leaf_internal_id);
                        
                        // if dotplot mode ... 
                        if( this.ndCon.isbHeatmapDotplotMode() ) {
                                                            
                            var current_radius = radius[rowdata_idx];
                            // get current radius --
                            var c;
                            if (StringOps.equalsIgnoreCase(this.ndCon.getDotPlotsShape(),("rect"))) {
                                c = rowplot.rect(current_radius * 2, current_radius * 2).move(start_pos + this.ndCon.getPlotColWidth() / 2 - current_radius,
                                startpos.pt.y - current_radius).radius(this.ndCon.getRectRoundedCorner(),
                                this.ndCon.getRectRoundedCorner())
                            } else {
                                c = rowplot.circle(current_radius*2).move(start_pos + this.ndCon.getPlotColWidth() / 2 - current_radius,
                                startpos.pt.y-current_radius)
                            }
                            c.attr("fill", color);

                            if (strokecolor.length >=1 && !StringOps.equalsIgnoreCase(strokecolor,("none"))) {
                                c.attr("stroke", strokecolor);
                                c.attr("stroke-width", strokewidth + "");
                            }
                            
                            if (isCircular) {
                                // if circular mode and angle is not zero; May 17, 2011; this part was moved
                                // from the begining of the outter 'for' loop, otherwise the barplots are not
                                // shown correctly                                
                                c.transform({rotation:180 - angle, cx:this.rootXY.x, cy:this.rootXY.y})
                            }

                        } else {
                            // if regular mode // 
                            if (isCircular) {
                                var innerRadius = start_pos - this.rootXY.x + this.ndCon.getPlotColMargin() / 2; 
                                var outterRadius = innerRadius + this.actualPixelColWidth;
                                var fan = this.makeFanPlot(this.rootXY, innerRadius, outterRadius,
                                        (angleSpanPerLeafNode
                                                * (1 + Math.log10(node.getNumberOfLeafDescendents()))), rowplot);

                                // March 21, 2014 : apply stroke color
                                if (strokecolor.length>=1 && !StringOps.equalsIgnoreCase(strokecolor,("none"))) {
                                    fan.attr("stroke", strokecolor);
                                    fan.attr("stroke-width", strokewidth + "");
                                }
                                fan.attr("fill", color);
                                fan.transform({rotation:-angle, cx:this.rootXY.x, cy:this.rootXY.y}); // March 21, 2011; this rotation
                            } else {
                                /**
                                 * make rect ...
                                 */
                                this.actualPixelRowHeight = this.pxPerHeight;
                                this.actualPixelRowHeight = (this.actualPixelRowHeight
                                        * (1 + Math.log10(node.getNumberOfLeafDescendents())))
                                        - this.ndCon.getPlotColMargin();
                                var c = rowplot.rect(this.actualPixelColWidth,
                                    this.actualPixelRowHeight).move(start_pos + this.ndCon.getPlotColWidth() / 2 - this.actualPixelColWidth / 2,
                                startpos.pt.y - this.actualPixelRowHeight / 2).radius(this.ndCon.getRectRoundedCorner(),
                                this.ndCon.getRectRoundedCorner())                                
                                c.attr("fill", color);

                                if (strokecolor.length>=1 && !StringOps.equalsIgnoreCase(strokecolor,("none"))) {
                                    c.attr("stroke", strokecolor);
                                    c.attr("stroke-width", strokewidth + "");
                                }

                                if ((this.plotmode === (treePlotMode.CIRCULAR_CLADOGRAM)
                                        || this.plotmode === (treePlotMode.CIRCULAR_PHYLOGRAM))) {
                                    // if circular mode and angle is not zero; May 17, 2011; this part was moved
                                    // from the begining of the outter 'for' loop, otherwise the barplots are not
                                    // shown correctly
                                    c.transform({rotation:180 - angle, cx:this.rootXY.x, cy:this.rootXY.y}); // March 22, 2011; this
                                }
                            }
                        }


                        /**
                         * March 30, 2015 ...
                         */
                        if (this.ndCon.isbShowData()) {
                            var txtData = this.treeSVG.makeShowDataValue4DotplotsAndHeatMap(
                                    start_pos + this.ndCon.getPlotColWidth() / 2,
                                    startpos.pt.y-10 + this.ndCon.getShowDataFontSize() * 1 / 3, current_value + "",
                                    this.ndCon.getShowDataFontSize(), angle, !this.ndCon.isShowDataValueColorSet(), color,
                                    isCircular, showdataSVGGelement);
                        }

                        // increase start_pos incremetally; March 30, 2015 ...
                        start_pos += this.ndCon.getPlotColWidth();
                        
                        // 
                        rowdata_idx ++;
                    } // multi column plot
                } // if data is valid for current leaf node

            } // iterate hashPositionOfLeafNodes

            // Sep 28, 2014; this will be added at the end so that it'll appear on the top
            // of other layers ...

            /**
             * at the end, update currentMostRightPosition >> April 3, 2015, now max column
             */
            showdataSVGGelement.front()
            this.currentMostRightPosition += (this.ndCon.getPlotColWidth() * this.ndCon.getColumnCountMax()
                    + this.space_between_datasets);
        } else {
            // Point.deleteChildNodeFromLayerTree(this.layer);
        } // is active
    } // makeorupdate plot

    makeFanPlot( center, innerRadius, outterRadius, anglespan, parent_layer) {
		/*
		 * 1. get four points that mark the fan-shape locations of the four points are:
		 * 1 ---> 2 | | |arc | arc | v 4 <--- 3
		 */
		var p1 = Point.getCoordinatesOnCircle(center, innerRadius, 360 - anglespan / 2);
		var p2 = Point.getCoordinatesOnCircle(center, outterRadius, 360 - anglespan / 2);
		var p3 = Point.getCoordinatesOnCircle(center, outterRadius, anglespan / 2);
		var p4 = Point.getCoordinatesOnCircle(center, innerRadius, anglespan / 2);
        var segs = new Point();
		var large_arc = (anglespan >= 180) ? 1 : 0; // Feb 12, 2013; use global angle_span?
		segs.appendSegItem(Point.createPointStr(p1.x, p1.y)); // move to p1
		segs.appendSegItem(Point.createPointStr(p2.x, p2.y, 'L')); // line from p1 to p2;
		segs.appendSegItem(Point.createArcStr(p3.x, p3.y, outterRadius, 0, large_arc, 0)); // arc from p2 to p3
		segs.appendSegItem(Point.createPointStr(p4.x, p4.y,'L')); // line from p3 to p4;
		segs.appendSegItem(Point.createArcStr(p1.x, p1.y, innerRadius, 0, large_arc, 1)); // arc from p4 to p1
        var fan = parent_layer.path(segs.getSegPathListStr());
        return fan;
    }
}

export default Heatmap