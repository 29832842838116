import axios from 'axios'
import EventBus from '../components/EventBus'
import Commons from '../utils/CommonEvol'
import MC from '../treeObjects/MC'

class Db{
    constructor(){
        
    }

    static getUserId(){
        let token = localStorage.getItem("usertoken") 
        let decoded = JSON.parse(token)
        return decoded.userId
    }

    static uploadProj(hostname,projName){
        var resObject = axios.post(hostname+'query/createPro',
        {
            userId: Db.getUserId(),
            projectName: projName,
            description: 'NA'
        })
        return resObject
    }

    static uploadTree(hostname,projdet,treeName,treeFormat,treeContentStr,torder){
        var resObject = axios.post(hostname+'query/createTree',
        {
            userId: Db.getUserId(),
            projectId: projdet.projectId,
            treeName: treeName,
            treeContent: treeContentStr,
            treeFormat: treeFormat,
            torder:torder
        })
        return resObject
    }

    static updateTree(hostname,treeId,format,treeContentStr){
        var resObject = axios.post(hostname+'query/updateTree',
        {
            userId: Db.getUserId(),
            treeId: treeId,
            treeContentStr: treeContentStr,
            format: format
        })
        return resObject
    }

    static getLatestOrderId(hostname,treeDbserial){
        var resObject = axios.post(hostname+'query/getMaxOrder',
        {
            TreeID: treeDbserial,
        })
        return resObject
    }

    static uploadDataSet(hostname,orderId,treeDbserial, datasetname, datasetstring, datasettype){
        var resObject = axios.post(hostname+'query/createDatset',
        {
            userId: Db.getUserId(),
            treeId: treeDbserial,
            datasetName: datasetname,
            order:orderId,//ord.data.ord+1,
            datsetCont: datasetstring,
            datsetType: datasettype
        })
        return resObject
    }

    static updateDatSet(hostname,datasetSerial, datasetstring){
        var resObject = axios.post(hostname+'query/updateDatset',
        {
            dbSerialId: datasetSerial,
            datsetCont: datasetstring,            
        })
        return resObject
    }

    static resetOtherDatasets(hostname,datSetId,dataSetType,treeSerial){
        var catpanelname = Commons.getDataTypeName(dataSetType)
        if(catpanelname === MC.CHARTS){
            //do nothing
        }else{
            axios.post(hostname+'query/resetOtherDataSets',
            {
                userId:Db.getUserId(),
                treeId:treeSerial, //this.activeTree.getdbserial(),
                datSetId:datSetId,
                dataSetType:dataSetType
            }).then(tree => {
                if(tree.status == 200){                    
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }

    static uploadTheTree(hostname,projName,treeName,treeFormat,treeContentStr,projList,treeList){
        let projForTree = Db.checkProjStatus(projList,projName)
        console.log(projForTree)
        if(projForTree == null ){
            var projStat = Db.uploadProj(hostname,projName)
            projStat.then(retDat => {
                if(retDat.status == 200){
                    retDat.data['projectId'] = retDat.data.id
                    var treeStat = Db.uploadTree(hostname,retDat.data,treeName,treeFormat,treeContentStr,0)
                    treeStat.then(res => {
                        if(res.status == 200){
                            Db.resetOtherTreesProj(hostname,{'proj':retDat.data.id,'tree':res.data.id})                                
                            EventBus.$emit('new-tree', {'proj':retDat.data.id,'tree':res.data.id})
                            EventBus.$emit('closeModal',true)
                        }else{
                            Commons.showErrorMessage('error uploading the tree','Error')
                            // console.log('error uploading the tree ',res)
                        }
                    }).catch(err => {
                        console.log(err)
                    })
                }else{
                    Commons.showErrorMessage('error uploading the project','Error')
                    // console.log('error uploading the project ',retDat)
                }
            }).catch(err => {
                console.log(err)
            })
        }else{
            projForTree['projectId'] = projForTree.id
            var treeStat = Db.uploadTree(hostname,projForTree,treeName,treeFormat,treeContentStr,treeList.length+1)
            treeStat.then(res => {
                if(res.status == 200){
                    Db.resetOtherTreesProj(hostname,{'proj':projForTree.id,'tree':res.data.id})                                
                    EventBus.$emit('new-tree', {'proj':projForTree.id,'tree':res.data.id})
                    EventBus.$emit('closeModal',true)
                }else{
                    Commons.showErrorMessage('error uploading the tree','Error')
                }
            }).catch(err => {
                Commons.showErrorMessage(err,'Error')
            })
        }
    }

    static socialLogin(server_add, email_id, socialProvider, name ){
        let resObject = axios.post(server_add+'query/socialSignInSignUp',
        {
          id: email_id,
          first_name:name,
          last_name:'',
          institute:'',
          source:socialProvider
        })
    //     .then(res => {
    //       if(res.status == 200){
    //         //console.log(res)
    //         if(res.data.hasOwnProperty('error')){
    //           console.log(res.data) 
    //           this.log_check_status = res.data.error
    //         }else{
    //           this.$root.$emit('bv::toggle::collapse', 'show_login')
    //           localStorage.setItem('usertoken', JSON.stringify(res.data))
    //           this.userName = res.data.name
    //           this.loginType = 0
    //           this.email = ''
    //           this.password = ''
    //           router.push({ name: 'ToolWindow' })
    //           this.emitMethod()
    //           // this.afterLogin()
    //         }
    //       }else{
    //           this.showErrorMessage('server connection error '+res)
    //       }  
    //   }).catch(err => {
    //           this.showErrorMessage('server connection error '+err)
    //   })        
        return resObject
    }

    static checkProjStatus(projList,projNameTF){
        var found = false
        let projForTree = null
        for(var ind in projList){
            var proj = projList[ind]
            if(proj.text == projNameTF){
                found = true
                projForTree = proj
                return projForTree                    
            }
        }
      return null
    }

    static resetOtherTreesProj(hostname,projTreeId){
        axios.post(hostname+'query/resetOtherProjTrees',
        {
            userId:Db.getUserId(),
            projId:projTreeId.proj,
            treeId:projTreeId.tree
        }).then(tree => {
            if(tree.status == 200){
                EventBus.$emit('projtreeorders', projTreeId)
            }
        }).catch(err => {
            Commons.showErrorMessage(err,'Error')
        })
    }
}

export default Db