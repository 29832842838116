import SVG  from 'svg.js'

class Drag {
    constructor(el){
        this.el = el
        this.constraint = {}
    }

    getBBox(){
        var box = this.el.bbox()
        if(this.el instanceof SVG.Nested) box = this.el.rbox()
        if (this.el instanceof SVG.G || this.el instanceof SVG.Use || this.el instanceof SVG.Nested) {
            box.x = this.el.x()
            box.y = this.el.y()
        }
    return box
    }

    transformPoint(event, offset){
        event = event || window.event
        var touches = event.changedTouches && event.changedTouches[0] || event
        this.p.x = touches.clientX - (offset || 0)
        this.p.y = touches.clientY
        return this.p.matrixTransform(this.m)
    }

    start_drag(e){
        this.parent = this.parent || this.el.parent(SVG.Nested) || this.el.parent(SVG.Doc)
        this.p = this.parent.node.createSVGPoint()
        this.m = this.el.node.getScreenCTM().inverse()
        var box = this.getBBox()
        var anchorOffset;
        this.startPoints = {
            // We take absolute coordinates since we are just using a delta here
            point: this.transformPoint(e, anchorOffset),
            box:   box,
            transform: this.el.transform()
        }
    }

    drag(e){
        var box = this.getBBox()
            , p   = this.transformPoint(e)
            , x   = this.startPoints.box.x + p.x - this.startPoints.point.x
            , y   = this.startPoints.box.y + p.y - this.startPoints.point.y
            , c   = this.constraint
            , gx  = p.x - this.startPoints.point.x
            , gy  = p.y - this.startPoints.point.y
    
        this.el.matrix(this.startPoints.transform).transform({x:gx, y: gy}, true)
    }

    end_drag(e){
        var p = this.drag(e);
    }
    
}

export default Drag
