
class GeneTransferItemAttribute{
    constructor(){
        this.startSrcLeafID = ""
        this.endSrcLeafID = ""
		this.startDestLeafID = ""
        this.endDestLeafID = ""
        this.lineColor = "black"
        this.backgroundcolor = ""
		this.dir = ""
        this.bDashedLine = false 
        this.lineWidth = 1
		this.style = '1'
		this.hasInternalID = false
		this.hasRadius = false
		this.curRadius = 0
		this.hasAngle = false
		this.curAngle = 0
    }

    getStartSrcLeafID() {
		return this.startSrcLeafID;
	}

	setStartSrcLeafID( startLeafID) {
		this.startSrcLeafID = startLeafID;
	}

	getEndSrcLeafID() {
		return this.endSrcLeafID;
	}

	setEndSrcLeafID( endLeafID) {
		this.endSrcLeafID = endLeafID;
	}

	getStartDestLeafID() {
		return this.startDestLeafID;
	}

	setStartDestLeafID( startLeafID) {
		this.startDestLeafID = startLeafID;
	}

	getEndDestLeafID() {
		return this.endDestLeafID;
	}

	setEndDestLeafID( endLeafID) {
		this.endDestLeafID = endLeafID;
	}

	getLineColor() {
		return this.lineColor;
	}

	setLineColor( lineColor) {
		this.lineColor = lineColor;
	}

    isbDashedLine() {
		return this.bDashedLine;
	}

	setbDashedLine( bDashedLine) {
		this.bDashedLine = bDashedLine;
	}

    setLineWidth( line_width) {
		this.lineWidth = line_width;
	}
	
	getLineWidth(){
		return this.lineWidth;
	}

    setBkColor( string) {
		this.backgroundcolor = string;
	}
	
	getBkColor(){
		return this.backgroundcolor;
	}

	setDir(dir){
		this.dir = dir
	}

	getDir(){
		return this.dir
	}

	setStyle(style){
		this.style = style
	}
	getStyle(){
		return this.style
	}

	setHasInternalID(bool){
		this.hasInternalID = bool
	}
	isInternalID(){
		return this.hasInternalID
	}
}

export default GeneTransferItemAttribute