import ItemAttributesBase from './ItemAttributesBase'

class LegendItem extends ItemAttributesBase{
    constructor( cl,na,ra,sh,scol) {
        super()
		this.setColor(cl);
		this.setName(na);
		this.setWidth(ra);
		this.setStyle(sh);
		if (scol != null && scol.length >=1) {
			this.setStrokeColor(scol);
		}
	}
}

export default LegendItem