import PlotBase from "./PlotBase";
import treePlotMode from '../treeObjects/treePlotMode'
import Point from '../treePainter/Point'
import StringOps from "../utils/StringOps";

const FontStyle = {
    'NORMAL':'normal',
    'ITALIC':'italic',
    'OBLIQUE':'oblique',
    }
Object.freeze(FontStyle)


class GroupLabelPlot extends PlotBase{
    constructor(layer_tree_charts, disable_all_dataset, treeSVG){
        super()
        this.layer_tree_charts = layer_tree_charts
        this.disable_all_dataset = disable_all_dataset
        this.chart_plots_enabled = treeSVG.chart_plots_enabled
        this.treeSVG = treeSVG
        this.currentMostRightPosition = this.treeSVG.getLeafMostRightPosition()
        this.plotmode = treeSVG.getPlotMode()
        this.phylotree = treeSVG.getPhyloTree()
        this.LeafInternalID2NodePosisionts = this.getLeafInternalID2NodePosisionts()
        this.hmLeafID2NodePositions = this.gethmLeafID2NodePositions()
        this.BranchLengthPositions = this.getBranchLengthPositions()
        this.pxPerHeight = this.treeSVG.pxPerHeight
        this.space_between_datasets = this.treeSVG.space_between_datasets

        this.defaultColor = "black";
    }

    setLeafInternalID2NodePosisionts(LeafInternalID2NodePosisionts){
        this.LeafInternalID2NodePosisionts = LeafInternalID2NodePosisionts
    }

    setRootXY(rootXY){
        this.rootXY = rootXY
    }

    setPlotMode(plotmode){
        this.plotmode = plotmode
    }

    getAngleSpan(){
        return this.treeSVG.getAngleSpan()
    }

    getLeafInternalID2NodePosisionts(){
        return this.treeSVG.getLeafInternalID2NodePosisionts()
    }

    gethmLeafID2NodePositions(){
        return this.treeSVG.gethmLeafID2NodePositions()
    }

    getBranchLengthPositions(){
        return this.treeSVG.getBranchLengthPositions()
    }

    getLeafMostRightPosition(){
        return this.currentMostRightPosition
    }

    setLeafMostRightPosition(currentMostRightPosition){
        this.currentMostRightPosition = currentMostRightPosition
    }

    setGroupLabelData( newID, newdataset) {
        this.dataset = newdataset;
        this.datasetID = newID;
        this.setActive(true);
        this.datasetContentAsString = newdataset.getOriginal_datastring(); // get original user input as String ;
        this.opacity = this.dataset.getOpacity();
        this.setLegendEntry(newdataset.getLegend()); //
    }

    /**
     * added style==2 + added style == 3 + added style == 4, 5
     * NOTE: in circular mode: style == 5 will be force changed
     * to == 4
     */
    makeOrUpdatePlot() {
    
        this.LeafInternalID2NodePosisionts = this.getLeafInternalID2NodePosisionts()
        this.hmLeafID2NodePositions = this.gethmLeafID2NodePositions()
        this.BranchLengthPositions = this.getBranchLengthPositions()
        // this.defs = this.treeSVG.defs
        this.currentMostRightPosition = this.getLeafMostRightPosition()
        if (this.active && !this.disable_all_dataset && this.chart_plots_enabled) {

            var groupLabelStyle = this.dataset.getGroupLabelStyle();
            var mostright = this.currentMostRightPosition + this.space_between_datasets;

            /**
             * !!! in circular mode, style ==5 will be plotted as style
             * == 4 !!!!
             */
            if (groupLabelStyle == 5 && (this.plotmode === treePlotMode.CIRCULAR_CLADOGRAM
                || this.plotmode === treePlotMode.CIRCULAR_PHYLOGRAM)) {
                groupLabelStyle = 4;
            }

            if (groupLabelStyle == 1) {
                mostright += this.treeSVG.default_font_size / 2;
            }

            /*
            * >>>>>>>>>> the main layer >>>>>>>>>>>>>
            */
            this.initLayer(this.layer_tree_charts)
            Point.clearAllChildNodesFromSVGGElement(this.layer);
            this.layer.attr("fill-opacity", (this.opacity));

            //
            var current_max_width = 0; // keep tracking the max width of current plot

            /**
             * calculate max real text-width based on font size and
             * orientation .... will only do this for style=2 (for now)
             */
            var max_text_width = 0;
            if (groupLabelStyle >= 2) {
                for (var ind in this.dataset.getGroupLabels()) {
                    var item = this.dataset.getGroupLabels()[ind]
                    var itemwidth = item.getGroupLabel().length * this.treeSVG.font_width_height_ratio * item.getFontSize();
                    var itemheight = item.getFontSize();
                    var item_width = StringOps.equalsIgnoreCase( item.getTextOrientation(),("vertical")) ? itemheight * 1.2
                        : itemwidth;
                    if (item_width > max_text_width) {
                        max_text_width = item_width;
                    }
                }
                max_text_width += this.space_between_datasets * 2;
            }

            /**
             * calculate angle span per height
             */
            var angleSpanPerHeight = this.getAngleSpan() / this.phylotree.getMaxVerticalLevel();

            // plot each group label item
            for (var index in this.dataset.getGroupLabels()) {
                // get start and end positions
                var item = this.dataset.getGroupLabels()[index]
                var leafIDleft = item.getStartLeafID();
                var leafIDright = item.getEndLeafID();

                var p1 = (leafIDleft.length >=1 && this.hmLeafID2NodePositions.hasOwnProperty(leafIDleft))
                    ? this.hmLeafID2NodePositions[leafIDleft]
                    : null;
                var p2 = (leafIDright.length >=1 && this.hmLeafID2NodePositions.hasOwnProperty(leafIDright))
                    ? this.hmLeafID2NodePositions[leafIDright]
                    : null;
                // console.log(leafIDleft,leafIDright,p1,p2)

                /**
                 * swap p1 and p2 if both are not null and p1.vlevel > p2.vlevl
                 */
                if (p1 != null && p2 != null) {
                    if (p1.verticalLevel > p2.verticalLevel) {
                        var tmp = p1;
                        p1 = p2;
                        p2 = tmp;
                    }
                }

                /**
                 * plot if p1 is not null 
                 */
                if (p1 != null) {
                    /**
                     * some variables for group label
                     */
                    var textx = 0, texty = 0, textangle = 0;
                    var radius = mostright - this.rootXY.x;

                    /**
                     * to get last common ancester
                     */
                    var nodeids = [];
                    nodeids.push(leafIDleft);

                    // check if p2 is null
                    var p2isnull = false;
                    if (p2 == null) {
                        p2 = p1;
                        p2isnull = true;
                    } else {
                        nodeids.push(leafIDright); // if not null
                    }

                    /**
                     * plot a line from the start to the end, plot label
                     * in this part: + plot a line + decide the positions of text
                     */

                    /**
                     * circular mode
                     */
                    if (this.plotmode === treePlotMode.CIRCULAR_CLADOGRAM
                        || this.plotmode === treePlotMode.CIRCULAR_PHYLOGRAM) {

                        /**
                         * !!! important !!! 
                         */
                        if (this.treeSVG.getCircularModeClockwise()) {
                            var tmp = p1;
                            p1 = p2;
                            p2 = tmp;
                        }

                        /**
                         *  get the start and end angles of current fan plot ...
                         */
                        var angle1 = (p1.angle
                            - angleSpanPerHeight * (1 + Math.log10(p1.number_leaf_decendents)) / 2);
                        var angle2 = (p2.angle
                            + angleSpanPerHeight * (1 + Math.log10(p2.number_leaf_decendents)) / 2);
                        var angleSpanCurrentFan = (angleSpanPerHeight
                            * (Math.abs(p2.verticalLevel - p1.verticalLevel)
                                + (1 + Math.log10(p1.number_leaf_decendents)) / 2
                                + (1 + Math.log10(p2.number_leaf_decendents)) / 2));

                        // fine-tune angles
                        angle1 += (angleSpanPerHeight * this.dataset.getGroupLabelMargin() / 2);
                        angle2 -= (angleSpanPerHeight * this.dataset.getGroupLabelMargin() / 2);

                        // angleSpanCurrentFan *= (1-dataset.getGroupLabelMargin());
                        /**
                         * an arc ...
                         */
                        if (groupLabelStyle == 1) {
                            var arc = this.makeArcPlot(this.rootXY, radius, angle1, angle2, angleSpanCurrentFan, this.layer); //5931
                            arc.attr("stroke", item.getLineColor());
                            if (item.isbDashedLine()) {
                                arc.attr("stroke-dasharray", "5,2"); // now all have this dash
                            }
                            arc.attr("stroke-width", item.getLineWidth());
                            arc.attr("fill", "none");
                        /**
                         * fans !!!!
                         */
                        } else if (groupLabelStyle == 2 || groupLabelStyle == 3 || groupLabelStyle == 4) {

                            // outer radius is fixed for 2,3,4
                            var outerRadius = max_text_width + mostright - this.rootXY.x;

                            // ...
                            var innerRadius = 0;
                            if (groupLabelStyle == 2) {
                                innerRadius = mostright - this.rootXY.x;
                            } else if (groupLabelStyle == 3) {
                                var lca = this.phylotree.getLCA(nodeids);
                                var lcapos = this.BranchLengthPositions[lca.getInternalID()];
                                innerRadius = lcapos.pt.x - this.rootXY.x;
                                console.log(nodeids,lca,lcapos)
                            } else if (groupLabelStyle == 4) {
                                innerRadius = 2; // because this is relative to rootXY !!!
                            }

                            // make a fan !!
                            var fan = this.makeFanPlot(this.rootXY, innerRadius, outerRadius, angle1, angle2,
                                angleSpanCurrentFan, this.layer); //5963
                            fan.attr("stroke", "none");
                            fan.attr("fill", item.getBkColor());
                        }
                    }
                    /**
                     * rectangular mode --
                     */
                    else {

                        /**
                         * get Y position of the first and last points
                         */
                        var y1 =  (p1.pt.y
                            - this.pxPerHeight * (1 + Math.log10(p1.number_leaf_decendents)) / 2);
                        var y2 =  (p2.pt.y
                            + this.pxPerHeight * (1 + Math.log10(p2.number_leaf_decendents)) / 2);

                        // fine-tune y1 and y2
                        y1 += (this.pxPerHeight * this.dataset.getGroupLabelMargin() / 2);
                        y2 -= (this.pxPerHeight * this.dataset.getGroupLabelMargin() / 2);

                        if (groupLabelStyle == 1) {
                            /**
                             * plot a line, it will only take 80% of all available space ...
                             */

                            // plot line and add line styles ...
                            var line  = this.layer.line(mostright, y1, mostright, y2);
                            line.attr("stroke", item.getLineColor())
                            if (item.isbDashedLine()) {
                                line.attr("stroke-dasharray", "5,2"); // now all have this
                            }
                            line.attr("stroke-width", item.getLineWidth() + "px");

                        } else if (groupLabelStyle == 2 || groupLabelStyle == 3 || groupLabelStyle == 4
                            || groupLabelStyle == 5) {

                            var recty = y1;
                            var recth = y2 - y1;

                            // these two will change ...
                            var rectx = 0, rectw = 0;
                            if (groupLabelStyle == 2) {
                                rectx = mostright;
                                rectw = max_text_width;
                            } else if (groupLabelStyle == 3) {
                                // get LCA --
                                var lca = this.phylotree.getLCA(nodeids);
                                var lcapos = this.BranchLengthPositions[lca.getInternalID()];
                                rectx = lcapos.pt.x
                                rectw = mostright - lcapos.pt.x + max_text_width;
                            } else if (groupLabelStyle == 4) {
                                rectx = this.rootXY.x - 15;
                                rectw = mostright - this.rootXY.x + 15 + max_text_width;
                            } else if (groupLabelStyle == 5) {
                                /**
                                 * in this mode, text will be shown to the left of rootXY
                                 */
                                rectx = this.rootXY.x - max_text_width - this.space_between_datasets;
                                rectw = max_text_width + mostright - this.rootXY.x + this.space_between_datasets;
                            }

                            // make rectangular --
                            const rect = this.layer.rect(rectw, recth).move(rectx, recty).radius(0,0)                    
                            rect.attr("stroke", "none");
                            rect.attr("fill", item.getBkColor());
                        }
                    }

                    /**
                     * text valid modes: middle|start|end >>> 
                     */
                    textx = mostright + this.space_between_datasets;
                    if (groupLabelStyle == 1) {
                        textx += item.getLineWidth() / 2;
                    } else if (groupLabelStyle == 5) {
                        textx = this.rootXY.x - max_text_width;
                    }
                    if (StringOps.equalsIgnoreCase(item.getTextAlign(),("start"))) { //6053
                        texty = p1.pt.y;
                        textangle = p1.angle;
                    } else if (StringOps.equalsIgnoreCase(item.getTextAlign(),("middle"))) {
                        texty = (p1.pt.y + p2.pt.y) / 2 - 10;

                        var angle1 = (p1.angle
                            - angleSpanPerHeight * (1 + Math.log10(p1.number_leaf_decendents)) / 2);
                        var angle2 = (p2.angle
                            + angleSpanPerHeight * (1 + Math.log10(p2.number_leaf_decendents)) / 2);
                        var angleSpanCurrentFan =  (angleSpanPerHeight
                            * (Math.abs(p2.verticalLevel - p1.verticalLevel)
                                + (1 + Math.log10(p1.number_leaf_decendents)) / 2
                                + (1 + Math.log10(p2.number_leaf_decendents)) / 2));

                        if (this.treeSVG.getCircularModeClockwise()) {
                            textangle = angle1 + angleSpanCurrentFan / 2;
                        } else {
                            textangle = angle2 - angleSpanCurrentFan / 2;
                        }

                        textangle %= 360;
                    } else if (StringOps.equalsIgnoreCase( item.getTextAlign(),("end"))) {
                        texty = p2.pt.y;
                        textangle = p2.angle;
                    }
                    // console.log(item.getGroupLabel(),item.getTextAlign(),p1.pt,p2.pt,texty,textangle)
                    /**
                     * plot text
                     */                
                    // if horizontal mode; by default
                    if (StringOps.equalsIgnoreCase( item.getTextOrientation(),("horizontal")) ){
                        var text = this.layer.text(item.getGroupLabel()).move(textx, texty)                
                        text.font({size:item.getFontSize()});//
                        text.attr("fill", item.getFontColor());
                        text.attr("fill-opacity", 1 + "");
                        if (item.getFontItalic()) {
                            text.font({style:FontStyle.ITALIC})
                        }
                        texty += item.getFontSize() / 4 ;
                        text.y(texty); //

                        if (this.plotmode == treePlotMode.CIRCULAR_CLADOGRAM
                            || this.plotmode == treePlotMode.CIRCULAR_PHYLOGRAM) {
                            text.transform({rotation:180 - textangle, cx:this.rootXY.x, cy:this.rootXY.y})
                            this.treeSVG.betterReadabilityForSVGTextElem(text, textangle, textx, texty - item.getFontSize() / 4,
                                "end");
                        }
                        // console.log('i am being used ',item.getGroupLabel(),item.getTextOrientation(),this.plotmode)
                    } else if (StringOps.equalsIgnoreCase( item.getTextOrientation(),("vertical"))) {
                        /**
                         * if item will be plotted vertically two modes here: circular mode rect mode
                         * 
                         * fine-tune text x position
                         */
                        if (textangle != 0 && (this.plotmode === treePlotMode.CIRCULAR_CLADOGRAM
                            || this.plotmode === treePlotMode.CIRCULAR_PHYLOGRAM)) {

                            var endangle = (p1.angle
                                - angleSpanPerHeight * (1 + Math.log10(p1.number_leaf_decendents)) / 2);
                            var startangle = (p2.angle
                                + angleSpanPerHeight * (1 + Math.log10(p2.number_leaf_decendents)) / 2);
                            var textarc = (angleSpanPerHeight
                                * (Math.abs(p2.verticalLevel - p1.verticalLevel)
                                    + (1 + Math.log10(p1.number_leaf_decendents)) / 2
                                    + (1 + Math.log10(p2.number_leaf_decendents)) / 2));

                            var textmiddleangle = 0;
                            if (this.treeSVG.getCircularModeClockwise()) {
                                textmiddleangle = endangle + textarc / 2;
                            } else {
                                textmiddleangle = startangle - textarc / 2;
                            }

                            // get where (the angle) the text will be plotted
                            // NOTE: when p2 is null, textPlotAngle will be at the middle
                            var textPlotAngle = startangle;
                            var txtAlign = item.getTextAlign();
                            if (!p2isnull) {
                                if (StringOps.equalsIgnoreCase( txtAlign,("middle"))) {
                                    textPlotAngle = textmiddleangle;
                                } else if (StringOps.equalsIgnoreCase(item.getTextAlign(),("end"))) {
                                    textPlotAngle = endangle;
                                }
                            } else {
                                textPlotAngle = textmiddleangle;
                            }

                            textPlotAngle %= 360;
                            while (textPlotAngle < 0) {
                                textPlotAngle += 360;
                            }
                            var testangle = textarc > 180 ? textPlotAngle : 180 - textPlotAngle;
                            while (testangle < 0) {
                                testangle += 360;
                            }

                            var bReverseStartEnd = (testangle >= 0 && textangle <= 180);

                            // get start and end point; note radius will changed (fine tuned) according to
                            // textPlotAngle
                            // fine-tune text position on plot --
                            var radiusLocal = bReverseStartEnd
                                ? (mostright - this.rootXY.x + item.getFontSize() + item.getLineWidth())
                                : (mostright - this.rootXY.x + 5 + item.getLineWidth());
                            var pstart = Point.getCoordinatesOnCircle(this.rootXY, radiusLocal, startangle);
                            var pend = Point.getCoordinatesOnCircle(this.rootXY, radiusLocal, endangle);

                            /**
                             * create a dref path --
                             */
                            // make a random ID
                            var textpathID = "txtpath" + Math.abs(Math.random());
                            var segs = new Point();
                            // // make path --
                            if (bReverseStartEnd) {
                                segs.appendSegItem(Point.createPointStr(pend.x, pend.y));
                                segs.appendSegItem(Point.createArcStr(pstart.x, pstart.y,
                                radiusLocal, 0, textarc > 180 ? 1: 0, 0));
                            } else {
                                segs.appendSegItem(Point.createPointStr(pstart.x, pstart.y));
                                segs.appendSegItem(Point.createArcStr(pend.x, pend.y,
                                radiusLocal, 0, textarc > 180 ? 1: 0, 1));
                            }
                            // const path = this.defs.path(segs.getSegPathListStr());
                            // path.id(textpathID);
                            // defs.appendChild(path);

                            // create a text element and add its styles
                            var text1 = this.layer.text(function(add) {
                                add.tspan(item.getGroupLabel()).dy(0)
                              })
                            text1.font({size:item.getFontSize()});//
                            text1.attr("fill", item.getFontColor());
                            text1.attr("fill-opacity", 1 + ""); // set opacity to 1
                            if (item.getFontItalic()) {
                                text1.font({style:FontStyle.ITALIC});
                            }
                            text1.path(segs.getSegPathListStr())

                            /**
                             * use text-anchor and startOffset to pinpoint the location of the group label
                             * NOTE: is p2 is null, will always set to middle and startOffset = 50%
                             */
                            if (bReverseStartEnd) {
                                text1.attr("text-anchor",
                                    p2isnull ? "middle"
                                        : StringOps.equalsIgnoreCase(txtAlign,("start")) ? "end"
                                            : StringOps.equalsIgnoreCase( txtAlign,("end")) ? "start" : "middle");
                                var xval = p2isnull ? "50%"
                                : StringOps.equalsIgnoreCase(txtAlign,("end")) ? "0%"
                                    : StringOps.equalsIgnoreCase(txtAlign,("middle")) ? "50%" : "100%"
                                text1.textPath().attr('startOffset', xval)
                            } else {
                                text1.attr("text-anchor", p2isnull ? "middle" : txtAlign); // this is
                                                                    // necessary
                                var xval = p2isnull ? "50%"
                                : StringOps.equalsIgnoreCase(txtAlign,("start")) ? "0%"
                                    : StringOps.equalsIgnoreCase(txtAlign,("middle")) ? "50%" : "100%"
                                text1.textPath().attr('startOffset', xval)
                            }
                            // append text1 to the main layer ...
                        } else {
                            var text = this.layer.text(item.getGroupLabel()).move(textx, texty)                
                            text.font({size:item.getFontSize()});//
                            text.attr("fill", item.getFontColor());
                            text.attr("fill-opacity", 1 + "");
                            if (item.getFontItalic()) {
                                text.font({style:FontStyle.ITALIC})
                            }
                            text.attr("text-anchor", item.getTextAlign());
                            text.transform({rotation:90, cx:textx, cy:texty+10})
                        }
                    }
                    // <<<<< end of text ----
                } else {
                    // do nothing at the moment ...
                }

                var itemwidth = item.getGroupLabel().length * this.treeSVG.font_width_height_ratio * item.getFontSize();
                var itemheight = item.getFontSize();

                var item_width = StringOps.equalsIgnoreCase( item.getTextOrientation(),("vertical")) ? itemheight * 1.2
                    : itemwidth;
                var current_width = item.getLineWidth() + this.space_between_datasets + item_width;

                if (current_width > current_max_width) {
                    current_max_width = current_width;
                }

                if (max_text_width > current_max_width) {
                    current_max_width = max_text_width;
                }

                // however, if groupLabelStyle == 5, current_max_width will be
                // zero --
                if (groupLabelStyle == 5) {
                    current_max_width = 0;
                }
            } // end of for ...

            this.currentMostRightPosition += (current_max_width + this.space_between_datasets);
            // this.treeSVG.setLeafMostRightPosition(this.currentMostRightPosition)
        } else {
            Point.deleteChildNodeFromLayerTree(this.layer);
        }
    }

    makeFanPlot( center, innerRadius, outterRadius, angle1,
        angle2, angleSpan, parent_layer) {
        var dxy = Point.getCoordinatesOnCircle(center, innerRadius, angle2);
        var pxy = Point.getCoordinatesOnCircle(center, innerRadius, angle1); // xy of current node at parent circle

        var segs = new Point();
        var dxy2 = Point.getCoordinatesOnCircle(center, outterRadius, angle2);
        var pxy2 = Point.getCoordinatesOnCircle(center, outterRadius, angle1);

        segs.appendSegItem(Point.createPointStr(dxy.x, dxy.y)); // move to p1
        segs.appendSegItem(Point.createPointStr(dxy2.x, dxy2.y,'L')); // line from p1 to p2;
        segs.appendSegItem(Point.createArcStr(pxy2.x, pxy2.y, outterRadius, 0,
                angleSpan > 180 ? 1: 0, 1)); // arc from p2 to p3
        segs.appendSegItem(Point.createPointStr(pxy.x, pxy.y, 'L')); // line from p3 to p4;
        segs.appendSegItem(Point.createArcStr(dxy.x, dxy.y, innerRadius, 0,
                angleSpan > 180 ? 1: 0, 0)); // arc from p4 to p1
        // segs.appendSegItem(Point.createSVGPathSegClosePath()); // close path
        const path = parent_layer.path(segs.getSegPathListStr());
        return path;
    }

    makeArcPlot( center, radius, angle1, angle2, angleSpan, parent_layer) {
		var dxy = Point.getCoordinatesOnCircle(center, radius, angle2);
		var pxy = Point.getCoordinatesOnCircle(center, radius, angle1);

		var segs = new Point()
		segs.appendSegItem(Point.createPointStr(pxy.x, pxy.y));
		segs.appendSegItem(Point.createArcStr(dxy.x, dxy.y, radius, 0, angleSpan > 180 ? 1: 0, 0));
        const path = parent_layer.path(segs.getSegPathListStr())
        return path;
	}

}
export default GroupLabelPlot