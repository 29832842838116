import DataContainerParserBase from './DataContainerParserBase'
import GeneTransferItemAttribute from './GeneTransferItemAttribute'
import StringOps from '../utils/StringOps'

class GeneTransferContainerParser extends DataContainerParserBase{
    constructor(datasetid, datasetContent, phylotree){
        super()

        this.arGeneTrans = []

        if(datasetid.length >=1 && datasetContent.length >=1){
            this.setDatasetID(datasetid);
            this.setOriginal_datastring(datasetContent);
        }

        var lines = StringOps.JsArrayStringToArrayList( StringOps.splitStringByNewLine( datasetContent ) );
        for (var idx = 0; idx < lines.length; idx++) {
            var line = lines[idx].trim();
            if (line.trim().length > 0 && ! line.startsWith("#")) { // ignore annotation lines
                var lineparts = StringOps.JsArrayStringToArrayList( StringOps.splitStringByTab( line ) );
                var part1 = lineparts[0].trim();

                if (part1.startsWith("!")) {
					this.parseModifier(lineparts, idx + 1);                    
				}
				else if (lineparts.length >= 2) {
					var item = new GeneTransferItemAttribute();
                    item.setStyle(this.getGeneTransStyle())   

                    this.arGeneTrans.push(item)

                    var idGrps = StringOps.JsArrayStringToArrayList( StringOps.splitStringBySeperator(lineparts[0].trim(), ":") ); 
					if(idGrps.length >=2){
                        var idstart = StringOps.JsArrayStringToArrayList( StringOps.splitStringBySeperator(idGrps[0].trim(), ",") ); 
                        var idstop = StringOps.JsArrayStringToArrayList( StringOps.splitStringBySeperator(idGrps[1].trim(), ",") ); 
                            
                        if(this.getGeneTransStyle() == 1){
                            var lcaStart = phylotree.getLCA(idstart);
                            var lcaStop = phylotree.getLCA(idstop);
                            var str_internal_id = '',stp_internal_id = ''
                            if (lcaStart != null) {
                                str_internal_id = lcaStart.getInternalID();
                            }
                            if (str_internal_id != null) {
                                item.setHasInternalID(true)
                                this.formAttributesKeysSrc([str_internal_id],item)
                            }
                            if (lcaStop != null) {
                                stp_internal_id = lcaStop.getInternalID();
                            }
                            // console.log(lcaStart.getInternalID(),str_internal_id,lcaStop.getInternalID(),stp_internal_id)
                            if (stp_internal_id != null) {
                                this.formAttributesKeysDest([stp_internal_id],item)
                            }
                        }else if(this.getGeneTransStyle() == 2){
                            this.formAttributesKeysSrc(idstart,item)
                            this.formAttributesKeysDest(idstop,item)
                        }
                    }                    

                    var kvs = this.splitKeyValuePairs(lineparts[1]);

                    item.hasRadius = kvs.hasOwnProperty("radius") ? true : false
                    item.curRadius = kvs.hasOwnProperty("radius") ? kvs["radius"] : 0

                    item.hasAngle = kvs.hasOwnProperty("angle") ? true : false
                    item.curAngle = kvs.hasOwnProperty("angle") ? kvs["angle"] : 0
                    
                    // background color;
					item.setBkColor( kvs.hasOwnProperty("backgroundcolor") ? kvs["backgroundcolor"] : 
                    kvs.hasOwnProperty("bkcolor") ? kvs["bkcolor"] : this.getGroupLabelBkColor() );
     
                    item.setDir(kvs.hasOwnProperty("dir") ? kvs["dir"] : "0:0")
                    // line color; if 'linecolor' or 'color' exists
					item.setLineColor(kvs.hasOwnProperty("linecolor") ? kvs["linecolor"] : 
                    kvs.hasOwnProperty("color") ? kvs["color"] : this
                        .getGroupLabelLineColor());

                    // line width
                    var line_width = this.getGroupLabelLinewidth();
                    if (kvs.hasOwnProperty("linewidth")) {
                        try {
                            line_width = parseFloat(kvs["linewidth"]);
                        } catch ( e) {
                        }
                    }
                    item.setLineWidth(line_width);

                    // dashed line
                    var dashed = this.isbGrouplabelDashedLine();
                    if (kvs.hasOwnProperty("linestyle")) {
                        dashed = StringOps.equalsIgnoreCase(kvs["linestyle"],"dashed");
                    }
                    item.setbDashedLine(dashed);
                }
            }
        }
        if (this.arGeneTrans.length <=0) {
			this.setError("error: at least one valid gene transfer data should be given");
		}

		if (!this.isSizesMatchGroupsSizes()) {
			this.setError("error: the numbers of legends and legend colors don't match;");
		}
    }

    formAttributesKeysSrc(idPart,item) {
        if (idPart.length>=1) {            
            item.setStartSrcLeafID(idPart[0].trim());
        }
        if (idPart.length > 1) {
            item.setEndSrcLeafID(idPart[1].trim());
        }
    }

    formAttributesKeysDest(idPart,item) {
        if (idPart.length>=1) {            
            item.setStartDestLeafID(idPart[0].trim());
        }
        if (idPart.length > 1) {
            item.setEndDestLeafID(idPart[1].trim());
        }
    }

    getGeneTrans() {
		return this.arGeneTrans;
	}

}

export default GeneTransferContainerParser
