import MC from '../treeObjects/MC'
import axios from 'axios'
import Commons from '../utils/CommonEvol'

import NumericMatrixDataContainer from '../datacontainers/NumericMatrixDataContainer'
import BootstrapValueStyleDataContainerParser from '../datacontainers/BootstrapValueStyleDataContainerParser'
import TimeLineDataContainerParser from '../datacontainers/TimeLineDataContainerParser'
import ProteinDomainDataContainerParser from '../datacontainers/ProteinDomainDataContainerParser'
import GroupLabelDataContainerParser from '../datacontainers/GroupLabelDataContainerParser'
import HeatmapDataContainer from '../datacontainers/HeatmapDataContainer'
import ColumnPlotsDataContainerParser from '../datacontainers/ColumnPlotsDataContainerParser'
import DecorationContainerParser from '../datacontainers/DecorationContainerParser'
import ColorDataContainerParser from '../datacontainers/ColorDataContainerParser'
import InternalCollapseDataContainerParser from './InternalCollapseDataContainerParser'
import BranchLabelContainerParser from '../datacontainers/BranchLabelContainerParser'
import LeafAnnotateContainerParser from '../datacontainers/LeafAnnotateContainerParser'
import ImageLabelContainerParser from '../datacontainers/ImageLabelContainerParser'
import GeneSyntneyContainerParser from '../datacontainers/GeneSyntenyDataContainerParser'
import GeneTransferContainerParser from '../datacontainers/GeneTransferContainerParser'
import BranchMarkerDataContainerParser from '../datacontainers/BranchMarkerDataContainerParser'
import TreeDecoType from '../treeObjects/TreeDecoType'


class CatPanel{
    constructor(treeSVG,show,server_add,userId){
		this.treeSVG = treeSVG
		this.server_add = server_add
		this.userId = userId
        this.panType = ''
		this.promoMode = ''
		this.activeTree = ''
		this.show = show
		this.panStatus = false
		this.colorState = 'default'
		this.expand = false
        this.childPans = []
        this.addedChild = []
    }

    setProp(panType,promoMode,activeTree){
        this.panType = panType
		this.promoMode = promoMode
		this.activeTree = activeTree
        this.clearChildPans()
    }

	setShow(show){
		this.show = show
	}
    setPromoMode(promoMode){
        this.promoMode = promoMode
    }

    getPromoMode(){
        return this.promoMode
    }

    setPanType(panType){
        this.panType = panType
    }

    getPanType(){
        return this.panType
    }

    swapStatus(){
        // console.log(this.panStatus)
        this.panStatus =  !this.panStatus
		if(this.panStatus){
			this.colorState = 'info'
		}else{
			this.colorState = 'default'
		}
    }

    setPanStatus(newStatus){
        this.panStatus = newStatus
		if(this.panStatus){
			this.colorState = 'info'
		}else{
			this.colorState = 'default'
		}
    }

    getPanStatus(){
        return this.panStatus
	}
	
	setExStatus(newStatus){
        this.expand = newStatus
    }

    getExStatus(){
        return this.expand
	}
	
	activateDataset(typeOrformat,name,active,dbSerial){
		// console.log('i am here ',typeOrformat+name,'00',this.userId)
		if(typeOrformat === MC.PIES){
			this.treeSVG.getPies().setActivePCDataByID(typeOrformat+name)
			this.treeSVG.updateAllLegends()
		}else if(typeOrformat === MC.BOOTSTRAPSTYLES){
			this.treeSVG.setActiveBootstrapStyleSetByID(typeOrformat+name)
		}else if(typeOrformat === MC.LEAFLABELDECO){
			this.treeSVG.setActiveLeafDecoByID(typeOrformat+name)
		}else if(typeOrformat === MC.LEAFBKCOLORS){
			this.treeSVG.setActiveLeafBKcolorByDatasetID(typeOrformat+name)
		}else if(typeOrformat === MC.LEAFLABELCOLORS){
			this.treeSVG.setActiveLeafColorSetByID(typeOrformat+name)
		}else if(typeOrformat === MC.BRANCHECOLORS){
			this.treeSVG.setActiveBranchColorByDatasetID(typeOrformat+name)
		}else if(typeOrformat === MC.TIMELINE){
			this.treeSVG.setActiveTimeLineByID(typeOrformat+name)
		}else if(Commons.getDataTypeName(typeOrformat) === MC.CHARTS){
			this.treeSVG.setActiveChartByID(MC.CHARTS+name,active)
		}else if(Commons.getDataTypeName(typeOrformat) === MC.INTERNALCOLLAPSES){
			this.treeSVG.setActiveInternalCollapseDatasetByID(typeOrformat+name)
		}else if(Commons.getDataTypeName(typeOrformat === MC.BRANCHLABELS)){
			this.treeSVG.setActiveBranchLabelByID(typeOrformat+name)
		}else if(Commons.getDataTypeName(typeOrformat === MC.LEAFANNOTATE)){
			this.treeSVG.setActiveLeafAnnotateByID(typeOrformat+name)
		}else if(Commons.getDataTypeName(typeOrformat === MC.LEAFIMAGE)){
			this.treeSVG.setActiveImageLabelByID(typeOrformat+name)
		}else if(typeOrformat === MC.BRANCHMARKER){
			this.treeSVG.setActiveBranchMarkByID(typeOrformat+name)
		}else if(typeOrformat === MC.GENETRANSFER){
			this.treeSVG.setActiveGeneTransferByID(typeOrformat+name)
		}
		else{
			console.log('i am of type ',typeOrformat)
		}            
		if(Commons.isThisMyTree(this.activeTree.userId,this.userId)){
			var status = 0
			if(active){
				status = 1
			}
			this.updateDatasetStatus( status,dbSerial)
		}
	}

	// getDataTypeName(datatype){
	// 	return (datatype == (MC.BARS) || datatype == (MC.STRIPS) || datatype == (MC.PROTEINDOMAINS)
	// 		|| datatype == (MC.GROUPLABELS) || datatype == (MC.DOTPLOTS) || datatype == (MC.HEATMAP)
	// 		|| datatype == (MC.NAMEDCOLUMN) || datatype == MC.GENESYTNEY || datatype == MC.GENETRANSFER)

	// 				? MC.CHARTS
	// 				: datatype;
	// }

	updateDatasetStatus(newStatus,datId){
        axios.post(this.server_add+'query/updateDatStatus',
          {
              treeId: this.activeTree.dbserial,
              status: newStatus,
              datId: datId,
            }).then(res => {
              if(res.status == 200){
                // console.log(res)
                if(res.data.hasOwnProperty('error')){
                  this.log_check_status = res.data.error
                }
              }else{
                  this.showErrorMessage('server connection error '+res)
              }  
          }).catch(err => {
                  this.showErrorMessage('server connection error '+err)
          })
    }

    afterPanUpdate(status,doUpdate){
		// console.log(this.panType)
        // here check the chart type --
        if (this.panType === (MC.PIES)) {
            this.treeSVG.setDisableAllPiechartData(status);
        }else if (this.panType === (MC.BRANCHECOLORS)) {
            this.treeSVG.setDisableAllBranchColorData(status);
        } else if (this.panType === (MC.LEAFLABELCOLORS)) {
            this.treeSVG.setDisableAllLabelColorData(status);
        } else if (this.panType === (MC.LEAFBKCOLORS)) {
            this.treeSVG.setDisableAllLabelBKColorData(status);
        } else if (this.panType === (MC.BOOTSTRAPSTYLES)) {
            this.treeSVG.setDisableAllBootstrapValueSTyleData(status); // Dec 2, 2015; 
        } else if (this.panType === (MC.TIMELINE)) {
            this.treeSVG.setDisableAllTimeLineData(status); // May 1, 2016; 
        } else if (this.panType === (MC.LEAFLABELDECO)) {
            this.treeSVG.setDisableAllLeafDecoData(status);
        } else if (this.panType === (MC.INTERNALCOLLAPSES)) {
            this.treeSVG.setDisableAllInternalCollapseData(status);
        } else if (this.panType === (MC.CHARTS)) {
            this.treeSVG.setDisableAllCharts(status);
        } else if(this.panType === MC.BRANCHLABELS){
			this.treeSVG.setDisableAllBranchLabelData(status)
		} else if(this.panType === MC.LEAFANNOTATE){
			this.treeSVG.setDisableAllBranchAnnotateData(status)
		} else if(this.panType === MC.LEAFIMAGE){
			this.treeSVG.setDisableLabelImages(status)
		} else if(this.panType === MC.BRANCHMARKER){
			this.treeSVG.setDisableBranchMarker(status)
		}else if(this.panType === MC.GENETRANSFER){
			this.treeSVG.setDisableGeneTransfer(status)
		}
		// console.log(this.panType,status)
		if(doUpdate){
		// record its active status on server ...
		// console.log(this.treeSVG.getProjectID(),this.treeSVG.getTreeID())
			this.changeCatPanActiveStatusOnServer(this.treeSVG.getProjectID(), this.treeSVG.getTreeID(),this.panType,status,this.promoMode);
		}
    }

    addChildPanRow(udd){
        if(!this.addedChild.includes(udd.getName())){ //getDBSerial()
            this.childPans.push(udd) //.toJson()
            this.addedChild.push(udd.getName())
        }
    }

    hasDataSetAdded(udd){
        return this.addedChild.includes(udd.getName())
    }

    clearChildPans(){
        this.addedChild = []
        this.childPans = []
    }

    getChildPans(){
		this.childPans.sort(function (a, b) {
			return a.dbOrder - b.dbOrder;
		});
        return this.childPans
    }

    continueAddDataSet2Tree(udd) {
        const datatype = udd.getTypeOrFormat();
		const datasetName = udd.getName();
		const datasetContent = udd.getContent();
		const full_dataset_name = datatype + datasetName;
        var success = true;

        var isDataValid = true;
		var errorMSG = ''
        
        if (datatype === (MC.PIES) || datatype === (MC.BARS)) {
            let numdata = new NumericMatrixDataContainer(datasetName, datasetContent, this.treeSVG.getPhyloTree());
            isDataValid = numdata.isDataValid();
            errorMSG = numdata.getErrorMessage();

			if (isDataValid) {
				if (datatype === (MC.PIES)) {
					this.treeSVG.getPies().addPCData(full_dataset_name, numdata, udd.getActive());
					this.treeSVG.updateAllLegends()
                } else if (datatype === (MC.BARS)) {
					this.treeSVG.addBarplotData(MC.CHARTS + datasetName, numdata, udd.getActive());
				}
            }else{
                console.log(isDataValid,errorMSG)
            }
        } else if (datatype === (MC.INTERNALCOLLAPSES)) {
			let dcon = new InternalCollapseDataContainerParser(datasetName, datasetContent, this.treeSVG.getPhyloTree());
			isDataValid = dcon.isDataValid();
			errorMSG = dcon.getErrorMessage();
			if (isDataValid) {
				this.treeSVG.addInternalCollapseData(full_dataset_name, dcon, TreeDecoType.INTERNALCOLLAPSES, udd.getActive());
			} else {
                console.log('error validating data ',isDataValid,errorMSG)
				success = false;
			}
		}else if (datatype === (MC.BOOTSTRAPSTYLES)) {
			/**
			 * ************************************************* 
			 *  bootstrap value style , dec, 3, 2015 ... 
			 * ************************************************** 
			 *   
			 */
			let dcon = new BootstrapValueStyleDataContainerParser(datasetName, datasetContent);
			isDataValid = dcon.isDataValid();
			errorMSG = dcon.getErrorMessage();
			if (isDataValid) {
				this.treeSVG.addBootstrapValueStyleData(full_dataset_name, dcon, TreeDecoType.BOOTSTRAPSTYLES, udd.getActive());
			} else {
                console.log('error validating data ',isDataValid,errorMSG)
                success = false;
			}
		}else if (datatype === (MC.TIMELINE)) {
			/**
			 * ************************************************* 
			 *  timeline; May 2, 2016 ... 
			 * ************************************************** 
			 *   
			 */
			let dcon = new TimeLineDataContainerParser(datasetName, datasetContent);
			isDataValid = dcon.isDataValid();
			errorMSG = dcon.getErrorMessage();
			if (isDataValid) {
				this.treeSVG.addTimeLineData(full_dataset_name, dcon, TreeDecoType.TIMELINE, udd.getActive());
			} else {
                console.log('error validating data ',isDataValid,errorMSG)
                success = false;
			}
		}else if (datatype === (MC.PROTEINDOMAINS)) {
			/**
			 * ************************************************* if
			 * dataset is protein domain May 13, 2012
			 * ************************************************** -- oct
			 * 20, 2013 -- if no colors are specified, random colors
			 * will be generated and added to the dataset this modified
			 * dataset will be uploaded to our server, instead of the
			 * original datase!!!!!!
			 */
			let domaindata = new ProteinDomainDataContainerParser(this.treeSVG.getPhyloTree(),{'datasetid':datasetName,'datasetContent': datasetContent});
			isDataValid = domaindata.isDataValid();
			errorMSG = domaindata.getErrorMessage();
			if (isDataValid) {
				if (domaindata.isDataMod()) {
                    const ndatasetContent = domaindata.getOriginal_datastring(); 
                    //TODO update the udd datastring content also
					//this.dboxProteinDomains.setDataSetContent(datasetContent); TODO: 
				}
				this.treeSVG.addProteinDomainData(MC.CHARTS + datasetName, domaindata, udd.getActive());
			} else {
                console.log('error validating data ',isDataValid,errorMSG)
                success = false;
			}
		}else if (datatype === (MC.GROUPLABELS)) {
            let dcCon = new GroupLabelDataContainerParser(datasetName, datasetContent);
			isDataValid = dcCon.isDataValid();
			errorMSG = dcCon.getErrorMessage();
			if (isDataValid) {
				this.treeSVG.addGroupLabelData(MC.CHARTS + datasetName, dcCon, TreeDecoType.GROUPLABELS, udd.getActive());
			} else {
                console.log('error validating data ',isDataValid,errorMSG)
                success = false;
			}
        }else if (datatype === (MC.DOTPLOTS)) {
            let numdata = new NumericMatrixDataContainer(datasetName, datasetContent, this.treeSVG.getPhyloTree());
			isDataValid = numdata.isDataValid();
			errorMSG = numdata.getErrorMessage();
			if (isDataValid) {
				this.treeSVG.addDotPlotData(MC.CHARTS + datasetName, numdata, TreeDecoType.DOTPLOTS, udd.getActive());
			} else {
                console.log('error validating data ',isDataValid,errorMSG)
                success = false;
			}
        }else if (datatype === (MC.HEATMAP)) {
            let numdata = new HeatmapDataContainer(datasetName, datasetContent, this.treeSVG.getPhyloTree());
			isDataValid = numdata.isDataValid();
			errorMSG = numdata.getErrorMessage();
            if (isDataValid) {
				this.treeSVG.addHeatmapData(MC.CHARTS + datasetName, numdata, TreeDecoType.HEATMAP, udd.getActive());
			} else {
                console.log('error validating data ',isDataValid,errorMSG)
                success = false;
			}
        }else if (datatype === (MC.NAMEDCOLUMN)) {
			/**
			 * ****************************************************** 
			 * if dataset is for named column; Dec 5, 2015 
			 * ******************************************************
			 */
			let numdata = new ColumnPlotsDataContainerParser(datasetName, datasetContent);
			isDataValid = numdata.isDataValid();
			errorMSG = numdata.getErrorMessage();
			if (isDataValid) {
				this.treeSVG.addColumPlotsData(MC.CHARTS + datasetName, numdata, TreeDecoType.NAMEDCOLUMNS, udd.getActive());
			} else {
                console.log('error validating data ',isDataValid,errorMSG)
				success = false;
			}
		}else if (datatype === (MC.LEAFLABELDECO)) {
			/**
			 * ****************************************************** if
			 * dataset is leaf label decorations; March 26, 2014
			 * ******************************************************
			 */
			let dcCon = new DecorationContainerParser(datasetName, datasetContent, this.treeSVG.getPhyloTree());
			isDataValid = dcCon.isDataValid();
			errorMSG = dcCon.getErrorMessage();
			if (isDataValid) {
				this.treeSVG.addDecoData(full_dataset_name, dcCon, TreeDecoType.LEAFLABELDECO, udd.getActive());
			} else {
                console.log('error validating data ',isDataValid,errorMSG)
				success = false;
			}
		}else if (datatype === (MC.BRANCHLABELS)){
			let branchDat = new BranchLabelContainerParser(datasetName, datasetContent)
			isDataValid = branchDat.isDataValid();
			errorMSG = branchDat.getErrorMessage();
			if(isDataValid){
				this.treeSVG.addBranchLabelData(full_dataset_name,branchDat,TreeDecoType.BRANCHLABELS,udd.getActive())
			}
		}else if( datatype === MC.LEAFANNOTATE){
			let branchDat = new LeafAnnotateContainerParser(datasetName, datasetContent, this.treeSVG.getPhyloTree())
			isDataValid = branchDat.isDataValid();
			errorMSG = branchDat.getErrorMessage();
			if(isDataValid){
				this.treeSVG.addBranchAnnotateData(full_dataset_name,branchDat,TreeDecoType.LEAFANNOTATE,udd.getActive())
			}
		}else if(datatype === MC.LEAFIMAGE){
			let imagDat = new ImageLabelContainerParser(datasetName, datasetContent, this.treeSVG.getPhyloTree())
			isDataValid = imagDat.isDataValid();
			errorMSG = imagDat.getErrorMessage();
			if(isDataValid){
				this.treeSVG.addImageLabelData(full_dataset_name, imagDat, udd.getActive())
			}
		}else if(datatype === MC.GENESYTNEY){
			let geneDat = new GeneSyntneyContainerParser(datasetName, datasetContent, this.treeSVG.getPhyloTree())
			isDataValid = geneDat.isDataValid();
			errorMSG = geneDat.getErrorMessage();
			if(isDataValid){
				this.treeSVG.addGeneData(MC.CHARTS + datasetName, geneDat, udd.getActive())
			}
		}else if(datatype === MC.BRANCHMARKER){
			let branchDat = new BranchMarkerDataContainerParser(datasetName, datasetContent, this.treeSVG.getPhyloTree())
			isDataValid = branchDat.isDataValid();
			errorMSG = branchDat.getErrorMessage();
			if(isDataValid){
				this.treeSVG.addBranchMarkerData(full_dataset_name,branchDat,TreeDecoType.BRANCHMARKER,udd.getActive())
			}
		}else if(datatype === MC.GENETRANSFER){
			let geneDat = new GeneTransferContainerParser(datasetName, datasetContent, this.treeSVG.getPhyloTree())
			isDataValid = geneDat.isDataValid();
			errorMSG = geneDat.getErrorMessage();
			if(isDataValid){				
				this.treeSVG.addGeneTransferData(full_dataset_name,geneDat,udd.getActive())
			}
		}
		else{// if not numerical data
			/*
			 * this.LEAFBKCOLORS | this.LEAFLABELCOLORS |
			 * this.BRANCHECOLORS | this.STRIPS 
			 */
			var colordata = new ColorDataContainerParser(datasetName, datasetContent);
			isDataValid = colordata.isDataValid();
			errorMSG = colordata.getErrorMessage();

			if (isDataValid) {
				/*
				 * add current data data to phylotreesvgviewer &&
				 * treedata container
				 */
				if (datatype === (MC.LEAFBKCOLORS)) {
					this.treeSVG.addColorsetToTreeNodes(full_dataset_name, colordata, TreeDecoType.LEAFBKCOLOR, udd.getActive());
				} else if (datatype === (MC.LEAFLABELCOLORS)) {
					this.treeSVG.addColorsetToTreeNodes(full_dataset_name, colordata, TreeDecoType.LEAFCOLOR, udd.getActive());
				} else if (datatype === (MC.BRANCHECOLORS)) {
					this.treeSVG.addColorsetToTreeNodes(full_dataset_name, colordata, TreeDecoType.BRANCHCOLOR, udd.getActive());
				} else if (datatype === (MC.STRIPS)) {
					this.treeSVG.addColorStripData(MC.CHARTS + datasetName, colordata, udd.getActive());
				}
			} else {
                console.log('error validating data ',datatype,isDataValid,errorMSG)
				success = false;
			}
        }

        if(isDataValid && success){ //if all checks are okay,add it to catpanel
            this.addChildPanRow(udd)
        }else{
			udd.setFormatFailed(true)
			udd.setFormatFailedMess(errorMSG)
			this.addChildPanRow(udd)
		}

        return success
    }

	checkAddDataSet2Tree(udd) {
        const datatype = udd.getTypeOrFormat();
		const datasetName = udd.getName();
		const datasetContent = udd.getContent();
		const full_dataset_name = datatype + datasetName;        
		var datObj = null
        
        if (datatype === (MC.PIES) || datatype === (MC.BARS)) {
            datObj = new NumericMatrixDataContainer(datasetName, datasetContent, this.treeSVG.getPhyloTree());                        			
        } else if (datatype === (MC.INTERNALCOLLAPSES)) {
			datObj = new InternalCollapseDataContainerParser(datasetName, datasetContent, this.treeSVG.getPhyloTree());									
		}else if (datatype === (MC.BOOTSTRAPSTYLES)) {
			datObj = new BootstrapValueStyleDataContainerParser(datasetName, datasetContent);									
		}else if (datatype === (MC.TIMELINE)) {
			datObj = new TimeLineDataContainerParser(datasetName, datasetContent);									
		}else if (datatype === (MC.PROTEINDOMAINS)) {
			datObj = new ProteinDomainDataContainerParser(this.treeSVG.getPhyloTree(),{'datasetid':datasetName,'datasetContent': datasetContent});						
		}else if (datatype === (MC.GROUPLABELS)) {
            datObj = new GroupLabelDataContainerParser(datasetName, datasetContent);									
        }else if (datatype === (MC.DOTPLOTS)) {
            datObj = new NumericMatrixDataContainer(datasetName, datasetContent, this.treeSVG.getPhyloTree());									
        }else if (datatype === (MC.HEATMAP)) {
            datObj = new HeatmapDataContainer(datasetName, datasetContent, this.treeSVG.getPhyloTree());						            
        }else if (datatype === (MC.NAMEDCOLUMN)) {			
			datObj = new ColumnPlotsDataContainerParser(datasetName, datasetContent);									
		}else if (datatype === (MC.LEAFLABELDECO)) {			
			datObj = new DecorationContainerParser(datasetName, datasetContent, this.treeSVG.getPhyloTree());									
		}else if (datatype === (MC.BRANCHLABELS)){
			datObj = new BranchLabelContainerParser(datasetName, datasetContent)
		}else if(datatype === MC.LEAFANNOTATE){
			datObj = new LeafAnnotateContainerParser(datasetName,datasetContent, this.treeSVG.getPhyloTree())
		}else if(datatype === MC.LEAFIMAGE){
			datObj = new ImageLabelContainerParser(datasetName, datasetContent, this.treeSVG.getPhyloTree())
		} else if(datatype === MC.GENESYTNEY){
			datObj = new GeneSyntneyContainerParser(datasetName, datasetContent, this.treeSVG.getPhyloTree())
		}else if(datatype === MC.BRANCHMARKER){
			datObj = new BranchMarkerDataContainerParser(datasetName, datasetContent, this.treeSVG.getPhyloTree())			
		}else if(datatype === MC.GENETRANSFER){
			datObj = new GeneTransferContainerParser(datasetName,datasetContent,this.treeSVG.getPhyloTree())
		}
		else{// if not numerical data
			/*
			 * this.LEAFBKCOLORS | this.LEAFLABELCOLORS |
			 * this.BRANCHECOLORS | this.STRIPS 
			 */
			datObj = new ColorDataContainerParser(datasetName, datasetContent,datatype);									
        }
        return datObj
	}

	//no more used
	loadDataSetToSVG(udd,dataSet){
		const datatype = udd.getTypeOrFormat();
		const datasetName = udd.getName();
		const datasetContent = udd.getContent();
		const full_dataset_name = datatype + datasetName;
		var success = true;
		var isDataValid = dataSet.isDataValid()
		var errorMSG = dataSet.getErrorMessage()
		
		if (datatype === (MC.PIES) || datatype === (MC.BARS)) {
			if (isDataValid) {
				if (datatype === (MC.PIES)) {
                    this.treeSVG.getPies().addPCData(full_dataset_name, dataSet, udd.getActive());
                } else if (datatype === (MC.BARS)) {
					this.treeSVG.addBarplotData(MC.CHARTS + datasetName, dataSet, udd.getActive());
				}
            }else{
                console.log(isDataValid,errorMSG)
            }
        } else if (datatype === (MC.INTERNALCOLLAPSES)) {			
			if (isDataValid) {
				// phylotreesvgviewer.getTreeSkeleton().addInternalCollapseData(full_dataset_name, dcon, TreeDecoType.INTERNALCOLLAPSES, udd.getActive());
			} else {
                console.log('error validating data ',isDataValid,errorMSG)
				success = false;
			}
		}else if (datatype === (MC.BOOTSTRAPSTYLES)) {			
			if (isDataValid) {
				this.treeSVG.addBootstrapValueStyleData(full_dataset_name, dataSet, TreeDecoType.BOOTSTRAPSTYLES, udd.getActive());
			} else {
                console.log('error validating data ',isDataValid,errorMSG)
                success = false;
			}
		}else if (datatype === (MC.TIMELINE)) {			
			if (isDataValid) {
				this.treeSVG.addTimeLineData(full_dataset_name, dataSet, TreeDecoType.TIMELINE, udd.getActive());
			} else {
                console.log('error validating data ',isDataValid,errorMSG)
                success = false;
			}
		}else if (datatype === (MC.PROTEINDOMAINS)) {			
			if (isDataValid) {
				if (dataSet.isDataMod()) {
                    const ndatasetContent = dataSet.getOriginal_datastring(); 
                    //TODO update the udd datastring content also
					//this.dboxProteinDomains.setDataSetContent(datasetContent); TODO: 
				}
				// phylotreesvgviewer.addProteinDomainData(MC.CHARTS + datasetName, domaindata, udd.getActive());
			} else {
                console.log('error validating data ',isDataValid,errorMSG)
                success = false;
			}
		}else if (datatype === (MC.GROUPLABELS)) {            
			if (isDataValid) {
				this.treeSVG.addGroupLabelData(MC.CHARTS + datasetName, dataSet, TreeDecoType.GROUPLABELS, udd.getActive());
			} else {
                console.log('error validating data ',isDataValid,errorMSG)
                success = false;
			}
        }else if (datatype === (MC.DOTPLOTS)) {            
			if (isDataValid) {
				this.treeSVG.addDotPlotData(MC.CHARTS + datasetName, dataSet, TreeDecoType.DOTPLOTS, udd.getActive());
			} else {
                console.log('error validating data ',isDataValid,errorMSG)
                success = false;
			}
        }else if (datatype === (MC.HEATMAP)) {            
            if (isDataValid) {
				this.treeSVG.addHeatmapData(MC.CHARTS + datasetName, dataSet, TreeDecoType.HEATMAP, udd.getActive());
			} else {
                console.log('error validating data ',isDataValid,errorMSG)
                success = false;
			}
        }else if (datatype === (MC.NAMEDCOLUMN)) {			
			if (isDataValid) {
				this.treeSVG.addColumPlotsData(MC.CHARTS + datasetName, dataSet, TreeDecoType.NAMEDCOLUMNS, udd.getActive());
			} else {
                console.log('error validating data ',isDataValid,errorMSG)
				success = false;
			}
		}else if (datatype === (MC.LEAFLABELDECO)) {			
			if (isDataValid) {
				this.treeSVG.addDecoData(full_dataset_name, dataSet, TreeDecoType.LEAFLABELDECO, udd.getActive());
			} else {
                console.log('error validating data ',isDataValid,errorMSG)
				success = false;
			}
		}else{// if not numerical data
			/*
			 * this.LEAFBKCOLORS | this.LEAFLABELCOLORS |
			 * this.BRANCHECOLORS | this.STRIPS 
			 */

			if (isDataValid) {
				/*
				 * add current data data to phylotreesvgviewer &&
				 * treedata container
				 */
				if (datatype === (MC.LEAFBKCOLORS)) {
					this.treeSVG.addColorsetToTreeNodes(full_dataset_name, dataSet, TreeDecoType.LEAFBKCOLOR, udd.getActive());
				} else if (datatype === (MC.LEAFLABELCOLORS)) {
					this.treeSVG.addColorsetToTreeNodes(full_dataset_name, dataSet, TreeDecoType.LEAFCOLOR, udd.getActive());
				} else if (datatype === (MC.BRANCHECOLORS)) {
					this.treeSVG.addColorsetToTreeNodes(full_dataset_name, dataSet, TreeDecoType.BRANCHCOLOR, udd.getActive());
				} else if (datatype === (MC.STRIPS)) {
					this.treeSVG.addColorStripData(MC.CHARTS + datasetName, dataSet, udd.getActive());
				}
			} else {
                console.log('error validating data ',datatype,isDataValid,errorMSG)
				success = false;
			}
		}
		
		if(isDataValid && success){ //if all checks are okay,add it to catpanel
            this.addChildPanRow(udd)
        }else{
			
		}
	}
	
    changeCatPanActiveStatusOnServer( projId, treeId, panType, cboxactive, proMode){
		if(proMode === 'SHARING'){
			//do nothing for now
		}else{
			axios.post(this.server_add+'query/updateCatStatus',
			{
				treeId: treeId,
				status: cboxactive,
				projId:projId,
				datType:panType
			}).then(res => {
				if(res.status == 200){
					//console.log(res)
					if(res.data.hasOwnProperty('error')){
					this.log_check_status = res.data.error
					}
				}else{
					console.log('server connection error '+res)
				}  
			}).catch(err => {
				console.log('server connection error '+err)
			})
		}
    }
}

export default CatPanel