
class ButIconMap {
    constructor(){
        this.iconMap = {}
        this.init()
    }

    init(){
        this.generalIcons()
        this.advCtrIconMap()
        this.datasetIcons()
        this.exportIcons()
    }

    generalIcons(){
        var src_path = ''
        this.iconMap['file'] = {'type':1,'name':'ibtnFileOpen','src':'/mainui/FileOpen'+'_normal.svg.png','iconNormal':'/mainui/FileOpen'+'_normal.svg.png','iconActive':'/mainui/FileOpen'+'_active.svg.png','iconDis':'/mainui/FileOpen'+'_disabled.svg.png','tooltipUrl':'','tooltip':'upload tree file','state':false,'disabled':true,'svg':'#icon-ev-fileopen','mouseover':false,'mouseexit':false}
        this.iconMap['quickSaveSVG'] = {'type':1,'name':'ibtnExport2SVGurl','src':'32_px/export/export_svg_URL'+'_normal.svg.png','iconNormal':'32_px/export/export_svg_URL'+'_normal.svg.png','iconActive':'32_px/export/export_svg_URL'+'_active.svg.png','iconDis':'32_px/export/export_svg_URL'+'_disabled.svg.png','svg':'#icon-ev-export-svg','tooltipUrl':'','tooltip':'Export tree to SVG(works only with small files)','state':false,'disabled':false,'mouseover':false,'mouseexit':false}
        this.iconMap['quickSavePNG'] = {'type':1,'name':'ibtnExport2PNG','src':'32_px/export/export_png'+'_normal.svg.png','iconNormal':'32_px/export/export_png'+'_normal.svg.png','iconActive':'32_px/export/export_png'+'_active.svg.png','iconDis':'32_px/export/export_png'+'_disabled.svg.png','svg':'#icon-ev-export-png','tooltipUrl':'','tooltip':'Export tree to PNG','state':false,'disabled':false,'mouseover':false,'mouseexit':false}
        
        var treeStyleMap = {}
        var treeStyleBtns = "ibtnRectClado-/mainui/rect_clado-view tree as rectanglar cladogram-1-#icon/ev/tree/cladogram/rect,"
                    +"ibtnRectPhylo-/mainui/rect_phylo-view tree as rectanglar phylogram-2-#icon/ev/tree/phylogram/rect,"
                    +"ibtnSlantedClado-/mainui/slanted_clado-view tree as slanted cladogram-3-#icon/ev/tree/cladogram/slanted,"
                    +"ibtnCircularClado-/mainui/circular_clado-view tree as circular cladogram-4-#icon/ev/tree/cladogram/circular,"
                    +"ibtnCircularPhylo-/mainui/circular_phylo-Eview tree as circular phylogram-5-#icon/ev/tree/phylogram/circular"
        var btnAr = treeStyleBtns.split(",")
        for(let i = 0;i<btnAr.length;i++){
            var subAr = btnAr[i].split('-')
            var tem = {'type':1,'actionId':subAr[3],'name':subAr[0],'src':src_path+subAr[1]+'_normal.svg.png','iconNormal':src_path+subAr[1]+'_normal.svg.png','iconActive':src_path+subAr[1]+'_active.svg.png','iconDis':src_path+subAr[1]+'_disabled.svg.png','tooltipUrl':'','tooltip':subAr[2],'state':false,'disabled':true,'svg':subAr[4].split("/").join("-"),'mouseover':false,'mouseexit':false,'bg':''}
            treeStyleMap[tem.name] = tem
        }
        this.iconMap['treeViewStyle'] = treeStyleMap

        var treeEdgeMap = {}
        var treeEdgeBtns = "ibtnTreeCurveStyle1-/mainui/curvestyle1- view tree edge style1-1-#icon/ev/treestyle/rounded,"
        +"ibtnTreeCurveStyle2-/mainui/curvestyle2- view tree edge style2-2-#icon/ev/treestyle/curv"
        var btnAr = treeEdgeBtns.split(",")
        for(let i = 0;i<btnAr.length;i++){
            var subAr = btnAr[i].split('-')
            var tem = {'type':1,'actionId':subAr[3],'name':subAr[0],'src':src_path+subAr[1]+'_normal.svg.png','iconNormal':src_path+subAr[1]+'_normal.svg.png','iconActive':src_path+subAr[1]+'_active.svg.png','iconDis':src_path+subAr[1]+'_disabled.svg.png','tooltipUrl':'','tooltip':subAr[2],'state':false,'disabled':true,'svg':subAr[4].split("/").join("-"),'mouseover':false,'mouseexit':false,'bg':''}
            treeEdgeMap[tem.name] = tem
        }
        this.iconMap['treeEdgeStyle'] = treeEdgeMap

        this.iconMap['htmlMode'] = {'type':1,'name':'ibtnHtmlMode','src':'/mainui/html_mode'+'_normal.svg.png','iconNormal':'/mainui/html_mode'+'_normal.svg.png','iconActive':'/mainui/html_mode'+'_active.svg.png','iconDis':'/mainui/html_mode'+'_disabled.svg.png','tooltipUrl':'','tooltip':'Show labels in HTML mode','state':false,'disabled':true,'svg':'#icon-ev-mode-html','mouseover':false,'mouseexit':false,'bg':''}
        this.iconMap['megaMode'] = {'type':1,'name':'ibtnMegaMode','src':'/mainui/mega_mode'+'_normal.svg.png','iconNormal':'/mainui/mega_mode'+'_normal.svg.png','iconActive':'/mainui/mega_mode'+'_active.svg.png','iconDis':'/mainui/mega_mode'+'_disabled.svg.png','tooltipUrl':'','tooltip':'Bootstrap MEGA mode','state':false,'disabled':true,'svg':'#icon-ev-mode-mega','mouseover':false,'mouseexit':false,'bg':''}

        var bootstrapBtns = "ibtnBootstrapShowHide-/mainui/bootstrap_onoff- Show/hide bootstrap score- #icon/ev/bootstrap/show,"
        +"ibtnBootstrapFontItalics-/mainui/fontitalic- Make bootstrap score font italics- #icon/ev/font/italic"
        var btnAr = bootstrapBtns.split(",")
        for(let i = 0;i<btnAr.length;i++){
            var subAr = btnAr[i].split('-')
            var tem = {'type':1,'name':subAr[0],'src':src_path+subAr[1]+'_normal.svg.png','iconNormal':src_path+subAr[1]+'_normal.svg.png','iconActive':src_path+subAr[1]+'_active.svg.png','iconDis':src_path+subAr[1]+'_disabled.svg.png','tooltipUrl':'','tooltip':subAr[2],'state':false,'disabled':true,'svg':subAr[3].split("/").join("-"),'mouseover':false,'mouseexit':false,'bg':''}
            this.iconMap[tem.name] = tem
        }
        // this.iconMap['bootStrapFontSz'] = {'type':2,'name':'bootStrapFont','tooltipUrl':'','tooltip':'set bootstrap score font size','state':false,'disabled':true}

        var branchLenBtns = "ibtnBranchLenShowHide;/mainui/branchlength_onoff;Show/hide branch length;#icon-ev-branchlength-show,"
        +"ibtnBranchLenFontItalics;/mainui/fontitalic;Make branch length font italics;#icon-ev-font-italic,"
        var btnAr = branchLenBtns.split(",")
        for(let i = 0;i<btnAr.length;i++){
            var subAr = btnAr[i].split(';')
            var tem = {'type':1,'name':subAr[0],'src':src_path+subAr[1]+'_normal.svg.png','iconNormal':src_path+subAr[1]+'_normal.svg.png','iconActive':src_path+subAr[1]+'_active.svg.png','iconDis':src_path+subAr[1]+'_disabled.svg.png','tooltipUrl':'','tooltip':subAr[2],'state':false,'disabled':true,'svg':subAr[3],'mouseover':false,'mouseexit':false,'bg':''}
            this.iconMap[tem.name] = tem
        }
        // this.iconMap['branchLenFontSz'] = {'type':2,'name':'branchLenFont','tooltipUrl':'','tooltip':'set branch length font size','state':false,'disabled':true}

        this.iconMap['leafLabelShowHide'] = {'type':1,'name':'ibtnLeafLabelShowHide','src':'/mainui/label_onoff'+'_normal.svg.png','iconNormal':'/mainui/label_onoff'+'_normal.svg.png','iconActive':'/mainui/label_onoff'+'_active.svg.png','iconDis':'/mainui/label_onoff'+'_disabled.svg.png','tooltipUrl':'','tooltip':'show/ hide leaf-node labels','state':false,'disabled':true,'svg':'#icon-ev-leaflabel-show','mouseover':false,'mouseexit':false,'bg':''}
        this.iconMap['leafLabelAlign'] = {'type':1,'name':'ibtnLeafLabelAlign','src':'/mainui/leaflabel_align'+'_normal.svg.png','iconNormal':'/mainui/leaflabel_align'+'_normal.svg.png','iconActive':'/mainui/leaflabel_align'+'_active.svg.png','iconDis':'/mainui/leaflabel_align'+'_disabled.svg.png','tooltipUrl':'','tooltip':'align / unalign leaf node labels','state':false,'disabled':true,'svg':'#icon-ev-leaflabel-align','mouseover':false,'mouseexit':false,'bg':''}
        this.iconMap['line2Leaflabel'] = {'type':1,'name':'ibtnLine2Leaflabel','src':'/mainui/line2leaflabel'+'_normal.svg.png','iconNormal':'/mainui/line2leaflabel'+'_normal.svg.png','iconActive':'/mainui/line2leaflabel'+'_active.svg.png','iconDis':'/mainui/line2leaflabel'+'_disabled.svg.png','tooltipUrl':'','tooltip':'','state':false,'disabled':true,'svg':'#icon-ev-leaflabel-lines','mouseover':false,'mouseexit':false,'bg':''}

        this.iconMap['fitCanvas2plotWidth'] = {'type':1,'name':'ibtnFitCanvas2plotWidth','src':'/mainui/fitCanvas2plotWidth'+'_normal.svg.png','iconNormal':'/mainui/fitCanvas2plotWidth'+'_normal.svg.png','iconActive':'/mainui/fitCanvas2plotWidth'+'_active.svg.png','iconDis':'/mainui/fitCanvas2plotWidth'+'_disabled.svg.png','tooltipUrl':'','tooltip':'fit canvas to plot width','state':false,'disabled':true,'svg':'#icon-ev-fit-width','mouseover':false,'mouseexit':false,'bg':''}
        this.iconMap['fitCanvas2plotHeight'] = {'type':1,'name':'ibtnFitCanvas2plotHeight','src':'/mainui/fitCanvas2plotHeight'+'_normal.svg.png','iconNormal':'/mainui/fitCanvas2plotHeight'+'_normal.svg.png','iconActive':'/mainui/fitCanvas2plotHeight'+'_active.svg.png','iconDis':'/mainui/fitCanvas2plotHeight'+'_disabled.svg.png','tooltipUrl':'','tooltip':'fit canvas to plot height','state':false,'disabled':true,'svg':'#icon-ev-fit-height','mouseover':false,'mouseexit':false,'bg':''}
        this.iconMap['fitCanvas2plot'] = {'type':1,'name':'ibtnFitCanvas2plot','src':'/mainui/fitCanvas2plot'+'_normal.svg.png','iconNormal':'/mainui/fitCanvas2plot'+'_normal.svg.png','iconActive':'/mainui/fitCanvas2plot'+'_active.svg.png','iconDis':'/mainui/fitCanvas2plot'+'_disabled.svg.png','tooltipUrl':'','tooltip':'fit canvas to plot','state':false,'disabled':true,'svg':'#icon-ev-fit-widthandheight','mouseover':false,'mouseexit':false,'bg':''}
        this.iconMap['whereIsMyTree'] = {'type':1,'name':'ibtnWhereIsMyTree','src':'/mainui/whereismytree'+'_normal.svg.png','iconNormal':'/mainui/whereismytree'+'_normal.svg.png','iconActive':'/mainui/whereismytree'+'_active.svg.png','iconDis':'/mainui/whereismytree'+'_disabled.svg.png','tooltipUrl':'','tooltip':'move my tree into view and fit canvas to it','state':false,'disabled':true,'svg':'#icon-ev-restore-tree-position','mouseover':false,'mouseexit':false,'bg':''}

        this.iconMap['ibtnMouseWheelZoominout'] = {'type':1,'name':'ibtnMouseWheelZoominout','src':'/mainui/mouse_zoominout'+'_normal.svg.png','iconNormal':'/mainui/mouse_zoominout'+'_normal.svg.png','iconActive':'/mainui/mouse_zoominout'+'_active.svg.png','iconDis':'/mainui/mouse_zoominout'+'_disabled.svg.png','tooltipUrl':'','tooltip':'use mouse wheel to zoom in/zoom out','state':false,'disabled':false,'svg':'#icon-ev-restore-tree-position','mouseover':false,'mouseexit':false,'bg':''}
        this.iconMap['ibtnMouseZoomin'] = {'type':1,'name':'ibtnMouseZoomin','src':'/mainui/zoomin'+'_normal.svg.png','iconNormal':'/mainui/zoomin'+'_normal.svg.png','iconActive':'/mainui/zoomin'+'_active.svg.png','iconDis':'/mainui/zoomin'+'_disabled.svg.png','tooltipUrl':'','tooltip':'zoom in','state':false,'disabled':false,'svg':'#icon-ev-zoom-in','mouseover':false,'mouseexit':false,'bg':''}
        this.iconMap['ibtnMouseZoomout'] = {'type':1,'name':'ibtnMouseZoomout','src':'/mainui/zoomout'+'_normal.svg.png','iconNormal':'/mainui/zoomout'+'_normal.svg.png','iconActive':'/mainui/zoomout'+'_active.svg.png','iconDis':'/mainui/zoomout'+'_disabled.svg.png','tooltipUrl':'','tooltip':'zoom out','state':false,'disabled':false,'svg':'#icon-ev-zoom-out','mouseover':false,'mouseexit':false,'bg':''}
        this.iconMap['ibtnMouseZoomrestore'] = {'type':1,'name':'ibtnMouseZoomrestore','src':'/mainui/zoomrestore'+'_normal.svg.png','iconNormal':'/mainui/zoomrestore'+'_normal.svg.png','iconActive':'/mainui/zoomrestore'+'_active.svg.png','iconDis':'/mainui/zoomrestore'+'_disabled.svg.png','tooltipUrl':'','tooltip':'zoom restore','state':false,'disabled':false,'svg':'#icon-ev-zoom-restore','mouseover':false,'mouseexit':false,'bg':''}

        this.iconMap['horizScaleIncrease'] = {'type':1,'name':'ibtnHorizScaleIncrease','src':'/mainui/horizLevelIncrease'+'_normal.svg.png','iconNormal':'/mainui/horizLevelIncrease'+'_normal.svg.png','iconActive':'/mainui/horizLevelIncrease'+'_active.svg.png','iconDis':'/mainui/horizLevelIncrease'+'_disabled.svg.png','tooltipUrl':'','tooltip':'increase horizontal scale','state':false,'disabled':true,'svg':'#icon-ev-increase-width','mouseover':false,'mouseexit':false,'bg':''}
        this.iconMap['horizScaleDecrease'] = {'type':1,'name':'ibtnHorizScaleDecrease','src':'/mainui/horizLevelDecrease'+'_normal.svg.png','iconNormal':'/mainui/horizLevelDecrease'+'_normal.svg.png','iconActive':'/mainui/horizLevelDecrease'+'_active.svg.png','iconDis':'/mainui/horizLevelDecrease'+'_disabled.svg.png','tooltipUrl':'','tooltip':'decrease horizontal scale','state':false,'disabled':true,'svg':'#icon-ev-decrease-width','mouseover':false,'mouseexit':false,'bg':''}
        this.iconMap['tbxHorizScale'] = {'type':2,'name':'tbxHorizScale','src':'','iconNormal':'','iconActive':'','iconDis':'','tooltipUrl':'','tooltip':'','state':false,'disabled':true}
        this.iconMap['verticScaleIncrease'] = {'type':1,'name':'ibtnVerticScaleIncrease','src':'/mainui/verticLevelIncrease'+'_normal.svg.png','iconNormal':'/mainui/verticLevelIncrease'+'_normal.svg.png','iconActive':'/mainui/verticLevelIncrease'+'_active.svg.png','iconDis':'/mainui/verticLevelIncrease'+'_disabled.svg.png','tooltipUrl':'','tooltip':'increase vertical scale','state':false,'disabled':true,'svg':'#icon-ev-increase-height','mouseover':false,'mouseexit':false,'bg':''}
        this.iconMap['verticScaleDecrease'] = {'type':1,'name':'ibtnVerticScaleDecrease','src':'/mainui/verticLevelDecrease'+'_normal.svg.png','iconNormal':'/mainui/verticLevelDecrease'+'_normal.svg.png','iconActive':'/mainui/verticLevelDecrease'+'_active.svg.png','iconDis':'/mainui/verticLevelDecrease'+'_disabled.svg.png','tooltipUrl':'','tooltip':'decrease vertical scale','state':false,'disabled':true,'svg':'#icon-ev-decrease-height','mouseover':false,'mouseexit':false,'bg':''}
        this.iconMap['tbxVerticScale'] = {'type':2,'name':'tbxVerticScale','src':'','iconNormal':'','iconActive':'','iconDis':'','tooltipUrl':'','tooltip':'','state':false,'disabled':true}

        this.iconMap['fontItalic'] = {'type':1,'name':'ibtnFontItalic','src':'/mainui/fontitalic'+'_normal.svg.png','iconNormal':'/mainui/fontitalic'+'_normal.svg.png','iconActive':'/mainui/fontitalic'+'_active.svg.png','iconDis':'/mainui/fontitalic'+'_disabled.svg.png','tooltipUrl':'','tooltip':'set/ unset font style for leaf label to italic','state':false,'disabled':true,'svg':'#icon-ev-font-italic','mouseover':false,'mouseexit':false,'bg':''}
        this.iconMap['fontSizeIncrease'] = {'type':1,'name':'ibtnFontSizeIncrease','src':'/mainui/fontSizeIncrease'+'_normal.svg.png','iconNormal':'/mainui/fontSizeIncrease'+'_normal.svg.png','iconActive':'/mainui/fontSizeIncrease'+'_active.svg.png','iconDis':'/mainui/fontSizeIncrease'+'_disabled.svg.png','tooltipUrl':'','tooltip':'increase leaf label font size','state':false,'disabled':true,'svg':'#icon-ev-increase-fontsize','mouseover':false,'mouseexit':false,'bg':''}
        this.iconMap['fontSizeDecrease'] = {'type':1,'name':'fontSizeDecrease','src':'/mainui/fontSizeDecrease'+'_normal.svg.png','iconNormal':'/mainui/fontSizeDecrease'+'_normal.svg.png','iconActive':'/mainui/fontSizeDecrease'+'_active.svg.png','iconDis':'/mainui/fontSizeDecrease'+'_disabled.svg.png','tooltipUrl':'','tooltip':'decrease leaf label font size','state':false,'disabled':true,'svg':'#icon-ev-decrease-fontsize','mouseover':false,'mouseexit':false,'bg':''}
        this.iconMap['tbxFontSize'] = {'type':2,'name':'tbxFontSize','src':'','iconNormal':'','iconActive':'','iconDis':'','tooltipUrl':'','tooltip':'view/ set font size for leaf labels','state':false,'disabled':true}

        this.iconMap['linewidthIncrease'] = {'type':1,'name':'ibtnLinewidthIncrease','src':'/mainui/linewidthIncrease'+'_normal.svg.png','iconNormal':'/mainui/linewidthIncrease'+'_normal.svg.png','iconActive':'/mainui/linewidthIncrease'+'_active.svg.png','iconDis':'/mainui/linewidthIncrease'+'_disabled.svg.png','tooltipUrl':'','tooltip':'increase tree branch line width','state':false,'disabled':true,'svg':'#icon-ev-increase-branchlinewidth','mouseover':false,'mouseexit':false,'bg':''}
        this.iconMap['linewidthDecrease'] = {'type':1,'name':'ibtnLinewidthDecrease','src':'/mainui/linewidthDecrease'+'_normal.svg.png','iconNormal':'/mainui/linewidthDecrease'+'_normal.svg.png','iconActive':'/mainui/linewidthDecrease'+'_active.svg.png','iconDis':'/mainui/linewidthDecrease'+'_disabled.svg.png','tooltipUrl':'','tooltip':'decrease tree branch line width','state':false,'disabled':true,'svg':'#icon-ev-decrease-branchlinewidth','mouseover':false,'mouseexit':false,'bg':''}
        this.iconMap['tbxLienwidth'] = {'type':2,'name':'tbxLienwidth','src':'','iconNormal':'','iconActive':'','iconDis':'','tooltipUrl':'','tooltip':'view/ set line width for tree branches','state':false,'disabled':true}
    }

    advCtrIconMap(){        
        //set clockwise,anti clockwise,rotate cloke wise,anticlockwise
        var src_path = ''
        var advCtrMap = {}
        var advCtrBtns = "ibtnCicularCounterClockwise-/mainui/circularCounterClock- Rotate counter clockwise-#icon/ev/circular/counterclockwise,"
        +"ibtnCicularClockwise-/mainui/circularClock- Rotate clockwise-#icon/ev/circular/clockwise,"
        +"ibtnCicularRotateCounterClockwise-/mainui/circularRotateCounterClock- Rotate circular tree counter clockwise-#icon/ev/rotate/left,"
        +"ibtnCicularRotateClockwise-/mainui/circularRotateClock- Rotate circular tree clockwise-#icon/ev/rotate/right,"
        +"ibtnCicularSpanDecrease-/mainui/circularDecreaseSpan- Decrease circular span-#icon/ev/decrease/fan,"
        +"ibtnCicularSpanIncrease-/mainui/circularIncreaseSpan- Increase circular span-#icon/ev/increase/fan,"
        +"ibtnMoveLegend-/mainui/moveLegend- Activate/Deactivate legend dragging-#icon/ev/move/legend,"
        +"ibtnMoveTree-/mainui/moveTree- Activate/Deactivate tree dragging-#icon/ev/move/tree,"
        +"ibtnLegZoomIn-/mainui/legzoomin- Zoom in Legends-kk,"
        +"ibtnLegZoomOut-/mainui/legzoomout- Zoom out Legends-kk"

        var btnAr = advCtrBtns.split(",")
        for(let i = 0;i<btnAr.length;i++){
            var subAr = btnAr[i].split('-')
            var tem = {'type':1,'name':subAr[0],'src':src_path+subAr[1]+'_normal.svg.png','iconNormal':src_path+subAr[1]+'_normal.svg.png','iconActive':src_path+subAr[1]+'_active.svg.png','iconDis':src_path+subAr[1]+'_disabled.svg.png','tooltipUrl':'','tooltip':subAr[2],'state':false,'disabled':true,'svg':subAr[3].split("/").join("-"),'mouseover':false,'mouseexit':false,'bg':''}
            if(tem.name == 'ibtnMoveLegend' || tem.name == 'ibtnMoveTree' || tem.name == 'ibtnLegZoomIn' || tem.name == 'ibtnLegZoomOut'){
                tem.disabled = false
            }
            advCtrMap[tem.name] = tem
        }
        this.iconMap['adv'] = advCtrMap
    }

    datasetIcons(){
        var datasetMap = {}
        var src_path = 'dataset/'
        var helpPageRoot = 'https://www.evolgenius.info/help/'
        var imgBtnNamaes = "ibtnDataUploadPiechart-buttonicon/dataset_piechart-tooltip/piecharts.png-upload data for pie chart <a href=\""+helpPageRoot+"dataset/piechart/\" target=\"_blank\">(help)</a>-1-#icon/ev/dataset/pie,"
                + "ibtnDataUploadBranchColor-buttonicon/dataset_branchcolor-tooltip/branchcolors.png-upload data for coloring branches <a href=\""+helpPageRoot+"dataset/branchcolors/\" target=\"_blank\">(help)</a>-2-#icon/ev/dataset/branchcolor,"
                + "ibtnDataUploadLabelColor-buttonicon/dataset_labelcolor-tooltip/leaflabelcolors.png-upload data for coloring leaf labels <a href=\""+helpPageRoot+"dataset/leafclr/\" target=\"_blank\">(help)</a> -3-#icon/ev/dataset/leaf/color,"
				+"ibtnDataUploadLeafLabelBKColor-buttonicon/dataset_leaflabelBKColor-tooltip/leaflabelbackgroud.png-upload data for leaf background colors <a href=\""+helpPageRoot+"dataset/leafbkclr/\" target=\"_blank\">(help)</a>-4-#icon/ev/dataset/leaf/backgroundcolor,"
				+"ibtnDataUploadLeafLabelDecoration-buttonicon/dataset_leaflabel_deco-tooltip/leafdecorations.png-upload data for leaf label decorations <a href=\""+helpPageRoot+"dataset/leaflabdeco/\" target=\"_blank\">(help)</a>-5-#icon/ev/dataset/leaf/decoration,"
				+"ibtnDataUploadTimeLine-buttonicon/timeline-tooltip/Timeline.png-upload data for timeline <a href=\""+helpPageRoot+"dataset/timeline/\" target=\"_blank\">(help)</a>-6-#icon/ev/dataset/timeline,"
				+"ibtnDataUploadGroupLabel-buttonicon/dataset_grouplabel-tooltip/groupLabels.png-upload data for group label <a href=\""+helpPageRoot+"dataset/grouplabel/\" target=\"_blank\">(help)</a>-7-#icon/ev/dataset/grouplabel,"
				+"ibtnDataUploadColorStrips-buttonicon/dataset_colorstripOL-tooltip/colorshapesandstrips.png-upload data for color shapes and strips <a href=\""+helpPageRoot+"dataset/clrstripshape/\" target=\"_blank\">(help)</a>-8-#icon/ev/dataset/coloredshape,"
				+"ibtnDataUploadBarPlots-buttonicon/dataset_bars-tooltip/barplots.png-upload data for bar plot <a href=\""+helpPageRoot+"dataset/bars/\" target=\"_blank\">(help)</a>-9-#icon/ev/dataset/barplot,"
				+"ibtnDataUploadDotPlots-buttonicon/dataset_dotplots-tooltip/dotPlots.png-upload data for dot plot <a href=\""+helpPageRoot+"dataset/dotplots/\" target=\"_blank\">(help)</a>-10-#icon/ev/dataset/dotplot,"
				+"ibtnDataUploadHeatmap-buttonicon/dataset_heatmap-tooltip/heatMap.png-upload data for heatmap <a href=\""+helpPageRoot+"dataset/heatmap/\" target=\"_blank\">(help)</a>-11-#icon/ev/dataset/heatmap,"
				+"ibtnDataUploadProteinDomains-buttonicon/dataset_protein_domains-tooltip/proteindomains.png-upload data for protein domains <a href=\""+helpPageRoot+"dataset/protdomain/\" target=\"_blank\">(help)</a>-12-#icon/ev/dataset/domain,"
				+"ibtnDataUploadBootstrapStyles-buttonicon/bootstrap_value_styles-tooltip/bootstrapvaluestyles.png-upload data for Boostrap value styles <a href=\""+helpPageRoot+"dataset/bootstrapvalstyle/\" target=\"_blank\">(help)</a>-13-#icon/ev/dataset/bootstrapstyle,"
				+"ibtnDataUploadCollapseInternally-buttonicon/collapse-tooltip/collapse_internal_nodes.png-upload data for collapsing internal nodes <a href=\""+helpPageRoot+"dataset/collapseintnodes/\" target=\"_blank\">(help)</a>-14-#icon/ev/dataset/collapse,"
                +"ibtnDataUploadNamedColumnPlot-buttonicon/namedcolumns-tooltip/columnplots_popup.png-upload data for named column plots <a href=\""+helpPageRoot+"dataset/columnplots/\" target=\"_blank\">(help)</a>-15-#icon/ev/dataset/namedcolumn,"
                + "ibtnDataUploadBranchLabel-buttonicon/branch_label-tooltip/branchlabel.png-upload data for branch label <a href=\""+helpPageRoot+"dataset/branchlabel/\" target=\"_blank\">(help)</a>-2-#icon/ev/dataset/branch/annotation,"
                + "ibtnDataUploadBranchAnnotate-buttonicon/branchann-tooltip/branchann.png-upload data for leaf annotation <a href=\""+helpPageRoot+"dataset/leafann/\" target=\"_blank\">(help)</a>-2-#icon/ev/dataset/leaf/annotation,"
                + "ibtnDataUploadLeafGraphics-buttonicon/label_image-tooltip/labelImage.png-upload data for leaf graphics <a href=\""+helpPageRoot+"dataset/leafImg/\" target=\"_blank\">(help)</a>-2-#icon/ev/dataset/image,"
                + "ibtnDataUploadGeneSyn-buttonicon/gene_synteny2-tooltip/gene_synteny.png-upload data for gene synteny <a href=\""+helpPageRoot+"dataset/genesynteny/\" target=\"_blank\">(help)</a>-2-#icon/ev/dataset/genesynteny,"
                + "ibtnDataUploadGeneTransfer-buttonicon/gene_transfer-tooltip/geneTrans.png-upload data for gene transfer <a href=\""+helpPageRoot+"dataset/genetransfer/\" target=\"_blank\">(help)</a>-2-#icon/Genetransfer,"
                + "ibtnDataUploadBranchMarker-buttonicon/branchmarker-tooltip/branchmarker.png-upload data for inner branch marker <a href=\""+helpPageRoot+"dataset/branchmarker/\" target=\"_blank\">(help)</a>-2-#icon/ev/dataset/branch/shape"                
                
        var btnAr = imgBtnNamaes.split(",")
        for(let i = 0;i<btnAr.length;i++){
            var subAr = btnAr[i].split('-')
            var tem = {'type':1,'name':subAr[0],'src':src_path+subAr[1]+'_normal.svg.png','iconNormal':src_path+subAr[1]+'_normal.svg.png','iconActive':src_path+subAr[1]+'_active.svg.png','iconDis':src_path+subAr[1]+'_disabled.svg.png','tooltipUrl':src_path+subAr[2],'tooltip':subAr[3],'state':false,'disabled':true,'svg':subAr[5].split("/").join("-"),'mouseover':false,'mouseexit':false,'bg':''}
            datasetMap[tem.name] = tem
        }
        this.iconMap['dataset'] = datasetMap
    }

    exportIcons(){
        var datasetMap = {}
        var src_path = ''//../assets/
        var imgBtnNamaes =  "ibtnExport2JPEG-32_px/export/export_jpeg-Export tree to JPEG-2-#icon/ev/export/jpeg,"
				+ "ibtnExport2TIFF-32_px/export/export_tiff-Export tree to TIFF-3-#icon/ev/export/tiff,"
				+"ibtnExport2Nexus-32_px/export/export_nexus-Export tree to Nexus format-4-#icon/ev/export/nxs,"
				+"ibtnExport2Nhx-32_px/export/export_nhx-Export tree to NHX format-5-#icon/ev/export/nhx,"
				+"ibtnExport2PhyloXML-32_px/export/export_xml-Export tree to PhuloXML format-6-#icon/ev/export/xml,"
        var btnAr = imgBtnNamaes.split(",")
        datasetMap['pdfSave'] = {'type':1,'name':'ibtnSave2PDF2','src':'32_px/export/export_pdfchen'+'_normal.svg.png','iconNormal':'32_px/export/export_pdfchen'+'_normal.svg.png','iconActive':'32_px/export/export_pdfchen'+'_active.svg.png','iconDis':'32_px/export/export_pdfchen'+'_disabled.svg.png','svg':'#icon-ev-export-pdf','tooltipUrl':'','tooltip':'Export tree to PDF','state':false,'disabled':false,'mouseover':false,'mouseexit':false,'bg':''}
        for(let i = 0;i<btnAr.length;i++){
            var subAr = btnAr[i].split('-')
            if(subAr[0].length>1){
                var tem = {'type':1,'name':subAr[0],'src':src_path+subAr[1]+'_normal.svg.png','iconNormal':src_path+subAr[1]+'_normal.svg.png','iconActive':src_path+subAr[1]+'_active.svg.png','iconDis':src_path+subAr[1]+'_disabled.svg.png','svg':subAr[4].split("/").join("-"),'tooltipUrl':'','tooltip':subAr[2],'state':false,'disabled':false,'mouseover':false,'mouseexit':false,'bg':''}
                datasetMap[tem.name] = tem
            }
        }
        datasetMap['newickSave'] = {'type':1,'name':'ibtnExport2Newick','src':'32_px/export/export_newick'+'_normal.svg.png','iconNormal':'32_px/export/export_newick'+'_normal.svg.png','iconActive':'32_px/export/export_newick'+'_active.svg.png','iconDis':'32_px/export/export_newick'+'_disabled.svg.png','svg':'#icon-ev-export-nwk','tooltipUrl':'','tooltip':'Export tree to Newick file','state':false,'disabled':false,'mouseover':false,'mouseexit':false,'bg':''}

        this.iconMap['export'] = datasetMap
    }

    getIconMap(){
        return this.iconMap
    }
}


export default ButIconMap
