<template>
<div>
    <div class="tobar">
        <ToolBar
        :actTree="activeTree"
        :actProject="activeProj"
        :trSVG="treeSVG"
        :catPanel="name2catpanel"
        :promode="promoMode"
        :crPageView="curPageView"> 
        </ToolBar>
    </div>
    <div :style="setCanvasPadding" v-cloak @drop.prevent="addFile" @dragover.prevent v-on:dragover="showDragWin =true && curPageView == 'tool'" v-on:dragleave="showDragWin =false">
        <b-container fluid style="padding-left:0;padding-right:0;">                        
            <div else style="text-align: left;width:100%;height:100%;">
            <svg id="treeCanvas" :width="window.width" :height="window.height" xmlns="http://www.w3.org/2000/svg" style="border-style: groove;background-color:white">
            </svg>
            </div>
            <span v-show="panelShow && curPageView != 'embed'">        
                <ControlWindow 
                :actTree="activeTree"
                :actProject="activeProj"
                :promode="promoMode"
                :trSVG="treeSVG"
                @name2CatPansUpdate="updateCatPanelArray($event)">
                </ControlWindow>        
            </span>        
        </b-container>
        <span v-if="curPageView != 'embed' && curPageView != 'share'">
            <ViewDropFile 
            :actTree="activeTree"
            :actProject="activeProj"
            :catPanel="name2catpanel">
            </ViewDropFile>
            <v-dialog :clickToClose="false"></v-dialog>
            <b-overlay :show="showDragWin" rounded="sm" no-wrap>
                    <template #overlay>
                        <div class="text-center" style="margin-top:30px">
                        <b-icon icon="download" aria-hidden="true" scale="3" ></b-icon>
                        <!-- <p id="cancel-label">Drop here</p>                 -->
                        <h3 style="margin-top:10px">Drop here</h3>
                        <md-progress-bar md-mode="indeterminate" v-show="dropFileLoading"></md-progress-bar>
                        </div>
                    </template>
            </b-overlay>
        </span>
    </div>
</div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import EventBus from './EventBus'
import { constants } from 'fs'
import SVG  from 'svg.js'
import '../svg.foreignobject'
import TreeSVG from '../treePainter/treeSVG'

import Commons from '../utils/CommonEvol'
import ControlWindow from './ControlWindow'
import ViewDropFile from './ViewDropFile'
import ToolBar from './ToolBar'
import UserDataTree from '../treeObjects/UserDataTree'

import FixedHeader from 'vue-fixed-header'

const flatted = require("flatted")


export default {
    props:['treeViewType'],
    // eslint-disable-next-line vue/no-unused-components
    components: 
    {
        ToolBar,
        ControlWindow,
        ViewDropFile                     
    },
    data() {        
        return {
            userId: '',
            server_add : this.$hostname,
            activeProj: '',
            activeTree: '',
            marginTop:'60px',
            curPageView : 'tool',
            projList:[],
            treeList:[],
            hideCanvas:true,
            svgElement:null,
            window: {
                width: 620,
                height: 300
            },
            def_window: {
                width: 600,
                height: 300
            },
            maxWin:{
                width:500,
                height:400
            },
            promoMode:'LIVE',

            panelShow:false,
            showCont:false,
            showPan:true,
            treeSVG:null,
            projLoaded:false,
            dataFromDb:false,
            files:[],
            showDragWin:false,
            dropFileLoading:false,
            canvasTopPad:'80px',
            name2catpanel:[],
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize)
        // this.detectPageViewType()
        var that = this                
        EventBus.$on('logged-in', status => {
            if(status=='100' || status=='101'){
            }else{
                this.panelShow = false;
                // this.closeModal()
            }
        })
        EventBus.$on('reload-svg',load=>{
            if(this.activeProj != null && this.activeTree != null){
                // this.detectPageViewType()
                this.dataFromDb = true
                this.treeWorkerThread(this.activeTree)
            }
        })
        EventBus.$on('control-closed', showPan => {
            this.showPan = showPan
        })        
        EventBus.$on('svg-dim',dim=>{
            if(that.svgElement != null && that.svgElement != undefined){
                that.svgElement.attr('width',dim.width)
                that.svgElement.attr('height',dim.height)
            }
        })
        EventBus.$on('showMess', showMess => {
            this.showErrorMessage(showMess.mess,showMess.title)
        })
        EventBus.$on('clear-canvas', state => {
            this.clearSVG()
        }) 
    },
    watch:{
        "$store.state.activeTree": {
            handler: function(nv) {
                if(nv != null && nv != ''){
                    if(Vue.config.productionTip){
                        console.log("cur tree ",nv)
                    }
                    // this.detectPageViewType()
                    this.dataFromDb = true
                    this.treeWorkerThread(nv)
                }
            },
            immediate: true // provides initial (not changed yet) state
        },
        "$store.state.activeProj": {
            handler: function(nv) {
                if(nv != null && nv != ''){
                    if(Vue.config.productionTip){
                        console.log("cur pro ",nv)
                    }
                    this.activeProj = nv
                    // localStorage.setItem('curpro',JSON.stringify(this.activeProj))
                }
            },
            immediate: true // provides initial (not changed yet) state
        },
        "$store.state.projList": {
            handler: function(nv) {
                this.projList = nv
                localStorage.setItem('projlist',JSON.stringify(this.projList))
            },
            immediate: true // provides initial (not changed yet) state
        }
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    },
    mounted() {        
        this.curPageView = 'tool'
        // this.disableAllBtns()
        if(localStorage.getItem("usertoken") != null){
            const token = localStorage.getItem("usertoken") 
            const decoded = JSON.parse(token)//jwtDecode
            this.userId = decoded.userId
            EventBus.$emit('hide-canvas', {'state':true,'src':'canvaswindow','type':'normal'})            
            if(this.activeTree != null && this.activeProj != null){
                // this.detectPageViewType()
                this.dataFromDb = true
                this.treeWorkerThread(this.activeTree)
            }
        }
        // console.log(this.curPageView,this.shareId)
    },    
    computed: {      
      setCanvasPadding(){
          return {
              'padding-top':`${this.canvasTopPad}`
          }
      },            
    },    
    methods: {                                
        loadCache(){
            if(localStorage.getItem('projlist')){
                if(this.projList == null){
                    this.projList = JSON.parse(localStorage.projlist)
                }else{
                }
            }
            if(localStorage.getItem('treelist')){
                if(this.treeList == null){
                    this.treeList = JSON.parse(localStorage.treelist)
                }else{
                }
            }
        },
        // detectPageViewType(){
        //     this.shareId = this.$route.params.shareId
        //     this.embedId = this.$route.params.embedId
        //     if(this.shareId != undefined){
        //         this.promoMode = "SHARING"
        //         this.curPageView = 'share'            
        //     }else if(this.embedId != undefined){
        //         this.promoMode = "SHARING"
        //         this.curPageView = 'embed'
        //     }
        //     // EventBus.$emit('curPageView', this.curPageView)            
        // },
        addFile(e) {
            let droppedFiles = e.dataTransfer.files;
            if(!droppedFiles) return;
            // this tip, convert FileList to array, credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
            ([...droppedFiles]).forEach(f => {
                this.files.push(f);
            });
            if(this.files.length >=1){
                if(this.showDragWin){
                    this.dropFileLoading = true
                    this.checkInputFileAndData(this.files[0])
                }
            }else{
                this.showDragWin = false
            }
        },
        async checkInputFileAndData(file){
            await new Promise(p=>{
                var reader = new FileReader();
                reader.readAsText(file);
                reader.onload = (event) => {
                    this.showDragWin = false
                    this.dropFileLoading = false
                    var fileType = Commons.checkInputFile(event.target.result)
                    
                    let propArray = [file.name,fileType.format,fileType.isTree,event.target.result]
                    EventBus.$emit('file-props', propArray)
                    
                    this.$modal.show('viewFileMod');            
                    this.files = []
                    // console.log(file,fileType)
                }
            })
        },
        treeWorkerThread(curTree){
            if(this.projLoaded && this.activeTree != null && this.activeTree == curTree){
                    EventBus.$emit('hide-canvas', {'state':false,'src':'canvaswindow','type':'normal'})
            }else{
                this.activeTree = curTree
                this.projLoaded = true                
            }            
            this.loadTreeDat(curTree)
        },
        loadTreeDat(curTree){
            this.clearSVG()
            EventBus.$emit('clear-toolbar',true)
            var curTree = this.activeTree
            try {
                // console.log('i load fresh',this.activeTree,this.activeProj)
                var svgDom = document.getElementById('treeCanvas')
                this.svgElement = SVG("treeCanvas")
                this.treeSVG = new TreeSVG(svgDom,this.svgElement,this.def_window.width,this.def_window.height,this.server_add, this.promoMode, 'normal')
                this.treeSVG.loadTree(curTree,this.activeProj.id)
                this.plotmode = this.treeSVG.getPlotMode()
                this.panelShow = true
            }
            catch(err) {
            }            
            return true
        },
        updateCatPanelArray(val){
            this.name2catpanel = val
        },
        handleResize() {
            this.maxWin.width = window.innerWidth-15;
            this.maxWin.height = window.innerHeight-150;
            EventBus.$emit('resize-winmax', {'maxwidth':this.maxWin.width,'maxheight':this.maxWin.height})
        },
        clearSVG(){
            var svg = document.getElementById('treeCanvas')
            if(svg != null && svg.parentNode != null){
                svg.parentNode.replaceChild(svg.cloneNode(false), svg);
            }
        },
        showErrorMessage(errorMsg,title) {        
            let message = {
                title: title,
                body: errorMsg
            };
            this.$dialog.alert(message,{html: false, okText: 'dismiss'}).then(function(dialog) {
            });
        }
    },    
}

</script>

<style>
    
    body {
      width: 100%;
      height: 100%;
      font-family: monospace;
    }

    .pointer {
        fill: #42b983;
        cursor: pointer;
    }
    .cover-container {
        height: 1800px;
        width: 100%;
        overflow-x: auto;
        overflow-y: auto;        
    }
    .sidePanel{
        width:400px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        text-align: left;
    }

    .scroll {
        max-height: 200px;
        width: 100% !important;
        max-width: 100% !important;
        overflow-y: scroll;
    }

    .rightFlowControls{
        position:fixed;
        margin-top:40px;
        margin-right:10px;
        top:130px !important;
        right: 0px !important;
        z-index: 100;
    }

    .rightFlowControlsMin{
        position:relative;
        /* margin-top:30px;
        margin-right:10px; */
        /* bottom:0 !important;
        right: 0 !important;
        transform: translateY(-100%);
        z-index: 100; */
        display: none;
    }

    .leftPan{
        display: inline-block;
        position:fixed;
        margin-top:40px;
        margin-right:10px;
        top:135px !important;
        right: 15px !important;
        z-index: 90;
    }

    .hide {
        display: none;
    }

    .shadow {
        -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
        filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
        /* Similar syntax to box-shadow */
    }

.tobar{
    position: fixed;
    top: 60px;
}

</style>