import ColorAttributes from './ColorAttributes'

class ColumnAttributesContainerParser extends ColorAttributes {
    constructor( kvs, go){
        super()

        this.startLeafID = ""
        this.endLeafID = ""
        this.fontsize = 12
        this.roundedCorner = 4
        this.fontitalic = false
        this.fontbold = false
        this.textangle = -60
        this.spaceBetweenColumns = 10

        this.setStyle("rect");
		this.setWidth(10);
        this.setStrokeWidth(1);
        
        /**
		 * the following variables will not get defaults from "GO"
		 */
		// start leaf id 
		if( kvs.hasOwnProperty("from") ){
			this.setStartLeafID( kvs["from"] );
		}
		
		// end leaf id
		if( kvs.hasOwnProperty( "to" ) ){
			this.setEndLeafID( kvs["to"] );
		}
		
		// text NOTE: this will not get from default  
		if( kvs.hasOwnProperty( "text" ) ){
			this.setName( kvs["text"] );
		}
		
		// named column: default does not apply   
		if( kvs.hasOwnProperty("namedcolumn") ){
			this.setName( kvs["namedcolumn"] );
        }
        
        /**
		 * if the following is not set, will copy default value from go (if go is valid)  
		 */
		// rounded cornor 
		if( kvs.hasOwnProperty("roundedcorner") ){
			try{
				this.setRoundedCorner( parseInt( kvs["roundedcorner"] ) );
			} catch ( e){
				if( go != null ){
					this.setRoundedCorner( go.getRoundedCorner() );
				}
			}
		} else if( go != null ){
			this.setRoundedCorner( go.getRoundedCorner() );
		}
		
		// style 
		if( kvs.hasOwnProperty("style") ){
			this.setStyle( kvs["style"] );
		} else if( go != null ){
			this.setStyle( go.getStyle() );
		}
		
		// strokewidth 
		if( kvs.hasOwnProperty("strokewidth") ){
			try{
				this.setStrokeWidth( parseFloat( kvs["strokewidth"]) );
			} catch(  e ){
				if( go != null ){
					this.setStrokeWidth( go.getStrokeWidth() );
				}
			}
		} else if( go != null ){
			this.setStrokeWidth( go.getStrokeWidth() );
		}
		
		// show 
		if( kvs.hasOwnProperty( "show" ) ){
			this.setShow( kvs["show"] === ("1") );
		} else  if( go != null ) {
			this.setShow( go.isShow() );
		}
		
		// space between columns 
		if( kvs.hasOwnProperty( "spacebetweencolumns" ) ){
			try{
				this.setSpaceBetweenColumns(parseFloat( kvs[ "spacebetweencolumns" ] ) );
			} catch ( e){
				 if( go != null ){
					 this.setSpaceBetweenColumns( go.getSpaceBetweenColumns() );
				 }
			}
		} else  if( go != null ) {
			this.setSpaceBetweenColumns( go.getSpaceBetweenColumns() );
		}
		
		
		// width 
		if( kvs.hasOwnProperty( "width" ) ){
			try{
				this.setWidth( parseFloat( kvs[ "width" ] ) );
			} catch ( e){
				 if( go != null ) {
					 this.setWidth( go.getWidth() );
				 }
			}
		} else  if( go != null ) {
			this.setWidth( go.getWidth() );
		}
		
		// text angle 
		if( kvs.hasOwnProperty( "textangle" ) ){
			try{
				this.setTextangle( parseFloat( kvs[ "textangle" ] ) );
			} catch ( e){
				 if( go != null ) {
					 this.setTextangle( go.getTextangle() );
				 }
			}
		} else  if( go != null ) {
			this.setTextangle( go.getTextangle() );
		}
		
		// set font bold 
		if( kvs.hasOwnProperty( "fontbold" )){
			this.setFontBold(kvs["fontbold"] === ("1") );
		} else  if( go != null ) {
			this.setFontBold(go.isFontBold() );
		}
		
		// set font italic 
		if( kvs.hasOwnProperty( "fontitalic" )){
			this.setFontitalic( kvs["fontitalic"] === ("1") );
		} else   if( go != null ) {
			this.setFontitalic( go.isFontitalic() );
		}
		
		// set color/ stroke color 
		if( kvs.hasOwnProperty("color") ){
			this.setColorAttributesByString( kvs["color"] );
		} else   if( go != null ){
			this.setColor( go.getColor() );
			this.setStrokeColor( go.getStrokeColor() );
		}
		
		// fontsize 
		if( kvs.hasOwnProperty("fontsize") ){
			try{
				this.setFontsize( parseInt( kvs["fontsize"]) );
			} catch( e ){
				 if( go != null ){
					 this.setFontsize( go.getFontsize() );
				 }
			}
		} else if( go != null ){
			this.setFontsize( go.getFontsize() );
		}
    }

    getStartLeafID() {
		return this.startLeafID;
	}

	setStartLeafID( startLeafID) {
		this.startLeafID = startLeafID;
	}

	getEndLeafID() {
		return this.endLeafID;
	}

	setEndLeafID( endLeafID) {
		this.endLeafID = endLeafID;
	}

	getFontsize() {
		return this.fontsize;
	}

	setFontsize( fontsize) {
		this.fontsize = fontsize;
	}

	isFontitalic() {
		return this.fontitalic;
	}

	setFontitalic( fontitalic) {
		this.fontitalic = fontitalic;
	}

	isFontBold() {
		return this.fontbold;
	}

	setFontBold( fontfold) {
		this.fontbold = fontfold;
	}

	getTextangle() {
		return this.textangle;
	}

	setTextangle( textangle) {
		this.textangle = textangle;
	}

	getSpaceBetweenColumns() {
		return this.spaceBetweenColumns;
	}

	setSpaceBetweenColumns( spaceBetweenColumns) {
		this.spaceBetweenColumns = spaceBetweenColumns;
	}

	getRoundedCorner() {
		return this.roundedCorner;
	}

	setRoundedCorner( roundedCorner) {
		this.roundedCorner = roundedCorner;
	}
}

export default ColumnAttributesContainerParser