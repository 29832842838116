<template>
<div>
  <!-- <md-content class="md-elevation-2"> -->
    <span v-if="auth=='loggedin'">
      <b-navbar v-show="showNav" toggleable="lg"   class="fixed-top navbar-expand" style="background-color:white;min-width:960px">
        <span>
          <b-button variant="link" to="/treeview" v-on:click.native="reloadActiveData()" title="View Tree">
            <img src="image/logo_.png" fluid width="40px" to="/treeview" />
          </b-button>
        </span>
        <b-navbar-brand v-if="auth=='loggedin'" href="https://evolgenius.info//evol/evolStat.php" tag="h1" target="_blank" title="Evolview stats" style="font-size:2.5em;color:#20140C;font-family:Verdana;text-decoration:none">Evolview</b-navbar-brand>
        <b-navbar-toggle target="nav_collapse" />
        <b-collapse is-nav id="nav_collapse">
                <b-navbar-nav :class=" auth!='loggedin' ? 'mx-auto': null ">
                  <model-select v-show="isTool && !shareMode && auth=='loggedin'" :options="projects.poptions"
                                            v-model="projects.projDropComp"
                                            placeholder="select Project"
                                            @input="switchProject"
                                            style="width:350px"                                        
                                            >
                  </model-select>        
                  <model-select v-show="isTool && !shareMode && auth=='loggedin'" :options="trees.toptions"
                                            v-model="trees.treeDropComp"
                                            placeholder="select Tree"
                                            @input="switchTree"                                        
                                            >
                  </model-select>        
                  <span v-show="shareMode">Share Mode</span>
                  <model-select v-show="shareMode" :options="sharedProj.options"
                                            v-model="sharedProj.sel"
                                            placeholder="select Project"
                                            @input="switchSharedTree"
                                            >
                  </model-select>                
                </b-navbar-nav>
                <!-- Right aligned nav items <font-awesome-icon icon="info-circle" class="iconsz" title="citation" style="padding:0px"></font-awesome-icon> -->
                <b-navbar-nav :class=" auth!='loggedin' ? null: 'ml-auto' ">
                <b-navbar-nav>
                  <b-nav-item-dropdown text="Old versions" right v-if="auth=='loggedin'" style="padding-top:10px;font-size:20px;">          
                    <b-dropdown-item href="https://www.evolgenius.info/evolview-v2/" >Version 2</b-dropdown-item>
                    <b-dropdown-item href="https://www.evolgenius.info/evolview-v3/" >Version 3</b-dropdown-item>
                  </b-nav-item-dropdown>
                  <b-nav-item v-if="auth=='loggedin'" class="nav-link" @click="showCitation" style="font-weight:bold;font-size:20px;">Cite</b-nav-item>  
                  <b-nav-item v-if="auth=='loggedin' && !shareMode" class="nav-link" to="/projects"><font-awesome-icon icon="list-alt" class="iconsz" title="Projects" style="padding:0px"></font-awesome-icon></b-nav-item>  
                  <b-nav-item href="https://www.evolgenius.info/help/" v-if="auth=='loggedin'" class="nav-link iconsz" target="_blank" ><font-awesome-icon icon="question-circle" title="help"></font-awesome-icon></b-nav-item>                 
                </b-navbar-nav>
                <md-button v-if="auth=='loggedin' && !shareMode" class="md-icon-button" title="full screen" @click="goFullScreen" style="margin-top:0px;align-self:center">
                      <font-awesome-icon icon="expand"  class="iconsz" style="color:grey;"></font-awesome-icon>
                </md-button>
                <b-nav-item-dropdown right v-if="auth=='loggedin'" style="padding-top:10px">      
                    <template slot="button-content">
                      <span v-show="loginType != 2">
                        <em><font-awesome-icon icon="user-circle" class="iconsz"></font-awesome-icon></em>
                        &nbsp;{{userName}}
                      </span>
                    <span v-show="loginType == 2">
                      <img
                            :src="authUser.picture"
                            alt="User's profile picture"
                            class="nav-user-profile d-inline-block rounded-circle mr-3"
                            width="25"
                      />&nbsp;{{userName}}            
                    </span>
                    </template>
                    <b-dropdown-item href="#" v-on:click="showPassReset"  >Profile</b-dropdown-item>
                    <b-dropdown-item href="#" v-on:click="logout">Signout</b-dropdown-item>
                </b-nav-item-dropdown>        
        </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </span>
    <span v-else-if="auth=='' && shareMode">
      <b-navbar v-show="showNav" toggleable="lg"   class="fixed-top navbar-expand" style="background-color:white;min-width:960px">
          <b-button variant="link" to="/" title="View Tree">
                <img src="image/logo_.png" fluid width="40px" to="/treeview" />
          </b-button>
          <b-navbar-brand href="https://evolgenius.info//evol/evolStat.php" tag="h1" target="_blank" title="Evolview stats" style="font-size:2.5em;color:#20140C;font-family:Verdana;text-decoration:none">Evolview</b-navbar-brand>
          <b-navbar-toggle target="nav_collapse" />
          <b-collapse is-nav id="nav_collapse">
            <b-navbar-nav class="mh-auto">
              <span v-show="shareMode">Share Mode</span>
              <model-select v-show="shareMode" :options="sharedProj.options"
                                        v-model="sharedProj.sel"
                                        placeholder="select Project"
                                        @input="switchSharedTree"
                                        >
              </model-select>
            </b-navbar-nav>
          </b-collapse>
      </b-navbar>
    </span>
    <span v-else-if="auth=='' && !shareMode">
      <b-navbar v-show="showNav" toggleable="lg"   class="fixed-top navbar-expand" style="background-color:white;min-width:960px">
        <b-container>
          <b-navbar-nav class="mh-auto">
            <b-button variant="link" to="/" title="View Tree">
                <img src="image/logo_.png" fluid width="40px" to="/treeview" />
            </b-button>
            <b-navbar-brand href="https://evolgenius.info/evol/evolStat.php" tag="h1" target="_blank" title="Evolview stats" style="font-size:2.5em;color:#20140C;font-family:Verdana;text-decoration:none;padding-top:17px">Evolview</b-navbar-brand>
            <b-nav-item @click="trialLogin" class="navclass" style="color:black;padding-top:15px;font-weight:bold" target="_blank" >Try me</b-nav-item>         
            <b-nav-item href="https://www.evolgenius.info/help/" class="navclass" style="color:black;padding-top:15px;font-weight:bold" target="_blank" >Help pages</b-nav-item>                 
            <b-nav-item href="#" to="/about" class="navclass" style="color:black;padding-top:15px;" >About us</b-nav-item>         
            <b-nav-item v-b-toggle.show_login @click="scrollUp" class="navclass" style="color:black;padding-top:15px;font-weight:bold" target="_blank" >Login</b-nav-item>         
          </b-navbar-nav>
        </b-container>        
      </b-navbar>
    </span>   
  <b-collapse id="show_login" v-model="mod_visible" class="mt-2">
        <b-card>
          <b-form v-on:submit.prevent="login" inline>            
            <label class="sr-only" for="email">Username</label>
            <b-input-group prepend="@" class="mb-0 mr-sm-2">
              <b-input id="email" v-model="email" type="email" :state="validation" placeholder="Username" />
              <b-form-invalid-feedback :state="validation">
                {{ log_check_status }}
              </b-form-invalid-feedback>
            </b-input-group>

            <label class="sr-only" for="inlineFormInputName2">Password</label>
            <b-input type="password" v-model="password" class="mb-2 mr-sm-2 mb-sm-0" id="inlineFormInputName2" placeholder="Password" />

            <!-- <b-form-checkbox  v-model="iStayLogIn" @click="doStayLogin">Stay logged in</b-form-checkbox> -->

            <b-button variant="outline-primary" class="mr-1" type="submit">Login</b-button>
            <b-button variant="outline-danger" @click="mod_visible = !mod_visible" class="mr-1">Cancel</b-button>
            <SocialLog/>
          </b-form>
        </b-card>
  </b-collapse> 
  <modal id="passwindow" name="passwindow"        
        :draggable="true"  
        :adaptive="true"
        height="auto"
        @before-close="beforeClose"     
    >
        <b-card no-body>
          <template v-slot:header>
                    <h3>User settings</h3>
          </template>
          <b-card-body style="text-align: left;">
            <b-row>
              <b-col sm="3">Name</b-col>
              <b-col>{{this.userName}}</b-col>
            </b-row>
            <span v-show="loginType == 0">
              <b-row>
                  <b-col sm="3">Old password</b-col>
                  <b-col >
                          <input type="password" v-model="oldPassword" class="form-control" placeholder="Enter old Password" required>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="3">New password</b-col>
                  <b-col >
                          <input type="password" v-model="newPassword" class="form-control" placeholder="Enter new Password" required>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="3">Retype new password</b-col>
                  <b-col >
                          <input type="password" v-model="rePassword" class="form-control" :state="validateNewPass" placeholder="Retype new Password" required>
                  </b-col>
              </b-row>
            </span>
            <span v-show="loginType == 1">
              <b-row>
                <b-col sm="3"></b-col>
                <b-col><button>Register this account</button></b-col>
              </b-row>
            </span>
            <span v-show="loginType == 2">
              <b-row>
                <b-col sm="3">Login source</b-col>
                <b-col>{{authUser.user_id.substring(0,authUser.user_id.indexOf('|'))}}</b-col>
              </b-row>
              <b-row>
                <b-col sm="3">Email address</b-col>
                <b-col>{{authUser.email}}</b-col>
              </b-row>
            </span>
          </b-card-body>
          <template v-slot:footer>
                <b-button size="sm" variant="danger" @click="closePassWin()">
                    Cancel
                </b-button>
                <b-button size="sm" variant="success" @click="updatePass()" v-show="loginType == 0">
                    Submit
                </b-button>
            </template>
        </b-card>
  </modal>
  <v-dialog />
  </div>
</template>

<script>
import EventBus from './EventBus'
import router from '../router'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import { constants } from 'fs'
import UserDataTree from '../treeObjects/UserDataTree'
import { ModelSelect } from 'vue-search-select'
import SocialLog from './snsaccounts'
const _ = require("underscore")
const CryptoJS = require("crypto-js");

// const bcrypt = require("bcrypt")


export default {
    components: {ModelSelect,SocialLog},
    data () {
        return {
          server_add : this.$hostname,
          showNav:true,
          auth: '',
          user: '',
          email: '',
          iStayLogIn:false,          
          log_check_status: '',
          showDropDown:true,
          mod_visible:false,
          password: '',
          userId: '',
          isTool:true,
          projects:{
            enable:false,
            projTreeMap:[],
            activeProj: '',
            projDropComp: '',
            pvalue: '',
            poptions: [],
          },
          trees:{
            activeTree: '',
            treeDropComp: '',
            tvalue: '',
            toptions: [],
          },          
          currentPhyloTree:null,
          currentTreePartnerMap:null,
          currentTreeRectPosMap:null,
          currentTreeCirPosMap:null,
          shareMode:false,
          sharedProj:{
            options:[],
            sel:'',
            treeMap:{}
          },
          treeWidth:20,
          projWidth:20,
          cancelUsed : false,
          oldPassword:'',
          newPassword:'',
          rePassword:'',
          userName:'',
          loginType:0,
          authUser:{user_id:''},
        }
    },
    created() {
      EventBus.$on('logged-in', status => {
        if(status=='100' || status=='101'){
          this.auth = 'loggedin'
          this.afterLogin()
        }else{
          this.auth = ''
        }
      })
      EventBus.$on('new-tree', info => {
        EventBus.$emit('hide-canvas', {'state':true,'src':'navbar-256','type':'normal'})
        this.loadProjects(info.proj,info.tree)
      })
      EventBus.$on('update-tree', info => {
        EventBus.$emit('hide-canvas', {'state':true,'src':'navbar-261','type':'normal'})
        this.loadTreeList(info.tree)
      })
      EventBus.$on('new-dataset', info => {
        EventBus.$emit('hide-canvas', {'state':true,'src':'navbar-264','type':'normal'})
        this.findActiveTree(info.ds.TreeID) //,info.ds.ID
      })
      EventBus.$on('update-dataset', info => {
        EventBus.$emit('hide-canvas', {'state':true,'src':'navbar-269','type':'normal'})
        this.findActiveTree(info.ds.dbserial)
      })
      EventBus.$on('authUser',authUser=>{
        this.authUser = authUser
      })
      EventBus.$on('logtype', log => {
         this.loginType = log
      })
      // EventBus.$on('show-loader', state => {
      //    this.isInfoLoading = state
      // })
      EventBus.$on('load-sharetree', info => {
        EventBus.$emit('hide-canvas', {'state':true,'src':'NavBar-278','type':'share'})
        this.showNav = true
        this.shareMode = true
        console.log(this.shareMode,this.auth)
        this.loadSharedData(info,'share')
      }) 
      EventBus.$on('load-embedtree', info => {
        EventBus.$emit('hide-canvas', {'state':true,'src':'NavBar-283','type':'embed'})
        this.setEmbedMode()
        this.loadSharedData(info,'embed')
      })
    },
    mounted() {
      // this.init()      
      if(localStorage.getItem("usertoken") != null){
        this.auth = 'loggedin'
        this.afterLogin()
      }else{
        console.log("user login is empty")
      }
    },
    watch:{
      $route (to, from){
        if(this.$route.name === 'ToolWindow'){
          this.isTool = true
        }else{
          this.isTool = false
        }
      },
      "$store.state.user": {
            handler: function(nv) {
                this.user = nv
                this.userId = nv.userId
            },
            immediate: true
        }
    },
    methods: {
      goFullScreen(){
                EventBus.$emit('fullscreen','')
      },
      scrollUp(){
          window.scrollTo(0,0);
      },
      checkSessionStatus(){

      },
      setEmbedMode(){
        this.showNav = false        
        EventBus.$emit('hide-navbar', 1)
      },
      trialLogin(){
        var trial_dat = {}
        axios.post(this.server_add+'query/getMaxUserId',
            {
            }).then(res => {
              if(res.status == 200){
                console.log(res)
                if(res.data.hasOwnProperty('error')){
                  // console.log(res.data) 
                  this.log_check_status = res.data.error
                }else{
                  if(this.mod_visible){
                    this.mod_visible = !this.mod_visible
                  }
                  // this.$root.$emit('bv::toggle::collapse', 'show_login')
                  localStorage.setItem('usertoken', JSON.stringify(res.data))
                  this.$store.commit('userChange', res.data)
                  this.userName = res.data.userId
                  this.loginType = 1
                  this.email = ''
                  this.password = ''
                  this.projects.projDropComp = ''
                  this.trees.treeDropComp = ''
                  router.push({ name: 'ToolWindow' })
                  this.emitMethod()
                  // this.afterLogin()
                }
              }else{
                  this.showErrorMessage('server connection error '+res)
              }  
          }).catch(err => {
              this.showErrorMessage('server connection error '+err)
        })        
      },
      login () {
        if(this.email.length>1 && this.password.length>=5){
          // bcrypt.hash(this.password,10,(err,hash)=>{
            var encrypted = CryptoJS.AES.encrypt(this.password, "DamOpenCollegeSoon2019");
            axios.post(this.server_add+'query/login',
              {
                email: this.email,
                password: encrypted.toString()
              }).then(res => {
                if(res.status == 200){
                  //console.log(res)
                  if(res.data.hasOwnProperty('error')){
                    console.log(res.data) 
                    this.log_check_status = res.data.error
                  }else{
                    this.$root.$emit('bv::toggle::collapse', 'show_login')
                    localStorage.setItem('usertoken', JSON.stringify(res.data))
                    this.$store.commit('userChange', res.data)
                    this.userName = res.data.name
                    this.loginType = 0
                    this.email = ''
                    this.password = ''
                    this.projects.projDropComp = ''
                    this.trees.treeDropComp = ''
                    router.push({ name: 'ToolWindow' })
                    this.emitMethod()
                    // this.afterLogin()
                  }
                }else{
                    this.showErrorMessage('server connection error '+res)
                }  
            }).catch(err => {
                    this.showErrorMessage('server connection error '+err)
            })
          // })
        }else{
          this.showErrorMessage('Username or password cannot be empty')
        }
      },
      doStayLogin(){
        // console.log('i am here',this.iStayLogIn)
        localStorage.setItem('staylog', this.iStayLogIn)
      },
      afterLogin(){
          const token = localStorage.getItem("usertoken")
          const decoded = JSON.parse(token)
          this.userId = decoded.userId
          if(decoded.hasOwnProperty('name')){
            this.userName = decoded.name
            this.loginType = 0
          }else{
            this.userName = decoded.userId
            this.loginType = 1
          }
          EventBus.$emit('proj-user', this.userId)
          var curPag = this.$router.currentRoute
          if(curPag.path != '/treeview'){
            // this.$router.push({ path: '/tool' })
            this.isTool = false
          }
          this.init()
      },
      afterLogout(){
          this.userId = ''
      },
      emitMethod () {
          EventBus.$emit('logged-in', '100')
      },        
      logout () {        
        if(this.loginType == 0){
          EventBus.$emit('logged-in', '000')
          localStorage.removeItem('curtree')
          localStorage.removeItem('curpro')
          localStorage.removeItem('projlist')
          var saveState = this.iStayLogIn
          if(!saveState){
            localStorage.removeItem('usertoken')
          }
          this.projects.projDropComp = ''
          this.trees.treeDropComp = ''
          EventBus.$emit('clear-canvas', '1')
          EventBus.$emit('disable-all', '1')
          router.push({ name: 'Home' })
        }else if(this.loginType == 1){
          let that = this
          this.$modal.show('dialog', {
            title: 'Confirmation',
            text: 'Youre a temporary user; logging out means losing all your projects/trees/datasets.To keep them, please sign up and transfer your data to your new account.To create a new account, click Sign up on the header bar.Continue logging out?',
            buttons: [
              {
                title: 'Cancel',
                handler: () => {
                  this.$modal.hide('dialog')
                }
              },
              {
                title: 'Submit',
                handler: () => {
                  EventBus.$emit('logged-in', '000')
                  localStorage.removeItem('curtree')
                  localStorage.removeItem('curpro')
                  localStorage.removeItem('projlist')
                  var saveState = that.iStayLogIn
                  if(!saveState){
                    localStorage.removeItem('usertoken')
                  }
                  this.projects.projDropComp = ''
                  this.trees.treeDropComp = ''
                  EventBus.$emit('clear-canvas', '1')
                  EventBus.$emit('disable-all', '1')
                  router.push({ name: 'Home' })
                  this.$modal.hide('dialog')
                }
              }              
            ]
          })          
        } else if(this.loginType == 2){
          // this.$auth.logout();
          EventBus.$emit('logged-in', '000')
          localStorage.removeItem('curtree')
          localStorage.removeItem('curpro')
          localStorage.removeItem('projlist')
          var saveState = this.iStayLogIn
          if(!saveState){
            localStorage.removeItem('usertoken')
          }
          router.push({ name: 'Home' })
        }
      },
      init(){
        if(['ToolWindow'].indexOf(this.$route.name) == 0 ){
            this.loadProjects(null,null)
        }
        // console.log(this.$route.name,['ToolWindow'].indexOf(this.$route.name),['EmbedToolWindow/:embedId'].indexOf(this.$route.name))
      },
      reloadActiveData(){        
        EventBus.$emit('reload-svg',true);
      },      
      switchProject(){
          this.projWidth =  this.projects.projDropComp.projectName.length
          this.updateProjectStatus(this.projects.activeProj.value,this.projects.projDropComp.value)
          this.projects.activeProj = this.projects.projDropComp
          this.fireProjectChange()
          this.loadTreeList(null)
      },        
      switchTree(){
        this.treeWidth = this.trees.treeDropComp.text.length
        this.updateTreeStatus(this.trees.activeTree.value,this.trees.treeDropComp.value)
        this.trees.activeTree = this.trees.treeDropComp
        this.switchToActiveTree()
      },
      switchSharedTree(){
        var that = this
        if(that.sharedProj.sel != null){
            var res = _.filter(that.sharedProj.options,function (tree) {
              return tree.value == that.sharedProj.sel;
            })
            if(res.length>=1){
              this.sharedProj.sel = res[0]
            }
        }
        // console.log(this.sharedProj.sel,res,that.sharedProj.options)
        this.sharedProj.activeTree = this.sharedProj.sel
        this.getTreeData(true,'share',this.sharedProj)
      },
      async loadProjects(activeProjId,activeTreeId) {
        // console.log(this.userId)
        // EventBus.$emit('loadMess', "Wait,loading projects..")
        await axios.post(this.server_add+'query/listPro',
        {
            userId: this.userId
        }).then(pro => {
            if(pro.status == 200){ 
                var foundMain = false
                this.projects.poptions = []                   
                for (let i = 0; i < pro.data.length; i++) {
                    pro.data[i]['text'] = pro.data[i].projectName
                    pro.data[i]['value'] = pro.data[i].id
                    this.projects.poptions.push(pro.data[i])
                    if(activeProjId != null && !foundMain && pro.data[i].id == activeProjId){
                        this.projects.activeProj = pro.data[i]
                        foundMain = true;
                        this.projects.projDropComp = pro.data[i] //pro.data[i].projectName
                        this.fireProjectChange()
                    }else if(pro.data[i].active == 1 && !foundMain && pro.data[i].projectName != 'SHOWCASES' && pro.data[i].projectName != 'DEMOS' && pro.data[i].projectName != 'EXAMPLES'){
                        this.projects.activeProj = pro.data[i]
                        foundMain = true;
                        this.projects.projDropComp = pro.data[i] //pro.data[i].projectName
                        this.fireProjectChange()
                    }
                }
                if(foundMain){
                  this.loadTreeList(activeTreeId)
                }
                this.fireloadedProjList()
                if(!foundMain &&  pro.data.length>=1){
                    //console.log("foundmain is now "+foundMain)
                    this.projects.activeProj = pro.data[0]
                    foundMain = true;
                    this.projects.projDropComp = pro.data[0]//pro.data[0].projectName
                    this.fireProjectChange()
                    this.loadTreeList(activeTreeId)
                }
            }else{
                this.showErrorMessage('erroor getting projects data from server ')
            }
        }).catch(err => {
            this.showErrorMessage('server connection error '+err)
        })
        },
        loadTreeList(activeTreeId) {
        this.trees.toptions=[]
        this.trees.treeDropComp = ''
        // console.log(this.projects.projDropComp)
          EventBus.$emit('hide-canvas', {'state':true,'src':'NavBar-580','type':'normal'})
          if(this.checkIfExist('pro',this.projects.projDropComp.id)){
              let tree = this.getJsonProject('pro',this.projects.projDropComp.id)
              for (let j = 0; j < tree.length; j++) {
                tree[j]['text'] = tree[j].treeName
                tree[j]['value'] = tree[j].id
                this.trees.toptions.push(tree[j])
              }
              this.trees.toptions.sort(function (a, b) {
                return a.order - b.order;
              });
              EventBus.$emit('closeLoadWin', true)
              this.findActiveTree(activeTreeId)
          }else{
              // console.log("need to dl" + this.projects.projDropComp.value)
              axios.post(this.server_add+'query/listProTree',
              {
                  projectId: this.projects.projDropComp.id,
                  userId:this.projects.activeProj.userId
              }).then(tree => {
                  if(tree.status == 200){
                      for (let j = 0; j < tree.data.length; j++) {
                        tree.data[j]['text'] = tree.data[j].treeName
                        tree.data[j]['value'] = tree.data[j].id
                        // console.log(tree.data[j])
                        this.trees.toptions.push(tree.data[j])
                      }
                      this.trees.toptions.sort(function (a, b) {
                        return a.order - b.order;
                      });
                      let found = this.checkIfExist('pro',this.projects.projDropComp.id)
                      if(!found)
                          this.projects.projTreeMap.push({'pro':this.projects.projDropComp,'val':tree.data})
                      //console.log(found +' '+this.projTreeMap.map(a => a.pro))
                      EventBus.$emit('closeLoadWin', true)
                      this.findActiveTree(activeTreeId)
                  }else{
                      this.showErrorMessage('Error getting tree data from server!')
                  }
              }).catch(err => {
                  this.showErrorMessage('server connection error '+err)
              })
          }           
        },
        async loadSharedData(shareId,shareEmbedTreeType){
          await axios.post(this.server_add+'query/getSharedTree',
          {
            shareName:shareId
          }).then(tree => {
              if(tree.status == 200){
                var datAr = tree.data
                var usdTreeAr = []
                this.sharedProj = {'id':shareId,'value':shareId,'projectName':shareId,'text':shareId, 'sel':''}                
                this.shareMode = true
                var that = this
                datAr.forEach(dat => {
                  var ud = new UserDataTree()
                  ud.setDbserial(dat["TreeID"]);
                  ud.setTreename(dat["ProjectName"] + " : " + dat["realName"]); // 
                  ud.setShareTreedAlias(dat["TreeName"]);
                  ud.setProjectName(dat["ProjectName"]);
                  ud.setProjectColor(dat["Color"]);
                  ud.setSharedDBserial(dat["ID"]);
                  ud.setSharedMode(dat["SharingType"]);
                  ud.setTreeContent(dat['TreeContent'])
                  ud.setTreeFormat(dat['TreeFormat'])
                  ud.setUserId(dat['UserID'])
                  ud['value'] =  dat["TreeID"]
                  ud['text'] = ud.getTreeName()                 
                  usdTreeAr.push(ud)
                  // that.sharedProj.treeMap[ud.value] = ud
                });
                this.sharedProj.options = usdTreeAr
                this.sharedProj.sel = usdTreeAr[0]
                this.sharedProj.activeTree = this.sharedProj.sel
                this.getTreeData(true,'share',this.sharedProj)
              }
          }).catch(err => {
            console.log(err)
          })
        },
        showErrorMessage(errorMsg) {
          this.$modal.show('dialog', {
            title: 'Error',
            text: errorMsg,
            buttons: [
              {
                title: 'close',
                handler: () => {
                  this.$modal.hide('dialog')
                }
              }              
            ]
          })
        },
        checkIfExist (jsonKey,jsonValue){
          let status = false;
          for(var i = 0; i < this.projects.projTreeMap.length; i++){
              if(this.projects.projTreeMap[i].hasOwnProperty(jsonKey) && this.projects.projTreeMap[i][jsonKey] === jsonValue) {
                  return true;
              }
          }
          return false
        },
        getJsonProject (jsonKey,jsonValue){
          for(var i = 0; i < this.projects.projTreeMap.length; i++){
              if(this.projects.projTreeMap[i].hasOwnProperty(jsonKey) && this.projects.projTreeMap[i][jsonKey] === jsonValue) {
                  return this.projects.projTreeMap[i]['val']
              }
          }
          return null
        },
        findActiveTree(activeTreeId) {
          let foundActiveTree = false
          if(!foundActiveTree && activeTreeId != null){
            for(var i = 0; i < this.trees.toptions.length; i++){
                    if(this.trees.toptions[i].id === activeTreeId){
                        this.trees.activeTree = this.trees.toptions[i]
                        foundActiveTree = true
                        break
                    }
                }
          }
          if(this.trees.activeTree.hasOwnProperty('treeName') && this.trees.activeTree.id !== this.trees.treeDropComp.id && !foundActiveTree){
                for(var i = 0; i < this.trees.toptions.length; i++){
                    if(this.trees.toptions[i].id === this.trees.treeDropComp.id && !foundActiveTree){
                        this.trees.activeTree = this.trees.toptions[i]
                        foundActiveTree = true
                        break
                    }
                }
                //console.log("activated new tree "+foundActiveTree+" ")
          } 
          if(!foundActiveTree){
              for(var i = 0; i < this.trees.toptions.length; i++){
                  if(this.trees.toptions[i].active === 1 && !foundActiveTree){
                      this.trees.activeTree = this.trees.toptions[i]
                      foundActiveTree = true
                  }
              }
          }
          if(!foundActiveTree){
              if(this.trees.toptions.length>=1){
                  this.trees.activeTree = this.trees.toptions[0]
                  foundActiveTree = true
              }
          }
          if(foundActiveTree){
            // console.log('active tree is '+JSON.stringify(this.trees.activeTree))
            this.switchToActiveTree()
          }
        },
        switchToActiveTree(){
          resolve => setTimeout(resolve, 3000); // 3 sec
          this.deActivateOtherTress(this.trees.activeTree.value)
          this.selectActiveTreeOnDropDown()
          this.getTreeData(false,'normal',this.trees)
        },
        selectActiveTreeOnDropDown(){
          let that = this
          if(this.trees.activeTree != null){
            var res = _.filter(that.trees.toptions,function (tree) {return tree.value === that.trees.activeTree.value;})
            if(res.length>=1){
              this.trees.treeDropComp = res[0]
            }
          }
        },
        deActivateOtherTress(activeTreeId) {
          for(var i = 0; i < this.trees.toptions.length; i++){
              if(this.trees.toptions.id === activeTreeId){
                  this.trees.toptions[i].active = 1
              }else{
                  this.trees.toptions[i].active = 0
              }
          }
        },
        async getTreeData(treeType,shareEmbedTreeType,trees){
          //get datasets
          //get canvas parameters
          //get cat panels
          EventBus.$emit('hide-canvas', {'state':true,'src':'NavBar-761','type':shareEmbedTreeType})
          await axios.post(this.server_add+'query/getTreeDatasets',
          {
            treeId: trees.activeTree.value
          }).then(dataSets => {
              if(dataSets.status == 200){
                axios.post(this.server_add+'query/getTreeCatPanels',
                {
                  treeId: trees.activeTree.value
                }).then(catPans => {
                    if(catPans.status == 200){
                      axios.post(this.server_add+'query/getTreeCanvasInfo',
                      {
                        treeId: trees.activeTree.value
                      }).then(canvasIn => {
                          if(canvasIn.status == 200){
                            var userDatTree = null
                            try{
                              if(treeType){
                                userDatTree = trees.activeTree
                              }else{
                                userDatTree = this.formUserDatTree(trees.activeTree,this.projects.activeProj)
                              }
                              // console.log(userDatTree)                            
                              if(userDatTree != null && userDatTree != undefined){
                                userDatTree.setCanvasParams(canvasIn.data)
                                userDatTree.setDatasets(dataSets.data,false)                              
                                for(var key in catPans.data){
                                  var cPan = catPans.data[key]
                                  // console.log(cPan.DatasetType,cPan.Active)
                                  userDatTree.addAcatPanelStatus(cPan.DatasetType,cPan.Active)
                                }                              
                                userDatTree.catpans = catPans.data
                                if(shareEmbedTreeType == 'normal'){
                                  this.fireTreeChange(userDatTree)
                                }else if(shareEmbedTreeType === 'share' || shareEmbedTreeType === 'Collaborate'){
                                  this.fireShareTreeChange(userDatTree)
                                }else if(shareEmbedTreeType === 'embed'){
                                  this.fireEmbedTreeChange(userDatTree)
                                }else{
                                  console.log('missed tree type ',shareEmbedTreeType,userDatTree)
                                }
                              }
                            }
                            catch(err) {
                              console.log('some error ',err)
                            }
                          }
                      }).catch(err => {
                        console.log(err)
                      })
                    }
                }).catch(err => {
                  console.log(err)
                })
              }
          }).catch(err => {
            console.log(err)
          })
        },
        formUserDatTree(treeObj,projInfo){
          var userDatTree = new UserDataTree()
          userDatTree.init(treeObj.value,treeObj.text,treeObj.active,treeObj.treeFormat,treeObj.color,treeObj.userId)
          userDatTree.projectName = projInfo.projectName
          userDatTree.treeContentString = treeObj.treeContent
          userDatTree.assign(treeObj)
          return userDatTree
        },
        showCitation() {          
          this.$modal.show('dialog', {
            title: 'Citation',
            text:'<p><ol><li>Subramanian et al. Evolview v3: a webserver for visualization, annotation, and management of phylogenetic trees, Nucleic Acids Research,(2019) <a href="https://academic.oup.com/nar/advance-article/doi/10.1093/nar/gkz357/5494715" target="_blank">View</a></li><li>He et al. Evolview v2: an online visualization and management tool for customized and annotated phylogenetic trees, Nucleic Acids Res, (2016). <a href="http://nar.oxfordjournals.org/content/44/W1/W236" target="_blank">View</a></li><li>Zhang et al. EvolView, an online tool for visualizing, annotating and managing phylogenetic trees. Nucleic Acids Res (2012) pp. 4. <a href="http://nar.oxfordjournals.org/content/40/W1/W569" target="_blank">View</a></li></ol></p>',
            buttons: [
              {
                title: 'close',
                handler: () => {
                  this.$modal.hide('dialog')
                }
              }              
            ]
          })
        },
        showPassReset(){
          this.oldPassword = ''
          this.newPassword = ''
          this.rePassword = ''
          this.$modal.show('passwindow');
        },
        closePassWin(){
          this.oldPassword = ''
          this.newPassword = ''
          this.rePassword = ''
          this.cancelUsed = true
          this.$modal.hide('passwindow');
        },
        updatePass(){
          let encOldPass = CryptoJS.AES.encrypt(this.oldPassword, "DamOpenCollegeSoon2019").toString();
          let encNewPass = CryptoJS.AES.encrypt(this.newPassword, "DamOpenCollegeSoon2019").toString();
                    console.log(encOldPass,encNewPass)

          axios.post(this.server_add+'query/updatePassword',
          {
              userId: this.userId,
              oldPass: encOldPass,
              newPass: encNewPass
            }).then(res => {
              console.log(res)
              if(res.status == 200){
                //console.log(res)
                if(res.data.hasOwnProperty('error')){
                  this.log_check_status = res.data.error
                }
              }else{
                  this.showErrorMessage('server connection error '+res)
              }  
          }).catch(err => {
                  this.showErrorMessage('server connection error '+err)
          })

          this.closePassWin()
        },
        beforeClose(event){
            if(!this.cancelUsed){
                event.cancel()
            }else{
              this.cancelUsed = false
            }
        },
        fireloadedProjList(){
          // EventBus.$emit('project-list', this.projects.poptions)
          this.$store.commit('projListChange', this.projects.poptions)
        },
        fireTreeChange(curTree){
          EventBus.$emit('hide-canvas', {'state':true,'src':'NavBar-896','type':'normal'})
          this.$store.commit('activeTreeChange', curTree)
        },
        fireShareTreeChange(curTree){
          this.shareMode = true
          EventBus.$emit('hide-canvas', {'state':true,'src':'NavBar-901','type':'share'})
          this.$store.commit('activeShareTreeChange', curTree)
        },
        fireEmbedTreeChange(curTree){
          EventBus.$emit('hide-canvas', {'state':true,'src':'NavBar-906','type':'embed'})
          this.$store.commit('activeEmbedTreeChange', curTree)
        },
        fireProjectChange(){
          // EventBus.$emit('current-project', this.projects.activeProj)
          this.$store.commit('activeProjChange', this.projects.activeProj)
        },
        updateProjectStatus(oldProjId,newProjId){
          axios.post(this.server_add+'query/updateProjState',
          {
              userId: this.userId,
              oldProjectId: oldProjId,
              newProjectId: newProjId
            }).then(res => {
              if(res.status == 200){
                //console.log(res)
                if(res.data.hasOwnProperty('error')){
                  this.log_check_status = res.data.error
                }
              }else{
                  this.showErrorMessage('server connection error '+res)
              }  
          }).catch(err => {
                  this.showErrorMessage('server connection error '+err)
          })
        },
        updateTreeStatus(oldTreeId,newTreeId){
          axios.post(this.server_add+'query/updateTreeState',
          {
              userId: this.userId,
              projectId: this.projects.activeProj.value,
              oldTreeId: oldTreeId,
              newTreeId: newTreeId
            }).then(res => {
              if(res.status == 200){
                //console.log(res)
                if(res.data.hasOwnProperty('error')){
                  this.log_check_status = res.data.error
                }
              }else{
                  this.showErrorMessage('server connection error '+res)
              }  
          }).catch(err => {
                  this.showErrorMessage('server connection error '+err)
          })
        }        
    },
    computed: {
        validation() {
          let regex = new RegExp(/([\w.\-_]+)?\w+@[\w-_]+(\.\w+){1,}/,'igm') 
          return this.email.length > 4 && regex.test(this.email)
        },
        validateNewPass(){        
          return this.newPassword === this.rePassword
        }     
    }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;600&display=swap');  
  .nopadding {
    padding-right: 1px !important;
    padding-left: 3px !important;
    /* margin: 0 !important; */
  }

  .bxshadow{
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    /* transition: all 0.3s cubic-bezier(.25,.8,.25,1); */
  }

  .iconsz{
    font-size: 1.8em;
  }

  .navclass{
    font-size: 1.5em;
    font-family: 'Nunito', sans-serif;
    text-decoration: none;
    color:black;
    font-weight: 600;
    padding-left: 50px;
    padding-right: 50px;
  }

  .nolink{
    text-decoration: none;
  }

  @media all and (min-width: 768px){
    body {
        min-width: 960px;
    }
  }
</style>