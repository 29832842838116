import DataContainerParserBase from './DataContainerParserBase'
import StringOps from '../utils/StringOps'
import DecorationItemAttributes from './DecorationItemAttributes'

class DecorationContainerParser extends DataContainerParserBase{
    constructor(datasetid, datasetContent, phylotree){
        super()

        /**
         *  internal ID to decoration items
         *  the input data will be something like:
         *  A	shape1,color1:strokecolor1	shape2,color2:strokecolor2
         *  B,C	shape3,color3:strokecolor3	shape4,color4:strokecolor4
         *  
         *  the resulting hashmap will be something like:
         *  $hash{ A } = [ shapeColorSet1, shapeColorSet2 ... ] and
         *  $hash{ B } = [ shapeColorSet3, shapeColorSet4 ... ]
         *  
         *  NOTE: if multiple leaf_label_name are present, only the first one will be used (for now) ...
         */
        this.leafID2Decoration = {};

        this.setDatasetID(datasetid);
		this.setOriginal_datastring(datasetContent);

        var lines = StringOps.JsArrayStringToArrayList( StringOps.splitStringByNewLine( datasetContent ) );
		for (var idx = 0; idx < lines.length; idx++) {
			var line = lines[idx].trim();
			if (line.trim().length > 0 && !line.startsWith("#")) { // ignore annotation lines
				var lineparts = 
                StringOps.JsArrayStringToArrayList( StringOps.splitStringByTab(line) );
				var part1 = lineparts[0].trim();
				// Modifiers -----------------------------
				if (part1.startsWith("!")) {
					this.parseModifier(lineparts, idx + 1);
				}
				//values ---------------------------------
				else if (lineparts.length >= 2) {

					/**
					 * note: par1 should be like:
					 *  - A, or
					 *  - A,B
					 */
					var leafnodeIDs = StringOps.JsArrayStringToArrayList( StringOps.splitStringBySeperator( part1, ",") );
					if (leafnodeIDs.length >=1) {
						var leafid = leafnodeIDs[0].trim();

						var decos = [];
						for (var idxdeco = 1; idxdeco < lineparts.length; idxdeco ++ ) {
							decos.push(new DecorationItemAttributes(lineparts[idxdeco]));
						}

						this.getLeafID2Decoration()[leafid] =  decos;
						
						/**
						 * in case of two or more leaf node IDs, i.e. leafnodeIDs.size() >= 2,
						 * also add internal ID to <getLeafID2Decoration>
						 */
						if( leafnodeIDs.length >= 2 ){
							var lca = phylotree.getLCA(leafnodeIDs);
							if( lca != null ){
								this.getLeafID2Decoration()[lca.getInternalID()] =  decos;
							}
						} // does this work? 
					}
				}
			}

		}// end of for
    }

    getLeafID2Decoration() {
		return this.leafID2Decoration;
	}
}

export default DecorationContainerParser