
class Point{
    constructor(options = {}){
        this.x = options && options.x || 0
        this.y = options && options.y || 0
        this.segPathList = []
    }

    getX(){
        return this.x
    }

    getY(){
        return this.y
    }

    distance(destination){
        return Math.sqrt(this.square(destination.x-this.x)+this.square(destination.y-this.y))
    }

    static distanceBetweenPoint(pt1,pt2){
        return Math.sqrt(this.square(pt2.x-pt1.x)+this.square(pt2.y-pt1.y))
    }

    static twoPointsCenter(pt1,pt2){
        return Math.round((pt1.pt.y+pt2.pt.y)/2)
    }

    static square(pt){
        return pt*pt
    }

    appendSegItem(seg){
        this.segPathList.push(seg)
    }

    closeSegItem(){
        this.segPathList.push(' z')
    }
  
    getSegPathListStr(){
        return this.segPathList.join(' ')
    }

    static createPoint(ptX = 0,ptY = 0){
        return {'x':ptX,'y':ptY}
    }

    static createPointStr(ptX = 0,ptY = 0, typ = 'M'){
        return typ +' '+ptX+' '+ptY+' '
    }

    static createArcStr(spx, spy, radius,angle,largeFlag,sweepFlag){
        return `A${radius},${radius},${angle},${largeFlag},${sweepFlag},${spx},${spy}`
    } //M${stx},${sty},

    static createCubicArcStr(x1, y1, x2, y2, x, y){
        return `C${x1},${y1},${x2},${y2},${x},${y}`
    }

    static createSVGPathSegLinetoAbs(ptX,ptY){
        return 'L '+ptX+ ' '+ptY+' '
    }

    // static createSVGPathSegArcAbs(x, y, radius, rotation,startAngle, endAngle, sweepFlag){
    //     return Point.describeArc(x, y, radius, rotation,startAngle, endAngle, false,sweepFlag)
    // }

    static lineToArc(x, y, radius, angle){
        var p = Point.polarToCartesian(x, y, radius, angle);
        return 'L '+ p.x+' ' +p.y+' '
    }

    static describeArc(x, y, radius, startAngle, endAngle){      
        var start = Point.polarToCartesian(x, y, radius, endAngle);
        var end = Point.polarToCartesian(x, y, radius, startAngle);
        var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
        var d = `M${start.x},${start.y}A${radius}, ${radius}, 0, ${largeArcFlag}, 0, ${end.x}, ${end.y}`;
        return d;
    }

    // static drawArc(rotation, largeArcFlag, sweepFlag,x , y){
    //     return 'A '+ +' '
    // }

    static polarToCartesian(centerX, centerY, radius, angleInDegrees) {
        var angleInRadians = (angleInDegrees-90) * Math.PI / 180.0;      
        return {
          x: centerX + (radius * Math.cos(angleInRadians)),
          y: centerY + (radius * Math.sin(angleInRadians))
        };
    }

    static getCoordinatesOnCircle(xy, radius, angle) {
		// angle cannot be higher than 360
		angle += 360.0;
		angle = angle %  360.0;

		// narrow angle within range of 0~90
		var nangle = angle %  90.0;
		// translate angle to radians
		var radians =  ((nangle * 2.0 * Math.PI) / 360.0);
		// assume that the center is at (0,0)
		var sin =  Math.sin(radians);
		var cos =  Math.cos(radians);
		var x = cos * radius;
		var y = sin * radius;
		// adjust x,y according to original angle
        var newx = 0, newy = 0;
		if (angle >= 0 && angle < 90) {
			newx = -x;
			newy = y;
		} else if (angle >= 90 && angle < 180) {
			newx = y;
			newy = x;
		} else if (angle >= 180 && angle < 270) {
			newx = x;
			newy = -y;
		} else if (angle >= 270 && angle < 360) {
			newx = -y;
			newy = -x;
		}

		// make a SVGpoint based on newx, newy; and move it to xy
		var newxy = Point.createPoint();
        newxy.x = newx+xy.x
        newxy.y = newy+xy.y
		return newxy;
    }

    static clearAllChildNodesFromSVGGElement(svgg) {
        if(svgg != null && svgg != undefined){
            svgg.clear()
        }
    }

    static deleteChildNodeFromLayerTree(svgg){
        if(svgg != null && svgg != undefined){
            svgg.remove()
        }
    }
    
}

export default Point
