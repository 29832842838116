<template>
  <!-- <div class="treebg" > -->
    <full-page :options="options" id="fullpage">
      <div class="section">
          <div class="container h-100">
            <br>
            <br>
            <b-row>
              <b-col>
                    <h2>About us</h2>
              </b-col>
            </b-row>
            <br>
            <b-row align-self="left" >
              <b-col class="col-md-12 mt-6 mx-auto ">              
                    <p style="font-size:1.2em;font-weight:bold;text-align: justify;text-justify: inter-word;">Evolview is an online visualization and management tool for customized and annotated phylogenetic trees. It allows users to visualize phylogenetic trees in various formats, customize the trees through built-in functions and user-supplied datasets and export the customization results to publication-ready figures.We provide revolutionized novel tools or interactive (web) interfaces to existing tools to make evolution analyses way much easier.</p>
              </b-col>
            </b-row>
            <h2> Citation </h2>
            <br>
            <b-row style="font-size:1.2em;font-weight:bold;align:left;text-align: justify;text-justify: inter-word;">
              <b-col>
                <ol>
                  <li >Subramanian et al. Evolview v3: a webserver for visualization, annotation, and management of phylogenetic trees, Nucleic Acids Research,(2019) <a href="https://academic.oup.com/nar/advance-article/doi/10.1093/nar/gkz357/5494715" target="_blank"> view link</a>.</li>
                  <br>
                  <li>He et al. Evolview v2: an online visualization and management tool for customized and annotated phylogenetic trees, Nucleic Acids Res, (2016). <a href="http://nar.oxfordjournals.org/content/44/W1/W236" target="_blank"> view link</a>.</li>
                  <br>
                  <li>Zhang et al. EvolView, an online tool for visualizing, annotating and managing phylogenetic trees. Nucleic Acids Res (2012) pp. 4. <a href="http://nar.oxfordjournals.org/content/40/W1/W569" target="_blank"> view link</a>.</li>
                  <br>
                </ol>
              </b-col>
            </b-row>
            <b-row style="font-size:1.2em;font-weight:bold;align:left">
              <b-col>
                <h2>
                  Local deployment
                </h2>
                <p>
                  Instructions for deploying evolview to a local computer <a href="https://www.evolgenius.info/help/locdeploy/" target="_blank"> View here </a>
                </p>
              </b-col>
            </b-row>
          </div>
      </div>
      <div class="section">
        <div class="container h-100">
          <br>
            <b-row style="font-size:1.2em;font-weight:bold;align:left">
              <b-col>
                <h2>
                  Version history
                </h2>                
                <table class="table table-bordered" width="100px"  style="background-color:rgba(0, 0, 0, 0);">
                  <thead>
                  <tr>
                  <th>site #</th>
                  <th>url</th>
                  <th>version</th>
                  <th>publication</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr>
                    <td>1.</td>
                    <td><a href="https://www.evolgenius.info/evolview-v2/" rel="nofollow" target="_blank">Evolview v2</a></td>
                    <td>version 2</td>
                    <td>He et al. Evolview v2 (2016)</td>
                    </tr>
                    <tr>
                    <td>2.</td>
                    <td><a href="https://www.evolgenius.info/evolview-v3/" rel="nofollow" target="_blank">Evolview v3</a></td>
                    <td>version 3</td>
                    <td>Subramanian et al. Evolview v3 (2019)</td>
                    </tr>
                    <tr>
                    <td>3.</td>
                    <td><a href="http://www.evolgenius.info/evolview/#/" rel="nofollow" target="_blank">Evolview v4</a></td>
                    <td>version 4</td>
                    <td>To be published. </td>
                    </tr>
                  </tbody>
                </table>
                <p> The subversions and detailed builds history is availiable at: <a href="https://www.evolgenius.info/help/verhistory/" target="_blank">view here</a></p>
              </b-col>
            </b-row>
            <br>            
            <b-row>
              <b-col>
                <h2>Feedback and contact us</h2>
                  <md-button class="md-raised" style="min-width:200px;height:160px;font-size:1.6em;font-weight:bold;" href="mailto:evolgeniusteam@gmail.com" target="_blank"><font-awesome-icon icon="envelope" class="iconsz" title="Projects" style="padding:0px"></font-awesome-icon> &nbsp;Email</md-button>
                  <md-button class="md-raised" style="min-width:200px;height:160px;font-size:1.6em;font-weight:bold;" href="https://github.com/evolgeniusteam/EvolviewDocumentation/issues" target="_blank"><font-awesome-icon icon="github" class="iconsz" title="Projects" style="padding:0px"></font-awesome-icon>&nbsp;Report issues</md-button>
                  <md-button class="md-raised" style="min-width:200px;height:160px;font-size:1.6em;font-weight:bold;" href="https://www.evolgenius.info/help/faq/" target="_blank"><font-awesome-icon icon="question-circle" class="iconsz" title="Projects" style="padding:0px"></font-awesome-icon>&nbsp;FAQ</md-button>
              </b-col>
            </b-row>
        </div>
      </div>
    </full-page>
  <!-- </div> -->
</template>

<script>
export default {
  data() {
    return {
      options: {
        licenseKey: 'YOUR_KEY_HERE',
        scrollOverflow: true,
        scrollBar: false,
        menu: '#menu',
        navigation: true,
        // parallax:true,
        // parallaxOptions: {type: 'reveal', percentage: 70, property: 'translate'},
      }
    }
  },
}
</script>
