<template>
    <div>
    <modal id="modalScoped" name="modalScoped"        
        draggable=".window-header"
        :adaptive="true" 
        :min-height="minWinHt"
        :scrollable="true"
        @before-close="beforeClose"     
        :max-height="570"
        :resizable="true"
        style="z-index:1040"
    >
        <b-card no-body style="hwight:100%;width:100%" class="h-100">
            <template v-slot:header>
                <span v-if="modelType == 1">
                    <h3 class="window-header" style="cursor:all-scroll">Upload new Tree</h3>
                </span>
                <span v-if="modelType == 2">
                    <h3 class="window-header" style="cursor:all-scroll">Edit Tree</h3>
                </span>
                <span v-if="modelType == 3">
                    <h3 class="window-header" style="cursor:all-scroll">Upload new dataset ({{uploadDatasetType}}) </h3>
                </span>
                <span v-if="modelType == 4">
                    <h3 class="window-header" style="cursor:all-scroll">Edit dataset ({{uploadDatasetType}})</h3>
                </span>
            </template>
            <b-card-body style="text-align: left;">
                <span v-show="treeUpload">
                    <b-row>
                        <b-col sm="3">Project Name</b-col>
                        <b-col sm="5">
                            <b-form-input id="projNameTF" v-model="projNameTF" type="text" :state="validateProj" size="sm"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="3">Tree Name</b-col>
                        <b-col sm="5">
                            <b-form-input id="treeNameTF" v-model="treeNameTF" type="text" :state="validateTree" size="sm"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="3">Demo tree</b-col>
                        <b-col sm="4">
                            <b-form-select v-model="demTreeSel" :options="demoTreeOptions" @change="selDemoTree" size="sm"></b-form-select>
                        </b-col>
                    </b-row>
                </span>
                <span v-show="showFileTyp">
                    <b-row>
                        <b-col sm="3">Format</b-col>
                        <b-col sm="4">
                            <b-form-select v-model="inFileTypSel" :options="fileTypOptions" @change="selFormChange" size="sm"></b-form-select>
                        </b-col>
                    </b-row>
                </span>
                <span v-show="modelType == 3">
                    <b-row style="padding:5px">
                        <b-col sm="3">Dataset Name</b-col>
                        <b-col sm="5">
                            <b-form-input id="datasetNameTF" v-model="datasetNameTF" type="text" size="sm" :state="notEmptyDsName"></b-form-input>
                        </b-col>
                    </b-row>                    
                    <b-row style="padding:5px">
                        <b-col sm="3"></b-col>
                        <b-col sm="5">
                            <b-button size="sm" variant="info" @click="genTemplate">Generate Template</b-button>
                        </b-col>
                    </b-row>
                </span>
                <span v-show="modelType == 4">
                    <b-row style="padding:5px">
                        <b-col sm="3">Edit Dataset name</b-col>
                        <b-col sm="5">
                            <b-form-input id="datasetNameTF" v-model="datasetNameTF" type="text" size="sm" :state="notEmptyDsName"></b-form-input>
                        </b-col>
                    </b-row>
                </span>
                <span class="w-100" >
                    <b-row>
                        <b-col sm="3">Data</b-col>
                        <b-col >                                    
                            <codemirror ref="codMirror"  :value="rawTxtAr" :options="cmOptions" @input="onCmCodeChange" style="border-style: groove;"></codemirror>
                        </b-col>
                    </b-row>
                    <b-row style="margin-top:10px">
                        <b-col sm="3">Or Upload</b-col>
                        <b-col>
                            <b-form-file
                            v-model="upFile"
                            :state="Boolean(upFile)"
                            placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."
                            ></b-form-file>
                        </b-col>
                    </b-row>
                </span>                
            </b-card-body>
            <template v-slot:footer>                
                <b-button size="sm" variant="danger" @click="closeModal()">
                    Cancel
                </b-button>
                <b-button size="sm" variant="success" @click="submitModal()" >
                    Submit
                </b-button>
            </template>
        </b-card>
    </modal>
    <v-dialog :clickToClose="false"></v-dialog>
    </div>
</template>

<script>
import axios from 'axios'
import EventBus from './EventBus'
import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/addon/display/autorefresh'
import 'codemirror/mode/evolview/evolview'
import 'codemirror/theme/eclipse.css'

import UserDataTree from '../treeObjects/UserDataTree'
import UserDataDataset4Tree from '../treeObjects/UserDataDataset4Tree'
import Db from '../dbop/Db'
import Commons from '../utils/CommonEvol'
import DataTemplates from '../utils/DataTemplates'
import StringOps from '../utils/StringOps'

export default {
    props:['demoProjects','actTree','actProject','catPanel'],
    components:{
        codemirror
    },
    data() {
        return {
            userId:'',
            server_add : this.$hostname,
            modelType:'',
            uploadDatasetType:'',
            treeUpload:false,
            showFileTyp:false,
            projNameTF:'',
            treeNameTF:'',
            projList:[],
            treeList:[],
            activeProject:'',
            activeTree:'',
            name2catpanel:[],
            demTreeSel:null,
            demoTreeOptions:[],
            fileTypOptions:['newick(also phylip)','nhx','nexus','phyloXML'],
            inFileTypSel:'newick(also phylip)',
            inFileTypSelForm:'newick',
            editNameSave:false,
            oriDatsetName:'',
            datasetNameTF:'',
            rawTxtAr:'',
            cmOptions: {
                // codemirror options
                line: false,
                scroll:false,
                height:'auto',
                autoRefresh: true,
                autofocus: true,
                mode : "evolview",
                theme : "eclipse",
                readOnly : false,
                lineNumbers : true,
                lineWrapping : false,			
                styleActiveLine : true
            },
            upFile:null,
            upFileContStr:'',
            showFileUpload : false,
            isModalVisible : false,
            cancelUsed : true,
            disableSubmit:true,
            minWinHt:630,
            leafnames:'',
        }
    },
    created(){        
        let that = this
        
        EventBus.$on('dataset-type',datsetType=>{
            this.uploadDatasetType = datsetType
        })
        EventBus.$on('modal-props', props => {
            that.modelType = props[0]
            that.modContentStr = props[1]
            that.treeUpload = props[2]
            that.showFileTyp = props[3]
            that.showFileUpload = props[4]
            that.isModalVisible = props[5]
            that.cancelUsed = props[6]
            that.rawTxtAr = that.modContentStr
            if(that.modelType === 1){
                that.minWinHt = 630            
            }
            // else if(that.modelType === 3){
            //     that.minWinHt = 540            
            // }
        })

        // EventBus.$on('name2catpanel', catpanelMap => {
        //     that.name2catpanel = catpanelMap
        // })

        EventBus.$on('setModalFormatSel', formsel => {
            that.setModalFormatSel(formsel)
        })
        
        EventBus.$on('edit-tree', etree => {
            that.rawTxtAr = etree
            that.minWinHt = 560
            // that.minWinHt = 540            
        })
        EventBus.$on('leafnames', leafnames => {
            that.leafnames = leafnames            
        })
        EventBus.$on('edit-datset', edatset => {
            that.rawTxtAr = edatset.rawtxt
            that.datSetSerial = edatset.datsetserial
            that.uploadDatasetType = edatset.uploadDatasetType
            that.datSetName = edatset.name
            that.oriDatsetName = that.datSetName
            that.datasetNameTF = that.datSetName
            that.modelType = 4
            that.minWinHt = 560
            // that.minWinHt = 540
            // console.log('i am here',edatset)
        })
        
        EventBus.$on('closeModal', userid => {
            this.closeModal()
        })
        EventBus.$on('logged-in', status => {
            if(status=='100' || status=='101'){
            }else{
                this.panelShow = false;
                this.closeModal()
            }
        })
    },
    watch:{
        upFile(file){
            var reader = new FileReader();
            reader.readAsText(file);
            reader.onload = (event) => {
                this.upFileContStr = event.target.result;
            }
        },
        actTree(val){
            this.activeTree = val
        },
        actProject(val){
            this.activeProject = val
        },        
        catPanel(val){
            this.name2catpanel = val
        },
        demoProjects(val){
            this.demoTreeOptions = val
        },
        "$store.state.projList": {
            handler: function(nv) {
                this.projList = nv
            },
            immediate: true // provides initial (not changed yet) state
        },
        "$store.state.user": {
            handler: function(nv) {
                this.userId = nv.userId
            },
            immediate: true
        }
    },
    mounted() {
        if(localStorage.getItem("usertoken") != null){
            const token = localStorage.getItem("usertoken") 
            const decoded = JSON.parse(token)//jwtDecode
            this.userId = decoded.userId
        }
    },
    computed:{
        validateProj() {
          var found = true
          var that = this
          for(var ind in this.projList){
            var proj = this.projList[ind]
            if(proj.text == this.projNameTF){
                // console.log(proj)
                found = true
                that.disableSubmit = false
                proj.siblings.forEach(element => {
                    that.treeList.push(element.TreeName)
                });
                // this.loadTreesOftheProj(proj)
                break                    
            }
          }
            if(this.projNameTF.length<=0){
                found = false
                that.disableSubmit = true
            }
          return found
        },      
        validateTree() {
          var found = true
          var that = this
            if(this.treeList.includes(this.treeNameTF.trim())){
                found = false
                that.disableSubmit = true
            }
            if(this.treeNameTF.trim().length <=0){
                found = false
                that.disableSubmit = true
            }
          return found
        },
        codemirror() {
            return this.$refs.codMirror.codemirror
        },
        notEmptyDsName() {
                return this.datasetNameTF.length>=1
        },
    },
    methods: {
        beforeClose(event){
            if(!this.cancelUsed){
                event.cancel()
            }
        },
        selDemoTree(){
            this.rawTxtAr = this.demTreeSel.treeContent
            var format = this.demTreeSel.treeFormat
            this.setModalFormatSel(format)
        },
        onCmCodeChange(newCode) {
            this.rawTxtAr = newCode
        },
        selFormChange(){
            // console.log('format changed ',this.inFileTypSel)
            this.setModalFormatSel(this.inFileTypSel)
        },
        setModalFormatSel(format){
            // console.log(format)
            for(var ind in this.fileTypOptions){
                var form = this.fileTypOptions[ind]
                if(form.match(format)){
                    this.inFileTypSel = form
                    if(form.startsWith('newick')){
                        this.inFileTypSelForm = 'newick'
                    }else{
                        this.inFileTypSelForm = form
                    }
                    // console.log(this.inFileTypSelForm)
                }
            }
        },
        genTemplate(){
            let genDatTemplate = new DataTemplates(this.leafnames,this.uploadDatasetType)
            var legendStr = ''
            var headerStr = genDatTemplate.genHeader()
            var bodyStr = genDatTemplate.genBody()
            this.rawTxtAr = legendStr+"\n"+headerStr+"\n"+bodyStr
        },
        closeModal() {
            this.projNameTF = ''
            this.treeNameTF = ''
            this.treeList = []
            this.inFileTypSel = 'newick(also phylip)'
            this.demTreeSel = ''            
            this.rawTxtAr = ''
            this.treeUpload = false
            this.showFileTyp = false
            this.showFileUpload = false
            this.isModalVisible = false;
            this.cancelUsed = true
            this.disableSubmit = true
            this.$modal.hide('modalScoped');
        },
        submitModal(){
            //get the modal type
            //check the data tree/dataset
            //upload the data
            //retrive the record from db and load view
            var contentStr = this.rawTxtAr
            if(contentStr.length<=1){
                contentStr = this.upFileContStr
            }
            var error_msg = ''
            switch(this.modelType){
                case 1:{//upload new tree
                    var isTrueForm = Commons.checkTreeFormat(this.treeNameTF,this.inFileTypSelForm,contentStr)
                    if(isTrueForm.isTreeDataValid()){
                        Db.uploadTheTree(this.server_add,this.projNameTF,this.treeNameTF,this.inFileTypSelForm,contentStr,this.projList,this.treeList)
                    }else{
                        error_msg = 'Error with input tree:'+isTrueForm.getErrorMessage()
                    }
                    break
                }
                case 2:{//update tree
                    // console.log(this.inFileTypSelForm,contentStr,this.activeTree.userId,this.userId)
                    var doesUserHaveRights = Commons.isThisMyTree(this.activeTree.userId,this.userId)
                    var isTrueForm = Commons.checkTreeFormat(this.activeTree.treeName,this.inFileTypSelForm,contentStr)
                    if(doesUserHaveRights){
                        if(isTrueForm.isTreeDataValid()){
                            var treeStat = Db.updateTree(this.server_add,this.activeTree.dbserial,this.inFileTypSelForm,contentStr)
                            treeStat.then(res => {
                                if(res.status == 200){
                                    // console.log(res)                                
                                    EventBus.$emit('update-tree', {'proj':this.activeProj.id,'tree':this.activeTree.id})
                                    this.closeModal()
                                }else{
                                    error_msg = 'error updating the tree '+res
                                }
                            }).catch(err => {
                                error_msg = 'error updating '+err
                            })
                        }else{
                            error_msg = 'Error with input tree:'+isTrueForm.getErrorMessage()
                        }
                    }else{
                        // console.log(this.activeTree,this.userId)
                        error_msg = 'You dont have rights to update this tree!!'
                    }
                    break
                }
                case 3:{
                    //upload new dataset
                    var doesUserHaveRights = Commons.isThisMyTree(this.activeTree.userId,this.userId)
                    var isTruedat = Commons.checkDatasetFormat(this.activeProject,this.activeTree,this.name2catpanel,this.datasetNameTF,contentStr,this.uploadDatasetType)
                    // console.log(isTruedat)
                    if(doesUserHaveRights){
                        if(isTruedat != null){
                            if(isTruedat.ds && isTruedat.ds.isDataValid()){                                
                                var getOrdId = Db.getLatestOrderId(this.server_add,this.activeTree.dbserial)
                                getOrdId.then(ordStat => {
                                    if(ordStat.status == 200){
                                        var orderId = ordStat.data.ord+1                                
                                        var uploadStat = Db.uploadDataSet(this.server_add,orderId,this.activeTree.dbserial,this.datasetNameTF,contentStr,this.uploadDatasetType)                        
                                        uploadStat.then(res => {
                                            if(res.status == 200){
                                                Db.resetOtherDatasets(this.server_add,res.data.ID,res.data.DatasetType,this.activeTree.getdbserial())                                
                                                EventBus.$emit('new-dataset', {'ds':res.data})
                                                this.closeModal()
                                            }else{
                                                error_msg = 'error updating the tree '+res
                                            }
                                        }).catch(err => {
                                            error_msg = 'error updating '+err
                                        })
                                    }else{
                                        error_msg = 'error updating the tree '+ordStat.data
                                    }
                                }).catch(err => {
                                    error_msg = 'error updating '+err
                                })                        
                            }else{
                                error_msg = 'error with dataset '+isTruedat.ds.getErrorMessage()
                            }
                        }
                    }else{
                        // console.log(this.activeTree,this.userId)
                        error_msg = 'You dont rights to upload dataset to this tree!!'
                    }
                    break
                }
                case 4:{
                    // console.log(contentStr,this.activeTree.userId,this.userId,this.name2catpanel)
                    //update dataset
                    var doesUserHaveRights = Commons.isThisMyTree(this.activeTree.userId,this.userId)
                    var isTruedat = Commons.checkDatasetFormat(this.activeProject,this.activeTree,this.name2catpanel,this.datasetNameTF,contentStr,this.uploadDatasetType)
                    if(doesUserHaveRights){
                        if(isTruedat != null && isTruedat.ds != null && isTruedat.ds.isDataValid()){
                            if(StringOps.equalsIgnoreCase(this.datasetNameTF,this.oriDatsetName)){
                                var uploadStat = Db.updateDatSet(this.server_add,this.datSetSerial,contentStr)
                                uploadStat.then(res => {
                                    if(res.status == 200){                                
                                        EventBus.$emit('update-dataset', {'ds':this.activeTree})
                                        this.closeModal()
                                    }else{
                                        error_msg = 'error updating the tree '+res
                                    }
                                }).catch(err => {
                                    error_msg = 'error updating '+err
                                })
                            }else{
                                var getOrdId = Db.getLatestOrderId(this.server_add,this.activeTree.dbserial)
                                getOrdId.then(ordStat => {
                                    if(ordStat.status == 200){
                                        var orderId = ordStat.data.ord+1                                
                                        var uploadStat = Db.uploadDataSet(this.server_add,orderId,this.activeTree.dbserial,this.datasetNameTF,contentStr,this.uploadDatasetType)                        
                                        uploadStat.then(res => {
                                            if(res.status == 200){
                                                Db.resetOtherDatasets(this.server_add,res.data.ID,res.data.DatasetType,this.activeTree.getdbserial())                                
                                                EventBus.$emit('new-dataset', {'ds':res.data})
                                                this.closeModal()
                                            }else{
                                                error_msg = 'error updating the tree '+res
                                            }
                                        }).catch(err => {
                                            error_msg = 'error updating '+err
                                        })
                                    }else{
                                        error_msg = 'error updating the tree '+ordStat.data
                                    }
                                }).catch(err => {
                                    error_msg = 'error updating '+err
                                })
                            }                            
                        }else{
                            error_msg = 'error with dataset '+isTruedat.ds.getErrorMessage()
                        }
                    }else{
                        // console.log(this.activeTree,this.userId)
                        error_msg = 'You dont rights to upload dataset to this tree!!'
                    }
                    break
                }
            }
            if(error_msg.length>=1){
                this.showErrorMessage(error_msg,'Error')
            }
        },
        loadTreesOftheProj(proj) {
          axios.post(this.server_add+'query/listProTree',
          {
            projectId: proj.id,
            userId:proj.userId
          }).then(tree => {
              if(tree.status == 200){
                  for(var ind in tree.data){
                      var tr = tree.data[ind]
                      this.treeList.push(tr.treeName)
                  }
              }
          }).catch(err => {
            console.log(err)
          })
        },
        showErrorMessage(errorMsg,status) {
            let that = this
            this.$modal.show('dialog', {
            title: status,
            text: errorMsg,
            buttons: [
              {
                title: 'Close',
                handler: () => {
                  this.$modal.hide('dialog')
                }
              },                          
            ]
          })
        }
        
    },
}
</script>

<style>
/* .CodeMirror {
height: auto !important;
} */
.vm--container { z-index: 1100 !important; }

</style>