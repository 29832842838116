import NumericMatrixDataContainer from '../datacontainers/NumericMatrixDataContainer'
import StringOps from '../utils/StringOps'
import Point from '../treePainter/Point'

const FontStyle = {
    'NORMAL':'normal',
    'ITALIC':'italic',
    'OBLIQUE':'oblique',
    }
Object.freeze(FontStyle)

class Pies{
    constructor(disable_all_dataset,rootXY, layer_tree,treeSVG){
        this.pcDatasets = {}
        this.activePCDataID = ''
        this.treeSVG = treeSVG
        this.isAllPiechartsDisabled = false
        this.disable_all_dataset = disable_all_dataset
        this.rootXY = rootXY
        this.layer_tree_piecharts = layer_tree.group().id('layer_tree_piecharts')
        this.layer_piechart_showdata = layer_tree.group().id('layer_piechart_showdata')
        // this.layler_legends_pie = layer_tree.group().id('tree_leaf_labels')

        this.phylotree = ''
        this.BranchLengthPositions = ''
    }

    loadTreeData(phylotree, BranchLengthPositions ){
        this.phylotree = phylotree
        this.BranchLengthPositions = BranchLengthPositions
    }

    setBranchLengthPositions(BranchLengthPositions){
        this.BranchLengthPositions = BranchLengthPositions
    }

    getActivePie() {
        return this.pcDatasets[this.activePCDataID];
    }

    addPCData(pcdataname, newpcdata, active) {
        if (newpcdata.isDataValid()) {
            // add dataset
            this.pcDatasets[pcdataname] =  newpcdata;
            // if active
            if (active) {
                this.activePCDataID = pcdataname;
                this.makePies();
            }
            // console.log(active,pcdataname)
            // updateAllLegends();
        }
    }

    setDisableAllPiechartData( disableAll) {
        this.isAllPiechartsDisabled = disableAll;
        this.makePies();
        // updateAllLegends();
    }

    setActivePCDataByID( newid ) {
        // then update pies
        this.activePCDataID = newid;
        this.makePies();
        // updateAllLegends();
    }

    makePies(bReroot = false) {
        if (this.activePCDataID.length>=1 && !this.disable_all_dataset && !this.isAllPiechartsDisabled) {
            this.clearAllChildNodesFromSVGGElement(this.layer_tree_piecharts)

            var activePCData = this.pcDatasets[this.activePCDataID];
            var strokewidth = activePCData.getDefaultStrokeWidth();
            if (bReroot) {
                activePCData.reMapDataAfterTreeReroot(this.phylotree);
            }
            var opacity = activePCData.isIsUserInputOpacity() ? activePCData.getOpacity() : 0.6;
            const cats = activePCData.getLegendTexts()
            const data = activePCData.getTreeNodeID2Data();
			const colors = activePCData.getLegendColors();
            var strokecolors = activePCData.getLegendStrokeColors();
            var pie_counter = 0
            for (var entry in data) {
                var node_internalID = entry;
                const piechartdata = data[entry];
                var radius = activePCData.getRadiusByID(node_internalID);// April 8, 2011; fix bug in this
				if (activePCData.isByarea()) {
					radius = activePCData.getAreaByID(node_internalID);
                }
                var halfRadi = radius/2
                var sum = activePCData.getSumByID(node_internalID);
                if (radius > 0 && sum > 0 && this.BranchLengthPositions.hasOwnProperty(node_internalID)) {
                    var pos = this.BranchLengthPositions[node_internalID]
                    var pieGroup = this.layer_tree_piecharts.group().id('pie_group_'+pie_counter)
                    var piechart = pieGroup.group().id('pie_'+pie_counter)
                    piechart.style({ color: "#FFFFFF", opacity: opacity+""})
                    
                    //add mouse pointer for group
                    var that = this
                    pieGroup.mouseover(function(evt) {
                        that.treeSVG.makeDatSetTooltipShow(evt,colors,piechartdata,cats,null,'circle')
                    })
                    pieGroup.mouseout(function(evt) {
                        that.treeSVG.makeDatSetTooltipHide()
                    })

                    var start_angle = 0;
					for (var i = 0; i < piechartdata.length; i++) {
                        var angle_addup = piechartdata[i] / sum * 360;
                        if (angle_addup == 0) {
                            // do nothing
                        } else if (angle_addup == 360) {
                            var circle = piechart.circle(radius)
                            circle.attr("fill", colors[i]);
                            if (strokecolors[i].length>=1 && strokecolors[i] != ("none")) {
                                circle.attr({stroke: strokecolors[i],"stroke-width":strokewidth});
                            }
                            if (activePCData.isbShowData()) {
                                var text = pieGroup.text(piechartdata[i] + "").move(0,activePCData.getShowDataFontSize() * 1 / 3)
                                text.font({size:activePCData.getShowDataFontSize()})
                                if (activePCData.isbShowDataFontItalic()) {
                                    text.font({family:FontStyle.ITALIC});
                                }
                                if (!StringOps.equalsIgnoreCase(activePCData.getShowDataFontColor(),'black')) {
                                    text.attr("fill", activePCData.getShowDataFontColor());
                                }
                                text.attr("text-anchor", "middle"); // only allow to align in the middle                            
                            }
                        }else{
                            var start_on_arc = this.getCoordinatesOnCircle({'x':0,'y':0},radius,start_angle)
                            var stop_on_arc = this.getCoordinatesOnCircle({'x':0,'y':0},radius,angle_addup+start_angle)
                            var pathPoint = new Point()
                            pathPoint.appendSegItem(Point.createPointStr())
                            pathPoint.appendSegItem(Point.lineToArc(0,0,radius,angle_addup+start_angle))
                            pathPoint.appendSegItem(Point.describeArc(0,0,radius,start_angle,angle_addup+start_angle))
                            pathPoint.appendSegItem('L 0 0 ')
                            // pathPoint.closeSegItem()
                            var pieCircle = piechart.path(pathPoint.getSegPathListStr())
                            if (strokecolors[i].length >=1 && strokecolors[i] != ("none")) {
                                pieCircle.attr({stroke: strokecolors[i],"stroke-width":strokewidth});
                            }
                            // console.log(radius,start_angle,start_angle+angle_addup,start_on_arc,stop_on_arc,piechartdata[i],colors[i],pathPoint.getSegPathListStr())
                            pieCircle.attr("fill", colors[i]);

                            if (activePCData.isbShowData()) {
                                var xy = this.getCoordinatesOnCircle(Point.createPoint(),radius * 2 / 3,(angle_addup + start_angle) / 2)
                                var text = pieGroup.text(piechartdata[i] + "").move(xy.x,xy.y + activePCData.getShowDataFontSize() * 1 / 3)
                                text.font({size:activePCData.getShowDataFontSize()})
                                if (activePCData.isbShowDataFontItalic()) {
                                    text.font({family:FontStyle.ITALIC});
                                }
                                if (!StringOps.equalsIgnoreCase(activePCData.getShowDataFontColor(),'black')) {
                                    text.attr("fill", activePCData.getShowDataFontColor());
                                }
                                text.attr("text-anchor", "middle"); // only allow to align in the middle                            
                            }
                        }
                        start_angle += angle_addup;
                    }
                    piechart.move(-halfRadi,-halfRadi)
                    if (pos.angle != 0) {
                        var xy = this.getCoordinatesOnCircle(this.rootXY, this.distanceTo(this.rootXY,pos.pt),
                                        pos.angle);
                        pieGroup.translate(xy.x,xy.y)
                    }else{
                        pieGroup.translate(pos.pt.x,pos.pt.y)
                    }
                    pie_counter++
                }
            }
        }else{
            this.clearAllChildNodesFromSVGGElement(this.layer_piechart_showdata);
			this.clearAllChildNodesFromSVGGElement(this.layer_tree_piecharts);
        }
    }

    getCoordinatesOnCircle(xy, radius, angle) {
		// angle cannot be higher than 360
		angle += 360.0;
		angle = angle %  360.0;

		// narrow angle within range of 0~90
		var nangle = angle %  90.0;
		// translate angle to radians
		var radians =  ((nangle * 2.0 * Math.PI) / 360.0);
		// assume that the center is at (0,0)
		var sin =  Math.sin(radians);
		var cos =  Math.cos(radians);
		var x = cos * radius;
		var y = sin * radius;
		// adjust x,y according to original angle
        var newx = 0, newy = 0;
		if (angle >= 0 && angle < 90) {
			newx = -x;
			newy = y;
		} else if (angle >= 90 && angle < 180) {
			newx = y;
			newy = x;
		} else if (angle >= 180 && angle < 270) {
			newx = x;
			newy = -y;
		} else if (angle >= 270 && angle < 360) {
			newx = -y;
			newy = -x;
		}

		// make a SVGpoint based on newx, newy; and move it to xy
		var newxy = {'x':0,'y':0};//[{'x':newx,'y':newy}];
        newxy.x = newx+xy.x
        newxy.y = newy+xy.y
		return newxy;
    }

    distanceTo(start,destination){
        return Math.sqrt(this.square(destination.x-start.x)+this.square(destination.y-start.y))
    }

    square(pt){
        return pt*pt
    }

    clearAllChildNodesFromSVGGElement(svgg) {
		svgg.clear()
    }

    removePCDataByID( pcdataID) {
        if (this.pcDatasets.hasOwnProperty(pcdataID)) {
            if (pcdataID === (this.activePCDataID)) {
                this.activePCDataID = "";
                this.makePies();
            }
        }
    }

    getActivePieID() {
        return this.activePCDataID;
    }

    getOriginalUserInputByDatasetID( pcdataid) {
        return this.pcDatasets.hasOwnProperty(pcdataid) ? this.pcDatasets[pcdataid].getOriginal_datastring() : "";
    }

}

export default Pies