import DataContainerParserBase from './DataContainerParserBase'
import StringOps from '../utils/StringOps'

class InternalCollapseDataContainerParser extends DataContainerParserBase{
    constructor(newdatasetid, newdataString, phylotree){
        super()

		this.hmPhylonode2style = {}
		this.hmPhylonode2styleAr = []
        this.collapsePct = 0.4

        this.setDatasetID(newdatasetid);
		this.setActive(true);
		this.setOriginal_datastring(newdataString);
		
		this.internalCollapseDataParser(newdataString, phylotree);
    }

    internalCollapseDataParser( newdataString, phylotree) {
		
		// split into lines by line breaks
		var lines = StringOps.JsArrayStringToArrayList( StringOps.splitStringByNewLine( newdataString ) );
		for (var idx = 0; idx < lines.length; idx++) {
			var current_line_index = idx + 1;
			var line = lines[idx].trim();
			if (line.length > 0 && !line.startsWith("#")) {
				var lineparts =
                StringOps.JsArrayStringToArrayList( StringOps.splitStringByTab( line ) );
				// console.log(line,lineparts)
				var part1 = lineparts[0].trim();

				// modifiers ---------------------------------------
				if (part1.startsWith("!")) {
					this.parseModifier(lineparts, current_line_index);
				}
				// values ------------------------------------------
				else {
					
					/**
					 * C,D	name=my collapsed node,color=yellow:darker,linewidth=2,linestyle=dashed 
					 */
					var kvs = null;
					if( lineparts.length >= 2 ){
						kvs = this.splitKeyValuePairs(lineparts[1].trim()); // split key value pairs --
					} else {
						kvs = {};
					}
					// console.log(kvs)
					/**
					 * here part 1 should be either 
					 *  + an internal node ID, or 
					 *  + two leaf node IDs, separated by a ',';
					 */
                    var ids = StringOps.JsArrayStringToArrayList( StringOps.splitStringBySeperator(part1, ","));
					var lca = phylotree.getLCA(ids);
					
                    if( lca != null ){
                    	if( !this.hasThenodePresent( lca ) && !lca.isleaf() ) {
							// this.hmPhylonode2style[lca] =  kvs; // put kv pairs --
							this.hmPhylonode2styleAr.push({'lca':lca,'kvs':kvs})
						}
                    } else {
                    	this.setError("error parsing data at line: " + current_line_index + ", your input'" + line + "' does not correspond to any valid tree Node");
                        this.setValid(false);
                    }
				}
			}
		} // end of foreach line ...
		/**
		 * here i check if one node is a parent of the other, if true, delete the daughter node 
		 */
		if( Object.keys(this.hmPhylonode2styleAr).length >= 2 ){
			var nodes2delete = [];
			// console.log('am here')
			// find nodes to delete ... 
			for( var kv1 in this.hmPhylonode2styleAr ){
				var nodei = this.hmPhylonode2styleAr[kv1].lca;
				for( var kv2 in this.hmPhylonode2styleAr){
					var nodej = this.hmPhylonode2styleAr[kv2].lca;
					if( kv1 != kv2 ){
						if( !nodes2delete.includes( nodei ) && nodei.isAncestor(nodej)  ){ // if nodej is ancester of nodei, delete nodei
							nodes2delete.push(nodei);
						} else if ( !nodes2delete.includes( nodej ) && nodej.isAncestor( nodei ) ){ // if i is ancester of j , delete j 
							nodes2delete.push(nodej);
						}
					}
				}
			}
			
			// delete
			var newStylesAr = []
			for( var ind in this.hmPhylonode2styleAr){
				var node = this.hmPhylonode2styleAr[ind].lca
				if(nodes2delete.includes(node)){
				}else{
					newStylesAr.push(this.hmPhylonode2styleAr[ind])
				}
			}
			if(newStylesAr.length >=1){
				this.hmPhylonode2styleAr = newStylesAr
			}
		}
		
		/**
		 * check briefly if dataset id valid 
		 */
		if( Object.keys(this.hmPhylonode2styleAr).length == 0 ){
			this.setError("no valid phylo-nodes parsed; input is invalid. please edit your input and try again");
			this.setValid(false);
		} 
		// console.log('total styles',Object.keys(this.hmPhylonode2style).length,this.hmPhylonode2style)
    }// parser ends here 

	hasThenodePresent(inNode){
		for( var ind in this.hmPhylonode2styleAr){
			var node = this.hmPhylonode2styleAr[ind].lca
			if(inNode === node){
				return true
			}
		}
		return false
	}
    getInternalNodesToCollapseAt(){
		return this.hmPhylonode2style;
	}

	getInternalNodesToCollapseAtAr(){
		return this.hmPhylonode2styleAr
	}

	getCollapsePct() {
		return this.collapsePct ;
	}
}

export default InternalCollapseDataContainerParser