import DataContainerParserBase from './DataContainerParserBase'
import StringOps from '../utils/StringOps'
import BranchLabelItemDefAttribute from './BranchLabelItemDefAttribute'


class ImageLabelContainerParser extends DataContainerParserBase{
    constructor(datasetid, datasetContent, phylotree) {
        super()

        if(datasetid.length >=1 && datasetContent.length >=1){
            this.setDatasetID(datasetid);
            this.setOriginal_datastring(datasetContent);
        }

        this.row2data = {}
        this.id2originalID = {}

        var lines = StringOps.JsArrayStringToArrayList( StringOps.splitStringByNewLine( datasetContent ) );
        for (var idx = 0; idx < lines.length; idx++) {
            var line = lines[idx].trim();
            if (line.trim().length > 0 && ! line.startsWith("#")) { // March 22, 2011; ignore annotation lines
                var lineparts = StringOps.JsArrayStringToArrayList( StringOps.splitStringByTab( line ) );
                var part1 = lineparts[0].trim();

                if ( part1.startsWith("!")) {
                    this.parseModifier(lineparts, idx + 1);
                }else if (lineparts.length >= 2) {
                    let ids = StringOps.JsArrayStringToArrayList( StringOps.splitStringBySeperator(part1, ","));
                    let item_def = this.getImageProp(lineparts[1].trim())
                    var internal_id = '';
                    var lca = phylotree.getLCA(ids);
                    if (lca != null) {
                        internal_id = lca.getInternalID();
                    }

                    if (internal_id != null) {                    	
                        this.id2originalID[internal_id] =  ids;
                        this.row2data[internal_id] =  item_def;
                    }
                }
            }
        }

        if(Object.keys(this.row2data).length<=0){
            this.valid = false
            this.setError("error: at least one valid colorset data should be given");
        }
    }

    getImageProp(part2){
        let item_def = new BranchLabelItemDefAttribute()
        var kvs = this.splitKeyValuePairs(part2)

        item_def.setImage(kvs.hasOwnProperty("src")? kvs["src"] : '')
        if(kvs.hasOwnProperty('width')){
            item_def.setImageWidth(parseFloat(kvs['width']))
        }
        if(kvs.hasOwnProperty('height')){
            item_def.setImageHeight(parseFloat(kvs['height']))
        }
        return item_def
    }

    getRow2data(){
        return this.row2data
    }

    getId2originalID(){
        return this.id2originalID
    }
}

export default ImageLabelContainerParser