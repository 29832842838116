<template>
  <div id="app" :style="removeMarginTop">
    <navbar v-show="['EmbedToolWindow/:embedId'].indexOf($route.name) != 0 && showNaveBar"></navbar>
    <router-view style="margin-top:10px;height:100%"></router-view>
    <div v-show="['Login'].indexOf($route.name) == 0 || ['Home'].indexOf($route.name) == 0 || ['Help'].indexOf($route.name) == 0 || ['About'].indexOf($route.name) == 0" >
      <b-row style="padding:10px" align-self="center">
            <b-col align-self="center">
                <p>
                © 2021 Evolgenius.info 
                <a href="http://www.chenlab.medgenius.info/index.php" target="_blank">HUST Chenlab</a>                
                Citation:Subramanian et al. Evolview v3 <i>Nucleic Acids Res</i> <a href="https://doi.org/10.1093/nar/gkz357" target="_blank">https://doi.org/10.1093/nar/gkz357</a>
                </p>
            </b-col>
        </b-row>
    </div>
    <b-overlay :show="hideCanvas" rounded="sm" id="normalCan" no-wrap>
            <template #overlay>
                <div class="text-center">
                <b-spinner label="Spinning"></b-spinner>
                <p id="cancel-label">Please wait,loading canvas...</p>
                </div>
            </template>
    </b-overlay>      
    <b-overlay :show="hideShareCanvas && ['SharedToolWindow/:shareId'].indexOf($route.name) != 0" rounded="sm" id="sharedCan" no-wrap>
            <template #overlay>
                <div class="text-center">
                <b-spinner label="Spinning"></b-spinner>
                <p id="cancel-label">Please wait,loading canvas...</p>
                </div>
            </template>
    </b-overlay>
    <b-overlay :show="hideEmbedCanvas && ['EmbedToolWindow/:embedId'].indexOf($route.name) != 0" rounded="sm"  id="embedCan" no-wrap>
            <template #overlay>
                <div class="text-center">
                <b-spinner label="Spinning"></b-spinner>
                <p id="cancel-label">Please wait,loading canvas...</p>
                </div>
            </template>
    </b-overlay>
  </div>
</template>

<script>
import Navbar from './components/NavBar'
import EventBus from './components/EventBus'

export default {
  name: 'App',
  components: {
    'Navbar': Navbar,
  },
  data() {
    return {
      marginTop:'70px',
      hideCanvas:false,
      hideShareCanvas:false,
      hideEmbedCanvas:false,
      showNaveBar:true,
    }
  },
  computed: {
    removeMarginTop(){
          return {
              'background-color':'#FAF9F9',
              'margin-top':`${this.marginTop}`
          }
      },
  },
  created() {
    let that = this
    EventBus.$on('hide-navbar',info=>{
            if(info === 1){
                that.marginTop = "0px"
            }else{
                that.marginTop = "70px"
            }
    })
    EventBus.$on('hide-canvas',info=>{
      // console.log(info)
        if(info.type == 'normal'){
          this.hideCanvas = info.state
          document.documentElement.style.overflow = 'hidden'
          if(!info.state){
              EventBus.$emit('closeLoadWin', true)
              document.documentElement.style.overflow = 'auto'
          }
          if(['Home'].indexOf(this.$route.name) == 0 || ['Login'].indexOf(this.$route.name) == 0 || ['About'].indexOf(this.$route.name) == 0 || ['Help'].indexOf(this.$route.name) == 0  || ['Projects'].indexOf(this.$route.name) == 0){
            this.hideCanvas = false
          }
        }
        if(info.type == 'share'){
          this.hideShareCanvas = info.state
          document.documentElement.style.overflow = 'hidden'
          if(!info.state){
              EventBus.$emit('closeLoadWin', true)
              document.documentElement.style.overflow = 'auto'
          }
          if(['Home'].indexOf(this.$route.name) == 0 || ['Login'].indexOf(this.$route.name) == 0 || ['About'].indexOf(this.$route.name) == 0 || ['Help'].indexOf(this.$route.name) == 0  || ['Projects'].indexOf(this.$route.name) == 0){
            this.hideShareCanvas = false
          }
        }
        if(info.type == 'embed'){
          this.hideEmbedCanvas = info.state
          document.documentElement.style.overflow = 'hidden'
          if(!info.state){
              EventBus.$emit('closeLoadWin', true)
              document.documentElement.style.overflow = 'auto'
          }
          if(['Home'].indexOf(this.$route.name) == 0 || ['Login'].indexOf(this.$route.name) == 0 || ['About'].indexOf(this.$route.name) == 0 || ['Help'].indexOf(this.$route.name) == 0  || ['Projects'].indexOf(this.$route.name) == 0){
            this.hideEmbedCanvas = false
          }
        }
    })
    EventBus.$on('fullscreen',info=>{
      that.goFullScreen()
      that.showNaveBar = false
    })
  },
  mounted() {
    // console.log(this.$route,['Home'].indexOf(this.$route.name) == 0)
    if(['Home'].indexOf(this.$route.name) == 0 || ['Login'].indexOf(this.$route.name) == 0 || ['Projects'].indexOf(this.$route.name) == 0 || ['About'].indexOf(this.$route.name) == 0 || ['Help'].indexOf(this.$route.name) == 0){
      this.hideCanvas = false
    }
    let that = this
    document.addEventListener('fullscreenchange', function() {
      var full_screen_element = document.fullscreenElement;

      if(full_screen_element !== null){
        console.log('Page has entered fullscreen mode');
      }else{
        console.log('Page has exited fullscreen mode');
        that.showNaveBar = true
      }
    });
  },
  methods: {
    stopLoading(){
            this.hideCanvas = false
    },
    goFullScreen(){
      var element = document.querySelector("#app");
      element.requestFullscreen()
      .then(function() {
        // element has entered fullscreen mode successfully
      })
      .catch(function(error) {
        // element could not enter fullscreen mode
        // error message
        console.log(error.message);
      });
    }
  },
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
html,body{height:100%;}

@media all {
    body {
        min-width: 960px;
    }
}

</style>

