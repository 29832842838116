
class ColumnPlotsColumnDataContainer{
    constructor(){
        this.columnLabelStyle = null
        this.columnData = []
    }

    setColumNameData( colNameAttrCon) {
		this.columnLabelStyle = colNameAttrCon;
	}

	getColumnNameData() {
		return this.columnLabelStyle;
	}

	addFragment( dataAttrCon) {
		this.columnData.push(dataAttrCon);
	}
	
	getFragments() {
		return this.columnData;
	}
}

export default ColumnPlotsColumnDataContainer