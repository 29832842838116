<template>
    <vue-draggable-resizable :w="310" :min-height="300" style="position: fixed;right:10px;top:190px;" v-show="showPan" :drag-cancel="'.drag-cancel'">
        <md-card md-with-hover style="height:100%;width:100%;overflow:scroll">
            <!-- <md-ripple> -->
                <md-card-header>
                    <div class="md-title">
                        <h5 class="d-flex justify-content-between align-items-center">
                        Controls
                        <font-awesome-icon icon="window-close" @click="showPan = !showPan"></font-awesome-icon>
                        </h5>
                    </div>
                <div class="md-subhead" align="left">{{winTitle}}</div>
                </md-card-header>
                <md-card-content style="padding: 0px">
                    <ul class="no-bullets" style="width:100%">
                        <li v-for="(btn, idx) in name2catpanel" 
                                    :key="idx" style="text-align: left;width:100%;padding-left:15px;padding-right:15px;" v-show="btn.show" >
                            <!-- <span class="md-list-item-text">
                                <switches theme="bootstrap" :color="btn.colorState" v-model="btn.panStatus" v-on:click.native="changeCatPanelStatus(btn,idx)" style="padding-top:5px;padding-right:5px" v-b-toggle="'dp-'+idx"></switches>
                            {{btn.panType}}</span> -->
                            <table collspacing="1" border="0" style="width: 100%;">
                                <colgroup><col><col width="13px"></colgroup>
                                <tbody>
                                    <tr>
                                        <td>
                                            <a align="left">
                                                <img :src="!btn.panStatus ? 'image/switch-off.png' : 'image/switch-on.png' " alt="" width="35" height="35"  @click="changeCatPanelStatus(btn,idx)">                                                                                
                                            </a>
                                            <b-button variant="link" align="left" v-on:click.prevent="hideExpandCatPan(idx)">&nbsp;{{btn.panType}}</b-button>
                                        </td>
                                        <td>
                                            <b-button variant="link" v-on:click.prevent="hideExpandCatPan(idx)">
                                            <span v-show="!btn.expand" ><font-awesome-icon icon="chevron-right"></font-awesome-icon></span>
                                            <span v-show="btn.expand" ><font-awesome-icon icon="chevron-down"></font-awesome-icon></span>
                                            </b-button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <b-collapse :id="btn.panType" v-model="btn.expand" class="mt-2"  @shown="catPanShown(idx)" @hide="catPanHided(idx)">
                            <md-list style="width:100%">
                                <md-list-item v-for="(subBtn, iddx) in btn.getChildPans()" 
                                                :key="iddx" style="padding-left:1px;margin-left:1px" >
                                    <table collspacing="1" border="0" style="width: 100%;">
                                    <colgroup><col><col width="13px"><col width="13px"><col width="13px"><col width="13px"></colgroup>
                                    <tbody>
                                        <tr>
                                            <td>
                                            <span :disabled="subBtn.formatFailed" v-if="getDataTypeName(subBtn.typeOrformat) == 'Charts'"  @click="switchDatSetView(subBtn,'2',btn)" >
                                                <p style="word-break: break-word;white-space: pre-wrap;">
                                                    <img :src="getSelIcon('image/checkbox',subBtn)" alt="" width="18" height="18"> 
                                                    <span v-if="subBtn.formatFailed" ><s>{{subBtn.name}}</s></span>
                                                    <span v-else >{{subBtn.name}}</span>
                                                </p>                                    
                                            </span>                                
                                            <span :disabled="subBtn.formatFailed" v-if="getDataTypeName(subBtn.typeOrformat) != 'Charts'"  @click="switchDatSetView(subBtn,'1',btn)">
                                                <p style="word-break: break-word;white-space: pre-wrap;">
                                                    <img :src="getSelIcon('image/radio-button',subBtn)" alt="" width="18" height="18"> 
                                                    <span v-if="subBtn.formatFailed" ><s>{{subBtn.name}}</s></span>
                                                    <span v-else >{{subBtn.name}}</span>
                                                </p>
                                            </span>
                                            </td>
                                            <td><font-awesome-icon icon="pencil-alt" @click="editDatSet(subBtn)" title="Edit dataset" style="margin-right:0px;padding-top:0px"></font-awesome-icon></td>
                                            <td><font-awesome-icon icon="arrow-up" @click="moveUp(iddx,btn.getChildPans(),btn)" title="Move up dataset order" style="color:blue;margin-right:0px"></font-awesome-icon></td>
                                            <td><font-awesome-icon icon="arrow-down" @click="moveDown(iddx,btn.getChildPans(),btn)" title="Move down dataset order" style="color:blue;margin-right:0px"></font-awesome-icon></td>
                                            <td><font-awesome-icon icon="times" @click="delDatSet(iddx,btn.getChildPans(),btn)" title="Delete dataset" style="color:red;margin-right:0px"></font-awesome-icon></td>
                                            <td v-show="subBtn.formatFailed"><font-awesome-icon icon="info-circle" @click="showErrorDetails(subBtn.formatFailedMessage)" title="Show error info" style="color:red;margin-right:0px"></font-awesome-icon></td>
                                        </tr>
                                    </tbody>
                                    </table>
                                </md-list-item>
                            </md-list>
                            </b-collapse>
                        </li>
                    </ul>
                </md-card-content>
        </md-card>
    </vue-draggable-resizable>        
</template>

<script>
import axios from 'axios'
import EventBus from './EventBus'
import Commons from '../utils/CommonEvol'
import MC from '../treeObjects/MC'
import CatPanel from '../datacontainers/CatPanel'
import UserDataDatasetTree from '../treeObjects/UserDataDataset4Tree'

const swap = (arr, index1, index2) => arr.map((val, idx) => {
  if (idx === index1) return arr[index2];
  if (idx === index2) return arr[index1];
  return val;
});

export default {
    props:['actTree','actProject','promode','trSVG'],
    components:{
        // Switches
    },
    data() {
        return {
            userId: '',
            panType:'',            
            server_add:this.$hostname,
            activeProj:null,            
            showCont:false,
            showPan:true,
            controlPanList:[],
            name2catpanel:{},
            width: 350,
            height: 200,
            treeShareSVG:null,
            treeEmbedSVG:null,
            winTitle:'',
            promoMode:'LIVE',
            treeSVG:'',
            activeTree:this.actTree,
            activeProject:this.actProject,
            cancelUsed : true,
        }
    },
    watch:{
        showPan(val){
            EventBus.$emit('control-closed', val)
        },
        actTree(val){
            this.activeTree = val
        },
        actProject(val){
            this.activeProject = val
        },
        trSVG(val){
            this.treeSVG = val
            this.onCanvasLoaded()
        },        
        promode(val){
            this.promoMode = val
        },        
    },
    created() {
        EventBus.$on('control-open', showPan => {
            this.showPan = showPan
            this.showCont = this.showPan
        })        
    },    
    mounted() {
        if(localStorage.getItem("usertoken") != null){
            const token = localStorage.getItem("usertoken") 
            const decoded = JSON.parse(token)//jwtDecode
            this.userId = decoded.userId
        }
    },
    methods: {        
        onResize: function (x, y, width, height) {
            // this.x = x
            // this.y = y
            this.width = width
            this.height = height
            // console.log(height)
        },
        onCanvasLoaded(){
            if(this.activeTree != null && this.activeTree != undefined){
                this.winTitle = this.activeTree.treeName
                if(this.activeTree.catpanelChecked != undefined){
                    this.addCatPanels(this.activeTree)
                    this.addDataToCatPans()
                    this.showPan = true
                }
            }
        },
        changeCatPanelStatus(btn,idx){            
            this.name2catpanel[idx].setPanStatus(!this.name2catpanel[idx].panStatus)
            if(!this.name2catpanel[idx].panStatus){
                this.name2catpanel[idx].setExStatus(false)
            }else{
                this.name2catpanel[idx].setExStatus(true)
            }
            if(this.name2catpanel[idx].panStatus){
                btn.colorState = 'info'
            }else{
                btn.colorState = 'default'
            }
            this.$forceUpdate();
            this.activateCatPanel(this.name2catpanel[idx])
            // console.log(this.name2catpanel[idx].panStatus)
            // this.$set(btn.panStatus,'panStatus',btn.panStatus)
        },        
        getSelIcon(imSrc,btn){
            if(!btn.formatFailed){
                if(btn.active){
                    imSrc = imSrc+'-on.png'
                }else{
                    imSrc = imSrc+'-off.png'
                }
            }else{
                imSrc = imSrc+'-inactive.png'
            }
            return imSrc
        },
        getSelCatIcon(imSrc,btn){
            if(btn.panStatus){
                imSrc = imSrc+'-sel.png'
            }else{
                imSrc = imSrc+'-unsel.png'
            }
            return imSrc
        },
        getDataTypeName(datatype){
            return Commons.getDataTypeName(datatype)
        },
        switchDatSetView(btn,typ,parentCat){
            // btn.active =  !btn.active
            // console.log('what am i ',typ,btn,parentCat.getChildPans())
            if(!btn.formatFailed && typ === '1'){ //radio buttons
                // btn.active =  !btn.active
                this.resetOtherDatsets(btn,parentCat.childPans)
                this.setChildCatStatus(parentCat,btn)
            }else if(!btn.formatFailed && typ === '2'){ //checkbox
                btn.active =  !btn.active
                this.activateDataset(btn)
                // btn.activateDataset(btn.typeOrformat,btn.name,btn.active,btn.dbSerial)
            }
        },
        resetOtherDatsets(btn,btnArry){
            if(Commons.isThisMyTree(this.activeTree.userId,this.userId)){
                let deactiveAr = btnArry.map(p=>p.dbSerial)                
                axios.post(this.server_add+'query/deactivateDatStatus',
                {
                    treeId: this.activeTree.dbserial,
                    idArray: deactiveAr,
                    }).then(res => {
                    if(res.status == 200){
                        // console.log(res)
                        if(res.data.hasOwnProperty('error')){
                            this.log_check_status = res.data.error
                        }
                    }else{
                        Commons.showErrorMessage('server connection error '+res,'Error')
                    }  
                }).catch(err => {
                        Commons.showErrorMessage('server connection error '+err,'Error')
                })
            }
        },
        setChildCatStatus(parentCat,childCat){
            var array = parentCat.childPans
            for(let ind in array){
                var ar = array[ind]
                if(ar instanceof (UserDataDatasetTree)){
                    if(childCat.dbSerial != ar.dbSerial){
                        ar['active'] = false
                    }else{
                        ar['active'] = true
                    }
                }
            }
            this.activateDataset(childCat)
            // childCat.activateDataset(childCat.typeOrformat,childCat.name,childCat.active,childCat.dbSerial)
        },
        activateCatPanel(catPanel){
            // console.log('cat pan state ',catPanel.panStatus,catPanel)
            catPanel.afterPanUpdate(catPanel.panStatus,Commons.isThisMyTree(this.activeTree.userId,this.userId))
        },
        activateDataset(dataSet){
            // console.log('i am here ',dataSet)
            if(dataSet.typeOrformat === MC.PIES){
                this.treeSVG.getPies().setActivePCDataByID(dataSet.typeOrformat+dataSet.name)
				this.treeSVG.updateAllLegends()
            }else if(dataSet.typeOrformat === MC.BOOTSTRAPSTYLES){
                this.treeSVG.setActiveBootstrapStyleSetByID(dataSet.typeOrformat+dataSet.name)
            }else if(dataSet.typeOrformat === MC.LEAFLABELDECO){
                this.treeSVG.setActiveLeafDecoByID(dataSet.typeOrformat+dataSet.name)
            }else if(dataSet.typeOrformat === MC.LEAFBKCOLORS){
                this.treeSVG.setActiveLeafBKcolorByDatasetID(dataSet.typeOrformat+dataSet.name)
            }else if(dataSet.typeOrformat === MC.LEAFLABELCOLORS){
                this.treeSVG.setActiveLeafColorSetByID(dataSet.typeOrformat+dataSet.name)
            }else if(dataSet.typeOrformat === MC.BRANCHECOLORS){
                this.treeSVG.setActiveBranchColorByDatasetID(dataSet.typeOrformat+dataSet.name)
            }else if(dataSet.typeOrformat === MC.TIMELINE){
                this.treeSVG.setActiveTimeLineByID(dataSet.typeOrformat+dataSet.name)
            }else if(this.getDataTypeName(dataSet.typeOrformat) === MC.CHARTS){
                this.treeSVG.setActiveChartByID(MC.CHARTS+dataSet.name,dataSet.active)
            }else if(this.getDataTypeName(dataSet.typeOrformat) === MC.INTERNALCOLLAPSES){
                this.treeSVG.setActiveInternalCollapseDatasetByID(dataSet.typeOrformat+dataSet.name)
            }else if(this.getDataTypeName(dataSet.typeOrformat === MC.BRANCHLABELS)){
                this.treeSVG.setActiveBranchLabelByID(dataSet.typeOrformat+dataSet.name)
            }else if(this.getDataTypeName(dataSet.typeOrformat === MC.LEAFANNOTATE)){
                this.treeSVG.setActiveLeafAnnotateByID(dataSet.typeOrformat+dataSet.name)
            }else if(this.getDataTypeName(dataSet.typeOrformat === MC.LEAFIMAGE)){
                this.treeSVG.setActiveImageLabelByID(dataSet.typeOrformat+dataSet.name)
            }else if(dataSet.typeOrformat === MC.BRANCHMARKER){
                this.treeSVG.setActiveBranchMarkByID(dataSet.typeOrformat+dataSet.name)
            }else if(dataSet.typeOrformat === MC.GENETRANSFER){
                this.treeSVG.setActiveGeneTransferByID(dataSet.typeOrformat+dataSet.name)
            }
            else{
                console.log('i am of type ',dataSet.typeOrformat)
            }            
            if(Commons.isThisMyTree(this.activeTree.userId,this.userId)){
                var status = 0
                if(dataSet.active){
                    status = 1
                }
                this.updateDatasetStatus( status,dataSet.dbSerial)
            }
        },
        editDatSet(btn){
            this.showModal(4,btn.content,btn)
        },
        showModal(typ,contentStr,dataSet) {
            this.modelType = typ
            this.modContentStr = contentStr
            if(typ === 4){ //dataset edit
                this.treeUpload = false
                this.showFileTyp = false
                this.showFileUpload = true
                this.rawTxtAr = contentStr
                // console.log(dataSet)
                this.datSetSerial = dataSet.dbSerial
                this.uploadDatasetType = dataSet.typeOrformat
                EventBus.$emit('edit-datset', {'rawtxt':this.rawTxtAr,'datsetserial':this.datSetSerial,'uploadDatasetType':this.uploadDatasetType,'name':dataSet.name})
            }
            this.isModalVisible = true;
            this.cancelUsed = false

            let propArray = [this.modelType,this.modContentStr,this.treeUpload,this.showFileTyp,this.showFileUpload,this.isModalVisible,this.cancelUsed]
            EventBus.$emit('modal-props', propArray)            

            this.$modal.show('modalScoped');            
        },
        moveUp(currentInd,array,btn){
            if(currentInd-1 >=0){
                let tem = array[currentInd-1]
                let temm = array[currentInd]
                let ord1 = tem.dbOrder
                let ord2 = temm.dbOrder
                array[currentInd-1].dbOrder  = ord2
                array[currentInd].dbOrder = ord1
                array = swap(array,currentInd-1,currentInd)
                var oldId = tem.dbSerial
                var newId = temm.dbSerial
                // console.log(tem,temm)
                // console.log(oldId,newId,temm,array,currentInd,currentInd-1)
                this.$set(btn.childPans,'childPans',array)
                this.$forceUpdate();
                this.treeSVG.updateChartOrder(temm.name,ord1,tem.name,ord2);
                if(Commons.isThisMyTree(this.activeTree.userId,this.userId)){                    
                    this.updateDatasetOrder(oldId,currentInd,newId,currentInd-1)
                    // console.log(currentInd,currentInd-1,array.length,array)
                }
            }else{
                console.log(currentInd)
            }
        },
        moveDown(currentInd,array,btn){
            if(currentInd+1<array.length){
                let tem = array[currentInd+1]
                let temm = array[currentInd]
                let ord1 = tem.dbOrder
                let ord2 = temm.dbOrder
                array[currentInd+1].dbOrder  = ord2
                array[currentInd].dbOrder = ord1
                array = swap(array,currentInd,currentInd+1)
                var oldId = tem.dbSerial
                var newId = temm.dbSerial
                // console.log(oldId,newId,array,currentInd,currentInd+1)
                this.$set(btn.childPans,'childPans',array)
                this.$forceUpdate();
                this.treeSVG.updateChartOrder(temm.name,ord1,tem.name,ord2);
                if(Commons.isThisMyTree(this.activeTree.userId,this.userId)){                    
                    this.updateDatasetOrder(oldId,currentInd,newId,currentInd+1)
                }
                //console.log(currentInd,currentInd+1,array.length)
            }else{
                console.log(currentInd)
            }
        },
        hideExpandCatPan(idx){
            this.$root.$emit('bv::toggle::collapse', this.name2catpanel[idx].panType)
        },
        catPanShown(idx){
            this.name2catpanel[idx].setExStatus(true)
            this.$forceUpdate();
        },
        catPanHided(idx){
            this.name2catpanel[idx].setExStatus(false)
            this.$forceUpdate();
        },
        delDatSet(currentInd,array,btn){
            var that = this
            var dataId = array[currentInd].dbSerial
            this.$modal.show('dialog', {
            title: 'Do you want to delete?',
            text: 'Please confirm that you want to delete dataset.',
            buttons: [
                {
                title: 'Cancel',
                handler: () => {
                    this.$modal.hide('dialog')
                }
                },
                {
                title: 'Delete',
                handler: () => {
                    // alert('Like action')
                    // console.log(btn,array[currentInd],dataId)                    
                    if(that.userId === btn.activeTree.userId){
                        that.delDataset(dataId,MC.CHARTS+array[currentInd].name) //update delete on the server
                        array.splice(currentInd,1)
                        that.$set(btn.activeTree.datasets,'datasets',array)
                        that.$forceUpdate();
                    }else{
                        Commons.showErrorMessage('You dont have rights to delete the data','Error')
                    }
                    that.$modal.hide('dialog')
                }
                }
            ]
            })            
        },
        addCatPanels(curTree){
            var catPanTypes = Commons.getCatPanTypes()
            this.name2catpanel = {}
            this.controlPanList = []        
            for(var ind in catPanTypes){
                var pan = catPanTypes[ind]
                let cat = new CatPanel(this.treeSVG,false,this.server_add,this.userId)
                cat.setProp(pan,this.promoMode,this.activeTree)
                // console.log(curTree.catpanelChecked.hasOwnProperty(pan))
                cat.setPanStatus(curTree.catpanelChecked.hasOwnProperty(pan) ? curTree.catpanelChecked[pan] : false)
                cat.setExStatus(cat.getPanStatus())
                if(!this.name2catpanel.hasOwnProperty(pan)){
                    this.name2catpanel[pan] = cat
                }
            }
            // console.log(this.name2catpanel)
        },
        addDataToCatPans(){
            var totCats = 0
            for(var cat in this.activeTree.datasets){
                var userDatDataset4Tree = this.activeTree.datasets[cat]
                var catpanelname = this.getDataTypeName(userDatDataset4Tree.getTypeOrFormat())                
                if(this.name2catpanel.hasOwnProperty(catpanelname)){
                    var cp = this.name2catpanel[catpanelname];
                    cp.setShow(true)
                    totCats++
                    if(!cp.hasDataSetAdded(userDatDataset4Tree)){
                        cp.continueAddDataSet2Tree(userDatDataset4Tree)
                        // cp.afterPanUpdate(cp.getPanStatus(),false)
                        // break;
                    }
                }                
            }
            this.$emit('name2CatPansUpdate', this.name2catpanel)
            if(totCats >=1){
                this.showPan = false
            }
            this.checkCtrlPanStatus()
        },
        checkCtrlPanStatus(){
            for(var ind in this.name2catpanel){
                var catPan = this.name2catpanel[ind]
                if(!catPan.getPanStatus()){
                    catPan.afterPanUpdate(catPan.getPanStatus(),false)
                }
            }
        },
        updateDatasetStatus(newStatus,datId){
        axios.post(this.server_add+'query/updateDatStatus',
          {
              treeId: this.activeTree.dbserial,
              status: newStatus,
              datId: datId,
            }).then(res => {
              if(res.status == 200){
                // console.log(res)
                if(res.data.hasOwnProperty('error')){
                  this.log_check_status = res.data.error
                }
              }else{
                  Commons.showErrorMessage('server connection error '+res,'Error')
              }  
          }).catch(err => {
                  Commons.showErrorMessage('server connection error '+err,'Error')
          })
        },
        updateDatasetOrder(oldId,oldOrder,newId,newOrder){
        axios.post(this.server_add+'query/updateDatOrder',
          {
              treeId: this.activeTree.dbserial,
              oldDatId: oldId,
              oldOrder: oldOrder,
              newDatId: newId,
              newOrder: newOrder
            }).then(res => {
              if(res.status == 200){
                //console.log(res)
                if(res.data.hasOwnProperty('error')){
                  this.log_check_status = res.data.error
                }
              }else{
                  Commons.showErrorMessage('server connection error '+res,'Error')
              }  
          }).catch(err => {
                  Commons.showErrorMessage('server connection error '+err,'Error')
          })
        },
        delDataset(datSetId,chartsName){
        axios.post(this.server_add+'query/delDatset',
          {
              treeId: this.activeTree.dbserial,
              datId:datSetId,
            }).then(res => {
              if(res.status == 200){
                //console.log(res)
                if(res.data.hasOwnProperty('error')){
                  this.log_check_status = res.data.error
                }else{
                    //update treesvg
                    this.treeSVG.deleteChartByID(chartsName)
                }
              }else{
                  Commons.showErrorMessage('server connection error '+res,'Error')
              }  
          }).catch(err => {
                  Commons.showErrorMessage('server connection error '+err,'Error')
          })
        },
        showErrorDetails(errorMess){
            this.showErrorMessage(errorMess,'Error')
        },
        showErrorMessage(errorMsg,status) {
            let that = this
            this.$modal.show('dialog', {
            title: status,
            text: errorMsg,
            buttons: [
              {
                title: 'Close',
                handler: () => {
                  that.$modal.hide('dialog')
                }
              },                          
            ]
          })
        }
    },
}
</script>

<style>

.modal-enter-active, .modal-leave-active {
    /* background: rgba(255, 255, 255, 0); */
    opacity: 0;
}
.modal-enter, .modal-leave-to {
    /* background: rgba(255, 255, 255, 0); */
    opacity: 0;
}
.modal {
    background: rgba(0, 0, 0, 0);
    background-color: transparent;
    opacity: 0;
}
ul.no-bullets {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.list-group-item.nolist {
    position: relative;
    display: block;
    padding-left: 0.5rem;
    /* padding: 0.75rem 1.25rem; */
    background-color: #fff;
    /* border: 1px solid rgba(0, 0, 0, 0.125); */
}
</style>