import UserDataDataset4Tree from './UserDataDataset4Tree'

class UserDataTree{
    constructor( ){
        this.active = 0 
        this.checked = false
        this.dbserial = 0
        this.sharedDBserial = 0
        this.treeName = '',
        this.treeFormat = '',
        this.shareTreedAlias = "", 
        this.sharedMode = "", 
        this.sharedProjectName = "", 
        this.projectName = "", 
        this.projectColor = "", 
        this.treeContentString = "";
        this.userId = ''

        this.otherProps = {}
        this.canvasParams ={};
        this.treeSharing = [];
        this.datasets = [];
        this.catpanelChecked = {};
    }

    init(dbserial,  treename, active, treeformat, treecolor, userId){
        this.active = active 
        this.dbserial = dbserial
        this.treeName = treename
        this.treeFormat = treeformat
        this.userId = userId
    }

    assign(rawTree){
        var prelis = ['active','color','id','treeName','treeContent','treeFormat']
        for(var key of Object.keys(rawTree)){
            if(!prelis.includes(key)){
                this.otherProps[key] = rawTree[key]
            }
        }
    }

    isActive(){
        if(this.active==0){
            return false
        }
        return true;
    }

    getDbserial() {
		return this.dbserial;
	}

	setDbserial(dbserial) {
		this.dbserial = dbserial;
    }
    
    setUserId(userId){
        this.userId = userId
    }

	getSharedTimes() {
		return this.treeSharing.length;
    }
    
    getTreeName(){
        return this.treeName
    }

    setTreename(treename) {
		this.treeName = treename;
	}

    getProjectName() {
		return this.projectName;
	}

	setProjectName(projectName) {
		this.projectName = projectName;
    }

    getProjectColor() {
		return this.projectColor;
	}

	setProjectColor(projectColor) {
		this.projectColor = projectColor;
    }
    
    getSharedDBserial() {
		return this.sharedDBserial;
	}

	setSharedDBserial(sharedDBserial) {
		this.sharedDBserial = sharedDBserial;
    }
    
    setSharedMode(sharedMode) {
		this.sharedMode = sharedMode;
    }
    
    setTreeContent(TreeContent){
        this.treeContentString = TreeContent
    }
    
    getShareTreedAlias() {
		return this.shareTreedAlias;
	}

	setShareTreedAlias(shareTreedAlias) {
		this.shareTreedAlias = shareTreedAlias;
    }

    setTreeFormat(treeFormat){
        this.treeFormat = treeFormat
    }
    
    getdbserial(){
        return this.dbserial
    }
	
	addATreeSharingEntry(  ts ){
		this.treeSharing.push(ts);
	}
	
	addACanvasParam( key, value ){
		this.canvasParams.put(key, value);
	}

	setCanvasParams(canvasParam) {
		this.canvasParams = [];
		for(var kv in canvasParam){
			this.canvasParams[kv] =  canvasParam[kv];
		}
    }
    
    getCanvasParams(){
        return this.canvasParams
    }
	
	addDataset(  dataset ){
		this.datasets.add(dataset);
	}

	setDatasets(datasets,type) {
        this.datasets = [];
        let found_active = false
		for(var ind in datasets){
            var d = this.createDatasetObj(datasets[ind],type)
            // if(d.getActive() && !found_active){
            //     found_active = true
            // }else{
            //     d.setActive(false)
            // }
			this.datasets.push(d);
        }        
    }
    
    createDatasetObj(jsonDataset,type){
        var dataSet = new UserDataDataset4Tree()
        if(type){ //for chache based loading
            dataSet.reloadObject(jsonDataset)
        }else{ //from db load
            dataSet.fillObject(jsonDataset)
        }
        return dataSet
    }

	addAcatPanelStatus( catpanelname,  ischecked) {
        this.catpanelChecked[catpanelname] =  (ischecked === 1)        
	}
	
	// Dec 5, 2014; default is true!!!
	isCatpanelCheckedByName(  catpanelname ){
		return this.catpanelChecked.hasOwnProperty( catpanelname ) ? this.catpanelChecked[catpanelname] : false;
    }
    
    serialize() {
        return JSON.stringify(this)
    }

    loadJson(jsonObj){
        this.active = jsonObj.active
        // this.canvasParams = jsonObj.canvasParams
        this.catpanelChecked = jsonObj.catpanelChecked
        this.catpans = jsonObj.catpans
        this.checked = jsonObj.checked
        // this.datasets = jsonObj.datasets
        this.dbserial = jsonObj.dbserial
        this.otherProps = jsonObj.otherProps
        this.projectColor = jsonObj.projectColor
        this.projectName = jsonObj.projectName
        this.shareTreedAlias = jsonObj.shareTreedAlias
        this.sharedDBserial = jsonObj.sharedDBserial
        this.sharedMode = jsonObj.sharedMode
        this.sharedProjectName = jsonObj.sharedProjectName
        this.treeContentString = jsonObj.treeContentString
        this.treeFormat = jsonObj.treeFormat
        this.treeName = jsonObj.treeName
        this.treeSharing = jsonObj.treeSharing
        this.userId = jsonObj.userId

        this.setCanvasParams(jsonObj.canvasParams)
        this.setDatasets(jsonObj.datasets,true)
    }
}

export default UserDataTree