
class UserDataDatasetTree{
    constructor(){
        this.dbSerial = 0
        this.name = ''
        this.content = ''
        this.desc = ''
        this.color = ''
        this.typeOrformat = ''
        this.active = false
        this.disable = true
        this.favorite = false
        this.saveDatasetToServer = false
        this.dbOrder = 0
        this.formatFailed = false
        this.formatFailedMessage = 'Error with dataset file'
    }

    fillObject(jsonObject){
        this.setName(jsonObject.DatasetName)
        this.setDBSerial(jsonObject.ID)
        this.setDesc(jsonObject.Description)
        this.setContent(jsonObject.DatasetContent)
        this.setTypeOrFormat(jsonObject.DatasetType)
        this.setActive(jsonObject.Active === 1)
        this.setDBOrder(jsonObject.Order)
    }

    reloadObject(jsonObject){
        this.setName(jsonObject.name)
        this.setDBSerial(jsonObject.dbSerial)
        this.setDesc(jsonObject.desc)
        this.setContent(jsonObject.content)
        this.setTypeOrFormat(jsonObject.typeOrformat)
        this.setDBOrder(jsonObject.dbOrder)
        this.setActive(jsonObject.active === 1)
    }

    setDBOrder(order){
        this.dbOrder = order
    }

    setFormatFailed(bool){
        this.formatFailed = bool
    }

    setFormatFailedMess(mess){
        this.formatFailedMessage = mess
    }

    getDBOrder(){
        return this.dbOrder
    }
    
    setName(n){
        this.name = n;
    }
    
    setDesc(d){
        this.desc = d;
    }
    
    setContent(c){
        this.content = c;
    }
    
    setColor(c){
        this.color = c;
    }
    
    setFavorite(b){
        this.favorite = b;
    }
    
    setActive(a){
        this.active = a;
    }
    
    setDBSerial(newid){
        this.dbSerial = newid;
    }
    
    setTypeOrFormat(fm){
        this.typeOrformat = fm;
    }
    
    getName(){
        return this.name;
    }
    
    getDesc(){
        return this.desc;
    }
    
    getContent(){
        return this.content;
    }
    
    getColor(){
        return this.color;
    }
    
    getActive(){
        return this.active;
    }
    
    getFavorite(){
        return this.favorite;
    }
    
    getDBSerial(){
        return this.dbSerial;
    }
    
    getTypeOrFormat(){
        return this.typeOrformat;
    }

	setSaveDatasetToServer(b) {
		this.saveDatasetToServer = b;
	}
	
	getSaveDatasetToServer(){
		return this.saveDatasetToServer;
    }
    
    toJson(){
        return {
            'dbSerial':this.getDBSerial(),
            'Active':this.getActive(),
            'DatasetName':this.getName(),
            'format':this.getTypeOrFormat(),
            'content':this.getContent()
        }
    }
}

export default UserDataDatasetTree