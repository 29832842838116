import DataContainerParserBase from './DataContainerParserBase'
import BranchLabelItemDefAttribute from './BranchLabelItemDefAttribute'
import BranchLabelAttributeContainer from './BranchLabelAttributeContainer'
import StringOps from '../utils/StringOps'

class BranchLabelContainerParser extends DataContainerParserBase{
    constructor(datasetid, datasetContent) {
        super()

        if(datasetid.length >=1 && datasetContent.length >=1){
            this.setDatasetID(datasetid);
            this.setOriginal_datastring(datasetContent);
        }

        this.colorsets = []
        let groupDefMap = {}

        var lines = StringOps.JsArrayStringToArrayList( StringOps.splitStringByNewLine( datasetContent ) );
        for (var idx = 0; idx < lines.length; idx++) {
            var line = lines[idx];
            if (line.trim().length > 0 && ! line.startsWith("#")) { // ignore annotation lines
                var lineparts = StringOps.JsArrayStringToArrayList( StringOps.splitStringByTab( line ) );
                var part1 = lineparts[0].trim();
                // console.log(part1)
                // Modifiers -----------------------------
                if( StringOps.equalsIgnoreCase(part1,"!groups_class")){
                    let item_def = new BranchLabelItemDefAttribute()
                    var kvs = this.splitKeyValuePairs(lineparts[1])

                    item_def.setDefId(kvs.hasOwnProperty("id") ? kvs["id"] : '')
                    item_def.setText(kvs.hasOwnProperty("text")? kvs["text"] : '')
                    item_def.setShape(kvs.hasOwnProperty("shape") ? kvs["shape"] : 'rect')

                    item_def.setBackground( kvs.hasOwnProperty("backgroundcolor") ? kvs["backgroundcolor"] : 
                    kvs.hasOwnProperty("bkcolor") ? kvs["bkcolor"] : this.defBkcolor() )
                    
                    item_def.setFontColor(kvs.hasOwnProperty("fontcolor") ? kvs["fontcolor"] : 
						kvs.hasOwnProperty("textcolor") ? kvs["textcolor"] : 
						kvs.hasOwnProperty("color") ? kvs["color"] : this.getGroupLabelFontColor());

                    var italic = this.getBranchLabelFontItalic();
					if (kvs.hasOwnProperty("fontitalic") || kvs.hasOwnProperty("textitalic") ) {
						try {
							var text = kvs.hasOwnProperty("fontitalic") ? kvs["fontitalic"] : kvs["textitalic"];
							italic = parseInt( text ) > 0;
						} catch ( e) { // true
						}
					} else {
					}
                    item_def.setFontItalic(italic);
                    
                    var fontsize = this.getBranchLabelFontSize();
					if (kvs.hasOwnProperty("fontsize") || kvs.hasOwnProperty("textsize")) {
						try {
							var text = kvs.hasOwnProperty("fontsize") ? kvs["fontsize"] : kvs["textsize"];
							fontsize = parseInt( text );
						} catch ( e) {
						}
					}
                    item_def.setFontSz(fontsize);
                    
                    item_def.setLineColor(kvs.hasOwnProperty("linecolor") ? kvs["linecolor"] : 
						kvs.hasOwnProperty("color") ? kvs["color"] : this
							.getGroupLabelLineColor());

					// line width
					var line_width = this.getBranchLabelLineWidth();
					if (kvs.hasOwnProperty("linewidth")) {
						try {
							line_width = parseFloat(kvs["linewidth"]);
						} catch ( e) {
						}
					}
                    item_def.setLineWidth(line_width);
                    
                    // dashed line
					var dashed = this.isbBranchLabelDashedLine();
					if (kvs.hasOwnProperty("linestyle")) {
						dashed = StringOps.equalsIgnoreCase(kvs["linestyle"],"dashed");
					}
					item_def.setbDashedLine(dashed);

					// text align
					var atextalign = this.getBranchLabelTextAlign();
					if (kvs.hasOwnProperty("textalign") || kvs.hasOwnProperty("fontalign") ) {
						var textalign = kvs.hasOwnProperty("textalign")  ? kvs["textalign"] : kvs["fontalign"];
                        if(StringOps.equalsIgnoreCase(textalign,"middle") || StringOps.equalsIgnoreCase(textalign,"start") || StringOps.equalsIgnoreCase(textalign,"end"))
                         {
							atextalign = textalign;
						}
					} //
                    item_def.setTextAlign(atextalign);
                    // this.groupsDef.push(item_def)
                    groupDefMap[kvs['id']] = item_def
                }else if ( part1.startsWith("!")) {
                    this.parseModifier(lineparts, idx + 1);
                }
                //values ---------------------------------
                else if (lineparts.length >= 2) {
                    var part2 = lineparts[1].trim();
                    var temp = part2.split(",")
                    let branch_def = null
                    // console.log(part2,temp.length,temp,groupDefMap.hasOwnProperty(part2),Object.keys(groupDefMap))
                    if(temp.length>=2){ //has label def here itself
                        branch_def = this.getBranchProp(part2)
                        this.colorsets.push(new BranchLabelAttributeContainer(StringOps.JsArrayStringToArrayList( StringOps.splitStringBySeperator(part1, ",") ),branch_def))
                    }else{ //uses group label def
                        if(groupDefMap.hasOwnProperty(part2)){
                            this.colorsets.push(new BranchLabelAttributeContainer(StringOps.JsArrayStringToArrayList( StringOps.splitStringBySeperator(part1, ",") ),groupDefMap[part2]))
                        }else{
                            //group def not found
                            this.valid = false
                            this.setError("error at line "+idx+" ,found no group_class id")
                        }
                    }                    
                }
                if(lineparts.length>=4){
                    this.hasStroke = true
                }
            }
        }

        if (this.colorsets.length <=0) {
            this.valid = false
            this.setError("error: at least one valid colorset data should be given");
        }
 
        if (!this.isSizesMatchGroupsSizes()) {
             this.valid = false
             this.setError("error: the numbers of legends and legend colors don't match;"); // TODO: ciculate colors if there are not enough colors
        }
    }

    getBranchProp(part2){
        let item_def = new BranchLabelItemDefAttribute()
        var kvs = this.splitKeyValuePairs(part2)

        item_def.setText(kvs.hasOwnProperty("text")? kvs["text"] : '')
        item_def.setDefId(kvs.hasOwnProperty("id") ? kvs["id"] : '')

        if(kvs.hasOwnProperty("shape")){
            item_def.drawShape(true)
            item_def.setShape(kvs.hasOwnProperty("shape") ? kvs["shape"] : 'rect')
        }

        item_def.setBackground( kvs.hasOwnProperty("backgroundcolor") ? kvs["backgroundcolor"] : 
        kvs.hasOwnProperty("bkcolor") ? kvs["bkcolor"] : this.defBkcolor() )
        
        item_def.setFontColor(kvs.hasOwnProperty("fontcolor") ? kvs["fontcolor"] : 
            kvs.hasOwnProperty("textcolor") ? kvs["textcolor"] : 
            kvs.hasOwnProperty("color") ? kvs["color"] : this.getGroupLabelFontColor());

        var italic = this.getBranchLabelFontItalic();
        if (kvs.hasOwnProperty("fontitalic") || kvs.hasOwnProperty("textitalic") ) {
            try {
                var text = kvs.hasOwnProperty("fontitalic") ? kvs["fontitalic"] : kvs["textitalic"];
                italic = parseInt( text ) > 0;
            } catch ( e) { // true
            }
        } else {
        }
        item_def.setFontItalic(italic);
        
        var fontsize = this.getBranchLabelFontSize();
        if (kvs.hasOwnProperty("fontsize") || kvs.hasOwnProperty("textsize")) {
            try {
                var text = kvs.hasOwnProperty("fontsize") ? kvs["fontsize"] : kvs["textsize"];
                fontsize = parseInt( text );
            } catch ( e) {
            }
        }
        item_def.setFontSz(fontsize);
        var temWidth = kvs['text'].length
        if(this.maxWidth <= temWidth){
            this.maxWidth = temWidth
        }
        
        item_def.setLineColor(kvs.hasOwnProperty("linecolor") ? kvs["linecolor"] : 
            kvs.hasOwnProperty("color") ? kvs["color"] : this
                .getGroupLabelLineColor());
        // line width
        var line_width = this.getBranchLabelLineWidth();
        if (kvs.hasOwnProperty("linewidth")) {
         try {
          line_width = parseFloat(kvs["linewidth"]);
         } catch ( e) {
         }
        }
        item_def.setLineWidth(line_width);
                    
        // dashed line
        var dashed = this.isbBranchLabelDashedLine();
        if (kvs.hasOwnProperty("linestyle")) {
         dashed = StringOps.equalsIgnoreCase(kvs["linestyle"],"dashed");
        }
        item_def.setbDashedLine(dashed);

        var atextalign = this.getBranchLabelTextAlign();
        if (kvs.hasOwnProperty("textalign") || kvs.hasOwnProperty("fontalign") ) {
            var textalign = kvs.hasOwnProperty("textalign")  ? kvs["textalign"] : kvs["fontalign"];
            if(StringOps.equalsIgnoreCase(textalign,"middle") || StringOps.equalsIgnoreCase(textalign,"start") || StringOps.equalsIgnoreCase(textalign,"end"))
            {
            atextalign = textalign;
            }
        } //
        item_def.setTextAlign(atextalign);
        return item_def                
    }

    getColorsets() {
        return this.colorsets;
    }
}

export default BranchLabelContainerParser