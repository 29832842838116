
class treePlotMode{
    static RECT_CLADOGRAM = 'RECT_CLADOGRAM'
    static RECT_PHYLOGRAM = 'RECT_PHYLOGRAM'
    static SLANTED_CLADOGRAM_RECT='SLANTED_CLADOGRAM_RECT'
    static SLANTED_CLADOGRAM_MIDDLE = 'SLANTED_CLADOGRAM_MIDDLE'
    static SLANTED_CLADOGRAM_NORMAL = 'SLANTED_CLADOGRAM_NORMAL'
    static CIRCULAR_PHYLOGRAM  = 'CIRCULAR_PHYLOGRAM'
    static CIRCULAR_CLADOGRAM = 'CIRCULAR_CLADOGRAM'
    static RADIAL_CLADOGRAM = 'RADIAL_CLADOGRAM'
    static UNROOTED = 'UNROOTED'
}

export default treePlotMode