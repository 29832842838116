import ColorAttributes from './ColorAttributes'

class ColorAttributesContainer{
    constructor(nodeids, colsAr, m,stroke){
        this.nodeIDs = nodeids;
        this.colors = []
        for(var ind in colsAr){
            var color = colsAr[ind]
            this.colors.push( new ColorAttributes(  {'attribute':color }) );
        }
        this.mode = m;
        this.stroke = stroke
    }

    getNodeIDs() {
        return this.nodeIDs;
    }

    getMode() {
        return this.mode;
    }
    
    getColors(){
        return this.colors;
    }

    getStroke(){
        return this.stroke
    }
}

export default ColorAttributesContainer