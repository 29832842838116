import Vue from 'vue'
import App from './App'
import router from './router'
import Vuex from 'vuex'
import { BootstrapVue,BootstrapVueIcons,BBreadcrumb,ModalPlugin,BButton } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import Layout  from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faInfoCircle,faQuestionCircle,faWindowMaximize, faUserCircle, faStar,faPollH, faDownload, faUpload, faPlusCircle, faMinusCircle, faChevronUp, faChevronDown, faCopy, faShareAltSquare, faEdit, faTrashAlt, faArrowUp, faArrowDown, faTimes, faPencilAlt, faSquare, faWindowMinimize,faWindowRestore,faAsterisk,faCode,faExternalLinkAlt,faClipboard,faBars,faWindowClose,faListAlt, faFileUpload, faFileDownload, faTimesCircle, faCheck, faArrowsAlt,faArrowCircleRight,faEnvelope} from '@fortawesome/free-solid-svg-icons'
// import { farsq } from '@fortawesome/free-regular-svg-icons'
import { faFontAwesome } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon,FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
import { faSlidersH } from '@fortawesome/fontawesome-free-solid'
import VueLocalStorage from 'vue-localstorage'
import 'vue-search-select/dist/VueSearchSelect.css'
// import * as VueWindow from '@hscmap/vue-window'
import vmodal from 'vue-js-modal'
import Multiselect from 'vue-multiselect'
import "vue-multiselect/dist/vue-multiselect.min.css"
import VueClipboard from 'vue-clipboard2'
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
import { domain, clientId } from "../auth_config.json";

import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'

import { Auth0Plugin } from "./auth";
import hljs from "highlight.js/lib/";
import "highlight.js/styles/github.css";

import * as evolicons from './assets/iconfont'

import 'fullpage.js/vendors/scrolloverflow' // Optional. When using scrollOverflow:true
import './fullpage.parallax.min.js'
import VueFullPage from 'vue-fullpage.js'
Vue.use(VueFullPage);

import VuejsDialog from 'vuejs-dialog';
import VuejsDialogMixin from 'vuejs-dialog/dist/vuejs-dialog-mixin.min.js'; // only needed in custom components
import 'vuejs-dialog/dist/vuejs-dialog.min.css'; // include the default style

// Tell Vue to install the plugin.
Vue.use(VuejsDialog);

Vue.config.productionTip = false
Vue.use(Vuex)
// Vue.use(VueWindow)
Vue.use(VueClipboard)
Vue.use(vmodal,{ dialog: true })
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(Layout)
Vue.use(ModalPlugin)
Vue.use(VueLocalStorage)
Vue.use(VueMaterial)
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

export const store = new Vuex.Store({
  state: {
    user: '',
    catPanels:'',
    activeTree:'',
    activeShareTree:'',
    activeEmbedTree:'',
    activeProj:'',
    projList:[],
    // projTreeList:[],
    // datsetList:[],
    // name2CatPans:[],
    treeSVGCan:null,
    treeEmbedSVGCan:null,
    treeShareSVGCan:null,
  },
  mutations: {
    userChange(state, user) {
      state.user = user
    },
    catPanChange(state,catPanels){
      state.catPanels = catPanels
    },
    activeTreeChange(state,activeTree){
      state.activeTree = activeTree
    },
    activeShareTreeChange(state,activeShareTree){
      state.activeShareTree = activeShareTree
    },
    activeEmbedTreeChange(state,activeEmbedTree){
      state.activeEmbedTree = activeEmbedTree
    },
    activeProjChange(state,activeProj){
      state.activeProj = activeProj
    },    
    projListChange(state,projList){
      state.projList = projList
    },
    // projTreeListChange(state,projTreeList){
    //   state.projTreeList = projTreeList
    // },
    // datsetListChange(state,datsetList){
    //   state.datsetList = datsetList
    // },
    // name2CatPansChange(state,name2CatPans){
    //   state.name2CatPans = name2CatPans
    // },
    treeSVGCanChange(state,treeSVGCan){
      state.treeSVGCan = treeSVGCan
    },
    treeShareSVGCanChange(state,treeShareSVGCan){
      state.treeShareSVGCan = treeShareSVGCan
    },
    treeEmbedSVGCanChange(state,treeEmbedSVGCan){
      state.treeEmbedSVGCan = treeEmbedSVGCan
    }

  },
  getters: {
    user: state => state.user,
    actTree: state => state.activeTree,
    actShTree: state => state.activeShareTree,
    actEmTree: state => state.activeEmbedTree,
    actProj:state => state.activeProj,
    catPan:state => state.catPanels,
    projLis:state => state.projList,
    // projTreeLis:state => state.projTreeList,
    // datsetLis:state => state.datsetList,
    // name2CatPan:state => state.name2CatPans,
    treeSVGCan:state => state.treeSVGCan
  }
})

Vue.component('b-button', BButton)
Vue.component('vue-draggable-resizable', VueDraggableResizable)
Vue.component('multi-select', Multiselect)
Vue.component('b-breadcrumb', BBreadcrumb)
library.add(faInfoCircle,faQuestionCircle,faWindowMaximize,faUserCircle,faSlidersH,faStar,faPollH,faDownload,faUpload,faPlusCircle,faMinusCircle,faChevronUp,faChevronDown,faCopy,faShareAltSquare,faEdit,faTrashAlt,faArrowUp,faArrowDown,faTimes,faPencilAlt,faSquare, faWindowMinimize,faWindowRestore,faAsterisk,faCode,faExternalLinkAlt,faClipboard,faBars,faWindowClose,faListAlt,faFileUpload,faFileDownload,faTimesCircle,faCheck,faArrowsAlt,faArrowCircleRight,faEnvelope,faFontAwesome)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)


// Vue.prototype.$testStatus = false
Vue.prototype.$hostname = (!Vue.config.productionTip) ? '': 'http://localhost:6005/'  
Vue.prototype.$hosturl = (!Vue.config.productionTip) ? 'https://evolgenius.info/evolview/#/':'http://localhost:8080/#/'
/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  router,
  components: { App },
  template: '<App/>',
  render: h => h(App)
})