
class TreeDecoType{
    static PIES = 'PIES'
    static STRIPS = 'STRIPS'
    static BARS = 'BARS'
    static LEAFCOLOR = 'LEAFCOLOR'
    static LEAFBKCOLOR = 'LEAFBKCOLOR'
    static BRANCHCOLOR = 'BRANCHCOLOR'
    static BRANCHSTROKE = "BRANCHSTROKE"
    static BRANCHLABELS = "BRANCHLABELS"
    static LEAFANNOTATE = "LEAFANNOTATE"
    static CHARTS = 'CHARTS'
    static PROTEINDOMAINS = 'PROTEINDOMAINS'
    static GENESYTNEY = 'GENESYTNEY'
    static BRANCHMARKER = "BRANCHMARKER"
    static LEAFLABELDECO = 'LEAFLABELDECO'
    static LEAFIMAGE = "LEAFIMAGE"
    static GROUPLABELS = 'GROUPLABELS'
    static DOTPLOTS = 'DOTPLOTS'
    static HEATMAP = 'HEATMAP'
    static BOOTSTRAPSTYLES = 'BOOTSTRAPSTYLES' 
    static INTERNALCOLLAPSES = 'INTERNALCOLLAPSES' 
    static NAMEDCOLUMNS = 'NAMEDCOLUMNS'
    static TIMELINE = 'TIMELINE'
    static GENETRANSFER = 'GENETRANSFER'
}

export default TreeDecoType