<template>
    <div class="container">
        <b-container fluid v-if="!showPage">
              <b-row>
                <div class="text-center m-5">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>&nbsp;Loading...</strong>
                </div>
              </b-row>
        </b-container>   
        <b-container fluid v-if="showPage" style="margin-top:100px;">
            <b-row style="margin-top:100px;text-align: left;">
                    <b-col>
                        <b-table-simple>
                            <b-tr style="border:none;border-bottom: 1px solid #F1F1EF;">
                                <b-td style="width:28%;border: none;"><h3>Project Management</h3></b-td>
                                <b-td style="border: none;"><h5><b-badge>{{totProjects}} Projects</b-badge> <b-badge style="background-color:#4CCBEA"> {{totTrees}} Trees</b-badge> <b-badge style="background-color:#E7BA21">{{totSharedTrees}} Shared Trees</b-badge></h5></b-td>
                            </b-tr>
                        </b-table-simple>
                    </b-col>                    
            </b-row>                                    
            <b-row style="text-align: left;">
                <b-col>
                    <b-row>
                        <b-col style="align:left">                       
                                <b-input-group>
                                    <b-form-input
                                    v-model="filter"
                                    type="search"
                                    id="filterHmPairs"
                                    placeholder="Type to Search"
                                    ></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>
                        </b-col>
                    </b-row>                    
                </b-col>
            </b-row>            
            <br/>
            <b-row>
                <b-col align-self="center">
                    <b-table
                                responsive
                                show-empty
                                :busy.sync="isBusy"
                                :striped="false"
                                :outlined="true"
                                :items="procSams"
                                :fields="tab_fields"
                                :current-page="currentPage"
                                :per-page="perPage"
                                :filter="filter"
                                :filter-function="filterTable"
                            >
                            <template  v-slot:table-busy>
                                      <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong>Loading...</strong>
                                      </div>
                            </template>
                            <template v-slot:cell(show_details)="row">
                                <b-button size="sm" @click="row.toggleDetails">
                                    {{ row.detailsShowing ? '-' : '+'}}
                                </b-button>
                            </template>
                            <template v-slot:cell(download) ="row">
                                <font-awesome-icon icon="download" size='sm' title="Download project data" @click="downloadProject(row.item)" style="color:green"></font-awesome-icon>
                            </template>
                            <template v-slot:cell(delete) ="row">
                                <font-awesome-icon icon="trash-alt" size='sm' title="Delete project" style="color:red"></font-awesome-icon>
                            </template>
                            <template v-slot:row-details="row">
                                <b-row class="list-group">
                                    <b-col cols>
                                        <b-table-simple>
                                            <b-tr>
                                                <b-td style="width:30%;border: none;">
                                                    <b-list-group>
                                                        <b-list-group-item v-for="(tree, idx) in row.item.sibiling" :key="idx" @click="row.item.curTreeIndex = idx" style="cursor:pointer">
                                                            {{tree.treeName}}
                                                            <b-badge variant="primary" pill>{{tree.getSharedTimes()}}</b-badge>
                                                        </b-list-group-item>
                                                    </b-list-group>
                                                </b-td>
                                                <b-td style="width:70%;border: none;">
                                                    <b-table
                                                        sticky-header
                                                        responsive
                                                        show-empty
                                                        :bordered="true"
                                                        :striped="false"
                                                        :outlined="true"
                                                        :items="row.item.sibiling[row.item.curTreeIndex].treeSharing"
                                                        :fields="sub_tab_feilds"
                                                    >
                                                        <template  v-slot:table-busy>
                                                            <div class="text-center text-danger my-2">
                                                                <b-spinner class="align-middle"></b-spinner>
                                                                <strong>Loading...</strong>
                                                            </div>
                                                        </template>
                                                        <template v-slot:cell(view) ="ro">
                                                            <span v-show="ro.item.SharingType === 'Share'">
                                                             <router-link :to="{ path: '/shared/'+ro.item.SharingName}" target="_blank"><font-awesome-icon icon="external-link-alt" size='sm' title="View tree;cursor:pointer"></font-awesome-icon></router-link>
                                                            </span>
                                                            <span v-show="ro.item.SharingType === 'Collaborate'">
                                                             <router-link :to="{ path: '/collab/'+ro.item.SharingName}" target="_blank"><font-awesome-icon icon="external-link-alt" size='sm' title="View tree;cursor:pointer"></font-awesome-icon></router-link>
                                                            </span>
                                                            <span v-show="ro.item.SharingType === 'embed'">
                                                             <router-link :to="{ path: '/embed/'+ro.item.SharingName}" target="_blank"><font-awesome-icon icon="external-link-alt" size='sm' title="View tree;cursor:pointer"></font-awesome-icon></router-link>
                                                            </span>                                                            
                                                        </template>
                                                        <template v-slot:cell(del) ="ro">
                                                            <a @click="showConfirmBox(ro.item.SharingID,row.item,row.item.curTreeIndex)"><font-awesome-icon icon="trash-alt" size='sm' title="Delete share link" style="color:red;cursor:pointer"></font-awesome-icon></a> 
                                                        </template>
                                                    </b-table>
                                                </b-td>
                                            </b-tr>
                                        </b-table-simple>                                        
                                    </b-col>
                                </b-row>                                                                
                            </template>                            
                    </b-table>
                    <b-row>
                            <b-col md="6" class="my-1">
                                <b-pagination
                                v-model="currentPage"
                                :total-rows="procSamsSize"
                                :per-page="perPage"
                                class="my-0"
                                ></b-pagination>
                            </b-col>
                            <b-col md="6" class="my-1">
                                <b-form-group label-cols-sm="3" label="Per page" class="mb-0">
                                <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios'
import EventBus from './EventBus'
import UserDataTree from '../treeObjects/UserDataTree'
const _ = require("underscore")
const download = require('downloadjs')

export default {
    data() {
        const token = localStorage.getItem("usertoken") 
        const decoded = JSON.parse(token)//jwtDecode
        return {
            userId: decoded.userId,
            server_add:this.$hostname,
            showPage:true,
            filter:null,
            isBusy:true,
            tab_data:[],
            tab_fields:[
                { key:'show_details',label: '','class': 'small_col2'},
                { key: 'projectID', label: 'Project ID', sortable: false},
                { key: 'projectName', label: 'Project Name', sortable: false},
                { key: 'trees', label: 'Total Trees', sortable: false},
                { key: 'shared', label: 'Total Shared Links', sortable: false},
                { key: 'download', label: 'Download', sortable: false},
                { key: 'delete', label:'Delete', sortable:false}
            ],
            sub_tab_feilds:[
                { key: 'TreeNameSharedAlias', label: 'Tree name', sortable: false},
                { key: 'SharingName', label: 'Sharing name', sortable: false},
                { key: 'SharingType', label:'Sharing mode', sortable:false},
                { key: 'Visits', label: 'Visits', sortable: true},
                { key: 'view', label: 'Link', sortable: false},
                { key: 'del', label: '', sortable: false},
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 15,
            pageOptions: [5, 10, 15,25],
            projHtmlTxt:'',
            filtMod:'Country',
            filtTerm:'',
            filtOptions:['Country','Related disease'],
            filtMap:{'Country':'Country','RelatedDisease':'Related disease','Related disease':'RelatedDisease'},
            addedFilters:[],
            totSharedTrees:0,
            totTrees:0,
            totProjects:0,
        }
    },
    mounted() {
        // console.log(this.$route,['Projects'].indexOf(this.$route.name))
        this.loadProjTab()        
        EventBus.$emit('hide-canvas', {'state':false,'src':'canvaswindow','type':'normal'})
    },
    computed: {
        procSams(){
            if(this.tab_data.length){
                let tem = this.tab_data.filter(sam =>{
                    var hasAss = false
                    if(this.filter != null && this.filter.length>=1){
                        // console.log(sam)
                        var sup = this.filter                                        
                        if( (sam.projectID != null && (sam.projectID+"").match(sup)) || 
                            (sam.projectName != null && sam.projectName.match(sup)) ){
                            hasAss = true
                        }
                    }else{
                        hasAss = true
                    }
                    if(this.addedFilters.length>=1){
                        var su = 0
                        this.addedFilters.forEach(ele => {
                            var sp = ele.split(':')
                            if(sp != null  && sam[sp[0]] != null && sam[sp[0]].toLowerCase().match(sp[1].toLowerCase())){
                               su++ 
                            }
                        });
                        return hasAss && su === this.addedFilters.length
                    }else{
                        return hasAss && true
                    }
                })
                return tem
            }
            return null
        },
        procSamsSize(){
            if(this.tab_data.length){
                let tem = this.tab_data.filter(sam =>{
                    var hasAss = false
                    if(this.filter != null && this.filter.length>=1){
                        // console.log(filter)
                        var sup = this.filter                                        
                        if( (sam.projectID != null && (sam.projectID+"").match(sup)) || 
                            (sam.projectName != null && sam.projectName.match(sup)) ){
                            hasAss = true
                        }
                    }else{
                        hasAss = true
                    }
                    if(this.addedFilters.length>=1){
                        var su = 0
                        this.addedFilters.forEach(ele => {
                            var sp = ele.split(':')
                            if(sp != null  && sam[sp[0]] != null && sam[sp[0]].toLowerCase().match(sp[1].toLowerCase())){
                               su++ 
                            }
                        });
                        return hasAss && su === this.addedFilters.length
                    }else{
                        return hasAss && true
                    }
                })
                return tem.length
            }
            return 0
        }
    },
    methods: {
        loadProjTab(){
            axios.post(this.server_add+'query/getProjectsAndTrees',
            {
                userId:this.userId
            }).then(retDat => {
                if(retDat.status == 200){
                    // this.tab_data = retDat.data
                    // this.showPage = true
                    // this.isBusy = false
                    // this.totalRows = this.tab_data.length
                    this.formProjDat(retDat.data)                    
                }
            }).catch(err => {
                console.log(err)
            })
        },
        formProjDat(data){
            var projDat = data.proj
            var treeShareDat = _.groupBy(data.tshare,'TreeID')
            var projMap = {}
            projDat.forEach(element => {
                var projId = element.projectID
                var proj = null
                if(!projMap.hasOwnProperty(projId)){
                    proj = {'projectName':element.projectName,'projectID':element.projectID,'color':element.color,'_showDetails':false,'trees':0,'shared':0}
                    projMap[projId] = proj
                }else{
                    proj = projMap[projId]
                }
                var udt = new UserDataTree()
                udt.init(element.treeID, element.treeName, element.TreeActive,element.treeFormat);
                if(treeShareDat.hasOwnProperty(element.treeID)){
                    var treeShareLis = treeShareDat[element.treeID]
                    treeShareLis.forEach(ele => {
                        var shareInfo = {'ProjectName':ele.ProjectName,'SharingType':ele.SharingType,'SharingName':ele.SharingName,'TreeNameSharedAlias':ele.TreeName,'Version':ele.Version,'Visits':ele.Visits,'SharingID':ele.ID}
                        udt.addATreeSharingEntry(shareInfo)
                        // console.log(udt.treeName,udt.getSharedTimes())
                    });
                }else{
                    // console.log('missed',element.treeID,element)
                }
                this.AddASibling(proj,udt)
            });
            this.tab_data = _.values(projMap)
            this.isBusy = false
            this.totalRows = this.tab_data.length
            this.calTotShares()
            this.totProjects = Object.values(projMap).length
        },
        AddASibling(proj,udt){
            if(proj.hasOwnProperty('sibiling')){
                proj.sibiling.push(udt)
                proj.trees += 1
            }else{
                proj['sibiling'] = [udt]
                proj.trees = 1
                proj['curTreeIndex'] = 0
                // console.log(proj.sibiling[0])
            }
            // this.totTrees +=proj.trees
        },
        calTotShares(){
            let that = this
            this.tab_data.forEach(element => {
                var totShares = 0
                if(element.hasOwnProperty('sibiling')){
                    element.sibiling.forEach(ele => {
                        totShares+=ele.getSharedTimes()
                    });
                    that.totTrees +=element.sibiling.length
                }
                element.shared = totShares
                that.totSharedTrees += element.shared
            });
        },
        downloadProject(proj){
            axios.post(this.server_add+'query/downloadProject',
            {
                proj:proj
            },{responseType:'arraybuffer'}).then(retDat => {
                if(retDat.status == 200){
                    // const blob = new Blob([retDat.data]);
                    let blob = new Blob([retDat.data], { type: 'application/zip' }) //It is optional                    
                    download(retDat.data,"attachement.zip","application/zip") //this is third party it will prompt download window in browser.
                }
            }).catch(err => {
                console.log(err)
            })
            // var data = JSON.stringify(proj);
            // axios({
            //     url:this.server_add+'query/downloadProject',
            //     method:'post',
            //     headers:{
            //         'Content-Type': 'multipart/form-data'
            //     },
            //     data:data,
            //     responseType:'arraybuffer' // If we don't mention we can't get data in desired format
            // })
            // .then(async response => {
            //     console.log("got al files in api ");
            //     let blob = await new Blob([response.data], { type: 'application/zip' }) //It is optional

            //     download(response.data,"attachement.zip","application/zip") //this is third party it will prompt download window in browser.

            //     return response.data;
            // })
        },
        delShareLink(shareLinkId,proj,udtIndex){
            axios.post(this.server_add+'query/delShareLink',
            {
                shRecId:shareLinkId
            }).then(retDat => {
                if(retDat.status == 200 && retDat.data.status === 'deleted'){
                    var delInd = -1
                    var udt = proj.sibiling[udtIndex]
                    for(var ind in udt.treeSharing){
                        var inf = udt.treeSharing[ind]
                        if(inf.SharingID == shareLinkId){
                            delInd = ind
                            break;
                        }
                    }
                    if(delInd>=0){
                        var ar = proj.sibiling[udtIndex].treeSharing
                        ar.splice(delInd,1)
                        this.$set(proj.sibiling[udtIndex].treeSharing,'treeSharing',ar)
                        this.$forceUpdate();
                    }
                }
            }).catch(err => {
                console.log(err)
            })
        },
        showConfirmBox(shareLinkId,proj,udtIndex) {
        this.$bvModal.msgBoxConfirm('Please confirm that you want to delete link.', {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(value => {
            if(value){
                this.delShareLink(shareLinkId,proj,udtIndex)
            }
          })
          .catch(err => {
            // An error occurred
          })
        },
        filterTable(row,filter) {
            var hasAss = false
            if(filter.includes(row.projectName) || filter.includes(row.projectID)){
                hasAss = true
            }
            if(filter != null ){
                var su = filter.trim()
                if( (row.projectName != null && row.projectName.match(su)) || 
                (row.projectID != null && (row.projectID+"").match(su))){
                  hasAss = true
                }
            }
            return hasAss;            
        },
        addFilter(){
            var filtType = this.filtMap[this.filtMod]
            var filtText = this.filtTerm
            var res = filtType+":"+filtText
            if(!this.addedFilters.includes(res)){
                this.addedFilters.push(res)
                this.filtTerm = ''
            }
        },
        removeFilter(term){
            var ind = this.addedFilters.indexOf(term)
            if(ind >-1){
                this.addedFilters.splice(ind, 1);
            }
        },
    },
}
</script>

<style >
.list-group{
    max-height: 250px;
    margin-bottom: 10px;
    overflow:scroll;
}
</style>