import PlotBase from "./PlotBase";
import treePlotMode from '../treeObjects/treePlotMode'
import ABarPlotValueAndAttributes from './ABarPlotValueAndAttributes'
import StringOps from '../utils/StringOps'
import Point from './Point'

const FontStyle = {
    'NORMAL':'normal',
    'ITALIC':'italic',
    'OBLIQUE':'oblique',
    }
Object.freeze(FontStyle)

class Bars extends PlotBase{
    constructor(layer_tree_charts, disable_all_dataset, treeSVG){
        super()
        this.treeSVG = treeSVG
        this.layer_tree_charts = layer_tree_charts
        this.disable_all_dataset = disable_all_dataset

        this.chart_plots_enabled = this.treeSVG.chart_plots_enabled
        this.space_between_datasets = this.treeSVG.space_between_datasets
        this.currentMostRightPosition = this.treeSVG.getLeafMostRightPosition()
        this.plotmode = this.treeSVG.getPlotMode()
        this.phylotree = this.treeSVG.getPhyloTree()
        this.rootXY = this.treeSVG.getRootXY()
        this.angle_span = this.getAngleSpan()
        this.space_between_columns_within_a_dataset = this.treeSVG.space_between_columns_within_a_dataset
        this.pxPerHeight = this.treeSVG.pxPerHeight
        this.LeafInternalID2NodePosisionts = this.getLeafInternalID2NodePosisionts()

        this.ndCon = null;
		this.plot_width_per_subpanel = 20;
		this.layer_barplot_grid = null
		this.layer_barplot_gridlabels = null
		this.maxValuePerColumn = [];
		this.row2data = {};
        this.fangle = 0, 
        this.pwidth_per_value = 0;

        this.defaultColor = "white";
		this.plotWidth = 40;
    }

    getAngleSpan(){
        return this.treeSVG.getAngleSpan()
    }

    getLeafInternalID2NodePosisionts(){
        return this.treeSVG.getLeafInternalID2NodePosisionts()
    }

    getLeafMostRightPosition(){
        return this.currentMostRightPosition
    }

    setLeafMostRightPosition(currentMostRightPosition){
        this.currentMostRightPosition = currentMostRightPosition
    }
    
    setDataSet(newID, mdatCon) {
        this.ndCon = mdatCon;
        this.datasetID = newID;
        this.setActive(true);
        this.row2data = this.ndCon.getTreeNodeID2Data(); //
        this.datasetContentAsString = mdatCon.getOriginal_datastring(); // get original user input as String ; April
                                                                        // 5, 2011;
        this.opacity = this.ndCon.getOpacity();
        // this.barheightPCT = numericDataContainer.getBarheightPCT();
        this.setLegendEntry(mdatCon.getLegend()); // march 20, 2014 --

        var groups = this.ndCon.getLegendTexts();
        var column2data = this.ndCon.getColumn2Data();
        for (var sidx = 0; sidx < groups.length; sidx++) {
            this.maxValuePerColumn.push(Math.max(...column2data[groups[sidx]]));
        }
    }

    // setRightMostPosition(rightMostPosition){
    //     this.currentMostRightPosition = rightMostPosition
    // }

    setLeafInternalID2NodePosisionts(LeafInternalID2NodePosisionts){
        this.LeafInternalID2NodePosisionts = LeafInternalID2NodePosisionts
    }

    setRootXY(rootXY){
        this.rootXY = rootXY
    }

    setPlotMode(plotmode){
        this.plotmode = plotmode
    }

    addHighlightToRowPlotByLeafInternalID(leaf_internal_id) {
        if (this.hmInternalID2RowPlot.hasOwnProperty(leaf_internal_id)) {
            this.hmInternalID2RowPlot[leaf_internal_id].attr("stroke", "red");
        }
    }

    removeHighlightFromRowPlotByLeafInternalID(leaf_internal_id) {
        if (this.hmInternalID2RowPlot.hasOwnProperty(leaf_internal_id)) {
            this.hmInternalID2RowPlot[leaf_internal_id].attr("stroke",null);
        }
    }

    /*
    * override the original defination in the base class at the moment, everytime
    * this function is called, everything will be replotted;  
    * 
    */
    makeOrUpdatePlot() { // BAR
    const groups = this.ndCon.getLegendTexts();
    const colors = this.ndCon.getLegendColors();
    const strokecolors = this.ndCon.getLegendStrokeColors();
    const defaultStrokeColor = this.ndCon.getDefaultStrokeColor();
    const strokewidth = this.ndCon.getDefaultStrokeWidth();
    this.currentMostRightPosition = this.getLeafMostRightPosition()
    this.LeafInternalID2NodePosisionts = this.getLeafInternalID2NodePosisionts()
    this.phylotree = this.treeSVG.getPhyloTree()
    this.rootXY = this.treeSVG.getRootXY()
    this.angle_span = this.getAngleSpan()

    this.hmInternalID2RowPlot = {};
    this.initLayer(this.layer_tree_charts)
    this.layer_barplot_grid = this.layer_tree_charts.group().id('layer_barplot_grid')
    this.layer_barplot_gridlabels = this.layer_tree_charts.group().id('layer_barplot_gridlabels')
    
    if (this.active && !this.disable_all_dataset && this.chart_plots_enabled) {
        var mostright = this.currentMostRightPosition + this.space_between_datasets;
        /*
        * allow user to specify plot width
        */
        this.plotWidth = this.ndCon.getPlotwidth();
        this.plot_width_per_subpanel = this.ndCon.isAlignindividualcolumn()
            ? (this.plotWidth - (groups.length - 1) * this.space_between_columns_within_a_dataset)
            / groups.length
            : this.plotWidth;

        
        var canMakeLogTransform = this.ndCon.canMakeLogTransform();
        if (this.ndCon.isLogtransform() && !canMakeLogTransform) {
            // MC.alert.setMessageAndShow("log transform is not possible, so the data are NOT log-transformed.",
            // AlertWidgetType.warning);
            console.log('cannot do log transform for this dataset')
        }

        // get max value, gridlineCount and group count --
        var maxvalue = 0,minvalue;
        var gridlineCount, groupSize;
        if (this.ndCon.isAlignindividualcolumn()) {
            maxvalue = this.ndCon.getMaxValue();
            minvalue = this.ndCon.getMinValue();
            gridlineCount = 4;
            groupSize = groups.length;
        } else {
            maxvalue = Math.max(...Object.values(this.ndCon.getID2Sum()));
            minvalue = Math.min(...Object.values(this.ndCon.getID2Sum()))
            gridlineCount = 5;
            groupSize = 1;
        }
        // console.log('max -val',maxvalue,minvalue)

        // calculate pwidth per value --
        if (canMakeLogTransform) {
            this.pwidth_per_value =(this.ndCon.isAlignindividualcolumn()
            ? this.plot_width_per_subpanel / Math.log(maxvalue)
            : this.plotWidth / Math.log(maxvalue));
        } else {
            this.pwidth_per_value = this.ndCon.isAlignindividualcolumn() ?this.plot_width_per_subpanel / maxvalue
            :this.plotWidth / maxvalue;
        }

        /**
         * recalculate pheight at the begining of the replot -- 
         * -- recalculate itemHeightPixel at the begining of the replot two
         * values should be prepared, one for circular mode, the other for normal mode
         */
        var itemHeightPixel = (this.plotmode === (treePlotMode.CIRCULAR_CLADOGRAM)
            || this.plotmode === (treePlotMode.CIRCULAR_PHYLOGRAM))
            ? this.ndCon.getItemPixelHeight((mostright * 2 * Math.PI / 360 * this.angle_span
                / this.phylotree.getMaxVerticalLevel()))
            : this.ndCon.getItemPixelHeight(this.pxPerHeight);

        // a hard limit ??? --
        if (itemHeightPixel > 40) {
            itemHeightPixel = 40;
        }
        //
        this.fangle = (Math.atan((itemHeightPixel / 2) / (mostright - this.rootXY.x)) / Math.PI * 180); // if bars are plotted as fans in circular mode

        /*
        * deal with grid and axis, if available
        */
        var makegrid = false;
        if (this.ndCon.isPlotgrid()) {
            Point.clearAllChildNodesFromSVGGElement(this.layer_barplot_grid);

            // layer_tree_charts.appendChild(layer_barplot_grid); // now all charts are added to
                            // charts ...
            this.layer_barplot_grid.attr("stroke-width", this.line_width_grid + "");
            this.layer_barplot_grid.attr("stroke-dasharray", "4,4"); // Apr 25, 2012; now all have this dash

            this.layer_barplot_grid.attr("stroke", "grey");
            this.layer_barplot_grid.attr("fill", "none"); // for path only;

            makegrid = true;
        } else {
            Point.deleteChildNodeFromLayerTree(this.layer_barplot_grid);
        }

        // grid axis; grid labels
        if (this.ndCon.isShowGridLabel()) {
            Point.clearAllChildNodesFromSVGGElement(this.layer_barplot_gridlabels);
            makegrid = true;
        } else {
            Point.deleteChildNodeFromLayerTree(this.layer_barplot_gridlabels);
        }

        if (makegrid) {
            this.drawGridLinesAndLabels(groupSize, gridlineCount, maxvalue, mostright, this.pwidth_per_value, this.pxPerHeight,
            this.plot_width_per_subpanel, this.layer_barplot_grid, this.layer_barplot_gridlabels, canMakeLogTransform,
            this.ndCon.getGridLabels(), this.ndCon.getGridShadeOptions());
        } // make grid

        /*
        * >>>>>>>>>> the main layer >>>>>>>>>>>>>
        */
        Point.clearAllChildNodesFromSVGGElement(this.layer);
        this.layer.attr("fill-opacity", this.opacity);

        // create a SVG group element to hold data values
        var showdataSVGGelement = this.layer.group().id('showdataSVGGelement')
        showdataSVGGelement.font({size:this.ndCon.getShowDataFontSize()})
        if (this.ndCon.isbShowDataFontItalic()) {
            showdataSVGGelement.font({style:FontStyle.ITALIC})
        }
        if (!StringOps.equalsIgnoreCase(this.ndCon.getShowDataFontColor(),"black")) {
            showdataSVGGelement.attr("fill", this.ndCon.getShowDataFontColor());
        }

        // iterate hashPositionOfLeafNodes
        for (var entry in this.LeafInternalID2NodePosisionts) {
            const leaf_internal_id = entry;
            var startpos = this.LeafInternalID2NodePosisionts[entry].pt;
            var angle = this.LeafInternalID2NodePosisionts[entry].angle;
            /**
            * iterate data for current node, one row at a time ... Aug 13, 2017 --
            */
            if (this.row2data.hasOwnProperty(leaf_internal_id)) {
                const cdata = this.row2data[leaf_internal_id];

                var start_pos = mostright; // it'll be changed at the end of the for loop

                /**
                 * each row of data now will be added into a SVGG element --
                 * then create a tooltip for each row of data. add rowplot to
                 * a HashMap
                 */
                const rowplot = this.layer.group().id('rowplot')
                this.hmInternalID2RowPlot[leaf_internal_id] =  rowplot; // 添加至hashmap ...

                // get external id
                const externalID = (this.phylotree != null)
                ? this.phylotree.getExternalIDbyInternalID(leaf_internal_id)
                : "";
                
                var that = this
                rowplot.mouseover(function(evt) {
                    that.treeSVG.addHighlightToAllRowplotsByInternalLeafID(leaf_internal_id);
                    that.treeSVG.makeDatSetTooltipShow(evt,colors,cdata,groups,externalID,'square')
                })
                rowplot.mouseout(function(evt) {
                    that.treeSVG.removeHighlightFromAllRowplotsByInternalLeafID(leaf_internal_id);
                    that.treeSVG.makeDatSetTooltipHide()
                })
                /**
                 *  assemble data for plot --
                 */
                var v2as = []; //ABarPlotValueAndAttributes
                for (var idx = 0; idx < groups.length; idx++) {
                    // if array colors is shorter than plottypes, colors are
                    // recycled ;
                    // this might not be necessary --
                    var color = (colors != null)
                    ? (colors.length > idx) ? colors[idx] : colors[idx % colors.length]
                    : this.defaultColor;
                    var strokecolor = (strokecolors != null)
                    ? (strokecolors.length > idx) ? strokecolors[idx]
                        : strokecolors[idx % strokecolors.length]
                    : "";

                    if (strokecolor.length<=0 && defaultStrokeColor.length>=1
                    && !StringOps.equalsIgnoreCase( defaultStrokeColor,("none"))) {
                        strokecolor = defaultStrokeColor;
                    }

                    var current_value = cdata[idx]; // sep 28, 2014;

                    if (canMakeLogTransform) {
                        if (current_value != 0) {
                            current_value = Math.log(current_value);
                        }

                        if (current_value < 0) {
                            current_value = 0.01; // will still plot something there ...
                        }
                    } //

                    var barwidth = (current_value - 0) * this.pwidth_per_value; // why - 0 here??? ???

                    if (barwidth < 1) {
                        barwidth = 1;
                    }
                    
                    var abr = new ABarPlotValueAndAttributes()
                    abr.loadAttributes(barwidth, current_value, color, strokecolor ) 
                    v2as.push( abr );
                    // console.log('abr here ',abr,current_value,this.pwidth_per_value)
                }
                
                /**
                 * reorder values if necessary --
                 */
                if( this.ndCon.getBarRowdataReorderStyle() === ("asc") ) {                    
                    v2as.sort((o1,o2)=>{
                        return o1.getBarWidth() < o2.getBarWidth() ? -1 
                                         : o1.getBarWidth() > o2.getBarWidth() ? 1 
                                                 : 0 
                    })
                } else if ( this.ndCon.getBarRowdataReorderStyle() === ("desc") ) {                    
                    v2as.sort((o1,o2)=>{
                        return o2.getBarWidth() < o1.getBarWidth() ? -1 
                                     : o2.getBarWidth() > o1.getBarWidth() ? 1 
                                             : 0 
                    })
                }

                // plot the bars ... 
                for (var idx = 0; idx < groups.length; idx++) {
                    var abp = v2as[idx];
                    var color = abp.getFillColor();
                    var strokecolor = abp.getStrokeColor();
                    var barwidth = abp.getBarWidth();
                    var current_value = abp.getBarWidwhRealValue();
                    
                    // check different plot types
                    if (!StringOps.equalsIgnoreCase( color,("white"))) {
                            /**
                             * plot, make a fan plot or bar plot depends on user's options
                             */
                            if (this.ndCon.isFanplot() && (this.plotmode === (treePlotMode.CIRCULAR_CLADOGRAM)
                                || this.plotmode === (treePlotMode.CIRCULAR_PHYLOGRAM))) {
                                var innerRadius = start_pos - this.rootXY.x;
                                var outterRadius = innerRadius + barwidth;
                                var fan = this.makeFanPlot(this.rootXY, innerRadius, outterRadius, this.fangle * 2,rowplot); // may
                                if (strokecolor.length>=1 && ! StringOps.equalsIgnoreCase(strokecolor,("none"))) {
                                    fan.attr("stroke", strokecolor);
                                    fan.attr("stroke-width", strokewidth + "");
                                }
                                fan.attr("fill", color);
                                /*
                                    * make rotate transform for fan
                                    */
                                if ((this.plotmode == treePlotMode.CIRCULAR_CLADOGRAM
                                    || this.plotmode == treePlotMode.CIRCULAR_PHYLOGRAM)) {            
                                    fan.transform({rotation: -angle, cx: this.rootXY.x, cy:this.rootXY.y})
                                }
                            } else {            
                                var rect = rowplot.rect(barwidth, itemHeightPixel).move(start_pos,startpos.y - itemHeightPixel / 2)
                                rect.attr("fill", color);
                                if (strokecolor.length>=1 && ! StringOps.equalsIgnoreCase(strokecolor,("none"))) {
                                    rect.attr("stroke", strokecolor);
                                    rect.attr("stroke-width", strokewidth + "");
                                }
                                if ((this.plotmode === (treePlotMode.CIRCULAR_CLADOGRAM)
                                    || this.plotmode === (treePlotMode.CIRCULAR_PHYLOGRAM))) {
                                    // if circular mode and angle is not zero; May 17, 2011; this part was moved
                                    // from the begining of the outter 'for' loop, otherwise the barplots are not
                                    // shown correctly                
                                    rect.transform({rotation:180 - angle, cx:this.rootXY.x, cy:this.rootXY.y})
                                }
                            } // if circular mode

                            /**
                             * show data, if users choose to do so; sep 28, 2014; show data will be added to
                             * showdataSVGGelement
                             */
                            if (this.ndCon.isbShowData()) {
                                // create a svg text object and add it to rowplot                                
                                var txtData = showdataSVGGelement.text(current_value + "")
                                txtData.font({anchor:this. ndCon.getShowDataTextAlign()})
                                var textanchor = this.ndCon.getShowDataTextAlign();
                                var x = StringOps.equalsIgnoreCase(textanchor,("start")) ? start_pos + 2
                                    : StringOps.equalsIgnoreCase(textanchor,("end")) ? start_pos - 2 + barwidth
                                    : start_pos + barwidth / 2;
                                var y = startpos.y + this.ndCon.getShowDataFontSize() * 1 / 3;
                                txtData.move(x,y-10)

                                showdataSVGGelement.front()

                                // if circular mode
                                if ((this.plotmode == treePlotMode.CIRCULAR_CLADOGRAM
                                    || this.plotmode == treePlotMode.CIRCULAR_PHYLOGRAM)) {

                                    txtData.transform({rotation:180-angle, cx:this.rootXY.x, cy:this.rootXY.y})

                                    // for better readability
                                    if ((angle >= 270 && angle <= 360) || (angle >= 0 && angle <= 90)) {
                                        // change y --
                                        y = startpos.y - this.ndCon.getShowDataFontSize() * 1 / 3;
                                        txtData.y(y);

                                        // switch start <=> end
                                        textanchor = StringOps.equalsIgnoreCase(textanchor,("start")) ? "end"
                                        : StringOps.equalsIgnoreCase(textanchor,("end")) ? "start" : textanchor;
                                        txtData.font({anchor:this. textanchor})

                                        // console.log(angle,x,y)
                                        // rotate
                                        // txtData.transform({rotation:180, cx:x, cy:y})
                                        txtData.rotate(360)
                                    }
                                }
                            } // if show data
                    } // if color isn't default color
                    // increase start_pos incremetally; March 24, 2011;
                    start_pos += (this.ndCon.isAlignindividualcolumn()) ? (this.plot_width_per_subpanel + this.space_between_columns_within_a_dataset) : barwidth;
                } // multi column plot
            } // if data is valid for current leaf node
        } // iterate hashPositionOfLeafNodes

        // this will be added at the end so that it'll appear on the top
        // of other layers ...

        // at the end, update currentMostRightPosition
        var plotwidth = this.ndCon.isAlignindividualcolumn()
            ? groups.length * this.plot_width_per_subpanel
            + (groups.length - 1) * this.space_between_columns_within_a_dataset
            : this.plotWidth;
        this.currentMostRightPosition += (plotwidth + this.space_between_datasets);
        // console.log('update right pos ',plotwidth,this.currentMostRightPosition, this.space_between_datasets,this.datasetID)
        } else {
            Point.deleteChildNodeFromLayerTree(this.layer);
            Point.deleteChildNodeFromLayerTree(this.layer_barplot_gridlabels);
            Point.deleteChildNodeFromLayerTree(this.layer_barplot_grid);
        } // is active
    } // makeorupdate plot

    makeFanPlot(center, innerRadius, outterRadius,angleSpan, layerGroup){
        var p1 = Point.getCoordinatesOnCircle(center, innerRadius, 360 - angleSpan / 2);
		var p2 = Point.getCoordinatesOnCircle(center, outterRadius, 360 - angleSpan / 2);
		var p3 = Point.getCoordinatesOnCircle(center, outterRadius, angleSpan / 2);
		var p4 = Point.getCoordinatesOnCircle(center, innerRadius, angleSpan / 2);
        
        var pathSeg = new Point()
        pathSeg.appendSegItem(Point.createPointStr(p1.x, p1.y))
        pathSeg.appendSegItem(Point.createPointStr(p2.x, p2.y,'L'))
        pathSeg.appendSegItem(Point.createArcStr(p3.x, p3.y,outterRadius,0,angleSpan >= 180 ? 1 : 0, 0)) //arc
        pathSeg.appendSegItem(Point.createPointStr(p4.x, p4.y,'L'))
        pathSeg.appendSegItem(Point.createPointStr(p4.x, p4.y,'M'))
        pathSeg.appendSegItem(Point.createArcStr(p1.x, p1.y,innerRadius,0,angleSpan >= 180 ? 1 : 0, 1)) //arc
        return layerGroup.path(pathSeg.getSegPathListStr())
    }

    /**
	 * *********************************** make a new function for
	 * plotting gridlines for all plots >>>> Aug 13, 2017; ++ make grid label to be
	 * parallel to grid line under circular mode -- ++ add support for user-supplied
	 * grid and label ++ add support for mouse-over/out events （不知为何在chrome下不工作） ++
	 * ************************************
	 */
	drawGridLinesAndLabels( groupSize, gridlineCount, maxvalue, plotstart,
        widthPerValue, pixPerProtein, plot_width_per_subpanel, layer_grid,
          layer_gridlabel,  isLogTransform, hmUserInputGrids,
         hmGridShadeOptions) {
     
       // get leaf information
       var firstleaf_id = this.phylotree.getFirstLeafNode().getInternalID();
       var lastleaf_id = this.phylotree.getLastLeafNode().getInternalID();
     
       var firstleafPos = this.LeafInternalID2NodePosisionts[firstleaf_id];
       var lastleafPos = this.LeafInternalID2NodePosisionts[lastleaf_id];
     
       var anglespan_per_leaf_label = this.getAngleSpan() / this.phylotree.getLeafNodes().length;
       var anglespan_of_leaf_lables = this.getAngleSpan() - anglespan_per_leaf_label;
     
       // grid shade options --
       var bGridShadeDataValid = hmGridShadeOptions != null && hmGridShadeOptions.length >=1;
     
       /**
        * 计算 grid 画的位置 Assemble positions at which grid lines to be
        * plotted ...
        */
       var gridValues = [];
     
       var isUserInputGridsAvailable = false;
       if (hmUserInputGrids != null && hmUserInputGrids.length >=1) {
         for (var f in hmUserInputGrids) {
           gridValues.push(hmUserInputGrids[f]); // no sanity check --
         }
       }
     
       if (gridValues.length<=0) {
         // nice axis, [ start, end, step ]
        var niceaxis = StringOps.AxisStartEndStep(0, maxvalue, gridlineCount);
         for (var f = niceaxis[0]; f < niceaxis[1]; f += niceaxis[2]) {
           gridValues.push(f);
         }
       } else {
         isUserInputGridsAvailable = true;
       }
     
       if (isLogTransform) {
         maxvalue = Math.log(maxvalue);
       }
     
       /**
        * plot real start / end in pixel --
        */
       var plotstart_current_group = plotstart; // 0
     
       /*
        * refine grid and axis add numbers for grid lines >>> 
        */
       for (var g = 0; g < groupSize; g++) {
         for (var grididx = 0; grididx < gridValues.length; grididx++) {
           var gridValue = gridValues[grididx];
           var canPlotShaddedStripBetweenGrids = grididx % 2 == 0 && (gridValues.length - grididx) > 1;
           
           var gridlabel = isUserInputGridsAvailable ? hmUserInputGrids[gridValue]
               : ( gridValue == gridValue) ? gridValue+""
                   : gridValue+"";
     
           if (isLogTransform) {
             if (gridValue != 0) {
               gridValue = Math.log(gridValue);
             }
             if (gridValue < 0) {
               gridValue = 0;
             }
           }
     
           var plotPosInPixel = (plotstart_current_group + gridValue * widthPerValue);
     
           // String color = grididx == 0 ? "grey" : "grey"; // make them all
           // grey
     
           var next_gridValue = 0, next_plotPosInPixel = 0;
           var next_gridlabel = "";
           if (canPlotShaddedStripBetweenGrids && bGridShadeDataValid) {
             next_gridValue = gridValues[grididx + 1];
             next_gridlabel = isUserInputGridsAvailable ? hmUserInputGrids[next_gridValue]
                 : (next_gridValue == next_gridValue) ? ( next_gridValue+"")
                     : (next_gridValue+"");
     
             // transform
             if (isLogTransform) {
               if (next_gridValue != 0) {
                 next_gridValue = Math.log(next_gridValue);
               }
               if (next_gridValue < 0) {
                 next_gridValue = 0;
               }
             }
     
             next_plotPosInPixel = (plotstart_current_group + next_gridValue * widthPerValue);
           }
     
           // if circular mode
           if ((this.plotmode === (treePlotMode.CIRCULAR_CLADOGRAM)
               || this.plotmode === (treePlotMode.CIRCULAR_PHYLOGRAM))) {
             var aradius = plotPosInPixel - this.rootXY.x;
     
             var startpoint = Point.getCoordinatesOnCircle(this.rootXY, aradius, firstleafPos.angle);
             var endpoint = Point.getCoordinatesOnCircle(this.rootXY, aradius, lastleafPos.angle);
     
             var sweepFlag = this.treeSVG.circular_tree_plot_in_clockwise_mode;
             var large_arc = (anglespan_of_leaf_lables >= 180) ? 1 : 0; // use global
                                                 // angle_span?
     
             // boolean largeArcFlag = angle >= 180 ? true : false;
             var segs = new Point();
             segs.appendSegItem(Point.createPointStr(startpoint.x, startpoint.y));
             segs.appendSegItem(Point.createArcStr(endpoint.x, endpoint.y, aradius, 0,
                 large_arc, sweepFlag ? 1 : 0));
             const path = layer_grid.path(segs.getSegPathListStr());
     
             /**
              * ******************************************************************** 画 扇面，位于
              * 0~1， 2~3， 4~5
              * ********************************************************************
              */
             if (canPlotShaddedStripBetweenGrids && bGridShadeDataValid) {
               var next_aradius = next_plotPosInPixel - this.rootXY.x;
               var next_startpoint = Point.getCoordinatesOnCircle(this.rootXY, next_aradius,
                   firstleafPos.angle);
               var next_endpoint = Point.getCoordinatesOnCircle(this.rootXY, next_aradius, lastleafPos.angle);
     
               //
               var fansegs = new Point();
     
               fansegs.appendSegItem(Point.createPointStr(startpoint.x, startpoint.y)); // move to p1
               fansegs.appendSegItem(
                   Point.createPointStr(next_startpoint.x, next_startpoint.y,'L')); // line from p1 to p2;
               fansegs.appendSegItem(Point.createArcStr(next_endpoint.x, next_endpoint.y,
                   next_aradius, 0, large_arc, sweepFlag ? 1 : 0)); // arc from p2 to p3
               fansegs.appendSegItem(Point.createPointStr(endpoint.x, endpoint.y,'L')); // line from p3 to p4;
               fansegs.appendSegItem(Point.createSVGPathSegArcAbs(startpoint.x, startpoint.y,
                   aradius, 0, large_arc, !sweepFlag ? 1 : 0)); // arc from p4 to p1

               const shaddedfan = layer_grid.path(fansegs.getSegPathListStr());
               // fill color
               shaddedfan.attr("fill", hmGridShadeOptions["fill"]);
               shaddedfan.attr("fill-opacity", hmGridShadeOptions["opacity"]);
     
               const msg = gridlabel + " -- " + next_gridlabel;
             }
     
             /**
              * ***************************************************** 画 grid label ...
              * *****************************************************
              */
             var angle = this.treeSVG.circular_tree_plot_in_clockwise_mode ? lastleafPos.angle
                 : firstleafPos.angle;
     
             // some variables --
             var current_s, r2Angle, y;
             var textanchor;
     
             if (angle > 0 && angle < 180) {
               current_s = plotPosInPixel - 10;
               r2Angle = 270;
               textanchor = "start";
               y = lastleafPos.pt.y + this.treeSVG.default_tick_label_font_size * 0.33;
             } else {
               current_s = plotPosInPixel + 10;
               r2Angle = 90;
               textanchor = "end";
               y = lastleafPos.pt.y + this.treeSVG.default_tick_label_font_size * 0.33;
             }
     
            // text label
            const txtGridLabel = layer_gridlabel.text(gridlabel).move(current_s-10, y+10)
            txtGridLabel.font({size:this.treeSVG.default_tick_label_font_size});
            txtGridLabel.font({anchor:textanchor}); //Align center     
             
            var textpoint = this.treeSVG.circular_tree_plot_in_clockwise_mode ? endpoint : startpoint;
            // txtGridLabel.transform({rotation:r2Angle, cx:textpoint.x, cy:textpoint.y})
            txtGridLabel.font({anchor:StringOps.equalsIgnoreCase(textanchor,("start")) ? "end" : "start"})
     
            if (angle != 0) {
               txtGridLabel.transform({rotation:180-angle, cx:this.rootXY.x, cy:this.rootXY.y})
            } // circular
     
            
            txtGridLabel.mouseover(function(){
                path.attr("stroke", "red");
                path.attr("stroke-width", "0.5px");
                this.attr("fill", "red");
            })
            txtGridLabel.mouseout(function(){
                path.attr("stroke",null);
                path.attr("stroke-width",null);
                this.attr("fill",null);
            })
    
            path.mouseover(function() {
                this.attr("stroke", "red");
                this.attr("stroke-width", "0.5px");
                 txtGridLabel.attr("fill", "red");
            })
            path.mouseout(function() {
                this.attr("stroke",null);
                this.attr("stroke-width",null);
                txtGridLabel.attr("fill",null);
            })     
           } else {
     
             // 非圆形 mode --
     
             var y = lastleafPos.pt.y + pixPerProtein;
     
             var txtGridLabel = layer_gridlabel.text(gridlabel).move(plotPosInPixel, y)             
             txtGridLabel.font({size:this.treeSVG.default_tick_label_font_size});
             txtGridLabel.attr("text-anchor", "middle"); // Align center
     
             const line = layer_grid.line(plotPosInPixel,
                firstleafPos.pt.y - pixPerProtein * 0.8 / 2, plotPosInPixel,
                lastleafPos.pt.y + pixPerProtein * 0.8 / 2)
     
             /**
              * ******************************************************************** 画
              * rect，位于 0~1， 2~3， 4~5 之间 ...
              * ********************************************************************
              */
             if (canPlotShaddedStripBetweenGrids && bGridShadeDataValid) {
               //
               const shaddedrect = layer_grid.rect(next_plotPosInPixel - plotPosInPixel,
                lastleafPos.pt.y - firstleafPos.pt.y + pixPerProtein * 0.8).move(plotPosInPixel,
                    firstleafPos.pt.y - pixPerProtein * 0.8 / 2).radius(0,0)     
               shaddedrect.attr("fill", hmGridShadeOptions["fill"]);
               shaddedrect.attr("fill-opacity", hmGridShadeOptions["opacity"]);
     
               const msg = gridlabel + " -- " + next_gridlabel;
             }
     
             /**
              * mouse events -- 老鼠事件！！！; 
              */
             txtGridLabel.mouseover(function(){
                line.attr("stroke", "red");
                line.attr("stroke-width", "0.8px");
                this.attr("fill", "red");
             })
             txtGridLabel.mouseout(function(){
                line.attr("stroke", null);
                line.attr("stroke-width", null);
                this.attr("fill", null);
             })

             line.mouseover(function(){
                this.attr("stroke", "red");
                this.attr("stroke-width", "0.8px");
                txtGridLabel.attr("fill", "red");
             })
             line.mouseout(function(){
                this.attr("stroke", null);
                this.attr("stroke-width", null);
                txtGridLabel.attr("fill", null);
             })
           }
         } // s
         plotstart_current_group += (plot_width_per_subpanel + this.space_between_columns_within_a_dataset);
       } // for
     }
}
export default Bars


