import ColorUtils from '../utils/ColorUtils'
import StringOps from '../utils/StringOps'
import ItemAttributesBase from './ItemAttributesBase'

class ColorAttributes extends ItemAttributesBase{

    constructor(options = {}) {//{ attribute = "", col = '',scol = '' } = {}){
        super()
        this.colU = new ColorUtils();
        if(options.attribute != undefined && options.attribute.length >= 1){
            this.setColorAttributesByString(options.attribute);
        }
        if(options.col != undefined && options.col.length >= 1){
            this.setColor(options.col);
            this.setStrokeColor(options.scol);
        }
    }

	setColorAttributeObj( ex ){
		this.setColor(ex.getColor());
		this.setStrokeColor(ex.getStrokeColor());
	}

    setColorAttributesByString( colorattr ){
		var es = StringOps.JsArrayStringToArrayList(
            StringOps.splitStringBySeperator(colorattr, ":"));
		
		var length = es.length;
		if( length >= 1 ){
			this.setColor( StringOps.JsReplaceSpacesAndIllegalCharactersWithSingleSpaceChar( es[0] ) ); // remove all spaces ...  
		}
		if( length >= 2 ){
			var strokecolor = es[1].trim();
			if( StringOps.equalsIgnoreCase(strokecolor,"darker")){
				strokecolor = this.colU.ChangeColorBrightness( this.getColor(), -0.2 );
			}  else if( StringOps.equalsIgnoreCase(strokecolor,"lighter")|| StringOps.equalsIgnoreCase(strokecolor,"brighter") ){
				strokecolor = this.colU.ChangeColorBrightness( this.getColor(), 0.2 );
			}
			this.setStrokeColor( strokecolor );
		}
	}
}

export default ColorAttributes