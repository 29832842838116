/* eslint-disable no-constant-condition */
import PhyNode from "./PhyNode"
import StringOps from "../utils/StringOps";
import PhyloParser from './PhyloParser'

var ignoreCase = require('ignore-case');
var XMLWriter = require('xml-writer');

class PyTree{
    constructor (treename, format, treestr) {
        this.treeName = treename
        this.treeStr = treestr
        this.treeFormat = format
        this.internalID = ""
        this.description = ""
        this.errormessage = ""
        this.hasBootStrap = false
        this.hasBranchlength = false
        this.isValidDataset = true
        this.isTreeEndWithSemiColon = false
        this.hasMultipleBoostrap = false;
        this.serial_internal_node = 1
        this.serial_leaf_node = 0

        this.hashID2Nodes = {}
        this.hsInternalID2externalID = {}

        this.maxRootToTipTotalBranchLength = 0
        this.regExpFloat = new RegExp("(\\d+\\.?\\d*)");
	    this.regBranchLengthAtEnd 	= new RegExp(":([+\\-]?(?:0|[1-9]\\d*)(?:\\.\\d*)?(?:[eE][+\\-]?\\d+)?)$"); // match to both float and scientific numbers --
	    this.matchLeftBracket 	= new RegExp("\\[");

        this.totalNodes = 0
        this.allNodes = []
        this.leafNodes = []

        this.rootNode = ''
        if(treestr.length > 0){
        	this.setTree(treename, treestr, format)
		}
    }

    setTree(treename, treestr, format){
        this.allNodes = [];
		this.leafNodes = [];
		this.rootNode = '';

		// error messages
		this.errormessage = "";

		// booleans
		this.hasBootStrap = false;
		this.hasBranchlength = false;
		this.isValidDataset = true;
		this.isTreeEndWithSemiColon = false;

		// ints; May 30, 2012: 
		this.serial_internal_node = 1;
		this.serial_leaf_node = 0;

		// hashs
		this.hashID2Nodes = {};
		this.hsInternalID2externalID = {};

		// floats
		this.maxRootToTipTotalBranchLength = 0;

		/*
		 * then parse the tree string
		 */

		this.treeName = treename;
		this.treeString = treestr;
		this.treeFormat = format;

        this.rootNode = this.makeNewInternalNode("", "INT" + this.serial_internal_node, true, null);

        if (format === ("newick") || format ===("newick(also phylip)")) { // currently only newick trees are supported
			treestr = StringOps.JsRemoveNewLines(treestr);

			/**
			 * first of all, check if the tree is valid
			 */
			var iBrackets = 0;
			for (var i = 0; i < treestr.length; i++) {
                var c = treestr.charAt(i)
				if (c == '(') {
					iBrackets++;
				} else if (c == ')') {
					iBrackets--;
				}
			}//

			if (iBrackets != 0) {
				this.isValidDataset = false;
				this.errormessage = "the numbers of '(' and ')' do not match, please check your tree!!";
			} else {
				this.newickParser(treestr.replace(/'/g,""), null, this.rootNode);
			}
		} else if (format === ("nhx")) {
			treestr = StringOps.JsRemoveNewLines(treestr);
			this.nhxParser(treestr.replace(/'/g,""));
		} else if (format === ("nexus")) {
			this.nexusParser(treestr.replace(/'/g,""));
		} else if (format === ("phyloXML")) {
			treestr = StringOps.JsRemoveNewLines(treestr);
			this.phyloXMLParser(treestr);
        }

        if (this.isValidDataset) { // if it is still true
			if (this.allNodes.length < 3) {
				this.isValidDataset = false;
				this.errormessage = "at least THREE nodes (two leaves and one internal node) are required ";
			} else if (this.isTreeEndWithSemiColon == false) {
				this.isValidDataset = false;
				this.errormessage = "tree doesn't end with a ';', please check";
			} else {

				/*
				 * check intergrety of current tree a tree is valid if only : 1. all
				 * nodes have valid/ non-null ID 2. all nodes have unique ID
				 */
			}
			/*
			 * after successfully loading the tree, calculate height (max
			 * distance to tip) and distance to root
			 */
			this.hasBranchlength = this.hasBranchlen(); // this goes first!!

			this.reCalcDistanceToRoot();
			this.reCalcMaxDistanceToTip();
			this.reCalcLevels(); //actually this function can be intergrated with reCalcMaxDistanceToTip or reCalcDistanceToRoot; but I don't want the mix thing tegother
			this.reDoID2Node();
		}else{// if tree is valid
			console.log(this.isValidDataset,this.errormessage)
		}
        // console.log(this.rootNode)
    }

	detectTreeFormat(treeStr){
		if(treeStr.startsWith("#NEXUS")){
			return {'isTree':true,'format':'nexus'}
		}else if(treeStr.startsWith("<?xml")){
			return {'isTree':true,'format':'phyloXML'}
		}else{
			this.setTree('test',treeStr,'nhx')
			if(!this.isValidDataset){
				this.setTree('test',treeStr,'newick')
				if(!this.isValidDataset){
					return {'isTree':false,'format':'unknown'}
				}else{
					console.log(this.rootNode)
					return {'isTree':true,'format':'newick'}
				}
			}else{
				console.log(this.rootNode,this.allNodes.length)
				return {'isTree':true,'format':'nhx'}
			}
		}		
	}
    
    reCalcDistanceToRoot() {
		/*
		 * recalculate distance_to_root for each node; distance to root is the
		 * total branch length from a given node to the root I use a nested
		 * function to do the calculation
		 *
		 * NOTE: this program will continue if only there is valid branchlength
		 */

		if (this.hasBranchlength == false) {
			return;
		}

		this.calcDistanceToRoot(this.rootNode, 0);
    }
    
    calcDistanceToRoot( node,  accumulated_distance) {
		var branchlength = node.isRoot ? 0 : node.getBranchLength(); // Feb 28, 2011; bug fix
		accumulated_distance += branchlength;
		node.setBranchLengthToRoot(accumulated_distance);

		if (node.isleaf() || node.isCollapse()) {
			return;
		}

		for (var ind in node.getDescendents()) {
            var descendent_node = node.getDescendents()[ind]
			this.calcDistanceToRoot(descendent_node, accumulated_distance);
		}
	}

	reCalcMaxDistanceToTip() {
		/*
		 * recalculate height for each node; height is the max branchlength to
		 * get to the tip; start with leaf nodes; calculate accumulative branch
		 * length from it to internal nodes
		 */

		if (this.hasBranchlength == false) {
			return;
		}

		for (var ind in this.allNodes) {
            var pnode = this.allNodes[ind]
			if (pnode.isleaf() || pnode.isCollapse() ) { // start with leaf node only
				this.calcMaxDistanceToTip(pnode, 0);
			}
		}

    }
    
    calcMaxDistanceToTip( pnode,  accumulated_height) {
		if (pnode.getMaxDistanceToTip() < accumulated_height) {
			pnode.setMaxDistanceToTip(accumulated_height);
		}

		if (pnode.isroot() == true || pnode.getParent() == null) { // quite if only current node is root or it has no parent
			return;
		}

		accumulated_height += pnode.getBranchLength();

		this.calcMaxDistanceToTip(pnode.getParent(), accumulated_height);

		this.maxRootToTipTotalBranchLength = accumulated_height;
	}

    reCalcLevels() {
		/** 
		 * calculate horizontal and vertical levels for internal nodes, 
		 * + vertical level = (min(levels of all descendents) + max(levels of all descendents)) / 2; 
		 * 
		 * + horizontal level = max(levels of all descendents) + 1; therefore root has the max horizontal level
		 * + leaf has horiz level of 1??  
		 *
		 * jan 7, 2011; add level_vertical_slanted = (min(levels of all
		 * descendents) + (max(levels of all descendents)) - min) / 2;
		 */
		/*
		 * parameters are: node, array ref to hold horizontal levels of all its
		 * descendents array ref to hold vertical levels of all its descendents
		 * array ref to hold horizontal levels of its parent array ref to hold
		 * vertical levels of its parent array ref to hold vertical levels of
		 * all its leaf descendents of the parent node array ref to hold
		 * vertical levels of all its leaf descendents of the current node
		 */
		this.calcLevels(this.rootNode, [], [], [], [], 
				[], []);
    }

    calcLevels(node,horiz,verti,horiz_parent, verti_parent,
         verti_leaf_only_parent,  verti_leaf_only_current) {
    // do nothing is current node is leaf or collapsed node ... 
    if ( node.isleaf() || node.isCollapse() ) {
        return; // do nothing
    }

    // if current node is an internal node; go through its descendents
    for( var ind in node.getDescendents() ) {
        var d = node.getDescendents()[ind]
        if ( d.isleaf() || d.isCollapse() ) { // Dec 13, 2015
            horiz.push(d.getLevelHorizontal());
            verti.push(d.getLevelVertical());

            verti_leaf_only_current.push(d.getLevelVertical()); // jan 7, 2011
        } else {
            /*
             * call this function itself, be careful always;
             */
            this.calcLevels(d, [], [], horiz, verti, verti_leaf_only_current, []); // call this function itself
        }
    }

    /**
     * now we've obtained horizontal and vertical levels for all
     * descendents, I'll do: a. calculate and save vertical and horizontal
     * level for current node b. return the two values to the parent node,
     * because these values are necessary to calculate corresponding levels
     * of the parent.
     */
    // calculate vertical level for current node and add the value to its parent node
    var current_vertical_level = (Math.min(...verti) + Math.max(...verti)) / 2;
    node.setLevelVertical(current_vertical_level);
    verti_parent.push(current_vertical_level);

    // calculate horizontal level for current node and add the value to its parent node
    var current_horizontal_level = Math.max(...horiz) + 1;
    node.setLevelHorizontal(current_horizontal_level);
    horiz_parent.push(current_horizontal_level);

    // jan 7, 2011; level_vertical_slanted; and min/max leaf vertical level for each internal nodes
    var min_leaf_vertial_level = Math.min(...verti_leaf_only_current);
    var max_leaf_vertial_level = Math.max(...verti_leaf_only_current);

    node.setMinLeafVerticalLevel(min_leaf_vertial_level);
    node.setMaxLeafVerticalLevel(max_leaf_vertial_level);

    node.setLevelVerticalSlanted((min_leaf_vertial_level + max_leaf_vertial_level) / 2);

    verti_leaf_only_parent.push(min_leaf_vertial_level);
    verti_leaf_only_parent.push(max_leaf_vertial_level);
    } // -- -- 

    reDoID2Node() {
    this.hashID2Nodes = []

    /*
     * at the end, go through all nodes and make a ID2Node hash
     */
    for (var ind in this.allNodes) {
        var node = this.allNodes[ind]
        var node_id = node.getID(); // NOTE: jan 25, 2011; rootNote usually doesn't have any ID
        var internalnode_id = node.getInternalID();

        if (node_id != null && node_id.trim().length > 0) {
            this.hashID2Nodes[node_id] =  node;

            // June 20, 2014 ; replace underlines in node_id so that queries like 'Homo sapiens' could match 'Homo_sapies'
            var alias = node_id.replace('_', ' ');
            this.hashID2Nodes[alias] =  node;
        }

        // internalID cannot be empty
        if (internalnode_id.trim().length > 0) {
            this.hashID2Nodes[internalnode_id]  = node;
        }
    }
	}
    
    newickParser(inputstr,hashTranslate,iNode){
        var tailString = "";
        inputstr = inputstr.trim()
        // console.log(inputstr,hashTranslate)
        if (inputstr) {
			// remove trailing ';'
			while (inputstr.endsWith(";")) {
				this.isTreeEndWithSemiColon = true; // is this really necessary??? 
				inputstr = inputstr.substring(0, inputstr.length - 1);
			}
			
			// walk backwards of the characters one by one
			for (let idx = inputstr.length - 1; idx >= 0; idx--) {
				if (inputstr.charAt(idx) == ')') {
					tailString = inputstr.substring(idx + 1);

					// change input str from (A,B,(C,D)E)F to A,B,(C,D)E
					inputstr = inputstr.substring(1, idx); // !!!!!
					break;
				}
			}
		} // if string is not empty
        // console.log(inputstr)

        if (tailString.length>=1) {
			// first of all, split string into two parts by ':'
			var parts = tailString.split(":");
			
			/**
			 *  deal with the first part
			 *  first, check case 8. Square brackets [&label=99]
			 */
			if (parts.length >= 1) {
				var part1 = parts[0].trim(); // trim ... 
				if (part1.length>=1) { // if not case 3
					if (this.matchLeftBracket.exec(part1) != null) { // get the float number from a string like this: [&label=99]
						var m = this.regExpFloat.exec(part1);
						if ((m != null)) {
							var bootstrap = parseFloat(m[0]);
							iNode.setBootStrap(bootstrap);
							this.hasBootStrap = true;
						}
					} else {
						var current_bootstrap = false;
						// check if it is a string or numeric 
						// if(!part1.includes('/')){
							try {
								var bootstrap = parseFloat(part1);
								// if success; case 2, 4
								if(!isNaN(part1)){
									// console.log('355',part1,bootstrap.isNaN,bootstrap)
									iNode.setBootStrap(bootstrap);
									this.hasBootStrap = true;
									current_bootstrap = true;
								}
							} catch(err){
								// if fail, i assume the string is internal note name; case 5, 7
								// iNode.setInternalID(part1);
								iNode.setID(part1); // previous internalID ...; june 20, 2014 
							}
						// }else{

						// }
						/**
						 * May 19, 2016; multiple bootstrap values ...
						 *  the input should look like: '100/1/0.99'
						 */
						if( !current_bootstrap ){ // if not yet parsed bootstrap values ... 
							var bootstraps = [];
							//1. remove any quote strings ',"
							var b = part1.replace("'|\"", "");
							var temAr = b.split('/')
							// 2. then try to split the string into different parts 
							for(var ind in temAr){
								var boot = temAr[ind]
								boot = boot.trim();
								try{
									var fBoot = parseFloat( boot );
									if(!isNaN(fBoot)){
										bootstraps.push(fBoot);
									}
								} catch (nfe ){
									// do nothing ...
								}
							}
							
							// 3. check how many bootstrap values parsed ... 
							if(bootstraps){
								if(!isNaN(bootstraps[0])){
									// console.log('388',bootstraps[0])
									iNode.setBootStrap(bootstraps[0]);
									this.hasBootStrap = true;
								}
								
								if( bootstraps.length > 1 && this.hasBootStrap){
									this.hasMultipleBoostrap = true;
									iNode.setArBootstrapValues( bootstraps );
								} // 
							}
						}
						
					}
				}// if part 1 is not empty
			}

			/**
			 * if there is a part 2 and it's not empty --
			 *   parts = tailString.split(":");
			 */
			if (parts.length >= 2) {
				var part2 = parts[1];
				if (part2) {
					if (this.matchLeftBracket.exec(part2) != null) { // if it is the itol style
						/**
						 * split it into two parts by '[', the first part should contain the branch lenth, while the second contains the bootstrap
						 * of cource, both could be empty,
						 * valid inputs are:
						 * :[] - both are empty,
						 * :[99]
						 * :0.456[]
						 * :0.456[99]
						 * NOTE: bootstrap value can also be float number
						 */
						var iparts = part2.split("\\[");
						if (iparts.length > 0) {
							// the first part: branch length
							var ipart1 = iparts[0];
							var m = this.regExpFloat.exec(ipart1);
							if ((m != null)) {
								var branchlength = parseFloat(m[0]);
								iNode.setBranchLength(branchlength);
								this.hasBranchlength = true;
							}// branch length

							// the second part, bootstrap
							if (iparts.length >= 2) {
								var ipart2 = iparts[1];
								var m2 = this.regExpFloat.exec(ipart2);
								if ((m != null)) {
									var bootstrap = parseFloat(m2.getGroup(0));
									iNode.setBootStrap(bootstrap);
									this.hasBootStrap = true;
								}
							} // bootstrap
						}
					} else {
						// parse branch length value; case 3,4,6,8
						try {
							var branchlength = parseFloat(part2);
							// if success; case 2, 4
							iNode.setBranchLength(branchlength);
						} catch (nfe) {
							// do nothing
						}
					}// if ... else ...
				} // if part2 is not empty
			}// if part2 is there
        }// if the string4internalNode string is not empty 
        
        		/**
		 * now go through what's between the parentheses and get the leaf nodes
		 *   (A,B,(C,D)E)F = original tree
		 *   A,B,(C,D)E = 'inputstr', the part the following codes will deal with
		 */
		if (inputstr) {
			/**
			 * split current input string into substrings, each is a daughter node of current internal node
			 * if your input string is like this: A,B,(C,D)E
			 * it will be split into the following three daughter strings:
			 *  A
			 *  B
			 *  (C,D)E
			 * accordingly, three daughter nodes will be created, two are leaf nodes and one is an internal node 
			 */
			var brackets = 0, leftParenthesis = 0, commas = 0;
			var sb = '';
            for (var i = 0; i < inputstr.length; i++) {
                var c = inputstr.charAt(i)
				if ( (c === ',' || c === ')') && brackets === 0) { // ',' usually indicates the end of an node; is || c == ')' really necessary ??? 
					// make daugher nodes
					var daughter = sb;
					
					/**
					 * Jan 14, 2016; check the last time of current node is leaf or interal 
					 */
					if (leftParenthesis > 0 && commas > 0 && daughter.includes(",")) {
						this.serial_internal_node++;
						this.newickParser(daughter, hashTranslate, this.makeNewInternalNode("", "INT" + this.serial_internal_node, false, iNode));
					} else {
						// a leaf daughter 
						this.serial_leaf_node++;
						// parse information for current daughter node
//						Log.debug("daughter is: " + daughter);
                        this.parseInforAndMakeNewLeafNode(daughter, hashTranslate, iNode);
					}
					
					// reset some variables
					sb = '';
					leftParenthesis = 0;
				} else {
					sb +=(c); // ',' will not be recored

					if (c === ',') {
						commas++;
					}
				}

				/**
				 *  brackets is used to find the contents between a pair of matching ()s
				 *  how does this work???
				 *  
				 *  here is how the value of brackets changes if your input string is like this :
				 *  (A,B,(C,D)E)F
				 *  1    2   1 0 # value of brackets ... 
				 *  +    +   - - # operation
				 *  ^          ^ # contents between these two () will be extracted = A,B,(C,D)E
				 *  
				 *  --- 
				 *  variable 'leftParenthesis' is used to indicate whether current daughter node is likely a internal node; 
				 *  however, this alone cannot garrentee this, because the name of a leaf node may contain Parenthesis
				 *  therefore I use 'leftParenthesis' and 'commas' together to indicate an internal node  
				 */
				if (c === '(') {
					brackets++;
					leftParenthesis++;
				} else if (c === ')') {
					brackets--;
				}
			}// 
				// deal with the last daughter 
			var daughter = sb;
			if (leftParenthesis > 0 && commas > 0 && daughter.includes(",")) {
				this.serial_internal_node++;
				this.newickParser(daughter, hashTranslate, this.makeNewInternalNode("", "INT" + this.serial_internal_node, false, iNode));
			} else {
				// a leaf daughter 
				this.serial_leaf_node++;
				// parse information for current daughter node
				this.parseInforAndMakeNewLeafNode(daughter, hashTranslate, iNode);
			}
		} /// new recursive parser 

    }

    makeNewInternalNode(id, internal_id, isroot, parentnode) {
		var newnode = new PhyNode();
		newnode.setInternalID(internal_id);
		newnode.setRoot(isroot);
		newnode.setLeaf(false);
		newnode.setID(id);

		//System.out.println(id + " = " + internal_id);

		// dec 5, 2011
		if (!isroot && parentnode != null) {
			newnode.setParent(parentnode);
			parentnode.addADescendent(newnode);
		}

		this.allNodes.push(newnode);
		this.hsInternalID2externalID[internal_id] =  id
		return newnode;
    }
    
    parseInforAndMakeNewLeafNode(leafstr, hashTranslate, iNode) {
		leafstr = leafstr.trim();
		/**
		 * parse a leaf node,
		 * possibilities are:
		 * 1. ,, - leaf node is not named (???)
		 * 2. A  - named leaf node
		 * 3. :0.1 - unamed leaf node with branch length
		 * 4. A:0.1 - named leaf node with branch length
		 */
		if (leafstr.length<1) { // case 1
			this.makeNewLeafNode("", 0, iNode, this.serial_leaf_node);
		} else {
			var branchlength 	= 0;
			var part1 		= leafstr; 
			
			/**
			 * ###########################################################################################
			 * ### June 2017; 19th, Monday --
			 * ###  leafstr may contain html code such as:
			 * 				<i style="color:red">Homo sapiens</i>
			 * 			therefore in order to split branch length correctly,  
			 * ###########################################################################################
			 */
			var m = this.regBranchLengthAtEnd.exec(leafstr); // float numbers & scientific numbers  --
            // console.log(m)
            if ((m != null)) {
				/**
				 * ---------------------------------------------------
				 *  how this works??
				 *  take the following input as an example:
				 *    Scer|<i style="color:red">YPL277C</i>:0.1234
				 *  
				 *  m.getGroup(0) 				== :0.1234
				 *  m.getGroup(1) 				== 0.1234 ; the bootstrap string 
				 *  substring(0, m.getIndex()) 	== Scer|<i style="color:red">YPL277C</i>; this is the string without branch length 
				 */
				
				try {
					branchlength = parseFloat( m[1] );
					this.hasBranchlength = true;
				} catch (nfe) {
					// do nothing
				}
				
				// after the branch length has been removed ...
				part1 = leafstr.substring(0, m.index );
			}

//
//			/**
//			 * now deal with part 1, two possibilities: named / unamed leaf node
//			 */
			if (part1.length<1) {
				this.makeNewLeafNode("", branchlength, iNode, this.serial_leaf_node);
			} else {
				var leafNodeName = part1.replace("'", "").replace("\"", "").trim();
//				Log.debug(part1 + " --> " + leafNodeName);
				
				leafNodeName = (hashTranslate != null && hashTranslate.hasOwnProperty(leafNodeName)) ? hashTranslate[leafNodeName] : leafNodeName;
				this.makeNewLeafNode(leafNodeName, branchlength, iNode, this.serial_leaf_node);
			}
		}
    }// end of parseInforAndMakeNewLeafNode
	
	setCollapseNodes(hmPhyloNode2Styles) {
		/**
		 * first of all, check if nodes is the same as the ones that are currently active 
		 */
		if( hmPhyloNode2Styles.length>=1 ){			
			// 1. set nodes status to collapse 
			for( var ind in this.allNodes ){
				var node = this.allNodes[ind]
				var hasStyle = this.hasNodeStyle(node,hmPhyloNode2Styles)
				node.setCollapse(hasStyle)
				// console.log(hasStyle,node)
			}
			
			// 2. remake essential variables ; double checked
			// this.allNodes.clear();
			this.leafNodes = [];
			this.reMakeEssentialVariables(this.rootNode, 0, false); 

			// 3. recaclulate some other variables; Dec 12, 2015;  
			this.reCalcDistanceToRoot();
			this.reCalcMaxDistanceToTip();
			this.reCalcLevels(); // recalculate horizontal and vertical levels			
		} // else do nothing ... 	
	}

	hasNodeStyle(node,hmPhyloNode2Styles){
		for(var ind in hmPhyloNode2Styles){
			var styleInfo = hmPhyloNode2Styles[ind]
			if(styleInfo.lca === node){
				return hmPhyloNode2Styles[ind].kvs
			}
		}
		return null
	}

	removeInternalCollapse() {
		// 1. set collapse to false for all nodes
		for( var ind in this.allNodes ){
			var node = this.allNodes[ind]
			node.setCollapse(null);
		}
		
		// 2. remake essential variables ; double checked
		//this.allNodes.clear();
		this.leafNodes = [];
		this.reMakeEssentialVariables(this.rootNode, 0, false);

		// 3. recaclulate some other variables; Dec 12, 2015;  
		this.reCalcDistanceToRoot();
		this.reCalcMaxDistanceToTip();
		this.reCalcLevels();
	}

    makeNewLeafNode(id, branch_length, parentnode, level_vertical) {
		var leafnode = new PhyNode();
		
		/**
		 * ===============================================================
		 * ## == 2017, strip off HTML codes, if there is any --
		 * ===============================================================
		 */
		var isHTML = /<\/?[a-z][\s\S]*>/i.test(id)
		var leafid2 = this.extractContent(id)//new HTML(id).getText();
		// console.log(leafid2,id)
		if( !StringOps.equalsIgnoreCase(leafid2,id) ){
			leafnode.setHTMLname( "<b>"+id+"<b>" );			
			id = leafid2;
		}

		leafnode.setLeaf(true);
		leafnode.setRoot(false);
		leafnode.setID(id);
		leafnode.setBranchLength(branch_length);
		
		/*
		 * level_vertical actually is the serial ID of leaf node; I use
		 * LEF_serial as internal ID of leaf nodes; Jan 14, 2011
		 */
		var internalid = "LEF_" + level_vertical;
		leafnode.setInternalID(internalid);

		/*
		 * horizontal and vertical levels; dec 29, 2010
		 */
		leafnode.setLevelHorizontal(1); // all leaf nodes have a horizontal level of 1
		leafnode.setLevelVertical(level_vertical); // leaf nodes have integer vertical levels starting with 1

		/*
		 * parental and descendental relationships
		 */
		leafnode.setParent(parentnode);
		parentnode.addADescendent(leafnode);

		/*
		 * add new leaf node
		 */
		this.leafNodes.push(leafnode);
		this.allNodes.push(leafnode);
		this.hsInternalID2externalID[internalid] =  id;
		
		return leafnode;
    }
	
	extractContent(s) {
		var span = document.createElement('span');
		span.innerHTML = s;
		return span.textContent || span.innerText;
	}

	/*
	 * Nov 28, 2011; nhx format see here for more details:
	 * http://phylosoft.org/NHX/ please note that using nhx is now discoraged;
	 * use phyloXML instead
	 *
	 * nhx format shares certain similarities with newick, so sode codes were
	 * copied from the newick parser
	 *
	 * a typical nhx tree would look like:
	 *
	 * (((ADH2:0.1[&&NHX:S=human:E=1.1.1.1],
	 * ADH1:0.11[&&NHX:S=human:E=1.1.1.1]):0.05[&&NHX:S=Primates:E=1.1.1.1:D=Y:B=100],
	 * ADHY:0.1[&&NHX:S=nematode:E=1.1.1.1],ADHX:0.12[&&NHX:S=insect:E=1.1.1.1]):0.1[&&NHX:S=Metazoa:E=1.1.1.1:D=N],
	 * (ADH4:0.09[&&NHX:S=yeast:E=1.1.1.1],ADH3:0.13[&&NHX:S=yeast:E=1.1.1.1],
	 * ADH2:0.12[&&NHX:S=yeast:E=1.1.1.1],
	 * ADH1:0.11[&&NHX:S=yeast:E=1.1.1.1]):0.1
	 * [&&NHX:S=Fungi])[&&NHX:E=1.1.1.1:D=N];
	 */
	nhxParser(treestr) {
		var name_str_backup = "", current_name_str = "";
		var current_internal_node = this.rootNode; // currrent internal node; set to rootnode
		var previous_chr = ' ';

		var isBranchLength = false, isFirstLeftParenthesis = true, isRightparenthesis = false;

		for (var idx = 0; idx < treestr.length; idx++) {
			var c = treestr.charAt(idx);
			if (c == '(') {
				/*
				 * possibilities: a. c is NOT the first '(', create a new
				 * internal node b. c is immediately after a ',', create a new
				 * internal node c. c is the first 'C', do nothing
				 */
				if (isFirstLeftParenthesis == false) { // a or b
					/*
					 * if not the first '(', the previous letter MUST BE ',' or
					 * '('
					 */
					if (previous_chr != '(' && previous_chr != ',') {
						this.errormessage = " pos:" + (idx - 1) + " '(' or ',' is expected, got '" + previous_chr + "'";
						this.isValidDataset = false;
					} else {
						/*
						 * make a new internal node
						 */
						this.serial_internal_node++;
						current_internal_node = this.makeNewInternalNode("", "INT" + this.serial_internal_node, false, current_internal_node);
					}
				} else {
					isFirstLeftParenthesis = false; // if true, set it to false
				}

				/*
				 * reset variables
				 */
				isRightparenthesis = false;

				current_name_str = "";
				name_str_backup = "";
			} else if (c == ':') {
				/*
				 * ':' indicates current tree has branch length
				 */
				isBranchLength = true;

				/*
				 * back up current name string and reset it
				 */
				name_str_backup = current_name_str;
				current_name_str = '';

			} else if (c == ',' || c == ';' || c == ')' || c == '[') {
				/*
				 * get branch length from current name string
				 */
				var branch_length = 0;

				if (isBranchLength == true) {
					try {
						branch_length = parseFloat(current_name_str.trim());
					} catch ( nfe) {
						branch_length = 0;
					}

					/*
					 * copy the value from backup
					 */
					current_name_str = name_str_backup;
				}

				/*
				 * >>>>> possibilities: >>>>> a. ,name:0.01, or ,name:0.01)
				 * create a new leaf node b. )name:0.01, or )name:0.01) save ID
				 * to current internal node and set parent as current internal
				 * node c. )name:0.01; save ID to internal node and end for loop
				 */

				// in case of a: name is a leaf node; make a new leaf node and add it to 'allNodes'
				if (!isRightparenthesis) {
					this.serial_leaf_node++;
					this.makeNewLeafNode(isBranchLength ? name_str_backup : current_name_str, branch_length, current_internal_node, this.serial_leaf_node);
				} else {
					// in cases of b: 
					/*
					 * set branch length
					 */
					current_internal_node.setID(current_name_str.trim());

					if (branch_length > 0) {
						current_internal_node.setBranchLength(branch_length);
					}

					/*
					 * if current node is internal, move one level up
					 */
					if (current_internal_node.isroot() == false) {
						current_internal_node = current_internal_node.getParent(); // change current_internal_node
					}
				}

				// 
				if (c == '[') {
					/*
					 * nhx style extra information -- just add the information
					 * to the last node, (leaf or internal)
					 */
					var first_colon = false;
					var pos_first_colon = 0;
					var nhx_string = '';

					while (true) {
						idx++;
						var ch = treestr.charAt(idx);
						if (ch == ':' && !first_colon) {
							first_colon = true;
							pos_first_colon = idx;
						}

						if (ch == ']') {
							idx++;
							c = treestr.charAt(idx); // get the char next to ']' and assign it to c
							break; // exit while loop
						}

						if (first_colon && idx > pos_first_colon) {
							nhx_string += (treestr.charAt(idx));
						}
					}

					// -- now parse nhx string --
					var nhx = nhx_string;
					if (nhx) {
						var node = this.allNodes[this.allNodes.length - 1];
                        var nhxAttributes = {};
                        var nhxAr = nhx.split(':')
						for (var ind in nhxAr) {
                            var keyvaluepair = nhxAr[ind]
							if (keyvaluepair.includes("=")) {
								var keyvalue = keyvaluepair.split("=");

								let k = keyvalue[0];
								let v = keyvalue[1];

								// set bootstrap to current node
								if (ignoreCase.equals(k,"B")) {
									this.hasBootStrap = true;
									var bootstrap = 0;
									try {
										bootstrap = parseFloat(current_name_str.trim());
									} catch (nfe) {
										bootstrap = 0;
									}

									node.setBootStrap(bootstrap);
								} else {
									nhxAttributes[k] =  v;
								}
							}
						}
						node.setAdditionalAttributes(nhxAttributes);
					}// if not empty

					// in cases like: )[&&NHX:S=Eukaryota:D=N:B=0];
					if (c == ';') {
						this.isTreeEndWithSemiColon = true;
						break; // exit?
					}
				}// if c== '['

				// reset string
				current_name_str = "";
				name_str_backup = "";

				// reset values
				//isBranchLength = false;

				if (c == ';') {
					this.isTreeEndWithSemiColon = true;
					break; // exit?
				}

				if (c == ')') {
					isRightparenthesis = true;
				} else {
					isRightparenthesis = false; // important
				}
			} else if (c != ' ') {
				current_name_str += c; // append c to current string
			}
			// Nov 07, 2011; blank letters are now allowed
			//if (c != ' ') {
			// keep track of previous char; blank letters are omitted
			previous_chr = c;
			//}
		} // for split tree string to char
	}

    nexusParser(treestr) {
		var hashTranslate = {};
		var bTranslate = false;
		var bBeginTrees = false;

		//System.out.println("======== parsing nexus format =============\nyour input is : " + treestr);
        // console.log('parsing nexus format ',treestr)
        var line_num = 0;
        var subAr = treestr.split('\n')
		for (var ind in  subAr) {
            var line = subAr[ind]
			line = line.trim();
            line_num++;
            if(line.length>=1){
                // console.log('925 ',line)
                if (line_num == 1 && !ignoreCase.equals(line,"#NEXUS")) {
                    this.errormessage = "input file doesn't start with #NEXUS!!";
                    this.isValidDataset = false;
                    break;
                } else if (line.toLowerCase().startsWith("Begin trees".toLowerCase())) {
                    bBeginTrees = true;
                } else if (line.toLowerCase().startsWith("Begin".toLowerCase()) && bBeginTrees) {
                    bBeginTrees = false;
                } else if ( ignoreCase.equals(line,"Translate")) {
                    bTranslate = true;
                } else if (ignoreCase.equals(line,";")) {
                    bTranslate = false;
                } else if (bBeginTrees && bTranslate) {
                    // split the translate part:
                    var parts = line.split(/\b(\s)/)//JSFuncs.JsArrayStringToArrayList( JSFuncs.splitStringBySpace(line) );
                    if (parts.length >= 2) {
                        var trans_str = "";
                        for (var i = 1; i < parts.length; i++) {
                            if (i > 1) {
                                trans_str += " ";
                            }
                            trans_str += parts[i].replace("'", "").replace(",", "") //JSFuncs.JsReplaceStringWithString(JSFuncs.JsReplaceStringWithString(parts.get(i), "'", ""), ",", "");
                        }
                        hashTranslate[parts[0].trim()] =  trans_str.trim();
                    }
                } else if (bBeginTrees && !bTranslate && line.toLowerCase().startsWith("tree".toLowerCase())) {
                    var substring_treestr = line.substring(line.indexOf("("));
                    /**
                     * first of all, check if the tree is valid
                     */
                    var iBrackets = 0;
                    // for (char c : substring_treestr.trim().toCharArray()) {
                    for (var idx = 0; idx < substring_treestr.trim().length; idx++) {
                        var c = substring_treestr.charAt(idx);
                        if (c == '(') {
                            iBrackets++;
                        } else if (c == ')') {
                            iBrackets--;
                        }
                    }//
                    // console.log(substring_treestr)
                    if (iBrackets != 0) {
                        this.isValidDataset = false;
                        this.errormessage = "the numbers of '(' and ')' do not match, please check your tree!!";
                    } else {
                        this.newickParser(substring_treestr, hashTranslate, this.rootNode);
                    }
                    break; // only the first tree will be taken
                }
            }
		}// for each line
    }// nexusParser
	
	phyloXMLParser(treestr){
		this.parseXML = new PhyloParser()
		var isTreeValid = this.parseXML.xmlToJson(treestr)
		if(isTreeValid != null && isTreeValid){
			this.isTreeEndWithSemiColon = true
			var phylogeny = this.parseXML.getElementsByTagName("phylogeny");
			if(phylogeny != null){
				var childnodes = this.parseXML.getChildNodes(phylogeny);
				if(childnodes != null){
					if(this.parseXML.isRooted(phylogeny)){
						for (var keys in childnodes) {
							if(keys === 'clade'){
								childnodes = childnodes[keys]
								break
							}
						}					
					}else{
						for (var keys in childnodes) {
							if(keys === 'clade'){
								childnodes = childnodes[keys]
								break
							}
						}
					}
					// console.log(childnodes)
					this.phyloXMLparserNodesIterator(this.rootNode, childnodes);
				}
			}else{
				this.errormessage = "no 'phylogeny' tag found in your xml file, check your input";
				this.isValidDataset = false;
			}
		}else{
			this.errormessage = this.parseXML.getParseError()
			this.isValidDataset = false;
		}
		// if(treeJson)
	}

	phyloXMLparserRootIterator(parentnode,nodes){
		var branch_length = nodes['branch_length']
		var bootstrap = nodes['confidence']
		if(bootstrap != null){
			bootstrap = parseInt(bootstrap.text)
		}
		if(branch_length != null){
			branch_length = parseInt(branch_length)
		}
		// this.serial_internal_node++
		parentnode = this.makeNewInternalNode('', "INT" + this.serial_internal_node, false, parentnode);
		if(branch_length != null &&  branch_length > -1){
			this.hasBranchlength = true;
			parentnode.setBranchLength(branch_length);
		}
		if (bootstrap != null) {
			this.hasBootStrap = true;
			parentnode.setBootStrap(bootstrap);
		}
		this.phyloXMLparserNodesIterator(parentnode,nodes['clade'])				
	}

	phyloXMLparserNodesIterator(parentnode,nodes){
		// console.log(parentnode)
		if(Array.isArray(nodes)){
			for(var ind in nodes){
				var node = nodes[ind]
				var branch_length = node['branch_length']
				var bootstrap = node['confidence']
				// console.log(node['name'],branch_length,bootstrap,node,node['branch_length'])
				if(bootstrap != null){
					bootstrap = (bootstrap.text)
				}				
				if(node.hasOwnProperty('clade')){
					// console.log(node['name'],branch_length,bootstrap,node,node['branch_length'])
					var currentNode = null
					this.serial_internal_node++
					currentNode = this.makeNewInternalNode('', "INT" + this.serial_internal_node, false, parentnode);
					if(branch_length != null &&  branch_length > -1){
						this.hasBranchlength = true;
						currentNode.setBranchLength(branch_length);
					}
					if (bootstrap != null) {
						this.hasBootStrap = true;
						currentNode.setBootStrap(bootstrap);
					}
					this.phyloXMLparserNodesIterator(currentNode,node['clade'])
				}else{
					this.serial_leaf_node++
					var iid = node['name']
					currentNode = this.makeNewLeafNode(iid,0,parentnode,this.serial_leaf_node)
					// console.log(node['name'],branch_length,bootstrap,node,node['branch_length'])
					if(branch_length != null &&  branch_length > -1){
						this.hasBranchlength = true;
						currentNode.setBranchLength(branch_length);
					}
					if (bootstrap != null) {
						this.hasBootStrap = true;
						currentNode.setBootStrap(bootstrap);
					}
				}
			}
		}else{
			var branch_length = 0,boostrap = -1
			for(var ind in nodes){
				var node = nodes[ind]
				if(ind == 'branch_length'){
					branch_length = parseInt(node)
					parentnode.setBranchLength(branch_length)
					this.hasBranchlength = true
				}else if( ind == 'confidence'){
					boostrap = parseInt(node.text)
					parentnode.setBootStrap(boostrap)
					this.hasBootStrap = true
				}else if( ind == 'clade'){
					this.phyloXMLparserNodesIterator(parentnode,node)
				}
			}
		}									
	}

    remove_linebreaks(message ) {
        return message.replace( /[\r\n]+/gm, "" );
    }

    getID() {
		return this.treeName;
    }
    
    setID(newid) {
		this.treeName = newid;
    }
    
    getInternalID() {
		return this.internalID;
    }
    
    setInternalID(newid) {
		this.internalID = newid;
	}
    
    getDescription() {
		return this.description;
	}

	setDescription(newdesc) {
		this.description = newdesc;
    }

    getRootNode() {
		return this.rootNode;
    }

    hasBootstrapScores() {
		return this.hasBootStrap;
	}
    
    getMaxHorizontalLevel() {
		return this.rootNode.getLevelHorizontal();
    }
    
    getMaxVerticalLevel() {
		return this.getLastLeafNode().getLevelVertical();
    }
        
    getNodeByID(node_id) {
		if (this.hashID2Nodes.hasOwnProperty(node_id)) {
			return this.hashID2Nodes[node_id];
		} else {
			return null;
		}
    }
    
    getAllAncestors(node) {
		var ancestorNodes = [];

		while (!node.isroot()) {
			ancestorNodes.push(node.getParent());
			node = node.getParent();
		}
		return ancestorNodes;
    }
    
    hasBranchlen() {
        var hasbranchlength = false;
        var tem = this.getAllNodes()
		for (var ind in tem) {
            var node = tem[ind]
			if (!node.isroot()) {
				if (node.getBranchLength() > 0) {
					hasbranchlength = true;
					break;
				}
			}
		} // end of for

		return hasbranchlength;
    }

    getLeafNodes() {
		return this.leafNodes;
    }
    
    getAllLeafLabels() {
		var leafnames = [];
		for (var ind in this.getLeafNodes()) {
            var pn = this.getLeafNodes()[ind]
			leafnames.push(pn.getID());
		}
		return leafnames;
    } // May 13, 2012
    
    getFirstLeafNode() {
		return this.leafNodes.length > 0 ? this.leafNodes[0] : null;
	}

	getLastLeafNode() {
		return this.leafNodes.length > 0 ? this.leafNodes[this.leafNodes.length - 1] : null;
    }

    getAllNodes() {
		return this.allNodes;
    }
    
    isTreeDataValid() {
		return this.isValidDataset;
	}

	getErrorMessage() {
		return this.errormessage;
    }
    
    getMaxTotalBranchLengthFromRootToAnyTip() {
		return this.maxRootToTipTotalBranchLength;
    }

    getExternalIDbyInternalID(internal_id) {
		return this.hsInternalID2externalID.hasOwnProperty(internal_id) ? this.hsInternalID2externalID[internal_id] : "";
    }
    
    getTreeFormat() {
		return this.treeFormat;
    }
    
    isHasMultipleBoostrap() {
		return this.hasMultipleBoostrap;
	}

	setHasMultipleBoostrap(hasMultipleBoostrap) {
		this.hasMultipleBoostrap = hasMultipleBoostrap;
	}

	toTreeString( treeFormat, showBootstrap, showInternalID, writeItolFormat) {
		/*
		 * check the following parameters first showBootstrap showInternalID
		 * showBranchlength writeItolFormat; note writeItolFormat is true only
		 * if showBootstrap == true
		 */
		var showBranchlength = this.hasBranchlength; //will show branch length if the tree has bl-values

		if (showBootstrap === true && this.hasBootstrapScores() === false) {
			showBootstrap = false;
		}

		if (showBootstrap == false || showBranchlength == false || treeFormat === ("nexus")) { // itol style requires both bootstrap AND branchlength
			writeItolFormat = false;
		}

		if (showBootstrap == true && writeItolFormat == false) {
			showInternalID = false;
		}

		// console.log(this.hasBootStrap,treeFormat,showInternalID)

		/*
		 * Dec 5, 2011; prepare translate
		 */
		var hashTranslate = {};
		if (treeFormat === ("nexus")) {
			hashTranslate = {};
			var serial = 0;
			for (var ind in this.leafNodes) {
				var leafnode = this.leafNodes[ind]
				serial++;
				hashTranslate[leafnode.getID()] = (serial);
			}
		}

		/*
		 * >>>> how to write a tree to string? dec 29, 2010 >>>>> basically,
		 * there are three steps: 1. for each internal node, find all its
		 * descendents, join them with ',', and surround the joint string with
		 * () 2. append ID/internalID/boostrap/branchlength of the internal node
		 * to the string 3. repeat 1&2 until the internal node is root
		 */
		// let's start with a simple tree
		var treestr_newick = this.phyloNodeToString(this.rootNode, [], [], showBootstrap, showInternalID, showBranchlength,
				writeItolFormat, treeFormat, hashTranslate);

		if (treeFormat === ("nexus")) {
			var treestr = "";
			treestr +=("#NEXUS\n\n")
			treestr +=("Begin trees; [Treefile created using EvolView]\n")
			treestr +=("\tTranslate\n");
			var serial = 0;
			for (var ind in this.leafNodes) {
				var leafnode = this.leafNodes[ind]
				serial++;
				if (leafnode.getID().includes(" ")) {
					treestr+=("\t\t")
					treestr+=(serial)
					treestr+=(" '")
					treestr+=(leafnode.getID())
					treestr+=("'");
				} else {
					treestr += ("\t\t")
					treestr+=(serial)
					treestr+=(" ")
					treestr+=(leafnode.getID());
				}

				if (serial < this.leafNodes.length) {
					treestr += (",");
				}
				treestr+= ("\n");
			}
			treestr += (";")
			treestr += ("\n");
			treestr += ("\t tree tree_1 = [&R] ")
			treestr += (treestr_newick)
			treestr += ("\n");
			treestr+=("End;")
			treestr+=("\n");

			treestr_newick = treestr;
		}

		return treestr_newick;
	}

	getPath2Root( node) {
		var nodes = [];
		if (node != null) {
			while (!node.isroot()) {
				nodes.push(node);
				node = node.getParent();
			}
		}
		return nodes;
	}

	getPath2MRoot(node,mNode){
		var nodes = []
		if(node != null){
			while(true){
				nodes.push(node.getInternalID())
				if(node == mNode){
					break
				}else{
					node = node.getParent()
				}
			}
		}
		return nodes
	}

	flipAtInternalNode( inode) {
		this.doFlipping(inode);
		
		/**
		 * remake all essential variables ... 
		 */
		this.allNodes = [];
		this.leafNodes = [];
		this.hashID2Nodes = {};
		this.hsInternalID2externalID = {};
		this.reMakeEssentialVariables(this.rootNode, 0, true); // oct 25, 2013 

		// recaclulate some other variables 
		this.reCalcDistanceToRoot();
		this.reCalcMaxDistanceToTip();
		this.reCalcLevels();
		//this.reDoID2Node();
	} //

	/**
	 * an alias function for flipAtInternalNode
	 * @param inode
	 */
	rotateAtInternalNode(  inode ){
		this.flipAtInternalNode( inode );
	}

	doFlipping( inode) {
		if (inode != null) {
			if (!inode.isleaf()) {
				var nodes = inode.getDescendents();
				nodes.reverse(); // will this do????
				inode.setDescendents(nodes);
				for (var ind in nodes) {
					var n = nodes[ind]
					this.doFlipping(n); // 
				}
				this.replaceNode(inode,this.rootNode.getDescendents())
			}
		}
	}

	replaceNode(node,descendentNodes){
		for(var ind in descendentNodes){
			var subNode = descendentNodes[ind]
			if(subNode.getInternalID() === node.getInternalID()){
				descendentNodes[ind] = node
			}else if(!subNode.isleaf()){
				this.replaceNode(node,subNode.getDescendents())
			}
		}
	}

	reMakeEssentialVariables(node, leafVerticalLevel, addToAllNodes) {
		// reDoID2Node () ...
		var node_id = node.getID(); // NOTE: jan 25, 2011; rootNote usually doesn't have any ID
		var internalnode_id = node.getInternalID();

		if (addToAllNodes && node_id != null && node_id.trim().length > 0) {
			this.hashID2Nodes[node_id] =  node;
		}

		// internalID cannot be empty
		if (addToAllNodes && internalnode_id.trim().length > 0) {
			this.hashID2Nodes[internalnode_id] =  node;
		}

		if ( node.isleaf() || node.isCollapse() ) {
			/**
			 * internal ID to external id; for leaf nodes only
			 */
			this.hsInternalID2externalID[internalnode_id] =  node_id;
			/**
			 * add current node to leafNodes
			 */
			this.leafNodes.push(node);
			
			/**
			 * Dec 13, 2015; 
			 *  + if current node is internal node at which the tree will be collapsed, calculate it's vertical level as 
			 *    log10( number_of_daughter_leaf_nodes ) + 1  
			 */
			// console.log(node_id,internalnode_id,node.isCollapse(),!node.isleaf())
			if( node.isCollapse() && !node.isleaf() ){
				// console.log(internalnode_id,node.isCollapse(),!node.isleaf())
				var incre = Math.log10( node.getNumberOfLeafDescendents() );
				node.setLevelVertical( leafVerticalLevel + incre / 2 + 1 ); // Dec 13, 2015; 
				leafVerticalLevel += 1 + incre;
			} else {
				leafVerticalLevel += 1;
				node.setLevelVertical(leafVerticalLevel);
			}
		} else {
			for (var ind in node.getDescendents()) {
				var dnode = node.getDescendents()[ind]
				leafVerticalLevel = this.reMakeEssentialVariables(dnode, leafVerticalLevel, addToAllNodes);
			}
		}		
		/**
		 * add all nodes to allnodes, including root node
		 */
		if( addToAllNodes ){
			this.allNodes.push(node);
		}
		return leafVerticalLevel;
	}

	phyloNodeToString( node, for_descendents, for_parent, showBootstrap, showInternalID,
	 showBranchlength, writeItolFormat, treeFormat, hashTranslate) {

	// do nothing if current node is leaf
	if (node.isleaf() == true) {
		return null; // do nothing
	}

	// if current node is an internal node; go through its descendents
	for (var ind in node.getDescendents()) {
		var descendent = node.getDescendents()[ind]
		if (descendent.isleaf() == true) {
			/*
			 * assemble a leaf string: ID:branchlength, in which
			 * ':branchlength' is depending on showBranchlength and add it
			 * to 'for_descendents'
			 */
			// leaf id
			var str_leaf = descendent.getID();
			if (treeFormat === ("nexus") && hashTranslate != null && hashTranslate.hasOwnProperty(str_leaf)) {
				str_leaf = hashTranslate[str_leaf];
			} else if (str_leaf.includes(" ")) {
				str_leaf = "'" + str_leaf + "'"; // Feb 2, 2012
			}

			// leaf branch length, if available
			if (showBranchlength === true) { // showBranchlength is true means there is branch-length data for all nodes
				str_leaf += ":" + (descendent.getBranchLength());
			}

			// nhx string, if available; not implemented

			for_descendents.push(str_leaf);
		} else {
			/*
			 * call this function itself, be careful always;
			 */
			this.phyloNodeToString(descendent, [], for_descendents, showBootstrap, showInternalID, showBranchlength, writeItolFormat, treeFormat, hashTranslate); // call this function itself
		}
	}

	/*
	 * now we've obtained string representation of all descendents for
	 * current node, I'll a. assemble string representation for this
	 * (internal) node b. return the string to the parent node, it's needed
	 * to assemble string representation for the parent node
	 */
	// assemble string representation for current internal node;
	// normally, one internal node should at least have two+ descendents,
	//   but in itol, internal node without descendents is supported; I'll deal with this latter
	var str_internal_node = "";

	var size_of_for_descendents = for_descendents.length;
	for (var i = 0; i < size_of_for_descendents; i++) {
		str_internal_node += for_descendents[i];
		if (i < (size_of_for_descendents - 1)) {
			str_internal_node += ",";
		}
	}
	str_internal_node = "(" + str_internal_node + ")";

	/*
	 * get annotation to current internal node
	 */
	var internalnode_id = node.getID().length > 0 ? node.getID() : node.getInternalID();
	if (internalnode_id.includes(" ")) {
		internalnode_id = "'" + internalnode_id + "'"; // Feb 2, 2012
	}

	var str_anno = "";

	if (treeFormat === ("nhx")) {
		var nhxAttrs = {};
		if (showBootstrap && !node.getBootStrap().isNaN && node.getBootStrap() != 0) {
			nhxAttrs["B"] =  (node.getBootStrap());
		}
		if (showInternalID) {
			nhxAttrs["ND"] =  node.getInternalID();
		}

		// now summarize --
		if (showBranchlength) {
			str_anno += ":" + node.getBranchLength();
		}
		// console.log(nhxAttrs,nhxAttrs.length,nhxAttrs.size,node)
		if (Object.getOwnPropertyNames(nhxAttrs).length>=1) {
			str_anno += "[&&NHX";
			for (var kv in nhxAttrs) {
				if(!nhxAttrs[kv].isNaN){
					// console.log(kv,nhxAttrs[kv])
					str_anno += ":" + kv + "=" + nhxAttrs[kv];
				}
			}
			str_anno += "]";
		}
		} else if (treeFormat === ("newick")) {
			if (writeItolFormat == true) {
				str_anno += ":" + node.getBranchLength() + "[" + node.getBootStrap() + "]";
				if (showInternalID == true) {
					str_anno = internalnode_id + str_anno;
				}
			} else {
				if (showBootstrap == true) {
					str_anno = (node.getBootStrap());
				}

				if (showInternalID == true) {
					str_anno = internalnode_id;
				}

				if (showBranchlength == true) {
					str_anno += ":" + node.getBranchLength();
				}
			}
		} else if (treeFormat === ("nexus")) {
			if (showBranchlength == true) {
				str_anno += ":" + node.getBranchLength();
			}

			if (showInternalID == true) {
				str_anno += "[" + internalnode_id + "]";
			}
		}

		// append the annotation to the ID of internal node
		str_internal_node += str_anno;

		if (node.isroot() == true) {
			str_internal_node += ';';
			return str_internal_node;
		} else {
			for_parent.push(str_internal_node);
			return null;
		} // end of node.isRoot
	} // end of function phyloNodeToString

	toXMLString() {
		var xw = new XMLWriter;
		xw.startDocument();
		var phyloxml = xw.startElement("phyloxml")
		phyloxml.writeAttribute("xmlns:xsi", "http://www.w3.org/2001/XMLSchema-instance")
		phyloxml.writeAttribute("xsi:schemaLocation", "http://www.phyloxml.org http://www.phyloxml.org/1.10/phyloxml.xsd");
		phyloxml.writeAttribute("xmlns", "http://www.phyloxml.org");
		var phylogeny = xw.startElement("phylogeny")
		phylogeny.writeAttribute("rooted", "true");
		this.phyloNodeToXML(this.rootNode, phylogeny);
		return xw.toString();
	}

	phyloNodeToXML( node,  parentXML) {
		// create a new element for current node
		var nodeXML = parentXML.startElement("clade");

		// if current node is internal node
		if (!node.isleaf()) {
			for (var ind in node.getDescendents()) {
				var childnode = node.getDescendents()[ind]
				this.phyloNodeToXML(childnode, nodeXML);
			}
		}

		// add branch length to current node
		if (this.hasBranchlength) {
			nodeXML.writeElement("branch_length",node.getBranchLength());		
			// nodeXML.appendChild(branchXML);
		}
		// add bootstrap
		if (this.hasBootStrap && !node.isleaf()) {
			var conf = nodeXML.startElement("confidence")
			conf.writeAttribute("type", "unknow")
			conf.text(node.getBootStrap())
			nodeXML.endElement()
		}

		if (! this.isEmpty(node.getID().trim())) {			
			nodeXML.writeElement("name",node.getID());
		}
		nodeXML.endElement()
	}

	getLCA(ids) {

		/**
		 * first of all, get a list of valid nodes and their ancestors
		 * corresponding to leaf_ids
		 */
		var phylonodes = [];
		var ancestors = [];
		for (var ind in ids) {
			var id = ids[ind]
			id = id.trim(); // trim ID ...
			
			var pnode = this.getNodeByID(id);
			if (pnode != null) {
				ancestors.push(this.getAllAncestors(pnode));
				phylonodes.push(pnode);
			}
		}

		//
		var lca = null;

		if (phylonodes.length == 1) {
			lca = phylonodes[0];
		} else if (phylonodes.length<=0) {
			// do nothing
		} else {

			for (var  ind in ancestors[0]) {
				var ancestor = ancestors[0][ind]
				var isLCA = true;
				for (var idx = 1; idx < ancestors.length; idx++) {
					if (ancestors[idx].includes(ancestor) == false) {
						isLCA = false;
					}
				}

				if (isLCA == true) {
					lca = ancestor;
					break;
				}
			}// for ancesters
		} // leaf_nodes.size

		return lca;
	} // get lca

	isEmpty(str){
    	if(str.length>=1) return false
    	return true
	}
	  
	getTreeString() {
		return this.treeString;
	}
}

export default PyTree
