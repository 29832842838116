import Point from '../treePainter/Point'

class Shapes {
    constructor(){

    }

    static makeStar(center, radius, factor, parent_layer) {
        var star = parent_layer.polygon()
        var temPolyAr = []
		for (var angle = 18; angle < 360; angle += 72) {
			temPolyAr.push(Shapes.convertToAr(Point.getCoordinatesOnCircle(center, radius, angle)));
			temPolyAr.push(Shapes.convertToAr(Point.getCoordinatesOnCircle(center, radius * factor, angle + 36)));
        }
        star.plot(temPolyAr)
		return star;
    }
    
    static maketriangle(leftbottom, width, parent_layer) {
		var p2y = width * Math.sin( (60 * 2.0 * Math.PI / 360.0));
		var x = leftbottom.x;
		var y = leftbottom.y - (width - p2y) / 2;
		var triPt = new Point()
		triPt.appendSegItem(Point.createPointStr(x, y));
		triPt.appendSegItem(Point.createPointStr(x + width / 2, y - p2y,'L')); // line from p1 to p2;
		triPt.appendSegItem(Point.createPointStr(x + width, y,'L')); // line from p2 to p3;
		triPt.appendSegItem(Point.createPointStr(x, y, 'L')); // line from p3 to p1;
		triPt.closeSegItem() // close path
        var triangle = parent_layer.path(triPt.getSegPathListStr())
		return triangle;
    }

    static makeCheckSymbol(start, plotwidth, parent_layer) {
		var check = parent_layer.group().id('check');
		var pleft = Point.createPoint(start.x + plotwidth * (1 / 10),
				start.y - plotwidth * (1 / 20));
		var pmiddle = Point.createPoint(start.x + plotwidth * (2 / 5),
				start.y + plotwidth * (1 / 5));
		var pright = Point.createPoint(start.x + plotwidth * (4 / 5),
				start.y - plotwidth * (1 / 3));
        check.line(pleft.x,pleft.y, pmiddle.x, pmiddle.y);
		check.line(pright.x,pright.y, pmiddle.x, pmiddle.y);
		return check;
	}
    
    static convertToAr(point){
        return [point.x,point.y]
    }
    
}

export default Shapes