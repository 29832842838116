import PlotBase from "./PlotBase";
import treePlotMode from '../treeObjects/treePlotMode'
import Point from '../treePainter/Point'
import StringOps from '../utils/StringOps'

const FontStyle = {
    'NORMAL':'normal',
    'ITALIC':'italic',
    'OBLIQUE':'oblique',
    }
Object.freeze(FontStyle)

const FontWeight = {
    'NORMAL':'normal',
    'BOLD':'bold',
    'BOLDER':'bolder',
    'LIGHTER':'lighter',
    }
Object.freeze(FontWeight)

class DotPlots extends PlotBase{
    constructor(layer_tree_charts, disable_all_dataset, treeSVG){
        super()
        this.defaultColor = "white";
        this.plotWidth = 40;
            
        this.ndCon = null;
		this.maxValuePerColumn = [];
		this.row2data = {};
        this.row2radius = {};
        
        this.layer_tree_charts = layer_tree_charts
        
        this.disable_all_dataset = disable_all_dataset
        this.chart_plots_enabled = treeSVG.chart_plots_enabled
        this.treeSVG = treeSVG
        this.currentMostRightPosition = this.treeSVG.getLeafMostRightPosition()
        this.plotmode = treeSVG.getPlotMode()
        this.phylotree = treeSVG.getPhyloTree()
        this.pxPerHeight = this.treeSVG.pxPerHeight
        this.space_between_datasets = this.treeSVG.space_between_datasets
        this.default_tick_label_font_size = this.treeSVG.default_tick_label_font_size
        this.circular_tree_plot_in_clockwise_mode = this.treeSVG.circular_tree_plot_in_clockwise_mode
        this.angle_span = this.treeSVG.angle_span
        this.LeafInternalID2NodePosisionts = this.getLeafInternalID2NodePosisionts()

    }

    getAngleSpan(){
        return this.treeSVG.getAngleSpan()
    }

    setLeafInternalID2NodePosisionts(LeafInternalID2NodePosisionts){
        this.LeafInternalID2NodePosisionts = LeafInternalID2NodePosisionts
    }

    setRootXY(rootXY){
        this.rootXY = rootXY
    }

    getNdCon(){
        return this.ndCon;
    }

    setPlotMode(plotmode){
        this.plotmode = plotmode
    }

    getLeafInternalID2NodePosisionts(){
        return this.treeSVG.getLeafInternalID2NodePosisionts()
    }

    gethmLeafID2NodePositions(){
        return this.treeSVG.gethmLeafID2NodePositions()
    }

    getLeafMostRightPosition(){
        return this.currentMostRightPosition
    }

    setLeafMostRightPosition(currentMostRightPosition){
        this.currentMostRightPosition = currentMostRightPosition
    }

    setNumericDataSet( newID, mdatCon) {
        this.ndCon = mdatCon;
        this.datasetID = newID;
        this.setActive(true);
        this.row2data = this.ndCon.getTreeNodeID2Data(); //
        this.datasetContentAsString = mdatCon.getOriginal_datastring(); // get original user input as String ; April
                                                                        // 5, 2011;
        this.opacity = this.ndCon.getOpacity();
        this.setLegendEntry(mdatCon.getLegend()); // march 20, 2014 --

        /*
         * March 29, 2015; plot width per col, default is 20 ...
         */
        this.plot_width_per_col = this.ndCon.getPlotColWidth();

        var groups = this.ndCon.getLegendTexts();
        var column2data = this.ndCon.getColumn2Data();
        for (var sidx = 0; sidx < groups.length; sidx++) {
            var maxThis = Math.max(...column2data[groups[sidx]]);
            this.maxValuePerColumn.push(maxThis);
        }

        /**
         * recalculate essential variables
         */
        this.recalcEssentialVariables();
    }

    recalcEssentialVariables() {
        /**
         * March 30, 2015. calculate radius ...
         */
        this.row2radius = [];

        // calculate other global parameters
        // note: pwidth_per_value will be calculate on per col basis ...; march 29, 2015
        // ..
        for (var entry in this.LeafInternalID2NodePosisionts) {        
            var leaf_internal_id = entry;
            if (this.row2data.hasOwnProperty(leaf_internal_id)) {
                const cdata = this.row2data[leaf_internal_id];

                var radius = [];
                for (var i = 0; i < cdata.length; i++) {
                    var value = cdata[i];
                    var max = this.ndCon.isDotPlotsScaleByCol() ? this.maxValuePerColumn[i] : this.ndCon.getMaxValue();

                    // calculate radius; by area or not ...
                    var r = 0;
                    if (value > 0 && max > 0) {
                        if (this.ndCon.isByarea()) {
                            r = (Math.sqrt(value) / Math.sqrt(max) / 2
                                    * (this.plot_width_per_col - this.ndCon.getPlotColMargin()));
                        } else {
                            r = value / max / 2 * (this.plot_width_per_col - this.ndCon.getPlotColMargin());
                        }
                    }
                    radius.push(r);
                }

                //
                this.row2radius[leaf_internal_id] =  radius;
            } // if leaf internal id is contained ...
        }
    }

    addHighlightToRowPlotByLeafInternalID(leaf_internal_id) {
        if (this.hmInternalID2RowPlot.hasOwnProperty(leaf_internal_id)) {
            this.hmInternalID2RowPlot[leaf_internal_id].attr("stroke", "red");
        }
    }

    removeHighlightFromRowPlotByLeafInternalID(leaf_internal_id) {
        if (this.hmInternalID2RowPlot.hasOwnProperty(leaf_internal_id)) {
            this.hmInternalID2RowPlot[leaf_internal_id].attr("stroke",null);
        }
    }
    
    makeOrUpdatePlot() { // dotplot ...

        const groups = this.ndCon.getLegendTexts();
        const colors = this.ndCon.getLegendColors();
        const strokecolors = this.ndCon.getLegendStrokeColors();
        const defaultStrokeColor = this.ndCon.getDefaultStrokeColor();
        const strokewidth = this.ndCon.getDefaultStrokeWidth();
        this.currentMostRightPosition = this.getLeafMostRightPosition()
        this.layer_dotplot_grid = this.layer_tree_charts.group().id('layer_dotplot_grid');
		this.layer_dotplot_gridlabels = this.layer_tree_charts.group().id('layer_dotplot_gridlabels');
        
        if (this.active && !this.disable_all_dataset && this.chart_plots_enabled) {
            var mostright = this.currentMostRightPosition + this.space_between_datasets;
            var isCircular = (this.plotmode === (treePlotMode.CIRCULAR_CLADOGRAM)
                    || this.plotmode === (treePlotMode.CIRCULAR_PHYLOGRAM));

            this.recalcEssentialVariables(); // Dec 17, 2015;

            /**
             * grid and grid labels; Marc 30, 2015 ...
             */
            var makegrid = false;
            if (this.ndCon.isPlotgrid()) {
                Point.clearAllChildNodesFromSVGGElement(this.layer_dotplot_grid);

                // layer_tree_charts.appendChild(layer_dotplot_grid); // sep 22, 2014; now all charts are added to
                                                                    // charts ...
                this.layer_dotplot_grid.attr("stroke-width", this.line_width_grid + "");
                this.layer_dotplot_grid.attr("stroke-dasharray", "4,4"); // Apr 25, 2012; now all have this dash

                this.layer_dotplot_grid.attr("stroke", "grey");
                this.layer_dotplot_grid.attr("fill", "none"); // for path only;

                this.makegrid = true;
            } else {
                Point.deleteChildNodeFromLayerTree(this.layer_dotplot_grid);
            }

            // grid axis; grid labels
            if (this.ndCon.isShowGridLabel()) {
                Point.clearAllChildNodesFromSVGGElement(this.layer_dotplot_gridlabels);
                // layer_tree_charts.appendChild(layer_dotplot_gridlabels); // sep 22, 2014
                this.makegrid = true;
            } else {
                Point.deleteChildNodeFromLayerTree(this.layer_dotplot_gridlabels);
            }
            // console.log('can i make grid? ',this.makegrid)
            if (this.makegrid) {

                /*
                 *streamline codes here
                 */

                // get leaf information
                var firstleaf_id = this.phylotree.getFirstLeafNode().getInternalID();
                var lastleaf_id = this.phylotree.getLastLeafNode().getInternalID();

                var firstleafPos = this.LeafInternalID2NodePosisionts[firstleaf_id];
                var lastleafPos = this.LeafInternalID2NodePosisionts[lastleaf_id];

                var anglespan_per_leaf_label = this.angle_span / this.phylotree.getLeafNodes().length;
                var anglespan_of_leaf_lables = this.angle_span - anglespan_per_leaf_label;

                var realstart = mostright; // 0
                var texty = (firstleafPos.pt.y
                        - (this.pxPerHeight
                                * (1 + Math.log10(this.phylotree.getFirstLeafNode().getNumberOfLeafDescendents()))) / 2
                        - 5);

                
                for (var g = 0; g <= groups.length; g++) {
                    // if circular mode
                    if (isCircular) {
                        var aradius = (realstart - this.rootXY.x);

                        var startpoint = Point.getCoordinatesOnCircle(this.rootXY, aradius, firstleafPos.angle);
                        var endpoint = Point.getCoordinatesOnCircle(this.rootXY, aradius, lastleafPos.angle);

                        var sweepFlag = this.circular_tree_plot_in_clockwise_mode === true ? 1 : 0;
                        var large_arc = (anglespan_of_leaf_lables >= 180) ? 1 : 0; // Feb 12, 2011; use
                                                                                                // global
                                                                                                // angle_span?

                        // boolean largeArcFlag = angle >= 180 ? true : false;
                        var segs = new Point();
                        segs.appendSegItem(Point.createPointStr(startpoint.x, startpoint.y));
                        segs.appendSegItem(Point.createArcStr(endpoint.x, endpoint.y,
                                aradius, 0, large_arc, sweepFlag));
                        var path = this.layer_dotplot_grid.path(segs.getSegPathListStr())
                    } else {
                        var line = this.layer_dotplot_grid.line(realstart,
                            firstleafPos.pt.y - this.pxPerHeight * 0.8 / 2, realstart,
                            lastleafPos.pt.y + this.pxPerHeight * 0.8 / 2)
                        /**
                         * for now text label only works in rectangular mode ; also allow
                         * user to change column label font style
                         */
                        if (g < groups.length && this.ndCon.isShowGridLabel()) {

                            // get font size --; Nov 29, 2015;
                            var font_size = this.ndCon.getColumnLabelFontSize() > 0 ? this.ndCon.getColumnLabelFontSize()
                                    : this.default_tick_label_font_size;
                            var text_angle = this.ndCon.getColumnLabelTextAngle();

                            var textx = realstart + this.ndCon.getPlotColWidth() / 2;
                            // adjust textx according to text angle --
                            if (text_angle < 0 && text_angle > -180) {
                                textx += font_size * 1 / 4;
                            } else {
                                textx -= font_size * 1 / 4;
                            }

                            var txtGridLabel = this.layer_dotplot_gridlabels.text(this.ndCon.getGroupByIndex(g)).move(textx+5, texty-15)                            
                            txtGridLabel.font({size:font_size});

                            // set align anchor according to text angle
                            if (text_angle < 0 && text_angle > -180) {
                                txtGridLabel.attr("text-anchor", "start"); // ... align to
                                                                                    // start ...
                            } else {
                                txtGridLabel.attr("text-anchor", "end"); // ... align to
                                                                                    // start ...
                            }

                            // font color
                            var font_color = this.ndCon.getColumnLabelFontColor().length >=1
                                    ? this.ndCon.getColumnLabelFontColor()
                                    : (colors != null)
                                            ? (colors.length > g) ? colors[g] : colors[g % colors.length]
                                            : this.defaultColor;
                            txtGridLabel.attr("fill", font_color);

                            // font italic --
                            if (this.ndCon.isColumnLableFontItalic()) {                                
                                txtGridLabel.font({style:FontStyle.ITALIC})
                            }

                            // font bold
                            if (this.ndCon.isColumnLableFontBold()) {
                                txtGridLabel.font({weight:FontWeight.BOLD})
                            }

                            // System.err.println("text angle is : " + text_angle);

                            // rotate text according user input angle; --
                            txtGridLabel.transform({rotation:text_angle, cx:textx, cy:texty})
                        }
                    }
                    // increase realstart position
                    realstart += this.ndCon.getPlotColWidth();
                } // s
            } // make grid
                // <<<<<<<<<<< grid and axis <<<<<<<<<<<

            /*
             * >>>>>>>>>> the main layer >>>>>>>>>>>>>
             */
            this.initLayer(this.layer_tree_charts) //
            Point.clearAllChildNodesFromSVGGElement(this.layer);
            this.layer.attr("fill-opacity", this.opacity);

            // Sep 28, 2014; create a SVG group element to hold data values
            const showdataSVGGelement = this.layer.group().id('showdataSVGGelement')
            showdataSVGGelement.font({size: this.ndCon.getShowDataFontSize()});
            if (this.ndCon.isbShowDataFontItalic()) {
                showdataSVGGelement.font({style:FontStyle.ITALIC})
            }
            if (this.ndCon.isShowDataValueColorSet() && ! StringOps.equalsIgnoreCase( this.ndCon.getShowDataFontColor(),("black"))) {
                showdataSVGGelement.attr("fill", this.ndCon.getShowDataFontColor());
            }

            // iterate hashPositionOfLeafNodes
            for (var entry in this.LeafInternalID2NodePosisionts) {
                const leaf_internal_id = entry;
                var startpos = this.LeafInternalID2NodePosisionts[entry]
                var angle = startpos.angle;
                if (this.row2data.hasOwnProperty(leaf_internal_id)) {
                    const radius = this.row2radius[leaf_internal_id];
                    const cdata = this.row2data[leaf_internal_id];

                    if (radius != null && cdata != null) {
                        var start_pos = mostright; // it'll be changed at the end of the for loop

                        /*
                         * each row of data now will be added into a SVGG element -- then
                         * create a tooltip for each row of data
                         */
                        const rowplot = this.layer.group().id('row_plot')
                        this.hmInternalID2RowPlot[leaf_internal_id] =  rowplot;

                        /**
                         * mouse events ...
                         */
                        var that = this
                        rowplot.mouseover(function(evt) {
                            that.treeSVG.addHighlightToAllRowplotsByInternalLeafID(leaf_internal_id);
                        })
                        rowplot.mouseout(function(evt) {
                            that.treeSVG.removeHighlightFromAllRowplotsByInternalLeafID(leaf_internal_id);
                        })

                        /**
                         * ... + added support for shapes ...
                         */
                        // iterate data for current node
                        for (var idx = 0; idx < groups.length; idx++) {
                            // if array colors is shorter than plottypes, colors are
                            // recycled ;
                            // - this might not be necessary --
                            var color = (colors != null)
                                    ? (colors.length > idx) ? colors[idx] : colors[(idx % colors.length)]
                                    : this.defaultColor;
                            var strokecolor = (strokecolors != null)
                                    ? (strokecolors.length > idx) ? strokecolors[idx]
                                            : strokecolors[idx % strokecolors.length]
                                    : "";

                            if (strokecolor.length<=0 && defaultStrokeColor.length >=1
                                    && !StringOps.equalsIgnoreCase(defaultStrokeColor,("none"))) {
                                strokecolor = defaultStrokeColor;
                            }

                            var current_radius = radius[idx]; 
                            var current_value = cdata[idx];

                            /**
                             * make circle or rect ...
                             */
                            var c = null; //OMSVGElement
                            if (StringOps.equalsIgnoreCase( this.ndCon.getDotPlotsShape(),("rect"))) {
                                var rectw = current_radius * 2 - this.ndCon.getPlotColMargin();
                                c = rowplot.rect(rectw, rectw).move(start_pos + this.ndCon.getPlotColWidth() / 2 - rectw / 2,
                                startpos.pt.y - rectw / 2).radius(this.ndCon.getRectRoundedCorner(),
                                this.ndCon.getRectRoundedCorner())
                            } else {
                                c = rowplot.circle(current_radius*2).move(start_pos + this.plot_width_per_col / 2 - current_radius,
                                    startpos.pt.y - current_radius)
                            }

                            c.attr("fill", color);

                            if (strokecolor.length>=1 && !StringOps.equalsIgnoreCase(strokecolor,("none"))) {
                                c.attr("stroke", strokecolor);
                                c.attr("stroke-width", strokewidth + "");
                            }

                            if (isCircular) {
                                // if circular mode and angle is not zero; this part was moved
                                // from the begining of the outter 'for' loop, otherwise the barplots are not
                                // shown correctly                                
                                c.transform({rotation:180-angle, cx: this.rootXY.x, cy: this.rootXY.y})
                            }

                            /**
                             * if show data ...  if current_value is in the range of
                             * valuestohideStartValue ~ valuestohideEndValue, will not plot
                             */
                            if (this.ndCon.isbShowData() && this.ndCon.shouldThisValueToBeShown(current_value)) {
                                var txtData = this.treeSVG.makeShowDataValue4DotplotsAndHeatMap(
                                        start_pos + this.ndCon.getPlotColWidth() / 2,
                                        startpos.pt.y - 8 + this.ndCon.getShowDataFontSize() * 1 / 3, current_value + "",
                                        this.ndCon.getShowDataFontSize(), angle, !this.ndCon.isShowDataValueColorSet(), color,
                                        isCircular, showdataSVGGelement);
                            } // if show data

                            // increase start_pos incremetally; 
                            start_pos += this.plot_width_per_col;
                        } // multi column plot
                    } // if both are not null
                } // if data is valid for current leaf node

            } // iterate hashPositionOfLeafNodes
            showdataSVGGelement.front()
            // this will be added at the end so that it'll appear on the top
            // of other layers ...
            // at the end, update currentMostRightPosition
            this.currentMostRightPosition += (this.plot_width_per_col * groups.length + this.space_between_datasets);
        } else {
            Point.deleteChildNodeFromLayerTree(this.layer);
            Point.deleteChildNodeFromLayerTree(this.layer_dotplot_gridlabels);
            Point.deleteChildNodeFromLayerTree(this.layer_dotplot_grid);
        } // is active
    } // makeorupdate plot

    getPlot_width_per_col(){
        return this.plot_width_per_col
    }
}

export default DotPlots