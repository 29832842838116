<template>
    <modal id="SmallModal" name="SmallModal"        
        :draggable="true" :adaptive="true" height="auto"  @before-close="beforeCloseSm"        
        >
        <b-card no-body>
            <template v-slot:header>
                <span v-if="smModelType == 1">
                    <h3>Copy Datasets</h3>
                </span>
                <span v-if="smModelType == 2">
                    <h3>Share tree</h3>
                </span>
            </template>
            <b-card-body>
                <b-row v-show="smModelType == 1">
                    <b-col>
                        <b-card header="Choose dataset(s) from tree to copy:" class="text-center">                    
                                <multi-select 
                                v-model="smCurDataset" 
                                :options="datasetLis"
                                group-values="options"
                                group-label="label"
                                track-by="text" label="text"
                                :multiple="true"
                                :max-height="150"
                                ></multi-select>
                        </b-card>
                    </b-col>
                </b-row>
                <b-row v-show="smModelType == 1">
                    <b-col>
                        <b-card header="Choose tree(s) to copy to:" class="text-center">                    
                            <multi-select 
                                v-model="smCurTree" 
                                :options="projTreeList"
                                group-values="options"
                                group-label="label"
                                track-by="text" label="text"
                                :multiple="true"
                                :max-height="100"
                            ></multi-select>
                        </b-card>
                    </b-col>
                </b-row>
                <b-row v-show="smModelType == 2">
                    <b-col>
                        Do you want to share the tree? 
                    </b-col>
                </b-row>
                <b-row v-show="smModelType == 2">
                    <b-col>
                        <b-form-group label="Share mode" label-for="ratio" label-cols-md="auto">
                            <b-form-select id="ratio" v-model="shareSel" :options="shareOptions" @change="shareSelChanged"></b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row v-show="smModelType == 2">
                    <b-col>
                        <b-form-checkbox v-model="shareProject" :disabled="shareProjActive">Share Project</b-form-checkbox>
                    </b-col>
                </b-row>
            </b-card-body>
            <template v-slot:footer>
                <b-button size="sm" variant="danger" @click="closeSmModal">
                    Cancel
                </b-button>
                <b-button size="sm" variant="success" @click="submitSmModal">
                    Submit
                </b-button>
            </template>
        </b-card>
    </modal>
</template>

<script>
import EventBus from './EventBus'
import axios from 'axios'
import Commons from '../utils/CommonEvol'

export default {    
    props:['actTree','actProject','projTrList','datstList'],
    data() {
        return {
            cancelUsedSm:false,
            smModelType:0,
            smCurDataset:[],
            smCurTree:[],            
            shareOptions:['Share','embed'],//,'Collaborate'],
            shareSel:'Share',
            shareProject:'',
            shareProjActive:false,
            datasetLis:[],
            projTreeList:[],
            userId:'',
            server_add : this.$hostname,
            activeProject:'',
            activeTree:''
        }
    },
    created() {        
        EventBus.$on('smModelType', smModelType => {
            this.smModelType = smModelType
        })
    },
    watch:{
        actTree(val){
            this.activeTree = val
        },
        actProject(val){
            this.activeProject = val
        },
        projTrList(val){
            this.projTreeList = val
            this.smCurDataset = []
            this.smCurTree = []
        },
        datstList(val){
            console.log('dt updated to ',val)
            this.datasetLis = val
        },
        // "$store.state.projTreeList": {
        //     handler: function(nv) {
        //         this.projTreeList = nv
        //         this.smCurDataset = []
        //         this.smCurTree = []
        //     },
        //     immediate: true // provides initial (not changed yet) state
        // },
        // "$store.state.datsetList": {
        //     handler: function(nv) {
        //         this.datasetLis = nv
        //     },
        //     immediate: true // provides initial (not changed yet) state
        // },
    },
    mounted() {
        if(localStorage.getItem("usertoken") != null){
            const token = localStorage.getItem("usertoken") 
            const decoded = JSON.parse(token)//jwtDecode
            this.userId = decoded.userId
        }
    },
    methods: {
        beforeCloseSm(event){
            if(!this.cancelUsedSm){
                event.cancel()
            }
        },
        shareSelChanged(){
            if(this.shareSel === 'embed'){
                this.shareProjActive = true
            }else{
                this.shareProjActive = false
            }
        },
        submitSmModal(){
            // console.log(this.smModelType,this.smCurDataset,this.smCurTree)            
            if(this.smModelType == 1){ //copy datasets
                if(this.smCurDataset.length <=0 || this.smCurTree.length <=0){ //|| 
                    Commons.showErrorMessage("please select at least one dataset AND one tree!!","Empty Selection")
                }else{
                    // console.log(this.smCurDataset)
                    axios.post(this.server_add+'query/copySelectedDatasetsToSelectedTrees',
                    {
                        userId:this.userId,
                        srcTreedbSerial:this.activeTree.dbSerial,
                        selDatasets:this.smCurDataset.map(p=>p.value+":"+p.text+":"+p.type),
                        selDatTypes:[...new Set(this.smCurDataset.map(p=>p.type))],
                        selTrees:this.smCurTree.map(p=>p.value)
                    }).then(tree => {
                        this.closeSmModal()
                        this.showLoading = true
                        let propArray = [this.loadingModWidth,this.showLoading,this.showShareLink,this.linkShareMode,this.shareLinkName,'',this.embedPath]
                        EventBus.$emit('loadModProps', propArray)
                        this.$modal.show("loadingMod")
                        setTimeout(() => this.hideLoadingModal(true,'Copy success','Dataset(s) successfully copied; you may have to refresh the webpage to view the results.'), 200);
                    }).catch(err => {
                        console.log(err)
                    })
                } 
            }else if(this.smModelType == 2){ //share tree 
                if(this.activeProject.projectName != ("DEMOS") && this.activeProject.projectName != ("SHOWCASES")  && this.activeProject.projectName != ("EXAMPLES")) {                
                    let that = this
                    this.$modal.show('dialog', {
                        title: 'Confirmation',
                        text: 'Do you want to '+this.shareSel+' the tree?',
                        buttons: [
                        {
                            title: 'Cancel',
                            handler: () => {
                            this.$modal.hide('dialog')
                            }
                        },
                        {
                            title: 'Submit',
                            handler: () => {
                            that.showLoading = true
                            let propArray = [0,that.showLoading,'','','','','']
                            EventBus.$emit('loadModProps', propArray)
                            that.shareMyTree()
                            that.$modal.hide('dialog')
                            }
                        }              
                        ]
                    })
                }else{
                    console.log('i am lost',this.activeProject.projectName)
                }
            }
        },
        hideLoadingModal(notify,title,notify_message){
            this.$modal.hide("loadingMod")
            this.$bvToast.toast(notify_message, {
                title: title,
                autoHideDelay: 5000,
                variant:"success",
                solid: true
            })            
        }, 
        shareMyTree(){
            const today = new Date()
            var dates_as_int = Number(today.getTime()*.001)
            var shareName = this.activeTree.getTreeName()+this.makeid(Math.floor((Math.random() * 10) + 1))
            var treeStr = ''
            if(this.shareProject){
                treeStr = this.activeProject.siblings.map(p=>p.ID+":"+p.TreeName).join(',')
            }else{
                treeStr = this.activeTree.getdbserial()+":"+this.activeTree.getTreeName()+","
            }
            axios.post(this.server_add+'query/shareTree',
            {
                treeId:this.activeTree.getdbserial(),
                treesLis:treeStr,
                treeName:this.activeTree.getTreeName(),
                projName:this.activeProj.projectName,
                shareType:this.shareSel,
                shareName:shareName,
                annoMsg:"No details",
            }).then(tree => {
                if(tree.status == 200){
                    this.showLoading = false
                    this.showShareLink = true
                    this.shareLinkName = shareName
                    this.linkShareMode = this.shareSel
                    if(shareName!= 'Share'){
                        this.embedPath = this.$hosturl+'embed/'+this.shareLinkName                        
                        this.loadingModWidth = 350
                    }
                    // console.log('show loader')
                    this.$modal.show("loadingMod")            
                    let propArray = [this.loadingModWidth,this.showLoading,this.showShareLink,this.linkShareMode,this.shareLinkName,'',this.embedPath]
                    EventBus.$emit('loadModProps', propArray)
                    // messageVNode = h('div', { class: ['foobar'] }, [
                    // h('p', { class: ['text-center'] }, 'Share link created!!'),          
                    // ])
                }
            }).catch(err => {
                console.log(err)
            })
        },
        makeid(length) {
            var result           = '';
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        },
        closeSmModal(){
            this.smModelType = 0
            this.projTreeList = []
            this.datasetLis = []
            this.cancelUsedSm = true
            this.$modal.hide('SmallModal');
        },
    },
}
</script>
