import PlotBase from "./PlotBase";
import treePlotMode from '../treeObjects/treePlotMode'
import Point from '../treePainter/Point'
import StringOps from "../utils/StringOps";


class GeneTransferPlot extends PlotBase{
    constructor(layer_tree_charts, disable_all_dataset, treeSVG){
        super()

        this.layer_tree_charts = layer_tree_charts
        this.disable_all_dataset = disable_all_dataset
        this.chart_plots_enabled = treeSVG.chart_plots_enabled
        this.plotmode = treeSVG.getPlotMode()
        this.phylotree = treeSVG.getPhyloTree()
        this.treeSVG = treeSVG
        this.pxPerHeight = treeSVG.pxPerHeight
        this.rootXY = treeSVG.rootXY
        this.defaultColor = "black";
    }

    setGeneTransferData( newID, newdataset) {
        this.dataset = newdataset;
        this.datasetID = newID;
        this.setActive(true);
        this.datasetContentAsString = newdataset.getOriginal_datastring(); // get original user input as String ;
        this.setLegendEntry(newdataset.getLegend()); //
    }

    plotStyle1(item,arrow_spacer,geneTransferLyr,bestStartXPos){
        var p1 = null,p12 = null,p1Main = null,p2 = null,p21 = null,p2Main = null
        var idList = []
        if(item.getStartSrcLeafID().length >= 1){
            p1 = this.treeSVG.getPositionsBasedonInternalID(item.getStartSrcLeafID())              
            idList.push(item.getStartSrcLeafID())
            p1Main = p1
            if(item.getEndSrcLeafID().length >= 1){
                p12 = this.treeSVG.getPositionsBasedonInternalID(item.getEndSrcLeafID())                                              
                if(p1 != null && p12 != null){
                    if (p1.verticalLevel > p12.verticalLevel) {
                        var tmp = p1;
                        p1 = p12;
                        p12 = tmp;
                    }
                    var pt1Center = Point.twoPointsCenter(p1,p12)
                    p1Main = {'pt':{'x':bestStartXPos+arrow_spacer,'y':pt1Center},'angle':p1.angle,'verticalLevel':p1.verticalLevel,'number_leaf_decendents':p1.number_leaf_decendents}                    
                }
            }
        }
        if(item.getStartDestLeafID().length >= 1 ){
            p2 = this.treeSVG.getPositionsBasedonInternalID(item.getStartDestLeafID())              
            idList.push(item.getStartDestLeafID())
            p2Main = p2
            if(item.getEndDestLeafID().length >= 1){
                p21 = this.treeSVG.getPositionsBasedonInternalID(item.getEndDestLeafID())              
                var pathDest = this.phylotree.getLCA([item.getStartDestLeafID(),item.getEndDestLeafID()])                                
                idList.push(pathDest.getInternalID())
                console.log(pathDest.getInternalID(),this.phylotree.getNodeByID(item.getStartDestLeafID()))
                // idList = idList.concat(this.phylotree.getPath2MRoot(this.phylotree.getNodeByID(item.getStartDestLeafID()),pathDest))
                // idList = idList.concat(this.phylotree.getPath2MRoot(this.phylotree.getNodeByID(item.getEndDestLeafID()),pathDest))
                if(p2 != null && p21 != null){
                    if (p2.verticalLevel > p21.verticalLevel) {
                        var tmp = p2;
                        p2 = p21;
                        p21 = tmp;
                    }
                    var pt2Center = Point.twoPointsCenter(p2,p21)
                    p2Main = {'pt':{'x':bestStartXPos+arrow_spacer,'y':pt2Center},'angle':p2.angle,'verticalLevel':p2.verticalLevel,'number_leaf_decendents':p2.number_leaf_decendents}
                }
            }
        }
        if(p1Main != null && p2Main != null){
            var radius = this.rootXY.x/2;
            var angle = p1Main.angle/2
            var arPos = ''
            var arSeq = ''
            var pp1 = p1Main,pp2 = p2Main
            if(item.getDir() === "0:1"){
                radius = p2Main.pt.x/4
                angle = p2Main.angle
                arPos = 'end'
                arSeq = '0 0, 5 3.5, 0 7'
                // pp1.pt.x +=arrow_spacer
                // pp2.pt.x +=(arrow_spacer/2)
            }else if(item.getDir() === "1:0"){
                radius = p1Main.pt.x/4
                angle = p1Main.angle
                arPos = 'start'
                arSeq = '10 0, 10 7, 2 3.5'
                pp1.pt.x -=arrow_spacer
                pp2.pt.x -=arrow_spacer
            }else if(item.getDir() === "1:1"){
                radius = (p1Main.pt.x+p2Main.pt.x)/2
                angle = (p1Main.angle+p2Main.angle)/2
                // pp1.pt.x -=arrow_spacer
                // pp2.pt.x -=arrow_spacer
            }
            if(item.hasAngle){
                angle = item.curAngle
            }
            if(item.hasRadius){
                radius = item.curRadius
            }
            if (this.plotmode === treePlotMode.CIRCULAR_CLADOGRAM
                || this.plotmode === treePlotMode.CIRCULAR_PHYLOGRAM) {
            }else{
                var arc = this.makeArcPlot(pp1.pt,pp2.pt,this.rootXY, Math.abs(radius), Math.abs(angle), geneTransferLyr,bestStartXPos); //mostrightForGenTranss
                arc.attr("stroke", item.getLineColor());
                if (item.isbDashedLine()) {
                    arc.attr("stroke-dasharray", "5,2"); // now all have this dash
                }
                arc.attr("stroke-width", item.getLineWidth());
                arc.attr("fill", "none");
                arc.marker(arPos, 8, 10, function(add) {
                add.polygon(arSeq)
                .center(3,1)
                .fill(item.getLineColor())
                })
            }
        }
    }

    plotStyle2(item,arrow_spacer,geneTransferLyr,bestStartXPos){
        var p1 = null,p12 = null,p1Main = null,p2 = null,p21 = null,p2Main = null
        var idList = []        
        var maxWidth = 0
        if(item.getStartSrcLeafID().length >= 1){
            p1 = this.treeSVG.getPositionsBasedonLeafID(item.getStartSrcLeafID())              
            p1Main = p1
            p1Main.pt.x = bestStartXPos+arrow_spacer            
            idList.push(this.treeSVG.getExternalToInternalId(item.getStartSrcLeafID()))
            if(item.getEndSrcLeafID().length >= 1){
                var pathSrc = this.phylotree.getLCA([item.getStartSrcLeafID(),item.getEndSrcLeafID()])
                idList.push(pathSrc.getInternalID())
                idList = idList.concat(this.phylotree.getPath2MRoot(this.phylotree.getNodeByID(item.getStartSrcLeafID()),pathSrc))
                idList = idList.concat(this.phylotree.getPath2MRoot(this.phylotree.getNodeByID(item.getEndSrcLeafID()),pathSrc))
                // console.log(item.getEndSrcLeafID(),this.phylotree.getNodeByID(item.getEndSrcLeafID()),this.phylotree.getPath2MRoot(this.phylotree.getNodeByID(item.getEndSrcLeafID()),pathSrc))
                p12 = this.treeSVG.getPositionsBasedonLeafID(item.getEndSrcLeafID())
                if(p1 != null && p12 != null){
                    if (p1.verticalLevel > p12.verticalLevel) {
                        var tmp = p1;
                        p1 = p12;
                        p12 = tmp;
                    }
                    var pt1Center = Point.twoPointsCenter(p1,p12)
                    p1Main = {'pt':{'x':bestStartXPos+arrow_spacer,'y':pt1Center},'angle':p1.angle,'verticalLevel':p1.verticalLevel,'number_leaf_decendents':p1.number_leaf_decendents}
                    var line = geneTransferLyr.line(bestStartXPos,p1.pt.y,bestStartXPos,p12.pt.y)
                    line.attr("stroke", item.getLineColor());
                }
            }
            if(p1 != null && p12 == null){
                var y1 =  (p1.pt.y - this.pxPerHeight * (1 + Math.log10(p1.number_leaf_decendents)) / 2);
                var y2 =  (p1.pt.y + this.pxPerHeight * (1 + Math.log10(p1.number_leaf_decendents)) / 2);

                var line = geneTransferLyr.line(bestStartXPos,y1,bestStartXPos,y2)
                line.attr("stroke", item.getLineColor());
            }
        }
        if(item.getStartDestLeafID().length >= 1 ){
            p2 = this.treeSVG.getPositionsBasedonLeafID(item.getStartDestLeafID())
            p2Main = p2
            p2Main.pt.x = bestStartXPos+arrow_spacer            
            idList.push(this.treeSVG.getExternalToInternalId(item.getStartDestLeafID()))
            if(item.getEndDestLeafID().length >= 1){
                p21 = this.treeSVG.getPositionsBasedonLeafID(item.getEndDestLeafID())
                var pathDest = this.phylotree.getLCA([item.getStartDestLeafID(),item.getEndDestLeafID()])
                idList.push(pathDest.getInternalID())
                idList = idList.concat(this.phylotree.getPath2MRoot(this.phylotree.getNodeByID(item.getStartDestLeafID()),pathDest))
                idList = idList.concat(this.phylotree.getPath2MRoot(this.phylotree.getNodeByID(item.getEndDestLeafID()),pathDest))
                if(p2 != null && p21 != null){
                    if (p2.verticalLevel > p21.verticalLevel) {
                        var tmp = p2;
                        p2 = p21;
                        p21 = tmp;
                    }
                    var pt2Center = Point.twoPointsCenter(p2,p21)
                    p2Main = {'pt':{'x':bestStartXPos+arrow_spacer,'y':pt2Center},'angle':p2.angle,'verticalLevel':p2.verticalLevel,'number_leaf_decendents':p2.number_leaf_decendents}
                    var line = geneTransferLyr.line(bestStartXPos,p2.pt.y,bestStartXPos,p21.pt.y)
                    line.attr("stroke", item.getLineColor());
                }
            }
            if(p2 != null && p21 == null){
            var y1 =  (p2.pt.y - this.pxPerHeight * (1 + Math.log10(p2.number_leaf_decendents)) / 2);
            var y2 =  (p2.pt.y + this.pxPerHeight * (1 + Math.log10(p2.number_leaf_decendents)) / 2);

            var line = geneTransferLyr.line(bestStartXPos,y1,bestStartXPos,y2)
            line.attr("stroke", item.getLineColor());
            }
        }
        if(p1Main != null && p2Main != null){
            var radius = this.rootXY.x;
            var angle = p1Main.angle
            var arPos = ''
            var arSeq = ''
            var pp1 = p1Main,pp2 = p2Main
            if(item.getDir() === "0:1"){
                radius = p2Main.pt.x
                angle = p2Main.angle
                arPos = 'end'
                arSeq = '0 0, 5 3.5, 0 7'
                pp1.pt.x -=arrow_spacer
                pp2.pt.x -=arrow_spacer
            }else if(item.getDir() === "1:0"){
                radius = p1Main.pt.x
                angle = p1Main.angle
                arPos = 'start'
                arSeq = '10 0, 10 7, 2 3.5'
                pp1.pt.x -=arrow_spacer
                pp2.pt.x -=arrow_spacer
            }else if(item.getDir() === "1:1"){
                radius = (p1Main.pt.x+p2Main.pt.x)/2
                angle = (p1Main.angle+p2Main.angle)/2
                pp1.pt.x -=arrow_spacer
                pp2.pt.x -=arrow_spacer
            }

            if(item.hasAngle){
                angle = item.curAngle
            }
            if(item.hasRadius){
                radius = item.curRadius
            }
            if (this.plotmode === treePlotMode.CIRCULAR_CLADOGRAM
                || this.plotmode === treePlotMode.CIRCULAR_PHYLOGRAM) {
            }else{
                var arc = this.makeArcPlot(pp1.pt,pp2.pt,this.rootXY, radius, Math.abs(180-angle), geneTransferLyr,bestStartXPos); //mostrightForGenTranss
                arc.attr("stroke", item.getLineColor());
                if (item.isbDashedLine()) {
                    arc.attr("stroke-dasharray", "5,2"); // now all have this dash
                }
                arc.attr("stroke-width", item.getLineWidth());
                arc.attr("fill", "none");
                arc.marker(arPos, 8, 10, function(add) {
                add.polygon(arSeq)
                .center(3,1)
                .fill(item.getLineColor())
                })

                var that = this
                arc.mouseover(function(evt) {
                    for(let entry in idList){
                        that.treeSVG.addHighlightToAllRowplotsByInternalLeafID(idList[entry]);
                    }
                })
                arc.mouseout(function(evt){
                    for(let entry in idList){
                        that.treeSVG.removeHighlightFromAllRowplotsByInternalLeafID(idList[entry]);
                    }
                })

                maxWidth = arc.width() +10
            }
        }
        return maxWidth
    }

    makeArcPlot( startPt,stopPt,center,radius,angle, parent_layer, mostRight) {
		var dxy = Point.getCoordinatesOnCircle(center, radius, angle);
		var segs = new Point()	
        segs.appendSegItem(Point.createPointStr(startPt.x,startPt.y));	
        segs.appendSegItem(Point.createCubicArcStr(startPt.x,startPt.y,dxy.x,dxy.y,stopPt.x,stopPt.y))
        return parent_layer.path(segs.getSegPathListStr())        
	}

    getDatasetFromParser(){
        return this.dataset
    }
}

export default GeneTransferPlot